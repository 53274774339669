import React from 'react';
import PropTypes from 'prop-types';

import { CellWrapper } from '../CellWrapper';
import { recordDocumentTypesDict } from '../../../../../../../shared_config/recordsRetention';
import { Tooltip } from '../../../../../components';

const { CONTRACTS, DOCUMENTS, INTAKES } = recordDocumentTypesDict;

export const ClosedDateCell = ({ api, node, value }) => {
    const styles = require('./index.scss');

    const getRecordTypeText = () => {
        switch (value.recordType) {
            case DOCUMENTS:
                return 'Document';
            case INTAKES:
                return 'Intake';
            case CONTRACTS:
                return 'Contract';
            default:
                return '';
        }
    };

    const closeReason = `${getRecordTypeText()}: ${value.reason}`;

    return (
        <CellWrapper api={api} node={node}>
            <Tooltip container={this} isPopover placement="top" rootClose tooltip={value.date}>
                <div className={styles.dateText}>{value.date}</div>
            </Tooltip>
            <Tooltip container={this} isPopover placement="top" rootClose tooltip={closeReason}>
                <div className={styles.subText}>{closeReason}</div>
            </Tooltip>
        </CellWrapper>
    );
};

ClosedDateCell.propTypes = {
    api: PropTypes.object,
    node: PropTypes.object,
    value: PropTypes.shape({
        date: PropTypes.string,
        reason: PropTypes.string,
        recordType: PropTypes.string,
    }),
};
