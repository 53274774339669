import { showSnackbar } from './notification';
import { inboxNotificationStatusesDict } from '../../../shared_config/audits';

const { NEW } = inboxNotificationStatusesDict;

const BASE_URL = '/user/me/inbox-notifications';

export const LOAD_HAS_NEW_INBOX_NOTIFICATION_SUCCESS =
    'gov/inboxNotifications/LOAD_HAS_NEW_INBOX_NOTIFICATION_SUCCESS';

export function loadHasNewInboxNotification() {
    return (dispatch, getState, client) => {
        return client
            .get(BASE_URL, { params: { limit: 1, status: NEW } })
            .then((result) => {
                dispatch({ type: LOAD_HAS_NEW_INBOX_NOTIFICATION_SUCCESS, result });
            })
            .catch(() => {}); // Intentionally ignoring errors here so we don't spam our logs
    };
}

export const LOAD_INBOX_NOTIFICATIONS = 'gov/inboxNotifications/LOAD_INBOX_NOTIFICATIONS';
export const LOAD_INBOX_NOTIFICATIONS_SUCCESS =
    'gov/inboxNotifications/LOAD_INBOX_NOTIFICATIONS_SUCCESS';
export const LOAD_INBOX_NOTIFICATIONS_FAIL = 'gov/inboxNotifications/LOAD_INBOX_NOTIFICATIONS_FAIL';

export function getInboxNotifications() {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_INBOX_NOTIFICATIONS });
        return client
            .get(BASE_URL)
            .then((result) => {
                dispatch({ type: LOAD_INBOX_NOTIFICATIONS_SUCCESS, result });
            })
            .catch((error) => dispatch({ type: LOAD_INBOX_NOTIFICATIONS_FAIL, error }));
    };
}

// State is managed by the calling component internally instead of redux
export function loadInboxNotificationEmail(id) {
    return (dispatch, getState, client) => {
        return client.get(`${BASE_URL}/${id}`);
    };
}

export const UPDATE_INBOX_NOTIFICATIONS = 'gov/inboxNotifications/UPDATE_INBOX_NOTIFICATIONS';
export const UPDATE_INBOX_NOTIFICATIONS_SUCCESS =
    'gov/inboxNotifications/UPDATE_INBOX_NOTIFICATIONS_SUCCESS';
export const UPDATE_INBOX_NOTIFICATIONS_FAIL =
    'gov/inboxNotifications/UPDATE_INBOX_NOTIFICATIONS_FAIL';

export function updateInboxNotifications(data) {
    return (dispatch, getState, client) => {
        dispatch({ type: UPDATE_INBOX_NOTIFICATIONS });
        return client
            .put(BASE_URL, { data })
            .then((result) => {
                const numUpdated = result.length;
                dispatch({ type: UPDATE_INBOX_NOTIFICATIONS_SUCCESS, result });
                dispatch(
                    showSnackbar(`${numUpdated} Notification${numUpdated > 1 ? 's' : ''} Updated`)
                );
                dispatch(loadHasNewInboxNotification());
            })
            .catch((error) => {
                dispatch({ type: UPDATE_INBOX_NOTIFICATIONS_FAIL, error });
                dispatch(showSnackbar(`Error: ${error.message}`, { isError: true }));
            });
    };
}
