import { get, isEmpty } from 'lodash';

import { getHtmlCharacterCount } from '../../utils';
import {
    ALLOW_MULTI_SELECT,
    ATTACHMENTS,
    BID_BOND,
    bidBondQuestionnaireResponseFieldNamesDict,
    CONFIRMATION,
    CUSTOM_VARIABLE,
    DATA,
    DATA_VERIFY,
    DOWNLOAD,
    EXTERNAL_FORM,
    FILE_UPLOAD,
    INPUT_DATA,
    LONG_ANSWER,
    MAX_LENGTH,
    MULTIPLE_CHOICE,
    NOTARIZE,
    QUESTIONNAIRE_RESPONSE,
    RESPONSE_DATA,
    SHORT_ANSWER,
    VALUE,
    YES_NO,
} from '../../../../shared_config/questionnaires';

const { BID_BOND_ID, BID_BOND_VENDOR_ID } = bidBondQuestionnaireResponseFieldNamesDict;

const RESPONSE_REQUIRED = 'Response is required';
const RESPONSE_OPTIONAL = 'Optional item skipped';

// Accessor for questionnaireResponse data
function getResponseData(questionnaire, responseKey, value) {
    let basePath = responseKey;

    if (value) {
        basePath = `${basePath}.${value}`;
    }

    return get(questionnaire, basePath);
}

/**
 * Helper for storing the validation error in the `questionnaireResponse` data object
 * @param {any} responseValidationError Fields within the `data` object that has errors
 * @return {object} Object formatted to identify the `data` fields with validation errors
 */
function questionnaireResponseValidationError(responseValidationError) {
    return {
        [QUESTIONNAIRE_RESPONSE]: {
            [RESPONSE_DATA]: responseValidationError,
        },
    };
}

function upfrontQuestionInputValidationError(responseValidationError) {
    return {
        [INPUT_DATA]: responseValidationError,
    };
}

/**
 * Checks whether the questionnaire has been completed
 * @param  {object} questionnaire Questionnaire to validate the response on
 * @param  {object} [opts={}] Options object
 * @param  {boolean} [opts.isUpfrontQuestion] Whether to use upfront question validation (vs vendor questionnaire validation)
 * @param  {boolean} [opts.optional] Displays error message with optional text copy
 * @param  {boolean} [opts.skipOptionalValidation] For optional questionnaires we only need to check that the questionnaire is valid or incomplete (need to check only that optional question was not answered in invalid manner)
 * @return {object|undefined} `redux-form` formatted error object
 */
export function questionnaireResponseComplete(questionnaire, opts = {}) {
    const { isUpfrontQuestion, optional, skipOptionalValidation } = opts;

    // Don't validate `isHiddenByLogic` items
    if (questionnaire.isHiddenByLogic) {
        return undefined;
    }

    const responseKey = isUpfrontQuestion
        ? INPUT_DATA
        : `${QUESTIONNAIRE_RESPONSE}.${RESPONSE_DATA}`;

    const validationError = isUpfrontQuestion
        ? upfrontQuestionInputValidationError
        : questionnaireResponseValidationError;

    // These questionnaire errors need to fail validation even if questionnaire response is optional
    // as they have been answered in an invalid manner
    switch (questionnaire.type) {
        case LONG_ANSWER:
        case SHORT_ANSWER: {
            const response = getResponseData(questionnaire, responseKey, VALUE);
            const maxLength = get(questionnaire, [DATA, MAX_LENGTH]);
            if (maxLength && response && getHtmlCharacterCount(response) > maxLength) {
                return validationError({
                    [VALUE]: `Response too long (${maxLength} characters maximum)`,
                });
            }
            break;
        }
        default:
            break;
    }

    // In some cases we only want to validate required fields. This option allows us to only
    // validate optional questions for invalid responses (above) and does no further validation.
    // `warn` level validation will want to continue validating even for optional questions.
    if (skipOptionalValidation) {
        return undefined;
    }

    const ERROR_MESSAGE = optional ? RESPONSE_OPTIONAL : RESPONSE_REQUIRED;

    switch (questionnaire.type) {
        case BID_BOND: {
            const bidBondIdResponse = getResponseData(questionnaire, responseKey, BID_BOND_ID);
            const bidBondVendorIdResponse = getResponseData(
                questionnaire,
                responseKey,
                BID_BOND_VENDOR_ID
            );
            const errors = {};
            if (!bidBondIdResponse) {
                errors[BID_BOND_ID] = ERROR_MESSAGE;
            }
            if (!bidBondVendorIdResponse) {
                errors[BID_BOND_VENDOR_ID] = ERROR_MESSAGE;
            }
            return isEmpty(errors) ? undefined : validationError(errors);
        }
        case CONFIRMATION: {
            const response = getResponseData(questionnaire, responseKey, VALUE);
            if (!response) {
                return validationError({
                    [VALUE]: optional ? RESPONSE_OPTIONAL : 'Confirmation is required',
                });
            }
            break;
        }
        case CUSTOM_VARIABLE:
        case DATA_VERIFY:
        case LONG_ANSWER:
        case SHORT_ANSWER: {
            const response = getResponseData(questionnaire, responseKey, VALUE);
            if (!response) {
                return validationError({
                    [VALUE]: ERROR_MESSAGE,
                });
            }
            break;
        }
        case MULTIPLE_CHOICE: {
            const allowMultiSelect = get(questionnaire, [DATA, ALLOW_MULTI_SELECT]);
            const response = getResponseData(questionnaire, responseKey, VALUE);
            const noResponse = allowMultiSelect
                ? !Array.isArray(response) || response.length === 0
                : !response;
            if (noResponse) {
                return validationError({
                    [VALUE]: ERROR_MESSAGE,
                });
            }
            break;
        }
        case YES_NO: {
            const response = getResponseData(questionnaire, responseKey, VALUE);
            if (response === undefined) {
                return validationError({
                    [VALUE]: ERROR_MESSAGE,
                });
            }
            break;
        }
        case DOWNLOAD:
        case EXTERNAL_FORM:
        case FILE_UPLOAD:
        case NOTARIZE: {
            const response = getResponseData(questionnaire, responseKey, ATTACHMENTS);
            if (!response || response.length === 0) {
                // File upload manipulates `QUESTIONNAIRE_RESPONSE`, so `validationError` is not needed
                return {
                    [QUESTIONNAIRE_RESPONSE]: ERROR_MESSAGE,
                };
            }
            break;
        }
        default:
            break;
    }

    // Case where there are no validation errors
    return undefined;
}

// Validates the questionnaire (only fails validation if required and not completed)
export function questionnaireResponse(questionnaire, opts) {
    if (questionnaire.isRequired) {
        return questionnaireResponseComplete(questionnaire, opts);
    }

    // For optional questionnaires we only need to check that the response is valid or
    // skipped (checks only that optional question was not answered in invalid manner)
    return questionnaireResponseComplete(questionnaire, { skipOptionalValidation: true });
}
