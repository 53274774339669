import { createSelector } from 'reselect';

import { projectTypesDict } from '../../../../../shared_config/projects';

const { PURCHASE } = projectTypesDict;

const getTemplateWorkloadWeights = (state) => state.admin.get('templateWorkloadWeights');

export const getTemplatesWithWorkloads = createSelector(
    [getTemplateWorkloadWeights],
    (templateWorkloadWeights) => {
        return templateWorkloadWeights.toJS().map(({ workloadWeight, ...template }) => {
            const { id, draft, review, final } = workloadWeight;
            return {
                ...template,
                workloadWeight:
                    template.projectType === PURCHASE
                        ? workloadWeight
                        : {
                              id,
                              draft,
                              review,
                              final,
                          },
            };
        });
    }
);
