import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { ProposalSelectForm } from './ProposalSelectForm';
import { fieldNames, form } from './ProposalSelectForm/constants';
import { getProposalsJS } from './selectors';
import {
    hideProposalSelect,
    loadAggregateProposalEvaluations,
    loadBidTabulationsWithTotals,
    selectProposals,
} from '../../../../actions/proposalEvaluations';
import { LoadingError, LoadingSpinner } from '../../../../components';
import { LINE_ITEM_AWARD, LOWEST_PRICE, SCORED } from '../../../../../../shared_config/evaluations';
import { getBidPosterAuthorized } from '../../selectors';

const { PROPOSAL_IDS, SHOULD_NOTIFY } = fieldNames;

const mapStateToProps = (state, props) => {
    return {
        hasBidPostAuthorization: getBidPosterAuthorized(state),
        loading:
            state.proposalEvaluations.get('loadingAggregate') ||
            state.proposalEvaluations.get('loadingBidTabulationTotals'),
        loadError:
            state.proposalEvaluations.get('loadAggregateError') ||
            state.proposalEvaluations.get('loadingBidTabulationTotalsError'),
        proposals: getProposalsJS(state, props),
        shouldNotify: formValueSelector(form)(state, SHOULD_NOTIFY),
        updateError: state.proposalEvaluations.get('selectProposalError'),
        updating: state.proposalEvaluations.get('selectingProposal'),
    };
};

const mapDispatchToProps = {
    hideProposalSelect,
    loadAggregateProposalEvaluations,
    loadBidTabulationsWithTotals,
    selectProposals,
};

// @connect
class ConnectedProposalSelectModal extends Component {
    static propTypes = {
        hideProposalSelect: PropTypes.func.isRequired,
        hasBidPostAuthorization: PropTypes.bool.isRequired,
        loadError: PropTypes.string,
        loadAggregateProposalEvaluations: PropTypes.func.isRequired,
        loadBidTabulationsWithTotals: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
            evaluation: PropTypes.shape({
                hasAwardPending: PropTypes.bool.isRequired,
                type: PropTypes.oneOf([LOWEST_PRICE, SCORED, LINE_ITEM_AWARD]).isRequired,
            }).isRequired,
        }).isRequired,
        proposals: PropTypes.array.isRequired,
        selectProposals: PropTypes.func.isRequired,
        shouldNotify: PropTypes.bool,
        updateError: PropTypes.string,
        updating: PropTypes.bool.isRequired,
    };

    componentDidMount() {
        const {
            project: {
                id: projectId,
                evaluation: { type: evaluationType },
            },
        } = this.props;

        if (evaluationType === LOWEST_PRICE) {
            this.props.loadBidTabulationsWithTotals(projectId);
        } else if (evaluationType === SCORED) {
            this.props.loadAggregateProposalEvaluations(projectId);
        }
    }

    get styles() {
        return require('./index.scss');
    }

    selectProposals = (data) => {
        // Ensure that proposal IDs are an array (single select returns ID)
        const { proposalIds, ...additionalData } = data;
        const {
            project: {
                evaluation: { type },
            },
        } = this.props;

        const formData = {
            ...additionalData,
        };

        if (type !== LINE_ITEM_AWARD) {
            const cleanProposalIds = proposalIds.map((selectData) => selectData.value);
            formData[PROPOSAL_IDS] = cleanProposalIds;
        }

        return this.props.selectProposals(formData);
    };

    renderBody() {
        const {
            hasBidPostAuthorization,
            loading,
            loadError,
            project: {
                evaluation: { hasAwardPending, type },
            },
            proposals,
            shouldNotify,
            updating,
            updateError,
        } = this.props;

        if (loading && !loadError) {
            return <LoadingSpinner className={this.styles.loadingContainer} noPadding />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        return (
            <ProposalSelectForm
                hasAwardPending={hasAwardPending}
                hasBidPostAuthorization={hasBidPostAuthorization}
                initialValues={{
                    [PROPOSAL_IDS]: [proposals[0]],
                    [SHOULD_NOTIFY]: true,
                }}
                isLineItemAward={type === LINE_ITEM_AWARD}
                onSubmit={this.selectProposals}
                proposals={proposals}
                shouldNotify={shouldNotify}
                updateError={updateError}
                updating={updating}
            />
        );
    }

    render() {
        const {
            project: {
                evaluation: { hasAwardPending },
            },
        } = this.props;

        return (
            <Modal onHide={this.props.hideProposalSelect} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">
                        {hasAwardPending ? 'Make Award Recommendation' : 'Award Project'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
            </Modal>
        );
    }
}

export const ProposalSelectModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedProposalSelectModal);
