import { redirect } from 'react-router-dom';

import { basicUserRoles } from '../../../../shared_config/userRoles';

/*
 * Redirects a user to the vendor index page if they are not an admin or batman.
 */
export default (getState) =>
    async ({ params }) => {
        const state = getState();
        const role = state.auth.getIn(['user', 'role']);

        if (role !== basicUserRoles.ADMIN && role !== basicUserRoles.BATMAN) {
            return redirect(`/vendors/${params.vendorId}`);
        }

        return null;
    };
