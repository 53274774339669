import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Outlet } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { getNavItems } from './selectors';
import { isContractAdminForContract, isContractEditor } from '../selectors';
import { getContractJS, isRetentionAdminUser } from '../../selectors';
import connectData from '../../ConnectData';
import {
    ContractHeader,
    LoadingError,
    LoadingSpinner,
    Nav,
    NavItem,
    PageTitle,
} from '../../../components';
import { loadContract, updateContract } from '../../../actions/contracts';
import { showConfirmationModal, showConfirmationSimpleModal } from '../../../actions/confirmation';
import { TOGGLE_CONTRACT_PUBLIC_PRIVATE } from '../../../constants/menuActions';

function fetchData(getState, dispatch, location, params) {
    const contractId = Number.parseInt(params.contractId, 10);

    // Only load the contract if it is not the current contract or hasn't been loaded yet
    const shouldLoadContract =
        getState().contracts.getIn(['contract', 'id']) !== contractId ||
        !getState().contracts.get('loadedContract');

    if (shouldLoadContract) {
        return dispatch(loadContract(contractId));
    }
}

const mapStateToProps = (state, props) => {
    return {
        contract: getContractJS(state),
        isContainedByProject: !!props.params.projectId,
        isContractAdmin: isContractAdminForContract(state),
        isEditor: isContractEditor(state),
        isRetentionAdmin: isRetentionAdminUser(state),
        loadContractError: state.contracts.get('loadContractError'),
        loadingContract: !!state.contracts.get('loadingContract'),
        navItems: getNavItems(state, props),
        updatingContract: state.contracts.get('updatingContract'),
    };
};

const mapDispatchToProps = {
    showConfirmationModal,
    showConfirmationSimpleModal,
    updateContract,
};

// @connectData
// @connect
class ConnectedContractNav extends Component {
    static propTypes = {
        contract: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),
        isContainedByProject: PropTypes.bool.isRequired,
        isContractAdmin: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        isRetentionAdmin: PropTypes.bool.isRequired,
        loadContractError: PropTypes.string,
        loadingContract: PropTypes.bool.isRequired,
        navItems: PropTypes.arrayOf(
            PropTypes.shape({
                indexRoute: PropTypes.bool,
                link: PropTypes.string.isRequired,
                path: PropTypes.string,
                title: PropTypes.string.isRequired,
            })
        ).isRequired,
        showConfirmationModal: PropTypes.func.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        updateContract: PropTypes.func.isRequired,
        updatingContract: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    handlePublicChange = (e) => {
        const {
            contract: { id: contractId },
        } = this.props;

        if (e.target.checked) {
            return this.props.showConfirmationSimpleModal(
                () => {
                    this.props.updateContract(contractId, { isPublic: true });
                },
                {
                    bsStyle: 'warning',
                    btnText: 'Make Public',
                    icon: 'globe',
                    text: 'Are you sure you want this contract to be publicly viewable?',
                }
            );
        }

        this.props.showConfirmationModal(TOGGLE_CONTRACT_PUBLIC_PRIVATE, {
            isPublic: e.target.checked,
            contractId,
        });
    };

    render() {
        const {
            contract,
            isContainedByProject,
            isContractAdmin,
            isEditor,
            isRetentionAdmin,
            loadContractError,
            loadingContract,
            navItems,
            updatingContract,
        } = this.props;

        if (loadingContract) {
            return <LoadingSpinner />;
        }

        if (loadContractError || !contract) {
            return <LoadingError error={loadContractError} />;
        }

        const navItemComponents = navItems.map((item) => (
            <NavItem key={item.link} qaTag={`contract-${item.title}`} to={item.link}>
                {item.title}
            </NavItem>
        ));

        const contractComponent = (
            <>
                <ContractHeader
                    contract={contract}
                    handlePublicChange={this.handlePublicChange}
                    isContainedByProject={isContainedByProject}
                    isContractAdmin={isContractAdmin}
                    isEditor={isEditor}
                    isRetentionAdmin={isRetentionAdmin}
                    updatingContract={updatingContract}
                />
                <Nav bsStyle="tabs" className={this.styles.contractNavTabs}>
                    {navItemComponents}
                </Nav>
                <div className={this.styles.childContainer}>
                    <Outlet />
                </div>
            </>
        );

        if (isContainedByProject) {
            return contractComponent;
        }

        return (
            <Panel>
                <h1 className="visually-hidden">Contract Details</h1>
                <PageTitle title="Contract Details" />
                <Panel.Body>{contractComponent}</Panel.Body>
            </Panel>
        );
    }
}

export const ContractNav = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedContractNav);
