import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { SectionDividerDisplay, SharedSectionLabel } from '../../../../../components';
import { defaultSectionConfigsMap } from '../../../../../../../../../../shared_config/sections';

export class ProjectContentDisplay extends PureComponent {
    static propTypes = {
        isDivider: PropTypes.bool.isRequired,
        meta: PropTypes.shape({
            invalid: PropTypes.bool.isRequired,
        }).isRequired,
        projectSection: PropTypes.shape({
            isTemplate: PropTypes.bool,
            section_type: PropTypes.string,
            shortName: PropTypes.string,
            title: PropTypes.string,
        }).isRequired,
        showFormValidation: PropTypes.bool,
        useSectionDividers: PropTypes.bool.isRequired,
    };

    render() {
        const {
            isDivider,
            meta: { invalid },
            projectSection,
            showFormValidation,
            useSectionDividers,
        } = this.props;

        const defaultSection = defaultSectionConfigsMap[projectSection.section_type];
        const showErrors = showFormValidation && invalid;

        if (isDivider) {
            return (
                <div className={classnames('row', showErrors && 'text-danger')}>
                    <SectionDividerDisplay
                        icon={defaultSection.icon}
                        projectSection={projectSection}
                        showErrors={showErrors}
                        twoColumnLayout
                    />
                </div>
            );
        }

        return (
            <div className={classnames('row', showErrors && 'text-danger')}>
                <div
                    className={classnames(
                        useSectionDividers ? 'col-sm-5 col-sm-offset-1' : 'col-sm-5'
                    )}
                >
                    <div>
                        {showErrors && (
                            <span>
                                <i className="fa fa-exclamation-triangle" />
                                &nbsp;
                            </span>
                        )}
                        {projectSection.title} (<em>{projectSection.shortName}</em>)
                    </div>
                    {projectSection.isTemplate && (
                        <SharedSectionLabel includeLink projectSection={projectSection} small />
                    )}
                </div>
                <div className="col-sm-4">
                    <div>
                        <i className={`fa fa-fw fa-${defaultSection.icon}`} />
                        &nbsp;
                        {defaultSection.title} type
                    </div>
                </div>
            </div>
        );
    }
}
