import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

export const TemplateListTimestampColumns = ({ createdAt, lastUpdatedAt }) => {
    return (
        <div className="row">
            <div className="col-md-6" title={moment(createdAt).format('lll')}>
                {moment(createdAt).format('ll')}
            </div>
            <div className="col-md-6" title={moment(lastUpdatedAt).format('lll')}>
                {moment(lastUpdatedAt).fromNow()}
            </div>
        </div>
    );
};

TemplateListTimestampColumns.propTypes = {
    createdAt: PropTypes.string.isRequired,
    lastUpdatedAt: PropTypes.string.isRequired,
};
