import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
    Accordion,
    AccordionSummary,
    Box,
    Button,
    Chip,
    DropdownMenu,
    Typography,
} from '@og-pro/ui';
import { ArrowDropDown, ChevronRight, Close as CloseIcon } from '@mui/icons-material';
import { tokens } from '@opengov/capital-style';

import { EthnicityAccordion } from './EthnicityAccordion';
import { LanguagesAccordion } from './LanguagesAccordion';
import { VendorListsAccordion } from './VendorLists';
import { accordionStyles, accordionSummaryStyles, chipStyles } from './sharedStyles';

const EMPTY_FORM_STATE = {
    ethnicities: [],
    languages: [],
    vendorLists: [],
};

export const FiltersMenu = ({ isPublic, searchParams, setSearchParams }) => {
    const ethnicities = searchParams.get('ethnicities')
        ? searchParams.get('ethnicities').split(',')
        : [];
    const languages = searchParams.get('languages') ? searchParams.get('languages').split(',') : [];
    const vendorLists = searchParams.get('vendorLists')
        ? searchParams
              .get('vendorLists')
              .split(',')
              .map((list) => parseInt(list, 10))
        : [];

    const initialFormData = {
        ethnicities,
        languages,
        vendorLists,
    };

    const [formData, setFormData] = useState(initialFormData);

    const handleCancel = () => {
        setFormData(initialFormData);
    };

    const handleSubmit = (event) => {
        searchParams.set('ethnicities', formData.ethnicities.join(','));
        searchParams.set('languages', formData.languages.join(','));
        searchParams.set('vendorLists', formData.vendorLists.join(','));
        setSearchParams(searchParams);

        event.preventDefault();
    };

    const totalSelected =
        (initialFormData.ethnicities?.length ?? 0) +
        (initialFormData.languages?.length ?? 0) +
        (initialFormData.vendorLists?.length ?? 0);

    return (
        <DropdownMenu
            allowSubmit={!isEqual(formData, initialFormData)}
            endIcon={
                <>
                    {totalSelected > 0 ? (
                        <Chip
                            label={totalSelected}
                            size="extraSmall"
                            sx={{ fontSize: `${tokens.typography.fontSizeMinimum} !important` }}
                        />
                    ) : null}
                    <ArrowDropDown />
                </>
            }
            label="More Filters"
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            showFormControls
        >
            <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column" gap={0.5} pt={1} px={2} width="375px">
                    <Box
                        alignItems="center"
                        display="flex"
                        height={32}
                        justifyContent="space-between"
                        mb={1}
                    >
                        <Typography variant="h4">Filters</Typography>
                        {!isEqual(formData, EMPTY_FORM_STATE) && (
                            <Button
                                onClick={() => setFormData(EMPTY_FORM_STATE)}
                                size="small"
                                startIcon={<CloseIcon />}
                                sx={{ fontSize: tokens.typography.fontSizeDefault }}
                            >
                                Clear All
                            </Button>
                        )}
                    </Box>
                    <Accordion sx={accordionStyles}>
                        <AccordionSummary expandIcon={<ChevronRight />} sx={accordionSummaryStyles}>
                            <Box display="flex" gap={2}>
                                <Typography
                                    alignContent="center"
                                    color={tokens.colors.colorGray700}
                                    variant="h5"
                                >
                                    Ethnicity
                                </Typography>
                                <Chip
                                    label={formData.ethnicities?.length || 0}
                                    size="extraSmall"
                                    sx={chipStyles}
                                />
                            </Box>
                        </AccordionSummary>
                        <EthnicityAccordion formData={formData} setFormData={setFormData} />
                    </Accordion>
                    <Accordion sx={accordionStyles}>
                        <AccordionSummary expandIcon={<ChevronRight />} sx={accordionSummaryStyles}>
                            <Box display="flex" gap={2}>
                                <Typography
                                    alignContent="center"
                                    color={tokens.colors.colorGray700}
                                    variant="h5"
                                >
                                    Languages
                                </Typography>
                                <Chip
                                    label={formData.languages?.length || 0}
                                    size="extraSmall"
                                    sx={chipStyles}
                                />
                            </Box>
                        </AccordionSummary>
                        <LanguagesAccordion formData={formData} setFormData={setFormData} />
                    </Accordion>
                    <Accordion sx={accordionStyles}>
                        <AccordionSummary expandIcon={<ChevronRight />} sx={accordionSummaryStyles}>
                            <Box display="flex" gap={2}>
                                <Typography
                                    alignContent="center"
                                    color={tokens.colors.colorGray700}
                                    variant="h5"
                                >
                                    {isPublic ? 'Vendor Lists' : 'All Vendor Lists'}
                                </Typography>
                                <Chip
                                    label={formData.vendorLists?.length || 0}
                                    size="extraSmall"
                                    sx={chipStyles}
                                />
                            </Box>
                        </AccordionSummary>
                        <VendorListsAccordion
                            formData={formData}
                            isPublic={isPublic}
                            setFormData={setFormData}
                        />
                    </Accordion>
                </Box>
            </form>
        </DropdownMenu>
    );
};

FiltersMenu.propTypes = {
    isPublic: PropTypes.bool,
    searchParams: PropTypes.shape({
        get: PropTypes.func.isRequired,
        set: PropTypes.func.isRequired,
    }).isRequired,
    setSearchParams: PropTypes.func.isRequired,
};
