import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { RecordTable } from '..';
import { LoadingSpinner } from '../../../../../components';
import { EXPIRED } from '../../constants';
import { getExpiredJS } from '../../selectors';
import { getGovernmentRetentionCodesJS } from '../../../selectors';
import { expiredTableColumnDef } from '../constants';

const mapStateToProps = (state) => {
    return {
        deleteExpiredError: state.projects.get('deleteExpiredError'),
        deletingExpired: state.projects.get('deletingExpired'),
        expired: getExpiredJS(state),
        loadingExpired: state.projects.get('loadingExpired'),
        loadExpiredError: state.projects.get('loadExpiredError'),
        policies: getGovernmentRetentionCodesJS(state),
    };
};

const ConnectedRecordTableExpired = ({ deletingExpired, expired, loadingExpired, policies }) => {
    const [filter, setFilter] = useState();
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        setSelectedRows([]);
    }, [expired]);

    const policyWarning =
        "Based on the set policy, these record's retention expiration date has passed.";

    const rows = expired
        .filter((record) => {
            if (filter) {
                return filter === record.recordType;
            }
            return record;
        })
        .map((record) => {
            return {
                id: record.id,
                title: {
                    title: record.title,
                    projectId: record.id,
                    financialId: record.financialId || record.contractId,
                    governmentId: record.government_id,
                    recordType: record.recordType,
                },
                department: record.departmentName,
                contact: record.contactDisplayName,
                expirationDate: {
                    date: moment(record.expirationDate).format('lll'),
                    reason: record.closeOutReason || record.status,
                    recordType: record.recordType,
                },
                recordType: record.recordType,
            };
        });

    if (loadingExpired && !deletingExpired) {
        return <LoadingSpinner />;
    }

    return (
        <RecordTable
            columnDef={expiredTableColumnDef}
            filter={filter}
            loading={deletingExpired}
            policies={policies}
            policyWarning={policyWarning}
            rows={rows}
            selectedRows={selectedRows}
            setFilter={setFilter}
            setSelectedRows={setSelectedRows}
            showDelete
            storeLocation={EXPIRED}
        />
    );
};

ConnectedRecordTableExpired.propTypes = {
    deletingExpired: PropTypes.bool,
    expired: PropTypes.array.isRequired,
    loadingExpired: PropTypes.bool,
    policies: PropTypes.array.isRequired,
};

export const RecordTableExpired = connect(mapStateToProps)(ConnectedRecordTableExpired);
