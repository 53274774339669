import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { capitalize } from 'lodash';

import { DateFilter } from '../../../../../../components';
import { FilterGroup } from '../../../../../../components/FilterGroup';
import { dateTypes, singleDateTypes } from '../../../../../../components/DateFilter/constants';
import { formConfig, isDateValid } from '../../form';
import { fieldNames } from '../../constants';

const { DATE_TYPE, ON, AFTER, BEFORE, START, END, ONE_DATE_TYPE } = fieldNames;

const parseDate = (str, dateFormat) => {
    if (!str) {
        return 0; // This is a hack because `react-widgets` will reset the input to the previous entered date if empty string provided
    }

    const parsedMoment = moment(
        str,
        ['M/D/YYYY', 'M-D-YYYY', 'MM-DD-YYYY', 'M.D.YYYY', 'MM.DD.YYYY'],
        true
    );

    if (parsedMoment.isValid()) {
        return parsedMoment.format(dateFormat);
    }

    return str.replace(/\.|-/g, '/');
};

const getSubtitle = (dates) => {
    const formatDate = (date) => moment(new Date(date)).format('MM/DD/YYYY');

    if (!dates.length) {
        return;
    }

    if (dates.length === 1 && singleDateTypes.includes(dates[0].filter)) {
        return `${dates[0].label} ${formatDate(dates[0].value)}`;
    }

    const startDate = dates.find((date) => date.filter === START);
    const endDate = dates.find((date) => date.filter === END);

    if (startDate && endDate) {
        return `Between ${formatDate(startDate.value)} - ${formatDate(endDate.value)}`;
    }
};
export const RequisitionDashboardDateFilter = ({ dropdown }) => {
    const styles = require('./index.scss');

    const on = dropdown.selectedOptions.find((filter) => filter.filter === ON);
    const after = dropdown.selectedOptions.find((filter) => filter.filter === AFTER);
    const before = dropdown.selectedOptions.find((filter) => filter.filter === BEFORE);
    const start = dropdown.selectedOptions.find((filter) => filter.filter === START);
    const end = dropdown.selectedOptions.find((filter) => filter.filter === END);

    const dates = [on, after, before, start, end].filter((date) => date);

    const handleOnChange = (date) => {
        const { setSelectedOptions } = dropdown;

        if (singleDateTypes.includes(date.type)) {
            setSelectedOptions((prevState) =>
                prevState.filter((option) => !dateTypes.includes(option.filter))
            );
        } else {
            setSelectedOptions((prevState) =>
                prevState.filter((option) => !singleDateTypes.includes(option.filter))
            );
        }

        setSelectedOptions((prevState) =>
            prevState.filter((option) => option.filter !== date.type)
        );

        if (!isDateValid(date.value)) {
            return;
        }

        if (date.value) {
            setSelectedOptions((prevState) => [
                ...prevState,
                {
                    filter: date.type,
                    label: capitalize(date.type),
                    value: date.value,
                },
            ]);
        }
    };

    return (
        <FilterGroup
            className={styles.filterGroup}
            groupFiltersSelected={dates.length}
            onChange={dropdown.toggleOption}
            selectedOptions={dropdown.selectedOptions || []}
            subtitle={getSubtitle(dates)}
            title="Desired Delivery Date"
        >
            <DateFilter
                className={styles.dateFilterContainer}
                dateFormat="MM/DD/YYYY"
                dateTypeFieldName={DATE_TYPE}
                formName={formConfig.form}
                onChange={handleOnChange}
                oneDateTypeFieldName={ONE_DATE_TYPE}
                oneDateValueFieldNames={{
                    [ON]: ON,
                    [AFTER]: AFTER,
                    [BEFORE]: BEFORE,
                }}
                parseDate={parseDate}
                rangeDateValueFieldNames={{
                    [END]: END,
                    [START]: START,
                }}
            />
        </FilterGroup>
    );
};

RequisitionDashboardDateFilter.propTypes = {
    dropdown: PropTypes.object,
};
