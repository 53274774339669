import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { fieldNames } from './constants';
import { getInvitedUsersSelectOptions } from '../../../../selectors';
import { SearchSelect, SearchSelectUserOption, Toggle } from '../../../../../components';
import { getIsGovernmentUsingFMS } from '../../../../../selectors/govApp';

const { ALLOW_OVER_BUDGET, CREATOR_IDS, LEADER_IDS, REQUIRE_OVER_BUDGET_NOTE } = fieldNames;

export const RequisitionGroupCreatorsSection = ({
    allowOverBudget,
    creatorIds,
    disabled,
    showFormValidation,
}) => {
    const hasFMS = useSelector(getIsGovernmentUsingFMS);
    const userSelectOptions = useSelector(getInvitedUsersSelectOptions);
    const leaderUserSelectOptions = useMemo(() => {
        const creatorIdsSet = new Set(creatorIds);
        return userSelectOptions.filter(({ value }) => creatorIdsSet.has(value));
    }, [creatorIds]);

    return (
        <>
            <Field
                closeMenuOnSelect={false}
                component={SearchSelect}
                components={{
                    Option: SearchSelectUserOption,
                }}
                disabled={disabled}
                help="Users who can create requisitions that use this requisition group"
                isMulti
                isMultiSimpleValue
                label="Creators of Requisitions"
                name={CREATOR_IDS}
                options={userSelectOptions}
                placeholder="Select requisition creators"
                showInviteUserHelp
                showValidation={showFormValidation}
            />
            {hasFMS && (
                <>
                    <Field
                        component={Toggle}
                        disabled={disabled}
                        label="Allow creators to submit requests that fail budget check"
                        labelInline
                        name={ALLOW_OVER_BUDGET}
                        qaTag="requisitionGroupForm-allowOverBudget"
                        tooltip="When enabled, creators can submit requests even when the budget check fails."
                    />
                    {allowOverBudget && (
                        <Field
                            component={Toggle}
                            disabled={disabled}
                            label="Require over budget justification"
                            labelInline
                            name={REQUIRE_OVER_BUDGET_NOTE}
                            qaTag="requisitionGroupForm-requireOverBudgetNote"
                            tooltip="Require creators to provide justification for overriding a budget check failure (optional otherwise)"
                        />
                    )}
                </>
            )}
            <Field
                closeMenuOnSelect={false}
                component={SearchSelect}
                components={{
                    Option: SearchSelectUserOption,
                }}
                disabled={disabled}
                help="Leaders can view all the requisitions created from this group. Only group creators may be designated as leaders"
                isMulti
                isMultiSimpleValue
                label="Group Leaders (optional)"
                name={LEADER_IDS}
                options={leaderUserSelectOptions}
                placeholder="Select group leaders"
                showValidation={showFormValidation}
            />
        </>
    );
};

RequisitionGroupCreatorsSection.propTypes = {
    allowOverBudget: PropTypes.bool,
    creatorIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    disabled: PropTypes.bool,
    showFormValidation: PropTypes.bool,
};
