import { contactFields } from '../../../constants/contactFields';
import { mappedProcurementContactFields } from '../../../../../shared_config/contacts';
import { listToDict } from '../../../../../shared_config/helpers';

export const fields = [
    ...contactFields,
    ...Object.values(mappedProcurementContactFields),
    'allowsProxyBidding',
    'auctionMaxFractionDigits',
    'budget',
    'categories',
    'contact_id',
    'department_id',
    'departmentName',
    'departmentHead',
    'departmentHeadTitle',
    'financialId',
    'isEmergency',
    'procurement_contact_id',
    'requisitionIdentifier',
    'title',
];
export const fieldNames = listToDict(fields);

export const budgetFields = ['amount', 'description', 'identifier'];
export const budgetFieldNames = listToDict(budgetFields);

export const auctionMaxFractionDigitsOptions = [
    { label: '$1.00', value: 0 },
    { label: '$0.01', value: 2 },
    { label: '$0.001', value: 3 },
    { label: '$0.0001', value: 4 },
];
