import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { ResubmitActionPanel } from './ResubmitActionPanel';
import { ReviewerActionPanel } from './ReviewerActionPanel';
import { getRequisitionCurrentStep } from '../../../../../selectors/govApp';
import { isRequisitionDraft } from '../../../../../../../shared_config/requisitions';

export const ActionPanel = ({ disabled, requisition, reviewSequenceSteps }) => {
    const currentStep = useSelector(getRequisitionCurrentStep);

    const sharedProps = {
        disabled,
        requisition,
    };

    if (isRequisitionDraft(requisition.status)) {
        return <ResubmitActionPanel {...sharedProps} />;
    }

    if (currentStep) {
        return (
            <ReviewerActionPanel
                {...sharedProps}
                currentStep={currentStep}
                reviewSequenceSteps={reviewSequenceSteps}
            />
        );
    }

    return null;
};

ActionPanel.propTypes = {
    disabled: PropTypes.bool,
    requisition: PropTypes.shape({
        status: PropTypes.number.isRequired,
    }).isRequired,
    reviewSequenceSteps: PropTypes.array.isRequired,
};
