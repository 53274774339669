import PropTypes from 'prop-types';
import React from 'react';
import { FieldArray } from 'redux-form';
import { useSelector } from 'react-redux';

import { AccountFields } from './AccountFields';
import { priceItemFieldNames } from '../../../../../../constants';
import {
    getGovernmentReqSetting,
    getIsRequisitionUsingFMS,
} from '../../../../../../../../selectors/govApp';
import { showAccountInformationOptionsValues } from '../../../../../../../../../../shared_config/requisitions';

const { ACCOUNT_SPLIT_PRICE_ITEMS } = priceItemFieldNames;

export const AccountSplit = ({
    change,
    disabled,
    fieldName,
    isDraft,
    extendedPrice,
    showFormValidation,
    priceItemIndex,
}) => {
    const styles = require('./index.scss');

    const hasFMS = useSelector(getIsRequisitionUsingFMS);
    const { useBudgetGroup, showAccountInformation } = useSelector(getGovernmentReqSetting);

    const level = useBudgetGroup ? 'Group' : 'Account';

    return (
        <div className={styles.budgetCheck}>
            <div className={styles.header}>
                <div className={styles.emptyHeader} />
                <div className={styles.headerLabels}>
                    {hasFMS && <h5 className={styles.budgetCheckLabel}>Budget Check</h5>}
                    <h5 className={styles.accountLabel}>
                        Account{' '}
                        {(showAccountInformation === showAccountInformationOptionsValues.REQUIRED ||
                            hasFMS) &&
                            ' *'}
                    </h5>
                    <h5 className={styles.amountLabel}>Amount</h5>
                </div>
            </div>
            <div className={styles.body}>
                {hasFMS ? (
                    <div className={styles.description}>
                        <h4>
                            <strong>{level} Level</strong> Budget Check
                        </h4>
                        <div className={styles.secondaryText}>
                            Add Account(s) to check the budget for this line item.
                        </div>
                    </div>
                ) : (
                    <div className={styles.description}>
                        <h4>Account Information</h4>
                        <div className={styles.secondaryText}>
                            Add one or more Account numbers to be associated with this line item.
                        </div>
                    </div>
                )}
                <div className={styles.accountLookup}>
                    <FieldArray
                        change={change}
                        component={AccountFields}
                        disabled={disabled}
                        extendedPrice={extendedPrice}
                        fieldName={fieldName}
                        isDraft={isDraft}
                        name={`${fieldName}.${ACCOUNT_SPLIT_PRICE_ITEMS}`}
                        priceItemIndex={priceItemIndex}
                        showFormValidation={showFormValidation}
                    />
                </div>
            </div>
        </div>
    );
};

AccountSplit.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fieldName: PropTypes.string,
    extendedPrice: PropTypes.number,
    isDraft: PropTypes.bool,
    showFormValidation: PropTypes.bool,
    priceItemIndex: PropTypes.number.isRequired,
};
