import React from 'react';

import { startCase } from 'lodash';

import { CDSButton } from '../../../../../../components';
import { useQueryParam } from '../../../../../../hooks';
import { numberOfRequestsColors } from '../../Visualizations/constants';
import {
    DEFAULT_NUMBER_OF_REQUESTS_PERIODS,
    getRequisitionStatusData,
} from '../../../../../../../../shared_config/requisitions';
import { chartInteractionParamsDict } from '../../Visualizations/helpers/chartInteractions';

const { OLDER_THAN, STATUS, TYPE } = chartInteractionParamsDict;

const showChartInteractionLabel = (params) => {
    const styles = require('./index.scss');

    if (params[OLDER_THAN]) {
        const valueIndex = DEFAULT_NUMBER_OF_REQUESTS_PERIODS.findIndex(
            (period) => period === params[OLDER_THAN]
        );
        const valueColor = numberOfRequestsColors[valueIndex];

        return (
            <>
                <div
                    className={styles.requestsOlderThanCircle}
                    style={{
                        backgroundColor: valueColor,
                    }}
                />
                <h3>Requests Older Than: {params[OLDER_THAN]} Days</h3>
            </>
        );
    }

    if (params[STATUS]) {
        return <h3>Requests In {getRequisitionStatusData(params[STATUS]).longName} Status</h3>;
    }

    if (params[TYPE]) {
        return <h3>{startCase(params[TYPE])} Requests</h3>;
    }

    return null;
};

export const ChartSelection = () => {
    const styles = require('./index.scss');

    const [params, , setMultipleQueryParams] = useQueryParam();

    const closeChartSelection = () => {
        setMultipleQueryParams({
            [OLDER_THAN]: null,
            [STATUS]: null,
            [TYPE]: null,
        });
    };

    return (
        <div className={styles.requestsOlderThanDataContainer}>
            {showChartInteractionLabel(params)}
            <CDSButton
                className={styles.closeChartSelectionBtn}
                onClick={closeChartSelection}
                variant="secondary"
            >
                <i className="fa fa-close" />
                Close Chart Selection
            </CDSButton>
        </div>
    );
};
