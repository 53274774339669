import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams, createSearchParams, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { LoadingSpinner } from '../../components';
import { tokenLogin } from '../../actions/auth';
import { userStatusTypes } from '../../../../shared_config/users';

const { RESET } = userStatusTypes;

export const RequireTokenResetLogin = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const query = Object.fromEntries(searchParams);
    const error = useSelector((state) => state.auth.get('tokenLoginError'));
    const user = useSelector((state) => state.auth.get('user'));

    const checkToken = async () => {
        dispatch(tokenLogin(query.id, query.token)).then(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        if (!query?.token || !query?.id) {
            return navigate('/not-found', { replace: true });
        }

        checkToken();
    }, []);

    if (loading) {
        return <LoadingSpinner noPadding />;
    }

    // if we got here the checkToken request finished
    if (error) {
        return (
            <Navigate
                replace
                to={{
                    pathname: '/not-found',
                    search: createSearchParams({ message: error.message }).toString(),
                }}
            />
        );
    }

    if (!user || user.get('status') !== RESET) {
        return <Navigate replace to="/not-found" />;
    }

    return children;
};

RequireTokenResetLogin.propTypes = {
    children: PropTypes.node.isRequired,
};
