import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { TextareaAutosize as MuiTextareaAutosize } from '@og-pro/ui';

import { FLAGS, useFlags } from '../../lib/launchdarkly';

const propTypes = {
    minRows: PropTypes.number,
};

export const Textarea = (props) => {
    return useFlags(FLAGS.ENABLE_MUI_TEXTAREA_AUTOSIZE) ? (
        <TextareaNext {...props} />
    ) : (
        <TextareaLegacy {...props} />
    );
};

const TextareaNext = ({ minRows = 4, ...props }) => {
    return <MuiTextareaAutosize minRows={minRows} {...props} />;
};

Textarea.propTypes = propTypes;
TextareaNext.propTypes = propTypes;

// TODO: Remove this component once we have enabled the flag for all users
class TextareaLegacy extends PureComponent {
    static propTypes = {
        minRows: PropTypes.number,
    };

    static defaultProps = {
        minRows: 4,
    };

    componentDidMount() {
        // Corrects the initial height of the textarea
        // https://github.com/andreypopp/react-textarea-autosize/issues/93#issuecomment-242477519
        setTimeout(() => {
            // Appears that text area can be undefined in some cases:
            // https://rollbar.com/govlist/Govlist-Client/items/134/
            if (this.textarea) {
                return this.textarea._resizeComponent(); // eslint-disable-line no-underscore-dangle
            }
        }, 1);
    }

    setRef = (ref) => {
        this.textarea = ref;
    };

    render() {
        return (
            <TextareaAutosize ref={this.setRef} style={{ overflow: 'hidden' }} {...this.props} />
        );
    }
}
