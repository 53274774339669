import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { RecordTable } from '..';
import { recentlyClosedTableColumnDef } from '../constants';
import { getActiveRetentionCodes } from '../../../selectors';
import { getPoliciesAssignedJS, getRecentlyClosedJS } from '../../selectors';
import { LoadingSpinner } from '../../../../../components';
import { assignPolicies } from '../../../../../actions/projects';

const mapStateToProps = (state) => {
    return {
        assigningPolicies: state.projects.get('assigningPolicies'),
        policiesAssigned: getPoliciesAssignedJS(state),
        loadingRecentlyClosed: state.projects.get('loadingRecentlyClosed'),
        policies: getActiveRetentionCodes(state),
        recentlyClosed: getRecentlyClosedJS(state),
    };
};

const ConnectedRecordTableRecentlyClosed = ({
    assigningPolicies,
    policiesAssigned,
    loadingRecentlyClosed,
    policies,
    recentlyClosed,
}) => {
    const dispatch = useDispatch();
    const [changedRows, setChangedRows] = useState([]);
    const [errors, setErrors] = useState([]);
    const [filter, setFilter] = useState();
    const [selectedRows, setSelectedRows] = useState([]);
    const policyWarning =
        "Please apply a retention policy to each of these projects who's contract end date has passed.";

    useEffect(() => {
        setChangedRows([]);
    }, [recentlyClosed]);

    useEffect(() => {
        setErrors(policiesAssigned.filter((assignment) => assignment.error));
    }, [policiesAssigned]);

    const handleApplyPolicies = () => {
        dispatch(assignPolicies(changedRows));
    };

    const handleCellValueChanged = (event) => {
        let previouslyUpdatedRows = changedRows;
        const newestUpdatedRow = event.data;

        const updatedRowIds = changedRows.map((changedRow) => changedRow.id);
        if (updatedRowIds.includes(newestUpdatedRow.id)) {
            previouslyUpdatedRows = changedRows.filter(
                (previouslyUpdatedRow) => previouslyUpdatedRow.id !== newestUpdatedRow.id
            );
        }

        setChangedRows([...previouslyUpdatedRows, newestUpdatedRow]);
    };

    const handleSelectPolicy = (policyId) => {
        const rowsWithSelectedPolicy = selectedRows.map((row) => ({
            ...row,
            retentionPolicy: {
                ...row.retentionPolicy,
                selectedPolicy: policyId,
            },
        }));
        setSelectedRows([]);
        setChangedRows([...rowsWithSelectedPolicy]);
    };

    const rows = recentlyClosed
        .filter((record) => {
            if (filter) {
                return filter === record.recordType;
            }
            return record;
        })
        .map((record) => {
            const closeDate = record.recordCloseDate || record.endDate;
            const rowError = errors.find((error) => error.id === record.id);
            let retentionPolicy = {
                selectedPolicy: record.retention_code_id,
                options: policies,
                closeDate,
                rowError,
            };
            const updatedIds = changedRows.map((changedRow) => changedRow.id);
            if (updatedIds.includes(record.id)) {
                retentionPolicy = changedRows.find(
                    (changedRow) => changedRow.id === record.id
                ).retentionPolicy;
            }
            return {
                id: record.id,
                title: {
                    title: record.title,
                    projectId: record.id,
                    financialId: record.financialId || record.contractId,
                    governmentId: record.government_id,
                    recordType: record.recordType,
                },
                department: record.departmentName,
                contact: record.contactDisplayName,
                lastUpdated: {
                    date: moment(record.updated_at).format('lll'),
                    rawDate: record.updated_at,
                    reason: record.closeOutReason || record.status,
                    recordType: record.recordType,
                },
                retentionPolicy,
                recordType: record.recordType,
            };
        });

    if (loadingRecentlyClosed && rows.length === 0) {
        return <LoadingSpinner />;
    }

    return (
        <RecordTable
            columnDef={recentlyClosedTableColumnDef}
            disableApplyPolicies={changedRows.length === 0}
            errors={errors}
            filter={filter}
            handleApplyPolicies={handleApplyPolicies}
            handleCellValueChanged={handleCellValueChanged}
            handleSelectPolicy={handleSelectPolicy}
            loading={assigningPolicies || loadingRecentlyClosed}
            policies={policies}
            policyWarning={policyWarning}
            rows={rows}
            selectedRows={selectedRows}
            setFilter={setFilter}
            setSelectedRows={setSelectedRows}
            showApplyPolicies
            showPolicies={selectedRows.length > 0}
        />
    );
};

ConnectedRecordTableRecentlyClosed.propTypes = {
    assigningPolicies: PropTypes.bool.isRequired,
    policiesAssigned: PropTypes.array.isRequired,
    loadingRecentlyClosed: PropTypes.bool.isRequired,
    policies: PropTypes.array.isRequired,
    recentlyClosed: PropTypes.array.isRequired,
};

export const RecordTableRecentlyClosed = connect(mapStateToProps)(
    ConnectedRecordTableRecentlyClosed
);
