import { signaturesBlocksToColumns } from '../../helpers/signatures';

/**
 * Builds a criteria object with keys representing the section/subsection the
 * criteria belongs to. Makes it easier and more efficient to update and
 * validate each section's criteria as an individual array rather than as a
 * collection. Criteria object gets collapsed back to a single array before
 * the data is saved.
 *
 * @param {object} project
 * @return {object}
 */
export function buildCriteriaMap(project) {
    const criteriaMap = (project.criteria || []).reduce((map, criteria) => {
        const criteriaKey = `${criteria.project_section_id}_${criteria.project_subsection_id}`;
        if (!map[criteriaKey]) {
            map[criteriaKey] = [];
        }

        map[criteriaKey].push(criteria);

        return map;
    }, {});

    return {
        ...project,
        criteria: criteriaMap,
    };
}

/**
 * Builds a signture object with keys representing the section/subsection the
 * signature belongs to. Gets collapsed back to a single array before
 * the data is saved.
 *
 * @param {object} project
 * @return {object}
 */
export function buildSignatureMap(project) {
    const signatureMap = (project.signatures || []).reduce((acc, signature) => {
        const key = `${signature.project_section_id}_${signature.project_subsection_id}`;

        return {
            ...acc,
            [key]: signaturesBlocksToColumns(signature),
        };
    }, {});

    return {
        ...project,
        signatures: signatureMap,
    };
}

/**
 * Builds a sectionDescription object with keys representing the section/subsection the
 * sectionDescription belongs to. Makes it easier and more efficient to update and
 * validate each section's description. SectionDescription object gets collapsed back to a single
 * array before the data is saved.
 *
 * @param {object} project
 * @return {object}
 */
export function buildSectionDescriptionMap(project) {
    const { sectionDescriptions } = project;

    const mappedSectionDescriptions = (sectionDescriptions || []).reduce((map, sectionDesc) => {
        const key = `${sectionDesc.project_section_id}_${sectionDesc.project_subsection_id}`;
        map[key] = sectionDesc;
        return map;
    }, {});

    return {
        ...project,
        sectionDescriptions: mappedSectionDescriptions,
    };
}
