import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Provider } from 'react-redux';
import { MemoryRouter } from 'react-router-dom';

import { ProjectDocumentReadOnly, ProjectTableOfContentsDocx } from '../components';
import { Timeline } from '../components/GovApp/ProjectDetail/Introduction/Timeline';
import { normalizeProject } from '../reducers/utils';
import { getStore } from '../store';
import { DocxHeadingFormattersContext } from '../components/contexts';
import { ProposalDocumentReport } from '../containers/GovApp/ReportsModal/reports';
import { attachmentObject, mapProposalDocuments } from '../containers/helpers';
import { generateCompleteProposal } from '../containers/selectors';

/*
 * NOTE: These functions should be kept in a separate file because they use
 * React rendering methods that require the client to be loaded.
 */

/**
 * Render a project as static HTML.
 * @param {Object} projectData The project to render
 * @param {Object} [props] Options to pass to component
 * @param {Object} [props.isDocx] If we are rendering for the .docx format
 * @param {Object} [opts] Extra options
 * @param {Object} [opts.docxHeadingFormatters] Docx heading formatters values
 * @param {number} [opts.sectionId] Section ID to use for single section export
 * @param {Object} [opts.storeOpts] Options to pass to the redux store
 * @return {string} The static HTML representing the project
 */
export function renderProjectStaticMarkup(projectData, props, opts = {}) {
    if (!projectData) return '<div></div>';

    const project = normalizeProject(projectData);
    const El = React.createElement(ProjectDocumentReadOnly, {
        project,
        ...props,
        ...opts,
    });

    const store = getStore(opts.storeOpts);

    const tree = (
        <Provider key="provider" store={store}>
            <MemoryRouter>
                <DocxHeadingFormattersContext.Provider
                    value={{
                        usesDocxHeadingFormatters: projectData.government.usesDocxHeadingFormatters,
                        docxHeadingFormatters: opts.docxHeadingFormatters || {},
                    }}
                >
                    {El}
                </DocxHeadingFormattersContext.Provider>
            </MemoryRouter>
        </Provider>
    );

    return renderToStaticMarkup(tree);
}

/**
 * Render a project timeline as static HTML.
 * @param {Object} projectData The project data to use for timeline render
 * @return {string} The static HTML representing the project timeline
 */
export function renderProjectTimelineStaticMarkup(projectData) {
    if (!projectData) {
        return '<div></div>';
    }

    const project = normalizeProject(projectData);
    const El = React.createElement(Timeline, {
        isDocx: true,
        project,
        timezone: project.government.organization.timezone,
    });

    return renderToStaticMarkup(El);
}

/**
 * Render a table of contents as static HTML.
 * @param {Object} projectData The project data to use for table of contents render
 * @param {Object} [opts] Options to pass to component
 * @param {Object} [opts.storeOpts] Options to pass to the redux store
 * @return {string} The static HTML representing the table of contents
 */
export function renderTableOfContentsStaticMarkup(projectData, opts = {}) {
    if (!projectData) {
        return '<div></div>';
    }

    const project = normalizeProject(projectData);
    const El = React.createElement(ProjectTableOfContentsDocx, { project });
    const store = getStore(opts.storeOpts);

    const tree = (
        <Provider key="provider" store={store}>
            <MemoryRouter>{El}</MemoryRouter>
        </Provider>
    );

    return renderToStaticMarkup(tree);
}

const getFormattedProposalForReport = (project, proposal) => {
    const proposalAttachmentObject = proposal.attachments.reduce(attachmentObject, {});
    const proposalDocs = mapProposalDocuments(project.proposalDocuments, proposalAttachmentObject);
    return generateCompleteProposal(proposal, project, proposalDocs);
};

/**
 * Render a proposal document report as static HTML.
 * @param {Object} project Project data to use for proposal details
 * @param {Object} rawProposal Raw proposal data to use for proposal details
 * @param {Object} [opts={}] Options to pass to component
 * @param {Object} [opts.storeOpts] Options to pass to the redux store
 * @return {string} The static HTML representing the proposal details report
 */
export const renderProposalDocumentReportStaticMarkup = (project, rawProposal, opts = {}) => {
    const proposal = getFormattedProposalForReport(project, rawProposal);
    const El = React.createElement(ProposalDocumentReport, { project, proposal });
    const store = getStore(opts.storeOpts);
    const tree = (
        <Provider key="provider" store={store}>
            <MemoryRouter>{El}</MemoryRouter>
        </Provider>
    );
    return renderToStaticMarkup(tree);
};
