import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { pick } from 'lodash';
import classnames from 'classnames';
import { Box, Typography } from '@og-pro/ui';

import { CertificationFormFields } from './FormFields';
import { certificationFieldNames } from '../../../../../constants';
import { updateOrganization } from '../../../../../../../../actions/admin';
import {
    cancelAddVendorCertification,
    deleteVendorCertification,
    updateVendorCertification,
} from '../../../../../../../../actions/adminVendor';
import { CDSButton } from '../../../../../../../../components';
import { getCertificationTitle } from '../../../../../../../../../../shared_config/certifications';

const formConfig = {
    enableReinitialize: true,
};

const {
    CERTIFICATE_NUMBER,
    CERTIFICATION_ID,
    CERTIFYING_AGENCY,
    ETHNICITY,
    EXPIRATION_DATE,
    ID,
    ISSUED_DATE,
    TITLE,
    VENDOR_CERTIFICATION,
} = certificationFieldNames;

const ConnectedCertificationForm = ({
    certification,
    change,
    form,
    handleSubmit,
    pristine,
    reset,
    setEditing,
    setShowAddCertification,
    showAddCertification,
    user,
}) => {
    const dispatch = useDispatch();
    const styles = require('../../index.scss');

    const certificationFormSelector = formValueSelector(form);
    const certificationId = useSelector((state) =>
        certificationFormSelector(state, `${VENDOR_CERTIFICATION}.${CERTIFICATION_ID}`)
    );
    const ethnicity = useSelector((state) =>
        certificationFormSelector(state, `${VENDOR_CERTIFICATION}.${ETHNICITY}`)
    );

    const handleSave = (formData) => {
        const certificationData = pick(formData.vendorCertification, [
            CERTIFICATE_NUMBER,
            CERTIFYING_AGENCY,
            ETHNICITY,
            EXPIRATION_DATE,
            ID,
            ISSUED_DATE,
            TITLE,
        ]);

        if (!certificationData.title) {
            certificationData.title = getCertificationTitle(
                formData.vendorCertification.certification_id
            );
        }

        dispatch(
            updateVendorCertification(
                user.vendor.id,
                formData.vendorCertification.certification_id,
                certificationData
            )
        );

        // adding a certification should clear the noDbeDesignations flag
        if (user.vendor.noDbeDesignations) {
            dispatch(
                updateOrganization(
                    user.organization_id,
                    {
                        vendor: { noDbeDesignations: false },
                    },
                    {
                        hideToast: true,
                    }
                )
            );
        }

        setEditing(null);
        setShowAddCertification(true);
    };

    const handleCancelAddCertification = () => {
        dispatch(cancelAddVendorCertification());
        setShowAddCertification(true);
    };

    const renderAddCertification = () => {
        return (
            <Box
                alignItems="center"
                className={classnames(styles.card, styles.editing)}
                display="flex"
                p={2}
            >
                <div>
                    <Typography className={styles.headingText} variant="h4">
                        Add Certification
                    </Typography>
                    <div>
                        If you do not have all of the information for this certification, you can
                        always come back to edit it later.
                    </div>
                </div>
                <CertificationFormFields
                    certificationId={certificationId}
                    change={change}
                    ethnicity={ethnicity}
                />
                <Box
                    alignItems="flex-end"
                    alignSelf="stretch"
                    className={styles.buttons}
                    display="flex"
                    justifyContent="flex-end"
                >
                    <CDSButton
                        onClick={handleCancelAddCertification}
                        qaTag="addCertification-cancel"
                        variant="secondary"
                    >
                        Cancel
                    </CDSButton>
                    {certificationId && (
                        <CDSButton
                            bsStyle="opengov"
                            onClick={handleSubmit(handleSave)}
                            qaTag="addCertification-save"
                        >
                            Add Certification
                        </CDSButton>
                    )}
                </Box>
            </Box>
        );
    };

    const renderEditCertification = () => {
        return (
            <Box
                alignItems="center"
                className={classnames(styles.card, styles.editing)}
                display="flex"
                p={2}
            >
                <div>
                    <Typography className={styles.headingText} variant="h4">
                        {certification.title}
                    </Typography>
                </div>
                <CertificationFormFields
                    certificationId={certificationId}
                    change={change}
                    editing
                    ethnicity={ethnicity}
                />
                <Box
                    alignItems="center"
                    alignSelf="stretch"
                    display="flex"
                    justifyContent="space-between"
                >
                    <CDSButton
                        className={styles.deleteButton}
                        onClick={() => {
                            dispatch(
                                deleteVendorCertification(
                                    user.vendor.id,
                                    certification.vendorCertification.id
                                )
                            );
                            setEditing(null);
                            setShowAddCertification(true);
                        }}
                        qaTag="editCertification-delete"
                        size="small"
                        variant="text"
                    >
                        <i className="fa fa-trash" />
                        Remove Certification
                    </CDSButton>
                    <Box className={styles.buttons} display="flex">
                        <CDSButton
                            onClick={() => {
                                setShowAddCertification(true);
                                setEditing(null);
                                reset();
                            }}
                            qaTag="editCertification-cancel"
                            variant="secondary"
                        >
                            Cancel
                        </CDSButton>
                        <CDSButton
                            disabled={pristine}
                            onClick={handleSubmit(handleSave)}
                            qaTag="editCertification-save"
                        >
                            Save Changes
                        </CDSButton>
                    </Box>
                </Box>
            </Box>
        );
    };

    if (showAddCertification) {
        return renderAddCertification();
    }

    return renderEditCertification();
};

export const CertificationForm = reduxForm(formConfig)(ConnectedCertificationForm);
