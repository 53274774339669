import { createSelector } from 'reselect';
import { findLast, get } from 'lodash';
import qs from 'qs';

import { getUserJS } from '../../selectors';
import { filterTypesDict } from '../../../../../shared_config/filters';
import {
    DEFAULT_PAGE_SIZE_LIMIT,
    contractFilterTypesDict,
} from '../../../../../shared_config/contracts';

const { CONTRACT_TABLE_LAYOUT_SETTINGS } = filterTypesDict;

// There should only ever be one, but this gets the last one just to be sure
export const getLayoutFilter = createSelector([getUserJS], (user) => {
    return findLast(user?.filters, (f) => f.type === CONTRACT_TABLE_LAYOUT_SETTINGS);
});

export const getUserDefaultFilters = createSelector([getLayoutFilter], (layoutFilter) => {
    return get(layoutFilter, 'data.defaultFilters', []);
});

export const getUserDefaultSort = createSelector([getLayoutFilter], (layoutFilter) => {
    return get(layoutFilter, 'data.defaultSorted', []);
});

export const getUserDefaultFullWidth = createSelector([getLayoutFilter], (layoutFilter) => {
    return get(layoutFilter, 'data.fullWidth', false);
});

export const getUserDefaultPageSize = createSelector([getLayoutFilter], (layoutFilter) => {
    return get(layoutFilter, 'data.pageSize', undefined);
});

export const getUserLastFilters = createSelector([getUserJS], (user) => {
    if (user.lastFilter && user.lastFilter.contract) {
        return user.lastFilter.contract;
    }

    return null;
});

export const getLoadContractOptions = createSelector(
    [
        getUserJS,
        (state, params) => params,
        getUserDefaultFilters,
        getUserDefaultSort,
        getUserDefaultPageSize,
        getUserLastFilters,
    ],
    (user, params, userDefaultFilters, userDefaultSort, userDefaultPageSize, userLastFilters) => {
        const options = {};
        const parsedParams = qs.parse(params, { arrayLimit: 100 });
        const hasNoContractSortFiltersSearchQuery =
            !parsedParams.filters && !parsedParams.sort && !parsedParams.quickSearchQuery;

        if (params.ids) {
            // Looks up IDs when a user clicks a chart segment from the Contracts Dashboard
            // Additional filtering is disabled on the UI
            options.filters = [
                {
                    type: contractFilterTypesDict.IDS,
                    value: params.ids.split(',').map((char) => parseInt(char, 10)),
                },
            ];
            options.limit = userDefaultPageSize || DEFAULT_PAGE_SIZE_LIMIT;
        } else if (user.lastFilter?.contract && hasNoContractSortFiltersSearchQuery) {
            options.filters = userLastFilters.filters || [];
            options.sort = userLastFilters.sort || [];
            options.quickSearchQuery = userLastFilters.quickSearchQuery || '';
            options.limit = userLastFilters.limit || 20;
        } else if (!user.lastFilter?.contract && hasNoContractSortFiltersSearchQuery) {
            // Applies default filters and sort from the user's Saved Layout when:
            // A. The user navigates to the page for the first time OR
            // B. The user refreshes from another route and navigates back to Contracts
            options.filters = userDefaultFilters;
            options.sort = userDefaultSort;
            options.limit = userDefaultPageSize || DEFAULT_PAGE_SIZE_LIMIT;
        } else if (parsedParams.filters || parsedParams.sort || parsedParams.quickSearchQuery) {
            // Applies filters and sort based on the query params
            if (parsedParams.filters) {
                options.filters = parsedParams.filters;
            }

            if (parsedParams.sort) {
                options.sort = parsedParams.sort;
            }

            if (parsedParams.quickSearchQuery) {
                options.quickSearchQuery = parsedParams.quickSearchQuery;
            }

            if (parsedParams.limit) {
                options.limit = parsedParams.limit;
            }

            if (parsedParams.page) {
                options.page = parsedParams.page;
            }
        }

        return options;
    }
);
