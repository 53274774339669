import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ContractMilestoneNotificationsForm } from '../../../../../components';

export class ContractMilestone extends PureComponent {
    static propTypes = {
        contract: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        contractMilestone: PropTypes.shape({
            date: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            notifications: PropTypes.array.isRequired,
        }).isRequired,
        isEditor: PropTypes.bool,
        reset: PropTypes.func.isRequired,
        timezone: PropTypes.string.isRequired,
        updateContractMilestoneNotifications: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    handleContractMilestoneNotificationsFormSubmit = (values) => {
        const {
            contract: { id: contractId },
            contractMilestone: { id: contractMilestoneId },
        } = this.props;

        this.props.updateContractMilestoneNotifications(
            contractId,
            contractMilestoneId,
            values,
            () => this.props.reset(`contractMilestoneNotificationsForm:${contractMilestoneId}`)
        );
    };

    render() {
        const {
            contractMilestone,
            contractMilestone: { date, id: contractMilestoneId, name, notifications },
            isEditor,
            timezone,
        } = this.props;

        return (
            <div>
                <div className={this.styles.milestoneDate}>
                    {moment.tz(date, timezone).format('MMM D, YYYY').toUpperCase()}
                </div>
                <h4 className={this.styles.milestoneTitle}>{name}</h4>
                <ContractMilestoneNotificationsForm
                    contractMilestone={contractMilestone}
                    form={`contractMilestoneNotificationsForm:${contractMilestoneId}`}
                    initialValues={{ notifications }}
                    isEditor={isEditor}
                    onSubmit={this.handleContractMilestoneNotificationsFormSubmit}
                />
            </div>
        );
    }
}
