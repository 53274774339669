import { listToDict } from '../../../../../../shared_config/helpers';

const SPENT = 'spent';
const COMMITTED = 'committed';
const IN_PROCESS = 'inProcess';
const AVAILABLE = 'available';
const OVER_BUDGET = 'overBudget';
const TOTAL = 'total';

export const visualizationColors = {
    transparent: 'rgba(0, 0, 0, 0.0)',
    gray: '#cdd2d6',
    red: '#d16e78',
    [SPENT]: '#3C964B',
    [COMMITTED]: '#ED8A5F',
    [IN_PROCESS]: '#885F99',
    budgetCheckPass: '#6A92D4',
    budgetCheckFail: '#CC5E69',
    separator: '#ccd6eb',
    text: '#131517',
};

export const visualizationItems = [SPENT, COMMITTED, IN_PROCESS, AVAILABLE, OVER_BUDGET, TOTAL];

export const visualizationItemsDict = listToDict(visualizationItems);

export const stripesPattern = {
    path: {
        d: 'M 0 10 L 10 0 M -1 1 L 1 -1 M 9 11 L 11 9',
        strokeWidth: 3.5,
    },
    width: 10,
    height: 10,
    opacity: 0.7,
    color: visualizationColors.gray,
};
