import { createSelector } from 'reselect';

import {
    hasDocBuilderSubscription,
    hasEvaluationSubscription,
    hasSourcingSubscription,
} from '../../containers/GovApp/selectors';
import {
    projectStatusFilterTypesDict,
    projectStatusesDict,
} from '../../../../shared_config/projects';

const { ALL, FINAL_DOCUMENT } = projectStatusFilterTypesDict;

const {
    REQUEST_DRAFT,
    REQUEST_REVIEW,
    DRAFT,
    REVIEW,
    FINAL,
    POST_PENDING,
    OPEN,
    AUCTION_PENDING,
    REVERSE_AUCTION,
    PENDING,
    AWARD_PENDING,
    EVALUATION,
    CLOSED,
} = projectStatusesDict;

export const getProjectStatusOptions = createSelector(
    [
        (state, props) => props.isDocBuilder,
        (state, props) => props.isIntake,
        hasDocBuilderSubscription,
        hasSourcingSubscription,
        hasEvaluationSubscription,
    ],
    (isDocBuilder, isIntake, hasDocBuilder, hasSourcing, hasEvaluation) => {
        if (isDocBuilder) {
            return [
                { label: 'All Statuses', value: ALL },
                { label: 'Draft', value: DRAFT },
                { label: 'Review', value: REVIEW },
                { label: 'Final', value: FINAL_DOCUMENT },
                { label: 'Closed', value: CLOSED },
            ];
        }
        if (isIntake) {
            return [
                { label: 'All Statuses', value: ALL },
                { label: 'Draft', value: REQUEST_DRAFT },
                { label: 'Review', value: REQUEST_REVIEW },
                { label: 'Closed', value: CLOSED },
            ];
        }

        const projectNavItems = [];
        if (hasDocBuilder) {
            projectNavItems.push(
                { label: 'Draft', value: DRAFT },
                { label: 'Review', value: REVIEW },
                { label: 'Final', value: FINAL }
            );
        }
        if (hasSourcing) {
            projectNavItems.push(
                { label: 'Post Pending', value: POST_PENDING },
                { label: 'Open', value: OPEN },
                { label: 'Auction Pending', value: AUCTION_PENDING },
                { label: 'Reverse Auction', value: REVERSE_AUCTION },
                { label: 'Pending', value: PENDING }
            );
        }
        if (hasEvaluation) {
            projectNavItems.push(
                { label: 'Evaluation', value: EVALUATION },
                { label: 'Award Pending', value: AWARD_PENDING }
            );
        }

        return [
            { label: 'All Statuses', value: ALL },
            ...projectNavItems,
            { label: 'Closed', value: CLOSED },
        ];
    }
);
