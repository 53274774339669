import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { DraftReviewToggle, Label } from '..';
import { trackEvent } from '../../helpers';

export const ProjectModeToggle = (props) => {
    const navigate = useNavigate();

    const toggleHandler = (e) => {
        const switchToDraftMode = e.target.checked;

        const { writingPath, reviewPath } = props;
        const currentRoute = switchToDraftMode ? writingPath : reviewPath;
        let nextRoute = switchToDraftMode ? reviewPath : writingPath;

        trackEvent('Project mode toggle', { switchToDraftMode, currentRoute, nextRoute });

        if (props.section && !nextRoute.includes('document-editor')) {
            nextRoute += `?section=${props.section}`;
        }

        return navigate(nextRoute);
    };

    const styles = require('./ProjectModeToggle.scss');

    return (
        <div className={props.className}>
            <Label
                className={`${styles.text} hidden-sm`}
                htmlFor="DraftReviewToggle"
                label="Edit"
            />
            <DraftReviewToggle checked={!props.checked} onChange={toggleHandler} />

            <Label
                className={`${styles.text} hidden-sm`}
                htmlFor="DraftReviewToggle"
                label="Preview"
            />
        </div>
    );
};

ProjectModeToggle.propTypes = {
    checked: PropTypes.bool.isRequired,
    writingPath: PropTypes.string.isRequired,
    reviewPath: PropTypes.string.isRequired,
    className: PropTypes.string,
    section: PropTypes.string,
};
