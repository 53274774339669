import React from 'react';
import { Panel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { getNavItems } from './selectors';
import { getRequisitionGroupsPath } from '../selectors';
import { LinkContainer, Nav, NavItem } from '../../../../components';

export const RequisitionGroupsNav = () => {
    const params = useParams();
    const location = useLocation();
    const navItems = useSelector((state) => getNavItems(state, { location, params }));
    const requisitionGroupsPath = useSelector(() => getRequisitionGroupsPath({ location, params }));

    const styles = require('./index.scss');

    const NavItems = navItems.map(({ isActive, path, title }, index) => {
        return (
            <LinkContainer active={isActive} key={index} to={`${requisitionGroupsPath}${path}`}>
                <NavItem overrideIsActive={false} qaTag={`requisitionGroupAdminNav-${title}`}>
                    {title}
                </NavItem>
            </LinkContainer>
        );
    });

    return (
        <Panel>
            <Panel.Body>
                <Nav bsStyle="tabs" className={styles.navItems} justified>
                    {NavItems}
                </Nav>
                <Outlet />
            </Panel.Body>
        </Panel>
    );
};
