import { useEffect } from 'react';

export const useClickOutside = (ref, callback) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            // if clicked outside
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        // Add event listener on mount
        document.addEventListener('mousedown', handleClickOutside);
        // Clean up the event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
};
