import { createSelector } from 'reselect';

import { getCurrentStepUserApprovalsData } from '../../selectors';
import { isRequisitionGlobalEditorUser } from '../../../../selectors';
import { getRequisitionJS, hasRequisitionCreatorAccess } from '../../../../../selectors/govApp';
import { isRequisitionDraft } from '../../../../../../../shared_config/requisitions';

export const getIsFullyEditable = createSelector(
    [getRequisitionJS, hasRequisitionCreatorAccess],
    (requisition, isCreator) => {
        const isDraft = isRequisitionDraft(requisition.status);
        return isDraft && isCreator;
    }
);

export const getIsCurrentApprover = createSelector(
    [isRequisitionGlobalEditorUser, getCurrentStepUserApprovalsData],
    (isRequisitionGlobalEditor, userApprovalsData) => {
        return userApprovalsData.length > 0 || isRequisitionGlobalEditor;
    }
);
