import { resourceManager } from '../helpers';
import { projectEventTypesDict } from '../../../shared_config/events';

const { PUBLIC_PROJECT_VIEW } = projectEventTypesDict;

const generateRecordPublicProjectViewOptions = ({ context }) => {
    return { data: { projectId: context.projectId, type: PUBLIC_PROJECT_VIEW } };
};

export function recordPublicProjectView(projectId) {
    return resourceManager({
        method: 'post',
        url: '/events/project',
        requestOptions: generateRecordPublicProjectViewOptions,
        context: { projectId },
        onFailure: () => {}, // We don't care about errors
    });
}

export function recordPublicProjectVendorEvent(projectId, type) {
    return resourceManager({
        method: 'post',
        url: '/events/vendor',
        requestOptions: {
            data: { project_id: projectId, type },
        },
        context: { projectId, type },
        onFailure: () => {}, // We don't care about errors
    });
}
