import { contractFilterTypesDict } from '../../../../../shared_config/contracts';

const { IDS } = contractFilterTypesDict;

export const parseContractsFilters = (filters) => {
    if (!filters) {
        return [];
    }

    return filters.map((filter) => {
        switch (filter.type) {
            // IDS filter needs to be parsed to an array of integers because the filter value is an object of strings
            case IDS:
                return {
                    ...filter,
                    value: Object.values(filter.value).map((id) => parseInt(id, 10)),
                };
            default:
                return filter;
        }
    });
};
