import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { loadRequisitionGroups } from '../../../../actions/requisitionGroups';
import { LoadingError, LoadingSpinner } from '../../../../components';
import { generateOptionIcons } from '../../../../components/TemplateAdmin/CreateFromTemplateModal/TemplateSelect';
import { getLiveUserRequisitionGroups } from '../../../../selectors/govApp';

export const ReviewGroupSelect = ({ clickHandler }) => {
    const styles = require('./shared.scss');

    const dispatch = useDispatch();

    const loadedGroups = useSelector((state) => state.requisitionGroups.get('loadedGroups'));

    useEffect(() => {
        if (!loadedGroups) {
            dispatch(loadRequisitionGroups());
        }
    }, [loadedGroups]);

    const loading = useSelector((state) => state.requisitionGroups.get('loadingGroups'));
    const loadError = useSelector((state) => state.requisitionGroups.get('loadGroupsError'));
    const reviewGroups = useSelector((state) => getLiveUserRequisitionGroups(state));

    function handleReviewGroupClick(reviewGroup) {
        clickHandler({ reviewGroup });
    }

    if (loading) {
        return (
            <div className={styles.loadingContainer}>
                <div className={styles.loadingBody}>
                    <LoadingSpinner className={styles.loadingReviewGroupsSpinner} />
                </div>
                <h4>Loading Review Groups...</h4>
            </div>
        );
    }

    if (loadError) {
        return <LoadingError error={loadError} />;
    }

    if (!reviewGroups.length) {
        return (
            <p>
                You cannot create requisitions because you are not a member of any Requisition
                Groups. Please contact a Requisition Admin and request to be added to a Requisition
                Group.
            </p>
        );
    }

    const ReviewGroups = reviewGroups.map((reviewGroup) => (
        <div className="col-xs-6" key={reviewGroup.id}>
            <span
                className={styles.reviewGroupIconButton}
                onClick={() => handleReviewGroupClick(reviewGroup)}
            >
                <i className={`fa fa-${reviewGroup.icon} fa-3x`} />
                <div className={styles.iconText}>{reviewGroup.name}</div>
            </span>
        </div>
    ));

    return (
        <div className={styles.projectCreateIcons}>
            <div className={styles.titleText}>Select Review Group</div>
            <div className={`row ${styles.reviewGroupIcons}`}>
                {generateOptionIcons(ReviewGroups)}
            </div>
        </div>
    );
};

ReviewGroupSelect.propTypes = {
    clickHandler: PropTypes.func.isRequired,
};
