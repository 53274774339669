import classNames from 'classnames';
import { capitalize } from 'lodash';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { BudgetChangedModalContent } from './BudgetChangedModalContent';
import { hideRequisitionBudgetCheckAlertModal } from '../../../actions/requisitions';
import { Button, LoadingError, LoadingSpinner } from '../../../components';
import {
    getRequisitionBudgetCheckAlertModal,
    getRequisitionBudgetCheckError,
    getRequisitionBudgetCheckResults,
    getRequisitionIsBudgetChecking,
} from '../../../selectors/govApp';

export const BudgetCheckAlertModal = () => {
    const dispatch = useDispatch();
    const { onContinue, onCancel, data } = useSelector(getRequisitionBudgetCheckAlertModal);

    const { actionLabel } = data;

    const isLoading = useSelector(getRequisitionIsBudgetChecking);
    const loadError = useSelector(getRequisitionBudgetCheckError);
    const budgetCheckResults = useSelector(getRequisitionBudgetCheckResults);

    const { changedAccountSplitPriceItems, hasIsOverBudgetChanged } = budgetCheckResults;
    const hasAnyBudgetInfoChanged =
        changedAccountSplitPriceItems?.length > 0 || hasIsOverBudgetChanged;

    const styles = require('./index.scss');

    const handleCancel = () => {
        onCancel();
        dispatch(hideRequisitionBudgetCheckAlertModal());
    };
    const handleContinue = () => {
        onContinue();
        dispatch(hideRequisitionBudgetCheckAlertModal());
    };

    useEffect(() => {
        // Checks are in place to ensure the budget check has completed loading without errors and
        // returned results that had no changes to the current budget status or line item amounts.
        if (
            !isLoading &&
            !loadError &&
            !hasIsOverBudgetChanged &&
            changedAccountSplitPriceItems &&
            changedAccountSplitPriceItems.length === 0
        ) {
            handleContinue();
        }
    }, [isLoading, loadError, hasIsOverBudgetChanged, changedAccountSplitPriceItems]);

    let modalTitle;
    let modalBody;
    let modalFooter;
    if (isLoading) {
        modalTitle = 'Checking For Changes';
        modalBody = <LoadingSpinner noPadding text="Performing Budget Check..." />;
    } else if (loadError) {
        modalTitle = 'Error Performing Budget Check';
        modalBody = <LoadingError error={loadError} />;
    } else if (hasAnyBudgetInfoChanged) {
        modalTitle = (
            <span>
                <i className={classNames('fa fa-warning', styles.budgetCheckAlertModalTitle)} />{' '}
                Something Has Changed
            </span>
        );
        modalBody = <BudgetChangedModalContent {...budgetCheckResults} />;
        modalFooter = true;
    }

    return (
        <Modal className={styles.budgetCheckAlertModal} onHide={handleCancel} show>
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalBody}</Modal.Body>
            {modalFooter && (
                <Modal.Footer>
                    <Button onClick={handleCancel} qaTag="budgetCheckAlarmDialog-seeWhatChanged">
                        See What&apos;s Changed
                    </Button>
                    <Button
                        bsStyle="primary"
                        onClick={handleContinue}
                        qaTag="budgetCheckAlarmDialog-continue"
                    >
                        <i className="fa fa-check" /> {capitalize(actionLabel)} & Continue
                    </Button>
                </Modal.Footer>
            )}
        </Modal>
    );
};
