import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroup, ListGroupItem, Modal } from 'react-bootstrap';

import { evaluationPhaseSetupOptions, PHASE } from './constants';
import { Button, LoadingButton } from '../../../../../components';
import { EvaluationPhaseSelectItem } from '../../../../../components/GovApp';
import { buildMap } from '../../../../../../../shared_config/helpers';

export class EvaluationPhaseCreateModal extends PureComponent {
    static propTypes = {
        createEvaluationPhase: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        project: PropTypes.object.isRequired,
        proposals: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            createError: null,
            creating: false,
            evaluationPhaseId: null,
            page: 1,
            selectedProposalIds: buildMap(props.proposals, 'id'),
            useNewScoringCriteria: null,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    getSelectedProposalIds = () => {
        const { selectedProposalIds } = this.state;

        return Object.keys(selectedProposalIds)
            .filter((id) => selectedProposalIds[id])
            .map((id) => Number.parseInt(id, 10));
    };

    createEvaluationPhase = () => {
        const { createEvaluationPhase, project, proposals } = this.props;

        const { evaluationPhaseId, selectedProposalIds, useNewScoringCriteria } = this.state;

        this.setState({ creating: true, createError: null });

        const excludedProposalIds = proposals
            .filter((proposal) => !selectedProposalIds[proposal.id])
            .map((proposal) => proposal.id);

        const data = {
            evaluationPhaseId: useNewScoringCriteria === PHASE ? evaluationPhaseId : undefined,
            excludedProposalIds,
            useNewScoringCriteria: !!useNewScoringCriteria,
        };

        return createEvaluationPhase(project, data).catch((error) => {
            this.setState({ creating: false, createError: error.message });
        });
    };

    selectAll = () => {
        const { proposals } = this.props;
        const selectedProposalIds = buildMap(proposals, 'id');
        this.setState({ selectedProposalIds });
    };

    nextPage = () => this.setState((prevState) => ({ page: prevState.page + 1 }));

    prevPage = () => this.setState((prevState) => ({ page: prevState.page - 1 }));

    toggleSelected = (proposal) => () => {
        this.setState((prevState) => {
            return {
                selectedProposalIds: {
                    ...prevState.selectedProposalIds,
                    [proposal.id]: !prevState.selectedProposalIds[proposal.id],
                },
            };
        });
    };

    unselectAll = () => this.setState({ selectedProposalIds: {} });

    renderCriteriaSelect() {
        const {
            project: { evaluationPhases },
        } = this.props;

        const { evaluationPhaseId, useNewScoringCriteria } = this.state;

        // Only show phase option if project has multiple phases defined
        const newEvaluationPhaseOptions = evaluationPhaseSetupOptions.filter(({ value }) => {
            return value !== PHASE || evaluationPhases.length > 1;
        });

        const nextButtonDisabled =
            useNewScoringCriteria === null ||
            (useNewScoringCriteria === PHASE && !evaluationPhaseId);

        return (
            <div>
                <h4 className={this.styles.header}>
                    How would you like to setup the next evaluation phase?
                </h4>
                <ListGroup>
                    {newEvaluationPhaseOptions.map(({ text, value }, index) => {
                        const isSelected = useNewScoringCriteria === value;
                        const icon = isSelected ? 'fa-check-square text-success' : 'fa-square-o';
                        return (
                            <ListGroupItem
                                bsStyle={isSelected ? 'success' : undefined}
                                key={index}
                                onClick={() => {
                                    this.setState({
                                        evaluationPhaseId: null,
                                        useNewScoringCriteria: value,
                                    });
                                }}
                            >
                                <i className={`fa fa-lg fa-fw ${icon}`} /> {text}
                            </ListGroupItem>
                        );
                    })}
                </ListGroup>
                {useNewScoringCriteria === PHASE && (
                    <>
                        <h4 className={this.styles.header}>
                            Which pre-defined evaluation phase would you like to use?
                        </h4>
                        <ListGroup>
                            {evaluationPhases.map((evaluationPhase) => {
                                return (
                                    <EvaluationPhaseSelectItem
                                        clickHandler={() => {
                                            this.setState({
                                                evaluationPhaseId: evaluationPhase.id,
                                            });
                                        }}
                                        evaluationPhase={evaluationPhase}
                                        isSelected={evaluationPhaseId === evaluationPhase.id}
                                        key={evaluationPhase.id}
                                    />
                                );
                            })}
                        </ListGroup>
                    </>
                )}
                <div className="text-center">
                    <Button
                        bsSize="lg"
                        bsStyle="success"
                        disabled={nextButtonDisabled}
                        onClick={this.nextPage}
                    >
                        Next <i className="fa fa-angle-double-right" />
                    </Button>
                </div>
            </div>
        );
    }

    renderProposalSelect() {
        const { proposals } = this.props;

        const { selectedProposalIds } = this.state;

        const selectedProposalCount = this.getSelectedProposalIds().length;
        const noSelectedProposals = selectedProposalCount === 0;

        return (
            <div>
                <h4 className={this.styles.header}>
                    Select responses to include in the next evaluation phase
                </h4>
                <p className="text-center text-muted">
                    Responses not selected will be excluded from subsequent evaluations
                </p>
                <div>
                    <Button
                        bsSize="sm"
                        bsStyle="link"
                        className={this.styles.selectAllButton}
                        onClick={noSelectedProposals ? this.selectAll : this.unselectAll}
                    >
                        <i
                            className={`fa fa-lg fa-${
                                noSelectedProposals ? 'square-o' : 'minus-square-o'
                            }`}
                        />
                        {noSelectedProposals ? 'Select' : 'Unselect'} All
                    </Button>
                </div>
                <ListGroup>
                    {proposals.map((proposal) => {
                        const isSelected = selectedProposalIds[proposal.id];
                        const icon = isSelected ? 'fa-check-square text-success' : 'fa-square-o';
                        return (
                            <ListGroupItem
                                bsStyle={isSelected ? 'success' : undefined}
                                key={proposal.id}
                                onClick={this.toggleSelected(proposal)}
                            >
                                <i className={`fa fa-lg fa-fw ${icon}`} /> {proposal.companyName}
                            </ListGroupItem>
                        );
                    })}
                </ListGroup>
                <div className="text-center">
                    <Button bsSize="lg" onClick={this.prevPage}>
                        <i className="fa fa-angle-left" /> Back
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        bsSize="lg"
                        bsStyle="success"
                        disabled={noSelectedProposals}
                        onClick={this.nextPage}
                    >
                        Next <i className="fa fa-angle-double-right" />
                    </Button>
                </div>
                {noSelectedProposals && (
                    <div className="text-center error-block">
                        Please select at least one response to include
                    </div>
                )}
            </div>
        );
    }

    renderConfirmation() {
        const { createError, creating, useNewScoringCriteria } = this.state;

        const selectedProposalCount = this.getSelectedProposalIds().length;

        return (
            <div className="text-center">
                <p className={`text-muted ${this.styles.warningText}`}>
                    {useNewScoringCriteria
                        ? 'After creating the new evaluation phase, you will be able to setup all new ' +
                          'evaluation criteria and evaluators and perform a new round of scoring.'
                        : 'We will carry over all your existing criteria, evaluators and scores ' +
                          'to the next phase and you can then perform another round of scoring'}
                    <br />
                    <br />
                    The new evaluation phase will include the{' '}
                    <strong>{selectedProposalCount}</strong> selected responses.
                    <br />
                    <br />
                    Once created, your new evaluation phase cannot be deleted!
                    <br />
                    <br />
                    <strong>IMPORTANT!</strong>
                    <br />
                    Starting a new evaluation phase will finalize this current phase!
                    <br />
                    Evaluators of the current phase will no longer be able to update their scores
                    after the new phase is created. We will save all the information related to this
                    current phase for your records.
                </p>
                <Button bsSize="lg" onClick={this.prevPage}>
                    <i className="fa fa-angle-left" /> Back
                </Button>
                &nbsp;&nbsp;
                <LoadingButton
                    bsSize="lg"
                    bsStyle="success"
                    disabled={creating}
                    icon="fa-plus"
                    loading={creating}
                    loadingText="Creating Phase..."
                    onClick={this.createEvaluationPhase}
                    text="Create Evaluation Phase"
                />
                {createError && <div className="text-center error-block">{createError}</div>}
            </div>
        );
    }

    render() {
        const { hideModal } = this.props;

        const { page } = this.state;

        return (
            <Modal onHide={hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Create New Evaluation Phase</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {page === 1 && this.renderCriteriaSelect()}
                    {page === 2 && this.renderProposalSelect()}
                    {page === 3 && this.renderConfirmation()}
                </Modal.Body>
            </Modal>
        );
    }
}
