import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import { BudgetCheckDetails } from '.';

export const BudgetCheckDetailsModal = ({ budgetCheck, onClose = () => {}, show = false }) => {
    return (
        <Modal show={show}>
            <BudgetCheckDetails budgetCheck={budgetCheck} onClose={onClose} />
        </Modal>
    );
};

BudgetCheckDetailsModal.propTypes = {
    budgetCheck: PropTypes.shape({
        fiscalYear: PropTypes.string,
        accountNumber: PropTypes.string,
        groupName: PropTypes.string,
        budgetCheckPass: PropTypes.bool,
        requestedAmount: PropTypes.number,
        accountLevel: PropTypes.object,
        groupLevel: PropTypes.object,
        isExpenseAccount: PropTypes.bool,
        accountNumberCompressedFormatted: PropTypes.string,
    }),
    onClose: PropTypes.func,
    show: PropTypes.bool,
};
