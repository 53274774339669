import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { AdminScorecardDisplay } from './AdminScorecardDisplay';
import { AdminScorecardForm } from './AdminScorecardForm';
import {
    getAdminScorecard,
    getAdminScorecardFormValuesMap,
    getAdminScoringCriteriaJS,
} from './selectors';
import { AdminScorecardStatus } from '../components';
import {
    getTotalWeight,
    isAdminScorecardComplete,
    isEvaluatorScorecardReadOnly,
} from '../selectors';
import {
    getCurrentEvaluation,
    getCurrentScoredProposals,
    getProjectJS,
    isEvaluationEditor,
} from '../../selectors';
import connectData from '../../../ConnectData';
import { shouldLoadFromEvaluationAudit } from '../../../../actions/evaluations';
import {
    loadAggregateProposalEvaluations,
    shouldReloadAggregateEvaluations,
    unsubmitAdminScorecard,
    updateAdminScorecard,
} from '../../../../actions/proposalEvaluations';
import { Button, SectionTitle, ZeroState } from '../../../../components';
import { evaluationStatuses } from '../../../../../../shared_config/evaluations';

const { DRAFT, OPEN } = evaluationStatuses;

function fetchData(getState, dispatch, location, params) {
    const state = getState();
    if (
        shouldLoadFromEvaluationAudit(state, Object.fromEntries(location.searchParams)) ||
        !shouldReloadAggregateEvaluations(state)
    ) {
        return Promise.resolve();
    }
    const projectId = Number.parseInt(params.projectId, 10);
    return dispatch(loadAggregateProposalEvaluations(projectId));
}

const mapStateToProps = (state, props) => {
    return {
        adminScorecardProposalEvaluations: getAdminScorecard(state, props),
        adminScoringCriteria: getAdminScoringCriteriaJS(state, props),
        evaluation: getCurrentEvaluation(state, props),
        initialFormValues: getAdminScorecardFormValuesMap(state, props),
        isEditor: isEvaluationEditor(state, props),
        isScorecardComplete: isAdminScorecardComplete(state, props),
        project: getProjectJS(state, props),
        proposals: getCurrentScoredProposals(state, props),
        readOnly: isEvaluatorScorecardReadOnly(state, props),
        totalWeight: getTotalWeight(state, props),
    };
};

const mapDispatchToProps = {
    unsubmitAdminScorecard,
    updateAdminScorecard,
};

// @connectData
// @connect
class ConnectedAdminScorecard extends Component {
    static propTypes = {
        adminScorecardProposalEvaluations: PropTypes.arrayOf(
            PropTypes.shape({
                proposalCriteriaScores: PropTypes.arrayOf(
                    PropTypes.shape({
                        proposal: PropTypes.shape({
                            companyName: PropTypes.string,
                        }).isRequired,
                        score: PropTypes.number,
                    })
                ).isRequired,
                scoringCriterium: PropTypes.shape({
                    description: PropTypes.string,
                    title: PropTypes.string.isRequired,
                }).isRequired,
            })
        ).isRequired,
        adminScoringCriteria: PropTypes.array.isRequired,
        evaluation: PropTypes.shape({
            status: PropTypes.string.isRequired,
        }).isRequired,
        initialFormValues: PropTypes.object.isRequired,
        isEditor: PropTypes.bool.isRequired,
        isScorecardComplete: PropTypes.bool.isRequired,
        project: PropTypes.object.isRequired,
        proposals: PropTypes.array.isRequired,
        readOnly: PropTypes.bool.isRequired,
        totalWeight: PropTypes.number.isRequired,
        unsubmitAdminScorecard: PropTypes.func.isRequired,
        updateAdminScorecard: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showForm: false,
            updating: false,
            updatingWithClose: false,
            updateError: null,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    submitHandler = (closeFormOnSave, isComplete) => (formData) => {
        const { adminScoringCriteria, project, proposals } = this.props;

        const proposalEvaluations = proposals.map((proposal) => {
            return {
                proposal_id: proposal.id,
                proposalCriteriaScores: adminScoringCriteria.map((scoringCriterium) => {
                    return {
                        ...formData[`${proposal.id}_${scoringCriterium.id}`],
                        scoring_criterium_id: scoringCriterium.id,
                    };
                }),
            };
        });

        const data = {
            isComplete,
            proposalEvaluations,
        };

        const updateKey = closeFormOnSave ? 'updatingWithClose' : 'updating';

        this.setState({
            [updateKey]: true,
            updateError: null,
        });
        return this.props
            .updateAdminScorecard(project.id, data)
            .then(() => {
                this.setState({
                    [updateKey]: false,
                });
                if (closeFormOnSave) {
                    this.setState({
                        showForm: false,
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    [updateKey]: false,
                    updateError: error.message,
                });
            });
    };

    toggleForm = () => this.setState((prevState) => ({ showForm: !prevState.showForm }));

    unsubmitAdminScorecard = () => {
        const { project } = this.props;

        this.setState({
            unsubmitting: true,
            unsubmitError: null,
        });

        this.props
            .unsubmitAdminScorecard(project.id)
            .then(() => {
                this.setState({
                    unsubmitting: false,
                });
            })
            .catch((error) => {
                this.setState({
                    unsubmitting: false,
                    unsubmitError: error.message,
                });
            });
    };

    renderStatusText() {
        const { isScorecardComplete, readOnly } = this.props;

        const { unsubmitting, unsubmitError } = this.state;

        if (readOnly && isScorecardComplete) {
            return null;
        }

        if (isScorecardComplete) {
            return (
                <>
                    <em>Need to update the admin scorecard?&nbsp;</em>
                    <Button
                        bsSize="sm"
                        bsStyle="link"
                        disabled={unsubmitting}
                        onClick={this.unsubmitAdminScorecard}
                        zeroPadding
                    >
                        <i className="fa fa-undo" /> Unsubmit Scores
                    </Button>
                    {unsubmitError && <div className="error-block">{unsubmitError}</div>}
                </>
            );
        }

        if (readOnly) {
            return <em>NOTE: Scores were not counted because scorecard was not submitted</em>;
        }

        return <em>NOTE: Scores will not be counted until scorecard is submitted</em>;
    }

    renderToggleButton() {
        const { isEditor, isScorecardComplete, readOnly } = this.props;

        const { showForm, updating, updatingWithClose } = this.state;

        if (readOnly || isScorecardComplete || !isEditor) {
            return null;
        }

        return (
            <div className={this.styles.toggleButtonContainer}>
                <Button
                    bsStyle={showForm ? undefined : 'primary'}
                    className={this.styles.toggleButton}
                    disabled={updating || updatingWithClose}
                    onClick={this.toggleForm}
                >
                    <i className={`fa fa-${showForm ? 'ban' : 'pencil'}`} />
                    &nbsp;
                    {showForm ? 'Cancel' : 'Manage Scorecard'}
                </Button>
            </div>
        );
    }

    renderScorecard() {
        const {
            adminScorecardProposalEvaluations,
            evaluation,
            initialFormValues,
            isEditor,
            isScorecardComplete,
            project,
            readOnly,
            totalWeight,
        } = this.props;

        const { showForm, unsubmitError, unsubmitting, updateError, updating, updatingWithClose } =
            this.state;

        if (evaluation.status === DRAFT || evaluation.status === OPEN) {
            return (
                <ZeroState
                    title={
                        <>
                            Evaluation has not been released to evaluators yet
                            <br />
                            Admin Scorecard will appear here once the evaluation has been released
                        </>
                    }
                />
            );
        }

        return (
            <>
                <div className="row">
                    <div className="col-sm-6 col-sm-offset-3 text-center">
                        <AdminScorecardStatus
                            info={this.renderStatusText()}
                            isComplete={isScorecardComplete}
                        />
                    </div>
                </div>
                {this.renderToggleButton()}
                {showForm ? (
                    <AdminScorecardForm
                        adminScorecardProposalEvaluations={adminScorecardProposalEvaluations}
                        initialValues={initialFormValues}
                        isCommentRequired={project.evaluation.isCommentRequired}
                        submitHandler={this.submitHandler}
                        totalWeight={totalWeight}
                        updateError={updateError}
                        updating={updating}
                        updatingWithClose={updatingWithClose}
                    />
                ) : (
                    <AdminScorecardDisplay
                        adminScorecardProposalEvaluations={adminScorecardProposalEvaluations}
                        totalWeight={totalWeight}
                    />
                )}
                {isScorecardComplete && isEditor && !readOnly && (
                    <div className="text-center">
                        <Button disabled={unsubmitting} onClick={this.unsubmitAdminScorecard}>
                            <i className="fa fa-undo" /> Unsubmit Scores
                        </Button>
                        {unsubmitError && <div className="error-block">{unsubmitError}</div>}
                        <p className={`text-muted ${this.styles.unsubmitText}`}>
                            Unsubmit your scores if you need to edit the scorecard.
                            <br />
                            Just be sure to resubmit once you have made the changes.
                        </p>
                    </div>
                )}
            </>
        );
    }

    render() {
        return (
            <>
                <SectionTitle
                    info="Provide scores for the evaluation items that have been designated for global scoring determined by admins"
                    title="Admin Scores"
                />
                {this.renderScorecard()}
            </>
        );
    }
}

export const AdminScorecard = compose(
    connectData(fetchData),
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedAdminScorecard);
