import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Panel } from 'react-bootstrap';

import { StepApprovalLogs } from './StepApprovalLogs';
import { StepStatus } from './StepStatus';
import { Collapse } from '../../../../../../components';

export const Step = ({
    StepStatusComponent,
    children,
    href,
    requisitionLogs,
    stepName,
    stepNameClassName,
    stepStatusIcon,
    stepStatusIconClassName,
    stepStatusIconText,
    stepStatusText,
    stepStatusTextClassName,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const hasBody = !!children;

    const styles = require('./index.scss');

    const onClick = () => {
        if (hasBody) {
            setIsOpen((prevState) => !prevState);
        }

        if (href) {
            window.open(href, '_blank');
        }
    };

    return (
        <Panel className={styles.panel}>
            <Panel.Heading
                className={classNames(
                    styles.panelHeading,
                    (hasBody || href) && styles.panelHeadingButton,
                    isOpen ? styles.panelHeadingOpen : styles.panelHeadingClosed
                )}
                onClick={onClick}
            >
                <StepStatus
                    StepStatusComponent={StepStatusComponent}
                    hasToggle={hasBody}
                    isOpen={isOpen}
                    stepName={stepName}
                    stepNameClassName={stepNameClassName}
                    stepStatusIcon={stepStatusIcon}
                    stepStatusIconClassName={stepStatusIconClassName}
                    stepStatusIconText={stepStatusIconText}
                    stepStatusText={stepStatusText}
                    stepStatusTextClassName={stepStatusTextClassName}
                />
            </Panel.Heading>
            {hasBody && (
                <Collapse in={isOpen}>
                    <Panel.Body className={styles.panelBody}>
                        <div className={styles.panelBodyContainer}>
                            {children}
                            <StepApprovalLogs requisitionLogs={requisitionLogs} />
                        </div>
                    </Panel.Body>
                </Collapse>
            )}
        </Panel>
    );
};

Step.propTypes = {
    StepStatusComponent: PropTypes.node,
    children: PropTypes.node,
    href: PropTypes.string,
    requisitionLogs: PropTypes.array,
    stepName: PropTypes.string.isRequired,
    stepNameClassName: PropTypes.string,
    stepStatusIcon: PropTypes.string, // Either `stepStatusIcon` or `stepStatusIconText` prop is required to be present
    stepStatusIconClassName: PropTypes.string,
    stepStatusIconText: PropTypes.string, // Either `stepStatusIcon` or `stepStatusIconText` prop is required to be present
    stepStatusText: PropTypes.string,
    stepStatusTextClassName: PropTypes.string,
};
