import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { FileUploadResponseInput } from './FileUploadResponseInput';
import { DOWNLOAD, NOTARIZE } from '../../../../../../../shared_config/questionnaires';

export class FileUploadResponseForm extends PureComponent {
    static propTypes = {
        checklistId: PropTypes.number,
        disabled: PropTypes.bool,
        id: PropTypes.number.isRequired,
        input: PropTypes.object.isRequired,
        isOGThemeEnabledForComponents: PropTypes.bool,
        isTemplateReadonlyView: PropTypes.bool,
        meta: PropTypes.object.isRequired,
        proposalId: PropTypes.number,
        showValidation: PropTypes.bool,
        type: PropTypes.string.isRequired,
    };

    render() {
        const {
            checklistId,
            disabled,
            id,
            input,
            isOGThemeEnabledForComponents,
            isTemplateReadonlyView,
            meta,
            proposalId,
            showValidation,
            type,
        } = this.props;

        return (
            <FileUploadResponseInput
                checklistId={checklistId}
                disabled={disabled}
                format={null} // Needed to prevent empty string from being inserted when no value: https://github.com/erikras/redux-form/issues/3320
                input={input}
                isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                isTemplateReadonlyView={isTemplateReadonlyView}
                label={type === DOWNLOAD || type === NOTARIZE ? 'Your file uploads:' : undefined}
                meta={meta}
                proposalId={proposalId}
                questionnaireId={id}
                showValidation={showValidation}
            />
        );
    }
}
