import { projectStatusesDict } from '../../../../../../../shared_config/projects';

const { AWARD_PENDING, REQUEST_REVIEW } = projectStatusesDict;

export const getMostRecentProject = (reqRelations) => {
    return reqRelations.reduce((mostRecent, current) => {
        const mostRecentDate = new Date(mostRecent.project.created_at);
        const currentDate = new Date(current.project.created_at);

        return currentDate > mostRecentDate ? current : mostRecent;
    });
};

export const parseReqRelations = (rawReqRelations) => {
    if (rawReqRelations.length === 0 || rawReqRelations.length === 1) {
        return rawReqRelations;
    }

    const statuses = new Set(rawReqRelations.map((reqRelation) => reqRelation.project.status));
    const areAllIntakes = rawReqRelations.every((reqRelation) => reqRelation.project.isIntake);
    const areAllSolicitations = rawReqRelations.every(
        (reqRelation) => reqRelation.project.isSolicitation
    );

    // If ALL projects are intakes, and all of them have REQUEST_REVIEW status, we only want to show the most recent project
    if (statuses.size === 1 && statuses.has(REQUEST_REVIEW) && areAllIntakes) {
        const mostRecentProject = getMostRecentProject(rawReqRelations);

        return [mostRecentProject];
    }

    // If ALL projects are solicitations, and all of them have AWARD_PENDING status, we only want to show the most recent project
    if (statuses.size === 1 && statuses.has(AWARD_PENDING) && areAllSolicitations) {
        const mostRecentProject = getMostRecentProject(rawReqRelations);

        return [mostRecentProject];
    }

    return rawReqRelations;
};
