import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { getTemplateVariableOptions } from '../GovApp/constants';
import {
    getProjectSectionTagOptions,
    getReviewProjectJS as getGovernmentProjectJS,
    getReviewAppendixIdsMap,
    getReviewPath as getGovernmentProjectPath,
    isAttachmentsEditable,
    isDocumentEditable,
} from '../GovApp/selectors';
import {
    getProjectJS as getPublicProjectJS,
    getProjectPath as getPublicProjectPath,
} from '../PublicApp/selectors';
import { isSubscribedToProject } from '../selectors';
import { getProposalProjectPath } from '../VendorApp/selectors';
import { showConfirmationSimpleModal } from '../../actions/confirmation';
import { shouldShowComments } from '../../actions/govComments';
import * as attachmentActions from '../../actions/govProjects/attachments';
import * as criteriaActions from '../../actions/govProjects/criteria';
import * as sectionDescriptionActions from '../../actions/govProjects/sectionDescriptions';
import { downloadAttachments } from '../../actions/publicProject';
import { ProjectSectionMapper } from '../../components';
import { FIXED_TOOLBAR_ADJUSTMENT_HEIGHT } from '../../constants/styles';
import { numberStringToInteger } from '../../utils';
import { sectionTypeNames } from '../../../../shared_config/sections';

const { DIVIDER } = sectionTypeNames;

export class ProjectSection extends PureComponent {
    static propTypes = {
        // The following props are used by all views
        isAttachmentsEditable: PropTypes.bool,
        isGovernmentView: PropTypes.bool,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            query: PropTypes.shape({
                field: PropTypes.string,
                section: PropTypes.string,
            }).isRequired,
        }).isRequired,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
            projectSections: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    isHidden: PropTypes.bool.isRequired,
                    isHiddenByLogic: PropTypes.bool,
                })
            ).isRequired,
            useSectionDividers: PropTypes.bool.isRequired,
        }),
        projectPath: PropTypes.string.isRequired,
        // The following props are only used with `isGovernmentView=false` and are all required
        downloadAttachments: PropTypes.func,
        isSubscribed: PropTypes.bool,
        // The following props are only used with `isGovernmentView=true` and are all required
        addAttachment: PropTypes.func,
        appendixIdsMap: PropTypes.object,
        criteriaCreate: PropTypes.func,
        criteriaUpdate: PropTypes.func,
        criteriaDelete: PropTypes.func,
        criteriaToggleForm: PropTypes.func,
        deleteAttachment: PropTypes.func,
        isEditable: PropTypes.bool,
        sectionDescriptionCreate: PropTypes.func,
        sectionDescriptionDelete: PropTypes.func,
        sectionDescriptionToggleForm: PropTypes.func,
        sectionDescriptionUpdate: PropTypes.func,
        showComments: PropTypes.bool,
        showConfirmationSimpleModal: PropTypes.func,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        toggleEditAttachment: PropTypes.func,
        updateAttachment: PropTypes.func,
    };

    componentDidMount() {
        const {
            isGovernmentView,
            location: {
                query: { field },
            },
        } = this.props;

        // Scrolls to the selected project field if a field param is specified
        if (isGovernmentView && field) {
            const targetEl = document.getElementById(`project-field-${field}`);
            if (targetEl) {
                // Add styling to the selected element
                const styles = require('./index.scss');
                targetEl.className += ` ${styles.selectedField}`;

                // Need to take off the event loop for child rendering to
                // complete, which will allow the scroll to work properly
                setTimeout(() => {
                    targetEl.scrollIntoView();
                    const pageYOffset = window.pageYOffset;
                    if (pageYOffset) {
                        // Offset scroll position to account for fixed navbar
                        window.scroll(0, pageYOffset - FIXED_TOOLBAR_ADJUSTMENT_HEIGHT);
                    }
                });
            }
        }
    }

    get sectionDescActions() {
        const {
            sectionDescriptionCreate,
            sectionDescriptionDelete,
            sectionDescriptionToggleForm,
            sectionDescriptionUpdate,
        } = this.props;

        return {
            sectionDescriptionCreate,
            sectionDescriptionDelete,
            sectionDescriptionToggleForm,
            sectionDescriptionUpdate,
        };
    }

    get criteriaActions() {
        const { criteriaCreate, criteriaDelete, criteriaToggleForm, criteriaUpdate } = this.props;

        return {
            criteriaCreate,
            criteriaDelete,
            criteriaToggleForm,
            criteriaUpdate,
        };
    }

    get attachmentActions() {
        const {
            addAttachment,
            appendixIdsMap,
            deleteAttachment,
            project: { id },
            toggleEditAttachment,
            updateAttachment,
        } = this.props;

        return {
            addAttachment: addAttachment(id),
            appendixIdsMap,
            deleteAttachment: deleteAttachment(id),
            toggleEditAttachment,
            updateAttachment: updateAttachment(id),
        };
    }

    // Add additional props dependent on government or public view
    get sectionProps() {
        const { isEditable, isGovernmentView, showComments } = this.props;

        return isGovernmentView
            ? {
                  attachmentActions: this.attachmentActions,
                  criteriaActions: this.criteriaActions,
                  isAttachmentsEditable: this.props.isAttachmentsEditable,
                  isEditable,
                  sectionDescActions: this.sectionDescActions,
                  showComments,
                  showConfirmationSimpleModal: this.props.showConfirmationSimpleModal,
              }
            : {
                  downloadPublicAttachment: this.downloadPublicAttachment,
              };
    }

    downloadPublicAttachment = (attachment) => {
        const { isSubscribed, project } = this.props;

        this.props.downloadAttachments(project, isSubscribed, [attachment]);
    };

    renderAllSections() {
        const { project, projectPath, tagOptions, templateVariableOptions } = this.props;

        const allSections = project.projectSections
            .filter((projectSection) => !projectSection.isHidden && !projectSection.isHiddenByLogic)
            .map((projectSection) => (
                <ProjectSectionMapper
                    {...this.sectionProps}
                    key={projectSection.id}
                    project={project}
                    projectPath={projectPath}
                    projectSectionId={projectSection.id}
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                />
            ));

        return <div>{allSections}</div>;
    }

    renderDivisionWithSections(currentSection) {
        const { project, projectPath, tagOptions, templateVariableOptions } = this.props;
        const dividerSection = currentSection || project.projectSections[0];

        const divisionSubsections = project.projectSections
            .filter(
                (projectSection) =>
                    !projectSection.isHidden &&
                    !projectSection.isHiddenByLogic &&
                    projectSection.sectionNumber === dividerSection.sectionNumber
            )
            .map((projectSection) => (
                <ProjectSectionMapper
                    {...this.sectionProps}
                    key={projectSection.id}
                    project={project}
                    projectPath={projectPath}
                    projectSectionId={projectSection.id}
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                />
            ));

        return <div>{divisionSubsections}</div>;
    }

    render() {
        const {
            location: {
                query: { section },
            },
            project,
            projectPath,
            tagOptions,
            templateVariableOptions,
        } = this.props;

        /**
         * The project will always be loaded on normal navigation, but on back
         * button nav the components get rendered before data is re-rendered
         * Possibly related:
         * https://github.com/ReactTraining/react-router/issues/5072
         */
        if (!project) return null;

        if (section === 'all') {
            return this.renderAllSections();
        }

        const projectSectionId = numberStringToInteger(section);
        const currentSection = project.projectSections.find(
            (projSection) => projSection.id === projectSectionId
        );

        if (
            (!currentSection && project.useSectionDividers) || // Case where the first section should be a divider on initial page load
            currentSection?.section_type === DIVIDER
        ) {
            return this.renderDivisionWithSections(currentSection);
        }

        return (
            <ProjectSectionMapper
                {...this.sectionProps}
                project={project}
                projectPath={projectPath}
                projectSectionId={projectSectionId}
                tagOptions={tagOptions}
                templateVariableOptions={templateVariableOptions}
            />
        );
    }
}

/**
 * NOTE: we can't use decorators below because they don't seem to play nice with not being after the
 * previously defined component, so we use the standard HOC style instead.
 */

// Government container

const ProjectSectionGovernment = (props) => {
    return <ProjectSection isGovernmentView {...props} />;
};
ProjectSection.Government = compose(
    withRouter,
    connect(
        (state, props) => {
            const project = getGovernmentProjectJS(state);

            return {
                appendixIdsMap: getReviewAppendixIdsMap(state),
                isAttachmentsEditable: isAttachmentsEditable(state),
                isEditable: isDocumentEditable(state),
                project,
                projectPath: getGovernmentProjectPath(state, props),
                showComments: shouldShowComments(state),
                tagOptions: getProjectSectionTagOptions(state),
                templateVariableOptions: getTemplateVariableOptions({ project }),
            };
        },
        {
            ...attachmentActions,
            ...criteriaActions,
            ...sectionDescriptionActions,
            showConfirmationSimpleModal,
        }
    )
)(ProjectSectionGovernment);

// Public container
const ProjectSectionPublic = (props) => {
    return <ProjectSection {...props} />;
};
ProjectSection.Public = compose(
    withRouter,
    connect(
        (state, props) => {
            const hasVendorId = !!props.params.vendorId;
            const projectPath = hasVendorId
                ? getProposalProjectPath(state, props)
                : getPublicProjectPath(state, props);

            return {
                isSubscribed: isSubscribedToProject(state),
                project: getPublicProjectJS(state),
                projectPath,
            };
        },
        {
            downloadAttachments,
        }
    )
)(ProjectSectionPublic);
