import moment from 'moment';
import qs from 'qs';
import { startCase } from 'lodash';

import {
    projectStatuses,
    projectStatusesDict,
    projectScopeFilterTypesDict,
} from '../../../../../shared_config/projects';

const { REQUEST_DRAFT, REQUEST_REVIEW, DRAFT, FINAL, REVIEW, OPEN, PENDING, CLOSED } =
    projectStatusesDict;

const { ALL_PROJECTS } = projectScopeFilterTypesDict;

export const getUnreleasedAddendaProjects = (projects) => {
    if (projects.length > 0) {
        return projects.filter((project) => {
            return project.addendums.length > 0;
        });
    }
    return [];
};

export const getUnresolvedQuestionsProjects = (projects) => {
    if (projects.length > 0) {
        return projects.filter((project) => {
            return project.status === OPEN && project.questions.length > 0;
        });
    }
    return [];
};

export const getPendingProjects = (projects) => {
    if (projects.length > 0) {
        return projects.filter((project) => {
            return project.status === PENDING;
        });
    }
    return [];
};

export const getProjectsStagesBarChartData = (projects, router) => {
    if (projects.length > 0) {
        const govId = projects[0].government_id;
        const projectStageGroups = {};
        const series = [];
        const excludedStatuses = [CLOSED, DRAFT, FINAL, REQUEST_DRAFT, REQUEST_REVIEW];

        projectStatuses.forEach((status) => {
            if (!excludedStatuses.includes(status)) {
                projectStageGroups[status] = [];
            }
        });

        projects.forEach((project) => {
            if (!excludedStatuses.includes(project.status)) {
                projectStageGroups[project.status].push(project.id);
            }
        });

        Object.keys(projectStageGroups).forEach((stage) => {
            const displayName = startCase(stage);
            const ids = projectStageGroups[stage];
            const projectStageCount = ids.length;

            const goToProjects = () => {
                router.push(
                    `/governments/${govId}/projects?${qs.stringify({ scope: ALL_PROJECTS, ids })}`
                );
            };

            series.unshift({
                data: [projectStageCount],
                description: `Projects in ${displayName}`,
                cursor: 'pointer',
                events: {
                    click: goToProjects,
                },
                label: `${projectStageCount}`,
                name: `${displayName}`,
            });
        });
        return series;
    }
    return [];
};

export const getProjectsInReviewChartData = (projects, router) => {
    if (projects.length > 0) {
        const reviewProjects = projects.filter((project) => project.status === REVIEW);

        if (reviewProjects.length > 0) {
            const govId = reviewProjects[0].government_id;
            const goToProjects = (ids) => {
                router.push(
                    `/governments/${govId}/projects?${qs.stringify({ scope: ALL_PROJECTS, ids })}`
                );
            };

            const noApprovals = {
                color: '#a6acaf',
                custom: {
                    ids: [],
                },
                get events() {
                    return {
                        click: () => goToProjects(this.custom.ids),
                    };
                },
                name: 'No Approvals',
                get y() {
                    return this.custom.ids.length;
                },
            };

            const someRejections = {
                color: '#a94442',
                custom: {
                    ids: [],
                },
                get events() {
                    return {
                        click: () => goToProjects(this.custom.ids),
                    };
                },
                name: 'Some Rejections',
                get y() {
                    return this.custom.ids.length;
                },
            };

            const someApprovals = {
                color: '#f0d54e',
                custom: {
                    ids: [],
                },
                get events() {
                    return {
                        click: () => goToProjects(this.custom.ids),
                    };
                },
                name: 'Some Approvals',
                get y() {
                    return this.custom.ids.length;
                },
            };

            const approvalsComplete = {
                color: '#449d44',
                custom: {
                    ids: [],
                },
                get events() {
                    return {
                        click: () => goToProjects(this.custom.ids),
                    };
                },
                name: 'Approvals Complete',
                get y() {
                    return this.custom.ids.length;
                },
            };

            reviewProjects.forEach((project) => {
                const approvalStatusCount = {
                    approved: 0,
                    pending: 0,
                    rejected: 0,
                };

                const projectApprovalSteps = project.projectApprovalSteps;

                projectApprovalSteps.forEach((reviewStep) =>
                    reviewStep.projectApprovals.forEach(
                        (approval) => approvalStatusCount[approval.status]++
                    )
                );

                if (approvalStatusCount.rejected) {
                    return someRejections.custom.ids.push(project.id);
                }

                if (
                    approvalStatusCount.approved &&
                    !approvalStatusCount.pending &&
                    !approvalStatusCount.rejected
                ) {
                    return approvalsComplete.custom.ids.push(project.id);
                }

                if (
                    approvalStatusCount.approved &&
                    (approvalStatusCount.pending || approvalStatusCount.rejected)
                ) {
                    return someApprovals.custom.ids.push(project.id);
                }

                if (
                    project.projectApprovalSteps.length === 0 ||
                    (!approvalStatusCount.approved && approvalStatusCount.pending)
                ) {
                    return noApprovals.custom.ids.push(project.id);
                }
            });

            if (
                noApprovals.custom.ids.length === 0 &&
                someApprovals.custom.ids.length === 0 &&
                someRejections.custom.ids.length === 0 &&
                approvalsComplete.custom.ids.length === 0
            ) {
                return [];
            }

            return [noApprovals, someRejections, someApprovals, approvalsComplete];
        }
        return [];
    }
    return [];
};

export const generateTimeBasedPieChartData = (projects, router) => {
    const govId = projects[0].government_id;
    const goToProjects = (ids) => {
        router.push(`/governments/${govId}/projects?${qs.stringify({ scope: ALL_PROJECTS, ids })}`);
    };

    const lessThan10DaysCount = {
        custom: {
            ids: [],
        },
        get events() {
            return {
                click: () => goToProjects(this.custom.ids),
            };
        },
        name: 'Less than 10 Days',
        get y() {
            return this.custom.ids.length;
        },
    };

    const between10And20DaysCount = {
        custom: {
            ids: [],
        },
        get events() {
            return {
                click: () => goToProjects(this.custom.ids),
            };
        },
        name: 'Between 10 & 20 Days',
        get y() {
            return this.custom.ids.length;
        },
    };

    const between20And30DaysCount = {
        custom: {
            ids: [],
        },
        get events() {
            return {
                click: () => goToProjects(this.custom.ids),
            };
        },
        name: 'Between 20 & 30 Days',
        get y() {
            return this.custom.ids.length;
        },
    };

    const over30DaysCount = {
        custom: {
            ids: [],
        },
        get events() {
            return {
                click: () => goToProjects(this.custom.ids),
            };
        },
        name: 'Over 30 Days',
        get y() {
            return this.custom.ids.length;
        },
    };

    projects.forEach((project) => {
        const { id } = project;
        const daysAgo = moment().diff(project.projectAudits[0].created_at, 'days');
        if (daysAgo < 10) lessThan10DaysCount.custom.ids.push(id);
        if (daysAgo > 10 && daysAgo < 20) between10And20DaysCount.custom.ids.push(id);
        if (daysAgo > 20 && daysAgo < 30) between20And30DaysCount.custom.ids.push(id);
        if (daysAgo > 30) over30DaysCount.custom.ids.push(id);
    });

    return [lessThan10DaysCount, between10And20DaysCount, between20And30DaysCount, over30DaysCount];
};
