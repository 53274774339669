import PropTypes from 'prop-types';
import React from 'react';

import { ReviewSequenceReviewGroups } from './ReviewSequenceReviewGroups';
import {
    StatusLabel,
    TemplateListTimestampColumns,
    TemplateStatusLabel,
    UserProfilePicture,
} from '../../../../components';
import { statusTypes } from '../../../../../../shared_config/reviewSequences';

const { ARCHIVED, PUBLISHED } = statusTypes;

export const ReviewSequencesListItem = ({
    reviewSequence: {
        created_at: createdAt,
        icon,
        isDefault,
        isException,
        lastUpdatedAt,
        name,
        reviewGroups,
        status,
        user,
    },
}) => {
    const styles = require('./index.scss');

    return (
        <div className="row">
            <div className="col-md-9">
                <div className="row">
                    <div className="col-md-5">
                        <span className={styles.sequenceName}>
                            {icon && <i className={`fa fa-fw fa-${icon}`} />}
                            {name || 'Untitled'}
                        </span>
                        <TemplateStatusLabel
                            className={styles.statusLabel}
                            isArchived={status === ARCHIVED}
                            isPublished={status === PUBLISHED}
                        />
                        {isDefault && (
                            <div>
                                <StatusLabel bsStyle="primary">Default Sequence</StatusLabel>
                            </div>
                        )}
                        {isException && (
                            <div>
                                <StatusLabel bsStyle="warning">Exception</StatusLabel>
                            </div>
                        )}
                    </div>
                    <div className="col-md-3 hidden-xs hidden-sm">
                        {user && (
                            <>
                                <UserProfilePicture horizontal user={user} />
                                &nbsp;&nbsp;
                                {user.displayName}
                            </>
                        )}
                    </div>
                    <div className="col-md-4 hidden-xs hidden-sm">
                        <ReviewSequenceReviewGroups reviewGroups={reviewGroups} />
                    </div>
                </div>
            </div>
            <div className="col-md-3 hidden-xs hidden-sm">
                <TemplateListTimestampColumns createdAt={createdAt} lastUpdatedAt={lastUpdatedAt} />
            </div>
        </div>
    );
};

ReviewSequencesListItem.propTypes = {
    reviewSequence: PropTypes.shape({
        created_at: PropTypes.string.isRequired,
        icon: PropTypes.string,
        isDefault: PropTypes.bool.isRequired,
        isException: PropTypes.bool.isRequired,
        name: PropTypes.string,
        lastUpdatedAt: PropTypes.string.isRequired,
        reviewGroups: PropTypes.array.isRequired,
        status: PropTypes.number.isRequired,
        user: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
        }),
    }).isRequired,
};
