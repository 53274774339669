import { fromJS } from 'immutable';

import * as publicProjectActions from '../actions/publicProject';
import * as reverseAuctionSocketActions from '../actions/reverseAuctionSocket';
import * as subscriptionsActions from '../actions/subscriptions';
import * as vendProposalActions from '../actions/vendProposals';
import { projectStatusesDict } from '../../../shared_config/projects';

const { PENDING, REVERSE_AUCTION } = projectStatusesDict;

const initialState = fromJS({
    applying: false,
    creatingProposal: false,
    downloadFilePath: null,
    downloadDeferred: false,
    loading: false,
    loaded: false,
    loadError: null,
    loadErrorStatus: null,
    govList: [],
    govProjects: { count: 0, rows: [] },
    govProjectsWithLineItems: [],
    searchedGovProjects: false,
    showApplyModal: false,
    showDownloadModal: false,
});

export default function publicProjectReducer(state = initialState, action = {}) {
    switch (action.type) {
        case publicProjectActions.LOAD:
            return state.merge(
                fromJS({
                    loading: true,
                    loaded: false,
                    loadError: null,
                    loadErrorStatus: null,
                })
            );
        case publicProjectActions.LOAD_SUCCESS: {
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: true,
                    project: fromJS(action.result),
                })
            );
        }
        case publicProjectActions.LOAD_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: false,
                    loadError: action.error && action.error.message,
                    loadErrorStatus: action?.error?.status,
                })
            );
        case vendProposalActions.LOAD_SUCCESS:
            return state.merge(
                fromJS({
                    project: fromJS(action.result.project),
                })
            );
        case publicProjectActions.LOAD_GOV:
            return state.merge(
                fromJS({
                    loadingGov: true,
                    loadGovError: null,
                })
            );
        case publicProjectActions.LOAD_GOV_SUCCESS:
            return state.merge(
                fromJS({
                    loadingGov: false,
                    government: fromJS(action.result),
                })
            );
        case publicProjectActions.LOAD_GOV_FAIL:
            return state.merge(
                fromJS({
                    loadingGov: false,
                    loadGovError: action.error && action.error.message,
                })
            );
        case publicProjectActions.SEARCH_PROJECTS:
            return state.merge(
                fromJS({
                    searchingGovProjects: true,
                    searchGovProjectsError: null,
                })
            );
        case publicProjectActions.SEARCH_PROJECTS_SUCCESS:
            return state.merge(
                fromJS({
                    searchingGovProjects: false,
                    searchedGovProjects: true,
                    govProjects: fromJS(action.result),
                })
            );
        case publicProjectActions.SEARCH_PROJECT_LINE_ITEMS_SUCCESS:
            return state.merge(
                fromJS({
                    searchingGovProjects: false,
                    searchedGovProjects: true,
                    govProjectsWithLineItems: fromJS(action.result.projects),
                })
            );
        case publicProjectActions.SEARCH_PROJECTS_FAIL:
            return state.merge(
                fromJS({
                    searchingGovProjects: false,
                    searchGovProjectsError: action.error && action.error.message,
                })
            );
        case publicProjectActions.RESET_SEARCH_PROJECTS:
            return state.set('searchedGovProjects', false);
        case publicProjectActions.LOAD_GOVS:
            return state.merge(
                fromJS({
                    loadingGovList: true,
                    loadGovListError: null,
                })
            );
        case publicProjectActions.LOAD_GOVS_SUCCESS:
            return state.merge(
                fromJS({
                    loadingGovList: false,
                    govList: fromJS(action.result),
                })
            );
        case publicProjectActions.LOAD_GOVS_FAIL:
            return state.merge(
                fromJS({
                    loadingGovList: false,
                    loadGovListError: action.error && action.error.message,
                })
            );
        case publicProjectActions.SHOW_APPLY_MODAL:
            return state.merge(
                fromJS({
                    showApplyModal: true,
                })
            );
        case publicProjectActions.HIDE_APPLY_MODAL:
            return state.merge(
                fromJS({
                    showApplyModal: false,
                    appliedRoute: null,
                })
            );
        case publicProjectActions.APPLY:
            return state.merge(
                fromJS({
                    applying: true,
                    applyError: null,
                    appliedRoute: null,
                })
            );
        case publicProjectActions.CREATE_PROPOSAL:
            return state.merge(
                fromJS({
                    creatingProposal: true,
                    applyError: null,
                })
            );
        case publicProjectActions.APPLY_SUCCESS:
            return state.merge(
                fromJS({
                    applying: false,
                    appliedRoute: action.route,
                })
            );
        case publicProjectActions.CREATE_PROPOSAL_SUCCESS:
            return state.merge(
                fromJS({
                    applying: false,
                    creatingProposal: false,
                })
            );
        case publicProjectActions.APPLY_FAIL:
        case publicProjectActions.CREATE_PROPOSAL_FAIL:
            return state.merge(
                fromJS({
                    applying: false,
                    creatingProposal: false,
                    applyError: action.error && action.error.message,
                })
            );
        case publicProjectActions.SHOW_DOWNLOAD_MODAL:
            return state.set('showDownloadModal', true);
        case publicProjectActions.HIDE_DOWNLOAD_MODAL:
            return state.set('showDownloadModal', false);
        case publicProjectActions.DOWNLOAD:
            return state.merge(
                fromJS({
                    downloading: true,
                    downloadDeferred: false,
                    downloadError: null,
                })
            );
        case publicProjectActions.DOWNLOAD_SUCCESS:
            return state.merge(
                fromJS({
                    downloading: false,
                    downloadFilePath: action.result,
                })
            );
        case publicProjectActions.DOWNLOAD_SUCCESS_QUEUED:
            return state.merge(
                fromJS({
                    downloading: false,
                    downloadDeferred: true,
                })
            );
        case publicProjectActions.DOWNLOAD_FAIL:
            return state.merge(
                fromJS({
                    downloading: false,
                    downloadError: action.error && action.error.message,
                })
            );
        case publicProjectActions.GOV_SUBSCRIBE:
            return state.merge(
                fromJS({
                    govSubscribing: true,
                    govSubscribeError: null,
                })
            );
        case publicProjectActions.GOV_SUBSCRIBE_SUCCESS:
            return state.merge(
                fromJS({
                    govSubscribing: false,
                })
            );
        case publicProjectActions.GOV_SUBSCRIBE_FAIL:
            return state.merge(
                fromJS({
                    govSubscribing: false,
                    govSubscribeError: action.error && action.error.message,
                })
            );
        case publicProjectActions.HIDE_GOV_SUBSCRIBE_FAIL:
            return state.delete('govSubscribeError');
        case subscriptionsActions.SUBSCRIBE_VENDOR_SUCCESS:
            return state.updateIn(['project', 'followers'], (list) =>
                list.push(fromJS(action.user))
            );
        case subscriptionsActions.UNSUBSCRIBE_VENDOR_SUCCESS:
            return state.updateIn(['project', 'followers'], (list) =>
                list.filter((subscriber) => subscriber.get('id') !== action.userId)
            );
        case reverseAuctionSocketActions.AUCTION_ENDED:
            return state.setIn(['project', 'status'], PENDING);
        case reverseAuctionSocketActions.AUCTION_STARTED:
            return state.setIn(['project', 'status'], REVERSE_AUCTION);
        case reverseAuctionSocketActions.AUCTION_EXTENDED:
            return state
                .setIn(['project', 'auctionExtensionCount'], action.auctionExtensionCount)
                .setIn(['project', 'auctionEndDate'], action.auctionEndDate);
        default:
            return state;
    }
}
