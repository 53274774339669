import React, { useContext } from 'react';
import { formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';

import {
    BaseSectionsNavBackButton,
    BaseSectionsNavNextButton,
    BaseSectionsNavBackNextButtons,
} from '../../../../../../components/SDv2/ProjectSectionsNav';
import { projectSectionsToNavSections } from '../../../../../../components/SDv2/helpers';
import { form, fieldNames } from '../../../forms/constants';
import { fieldNames as formFieldNames } from '../../../forms/TemplateForm/constants';
import { TemplateEditV2NavContext, TemplateEditV2FunctionsContext } from '../../context';

const { USE_MANUAL_NUMBERING } = formFieldNames;
const selector = formValueSelector(form);

export const TemplateSectionsBackNextButtons = () => {
    const projectSections = useSelector((state) => selector(state, fieldNames.PROJECT_SECTIONS));
    const useManualNumbering = useSelector((state) => selector(state, USE_MANUAL_NUMBERING));
    const { activeSectionId, setActiveSectionId } = useContext(TemplateEditV2NavContext);
    const { addingSectionData, buildRouteFunction, router, updateTemplateIfDirty } = useContext(
        TemplateEditV2FunctionsContext
    );

    // if we are adding a section
    // we fake an entry in the bottom of the list
    let rawSections = [...projectSections];
    if (addingSectionData.active) {
        rawSections = rawSections.concat([
            {
                ...addingSectionData.sectionType,
                title: 'New Section',
            },
        ]);
    }

    const sections = projectSectionsToNavSections({
        projectSections: rawSections,
        active: activeSectionId,
        setActive: (i) => {
            updateTemplateIfDirty();
            setActiveSectionId(i);
        },
        useManualNumbering,
    });

    return (
        <BaseSectionsNavBackNextButtons
            firstSectionComponent={
                <BaseSectionsNavBackButton
                    onClick={() => {
                        updateTemplateIfDirty();
                        router.push(buildRouteFunction('process-information'));
                    }}
                >
                    Project information
                </BaseSectionsNavBackButton>
            }
            lastSectionComponent={
                <BaseSectionsNavNextButton
                    onClick={() => {
                        updateTemplateIfDirty();
                        router.push(buildRouteFunction('attachments'));
                    }}
                >
                    Attachments
                </BaseSectionsNavNextButton>
            }
            sections={sections}
        />
    );
};
