import { listToDict } from '../../../../../../../shared_config/helpers';

export const form = 'flagForm';

export const fields = ['description', 'isActive', 'name'];

export const fieldNames = listToDict(fields);

export const flagStatusOptions = [
    {
        icon: 'check',
        label: 'Active',
        value: true,
    },
    {
        icon: 'ban',
        label: 'Disabled',
        value: false,
    },
];
