import { differenceBy, pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

import { permissionsSelectData } from './constants';
import { form, fields } from './PermissionForm/constants';
import { PermissionForm } from './PermissionForm/PermissionForm';
import { projectPermissionsDict } from '../../../../../shared_config/projects';

const { CREATOR } = projectPermissionsDict;

export class PermissionsForm extends Component {
    static propTypes = {
        canInvite: PropTypes.bool.isRequired,
        collaborators: PropTypes.array.isRequired,
        moduleType: PropTypes.string.isRequired,
        permissionTitle: PropTypes.string.isRequired,
        submitHandler: PropTypes.func.isRequired,
        userId: PropTypes.number.isRequired,
        users: PropTypes.array.isRequired,
    };

    render() {
        const {
            canInvite,
            collaborators,
            moduleType,
            permissionTitle,
            submitHandler,
            userId,
            users: allUsers,
        } = this.props;

        // Subset of all users that have not been invited
        const userChoices = differenceBy(allUsers, collaborators, 'id');
        const permissionForms = collaborators.map((user) => {
            user.role = user.projectUserPermissions[moduleType];
            return (
                <PermissionForm
                    canInvite={canInvite}
                    creator={user.role === CREATOR}
                    deleteHandler={submitHandler(false)}
                    enableReinitialize
                    form={`${form}${moduleType}${user.id}`}
                    initialValues={{ moduleType, ...pick(user, fields) }}
                    invited
                    isSelf={user.id === userId}
                    key={`${moduleType}${user.id}`}
                    onSubmit={submitHandler(true)}
                    permissionOptions={permissionsSelectData}
                    user={user}
                    users={allUsers}
                />
            );
        });

        const styles = require('./PermissionsForm.scss');
        return (
            <div className={styles.tableContainer}>
                <Table className={styles.table}>
                    <thead>
                        <tr>
                            <th>{permissionTitle}</th>
                            <th>Access</th>
                            <th className="visually-hidden">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <PermissionForm
                            canInvite={canInvite}
                            deleteHandler={submitHandler(false)}
                            form={`${form}${moduleType}`}
                            initialValues={{
                                role: permissionsSelectData[0].value,
                                moduleType,
                            }}
                            onSubmit={submitHandler(true)}
                            permissionOptions={permissionsSelectData}
                            users={userChoices}
                        />
                        {permissionForms}
                    </tbody>
                </Table>
                {!canInvite && (
                    <div className={`text-muted ${styles.noPermission}`}>
                        You do not have permission to invite users to this phase of the project.
                        Please request edit permissions from the project creator or an editor to
                        invite others.
                    </div>
                )}
            </div>
        );
    }
}
