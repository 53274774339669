import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { ProposalEvaluationsList as ProposalEvaluationsListComponent } from './components';
import {
    getAllEvaluationPhaseScores,
    getProposalEvaluationsListJS,
    shouldShowScoresOnly,
} from './selectors';
import { ProposalBackButton } from '../components';
import {
    canEvaluate,
    getEvaluationLocationObject,
    getTotalWeight,
    hasQuestionnaire,
} from '../selectors';
import {
    getAgreementsJS,
    getCurrentEvaluation,
    getEvaluationProjectJS,
    getEvaluator,
    getProjectStandardDocJS,
    isViewingPastPhase,
    mustAcceptProposalViewerAgreement,
} from '../../selectors';
import connectData from '../../../ConnectData';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import {
    deleteConsensusEvaluation,
    shouldLoadFromEvaluationAudit,
} from '../../../../actions/evaluations';
import {
    getProposalViewerAgreementData,
    menuActionHandler,
    showInstructionsModal,
} from '../../../../actions/govProjects';
import {
    loadAggregateProposalEvaluations,
    shouldReloadAggregateEvaluations,
    showEvaluationNotes,
    showEvaluationScorecard,
    showProposalSelect,
    updateProposalEvaluation,
} from '../../../../actions/proposalEvaluations';
import {
    Button,
    LoadingError,
    LoadingSpinner,
    Main,
    ProposalViewerAgreement,
    ProposalViewerAgreementAccepted,
    SectionTitle,
    ZeroState,
} from '../../../../components';
import { CONSENSUS_EVALUATION } from '../../../../constants/menuActions';
import { proposalEvaluationStatuses } from '../../../../../../shared_config/proposalEvaluations';
import { getProposalViewerAcceptanceDate } from '../../ProposalsList/selectors';

const { OPEN, COMPLETE } = proposalEvaluationStatuses;

function fetchData(getState, dispatch, location, params) {
    const state = getState();

    // Do not load any data when reading from evaluation audit or preventing reloads
    if (
        shouldLoadFromEvaluationAudit(state, Object.fromEntries(location.searchParams)) ||
        !shouldReloadAggregateEvaluations(state)
    ) {
        return Promise.resolve();
    }

    const projectId = Number.parseInt(params.projectId, 10);
    return Promise.all([
        dispatch(getProposalViewerAgreementData(projectId)),
        dispatch(loadAggregateProposalEvaluations(projectId)),
    ]);
}

const mapStateToProps = (state, props) => {
    return {
        acceptedAt: getProposalViewerAcceptanceDate(state, props),
        agreements: getAgreementsJS(state),
        allEvaluationPhaseScores: getAllEvaluationPhaseScores(state, props),
        canEvaluate: canEvaluate(state, props),
        error: state.proposalEvaluations.get('loadAggregateError'),
        evaluation: getCurrentEvaluation(state, props),
        evaluator: getEvaluator(state, props),
        getEvaluationPathObj: getEvaluationLocationObject(state, props),
        hasQuestionnaire: hasQuestionnaire(state),
        isPastPhase: isViewingPastPhase(state, props),
        loadAgreementsError: state.govProjects.get('loadAgreementsError'),
        loading: state.proposalEvaluations.get('loadingAggregate'),
        loadingAgreements: state.govProjects.get('loadingAgreements'),
        mustAcceptAgreement: mustAcceptProposalViewerAgreement(state, props),
        project: getEvaluationProjectJS(state),
        proposals: getProposalEvaluationsListJS(state, props),
        showScoresOnly: shouldShowScoresOnly(state, props),
        standardDocument: getProjectStandardDocJS(state),
        totalWeight: getTotalWeight(state, props),
    };
};

const mapDispatchToProps = {
    deleteConsensusEvaluation,
    menuActionHandler,
    showConfirmationSimpleModal,
    showEvaluationScorecard,
    showEvaluationNotes,
    showInstructionsModal,
    showProposalSelect,
    updateProposalEvaluation,
};

class ConnectedProposalEvaluationsList extends Component {
    static propTypes = {
        acceptedAt: PropTypes.string,
        agreements: PropTypes.array,
        allEvaluationPhaseScores: PropTypes.array,
        canEvaluate: PropTypes.bool.isRequired,
        deleteConsensusEvaluation: PropTypes.func.isRequired,
        error: PropTypes.string,
        evaluation: PropTypes.object,
        evaluator: PropTypes.object,
        getEvaluationPathObj: PropTypes.func.isRequired,
        hasQuestionnaire: PropTypes.bool.isRequired,
        info: PropTypes.string,
        isConsensusView: PropTypes.bool,
        isEvaluatorAdminView: PropTypes.bool,
        isPastPhase: PropTypes.bool.isRequired,
        loadAgreementsError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        loadingAgreements: PropTypes.bool,
        menuActionHandler: PropTypes.func.isRequired,
        mustAcceptAgreement: PropTypes.bool.isRequired,
        params: PropTypes.shape({
            evaluatorId: PropTypes.string,
        }).isRequired,
        project: PropTypes.object,
        proposalsPath: PropTypes.string.isRequired,
        proposals: PropTypes.array.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        showEvaluationNotes: PropTypes.func.isRequired,
        showEvaluationScorecard: PropTypes.func.isRequired,
        showInstructionsModal: PropTypes.func.isRequired,
        showProposalSelect: PropTypes.func.isRequired,
        showScoresOnly: PropTypes.bool.isRequired,
        standardDocument: PropTypes.object,
        title: PropTypes.string.isRequired,
        totalWeight: PropTypes.number.isRequired,
        updateProposalEvaluation: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    deleteConsensusEvaluation = () => {
        const {
            project: { id },
        } = this.props;

        this.props.showConfirmationSimpleModal(() => this.props.deleteConsensusEvaluation(id), {
            text: 'Are you sure you want to delete the Consensus Scorecard? All scores and comments will be deleted.',
        });
    };

    reloadConsensusScorecard = () => {
        const { project } = this.props;

        this.props.menuActionHandler(CONSENSUS_EVALUATION, project);
    };

    showConsensusEvaluationInstructions = () => {
        this.props.showInstructionsModal('consensusEvaluationCreated');
    };

    showEvaluationScorecard = (proposal) => () => {
        const { isConsensusView, evaluator } = this.props;

        const userId = isConsensusView ? null : evaluator.id;
        this.props.showEvaluationScorecard(proposal.id, userId);
    };

    showEvaluationNotes = (proposal) => () => {
        const { isConsensusView, evaluator } = this.props;

        const userId = isConsensusView ? null : evaluator.id;
        this.props.showEvaluationNotes(proposal.id, userId);
    };

    submitProposalEvaluation = (proposal) => {
        const {
            isConsensusView,
            params: { evaluatorId },
        } = this.props;

        const data = { status: COMPLETE };
        return this.props.updateProposalEvaluation(proposal, data, evaluatorId, isConsensusView);
    };

    unsubmitProposalEvaluation = (proposal) => {
        const {
            isConsensusView,
            params: { evaluatorId },
        } = this.props;

        const data = { status: OPEN };
        return this.props.updateProposalEvaluation(proposal, data, evaluatorId, isConsensusView);
    };

    renderConsensusScorecardButtons() {
        const { showScoresOnly } = this.props;

        if (showScoresOnly) {
            return null;
        }

        return (
            <div className={this.styles.consensusButtons}>
                <Button onClick={this.reloadConsensusScorecard}>
                    <i className="fa fa-refresh" /> Reset Scorecard
                </Button>
                &nbsp;&nbsp;
                <Button bsStyle="success" onClick={this.props.showProposalSelect}>
                    <i className="fa fa-trophy" /> Award Project
                </Button>
                <br />
                <Button bsSize="sm" bsStyle="link" onClick={this.deleteConsensusEvaluation}>
                    <span className="text-danger">
                        <i className="fa fa-trash" /> Delete Scorecard
                    </span>
                </Button>
            </div>
        );
    }

    render() {
        const {
            acceptedAt,
            agreements,
            allEvaluationPhaseScores,
            error,
            evaluation,
            evaluator,
            getEvaluationPathObj,
            info,
            isConsensusView,
            isEvaluatorAdminView,
            isPastPhase,
            loadAgreementsError,
            loading,
            loadingAgreements,
            mustAcceptAgreement,
            params,
            project,
            proposalsPath,
            proposals,
            showScoresOnly,
            standardDocument,
            title,
            totalWeight,
        } = this.props;

        const evaluatorAgreement = agreements && agreements.find((a) => a.user_id === evaluator.id);
        const evaluatorAccepted = get(evaluatorAgreement, 'accepted');

        if (loading || loadingAgreements) {
            return <LoadingSpinner />;
        }

        if (error || !project || loadAgreementsError) {
            return <LoadingError error={error || loadAgreementsError} />;
        }

        if (!evaluator && !isConsensusView) {
            return <ZeroState title="User is not a part of this evaluation" />;
        }

        if (isPastPhase && isConsensusView) {
            return (
                <ZeroState title="Consensus Scorecard is only viewable from the current evaluation phase" />
            );
        }

        // Can happen if consensus evaluation is deleted and then navigated back to
        if (!evaluation.hasConsensusEvaluation && isConsensusView) {
            return <ZeroState title="Consensus Scorecard has not been created yet" />;
        }

        if (standardDocument && mustAcceptAgreement && !isEvaluatorAdminView && !isConsensusView) {
            return (
                <>
                    <ProposalViewerAgreement
                        isEvaluation
                        project={project}
                        standardDocument={standardDocument}
                    />
                    <div className={this.styles.viewProposalsBtn}>
                        <Button bsStyle="link" to={getEvaluationPathObj('/proposals')} zeroPadding>
                            View submitted responses
                        </Button>
                    </div>
                </>
            );
        }

        return (
            <Main>
                {isEvaluatorAdminView && (
                    <ProposalBackButton
                        text="Back to Evaluators"
                        to={getEvaluationPathObj('/evaluators')}
                    />
                )}
                <SectionTitle
                    info={info || 'List of vendor responses to evaluate'}
                    onHelpClick={
                        isConsensusView ? this.showConsensusEvaluationInstructions : undefined
                    }
                    title={title}
                />
                {isEvaluatorAdminView && standardDocument && !evaluatorAccepted && (
                    <div className="text-center text-warning">
                        <i className="fa fa-exclamation-triangle" /> Evaluator has not accepted the
                        Response Viewer Agreement
                    </div>
                )}
                {isEvaluatorAdminView && standardDocument && evaluatorAccepted && (
                    <ProposalViewerAgreementAccepted
                        acceptedAt={evaluatorAgreement.acceptedAt}
                        standardDocument={standardDocument}
                    />
                )}
                {acceptedAt && !isConsensusView && !isEvaluatorAdminView && (
                    <ProposalViewerAgreementAccepted
                        acceptedAt={acceptedAt}
                        standardDocument={standardDocument}
                    />
                )}
                <ProposalEvaluationsListComponent
                    allEvaluationPhaseScores={allEvaluationPhaseScores}
                    canEvaluate={this.props.canEvaluate}
                    evaluation={evaluation}
                    evaluator={evaluator}
                    getEvaluationPathObj={getEvaluationPathObj}
                    hasQuestionnaire={this.props.hasQuestionnaire}
                    isConsensusView={isConsensusView}
                    isPastPhase={isPastPhase}
                    params={params}
                    project={project}
                    proposals={proposals}
                    proposalsPath={proposalsPath}
                    showEvaluationNotes={this.showEvaluationNotes}
                    showEvaluationScorecard={this.showEvaluationScorecard}
                    showScoresOnly={showScoresOnly}
                    submitProposalEvaluation={this.submitProposalEvaluation}
                    totalWeight={totalWeight}
                    unsubmitProposalEvaluation={this.unsubmitProposalEvaluation}
                />
                {isConsensusView && this.renderConsensusScorecardButtons()}
            </Main>
        );
    }
}

export const ProposalEvaluationsList = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProposalEvaluationsList);

// Evaluator proposal evaluation container
const EvaluatorProposalEvaluationsList = (props) => {
    const {
        evaluator,
        params: { evaluatorId },
    } = props;

    return (
        <ProposalEvaluationsList
            isEvaluatorAdminView
            proposalsPath={`/evaluators/${evaluatorId}/proposal-evaluations`}
            title={`${get(evaluator, 'displayName')}'s Evaluations`}
            {...props}
        />
    );
};
EvaluatorProposalEvaluationsList.propTypes = {
    evaluator: PropTypes.object,
    params: PropTypes.shape({
        evaluatorId: PropTypes.string.isRequired,
    }).isRequired,
};
ProposalEvaluationsList.Evaluator = compose(
    connectData(fetchData),
    withRouter,
    connect((state, props) => {
        return { evaluator: getEvaluator(state, props) };
    })
)(EvaluatorProposalEvaluationsList);

// Current user proposal evaluation container
const UserProposalEvaluationsList = (props) => {
    return (
        <ProposalEvaluationsList
            proposalsPath="/proposal-evaluations"
            title="My Evaluations"
            {...props}
        />
    );
};
ProposalEvaluationsList.User = connectData(fetchData)(UserProposalEvaluationsList);

// Consensus proposal evaluation container
const ConsensusProposalEvaluationsList = (props) => {
    return (
        <ProposalEvaluationsList
            info="Scorecard based on the combined evaluations of all evaluators"
            isConsensusView
            proposalsPath="/consensus-scorecard"
            title="Consensus Scorecard"
            {...props}
        />
    );
};
ProposalEvaluationsList.Consensus = connectData(fetchData)(ConsensusProposalEvaluationsList);
