import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { getFormSyncErrors, getFormNames } from 'redux-form';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@og-pro/ui';

import { ContentBlock } from '../../../SDv2/ContentBlock';
import { CDSButton, CDSButtonGroup } from '../../../SDv2';
import { sectionTypeNames } from '../../../../../../shared_config/sections';

export const CriteriaNeedsReviewItemV2 = ({
    criteria,
    handleInsert,
    projectSection,
    showTermsReviewModal,
    showComment,
    showValidation,
    renderCommentIcon,
    renderInvisibleDragArea,
    title,
}) => {
    const [form] = useSelector((state) => getFormNames()(state));
    const formErrors = useSelector((state) => getFormSyncErrors(form)(state));
    const styles = require('../shared.scss');

    const key = `${projectSection.id}_${get(projectSection, ['projectSubsections', 0, 'id'])}`;
    const arrayOfErrors = get(formErrors, ['criteria', key]);
    const error = Array.isArray(arrayOfErrors) ? arrayOfErrors.find((e) => e.isReviewed) : null;
    const notTextArea = projectSection.section_type !== sectionTypeNames.TEXT_AREA;

    return (
        <Box className={styles.container}>
            <ContentBlock error={error && showValidation ? error.isReviewed : null} withActions>
                <ContentBlock.Main onClick={showTermsReviewModal}>
                    <Box>
                        <Typography variant="h3">{title}</Typography>
                        {criteria.isReviewed && (
                            <Box>
                                <Box className={styles.reviewCompleted} component="span">
                                    <i className="fa fa-check-circle" /> Review Completed
                                </Box>
                                <Box className={styles.link} component="span">
                                    &nbsp;- Click item to view or revise your response
                                </Box>
                            </Box>
                        )}
                        {!criteria.isReviewed && (
                            <Box className={styles.link}>Click item to complete review</Box>
                        )}
                    </Box>
                </ContentBlock.Main>
                <ContentBlock.ActionSidebar>
                    <ContentBlock.ButtonGroup hidden={!showComment}>
                        <ContentBlock.Button>{renderCommentIcon()}</ContentBlock.Button>
                        {renderInvisibleDragArea()}
                    </ContentBlock.ButtonGroup>
                </ContentBlock.ActionSidebar>
            </ContentBlock>
            {!!handleInsert && projectSection.isWritingForm && notTextArea && (
                <CDSButtonGroup>
                    <CDSButton
                        onClick={() => handleInsert(criteria.orderById)}
                        qaTag="projectCreate-insertCriteria"
                        size="small"
                        variant="secondary"
                    >
                        <i className="fa fa-plus" /> Add a List item
                    </CDSButton>
                </CDSButtonGroup>
            )}
        </Box>
    );
};

CriteriaNeedsReviewItemV2.propTypes = {
    criteria: PropTypes.object,
    handleInsert: PropTypes.func,
    projectSection: PropTypes.object,
    showTermsReviewModal: PropTypes.func.isRequired,
    showComment: PropTypes.bool,
    showValidation: PropTypes.bool,
    renderCommentIcon: PropTypes.func.isRequired,
    renderInvisibleDragArea: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};
