import classNames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { submit } from 'redux-form';

import { Button, RequisitionConfirmationCreateForm } from '../../../../components';
import {
    fieldNames,
    form,
} from '../../../../components/Forms/RequisitionConfirmationCreateForm/constants';
import { useFiscalPeriodFormDisplay } from '../../../../lib/ogFinancials';
import { getIsRequisitionUsingFMS } from '../../../../selectors/govApp';

const {
    EXPECTED_PURCHASE_ORDER_DATE,
    REQUESTOR_ID,
    REVIEW_GROUP_ID,
    CREATOR_ID,
    FISCAL_PERIOD_OBJECT,
    FISCAL_PERIOD,
} = fieldNames;

const ConnectedCreateConfirmation = ({ data, submitForm, submitHandler, user }) => {
    const styles = require('./shared.scss');

    const hasFMS = useSelector(getIsRequisitionUsingFMS);

    const { isError, isLoadingFiscalPeriodsRange, isLoadingFiscalPeriods } =
        useFiscalPeriodFormDisplay(form, FISCAL_PERIOD_OBJECT, FISCAL_PERIOD);

    const isLoading = isLoadingFiscalPeriodsRange || isLoadingFiscalPeriods;

    const initialProjectOwnershipFormValues = {
        [EXPECTED_PURCHASE_ORDER_DATE]: null,
        [REQUESTOR_ID]: get(data, REQUESTOR_ID) || user.id,
        [REVIEW_GROUP_ID]: get(data, REVIEW_GROUP_ID) || data?.reviewGroup?.id,
        [CREATOR_ID]: get(data, CREATOR_ID) || user.id,
    };

    const disabledSubmit = hasFMS ? isLoading || isError : false;

    return (
        <div className={styles.confirmationContainer}>
            <div className={styles.confirmTitle}>Ready to Go!</div>
            <RequisitionConfirmationCreateForm
                initialValues={initialProjectOwnershipFormValues}
                onSubmit={submitHandler}
                user={user}
            />
            <div>
                <Button
                    bsSize="lg"
                    bsStyle="success"
                    disabled={disabledSubmit}
                    onClick={() => submitForm(form)}
                    qaTag="requisitionConfirmationCreateForm-createRequisition"
                >
                    <i className="fa fa-plus" /> Create Requisition
                </Button>
            </div>
            {hasFMS && isLoading && (
                <div className={styles.requisitionLoadingStatus}>
                    <i className="fa fa-spin fa-spinner" /> Loading fiscal periods from your
                    accounting system
                </div>
            )}
            {hasFMS && isError && (
                <div className={classNames(styles.requisitionLoadingStatus, 'text-danger')}>
                    <i className="fa fa-chain-broken" /> Error loading fiscal periods from your
                    accounting system
                    <br />
                    Requisition cannot be created until connection is restored
                </div>
            )}
        </div>
    );
};

ConnectedCreateConfirmation.propTypes = {
    data: PropTypes.object,
    submitForm: PropTypes.func.isRequired,
    submitHandler: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

export const RequisitionCreateConfirmation = connect(null, { submitForm: submit })(
    ConnectedCreateConfirmation
);
