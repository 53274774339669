import { browserHistory } from '@og-pro-migration-tools/react-router';
import { change } from 'redux-form';

import { saveSerializer } from './serializers';
import { loadApprovals } from '../../approvals';
import {
    hideConfirmationModal,
    showConfirmationModal,
    showConfirmationModalError,
    updatingConfirmationModal,
} from '../../confirmation';
import { createContract as realCreateContract } from '../../contracts';
import { showInstructionsModal } from '../../govProjects';
import { showNotification, showSnackbar } from '../../notification';
import { forceReloadConnectedClientProjects } from '../../projectSocket';
import { emitProjectSocket } from '../../utils';
import { trackEvent } from '../../../helpers';
import { CHANGE_PRESET_WORKFLOW } from '../../../constants/menuActions';
import { contractStatesDict } from '../../../../../shared_config/contracts';
import { evaluationStatuses } from '../../../../../shared_config/evaluations';
import { buildMap } from '../../../../../shared_config/helpers';
import { moduleTypesDict } from '../../../../../shared_config/modules';
import { projectStatusesDict } from '../../../../../shared_config/projects';
import { showSaveError, showSaveModal, showSaveSuccess } from '../../app';

const { DRAFT, EVALUATION, POST_PENDING, REQUEST_DRAFT } = projectStatusesDict;

const { BUILDER, EVALUATION: EVALUATION_MODULE, INTAKE, SOURCING } = moduleTypesDict;

export const INITIALIZE = 'projectCreate/INITIALIZE';
export const SHOW_DEPARTMENT_MODAL = 'projectCreate/SHOW_DEPARTMENT_MODAL';
export const HIDE_DEPARTMENT_MODAL = 'projectCreate/HIDE_DEPARTMENT_MODAL';
export const SHOW_HELP_MODAL = 'projectCreate/SHOW_HELP_MODAL';
export const HIDE_HELP_MODAL = 'projectCreate/HIDE_HELP_MODAL';
export const SHOW_CREATE_MODAL = 'projectCreate/SHOW_CREATE_MODAL';
export const HIDE_CREATE_MODAL = 'projectCreate/HIDE_CREATE_MODAL';

export const SHOW_TERMS_REVIEW_MODAL = 'projectCreate/SHOW_TERMS_REVIEW_MODAL';
export const HIDE_TERMS_REVIEW_MODAL = 'projectCreate/HIDE_TERMS_REVIEW_MODAL';

export const RESET_CREATE_ERROR = 'projectCreate/RESET_CREATE_ERROR';
export const CREATE = 'projectCreate/CREATE';
export const CREATE_SUCCESS = 'projectCreate/CREATE_SUCCESS';
export const CREATE_FAIL = 'projectCreate/CREATE_FAIL';
export const INITIATE_SUBMIT = 'projectCreate/INITIATE_SUBMIT';
export const RESET_SUBMIT = 'projectCreate/RESET_SUBMIT';
export const SUBMIT = 'projectCreate/SUBMIT';
export const SUBMIT_SUCCESS = 'projectCreate/SUBMIT_SUCCESS';
export const SUBMIT_FAIL = 'projectCreate/SUBMIT_FAIL';
export const INITIATE_UPDATE = 'projectCreate/INITIATE_UPDATE';
export const INITIATE_SNEAKY_UPDATE = 'projectCreate/INITIATE_SNEAKY_UPDATE';
export const RESET_SNEAKY_UPDATE = 'projectCreate/RESET_SNEAKY_UPDATE';

export const UPDATE = 'projectCreate/UPDATE';
export const UPDATE_SUCCESS = 'projectCreate/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'projectCreate/UPDATE_FAIL';

export const UPDATE_CATEGORIES = 'projectCreate/UPDATE_CATEGORIES';
export const UPDATE_CATEGORIES_SUCCESS = 'projectCreate/UPDATE_CATEGORIES_SUCCESS';
export const UPDATE_CATEGORIES_FAIL = 'projectCreate/UPDATE_CATEGORIES_FAIL';

export const INITIATE_EXPORT = 'projectCreate/INITIATE_EXPORT';
export const INITIATE_EXPORT_DOCUMENT = 'projectCreate/INITIATE_EXPORT_DOCUMENT';
export const RESET = 'projectCreate/RESET';
export const DESTROY = 'projectCreate/DESTROY';
export const SET_FORM_COMPLETE_CONFIRMATION = 'projectCreate/SET_FORM_COMPLETE_CONFIRMATION';

/**
 * Shows the project create modal
 * @param  {object} [data] Data to use to set initial values on project create
 * @return {object}
 */
export function showProjectCreateModal(data) {
    return { type: SHOW_CREATE_MODAL, data };
}

export function hideCreateModal() {
    return { type: HIDE_CREATE_MODAL };
}

export function reset() {
    return {
        type: RESET,
    };
}

/**
 * Creates a project
 * @param  {object} projectCreateData Data to use in creating project
 * @param  {object} additionalData Additional data to use in creating the project
 * @param  {string} additionalData.loadingText Text to display while project creation is happending
 * @param  {string} additionalData.nextRoutePath Path to the next page
 * @param  {string} [additionalData.eventName] Name of the tracked event
 * @param  {object} [additionalData.eventData] Data to send in the tracked event
 * @param  {object} [additionalData.onComplete] Function to execute after project creation
 * @return {Promise} The API request
 */
function createProject(projectCreateData, additionalData) {
    return (dispatch, getState, client) => {
        const { eventData, eventName, loadingText, nextRoutePath, onComplete } = additionalData;

        dispatch({ type: CREATE, text: loadingText });

        return client
            .post('/project', { data: projectCreateData })
            .then((result) => {
                const {
                    government_id: governmentId,
                    id: projectId,
                    isIntake,
                    template,
                    type,
                } = result;

                dispatch({ type: CREATE_SUCCESS, result });

                // Reset the project create data so we can start fresh
                dispatch(reset());

                trackEvent('Project Created', {
                    projectId,
                    template: template.title,
                    type: isIntake ? 'intake' : type,
                });

                // Track additional events
                if (eventName) {
                    trackEvent(eventName, {
                        projectId,
                        ...eventData,
                    });
                }

                // Execute complete handler if provided
                if (onComplete) {
                    onComplete(result);
                }

                // Navigate to next page
                if (nextRoutePath) {
                    browserHistory.push(
                        `/governments/${governmentId}/projects/${projectId}${nextRoutePath}`
                    );
                }
            })
            .catch((error) => {
                window.scroll(0, 0);
                dispatch({ type: CREATE_FAIL, error });
            });
    };
}

export function createBuilderProject(configData) {
    return (dispatch) => {
        const data = {
            ...configData,
            status: DRAFT,
            moduleType: BUILDER,
        };

        const additionalData = {
            eventName: 'Builder Project Created',
            loadingText: 'Creating Project From Template...',
            nextRoutePath: '/builder/create/project-properties',
            onComplete: (result) => {
                // When creating project from intake force connected client to reload intake
                if (result.intake_id) {
                    dispatch(forceReloadConnectedClientProjects(result.intake_id));
                }
            },
        };

        return dispatch(createProject(data, additionalData));
    };
}

// Creates a project used for making a intake
export function createIntakeProject(configData) {
    return (dispatch) => {
        const data = {
            // Intentionally do not add dates to intake projects
            ...configData,
            isIntake: true,
            status: REQUEST_DRAFT,
            moduleType: INTAKE,
        };

        const additionalData = {
            eventName: 'Intake Project Created',
            eventData: { isIntake: true },
            loadingText: 'Creating Project Request...',
            nextRoutePath: '/intake/create/project-properties',
        };

        return dispatch(createProject(data, additionalData));
    };
}

// Creates a new project for the purpose of posting
export function createPostProject(configData) {
    return (dispatch) => {
        const data = {
            ...configData,
            isPostOnly: true,
            status: POST_PENDING,
            moduleType: SOURCING,
        };

        const additionalData = {
            eventName: 'Post Project Created',
            eventData: { isPostOnly: true },
            loadingText: 'Creating Post...',
            nextRoutePath: '/sourcing/create',
        };

        return dispatch(createProject(data, additionalData));
    };
}

// Creates a new project for the purpose of starting an evaluation
export function createEvaluationProject(configData) {
    return (dispatch) => {
        const data = {
            ...configData,
            isEvaluationOnly: true,
            status: EVALUATION,
            evaluation: { status: evaluationStatuses.DRAFT },
            moduleType: EVALUATION_MODULE,
        };

        const additionalData = {
            eventName: 'Evaluation Project Created',
            eventData: { isEvaluationOnly: true },
            loadingText: 'Creating Evaluation...',
            nextRoutePath: '/evaluation/create',
        };

        return dispatch(createProject(data, additionalData));
    };
}

// Creates a new contract
export function createContract(configData, projectId) {
    return (dispatch, getState) => {
        const governmentId = getState().auth.getIn(['user', 'government', 'id']);

        const data = {
            ...configData,
            governmentId, // Needed for the `realCreateContract` action (helps route to next page)
            projectId,
            state: contractStatesDict.DRAFT,
        };

        dispatch(realCreateContract(data));
        dispatch(hideConfirmationModal());
    };
}

export const SHOW_AUTOMATED_ADDENDUM_MODAL = 'projectCreate/SHOW_AUTOMATED_ADDENDUM_MODAL';
export const HIDE_AUTOMATED_ADDENDUM_MODAL = 'projectCreate/HIDE_AUTOMATED_ADDENDUM_MODAL';

export function showAutomatedAddendumModal() {
    return { type: SHOW_AUTOMATED_ADDENDUM_MODAL };
}
export function hideAutomatedAddendumModal() {
    return { type: HIDE_AUTOMATED_ADDENDUM_MODAL };
}

/**
 * Wraps the function with the needed parameters to read data from the store, dispatch additional
 * options, and make network requests if needed.
 * @callback reduxActionCallback
 * @param {function} dispatch The `dispatch` function of the store
 * @param {function} getState The `getState` function of the store
 * @param {object} client The HTTP(S) client to make requests with
 */

/**
 * Update a project. Primarily used to update builder projects, but can also be used to update
 * sourcing projects along with individual project fields.
 *
 * TODO: really it can do anything with the `opts.endpoint` config option. This should probably be
 * generalized somewhere at some point.
 * @param {object} formData The form values to submit
 * @param {number} projectId The project ID
 * @param {string} status The project's `status` field
 * @param {object} [opts={}] Options for the project
 * @param {boolean} [opts.notify] Displays a save status modal
 * @param {string} [opts.endpoint] Use a different endpoint than the standard project update
 * @param {boolean} [opts.snackbar] Display a snackbar notification when save succeeds
 * @param {boolean} [opts.modal] Used when update is performed within the confirmation modal
 * @param {function} [opts.onComplete] Function to run after successful save
 * @return {reduxActionCallback} The wrapped update call
 */
export function update(formData, projectId, status, opts = {}) {
    return (dispatch, getState, client) => {
        const timezone = getState().auth.getIn(['user', 'organization', 'timezone']);
        dispatch({ type: UPDATE });

        if (opts.notify) {
            dispatch(showSaveModal());
        }

        const data = saveSerializer(formData, status, timezone);
        const endpoint = opts.endpoint || `/project/${projectId}`;

        return client
            .put(endpoint, { data })
            .then((result) => {
                const updateAction = { type: UPDATE_SUCCESS, result };
                dispatch(emitProjectSocket(projectId, updateAction));

                if (opts.notify) {
                    const afterSaveHandler = () => {
                        if (result.wasPosted) {
                            dispatch(showAutomatedAddendumModal());
                        }
                    };
                    dispatch(showSaveSuccess(afterSaveHandler));
                }
                if (opts.snackbar) dispatch(showSnackbar('Project Saved!'));
                if (opts.modal) dispatch(hideConfirmationModal());
                if (opts.onComplete) return opts.onComplete(result);
            })
            .catch((error) => {
                dispatch({ type: UPDATE_FAIL, error });
                if (opts.notify) {
                    dispatch(showSaveError(error.message));
                } else if (opts.modal) {
                    dispatch(showConfirmationModalError(error.message));
                } else {
                    dispatch(showNotification(error.message, { type: 'danger' }));
                }
            });
    };
}

export function submitProject(formData, projectId, nextRoute, status) {
    return (dispatch, getState, client) => {
        const timezone = getState().auth.getIn(['user', 'organization', 'timezone']);
        dispatch({ type: SUBMIT });
        dispatch(updatingConfirmationModal());

        const data = saveSerializer(formData, status, timezone);

        return client
            .put(`/project/${projectId}`, { data })
            .then((result) => {
                const submitAction = { type: SUBMIT_SUCCESS, result };
                dispatch(emitProjectSocket(projectId, submitAction));

                dispatch(hideConfirmationModal());

                trackEvent('Project Submitted For Review');
                trackEvent('Project Status Update', {
                    projectId,
                    oldStatus: formData.status,
                    newStatus: status,
                });

                // Reload the approvals
                dispatch(loadApprovals(projectId, { broadcast: true })).then((approvals) => {
                    const hasPresetApprovals = Array.isArray(approvals) && approvals.length > 0;
                    // Show the initial approvals instruction modal
                    dispatch(
                        showInstructionsModal('intakeIssued', {
                            hasPresetApprovals,
                            isStepComplete: true,
                        })
                    );
                });

                browserHistory.push(nextRoute);
            })
            .catch((error) => {
                dispatch({ type: SUBMIT_FAIL, error });
                dispatch(showConfirmationModalError(error.message));
            });
    };
}

export function resetSubmit() {
    return {
        type: RESET_SUBMIT,
    };
}

export function initiateSubmit() {
    return {
        type: INITIATE_SUBMIT,
    };
}

function initiateUpdate() {
    return {
        type: INITIATE_UPDATE,
    };
}

export function initiateSneakyUpdate(nextRoute) {
    return {
        type: INITIATE_SNEAKY_UPDATE,
        nextRoute,
    };
}

export function resetSneakyUpdate() {
    return {
        type: RESET_SNEAKY_UPDATE,
    };
}

export function initiateExport() {
    return {
        type: INITIATE_EXPORT,
    };
}

export function initiateExportDocument() {
    return {
        type: INITIATE_EXPORT_DOCUMENT,
    };
}

/**
 * Called to signal that the form has been initialized.
 * Must be called after loading the project or the form will not render.
 *
 * @return {object}
 */
export function initialize() {
    return {
        type: INITIALIZE,
    };
}

export function showDepartmentModal() {
    return (dispatch) => {
        dispatch({ type: SHOW_DEPARTMENT_MODAL });
    };
}

export function hideDepartmentModal() {
    return {
        type: HIDE_DEPARTMENT_MODAL,
    };
}

export function changeDepartmentForm(values, formName) {
    return (dispatch) => {
        dispatch(change(formName, 'departmentName', values.name));
        dispatch(change(formName, 'departmentHead', values.departmentHead));
        dispatch(change(formName, 'departmentHeadTitle', values.departmentHeadTitle));
    };
}

export function showHelpModal(data = {}) {
    return {
        type: SHOW_HELP_MODAL,
        data,
    };
}

export function hideHelpModal() {
    return {
        type: HIDE_HELP_MODAL,
    };
}

export function showTermsReviewModal(data) {
    return {
        type: SHOW_TERMS_REVIEW_MODAL,
        data,
    };
}

export function hideTermsReviewModal() {
    return {
        type: HIDE_TERMS_REVIEW_MODAL,
    };
}

export function resetCreateError() {
    return {
        type: RESET_CREATE_ERROR,
    };
}

export const SHOW_FORM_VALIDATION = 'projectCreate/SHOW_FORM_VALIDATION';

export function showFormValidation() {
    return {
        type: SHOW_FORM_VALIDATION,
    };
}

export function setFormCompleteConfirmation(data) {
    return { type: SET_FORM_COMPLETE_CONFIRMATION, data };
}

export const SCROLL_TO_TOP = 'projectCreate/SCROLL_TO_TOP';

export function scrollPageToTop() {
    return { type: SCROLL_TO_TOP, scrollToTop: true };
}

export function resetScrollPageToTop() {
    return { type: SCROLL_TO_TOP, scrollToTop: false };
}

export function initiateSave(isSaveValid) {
    return (dispatch) => {
        if (!isSaveValid) {
            // Show form validation if save is invalid
            trackEvent('Invalid Save Project');
            return dispatch(showFormValidation());
        }
        // Proceed with saving
        trackEvent('Save Project');
        return dispatch(initiateUpdate());
    };
}

export const LOAD_BUILDER_SECTIONS = 'projectCreate/LOAD_BUILDER_SECTIONS';
export const LOAD_BUILDER_SECTIONS_FAIL = 'projectCreate/LOAD_BUILDER_SECTIONS_FAIL';
export const LOAD_BUILDER_SECTIONS_SUCCESS = 'projectCreate/LOAD_BUILDER_SECTIONS_SUCCESS';

export function shouldLoadBuilderSections(state) {
    return (
        !state.projectCreate.get('loadingBuilderSections') &&
        !state.projectCreate.get('loadedBuilderSections')
    );
}

export function loadBuilderSections(projectId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_BUILDER_SECTIONS });
        return client
            .get(`/project/${projectId}/builder-sections`)
            .then((result) => {
                const builderSectionsMap = buildMap(result, 'project_section_id');
                dispatch({ type: LOAD_BUILDER_SECTIONS_SUCCESS, result: builderSectionsMap });
            })
            .catch((error) => dispatch({ type: LOAD_BUILDER_SECTIONS_FAIL, error }));
    };
}

export const VISIT_PROJECT_SECTION = 'projectCreate/VISIT_PROJECT_SECTION';

export function markBuilderSectionVisited(projectId, builderSection) {
    return (dispatch, getState, client) => {
        if (!builderSection || builderSection.wasVisited) {
            return false;
        }

        dispatch({
            type: VISIT_PROJECT_SECTION,
            projectSectionId: builderSection.project_section_id,
        });

        // Make the API call, but do not wait for the response (optimistically mark as visited)
        client.put(`/project/${projectId}/builder-sections/${builderSection.id}/visited`);

        return true;
    };
}

export function updateProjectCategories(projectId, categories) {
    return (dispatch, getState, client) => {
        dispatch({ type: UPDATE_CATEGORIES });
        const data = (categories || []).map((cat) => cat.id);
        return client
            .put(`/project/${projectId}/categories`, { data })
            .then((result) => {
                dispatch({ type: UPDATE_CATEGORIES_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: UPDATE_CATEGORIES_FAIL, error });
            });
    };
}

export function getProjectAssociatedCategories(projectId) {
    return (dispatch, getState, client) => {
        return client.get(`/project/${projectId}/associated-categories`);
    };
}

export function copyProjectDocument(projectId, data) {
    return (dispatch, getState, client) => {
        dispatch(updatingConfirmationModal());
        return client
            .post(`/project/${projectId}/copy`, { data })
            .then((result) => {
                dispatch(hideConfirmationModal());
                dispatch(showSnackbar('Project copied!'));
                browserHistory.push(`/governments/${result.government_id}/projects/${result.id}`);
            })
            .catch((error) => {
                dispatch(showConfirmationModalError(error.message));
                dispatch({ type: CREATE_FAIL, error });
            });
    };
}

export function copyProjectDocumentToIntake(projectId) {
    return (dispatch, getState, client) => {
        dispatch(updatingConfirmationModal());
        return client
            .post(`/project/${projectId}/copy-to-intake`)
            .then((result) => {
                dispatch(hideConfirmationModal());
                dispatch(showSnackbar('Intake Created from Project'));
                browserHistory.push(`/governments/${result.government_id}/projects/${result.id}`);
            })
            .catch((error) => {
                dispatch(showConfirmationModalError(error.message));
                dispatch({ type: CREATE_FAIL, error });
            });
    };
}

export function getProjectAutoNumber(projectId) {
    return (dispatch, getState, client) => {
        const autoNumberId = getState().auth.getIn(['user', 'government', 'project_auto_id']);
        return client.get(`/auto-numbers/${autoNumberId}`, { params: { project: projectId } });
    };
}

export function incrementProjectAutoNumber(projectId) {
    return (dispatch, getState, client) => {
        const autoNumberId = getState().auth.getIn(['user', 'government', 'project_auto_id']);
        return client.put(`/auto-numbers/${autoNumberId}`, { params: { project: projectId } });
    };
}

export function getApprovalWorkflowsFromProjectCreate(
    projectId,
    departmentId,
    selectedDepartmentValue,
    currentDepartmentName
) {
    return (dispatch, getState, client) => {
        return client
            .get(`/project/${projectId}/department-approval-workflows/${departmentId}`)
            .then((result) => {
                if (result.currentDeptWorkflow?.id !== result.newDeptWorkflow?.id) {
                    dispatch(
                        showConfirmationModal(CHANGE_PRESET_WORKFLOW, {
                            projectId,
                            departmentId,
                            selectedDepartmentValue,
                            currentDepartmentName,
                        })
                    );
                }
            });
    };
}
