import { get, pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { RequisitionGroupTitleMenu } from './RequisitionGroupTitleMenu';
import { RequisitionGroupForm } from './RequisitionGroupForm';
import { fieldNames, fields, form } from './RequisitionGroupForm/constants';
import { validate } from './RequisitionGroupForm/validate';
import { getDeserializedRequisitionGroup, getRequisitionGroupFormValues } from './selectors';
import { getRequisitionGroupsPath } from '../selectors';
import connectData from '../../../ConnectData';
import {
    loadRequisitionGroup,
    resetRequisitionGroupEdit,
    updateRequisitionGroup,
} from '../../../../actions/requisitionGroups';
import { loadReviewSequences } from '../../../../actions/reviewSequences';
import {
    Button,
    LoadingError,
    LoadingSpinner,
    Main,
    PageTitle,
    RouteLeaveWarning,
} from '../../../../components';
import { ARCHIVED, DRAFT, PUBLISHED } from '../../../../../../shared_config/reviewGroups';
import { statusTypes } from '../../../../../../shared_config/reviewSequences';

const { NAME } = fieldNames;

const fetchData = (getState, dispatch, location, params) => {
    const requisitionGroupId = Number.parseInt(params.requisitionGroupId, 10);
    return Promise.all([
        dispatch(loadRequisitionGroup(requisitionGroupId)),
        dispatch(loadReviewSequences({ status: statusTypes.PUBLISHED, view: 'group-positions' })),
    ]);
};

const formConfig = {
    enableReinitialize: true,
    form,
    validate,
};

const mapStateToProps = (state) => {
    const requisitionGroup = getDeserializedRequisitionGroup(state);
    return {
        initialValues: pick(requisitionGroup, fields),
        requisitionGroup,
    };
};

// @connectData
// @connect
// @reduxForm
const ConnectedRequisitionGroupEdit = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const { change, dirty, handleSubmit, requisitionGroup, submitFailed } = props;

    const [showFormValidation, setShowFormValidation] = useState(false);

    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.requisitionGroups.get('loadingItem'));
    const loadError = useSelector((state) => state.requisitionGroups.get('loadItemError'));

    const copying = useSelector((state) => state.requisitionGroups.get('copyingItem'));
    const deleting = useSelector((state) => state.requisitionGroups.get('deletingItem'));
    const updating = useSelector((state) => state.requisitionGroups.get('updatingItem'));

    const requisitionGroupsPath = useSelector(() => getRequisitionGroupsPath({ location, params }));
    const formValues = useSelector(getRequisitionGroupFormValues);

    useEffect(() => {
        return () => dispatch(resetRequisitionGroupEdit());
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (loadError || !requisitionGroup) {
        return <LoadingError error={loadError} />;
    }

    const { id, status } = requisitionGroup;
    const isArchived = status === ARCHIVED;
    const isPublished = status === PUBLISHED;

    const updateRequisitionGroupFn = (data, options) => {
        return dispatch(updateRequisitionGroup(id, data, options));
    };

    const toggleLive = () => {
        if (isPublished) {
            const submitData = {
                ...formValues,
                status: DRAFT,
            };
            return updateRequisitionGroupFn(submitData, {
                successMessage: 'Review group disabled',
            });
        }

        setShowFormValidation(true);

        return handleSubmit((data) => {
            const submitData = {
                ...data,
                status: PUBLISHED,
            };
            setShowFormValidation(false);
            return updateRequisitionGroupFn(submitData, {
                successMessage: 'Review group set live!',
            });
        })();
    };

    const toggleArchive = () => {
        const submitData = {
            ...formValues,
            status: isArchived ? DRAFT : ARCHIVED,
        };
        return updateRequisitionGroupFn(submitData, {
            onSuccess: isArchived ? undefined : () => navigate(requisitionGroupsPath),
            successMessage: `Review group ${isArchived ? 'enabled' : 'archived'}`,
        });
    };

    const saveRequisitionGroup = (additionalData) => {
        // If the template has been published we want to validate the form before submitting
        if (isPublished) {
            setShowFormValidation(true);
            return handleSubmit((data) => {
                setShowFormValidation(false);
                return updateRequisitionGroupFn({ ...data, ...additionalData });
            })();
        }

        return updateRequisitionGroupFn(formValues);
    };

    const disabled = copying || deleting || updating;

    return (
        <Main>
            <PageTitle title="Edit Requisition Group" />
            <div>
                <Button
                    bsSize="sm"
                    bsStyle="link"
                    qaTag="requisitionGroupEdit-back"
                    to={requisitionGroupsPath}
                >
                    <i className="fa fa-angle-left" /> Requisition Groups
                </Button>
            </div>
            <RouteLeaveWarning blockingValue={dirty} />
            <RequisitionGroupTitleMenu
                deleting={deleting}
                disabled={disabled}
                requisitionGroup={requisitionGroup}
                requisitionGroupsPath={requisitionGroupsPath}
                setShowFormValidation={setShowFormValidation}
                submitFailed={submitFailed}
                title={get(formValues, NAME)}
                toggleArchive={toggleArchive}
                toggleLive={toggleLive}
                updateRequisitionGroup={saveRequisitionGroup}
                updating={updating}
            />
            <RequisitionGroupForm
                change={change}
                disabled={isArchived || disabled}
                isPublished={isPublished}
                requisitionGroupFormValues={formValues}
                setShowFormValidation={setShowFormValidation}
                showFormValidation={showFormValidation}
                toggleLive={toggleLive}
                updateRequisitionGroup={saveRequisitionGroup}
            />
        </Main>
    );
};

ConnectedRequisitionGroupEdit.propTypes = {
    change: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    requisitionGroup: PropTypes.object,
    submitFailed: PropTypes.bool.isRequired,
};

export const RequisitionGroupEdit = compose(
    connectData(fetchData),
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedRequisitionGroupEdit);
