import { listToDict } from '../../../../../../shared_config/helpers';
import { requisitionFiltersNames } from '../../../../../../shared_config/requisitions';

export const qaTagPageName = 'requisitionsDashboard';

const { ON, AFTER, BEFORE, END, START, LAST_ACTION, CREATORS } = requisitionFiltersNames;

const fields = [
    'sorting',
    'oneDateType',
    ON,
    BEFORE,
    AFTER,
    END,
    START,
    'search',
    LAST_ACTION,
    CREATORS,
    'quickFilter',
    'dateType',
];

export const fieldNames = listToDict(fields);

export const dateFormat = 'MM/DD/YYYY';
