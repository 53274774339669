import { createSelector } from 'reselect';

import {
    inboxNotificationCategorySets,
    inboxNotificationStatusesDict,
} from '../../../../../shared_config/audits';
import {
    hasContractingSubscription,
    hasDocBuilderSubscription,
    hasEvaluationSubscription,
    hasIntakeSubscription,
    hasRequisitionSubscription,
    hasSourcingSubscription,
} from '../selectors';

const { BUILDER, CONTRACTS, EVALUATIONS, INTAKE, REQUISITIONS, SOURCING } =
    inboxNotificationCategorySets;

const { COMPLETED, IN_PROGRESS, NEW } = inboxNotificationStatusesDict;

const getInboxNotifications = (state) => state.inboxNotifications.get('inboxNotifications');

export const getInboxNotificationsJS = createSelector(
    [
        getInboxNotifications,
        hasContractingSubscription,
        hasDocBuilderSubscription,
        hasEvaluationSubscription,
        hasIntakeSubscription,
        hasRequisitionSubscription,
        hasSourcingSubscription,
    ],
    (
        rawInboxNotifications,
        hasContracting,
        hasDocBuilder,
        hasEvaluation,
        hasIntake,
        hasRequisition,
        hasSourcing
    ) => {
        if (rawInboxNotifications) {
            const inboxNotifications = rawInboxNotifications.toJS();
            const defaultCategorizedNotifications = {
                ...(hasDocBuilder && {
                    [BUILDER]: {
                        [NEW]: [],
                        [IN_PROGRESS]: [],
                        [COMPLETED]: [],
                    },
                }),
                ...(hasContracting && {
                    [CONTRACTS]: {
                        [NEW]: [],
                        [IN_PROGRESS]: [],
                        [COMPLETED]: [],
                    },
                }),
                ...(hasEvaluation && {
                    [EVALUATIONS]: {
                        [NEW]: [],
                        [IN_PROGRESS]: [],
                        [COMPLETED]: [],
                    },
                }),
                ...(hasIntake && {
                    [INTAKE]: {
                        [NEW]: [],
                        [IN_PROGRESS]: [],
                        [COMPLETED]: [],
                    },
                }),
                ...(hasRequisition && {
                    [REQUISITIONS]: {
                        [NEW]: [],
                        [IN_PROGRESS]: [],
                        [COMPLETED]: [],
                    },
                }),
                ...(hasSourcing && {
                    [SOURCING]: {
                        [NEW]: [],
                        [IN_PROGRESS]: [],
                        [COMPLETED]: [],
                    },
                }),
            };

            return inboxNotifications.reduce((categorizedNotifications, notification) => {
                const {
                    category,
                    emailAudit: { subject },
                    status,
                } = notification;

                if (categorizedNotifications[category]) {
                    categorizedNotifications[category][status].push({ ...notification, subject });
                }
                return categorizedNotifications;
            }, defaultCategorizedNotifications);
        }
        return null;
    }
);
