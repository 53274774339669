import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Panel } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import { getUserJS } from '../../selectors';
import { LoadingSpinner, LoadingError, VendorProfile } from '../../../components';
import { loadPublicVendor } from '../../../actions/publicVendor';

export const VendorPublicProfile = () => {
    const { governmentCode, vendorCode } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (vendorCode && governmentCode) {
            dispatch(loadPublicVendor(vendorCode, governmentCode));
        }
    }, [dispatch, vendorCode, governmentCode]);

    const loading = useSelector((state) => state.publicVendor.get('loadingVendor'));
    const loadError = useSelector((state) => state.publicVendor.get('loadVendorError'));
    const vendor = useSelector((state) => state.publicVendor.get('vendor')?.toJS());
    const user = useSelector(getUserJS);

    const styles = require('./styles.scss');

    // Types of users:
    const vendorIsViewingOwnProfile = user?.organization_id === vendor?.organization_id;
    const govUserSameAgencyAsTheProfile = user?.government?.code === governmentCode;

    const isInternalView = govUserSameAgencyAsTheProfile || vendorIsViewingOwnProfile;

    if (loadError) return <LoadingError error={loadError ?? 'No Vendor Found'} />;
    if (loading || !vendor) return <LoadingSpinner />;
    return (
        <div className="row" id="skip">
            {govUserSameAgencyAsTheProfile && (
                <div className={styles.backContainer}>
                    <Link to={`/governments/${user?.government_id}/vendors`}>
                        <div className={styles.arrowContainer}>
                            <ArrowBackRoundedIcon className={styles.back} />
                            <p className={styles.backText}>Vendor Management</p>
                        </div>
                    </Link>
                </div>
            )}
            <h1 className="visually-hidden">View Profile</h1>
            <div className="col-lg-8 col-lg-offset-2">
                <Panel>
                    <Panel.Body>
                        <div className="col-sm-10 col-sm-offset-1">
                            <VendorProfile
                                isRatingEnabled
                                publicView={!isInternalView}
                                showEdit={vendorIsViewingOwnProfile}
                                showShareableLink={false}
                                vendor={vendor}
                            />
                        </div>
                    </Panel.Body>
                </Panel>
            </div>
        </div>
    );
};
