import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@og-pro/ui';

import { VerifiedIcon } from '../..';
import { getCertificationTitle } from '../../../../../shared_config/certifications';

export const Certification = ({ certification, isVerified }) => {
    const styles = require('../VendorProfile.scss');

    return (
        <Box className={classnames('row', styles.certification)}>
            <div className={classnames('col-md-5', styles.certificationType)}>
                {getCertificationTitle(certification.certification_id)}
                {isVerified && <VerifiedIcon />}
            </div>
            <div className={classnames('col-md-7', styles.certificationDetails)}>
                {certification.certifyingAgency && <div>{certification.certifyingAgency}</div>}
                {certification.certificateNumber && <div>{certification.certificateNumber}</div>}
                {certification.ethnicity && <div>{certification.ethnicity}</div>}
                {certification.issuedDate && (
                    <div>
                        Issued: {new Date(certification.issuedDate).toLocaleDateString('en-US')}
                    </div>
                )}
                {certification.expirationDate && (
                    <div>
                        Expires:{' '}
                        {new Date(certification.expirationDate).toLocaleDateString('en-US')}
                    </div>
                )}
            </div>
        </Box>
    );
};

Certification.propTypes = {
    certification: PropTypes.shape({
        certification_id: PropTypes.number,
        certifyingAgency: PropTypes.string,
        certificateNumber: PropTypes.string,
        ethnicity: PropTypes.string,
        issuedDate: PropTypes.instanceOf(Date),
        expirationDate: PropTypes.instanceOf(Date),
    }),
    isVerified: PropTypes.bool,
};
