import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { QuestionLogicValueField } from './QuestionLogicValueField';
import { getQuestionLogicLogicableOptions } from '../selectors';
import {
    reviewSequenceStepPseudoFieldNames,
    reviewSequenceStepQuestionLogicFieldNames,
} from '../../../constants';
import { SearchSelect } from '../../../../../../../../components';
import {
    getLogicableConfig,
    questionLogicOperatorNamesDisplay,
} from '../../../../../../../../../../shared_config/questionLogics';

const { LOGICABLE_FIELD } = reviewSequenceStepPseudoFieldNames;
const { LOGICABLE, LOGICABLE_ID, OPERATOR, VALUE } = reviewSequenceStepQuestionLogicFieldNames;

export const QuestionLogicFields = ({
    change,
    disabled,
    fieldName,
    questionLogic,
    questionLogic: { logicable, logicableField },
    showFormValidation,
}) => {
    const questionLogicLogicableOptions = useSelector(getQuestionLogicLogicableOptions);

    const questionLogicLogicableFieldChangeHandler = (logicableFieldValue) => {
        const [logicableValue, rawLogicableIdValue] = logicableFieldValue.split('#');
        change(`${fieldName}.${LOGICABLE}`, logicableValue);
        change(`${fieldName}.${LOGICABLE_ID}`, Number.parseInt(rawLogicableIdValue, 10));
        change(`${fieldName}.${OPERATOR}`, null);
        change(`${fieldName}.${VALUE}`, null);
    };

    const {
        inputValueOptions,
        inputValueType,
        operatorOptions = [],
    } = getLogicableConfig(questionLogic);

    const operatorSelectOptions = operatorOptions.map((operatorValue) => ({
        label: questionLogicOperatorNamesDisplay[operatorValue],
        value: operatorValue,
    }));

    return (
        <div className="row">
            <div className="col-md-4">
                <Field
                    component={SearchSelect}
                    disabled={disabled}
                    label="Condition for including step"
                    name={`${fieldName}.${LOGICABLE_FIELD}`}
                    onChange={questionLogicLogicableFieldChangeHandler}
                    options={questionLogicLogicableOptions}
                    placeholder="Select condition to use"
                    showValidation={showFormValidation}
                />
            </div>
            <div className="col-md-4">
                <Field
                    component={SearchSelect}
                    disabled={disabled || !logicable}
                    help={logicable ? undefined : 'Select condition first'}
                    key={logicableField}
                    label="Operator"
                    name={`${fieldName}.${OPERATOR}`}
                    options={operatorSelectOptions}
                    placeholder="Select operator"
                    showValidation={showFormValidation}
                />
            </div>
            <div className="col-md-4">
                <QuestionLogicValueField
                    disabled={disabled || !logicable}
                    help={logicable ? undefined : 'Select condition first'}
                    inputValueOptions={inputValueOptions}
                    inputValueType={inputValueType}
                    key={logicableField}
                    label="Value"
                    name={`${fieldName}.${VALUE}`}
                    placeholder="Select value to compare"
                    showFormValidation={showFormValidation}
                />
            </div>
        </div>
    );
};

QuestionLogicFields.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    questionLogic: PropTypes.object.isRequired,
    showFormValidation: PropTypes.bool,
};
