import { createSelector } from 'reselect';

import {
    addendumStatusTypes,
    addendumTypesDict,
} from '../../../../../../../shared_config/addendums';
import { proposalStatusesDict } from '../../../../../../../shared_config/proposals';

const { ADDENDUM } = addendumTypesDict;

const { RELEASED } = addendumStatusTypes;

const { NO_BID } = proposalStatusesDict;

const getAddendums = (state) => state.addendums.get('addendums');
const getProposals = (state) => state.addendums.get('proposals');

export const getAddendumsJS = createSelector([getAddendums], (addendums) => {
    return addendums.toJS().filter((addendum) => {
        return addendum.type === ADDENDUM && addendum.status === RELEASED;
    });
});

export const getAddendumProposals = createSelector(
    [getAddendumsJS, getProposals],
    (addendums, proposals) => {
        return proposals
            .toJS()
            .filter((proposal) => proposal.status !== NO_BID)
            .map((proposal) => {
                const addendumConfirms = addendums.map((addendum) => {
                    return proposal.addendumConfirms.find((addendumConfirm) => {
                        return addendumConfirm.addendum_id === addendum.id;
                    });
                });
                return {
                    ...proposal,
                    addendumConfirms,
                };
            });
    }
);
