import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { LoginForm } from '../Login';
import { Button } from '../../components';
import { USE_OG_PLATFORM } from '../../constants';
import { getControlPanelLoginUrl } from '../../utils';

export const LoginModalBody = ({ error, isSessionTimeout, message, onLogin, user }) => {
    const navigate = useNavigate();
    const styles = require('./LoginModal.scss');
    const email = get(user, 'email');

    const handleSignUpClick = () => {
        navigate('/signup');
    };

    // When the session has timed out the user needs to re-login to Platform
    if (isSessionTimeout && !user?.isVendor && USE_OG_PLATFORM) {
        return (
            <>
                {message && <div className={`text-warning ${styles.message}`}>{message}</div>}
                <Button
                    block
                    bsStyle="opengov"
                    href={getControlPanelLoginUrl(email)}
                    qaTag="login-logBackIn"
                    type="submit"
                >
                    Log Back In
                </Button>
            </>
        );
    }

    return (
        <>
            {message && <div className={`text-warning ${styles.message}`}>{message}</div>}
            <LoginForm
                currentUserEmail={email}
                isSessionTimeout={isSessionTimeout}
                onLogin={onLogin}
            />
            {error && <div className={`text-danger ${styles.errorContainer}`}>{error}</div>}
            <div className={styles.signupContainer}>
                Don&#39;t have an account yet?&nbsp;
                <Button bsStyle="link" onClick={handleSignUpClick}>
                    Sign up
                </Button>
            </div>
        </>
    );
};

LoginModalBody.propTypes = {
    error: PropTypes.string,
    isSessionTimeout: PropTypes.bool,
    message: PropTypes.string,
    onLogin: PropTypes.func,
    user: PropTypes.object,
};
