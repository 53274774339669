import { createSelector } from 'reselect';

import {
    getRequisitionCurrentStep,
    getReviewSequenceSteps,
} from '../../../../../../selectors/govApp';
import { rejectionPolicyTypes } from '../../../../../../../../shared_config/reviewSequences';

const isEligibleRejectionPolicy = (rejectionPolicy, reviewSequenceSteps) => {
    if (
        rejectionPolicy.value === rejectionPolicyTypes.CLOSED_REJECTED ||
        rejectionPolicy.value === rejectionPolicyTypes.BACK_TO_BEGINNING
    ) {
        return true;
    }
    return !!reviewSequenceSteps.find(({ id }) => id === rejectionPolicy.reviewSequenceStepId);
};

const getRejectionPolicyName = (rejectionPolicy, reviewSequenceSteps) => {
    if (rejectionPolicy.value === rejectionPolicyTypes.CLOSED_REJECTED) {
        return 'Closed - Rejected';
    }
    if (rejectionPolicy.value === rejectionPolicyTypes.BACK_TO_BEGINNING) {
        return 'Go Back to Beginning';
    }
    const step = reviewSequenceSteps.find(({ id }) => id === rejectionPolicy.reviewSequenceStepId);
    return `Go Back to Step #${step.stepNumber}: ${step.name}`;
};

export const getRejectionNoteModalData = createSelector(
    [(state) => state.requisitionsApprovals.get('rejectionNoteModalData')],
    (rejectionNoteModalData) => {
        if (rejectionNoteModalData) {
            return {
                showModal: true,
                ...rejectionNoteModalData.toJS(),
            };
        }
        return { showModal: false };
    }
);

export const getRejectionPolicyOptions = createSelector(
    [getRequisitionCurrentStep, getReviewSequenceSteps],
    (currentStep, reviewSequenceSteps) => {
        return (currentStep?.rejectionPolicies || [])
            .filter((rejectionPolicy) =>
                isEligibleRejectionPolicy(rejectionPolicy, reviewSequenceSteps)
            )
            .map((rejectionPolicy) => ({
                label: getRejectionPolicyName(rejectionPolicy, reviewSequenceSteps),
                value: JSON.stringify(rejectionPolicy),
            }));
    }
);
