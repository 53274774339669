import { partition } from 'lodash';

import { showSnackbar } from './notification';

export const LOAD_CUSTOM_CATEGORIES = 'category/LOAD_CUSTOM_CATEGORIES';
export const LOAD_CUSTOM_CATEGORIES_SUCCESS = 'category/LOAD_CUSTOM_CATEGORIES_SUCCESS';
export const LOAD_CUSTOM_CATEGORIES_FAIL = 'category/LOAD_CUSTOM_CATEGORIES_FAIL';
export const CREATE_CUSTOM_CATEGORY = 'category/CREATE_CUSTOM_CATEGORY';
export const CREATE_CUSTOM_CATEGORY_SUCCESS = 'category/CREATE_CUSTOM_CATEGORY_SUCCESS';
export const CREATE_CUSTOM_CATEGORY_FAIL = 'category/CREATE_CUSTOM_CATEGORY_FAIL';
export const UPDATE_CUSTOM_CATEGORY = 'category/UPDATE_CUSTOM_CATEGORY';
export const UPDATE_CUSTOM_CATEGORY_SUCCESS = 'category/UPDATE_CUSTOM_CATEGORY_SUCCESS';
export const UPDATE_CUSTOM_CATEGORY_FAIL = 'category/UPDATE_CUSTOM_CATEGORY_FAIL';
export const DELETE_CUSTOM_CATEGORY = 'category/DELETE_CUSTOM_CATEGORY';
export const DELETE_CUSTOM_CATEGORY_SUCCESS = 'category/DELETE_CUSTOM_CATEGORY_SUCCESS';
export const DELETE_CUSTOM_CATEGORY_FAIL = 'category/DELETE_CUSTOM_CATEGORY_FAIL';

export function createCustomCategory(customCategoryName, categories) {
    return (dispatch, getState, client) => {
        dispatch({ type: CREATE_CUSTOM_CATEGORY });
        return client
            .post('/categories/custom', {
                data: {
                    categories,
                    customCategoryName: customCategoryName.trim(),
                },
            })
            .then((result) => {
                dispatch(showSnackbar('Custom category added!'));
                return dispatch({
                    result,
                    type: CREATE_CUSTOM_CATEGORY_SUCCESS,
                });
            })
            .catch((error) =>
                dispatch({
                    error,
                    type: CREATE_CUSTOM_CATEGORY_FAIL,
                })
            );
    };
}

export function deleteCustomCategory(customCategory) {
    return (dispatch, getState, client) => {
        dispatch({ id: customCategory.id, type: DELETE_CUSTOM_CATEGORY });
        return client
            .del(`/categories/custom/${customCategory.id}`)
            .then((result) => {
                dispatch(showSnackbar('Custom category deleted'));
                return dispatch({
                    id: customCategory.id,
                    result,
                    type: DELETE_CUSTOM_CATEGORY_SUCCESS,
                });
            })
            .catch((error) =>
                dispatch({
                    error,
                    id: customCategory.id,
                    type: DELETE_CUSTOM_CATEGORY_FAIL,
                })
            );
    };
}

export function setCustomCategory(customCategory, categories) {
    return (dispatch, getState, client) => {
        dispatch({ id: customCategory.id, type: UPDATE_CUSTOM_CATEGORY });
        return client
            .put(`/categories/custom/${customCategory.id}`, {
                data: {
                    categories,
                },
            })
            .then((result) => {
                dispatch(showSnackbar('Custom category updated'));
                return dispatch({
                    id: customCategory.id,
                    result,
                    type: UPDATE_CUSTOM_CATEGORY_SUCCESS,
                });
            })
            .catch((error) =>
                dispatch({
                    error,
                    id: customCategory.id,
                    type: UPDATE_CUSTOM_CATEGORY_FAIL,
                })
            );
    };
}

export function loadCustomCategories(governmentId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_CUSTOM_CATEGORIES });
        return client
            .get(`/categories/custom/${governmentId}`)
            .then((result) => {
                return dispatch({
                    type: LOAD_CUSTOM_CATEGORIES_SUCCESS,
                    customCategories: result,
                });
            })
            .catch((error) =>
                dispatch({
                    error,
                    type: LOAD_CUSTOM_CATEGORIES_FAIL,
                })
            );
    };
}

export function isCustomCategoriesLoaded(state) {
    return state.category && state.category.get('loadedCustomCategories');
}

export const LOAD_SUB_CATEGORIES = 'category/LOAD_SUB_CATEGORIES';
export const LOAD_SUB_CATEGORIES_SUCCESS = 'category/LOAD_SUB_CATEGORIES_SUCCESS';
export const LOAD_SUB_CATEGORIES_FAIL = 'category/LOAD_SUB_CATEGORIES_FAIL';

export function loadSubcategory(category, setId, opts = {}) {
    return (dispatch, getState, client) => {
        const getCategory = (categoryData, set) => {
            const { code, children } = categoryData;
            const params = { set };
            if (code) {
                return client.get(`/categories/${code}`, { params });
            }
            const data = { codes: children };

            return client.post('/categories/search', { data, params });
        };

        if (opts.omitActions) {
            return getCategory(category, setId);
        }

        dispatch({ type: LOAD_SUB_CATEGORIES });
        return getCategory(category, setId)
            .then((resultCategories) => {
                // Ensure the selected category is on top
                const [selectedCategory, rest] = partition(resultCategories, (resultCategory) => {
                    return category.code && category.code === resultCategory.code;
                });
                const result = {
                    ...category,
                    categories: [
                        // Mark selected category as leaf
                        ...selectedCategory.map((selectedCat) => {
                            return {
                                ...selectedCat,
                                isLeaf: true,
                            };
                        }),
                        ...rest,
                    ],
                };
                return dispatch({ type: LOAD_SUB_CATEGORIES_SUCCESS, result });
            })
            .catch((error) => dispatch({ type: LOAD_SUB_CATEGORIES_FAIL, error }));
    };
}

export const RESET_CATEGORIES = 'category/RESET_CATEGORIES';
export const LEVEL_UP = 'category/LEVEL_UP';
export const SHOW_ALL = 'category/SHOW_ALL';
export const CHANGE_CATEGORY_SET = 'category/CHANGE_CATEGORY_SET';

export function resetCategoriesSearch() {
    return { type: RESET_CATEGORIES };
}

export function levelUpCategories() {
    return { type: LEVEL_UP };
}

export function showAllCategories() {
    return { type: SHOW_ALL };
}

export function changeCategorySet(setId) {
    return { type: CHANGE_CATEGORY_SET, setId };
}

export const SEARCH_CATEGORIES = 'category/SEARCH_CATEGORIES';
export const SEARCH_CATEGORIES_SUCCESS = 'category/SEARCH_CATEGORIES_SUCCESS';
export const SEARCH_CATEGORIES_FAIL = 'category/SEARCH_CATEGORIES_FAIL';

export function searchCategories(query, setId) {
    return (dispatch, getState, client) => {
        if (!query && query !== 0) {
            return dispatch({ type: SEARCH_CATEGORIES_SUCCESS, result: [] });
        }
        const data = { query };
        const params = { set: setId };
        dispatch({ type: SEARCH_CATEGORIES });
        return client
            .post('/categories/search', { data, params })
            .then((rawResult) => {
                const result = rawResult.map((cat) => ({
                    label: `${cat.code} ${cat.title}`,
                    value: cat.id,
                    data: cat,
                }));
                return dispatch({ type: SEARCH_CATEGORIES_SUCCESS, result });
            })
            .catch((error) => dispatch({ type: SEARCH_CATEGORIES_FAIL, error }));
    };
}

export const LOAD_CATEGORIES = 'category/LOAD_CATEGORIES';
export const LOAD_CATEGORIES_SUCCESS = 'category/LOAD_CATEGORIES_SUCCESS';
export const LOAD_CATEGORIES_FAIL = 'category/LOAD_CATEGORIES_FAIL';

export function loadCategoriesById(categoryIds) {
    return (dispatch, getState, client) => {
        const data = { ids: categoryIds };
        dispatch({ type: LOAD_CATEGORIES });
        return client
            .post('/categories', { data })
            .then((result) => dispatch({ type: LOAD_CATEGORIES_SUCCESS, result }))
            .catch((error) => dispatch({ type: LOAD_CATEGORIES_FAIL, error }));
    };
}
