import { createSelector } from 'reselect';

import { positionTypes } from '../../../../shared_config/positions';

const { ENTITY, GROUP } = positionTypes;

const getRawPositions = (state) => state.requisitionsAdmin.get('positions');

export const getEntityPositions = createSelector([getRawPositions], (rawPositions) => {
    return rawPositions.toJS().filter((position) => position.type === ENTITY);
});

export const getGroupPositions = createSelector([getRawPositions], (rawPositions) => {
    return rawPositions.toJS().filter((position) => position.type === GROUP);
});
