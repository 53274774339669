import { contactPhoneFields } from '../../../../constants/contactFields';
import { listToDict } from '../../../../../../shared_config/helpers';

const fields = [
    ...contactPhoneFields,
    'companyName',
    'contactEmail',
    'contactFirstName',
    'contactLastName',
    'contactWebsite',
    'submittedAt',
];

export const fieldNames = listToDict(fields);
