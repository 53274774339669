import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { change, Field, getFormValues } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash';

import { DateTimePicker, RadioButtons, SearchSelect } from '..';
import { dateOptions, dateFilterTypesDict, oneDateTypes } from './constants';
import { requisitionFiltersNames } from '../../../../shared_config/requisitions';

const { ONE_DATE, DATE_RANGE } = dateFilterTypesDict;
const { ON, AFTER, BEFORE, START, END } = requisitionFiltersNames;

const Format = () => {
    const styles = require('./index.scss');

    return <p className={styles.format}>Format: mm / dd / yyyy</p>;
};

// This component is managed independently of redux-form
export const DateFilter = ({
    className,
    dateFormat,
    dateTypeFieldName,
    oneDateTypeFieldName,
    oneDateValueFieldNames,
    rangeDateValueFieldNames,
    onChange,
    ...props
}) => {
    const styles = require('./index.scss');
    const dispatch = useDispatch();

    const formValues = useSelector((state) => getFormValues(props.formName)(state));

    const oneDateType = formValues[oneDateTypeFieldName];

    const changeField = (fieldName, value) => dispatch(change(props.formName, fieldName, value));

    const handleOneDateChange = (newOneDateType) => {
        const activeValue = formValues[ON] || formValues[AFTER] || formValues[BEFORE];

        changeField(ON, null);
        changeField(BEFORE, null);
        changeField(AFTER, null);

        if (activeValue) {
            changeField(newOneDateType, activeValue);
            onChange({ value: activeValue, type: newOneDateType });
        }
    };

    const handleDateTypeChange = () => {
        // Reset all date fields
        changeField(ON, null);
        changeField(BEFORE, null);
        changeField(AFTER, null);
        changeField(START, null);
        changeField(END, null);
    };

    const handleDateInputChange = (e, type) => {
        const value = e.target.value;

        if (isNil(value)) {
            return;
        }

        onChange({
            value: props.parseDate(value, dateFormat),
            type,
        });
    };

    const oneDateComponent = () => {
        return (
            <div className={styles.datePicker}>
                <div className={styles.dateTypeContainer}>
                    <Field
                        component={SearchSelect}
                        formClassName={styles.dateTypeSelect}
                        name={oneDateTypeFieldName}
                        onChange={handleOneDateChange}
                        options={oneDateTypes}
                    />
                    <Field
                        allowEmpty
                        className={styles.oneDatePicker}
                        component={DateTimePicker}
                        dateFormat={dateFormat}
                        name={oneDateValueFieldNames[oneDateType]}
                        onChange={(value) => onChange({ value, type: oneDateType })}
                        onInputDateChange={(e) => handleDateInputChange(e, oneDateType)}
                        props={{
                            parse: (str) => props.parseDate(str, dateFormat),
                        }}
                        showValidation
                        time={false}
                        useOpenGovStyle
                    />
                </div>
                <Format />
            </div>
        );
    };

    const dateRangeComponent = () => {
        return (
            <div className={styles.dateRange}>
                <div className={styles.datePicker}>
                    Start
                    <Field
                        allowEmpty
                        className={styles.datePickerSelect}
                        component={DateTimePicker}
                        dateFormat={dateFormat}
                        name={rangeDateValueFieldNames[START]}
                        onChange={(value) => onChange({ value, type: START })}
                        onInputDateChange={(e) => handleDateInputChange(e, START)}
                        props={{
                            parse: (str) => props.parseDate(str, dateFormat),
                        }}
                        showValidation
                        time={false}
                        useOpenGovStyle
                    />
                    <Format />
                </div>
                <div className={styles.datePicker}>
                    End
                    <Field
                        allowEmpty
                        className={styles.datePickerSelect}
                        component={DateTimePicker}
                        dateFormat={dateFormat}
                        name={rangeDateValueFieldNames[END]}
                        onChange={(value) => onChange({ value, type: END })}
                        onInputDateChange={(e) => handleDateInputChange(e, END)}
                        props={{
                            parse: (str) => props.parseDate(str, dateFormat),
                        }}
                        showValidation
                        time={false}
                        useOpenGovStyle
                    />
                    <Format />
                </div>
            </div>
        );
    };

    return (
        <div className={classNames(styles.container, className)}>
            <Field
                component={RadioButtons}
                legend="Date Type"
                name={dateTypeFieldName}
                onChange={handleDateTypeChange}
                options={dateOptions}
            />
            {formValues[dateTypeFieldName] === ONE_DATE && oneDateComponent()}
            {formValues[dateTypeFieldName] === DATE_RANGE && dateRangeComponent()}
        </div>
    );
};
DateFilter.propTypes = {
    className: PropTypes.string,
    dateFormat: PropTypes.string,
    dateTypeFieldName: PropTypes.string.isRequired,
    formName: PropTypes.string.isRequired,
    oneDateTypeFieldName: PropTypes.string.isRequired,
    oneDateValueFieldNames: PropTypes.object.isRequired,
    parseDate: PropTypes.func,
    rangeDateValueFieldNames: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};
