import React from 'react';

import * as Gov from '../../containers/GovApp';
import { PermissionsConditionalRender } from '../components';
import { userRolePermissions } from '../../../../shared_config/userRoles';
import {
    legacyFetchData,
    requireGlobalOrDepartmentEditor,
    requireContractOrDepartmentAdmin,
} from '../loaders';

const { IS_GLOBAL_EDITOR, IS_DEPARTMENT_EDITOR } = userRolePermissions;

export default (getState, dispatch) => {
    return [
        {
            element: (
                <PermissionsConditionalRender
                    fallbackComponent={<Gov.ContractsDashboard />}
                    operator="OR"
                    permissionTypes={[IS_GLOBAL_EDITOR, IS_DEPARTMENT_EDITOR]}
                >
                    <Gov.GlobalEditorDashboard />
                </PermissionsConditionalRender>
            ),
            loader: async (props) => {
                const permissions = getState().auth.getIn(['user', 'userPermissions'])?.toJS();

                if (!permissions) {
                    return null;
                }

                if (permissions[IS_GLOBAL_EDITOR] || permissions[IS_DEPARTMENT_EDITOR]) {
                    return legacyFetchData(getState, dispatch, Gov.GlobalEditorDashboard)(props);
                }

                return legacyFetchData(getState, dispatch, Gov.ContractsDashboard)(props);
            },
            path: 'dashboard',
        },
        {
            element: <Gov.GlobalEditorDashboard />,
            loader: async (props) => {
                const permissionRedirect = await requireGlobalOrDepartmentEditor(getState)(props);

                if (permissionRedirect) {
                    return permissionRedirect;
                }

                return legacyFetchData(getState, dispatch, Gov.GlobalEditorDashboard)(props);
            },
            path: 'dashboard/projects',
        },
        {
            element: <Gov.ContractsDashboard />,
            loader: async (props) => {
                const permissionRedirect = await requireContractOrDepartmentAdmin(getState)(props);

                if (permissionRedirect) {
                    return permissionRedirect;
                }

                return legacyFetchData(getState, dispatch, Gov.ContractsDashboard)(props);
            },
            path: 'dashboard/contracts',
        },
    ];
};
