import { get, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from '@og-pro-migration-tools/react-router';

import {
    CriteriaScore,
    CriteriaScoreDocx,
    DataTable,
    ReportDocxTable,
    ScoringCriteriaCell,
    StatusLabel,
} from '../../../../../../components';
import { formatCSVExportScorePercentage, roundNumber } from '../../../../../../helpers';
import { PASS_FAIL } from '../../../../../../../../shared_config/evaluations';

const MAX_ROWS = 20;
const MINIMUM_OVERFLOW_ROWS_FOR_PAGINATION = 2;

class ConnectedAggregateScoringCriteriaEvaluationTable extends PureComponent {
    static propTypes = {
        getEvaluationPath: PropTypes.func,
        isDocx: PropTypes.bool,
        proposals: PropTypes.arrayOf(
            PropTypes.shape({
                isExcluded: PropTypes.bool.isRequired,
                scoringCriteria: PropTypes.arrayOf(
                    PropTypes.shape({
                        totalScore: PropTypes.number,
                    })
                ).isRequired,
            })
        ).isRequired,
        router: PropTypes.object.isRequired,
        showCriteriaDescriptions: PropTypes.bool,
        showPercentageTotals: PropTypes.bool,
        totalWeight: PropTypes.number.isRequired,
        useLandscape: PropTypes.bool,
    };

    totalScoringCriteriaData = {
        maxScore: 100,
        title: 'Total Score',
    };

    get styles() {
        return require('../AggregateEvaluationTable.scss');
    }

    render() {
        const {
            getEvaluationPath,
            isDocx,
            proposals,
            router,
            showCriteriaDescriptions,
            showPercentageTotals,
            totalWeight,
            useLandscape,
        } = this.props;

        const aggPath = '/aggregate-evaluations';
        const excludedLabelStyle = {
            backgroundColor: '#777',
            fontWeight: 'bold',
            color: '#fff',
            textAlign: 'center',
        };

        const vendorColumn = {
            Header: <b>Vendor</b>,
            id: 'vendor-name-column',
            accessor: (row) => row.companyName || 'Unnamed Company',
            Cell: (props) => (
                <>
                    <span
                        className={this.styles.selectableCell}
                        onClick={() =>
                            router.push(
                                getEvaluationPath(`${aggPath}/proposals/${props.original.id}`)
                            )
                        }
                    >
                        {props.value}
                    </span>
                    {props.original.isExcluded && (
                        <div>
                            <StatusLabel bsStyle="muted">Excluded</StatusLabel>
                        </div>
                    )}
                </>
            ),
            docxHtml: ({ datum, value }) => (
                <div>
                    {value}
                    {datum.isExcluded && <div style={excludedLabelStyle}>Excluded</div>}
                </div>
            ), // Used for docx table
        };

        const scoringCriteria = get(proposals[0], 'scoringCriteria', []);
        const scoringCriteriaColumns = scoringCriteria.map((scoringCriterium, idx) => {
            // Pass/Fail should always be shown as percent because there are no raw point based
            // number associated with them (always will be in the 0-1 range)
            const shouldShowPercentageTotals =
                showPercentageTotals || scoringCriterium.scoringMethod === PASS_FAIL;
            return {
                Header: (
                    <ScoringCriteriaCell
                        scoringCriteria={scoringCriterium}
                        separateLines
                        showDescription={showCriteriaDescriptions}
                        totalWeight={totalWeight}
                    />
                ),
                accessor: shouldShowPercentageTotals
                    ? `scoringCriteria[${idx}].totalScore`
                    : `scoringCriteria[${idx}].totalScoreByPoints`,
                Cell: (props) => (
                    <CriteriaScore
                        className={this.styles.criteriaScore}
                        criteriaScore={roundNumber(props.value, 1)}
                        isTotal
                        onClick={() =>
                            router.push(
                                getEvaluationPath(`${aggPath}/proposals/${props.original.id}`)
                            )
                        }
                        scoringCriteria={this.totalScoringCriteriaData}
                        showPercentageTotals={shouldShowPercentageTotals}
                        totalScorePercentage={get(
                            props,
                            `original.scoringCriteria[${idx}].totalScore`
                        )}
                    />
                ),
                // eslint-disable-next-line react/prop-types
                DocxCell: ({ datum, value }) => (
                    <CriteriaScoreDocx
                        criteriaScore={roundNumber(value, 1)}
                        isTotal
                        scoringCriteria={this.totalScoringCriteriaData}
                        showPercentageTotals={shouldShowPercentageTotals}
                        totalScorePercentage={get(datum, `scoringCriteria[${idx}].totalScore`)}
                    />
                ), // Used for docx table
            };
        });

        const totalColumn = {
            Header: (
                <div className={`data-table-text-wrap ${this.styles.totalCol}`}>
                    Total Score
                    {!showPercentageTotals && (
                        <>
                            <br />
                            (Max Score {totalWeight})
                        </>
                    )}
                </div>
            ),
            accessor: showPercentageTotals ? 'totalScore' : 'totalScoreByPoints',
            Cell: (props) => (
                <CriteriaScore
                    className={this.styles.totalCol}
                    criteriaScore={roundNumber(props.value, 2)}
                    isTotal
                    scoringCriteria={this.totalScoringCriteriaData}
                    showPercentageTotals={showPercentageTotals}
                    totalScorePercentage={props.original.totalScore}
                />
            ),
            // eslint-disable-next-line react/prop-types
            DocxCell: ({ datum, value }) => (
                <CriteriaScoreDocx
                    criteriaScore={roundNumber(value, 2)}
                    isTotal
                    scoringCriteria={this.totalScoringCriteriaData}
                    showPercentageTotals={showPercentageTotals}
                    style={{ fontWeight: 'bold' }}
                    totalScorePercentage={get(datum, 'totalScore')}
                />
            ), // Used for docx table
        };

        const columns = [vendorColumn, ...scoringCriteriaColumns, totalColumn];

        const usePagination = proposals.length > MAX_ROWS + MINIMUM_OVERFLOW_ROWS_FOR_PAGINATION;

        const formatDataForCSVExport = (data) => {
            const headers = [
                'Vendor',
                ...proposals[0].scoringCriteria.map((criteria) => criteria.title),
                'Total Score',
            ];

            const rows = data.map((dataRow) => {
                return [
                    dataRow[vendorColumn.id],
                    ...scoringCriteriaColumns.map((column) =>
                        formatCSVExportScorePercentage(
                            dataRow[column.accessor],
                            showPercentageTotals
                        )
                    ),
                    formatCSVExportScorePercentage(
                        dataRow[totalColumn.accessor],
                        showPercentageTotals
                    ),
                ];
            });

            return [headers].concat(rows);
        };

        if (isDocx) {
            return (
                <ReportDocxTable
                    columns={columns}
                    data={orderBy(proposals, 'totalScoreByPoints', 'desc')}
                    useLandscape={useLandscape}
                />
            );
        }

        return (
            <DataTable
                className="-striped -highlight"
                columns={columns}
                csvExportOptions={{
                    fileName: 'Vendor Scores by Evaluation Criteria',
                    getFormattedCSVData: formatDataForCSVExport,
                    headers: true,
                }}
                data={proposals}
                defaultPageSize={usePagination ? MAX_ROWS : proposals.length}
                showCSVExport
                showPagination={usePagination}
            />
        );
    }
}

export const AggregateScoringCriteriaEvaluationTable = withRouter(
    ConnectedAggregateScoringCriteriaEvaluationTable
);
