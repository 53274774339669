import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Skeleton, Typography } from '@og-pro/ui';

import { Button, VerifiedIcon } from '../../../../../components';
import { certificationSourceIds } from '../../../../../../../shared_config/certificationSource';

export const DisplayCertification = ({
    addingCertification,
    certification: { certification_source: certificationSourceId, title, vendorCertification },
    index,
    isVerifiedCertification,
    loading,
    onEditCertification,
}) => {
    const styles = require('./index.scss');

    const {
        certifyingAgency,
        certificationSource,
        certificateNumber,
        issuedDate,
        ethnicity,
        expirationDate,
    } = vendorCertification;

    const showCertificationDetails =
        !loading &&
        vendorCertification &&
        (certifyingAgency ||
            certificationSource?.name ||
            certificateNumber ||
            issuedDate ||
            expirationDate ||
            ethnicity);

    const renderCertifyingAgency = () => {
        if (certifyingAgency) {
            return certifyingAgency;
        }

        if (
            certificationSourceId !== certificationSourceIds.SELF_REPORTED &&
            certificationSource?.name
        ) {
            return certificationSource?.name;
        }
    };

    return (
        <Box
            className={classnames(styles.displayCertification, {
                [styles.verifiedCertification]: isVerifiedCertification,
            })}
            display="flex"
            flexDirection="column"
            p={2}
        >
            <Box alignItems="center" display="flex" flexGrow={1} justifyContent="space-between">
                <Typography className={styles.title} variant="h4">
                    {title}
                    {isVerifiedCertification && <VerifiedIcon />}
                </Typography>
                {!isVerifiedCertification && !addingCertification && !loading && (
                    <Button
                        bsStyle="link"
                        className={styles.editButton}
                        onClick={() => onEditCertification(index)}
                        qaTag="addCertification-edit"
                    >
                        <i className="fa fa-pencil" />
                        Edit
                    </Button>
                )}
            </Box>
            {loading && <Skeleton height="24px" />}
            {showCertificationDetails && (
                <Box display="flex">
                    <Box display="flex" flexDirection="column" flexGrow={1}>
                        <Typography>{renderCertifyingAgency()}</Typography>
                        <Typography>{certificateNumber}</Typography>
                        <Typography>{ethnicity}</Typography>
                    </Box>
                    <Box display="flex">
                        <Box display="flex" flexDirection="column" width={138}>
                            {issuedDate && (
                                <>
                                    <Typography className={styles.label}>Issued Date</Typography>
                                    <Typography>
                                        {new Date(issuedDate).toLocaleDateString('en-US')}
                                    </Typography>
                                </>
                            )}
                        </Box>
                        <Box display="flex" flexDirection="column" width={138}>
                            {expirationDate && (
                                <>
                                    <Typography className={styles.label}>
                                        Expiration Date
                                    </Typography>
                                    <Typography>
                                        {new Date(expirationDate).toLocaleDateString('en-US')}
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

DisplayCertification.propTypes = {
    addingCertification: PropTypes.bool,
    certification: PropTypes.object,
    index: PropTypes.number.isRequired,
    isVerifiedCertification: PropTypes.bool,
    loading: PropTypes.bool,
    onEditCertification: PropTypes.func,
};
