import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Field } from 'redux-form';
import ReactToggle from 'react-toggle';

import { fieldNames } from '../constants';
import { LoadingButton, DragIcon, InputText, Toggle } from '../..';
import { sectionTypeNames } from '../../../../../shared_config/sections';

const { IS_DISPLAYED, TITLE } = fieldNames;

const { DIVIDER } = sectionTypeNames;

export class ProjectSectionsEditItem extends Component {
    static propTypes = {
        copyProjectSection: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        index: PropTypes.number.isRequired,
        isHiddenByLogic: PropTypes.bool,
        member: PropTypes.string.isRequired,
        portal: PropTypes.object.isRequired,
        projectSectionId: PropTypes.number.isRequired,
        provided: PropTypes.object.isRequired,
        section: PropTypes.shape({
            icon: PropTypes.string.isRequired,
            isGeneral: PropTypes.bool.isRequired,
            isRemovable: PropTypes.bool.isRequired,
            section_type: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        }).isRequired,
        snapshot: PropTypes.object.isRequired,
        useManualNumbering: PropTypes.bool,
        useSectionDividers: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            copying: false,
            copyError: null,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    get isDivider() {
        const {
            section: { section_type: sectionType },
        } = this.props;

        return sectionType === DIVIDER;
    }

    copyProjectSection = () => {
        const { copyProjectSection, projectSectionId } = this.props;

        this.setState({ copyError: null, copying: true });

        copyProjectSection(projectSectionId)
            .then(() => this.setState({ copying: false }))
            .catch((error) => this.setState({ copyError: error.message, copying: false }));
    };

    render() {
        const {
            disabled,
            index,
            isHiddenByLogic,
            member,
            portal,
            provided,
            section,
            snapshot,
            useManualNumbering,
            useSectionDividers,
        } = this.props;

        const { copyError, copying } = this.state;

        const isFirstDivider = useSectionDividers && this.isDivider && index === 0;

        const item = (
            <div
                className={classnames(
                    'row',
                    this.styles.item,
                    snapshot.isDragging && this.styles.draggingItem
                )}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <div
                    className={classnames(
                        !useSectionDividers || this.isDivider
                            ? 'col-xs-8 col-xs-offset-1'
                            : 'col-xs-7 col-xs-offset-2'
                    )}
                >
                    <Field
                        aria-label="Edit Section Title"
                        component={InputText}
                        disabled={disabled || copying}
                        hasFeedback={false}
                        name={`${member}.${TITLE}`}
                        placeholder="Enter a title for the section"
                        qaTag="projectSectionsEditItem-sectionTitle"
                        type="text"
                    />
                    <div className={`help-block ${this.styles.help}`}>
                        <i className={`fa fa-fw fa-${section.icon}`} /> {section.title} section type
                        {section.isGeneral && (
                            <LoadingButton
                                aria-label="Copy Section"
                                bsSize="sm"
                                bsStyle="link"
                                className={this.styles.copyButton}
                                disabled={disabled || copying}
                                icon="fa-copy"
                                loading={copying}
                                onClick={this.copyProjectSection}
                                qaTag="projectSectionsEditItem-copySection"
                                text="Copy Section"
                                zeroPadding
                            />
                        )}
                    </div>
                    {copyError && (
                        <div className={classnames('text-danger', this.styles.copyError)}>
                            {copyError}
                        </div>
                    )}
                </div>
                <div className="col-xs-3 text-left">
                    {isHiddenByLogic ? (
                        <ReactToggle checked={false} className={this.styles.toggle} disabled />
                    ) : (
                        <Field
                            aria-label="Display Section"
                            className={this.styles.toggle}
                            component={Toggle}
                            disabled={disabled || copying || !section.isRemovable || isFirstDivider}
                            name={`${member}.${IS_DISPLAYED}`}
                            qaTag="projectSectionsEditItem-displaySection"
                        />
                    )}
                    {!isFirstDivider && !useManualNumbering && (
                        <DragIcon className={this.styles.dragIcon} disabled={disabled || copying} />
                    )}
                </div>
            </div>
        );

        if (snapshot.isDragging) {
            // Need to create a portal to have drag and drop properly align
            // https://github.com/atlassian/react-beautiful-dnd/issues/485
            // https://github.com/atlassian/react-beautiful-dnd/blob/v11.0.5/docs/patterns/using-a-portal.md
            // https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/portal/portal-app.jsx
            return ReactDOM.createPortal(item, portal);
        }

        return item;
    }
}
