import { listToDict } from '../../../../../../../shared_config/helpers';

export const form = 'criteriaNeedsReviewBuilder';

const instructionDataFields = ['includeAttachment', 'instructions', 'name', 'options', 'value'];
export const instructionDataFieldNames = listToDict(instructionDataFields);

export const unusedOptionTooltip =
    "Hiding the item will show the user the prompt, but won't include the item as part of the " +
    'document. Use this option when you want to prompt the user to do something without ' +
    'inserting content into the document.';

export const attachmentUploadOptionTooltip =
    'Will include the attachment uploader next to the item. Use this option when the user needs ' +
    'to upload a document as part of the process.';

export const previewTooltip =
    'Preview of how the item will appear to the user working on the document. The form fields ' +
    'are disabled in the preview, but will be active when the user is actually completing the ' +
    'item.';
