import { listToDict } from '../../../../utils';
import { contractPermissionsDict } from '../../../../../../shared_config/contracts';

const { CREATOR, EDIT, VIEW } = contractPermissionsDict;

export const creatorSelectOption = [{ label: 'Creator', value: CREATOR }];

export const fields = ['id', 'role'];

export const fieldNames = listToDict(fields);

export const permissionOptions = [
    { label: 'View', value: VIEW },
    { label: 'Edit', value: EDIT },
];
