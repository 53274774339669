import { createSelector } from 'reselect';

import { getUserJS } from '../../../../../selectors';
import {
    contractStatusClass,
    statusClass,
} from '../../../../../../components/helpers/statusHelper';
import { getReqRelations } from '../../../../../../selectors/govApp/reqRelations';
import { projectStatusesDict } from '../../../../../../../../shared_config/projects';

export const getIsLoadingReqRelations = (state) =>
    state.requisitionsApprovals.get('loadingReqRelations');
export const getLoadReqRelationsError = (state) =>
    state.requisitionsApprovals.get('loadReqRelationsError');

const getStatusName = (status) => {
    if (status === projectStatusesDict.REQUEST_DRAFT) {
        return projectStatusesDict.DRAFT;
    }
    if (status === projectStatusesDict.REQUEST_REVIEW) {
        return projectStatusesDict.REVIEW;
    }
    return status;
};

export const formatResource = (resource, user, isContract) => {
    const path = `/governments/${user.government_id}`;
    if (isContract) {
        const { contractId, contractParty, id, status, title } = resource;
        return {
            header: contractParty?.companyName || 'No Vendor Selected Yet',
            href: `${path}/contracts/${id}`,
            info: `Contract${contractId ? ` ${contractId}` : ''}`,
            status,
            statusClass: contractStatusClass(status),
            title: title || 'Untitled Contract',
        };
    }

    const { financialId, id, isIntake, status, title } = resource;
    const resourceType = isIntake ? 'Intake' : 'Solicitation';
    return {
        href: `${path}/projects/${id}`,
        info: `${resourceType}${financialId ? ` ${financialId}` : ''}`,
        status: getStatusName(status),
        statusClass: statusClass(status),
        title: title || `Untitled ${resourceType}`,
    };
};

export const getReqRelationsForDisplay = createSelector(
    [getReqRelations, getUserJS],
    (reqRelations, user) => {
        return reqRelations.map((reqRelation) => {
            const { contracts, intake, project, ...rest } = reqRelation;
            const [activeItem, ...otherItems] = [
                ...(contracts.length > 0
                    ? [contracts.map((contract) => formatResource(contract, user, true))]
                    : []),
                ...(project ? [formatResource(project, user)] : []),
                ...(intake ? [formatResource(intake, user)] : []),
            ];

            return {
                ...rest,
                activeItems: Array.isArray(activeItem) ? activeItem : [activeItem],
                otherItems,
            };
        });
    }
);
