import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Panel } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Outlet } from 'react-router-dom';
import { compose } from 'redux';

import { CLEANUP_DOCUMENTS, EXPIRED, RECENTLY_CLOSED } from '../constants';
import {
    getCleanupDocumentsJS,
    getExpiredJS,
    getNavItems,
    getRecentlyClosedJS,
} from '../selectors';
import { getGovernmentRetentionCodesJS } from '../../selectors';
import { getUserJS } from '../../../selectors';
import { Button, UnderlineNav } from '../../../../components';
import {
    loadCleanupDocuments,
    loadExpired,
    loadRecentlyClosed,
} from '../../../../actions/projects';
import { loadGovernmentRetentionCodes } from '../../../../actions/retention';

const mapStateToProps = (state, props) => {
    return {
        cleanupDocuments: getCleanupDocumentsJS(state),
        expired: getExpiredJS(state),
        navItems: getNavItems(state, props),
        recentlyClosed: getRecentlyClosedJS(state),
        retentionCodes: getGovernmentRetentionCodesJS(state),
        user: getUserJS(state),
    };
};

const ConnectedRecordsRetentionNav = (props) => {
    const dispatch = useDispatch();

    const { cleanupDocuments, expired, location, navItems, recentlyClosed, retentionCodes, user } =
        props;

    useEffect(() => {
        if (cleanupDocuments.length === 0) {
            dispatch(loadCleanupDocuments());
        }

        if (expired.length === 0) {
            dispatch(loadExpired());
        }

        if (recentlyClosed.length === 0) {
            dispatch(loadRecentlyClosed());
        }

        if (retentionCodes.length === 0) {
            dispatch(
                loadGovernmentRetentionCodes({
                    governmentId: user.government_id,
                })
            );
        }
    }, []);

    const routeIsManage = location.pathname.includes('manage');

    let activeTab;
    if (location.pathname.includes('documents')) {
        activeTab = CLEANUP_DOCUMENTS;
    } else if (location.pathname.includes('expired')) {
        activeTab = EXPIRED;
    } else {
        activeTab = RECENTLY_CLOSED;
    }

    const styles = require('./index.scss');

    const renderHeader = () => (
        <div className={styles.heading}>
            <div className={styles.title}>Records Retention Administration</div>
            <Button
                qaTag="recordsRetention-managePolicies"
                to={`/governments/${user.government_id}/retention-admin/manage`}
            >
                <i className="fa fa-gear" /> Manage Policies
            </Button>
        </div>
    );
    const renderNavToManage = () => {
        return (
            <div>
                <Button
                    bsStyle="link"
                    className={styles.backButton}
                    qaTag="recordsRetention-back"
                    to={`/governments/${user.government_id}/retention-admin/documents`}
                >
                    <i className={`fa fa-angle-left fa-lg ${styles.backArrow}`} />
                    Back to Records Retention Administration
                </Button>
                <div className={styles.heading}>
                    <div className={styles.title}>Manage Policies</div>
                </div>
            </div>
        );
    };
    const renderNavItems = () =>
        navItems.map((item) => {
            const { name, status, to } = item;
            const count = props[status] ? props[status].length : 0;

            return (
                <UnderlineNav.NavItem
                    className={classNames({
                        active: status === activeTab,
                    })}
                    key={status}
                    to={`/governments/${user.government_id}/retention-admin${to}`}
                >
                    {name}&nbsp;
                    <span className={styles.counter}>({count})</span>
                </UnderlineNav.NavItem>
            );
        });

    return (
        <Panel className="col-xs-10 col-xs-offset-1">
            <Panel.Body className={styles.container}>
                {routeIsManage ? renderNavToManage() : renderHeader()}
                <div className={styles.nav}>
                    <UnderlineNav>{!routeIsManage && renderNavItems()}</UnderlineNav>
                </div>
                <Outlet />
            </Panel.Body>
        </Panel>
    );
};

ConnectedRecordsRetentionNav.propTypes = {
    cleanupDocuments: PropTypes.array.isRequired,
    expired: PropTypes.array.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }),
    navItems: PropTypes.array.isRequired,
    recentlyClosed: PropTypes.array.isRequired,
    retentionCodes: PropTypes.array.isRequired,
    user: PropTypes.shape({
        government_id: PropTypes.number.isRequired,
    }).isRequired,
};

export const RecordsRetentionNav = compose(
    withRouter,
    connect(mapStateToProps)
)(ConnectedRecordsRetentionNav);
