import { listToDict } from '../../../shared_config/helpers';

export const addressFields = ['address1', 'address2', 'city', 'countryCode', 'state', 'zipCode'];

export const emailFields = ['email', 'emailFrequency'];

export const phoneFields = ['phone', 'phoneCountry', 'phoneExt'];

export const nameFields = ['firstName', 'lastName', 'title'];

export const userFields = [...addressFields, ...emailFields, ...phoneFields, ...nameFields];

export const userFieldNames = listToDict(userFields);
