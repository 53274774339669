import { browserHistory } from '@og-pro-migration-tools/react-router';

import {
    hideConfirmationModal,
    showConfirmationModalError,
    updatingConfirmationModal,
} from './confirmation';
import { saveSerializer } from './evaluation/create/serializers';
import { menuActionHandler, showInstructionsModal } from './govProjects';
import { showNotification, showSnackbar } from './notification';
import { emitProjectSocket } from './utils';
import { RELEASE_EVALUATION } from '../constants/menuActions';
import { trackEvent } from '../helpers';
import {
    evaluationStatuses,
    LOWEST_PRICE,
    LINE_ITEM_AWARD,
} from '../../../shared_config/evaluations';

/**
 * Called to signal that the form has been initialized.
 * Must be called after loading the project or the form will not render.
 */
export const INITIALIZE = 'gov/evaluations/INITIALIZE';
export const INITIATE_UPDATE = 'gov/evaluations/INITIATE_UPDATE';
export const INITIATE_SUBMIT = 'gov/evaluations/INITIATE_SUBMIT';

export function initialize() {
    return { type: INITIALIZE };
}

export function initiateUpdate() {
    return { type: INITIATE_UPDATE };
}

export function initiateSubmit() {
    return { type: INITIATE_SUBMIT };
}

export const ADD_EVALUATORS = 'gov/evaluations/ADD_EVALUATORS';

export function addEvaluators() {
    return { type: ADD_EVALUATORS };
}

export const CREATE = 'gov/evaluations/CREATE';
export const CREATE_SUCCESS = 'gov/evaluations/CREATE_SUCCESS';
export const CREATE_FAIL = 'gov/evaluations/CREATE_FAIL';

// Creates an evaluation from an existing project (happens inside confirmation modal)
export function createEvaluation(project, evaluationType) {
    return (dispatch, getState, client) => {
        const { id, government_id: govId } = project;
        const data = { type: evaluationType };

        dispatch(updatingConfirmationModal());
        dispatch({ type: CREATE });

        // First update the project status
        return client
            .post(`/project/${id}/evaluation`, { data })
            .then((result) => {
                const createAction = { type: CREATE_SUCCESS, result };
                dispatch(emitProjectSocket(id, createAction));

                trackEvent('Project Status Update', {
                    projectId: id,
                    oldStatus: project.status,
                    newStatus: result.status,
                });

                trackEvent('Evaluation Created', {
                    projectId: result.id,
                    evaluationId: result.evaluation_id,
                    isEvaluationOnly: false,
                });

                dispatch(hideConfirmationModal());
                if (evaluationType === LOWEST_PRICE) {
                    browserHistory.push(
                        `/governments/${govId}/projects/${id}/evaluation/bid-tabulations`
                    );
                    dispatch(showInstructionsModal('evaluationLowestPriceCreated'));
                } else if (evaluationType === LINE_ITEM_AWARD) {
                    browserHistory.push(
                        `/governments/${govId}/projects/${id}/evaluation/line-item-award`
                    );
                    dispatch(showInstructionsModal('evaluationLineItemAwardCreated'));
                } else {
                    browserHistory.push(`/governments/${govId}/projects/${id}/evaluation/create`);
                }
            })
            .catch((error) => {
                dispatch(showConfirmationModalError(error.message));
                dispatch({ type: CREATE_FAIL, error });
            });
    };
}

export function deleteEvaluation(project) {
    return (dispatch, getState, client) => {
        const { government_id: govId, id: projectId } = project;

        dispatch(updatingConfirmationModal());

        return client
            .del(`/project/${projectId}/evaluation`)
            .then((result) => {
                dispatch(hideConfirmationModal());
                const projectListPath = `/governments/${govId}/projects`;
                browserHistory.push(projectListPath);

                trackEvent('Project Evaluation Module Deleted');

                if (result.isEvaluationOnly) {
                    // The project and evaluation were both deleted
                    trackEvent('Project Deleted');
                } else {
                    // The project was updated, not deleted
                    trackEvent('Project Status Update', {
                        projectId,
                        oldStatus: project.status,
                        newStatus: result.status,
                    });
                }

                dispatch(showNotification('Evaluation successfully deleted'));
            })
            .catch((error) => {
                dispatch(
                    showConfirmationModalError(`Evaluation could not be deleted: ${error.message}`)
                );
            });
    };
}

export function hasEvaluation(state) {
    return !!state.auth.getIn(['user', 'government', 'hasEvaluation']);
}

export const UPDATE = 'gov/evaluations/UPDATE';
export const UPDATE_SUCCESS = 'gov/evaluations/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'gov/evaluations/UPDATE_FAIL';

export function updateEvaluation(projectId, formData, opts = {}) {
    return (dispatch, getState, client) => {
        const tz = getState().auth.getIn(['user', 'organization', 'timezone']);
        const data = saveSerializer(formData, tz);

        dispatch({ type: UPDATE });
        if (opts.modal) dispatch(updatingConfirmationModal());
        return client
            .put(`/project/${projectId}/evaluation`, { data })
            .then((result) => {
                const updateAction = { type: UPDATE_SUCCESS, result };
                dispatch(emitProjectSocket(projectId, updateAction));
                if (opts.notify) {
                    dispatch(showSnackbar(opts.notifyDisplay || 'Evaluation Saved'));
                }
                if (opts.modal) dispatch(hideConfirmationModal());
                if (opts.onComplete) return opts.onComplete(result);
            })
            .catch((error) => {
                dispatch({ type: UPDATE_FAIL, error });
                dispatch(showNotification(error.message, { type: 'danger' }));
                if (opts.modal) {
                    dispatch(showConfirmationModalError(error.message));
                }
            });
    };
}

export function submitEvaluation(projectId, formData) {
    return (dispatch, getState) => {
        const submitData = {
            ...formData,
            evaluation: {
                ...formData.evaluation,
                status: evaluationStatuses.OPEN,
            },
        };

        const govId = getState().govProjects.getIn(['selectedProject', 'government_id']);
        const evaluationPath = `/governments/${govId}/projects/${projectId}/evaluation`;

        const onComplete = (result) => {
            trackEvent('Evaluation Draft Completed');
            trackEvent('Evaluation Status Update', {
                projectId,
                oldStatus: evaluationStatuses.DRAFT,
                newStatus: evaluationStatuses.OPEN,
            });

            // When past initial phase, prompt to release evaluation immediately
            if (result.evaluation.phaseNumber > 1) {
                browserHistory.push(evaluationPath);
                return dispatch(menuActionHandler(RELEASE_EVALUATION, result));
            }

            // Show the evaluations instruction modal
            browserHistory.push(`${evaluationPath}/proposals`);
            return dispatch(showInstructionsModal('evaluationDraftComplete'));
        };

        return dispatch(updateEvaluation(projectId, submitData, { onComplete }));
    };
}

export function releaseEvaluation(project, formData) {
    return (dispatch) => {
        const { id: projectId, government_id: govId } = project;
        const data = {
            ...formData,
            evaluation: { status: evaluationStatuses.RELEASED },
        };
        const nextRoute = `/governments/${govId}/projects/${projectId}/evaluation/aggregate-evaluations`;

        const onComplete = () => {
            trackEvent('Evaluation Released');
            trackEvent('Evaluation Status Update', {
                projectId,
                oldStatus: evaluationStatuses.OPEN,
                newStatus: evaluationStatuses.RELEASED,
            });

            // Show the evaluations instruction modal
            browserHistory.push(nextRoute);
            return dispatch(showInstructionsModal('evaluationReleased'));
        };

        return dispatch(updateEvaluation(projectId, data, { onComplete, modal: true }));
    };
}

export const SHOW_FORM_VALIDATION = 'gov/evaluations/SHOW_FORM_VALIDATION';

export function showFormValidation() {
    return { type: SHOW_FORM_VALIDATION };
}

export function shouldLoadFromEvaluationAudit(state, query) {
    const queryPhase = Number.parseInt(query.phase, 10);
    if (Number.isNaN(queryPhase)) {
        return false;
    }

    const currentPhase = state.govProjects.getIn(['selectedProject', 'evaluation', 'phaseNumber']);
    if (!currentPhase || currentPhase === queryPhase) {
        return false;
    }

    const evaluationAudits = state.govProjects.getIn(['selectedProject', 'evaluationAudits']);
    return evaluationAudits.some(
        (audit) => audit.getIn(['data', 'evaluation', 'phaseNumber']) === queryPhase
    );
}

export function loadQualifiedProposals(projectId) {
    const params = { status: 'evaluationEligible' };
    return (dispatch, getState, client) => {
        return client.get(`/project/${projectId}/proposal`, { params }).catch((error) => {
            dispatch(showNotification(error.message, { type: 'danger' }));
            throw error;
        });
    };
}

export function createEvaluationPhase(project, data) {
    return (dispatch, getState, client) => {
        return client.post(`/project/${project.id}/evaluation/phase`, { data }).then((result) => {
            const updateAction = { type: UPDATE_SUCCESS, result };
            dispatch(emitProjectSocket(project.id, updateAction));

            const nextRoute = `/governments/${project.government_id}/projects/${project.id}/evaluation/create/criteria`;
            browserHistory.push(nextRoute);
            return dispatch(
                showInstructionsModal('evaluationPhaseCreated', {
                    phaseNumber: result.evaluation.phaseNumber,
                    useNewScoringCriteria: data.useNewScoringCriteria,
                })
            );
        });
    };
}

export const SHOW_EMAIL_EDIT_MODAL = 'gov/evaluations/SHOW_EMAIL_EDIT_MODAL';
export const HIDE_EMAIL_EDIT_MODAL = 'gov/evaluations/HIDE_EMAIL_EDIT_MODAL';

export function showEvaluatorReminderEmailModal(sendEmailHandler) {
    return { type: SHOW_EMAIL_EDIT_MODAL, sendEmailHandler };
}

export function hideEvaluatorReminderEmailModal() {
    return { type: HIDE_EMAIL_EDIT_MODAL };
}

export function sendEvaluatorReminderEmail(projectId, evaluatorId, comment) {
    return (dispatch, getState, client) => {
        const data = { comment };
        return client
            .post(`/project/${projectId}/evaluation/evaluators/${evaluatorId}/remind`, { data })
            .then(() => {
                dispatch(showSnackbar('Reminder email sent'));
            });
    };
}

export function createConsensusEvaluation(projectId, opts = {}) {
    return (dispatch, getState, client) => {
        dispatch(updatingConfirmationModal());
        return client
            .post(`/project/${projectId}/evaluation/consensus-evaluation`)
            .then((result) => {
                const updateAction = { type: UPDATE_SUCCESS, result };
                dispatch(emitProjectSocket(result.id, updateAction));
                dispatch(hideConfirmationModal());

                if (opts.reload) {
                    return dispatch(showSnackbar('Consensus scorecard reloaded'));
                }

                const nextRoute = `/governments/${result.government_id}/projects/${result.id}/evaluation/consensus-scorecard`;
                browserHistory.push(nextRoute);
                return dispatch(showInstructionsModal('consensusEvaluationCreated'));
            })
            .catch((error) => {
                dispatch(showConfirmationModalError(error.message));
            });
    };
}

export function deleteConsensusEvaluation(projectId) {
    return (dispatch, getState, client) => {
        return client
            .del(`/project/${projectId}/evaluation/consensus-evaluation`)
            .then((result) => {
                const nextRoute = `/governments/${result.government_id}/projects/${result.id}/evaluation/aggregate-evaluations`;
                browserHistory.push(nextRoute);

                const updateAction = { type: UPDATE_SUCCESS, result };
                dispatch(emitProjectSocket(result.id, updateAction));
                dispatch(showSnackbar('Consensus scorecard deleted'));
            })
            .catch((error) => {
                dispatch(showConfirmationModalError(error.message));
            });
    };
}
