import { createSelector } from 'reselect';

import { getProjectJS, isEvaluationEditor } from '../../selectors';
import { evaluationStatuses } from '../../../../../../shared_config/evaluations';

const { COMPLETE } = evaluationStatuses;

const getBidTabulations = (state) => state.proposalEvaluations.get('bidTabulations');
const getBidTabulationProposalsData = (state) =>
    state.proposalEvaluations.get('bidTabulationProposalsData');
const getSelectedBidTabulationPriceItemIds = (state) => {
    return state.proposalEvaluations.get('selectedBidTabulationPriceItemIds');
};

export const getIsViewOnly = createSelector(
    [getProjectJS, isEvaluationEditor],
    (project, isEditor) => {
        if (!project) {
            return true;
        }

        return project.evaluation.status === COMPLETE || !isEditor;
    }
);

export const getBidTabulationsJS = createSelector([getBidTabulations], (rawBidTabulations) => {
    if (rawBidTabulations) {
        return rawBidTabulations.toJS();
    }
});

export const getBidTabulationProposalsDataJS = createSelector(
    [getBidTabulationProposalsData],
    (rawBidTabulationProposalsData) => {
        if (rawBidTabulationProposalsData) {
            return rawBidTabulationProposalsData.toJS();
        }
    }
);

export const getSelectedBidTabulationPriceItemIdsJS = createSelector(
    [getSelectedBidTabulationPriceItemIds],
    (rawSelectedBidTabulationPriceItemIds) => {
        if (rawSelectedBidTabulationPriceItemIds) {
            return rawSelectedBidTabulationPriceItemIds.toJS();
        }
    }
);
