import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RequisitionLogItem } from './RequisitionLogItem';
import {
    getIsLoadingRequisitionLogs,
    getLoadRequisitionLogsError,
    getRequisitionLogs,
} from './selectors';
import { loadRequisitionLogs } from '../../../../../actions/requisitionsApprovals';
import { LoadingError, LoadingSpinner, ZeroState } from '../../../../../components';

export const RequisitionLogs = ({ requisition }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadRequisitionLogs(requisition.id));
    }, []);

    const isLoading = useSelector(getIsLoadingRequisitionLogs);
    const loadError = useSelector(getLoadRequisitionLogsError);
    const requisitionLogs = useSelector(getRequisitionLogs);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (loadError) {
        return <LoadingError error={loadError} />;
    }

    if (requisitionLogs.length === 0) {
        return <ZeroState info="Be the first to leave a comment" title="No comments yet" />;
    }

    return requisitionLogs.map((requisitionLog) => (
        <RequisitionLogItem
            key={requisitionLog.id}
            requisition={requisition}
            requisitionLog={requisitionLog}
        />
    ));
};

RequisitionLogs.propTypes = {
    requisition: PropTypes.shape({
        id: PropTypes.number.isRequired,
        identifier: PropTypes.string.isRequired,
        isOverBudget: PropTypes.bool.isRequired,
        priceTable: PropTypes.shape({
            priceItems: PropTypes.array.isRequired,
        }).isRequired,
    }).isRequired,
};
