import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { CategorySelectModal } from './CategorySelectModal';
import { CategoryCodesLongForm } from '../../components';
import { categoryNames, categorySetsArray } from '../../../../shared_config/categories';
import { DefaultSelectInputLabel } from './components/DefaultSelectInputLabel';

export class CategorySelectInput extends PureComponent {
    static propTypes = {
        disallowCustomCategories: PropTypes.bool,
        input: PropTypes.shape({
            onBlur: PropTypes.func.isRequired,
            onChange: PropTypes.func.isRequired,
            value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired, // Can be initialized with empty string, otherwise should be array value
        }).isRequired,
        hasFeedback: PropTypes.bool,
        govCategorySetId: PropTypes.number,
        isGovRequired: PropTypes.bool,
        isRequired: PropTypes.bool,
        label: PropTypes.string,
        limit: PropTypes.number,
        maxLength: PropTypes.number,
        meta: PropTypes.shape({
            error: PropTypes.string,
            touched: PropTypes.bool,
        }).isRequired,
        noSelectionText: PropTypes.string,
        segmentByCodeSet: PropTypes.bool,
        showMoreCodeSetsHelp: PropTypes.bool,
        showValidation: PropTypes.bool,
        useOpenGovStyle: PropTypes.bool,
        useSingleCodeSet: PropTypes.bool,
        components: PropTypes.shape({
            CategoryList: PropTypes.elementType,
            Header: PropTypes.elementType,
        }),
    };

    static defaultProps = {
        disallowCustomCategories: false,
        hasFeedback: false,
        isRequired: false,
        label: 'Categories',
        limit: 2,
        maxLength: undefined,
        noSelectionText: 'No Categories Selected',
        segmentByCodeSet: false,
        showMoreCodeSetsHelp: false,
        showValidation: false,
        components: {},
    };

    constructor(props) {
        super(props);
        this.state = { showModal: false };
    }

    componentWillUnmount() {
        this.hideModal();
    }

    get styles() {
        return require('./CategorySelectInput.scss');
    }

    get categories() {
        const {
            input: { value },
        } = this.props;

        return value || this.defaultCategories;
    }

    // Necessary so a new array isn't generated each time causing a re-render
    defaultCategories = [];

    showModal = () => {
        this.setState({ showModal: true });
    };

    hideModal = () => {
        this.setState({ showModal: false });
    };

    selectHandler = (categories, hasChanged) => {
        if (hasChanged) {
            this.props.input.onChange(categories);
            this.props.input.onBlur(categories); // blur touches the field
        }
        return this.hideModal();
    };

    deleteHandler = (categoryId) => {
        const {
            input: { value },
            input: { onChange },
        } = this.props;
        const newCategories = value.filter((category) => category.id !== categoryId);
        onChange(newCategories);
    };

    renderHeader() {
        const { label, components, useOpenGovStyle } = this.props;

        const { Header = DefaultSelectInputLabel } = components;
        return (
            <Header
                label={label}
                selectedCategoriesCount={this.categories.length}
                showModal={this.showModal}
                useOpenGovStyle={useOpenGovStyle}
            />
        );
    }

    renderCategories() {
        const {
            govCategorySetId,
            isGovRequired,
            limit,
            maxLength,
            noSelectionText,
            segmentByCodeSet,
            components,
        } = this.props;
        const { CategoryList = CategoryCodesLongForm } = components;
        const categories = this.categories;
        const isGovRequiredText = (
            <p className="text-danger">Please add codes for this category.</p>
        );
        const noCategoriesSelected = <p className={this.styles.grayText}>{noSelectionText}</p>;

        if (segmentByCodeSet) {
            return categorySetsArray.map((setId, idx) => (
                <React.Fragment key={setId}>
                    <p
                        className={classnames(
                            this.styles.categorySetTitle,
                            idx === 0 && this.styles.firstCategorySetTitle
                        )}
                    >
                        {categoryNames[setId]} CODES
                    </p>
                    <CategoryList
                        addHandler={this.showModal}
                        codes={categories.filter((category) => category.setId === setId)}
                        deleteHandler={this.deleteHandler}
                        limit={limit}
                        maxCategoryTextLength={maxLength}
                        noCategoriesText={
                            isGovRequired && setId === govCategorySetId
                                ? isGovRequiredText
                                : noCategoriesSelected
                        }
                    />
                </React.Fragment>
            ));
        }

        return (
            <CategoryList
                addHandler={this.showModal}
                codes={categories}
                deleteHandler={this.deleteHandler}
                limit={limit}
                maxCategoryTextLength={maxLength}
                noCategoriesText={noCategoriesSelected}
            />
        );
    }

    render() {
        const {
            disallowCustomCategories,
            govCategorySetId,
            hasFeedback,
            isRequired,
            meta: { error, touched },
            showMoreCodeSetsHelp,
            showValidation,
            useSingleCodeSet,
        } = this.props;

        const categories = this.categories;

        let groupState = '';
        let helpBlock;
        const displayValidation = hasFeedback && (touched || showValidation);
        if (displayValidation && error) {
            groupState = 'has-error';
            helpBlock = <span className="help-block">{error}</span>;
        } else if (displayValidation) {
            groupState = 'has-success';
        }

        return (
            <div className={`form-group ${groupState}`}>
                {this.renderHeader()}
                {this.renderCategories()}
                {helpBlock}
                <CategorySelectModal
                    categories={categories}
                    defaultCategorySet={govCategorySetId}
                    disallowCustomCategories={disallowCustomCategories}
                    hideModal={this.hideModal}
                    isRequired={isRequired}
                    selectHandler={this.selectHandler}
                    showModal={this.state.showModal}
                    showMoreCodeSetsHelp={showMoreCodeSetsHelp}
                    useSingleCodeSet={useSingleCodeSet}
                />
            </div>
        );
    }
}
