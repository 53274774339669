import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import {
    BidBondFormBuilder,
    DataVerifyFormBuilder,
    DownloadFormBuilder,
    ExternalFormBuilder,
    GenericFormBuilder,
    MultipleChoiceFormBuilder,
    SectionHeaderFormBuilder,
    ShortAnswerFormBuilder,
} from './components';
import {
    BID_BOND,
    CONFIRMATION,
    CUSTOM_VARIABLE,
    DATA_VERIFY,
    DOWNLOAD,
    EXTERNAL_FORM,
    FILE_UPLOAD,
    LONG_ANSWER,
    MULTIPLE_CHOICE,
    NOTARIZE,
    questionnaireTypes,
    SECTION_HEADER,
    SHORT_ANSWER,
    YES_NO,
} from '../../../../shared_config/questionnaires';

export class QuestionnaireFormBuilder extends PureComponent {
    static propTypes = {
        allowDefaultValue: PropTypes.bool,
        disabled: PropTypes.bool,
        form: PropTypes.string.isRequired,
        includeContainsPricingOption: PropTypes.bool,
        isTitleEditingDisabled: PropTypes.bool,
        questionnaireId: PropTypes.number,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        type: PropTypes.oneOf(questionnaireTypes).isRequired,
        useRawPrompt: PropTypes.bool,
    };

    static defaultProps = {
        tagOptions: undefined,
        templateVariableOptions: undefined,
        useRawPrompt: false,
    };

    render() {
        const {
            allowDefaultValue,
            disabled,
            form,
            includeContainsPricingOption,
            isTitleEditingDisabled,
            questionnaireId,
            tagOptions,
            templateVariableOptions,
            type,
            useRawPrompt,
        } = this.props;

        const sharedProps = {
            disabled,
            form,
            includeContainsPricingOption,
            isTitleEditingDisabled,
            tagOptions,
            templateVariableOptions,
            useRawPrompt,
        };

        switch (type) {
            case EXTERNAL_FORM:
                return <ExternalFormBuilder {...sharedProps} />;
            case CUSTOM_VARIABLE:
                return (
                    <GenericFormBuilder
                        {...sharedProps}
                        allowDefaultValue
                        titleLabel="Custom Variable Name"
                        titlePlaceholder="Enter variable name"
                    />
                );
            case CONFIRMATION:
            case FILE_UPLOAD:
                return <GenericFormBuilder {...sharedProps} />;
            case LONG_ANSWER:
            case SHORT_ANSWER:
                return (
                    <ShortAnswerFormBuilder
                        maxLengthDefault={type === LONG_ANSWER ? 5000 : 200}
                        {...sharedProps}
                    />
                );
            case YES_NO:
                return (
                    <GenericFormBuilder
                        allowDefaultValue={allowDefaultValue}
                        defaultValueType={YES_NO}
                        {...sharedProps}
                    />
                );
            case MULTIPLE_CHOICE:
                return (
                    <MultipleChoiceFormBuilder
                        allowDefaultValue={allowDefaultValue}
                        questionnaireId={questionnaireId}
                        {...sharedProps}
                    />
                );
            case DATA_VERIFY:
                return <DataVerifyFormBuilder {...sharedProps} />;
            case BID_BOND:
                return <BidBondFormBuilder {...sharedProps} />;
            case DOWNLOAD:
            case NOTARIZE:
                return <DownloadFormBuilder {...sharedProps} isNotarize={type === NOTARIZE} />;
            case SECTION_HEADER:
                return <SectionHeaderFormBuilder {...sharedProps} />;
            default:
                return null;
        }
    }
}
