import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { form } from './constants';
import { AttachmentUploader, Button, Checkbox, DateTimePicker, InputText } from '../../../..';
import { timeline } from '../../../../../Forms/validation';
import { customTimelineFieldsDict } from '../../../../../../../shared_config/timelines';

const {
    AGENDA_URL,
    DATE,
    DISPLAY_TIME,
    HAS_TEXT_DATE,
    IS_ATTENDANCE_REQUIRED,
    IS_PUBLIC,
    LOCATION,
    SHOW_LOCATION,
    TEXT_DATE,
    TITLE,
} = customTimelineFieldsDict;

const mapStateToProps = (state, props) => {
    return {
        agendaUrl: formValueSelector(form)(state, AGENDA_URL),
        displayTime: formValueSelector(form)(state, DISPLAY_TIME),
        hasTextDate: formValueSelector(form)(state, HAS_TEXT_DATE),
        isPublic: formValueSelector(form)(state, IS_PUBLIC),
        showLocation: formValueSelector(form)(state, SHOW_LOCATION),
        validate: (values) => timeline(values, { skipRequiredFields: props.isTemplate }),
    };
};

const formConfig = {
    form,
};

// @connect
// @reduxForm
class ConnectedTimelineCreateForm extends Component {
    static propTypes = {
        agendaUrl: PropTypes.string,
        cancelAddDate: PropTypes.func.isRequired,
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        displayTime: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        hasTextDate: PropTypes.bool,
        isPublic: PropTypes.bool,
        isTemplate: PropTypes.bool,
        projectId: PropTypes.number,
        showLocation: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    agendaFileDeleteHandler = () => {
        return this.props.change(AGENDA_URL, null);
    };

    agendaFileUploadHandler = (agendaFile) => {
        return this.props.change(
            AGENDA_URL,
            `https://${agendaFile.bucket}.s3.amazonaws.com/${agendaFile.path}`
        );
    };

    displayLocationChangeHandler = (e, showLocation) => {
        const { change } = this.props;

        if (!showLocation) {
            change(SHOW_LOCATION, false);
            change(IS_PUBLIC, false);
            change(IS_ATTENDANCE_REQUIRED, false);
            return change(LOCATION, null);
        }

        change(IS_PUBLIC, true);
        return change(SHOW_LOCATION, true);
    };

    isPublicChangeHandler = (e, isPublic) => {
        const { change } = this.props;

        if (!isPublic) {
            change(IS_PUBLIC, false);
            return change(IS_ATTENDANCE_REQUIRED, false);
        }

        return change(IS_PUBLIC, true);
    };

    render() {
        const {
            agendaUrl,
            cancelAddDate,
            disabled,
            displayTime,
            handleSubmit,
            hasTextDate,
            isPublic,
            isTemplate,
            projectId,
            showLocation,
        } = this.props;

        const { addBtn, checkbox } = this.styles;

        const isAgendaUpload = agendaUrl && agendaUrl.includes('government-project.s3.amazonaws');

        return (
            <span className="list-group-item">
                <div className="row">
                    <form onSubmit={handleSubmit}>
                        <div className="col-xs-6">
                            <Field
                                component={InputText}
                                disabled={disabled}
                                hasFeedback={false}
                                label="Timeline Name"
                                name={TITLE}
                                placeholder="Enter timeline name"
                                type="text"
                            />
                        </div>
                        <div className="col-xs-6">
                            {!hasTextDate && (
                                <Field
                                    component={DateTimePicker}
                                    disabled={isTemplate || disabled}
                                    label={displayTime ? 'Timeline Date & Time' : 'Timeline Date'}
                                    name={DATE}
                                    time={displayTime}
                                />
                            )}
                            {hasTextDate && (
                                <Field
                                    component={InputText}
                                    disabled={disabled}
                                    hasFeedback={false}
                                    label="Timeline Date as Text"
                                    name={TEXT_DATE}
                                    placeholder="Enter text to use instead of date"
                                    type="text"
                                />
                            )}
                            <Field
                                className={checkbox}
                                component={Checkbox}
                                disabled={disabled}
                                name={HAS_TEXT_DATE}
                                text="Use Text Instead of Date?"
                            />
                            {!hasTextDate && (
                                <Field
                                    className={checkbox}
                                    component={Checkbox}
                                    disabled={disabled}
                                    name={DISPLAY_TIME}
                                    text="Specify Time?"
                                />
                            )}
                            <Field
                                className={checkbox}
                                component={Checkbox}
                                disabled={disabled}
                                name={SHOW_LOCATION}
                                onChange={this.displayLocationChangeHandler}
                                text="Specify Location?"
                            />
                            {showLocation && (
                                <>
                                    <Field
                                        component={InputText}
                                        disabled={disabled}
                                        hasFeedback={false}
                                        help="Can be a conference room, address or teleconference number"
                                        label="Location"
                                        name={LOCATION}
                                        placeholder="West Conference Room - 123 Front St"
                                        type="textarea"
                                    />
                                    <Field
                                        className={checkbox}
                                        component={Checkbox}
                                        disabled={disabled}
                                        name={IS_PUBLIC}
                                        onChange={this.isPublicChangeHandler}
                                        text="Public Meeting?"
                                    />
                                    {isPublic && (
                                        <>
                                            <Field
                                                className={checkbox}
                                                component={Checkbox}
                                                disabled={disabled}
                                                name={IS_ATTENDANCE_REQUIRED}
                                                text="Require Attendance?"
                                            />
                                            {!isTemplate && (
                                                <>
                                                    <AttachmentUploader
                                                        allowSingleAttachmentOnly
                                                        attachments={
                                                            isAgendaUpload
                                                                ? [
                                                                      {
                                                                          filename: 'Agenda',
                                                                          path: agendaUrl,
                                                                          url: agendaUrl,
                                                                      },
                                                                  ]
                                                                : []
                                                        }
                                                        deleteHandler={this.agendaFileDeleteHandler}
                                                        disabled={disabled}
                                                        dropzoneOptions={{
                                                            text: 'Drop file or click to select file to upload for meeting agenda',
                                                        }}
                                                        label="Agenda"
                                                        onSuccess={this.agendaFileUploadHandler}
                                                        s3GetUrl={`/project/${projectId}/agenda/s3`}
                                                        skipForm
                                                    />
                                                    {!isAgendaUpload && (
                                                        <Field
                                                            component={InputText}
                                                            disabled={disabled}
                                                            hasFeedback={false}
                                                            label="Agenda URL"
                                                            name={AGENDA_URL}
                                                            placeholder="Upload agenda above or add URL here"
                                                            type="text"
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="text-center">
                            <Button disabled={disabled} onClick={() => cancelAddDate()}>
                                <i className="fa fa-times" /> Cancel
                            </Button>
                            <div className={addBtn}>
                                <Button bsStyle="success" disabled={disabled} type="submit">
                                    OK
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </span>
        );
    }
}

export const TimelineCreateForm = compose(
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedTimelineCreateForm);
