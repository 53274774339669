import { isNumber, round, sumBy } from 'lodash';

import { currencyFormatter } from '.';
import {
    getScorePercentage,
    isCostFormulaScoringCriterium,
    shouldComputeScorePercentage,
    PASS_FAIL,
} from '../../../shared_config/evaluations';

function hasScore(criteriaScore) {
    return isNumber(criteriaScore);
}

export function roundNumber(num, precision) {
    if (!hasScore(num)) return undefined;
    return round(num, precision);
}

export function isPassFailCriteria(scoringCriteria) {
    return scoringCriteria.scoringMethod === PASS_FAIL;
}

const getNonRankedScoreText = (props) => {
    const {
        criteriaScore,
        defaultText = '-',
        isTotal,
        scoringCriteria,
        showPercentageTotals,
    } = props;

    if (!hasScore(criteriaScore)) return defaultText;
    if (isTotal && showPercentageTotals) return `${criteriaScore}%`;
    // Handle pass/fail case
    if (isPassFailCriteria(scoringCriteria)) return criteriaScore === 0 ? 'Fail' : 'Pass';
    if (isCostFormulaScoringCriterium(scoringCriteria)) {
        const hasCents = criteriaScore.toString().indexOf('.') !== -1;
        const cost = currencyFormatter({ value: criteriaScore }, { use0FractionDigits: !hasCents });
        if (!hasScore(scoringCriteria.computedMaxScore)) {
            return cost;
        }
        const scorePercent = getScorePercentage(criteriaScore, scoringCriteria);
        if (scorePercent === 0) {
            // Score will typically be 0 in case of an excluded proposal (score gets forced to 0).
            // In these cases we just want to display a 0 for the score and not include the cost
            // since the cost is not relevant.
            return 0;
        }
        const weightedScore = scorePercent * scoringCriteria.weight;
        return `${roundNumber(weightedScore, 2)} (${cost})`;
    }
    return criteriaScore;
};

export function scoreText(props) {
    const { rankedScore, usesRankedScoring } = props;

    const nonRankedScoreText = getNonRankedScoreText(props);

    if (usesRankedScoring && rankedScore) {
        return `${nonRankedScoreText} (${rankedScore})`;
    }
    return nonRankedScoreText;
}

export function scoreColor(props) {
    const {
        criteriaScore,
        isDocx,
        isTotal,
        isUnsubmittedScore,
        scoringCriteria,
        showPercentageTotals,
        totalScorePercentage,
    } = props;

    if (!shouldComputeScorePercentage(criteriaScore, scoringCriteria)) return undefined;

    // Apply no color treatment to unsubmitted scores
    if (isUnsubmittedScore) return undefined;

    const scorePercent =
        isTotal && !showPercentageTotals
            ? totalScorePercentage
            : getScorePercentage(criteriaScore, scoringCriteria) * 100;

    if (scorePercent >= 100) return isDocx ? '#d0e9c6' : 'success-dark';
    if (scorePercent >= 90) return isDocx ? '#dff0d8' : 'success';
    if (scorePercent >= 80) return isDocx ? '#e8f4e3' : 'success-light';
    if (scorePercent >= 70) return isDocx ? '#cce7f4' : 'info-dark';
    if (scorePercent >= 60) return isDocx ? '#d9edf7' : 'info';
    if (scorePercent >= 50) return isDocx ? '#e6f3fa' : 'info-light';
    if (scorePercent >= 40) return isDocx ? '#faf2cc' : 'warning-dark';
    if (scorePercent >= 30) return isDocx ? '#fcf8e3' : 'warning';
    if (scorePercent >= 20) return isDocx ? '#f6e9e9' : 'danger-light';
    if (scorePercent >= 10) return isDocx ? '#f2dede' : 'danger';
    return isDocx ? '#ebcccc' : 'danger-dark';
}

export function formatCSVExportScorePercentage(score, showPercentageTotals) {
    if (score || score === 0) {
        if (showPercentageTotals) {
            return `${score.toFixed(2)}%`;
        }
        return `${score.toFixed(2)}`;
    }
    return '';
}

export function getTotalWeight(evaluation) {
    if (evaluation) {
        return sumBy(evaluation.scoringCriteria, 'weight') || 100;
    }
    return 100;
}
