import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@og-pro/ui';
import { Field } from 'redux-form';

import { InputText } from '../../InputText';
import { fieldsEditableFieldNames } from './constants';

const { VALUE } = fieldsEditableFieldNames;

// SignatureHeader is just a regular Signature Block except the "styling" prop is different
// The block contains only 1 element in the "fields" prop which is the actual Header field.
// that's why we render like this, we are certain that's the only element and that it exists.
// Cant be removed, you have to remove the whole block.
export const SignatureHeader = ({ disabled, showValidation, name }) => {
    return (
        <Box>
            <Box mb={2}>
                <Box>
                    <Typography fontWeight={500}>Signature Block Header</Typography>
                </Box>
            </Box>
            <Box>
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    label={null}
                    name={`${name}[0].${VALUE}`}
                    placeholder="Enter Text"
                    showValidation={showValidation}
                    useOpenGovStyle
                />
            </Box>
        </Box>
    );
};

SignatureHeader.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    showValidation: PropTypes.bool,
};
