import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ActionSidebar, Button, ButtonGroup } from './ActionSidebar';
import { Main } from './Main';

export const ContentBlock = ({ error, variant, withActions = false, className, children }) => {
    const criteriaItemRef = useRef();
    const [isActive, setIsActive] = useState(false);
    const styles = require('./index.scss');

    /*
     * Need to use this hack because :focus-within will not work with an iframe :/
     */
    useEffect(() => {
        if (criteriaItemRef.current) {
            if (
                document.activeElement.nodeName === 'IFRAME' &&
                criteriaItemRef.current.contains(document.activeElement)
            ) {
                setIsActive(true);
            } else {
                setIsActive(false);
            }
        }
    }, [document.activeElement]);

    return (
        <div className={styles.contentBlockContainer}>
            <div
                className={classnames(
                    styles.contentBlock,
                    styles[variant],
                    {
                        [styles.active]: isActive,
                        [styles.withActions]: withActions,
                        [styles.hasError]: !!error,
                    },
                    className
                )}
                ref={criteriaItemRef}
            >
                {children}
            </div>
            {!!error && (
                <div className={styles.error}>
                    <i className="fa fa-exclamation-triangle" /> {error}
                </div>
            )}
        </div>
    );
};

ContentBlock.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    variant: PropTypes.oneOf(['primary', 'secondary']),
    withActions: PropTypes.bool.isRequired,
};
ContentBlock.defaultProps = {
    variant: 'primary',
};

ContentBlock.Main = Main;
ContentBlock.ActionSidebar = ActionSidebar;
ContentBlock.ButtonGroup = ButtonGroup;
ContentBlock.Button = Button;
