import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { InputText, RichTextInput } from '../../../..';
import { PROMPT, RAW_PROMPT, TITLE } from '../../../../../../../shared_config/questionnaires';

export class SectionHeaderFormBuilder extends PureComponent {
    static propTypes = {
        disabled: PropTypes.array,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        useRawPrompt: PropTypes.bool,
    };

    static defaultProps = {
        tagOptions: undefined,
        templateVariableOptions: undefined,
        useRawPrompt: false,
    };

    render() {
        const { disabled, tagOptions, templateVariableOptions, useRawPrompt } = this.props;

        return (
            <div>
                <Field
                    component={InputText}
                    disabled={disabled}
                    label="Section Name"
                    name={TITLE}
                    placeholder="Enter section name"
                    type="text"
                />
                <Field
                    component={RichTextInput}
                    disabled={disabled}
                    help="Add a description for the section (optional)"
                    label="Section Description"
                    minRows={2}
                    name={useRawPrompt ? RAW_PROMPT : PROMPT}
                    placeholder="Enter description"
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                />
            </div>
        );
    }
}
