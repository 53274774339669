import { sectionTypeNames } from '../../../shared_config/sections';
import { subsectionTypeNames } from '../../../shared_config/subsections';

const { TEXT_AREA } = sectionTypeNames;
const { BODY } = subsectionTypeNames;

function getModelAssociationKey(thread) {
    return ['criterion_id', 'project_subsection_id'].find((association) => !!thread[association]);
}

//
/**
 * Takes a thread and encodes it as a key on which to access comments
 *
 * IMPORTANT: One of `criterion_id` or `project_subsection_id` is required.
 *
 * @param  {object} thread Thread data to use for creating an comment thread key
 * @param  {object} thread.project_section_id Project section associated with thread
 * @param  {object} [thread.criterion_id] Criteria associated with thread
 * @param  {object} [thread.project_subsection_id] Project subsection associated with thread
 * @param  {object} [thread.type] Comment type of the thread
 * @return {string} Comment thread key that can be decrypted to get thread info
 */
export function getCommentThreadKey(thread) {
    const { type } = thread;

    const modelAssociationKey = getModelAssociationKey(thread);

    // In the event that a previously commented on item is deleted there will
    // be no criteria or subsection type.
    if (!modelAssociationKey) return null;

    // Comment will either be on a subsection or a criteria. So use ID from whichever key is
    // populated as the identifier.
    const associatedItemId = thread[modelAssociationKey];

    const commentBase = `${modelAssociationKey}.${associatedItemId}`;
    return type ? `${commentBase}.${type}` : commentBase;
}

// Takes a comment key and returns the deconstructed thread values.
export function decryptCommentThreadKey(key) {
    const [associationField, associationId, type] = key.split('.');

    return {
        associationField,
        associationId: Number.parseInt(associationId, 10),
        type,
    };
}

const formatCommentProjectField = (projectField, isProjectSubsection) => {
    if (projectField) {
        const { orderById: originalOrderById } = projectField;

        const title = projectField.title || 'Untitled';
        const description = projectField.description || title;

        // Ensure projectSubsections are displayed before criteria
        const orderById = isProjectSubsection ? originalOrderById - 100000 : originalOrderById;

        return {
            ...projectField,
            title,
            description,
            orderById,
        };
    }
};

/**
 * Gets a formatted project field that a given thread belongs to
 * @param  {object} project               Project that thread belongs to
 * @param  {object} projectSubsectionsMap Look up map for projectSubsections (see commentSelectors.js)
 * @param  {object} criteriaMap           Look up map for project criteria (see commentSelectors.js)
 * @param  {object} threadKey             Comment thread key created by `getCommentThreadKey`
 * @return {object}                       Project field properly formatted
 */
export function getThreadProjectField(project, projectSubsectionsMap, criteriaMap, threadKey) {
    const { associationField, associationId } = decryptCommentThreadKey(threadKey);
    const { projectSections } = project;
    // The selected item is a criteria field type
    if (associationField === 'criterion_id') {
        const criteria = criteriaMap[associationId];
        const criteriaTitle =
            criteria.section_type === TEXT_AREA
                ? projectSections.find((section) => section.id === criteria.project_section_id)
                      .title
                : criteria.title;

        return formatCommentProjectField({
            ...criteria,
            title: criteriaTitle,
        });
    }

    // The selected item is a project subsection field type
    if (associationField === 'project_subsection_id') {
        const projectSubsection = projectSubsectionsMap[associationId];

        if (projectSubsection.hasCommentDescription) {
            return formatCommentProjectField(
                {
                    ...projectSubsection,
                    description: project[projectSubsection.projectDescriptionKey],
                },
                true
            );
        }
        if (projectSubsection.subsection_type === BODY) {
            const projectSection = projectSections.find(
                (projSection) => projSection.id === projectSubsection.project_section_id
            );
            return formatCommentProjectField(
                {
                    ...projectSubsection,
                    title: `${projectSection.title} Section`,
                },
                true
            );
        }
        return formatCommentProjectField(projectSubsection, true);
    }
}
