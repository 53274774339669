import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ContactAddress } from '..';
import {
    contactFieldNames,
    getContactPhoneComplete,
    mappedContactFields,
} from '../../../../shared_config/contacts';
import { UNITED_STATES_PHONE_COUNTRY_CODE } from '../../../../shared_config/global';

const {
    ADDRESS_1,
    ADDRESS_2,
    CITY,
    COUNTRY,
    COUNTRY_CODE,
    EMAIL,
    FIRST_NAME,
    LAST_NAME,
    PHONE,
    PHONE_COMPLETE,
    PHONE_COUNTRY,
    PHONE_EXT,
    STATE,
    TITLE,
    ZIP_CODE,
} = contactFieldNames;

export class ContactInfo extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        label: PropTypes.string,
        fieldNames: PropTypes.object,
        showContactAddress: PropTypes.bool,
    };

    static defaultProps = {
        fieldNames: mappedContactFields,
    };

    /*
     * IMPORTANT: This component is rendered by the DOCX templater. Doing anything fancy with
     * classes or components will likely not work well when converting to docx. If fancy formatting
     * is ever needed add a `isDocx` prop and create a simple render version to use with docx.
     */
    render() {
        const {
            className,
            label,
            showContactAddress,
            [this.props.fieldNames[ADDRESS_1]]: contactAddress1,
            [this.props.fieldNames[ADDRESS_2]]: contactAddress2,
            [this.props.fieldNames[CITY]]: contactCity,
            [this.props.fieldNames[COUNTRY]]: contactCountry,
            [this.props.fieldNames[COUNTRY_CODE]]: contactCountryCode,
            [this.props.fieldNames[EMAIL]]: contactEmail,
            [this.props.fieldNames[FIRST_NAME]]: contactFirstName,
            [this.props.fieldNames[LAST_NAME]]: contactLastName,
            [this.props.fieldNames[PHONE]]: contactPhone,
            [this.props.fieldNames[PHONE_COMPLETE]]: contactPhoneComplete,
            [this.props.fieldNames[PHONE_COUNTRY]]: contactPhoneCountry,
            [this.props.fieldNames[PHONE_EXT]]: contactPhoneExt,
            [this.props.fieldNames[STATE]]: contactState,
            [this.props.fieldNames[TITLE]]: contactTitle,
            [this.props.fieldNames[ZIP_CODE]]: contactZipCode,
        } = this.props;

        let contactTelUri = contactPhone;
        if (contactPhoneCountry !== UNITED_STATES_PHONE_COUNTRY_CODE) {
            contactTelUri = `+${contactPhoneCountry}${contactTelUri}`;
        }
        if (contactPhoneExt) {
            // https://www.ietf.org/rfc/rfc3966.txt
            contactTelUri = `${contactTelUri};ext=${contactPhoneExt}`;
        }

        return (
            <div className={className}>
                {label && (
                    <>
                        <strong>
                            <u>{label}</u>
                        </strong>
                        <br />
                    </>
                )}
                {(contactFirstName || contactLastName) && (
                    <>
                        <strong>
                            {contactFirstName || ''} {contactLastName || ''}
                        </strong>
                        <br />
                    </>
                )}
                {contactTitle && (
                    <>
                        {contactTitle}
                        <br />
                    </>
                )}
                {showContactAddress && (
                    <ContactAddress
                        address1={contactAddress1}
                        address2={contactAddress2}
                        city={contactCity}
                        country={contactCountry}
                        countryCode={contactCountryCode}
                        state={contactState}
                        zipCode={contactZipCode}
                    />
                )}
                {contactEmail && (
                    <>
                        Email:&nbsp;
                        <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                        <br />
                    </>
                )}
                {contactPhone && (
                    <>
                        Phone:&nbsp;
                        <a href={`tel:${contactTelUri}`}>
                            {contactPhoneComplete ||
                                // New contacts may not have this "field" yet since it is
                                // generated on the server
                                getContactPhoneComplete(
                                    contactPhone,
                                    contactPhoneExt,
                                    contactPhoneCountry
                                )}
                        </a>
                        <br />
                    </>
                )}
            </div>
        );
    }
}
