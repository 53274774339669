import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Alert } from 'react-bootstrap';
import Media from 'react-media';
import Sticky from 'react-stickynode';
import { createSearchParams, Outlet } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { Button, ProjectSectionsEditButton, Nav, NavItem } from '..';
import { getSectionNumberingString } from '../../../../shared_config/helpers';
import { sectionTypeNames } from '../../../../shared_config/sections';
import { SCREEN_XS_MAX } from '../../constants/mediaQuery';
import { FIXED_TOOLBAR_ADJUSTMENT_HEIGHT } from '../../constants/styles';

const { DIVIDER } = sectionTypeNames;

class ConnectedProjectDetailNav extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        hasToolbar: PropTypes.bool,
        isAppliable: PropTypes.bool,
        isEditable: PropTypes.bool,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            query: PropTypes.shape({
                // Will be a number (except in 'all' case), but query params are always string types
                section: PropTypes.string,
            }).isRequired,
        }).isRequired,
        project: PropTypes.shape({
            projectSections: PropTypes.arrayOf(
                PropTypes.shape({
                    isHidden: PropTypes.bool.isRequired,
                    isHiddenByLogic: PropTypes.bool,
                    manualNumber: PropTypes.string,
                    sectionNumber: PropTypes.number.isRequired,
                    section_type: PropTypes.string.isRequired,
                    subsectionNumber: PropTypes.number,
                    title: PropTypes.string.isRequired,
                })
            ).isRequired,
            useSectionDividers: PropTypes.bool.isRequired,
            useManualNumbering: PropTypes.bool,
        }).isRequired,
        sectionCommentCounts: PropTypes.object,
        showComments: PropTypes.bool,
    };

    get styles() {
        return require('./ProjectDetailNav.scss');
    }

    // Scrolls to the top of a section if the section header is not in view
    scrollToSectionTop = () => {
        const targetEl = document.getElementById('project-section-container');
        if (targetEl) {
            targetEl.scrollIntoView();
            const pageYOffset = window.pageYOffset;
            if (pageYOffset && this.props.hasToolbar) {
                // Offset scroll position to account for fixed navbar
                window.scroll(0, pageYOffset - FIXED_TOOLBAR_ADJUSTMENT_HEIGHT);
            }
        }
    };

    navItems() {
        const {
            location: { pathname, query },
            project: { projectSections, useManualNumbering, useSectionDividers },
        } = this.props;

        return projectSections
            .filter((projectSection) => !projectSection.isHidden && !projectSection.isHiddenByLogic)
            .map((projectSection, idx) => {
                const {
                    id,
                    manualNumber,
                    sectionNumber,
                    subsectionNumber,
                    section_type: sectionType,
                    title,
                    disableNumbering,
                } = projectSection;
                const route = {
                    pathname,
                    search: createSearchParams({ ...query, section: id }).toString(),
                };

                // an item is active when the section id matches the query param section
                // except the first item which is also active when there's no query param
                const active = (!query.section && idx === 0) || parseInt(query.section, 10) === id;
                const indentClass =
                    useSectionDividers && sectionType !== DIVIDER ? this.styles.indent : undefined;
                const sectionNumbering = getSectionNumberingString({
                    manualNumber,
                    sectionNumber,
                    subsectionNumber,
                    useManualNumbering,
                    disableNumbering,
                });

                return (
                    <NavItem
                        className={active ? 'active' : null}
                        key={id}
                        overrideIsActive={false}
                        qaTag={`projectDetail-${id}.${title}`}
                        to={route}
                    >
                        <div className={`${this.styles.commentOverflow} ${indentClass}`}>
                            {sectionNumbering} {title}
                            {this.renderCommentCountIcon(id)}
                        </div>
                    </NavItem>
                );
            });
    }

    renderCommentCountIcon(id) {
        const { showComments, sectionCommentCounts } = this.props;
        if (!showComments || !sectionCommentCounts) return null;

        const commentCount = sectionCommentCounts[id];
        if (!commentCount) return null;

        return (
            <span className="pull-right">
                <i className="fa fa-comment-o" /> {commentCount}
            </span>
        );
    }

    renderNavItems = (stickyDisabled) => {
        const {
            hasToolbar,
            isEditable,
            location: {
                pathname,
                query: { section },
            },
            project,
        } = this.props;

        const offset = hasToolbar ? FIXED_TOOLBAR_ADJUSTMENT_HEIGHT : 10;
        const sectionText =
            section === 'all' ? (
                'Viewing All Sections'
            ) : (
                <span>
                    <i className="fa fa-plus-square-o" /> View All Sections
                </span>
            );

        return (
            <Sticky
                bottomBoundary="#project-detail-nav-container"
                enabled={!stickyDisabled}
                top={offset}
            >
                {({ status }) => (
                    <>
                        <div className={this.styles.projectDetailNav}>
                            <Nav
                                bsStyle="pills"
                                onClick={
                                    status === Sticky.STATUS_FIXED && !stickyDisabled
                                        ? this.scrollToSectionTop
                                        : undefined
                                }
                                stacked
                            >
                                {this.navItems()}
                            </Nav>
                        </div>
                        <div className="text-center">
                            <Button
                                bsSize="sm"
                                bsStyle="link"
                                onClick={
                                    status === Sticky.STATUS_FIXED && !stickyDisabled
                                        ? this.scrollToSectionTop
                                        : undefined
                                }
                                qaTag="projectDetailNav-sectionButton"
                                to={{
                                    pathname,
                                    search: createSearchParams({
                                        section: 'all',
                                    }).toString(),
                                }}
                            >
                                {sectionText}
                            </Button>
                            {isEditable && (
                                <ProjectSectionsEditButton
                                    bsSize="sm"
                                    project={project}
                                    text="Edit Sections"
                                />
                            )}
                        </div>
                    </>
                )}
            </Sticky>
        );
    };

    renderApplyAlert() {
        return (
            <Alert bsStyle="warning" className={this.styles.applyAlert}>
                <i className="fa fa-info-circle" />
                &nbsp; To respond to this project, please click the{' '}
                <strong>&quot;Draft Response&quot;</strong> button above.
            </Alert>
        );
    }

    render() {
        const { isAppliable, children } = this.props;

        return (
            <div
                className={`row ${this.styles.projectDetailNavContainer}`}
                id="project-detail-nav-container"
            >
                <div className={`col-sm-4 col-md-3 ${this.styles.navContainer} no-print`} id="skip">
                    <Media query={`(max-width: ${SCREEN_XS_MAX}px)`}>{this.renderNavItems}</Media>
                </div>
                <div className="col-sm-8 col-md-9" id="project-section-container">
                    {isAppliable && this.renderApplyAlert()}
                    {children}
                    <Outlet />
                </div>
            </div>
        );
    }
}

export const ProjectDetailNav = withRouter(ConnectedProjectDetailNav);
