import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getNavItems, getNumberOfBidsTotal, getSelectedRoute } from '../selectors';
import { getDashboardPath, getProjectJS } from '../../selectors';
import { getQuestionsJS, getUserOrganizationTimezone } from '../../../selectors';
import { CountdownTimerDigital, StatusLabel, UnderlineNav } from '../../../../components';
import { displayDateFormatter } from '../../../../helpers';
import { NumberOfBidsDigital } from './components/NumberOfBidsDigital';
import { timezoneAbbreviations } from '../../../../constants';
import { LIVE_Q_AND_A } from './constants';
import { questionStatusTypes } from '../../../../../../shared_config/questions';
import { getIsAuctionLive } from '../../App/selectors';
import { loadQuestions } from '../../../../actions/questions';

const { EXCLUDED } = questionStatusTypes;

export const ReverseAuctionNav = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dashboardPath = useSelector((state) => getDashboardPath(state, props));
    const navItems = useSelector((state) => getNavItems(state, props));
    const project = useSelector((state) => getProjectJS(state, props));
    const selectedRoute = useSelector((state) => getSelectedRoute(state, props));
    const timezone = useSelector((state) => getUserOrganizationTimezone(state, props));
    const numberOfBidsTotal = useSelector(getNumberOfBidsTotal);
    const questions = useSelector(getQuestionsJS);
    const isAuctionLive = useSelector(getIsAuctionLive);

    const unansweredQuestionsCount = questions.filter(
        (q) => q.isAuctionQuestion && q.status !== EXCLUDED && !q.releasedAt
    ).length;
    const unansweredQuestionsCountRef = useRef(unansweredQuestionsCount);

    // Unmounting the QA component dispatches a reset of the questions so have to reload them
    // to ensure the unanswered count stays visible.
    useEffect(() => {
        if (unansweredQuestionsCountRef.current > 0 && unansweredQuestionsCount === 0) {
            dispatch(loadQuestions(project.id, true));
        }
    }, [dispatch, project.id, unansweredQuestionsCount]);

    const handleTitleClick = () => navigate(dashboardPath);

    const { auctionEndDate, auctionStartDate, title } = project;

    const styles = require('./index.scss');

    return (
        <div className={`container ${styles.container}`}>
            <div className={`row ${styles.header}`}>
                <div className="col-xs-12 col-lg-7">
                    <div className={styles.titleContainer}>
                        <span className={styles.title} onClick={handleTitleClick}>
                            {title}
                        </span>
                        <StatusLabel
                            bsStyle={isAuctionLive ? 'info' : 'warning'}
                            className={styles.statusLabel}
                        >
                            {isAuctionLive ? 'Live' : 'Ended'}
                        </StatusLabel>
                    </div>
                    <div className={styles.timelineDetails}>
                        <div className={styles.timelineItem}>
                            <strong>Start: </strong>
                            {displayDateFormatter(auctionStartDate, timezone)}
                        </div>
                        <div className={styles.timelineItem}>
                            <strong>End: </strong>
                            {displayDateFormatter(auctionEndDate, timezone)}
                        </div>
                        <div className={styles.timezoneText}>
                            <em>All dates & times in {timezoneAbbreviations[timezone]}</em>
                        </div>
                    </div>
                </div>
                <div className={`col-xs-12 col-lg-5 ${styles.pullRightLg}`}>
                    <NumberOfBidsDigital numberOfBids={numberOfBidsTotal} />
                    <CountdownTimerDigital
                        endTime={auctionEndDate}
                        label={`${project.auctionExtensionCount} Clock Extensions`}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <UnderlineNav>
                        {navItems.map(({ name, path, route }) => (
                            <UnderlineNav.NavItem
                                className={classnames({ active: route === selectedRoute })}
                                exact
                                key={route}
                                overrideIsActive={false}
                                qaTag={`reverseAuctionNav-${path}`}
                                to={route}
                            >
                                {name}
                                {name === LIVE_Q_AND_A && unansweredQuestionsCount
                                    ? ` (${unansweredQuestionsCount})`
                                    : ''}
                            </UnderlineNav.NavItem>
                        ))}
                    </UnderlineNav>
                </div>
            </div>
        </div>
    );
};
