import { createSelector } from 'reselect';

import { sectionTypeNames } from '../../../../../shared_config/sections';

const getCategories = (state) => state.projectLibrary.get('categories');
const getProjects = (state) => state.projectLibrary.get('projects');
const getSelectedProject = (state) => state.projectLibrary.get('selectedProject');

export const getCategoriesJS = createSelector([getCategories], (categories) => categories.toJS());

export const getProjectsJS = createSelector([getProjects], (rawProjects) => {
    if (rawProjects) {
        return rawProjects.toJS();
    }
    return [];
});

export const getProjectJS = createSelector([getSelectedProject], (rawProject) => {
    if (rawProject) {
        return rawProject.toJS();
    }
});

export const getProjectScopeJS = createSelector([getProjectJS], (project) => {
    if (project) {
        return project.criteria.filter((crit) => crit.section_type === sectionTypeNames.SCOPE);
    }
    return [];
});

export const getProjectScopeSelectionJS = createSelector([getProjectScopeJS], (scope) => {
    return scope.filter((crit) => crit.selected);
});
