import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { useParams, useNavigate } from 'react-router-dom';

import { validate } from './validate';
import { form, policyTimelineOptions } from './constants';
import { Button, Checkbox, InputText, PageTitle, RadioGroup, Toggle } from '../../../../components';
import { addPolicyFormNamesDict, YEARS } from '../../../../../../shared_config/recordsRetention';
import { addNewPolicy, editPolicy } from '../../../../actions/retention';
import { getGovernmentRetentionCodesJS } from '../../selectors';
import { getUserJS } from '../../../selectors';
import { getArchiveSchedule, getArchiveScheduleFormOption } from './util';
import { ArchiveScheduleErrorsField } from './ArchiveScheduleErrorsField';

const formConfig = {
    form,
    validate,
};

const {
    TITLE,
    ABBREVIATION,
    IS_ACTIVE,
    ARCHIVE_SCHEDULE,
    ARCHIVE_SCHEDULE_HAS_ERRORS,
    SCHEDULE_OPTIONS,
    EXPIRE_AT_FISCAL_YEAR_START,
} = addPolicyFormNamesDict;

const mapStateToProps = (state) => {
    const formSelector = formValueSelector(form);
    return {
        isActive: formSelector(state, IS_ACTIVE) || false,
        retentionCodes: getGovernmentRetentionCodesJS(state),
        user: getUserJS(state),
    };
};

const ConnectedPolicyForm = ({ handleSubmit, initialize, isActive, retentionCodes, user }) => {
    const params = useParams();
    const navigate = useNavigate();

    const selectedCode = params.retentionCodeId
        ? retentionCodes.find((code) => code.id === Number.parseInt(params.retentionCodeId, 10))
        : null;

    const styles = require('./index.scss');
    const onCancel = () => {
        navigate(`/governments/${user.government_id}/retention-admin/manage`);
    };

    useEffect(() => {
        if (selectedCode) {
            const scheduleOptions = getArchiveScheduleFormOption(selectedCode.archiveSchedule);

            initialize({
                ...selectedCode,
                archiveSchedule: {
                    years: selectedCode.archiveSchedule,
                },
                scheduleOptions,
            });
        }
    }, []);

    const submitHandler = (values, dispatch) => {
        const policyOptions = {
            data: {
                ...values,
                archiveSchedule: getArchiveSchedule(values.archiveSchedule, values.scheduleOptions),
            },
        };

        // Field has no use in the api.
        delete policyOptions.data.scheduleOptions;
        return selectedCode
            ? dispatch(editPolicy(policyOptions))
            : dispatch(addNewPolicy(policyOptions));
    };

    // Per https://stackoverflow.com/questions/71940877/with-redux-form-how-can-i-normalize-and-allow-only-positive-integers-on-an-input
    const parsePositiveInt = (i) => {
        const matches = i.match(/^\d+$/);
        return matches ? parseInt(matches[0], 10) : 0;
    };

    const pageTitle = selectedCode ? 'Edit Policy' : 'Add New Policy';

    return (
        <div className={`container ${styles.container}`}>
            <PageTitle title={pageTitle} />
            <h3 className={styles.title}>{pageTitle}</h3>
            <div className="row">
                <div className={`col-sm-6 ${styles.column}`}>
                    <h4 className={styles.description}>Description of Policy</h4>
                    <Field
                        component={InputText}
                        label="Title"
                        name={TITLE}
                        qaTag="addPolicyForm-title"
                    />
                    <Field
                        className={styles.abbreviation}
                        component={InputText}
                        label="Abbreviation"
                        name={ABBREVIATION}
                        qaTag="addPolicyForm-abbreviation"
                    />
                    <h4 className={styles.policyAvailable}>Set This Policy To:</h4>
                    <div className={styles.toggle}>
                        <span>Unavailable</span>
                        <Field
                            aria-label="Policy Availability Toggle"
                            component={Toggle}
                            name={IS_ACTIVE}
                            qaTag="addPolicyForm-availability"
                        />
                        <div>Available</div>
                    </div>
                    <div className={styles.muted}>
                        {isActive
                            ? 'The policy is currently in use, and can be applied to closed projects.'
                            : 'The policy cannot be applied to closed projects.'}
                    </div>
                </div>
                <div className={`col-sm-6 ${styles.column}`}>
                    <h4 className={styles.schedule}>Schedule</h4>
                    <Field
                        component={RadioGroup}
                        defaultChecked={YEARS}
                        groupLabel={ARCHIVE_SCHEDULE}
                        label="Retention"
                        name={SCHEDULE_OPTIONS}
                        normalizeOption={(v) => parsePositiveInt(v).toString()}
                        options={policyTimelineOptions}
                        sublabel="After this time, documents with this policy will be candidates for permanent deletion."
                    />
                    <Field
                        component={ArchiveScheduleErrorsField}
                        name={ARCHIVE_SCHEDULE_HAS_ERRORS}
                    />
                    <Field
                        component={Checkbox}
                        name={EXPIRE_AT_FISCAL_YEAR_START}
                        qaTag="addPolicyForm-calculateExpirationDate"
                        text="Calculate expiration dates based on the start of the next fiscal year."
                    />
                    <div className={styles.buttonContainer}>
                        <Button onClick={onCancel} qaTag="addPolicyForm-cancel">
                            Cancel
                        </Button>
                        <Button
                            bsStyle="primary"
                            className={styles.button}
                            onClick={handleSubmit(submitHandler)}
                            qaTag="addPolicyForm-save"
                        >
                            Save Policy
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const PolicyForm = compose(
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedPolicyForm);

ConnectedPolicyForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    retentionCodes: PropTypes.array.isRequired,
    user: PropTypes.shape({
        government_id: PropTypes.number.isRequired,
    }),
};
