import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import { showExportModal } from '../../../../actions/exportProject';
import { CDSDropdownButton, MenuItem, Tooltip } from '../../../../components';
import { trackEvent } from '../../../../helpers';
import { getProjectJS } from '../../selectors';
import { sectionTypeNames } from '../../../../../../shared_config/sections';
import { getWritingSections } from '../../ProjectCreate/selectors';

const { ATTACHMENTS, DIVIDER } = sectionTypeNames;

export const ExportButton = ({ context, disabled, projectDocumentUrl, shouldSave, standalone }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [query] = useSearchParams();
    const [selectedSection, setSelectedSection] = useState({});
    const project = useSelector(getProjectJS);
    const writingSections = useSelector(getWritingSections);
    const isDocument = location.pathname.includes('document');
    const selectedSectionIdOrIdx = useRef(null);

    useEffect(() => {
        if (!isDocument) {
            selectedSectionIdOrIdx.current = null;
            return setSelectedSection({});
        }

        const activeSectionId = query.get('section');
        const activeSectionIdx = query.get('activeSection') || 0; // Accounts for case when first navigating to document

        if (
            selectedSectionIdOrIdx.current !== +activeSectionId ||
            selectedSectionIdOrIdx.current !== +activeSectionIdx
        ) {
            if (activeSectionId) {
                setSelectedSection(
                    project.projectSections.find((section) => section.id === +activeSectionId)
                );
                selectedSectionIdOrIdx.current = +activeSectionId;
            } else {
                const filteredSections = writingSections.filter(
                    (section) => section.id !== 1 && section.id !== 2 && section.id !== 3
                );
                setSelectedSection(filteredSections[+activeSectionIdx]);
                selectedSectionIdOrIdx.current = +activeSectionIdx;
            }
        }
    }, [isDocument, project.projectSections, query, writingSections]);

    const styles = require('./shared.scss');

    const handleDownloadClick = (_event, sectionOnly = false) => {
        const eventData = { view: context };

        if (sectionOnly) {
            trackEvent('Export Project Section Modal', eventData);
            dispatch(showExportModal(null, shouldSave, selectedSection.id));
        } else {
            trackEvent('Export Project Modal', eventData);
            dispatch(showExportModal(null, shouldSave));
        }
    };

    const handleDownloadOriginalClick = () => {
        const eventData = { view: context };

        trackEvent('Download Original Project', eventData);
    };

    const handlePreviewClick = (_event, sectionOnly = false) => {
        const eventData = { view: context };

        if (sectionOnly) {
            trackEvent('Preview Project Section', eventData);
            dispatch(showExportModal('preview', shouldSave, selectedSection.id));
        } else {
            trackEvent('Preview Project', eventData);
            dispatch(showExportModal('preview', shouldSave));
        }
    };

    const renderSectionExportItems = () => {
        if (!selectedSection?.id) return null;

        const { section_type: sectionType } = selectedSection;
        const itemsDisabled = sectionType === DIVIDER || sectionType === ATTACHMENTS;
        const disabledHelpText = itemsDisabled
            ? 'This section type cannot be exported individually'
            : '';

        const menuItems = [
            <MenuItem
                disabled={itemsDisabled}
                key="previewCurrentSection"
                onClick={itemsDisabled ? null : (e) => handlePreviewClick(e, true)}
                qaTag="projectActionMenu-previewCurrentSection"
            >
                Preview Current Section
            </MenuItem>,
            <MenuItem
                disabled={itemsDisabled}
                key="downloadCurrentSection"
                onClick={itemsDisabled ? null : (e) => handleDownloadClick(e, true)}
                qaTag="projectActionMenu-downloadCurrentSection"
            >
                Download Current Section
            </MenuItem>,
        ];

        return (
            <>
                <hr className={styles.dividerLine} />
                {itemsDisabled
                    ? menuItems.map((item) => (
                          <Tooltip
                              key={`${item.key}Tooltip`}
                              placement={standalone ? 'left' : 'right'}
                              tooltip={disabledHelpText}
                          >
                              {item}
                          </Tooltip>
                      ))
                    : menuItems.map((item) => item)}
            </>
        );
    };

    const items = (
        <>
            <MenuItem onClick={handlePreviewClick} qaTag="projectActionMenu-previewDocument">
                Preview Full Document
            </MenuItem>
            <MenuItem onClick={handleDownloadClick} qaTag="projectActionMenu-downloadDocument">
                Download Full Document
            </MenuItem>
            {projectDocumentUrl && (
                <MenuItem
                    href={projectDocumentUrl}
                    onClick={handleDownloadOriginalClick}
                    qaTag="projectActionMenu-downloadOriginal"
                >
                    Download Original
                </MenuItem>
            )}
            {renderSectionExportItems()}
        </>
    );

    if (standalone) {
        return (
            <CDSDropdownButton
                pullRight
                qaTag="projectCreate-export"
                title="Export"
                variant="secondary"
            >
                {items}
            </CDSDropdownButton>
        );
    }

    return (
        <NavDropdown
            className={classnames('action-toolbar-export-btn', styles.exportButtonDropdown)}
            disabled={disabled}
            id="action-toolbar-export-dropdown"
            title={
                <span>
                    <i className="fa fa-cloud-download" /> Export
                </span>
            }
        >
            {items}
        </NavDropdown>
    );
};

ExportButton.propTypes = {
    context: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    projectDocumentUrl: PropTypes.string,
    shouldSave: PropTypes.bool,
    standalone: PropTypes.bool,
};
