import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Box, Typography } from '@og-pro/ui';

import { CDSButton } from '../../../../../../components';
import { FinalReviewChecklistSectionStatus } from './Status';

export const FinalReviewChecklistExpandableSection = ({
    children,
    last,
    incomplete,
    incompleteFields,
    section,
}) => {
    const [expanded, setExpanded] = useState(false);
    const styles = require('./index.scss');

    return (
        <>
            <Box
                className={classnames(styles.section, {
                    [styles.borderBottom]: !last || (last && expanded),
                })}
                onClick={() => setExpanded(!expanded)}
            >
                <Box alignItems="center" display="flex" justifyContent="space-between">
                    <Box alignItems="center" display="flex">
                        <Box minWidth="30px" pr={1}>
                            <CDSButton
                                noPadding
                                onClick={() => setExpanded(!expanded)}
                                qaTag="finalProjectReview-expandButton"
                                variant="text"
                            >
                                <i
                                    className={classnames('fa', {
                                        'fa-chevron-down': expanded,
                                        'fa-chevron-right': !expanded,
                                    })}
                                />
                            </CDSButton>
                        </Box>
                        <Box>
                            {section.manualNumber ? (
                                <Box>
                                    <Typography variant="h4">{section.manualNumber}</Typography>
                                    <div>{section.title}</div>
                                </Box>
                            ) : (
                                <Typography variant="h4">
                                    {section.numbering} {section.title}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <FinalReviewChecklistSectionStatus
                        incomplete={incomplete}
                        incompleteFields={incompleteFields}
                    />
                </Box>
            </Box>
            {expanded ? children : null}
        </>
    );
};

FinalReviewChecklistExpandableSection.propTypes = {
    children: PropTypes.node,
    last: PropTypes.bool,
    incomplete: PropTypes.bool,
    incompleteFields: PropTypes.number,
    section: PropTypes.shape({
        title: PropTypes.string,
        numbering: PropTypes.string,
        manualNumber: PropTypes.string,
        showValidationError: PropTypes.bool,
    }).isRequired,
};
