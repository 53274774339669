import { showSnackbar } from './notification';
import { resourceManager } from '../helpers';

export const COPY_GROUP = 'requisitionGroups/COPY_GROUP';
export const COPY_GROUP_FAIL = 'requisitionGroups/COPY_GROUP_FAIL';
export const COPY_GROUP_SUCCESS = 'requisitionGroups/COPY_GROUP_SUCCESS';

export const DELETE_GROUP = 'requisitionGroups/DELETE_GROUP';
export const DELETE_GROUP_FAIL = 'requisitionGroups/DELETE_FAIL';
export const DELETE_GROUP_SUCCESS = 'requisitionGroups/DELETE_GROUP_SUCCESS';

export const LOAD_GROUP = 'requisitionGroups/LOAD_GROUP';
export const LOAD_GROUP_FAIL = 'requisitionGroups/LOAD_GROUP_FAIL';
export const LOAD_GROUP_SUCCESS = 'requisitionGroups/LOAD_GROUP_SUCCESS';

export const LOAD_GROUPS = 'requisitionGroups/LOAD_GROUPS';
export const LOAD_GROUPS_FAIL = 'requisitionGroups/LOAD_GROUPS_FAIL';
export const LOAD_GROUPS_SUCCESS = 'requisitionGroups/LOAD_GROUPS_SUCCESS';

export const RESET_GROUP_EDIT = 'requisitionGroups/RESET_GROUP_EDIT';
export const RESET_GROUPS = 'requisitionGroups/RESET_GROUPS';

export const UPDATE_GROUP = 'requisitionGroups/UPDATE_GROUP';
export const UPDATE_GROUP_FAIL = 'requisitionGroups/UPDATE_GROUP_FAIL';
export const UPDATE_GROUP_SUCCESS = 'requisitionGroups/UPDATE_GROUP_SUCCESS';

export const copyRequisitionGroup = (requisitionGroupId, opts = {}) => {
    return resourceManager({
        method: 'post',
        url: `/requisitions/review-groups/${requisitionGroupId}/copy`,
        onStart: ({ dispatch }) => dispatch({ type: COPY_GROUP }),
        onSuccess: ({ result, dispatch }) => {
            dispatch(showSnackbar('Review group copied!'));
            dispatch({ type: COPY_GROUP_SUCCESS, result });
            if (opts.onSuccess) {
                opts.onSuccess(result);
            }
            return result;
        },
        onFailure: ({ error, dispatch }) => dispatch({ type: COPY_GROUP_FAIL, error }),
    });
};

export const createRequisitionGroup = () => {
    return resourceManager({
        method: 'post',
        url: '/requisitions/review-groups',
        onSuccess: ({ result, dispatch }) => {
            dispatch(showSnackbar('Review group created!'));
            return result;
        },
    });
};

export const deleteRequisitionGroup = (requisitionGroupId, opts = {}) => {
    return resourceManager({
        method: 'del',
        url: `/requisitions/review-groups/${requisitionGroupId}`,
        onStart: ({ dispatch }) => dispatch({ type: DELETE_GROUP }),
        onSuccess: ({ result, dispatch }) => {
            dispatch(showSnackbar('Review group deleted'));
            dispatch({ type: DELETE_GROUP_SUCCESS, result });
            if (opts.onSuccess) {
                return opts.onSuccess(result);
            }
            return result;
        },
        onFailure: ({ error, dispatch }) => dispatch({ type: DELETE_GROUP_FAIL, error }),
    });
};

export const loadRequisitionGroup = (requisitionGroupId) => {
    return resourceManager({
        method: 'get',
        url: `/requisitions/review-groups/${requisitionGroupId}`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD_GROUP }),
        onSuccess: ({ result, dispatch }) => dispatch({ type: LOAD_GROUP_SUCCESS, result }),
        onFailure: ({ error, dispatch }) => dispatch({ type: LOAD_GROUP_FAIL, error }),
    });
};

export const loadRequisitionGroups = () => {
    return resourceManager({
        method: 'get',
        url: '/requisitions/review-groups',
        onStart: ({ dispatch }) => dispatch({ type: LOAD_GROUPS }),
        onSuccess: ({ result, dispatch }) => dispatch({ type: LOAD_GROUPS_SUCCESS, result }),
        onFailure: ({ error, dispatch }) => dispatch({ type: LOAD_GROUPS_FAIL, error }),
    });
};

export const resetRequisitionGroups = () => {
    return { type: RESET_GROUPS };
};

export function resetRequisitionGroupEdit() {
    return { type: RESET_GROUP_EDIT };
}

export const updateRequisitionGroup = (requisitionGroupId, data, opts = {}) => {
    return resourceManager({
        method: 'put',
        requestOptions: { data },
        url: `/requisitions/review-groups/${requisitionGroupId}`,
        onStart: ({ dispatch }) => dispatch({ type: UPDATE_GROUP }),
        onSuccess: ({ result, dispatch }) => {
            dispatch({ type: UPDATE_GROUP_SUCCESS, result });
            dispatch(showSnackbar(opts.successMessage || 'Review group saved'));
            if (opts.onSuccess) {
                return opts.onSuccess(result);
            }
            return result;
        },
        onFailure: ({ error, dispatch }) => dispatch({ type: UPDATE_GROUP_FAIL, error }),
    });
};
