import { listToDict } from '../../../utils';
import {
    projectScopeFilterTypesDict,
    projectDateFilterTypesDict,
    projectFilterTypesDict,
} from '../../../../../shared_config/projects';

const { ALL_PROJECTS, MY_COMMENTS, MY_DEPARTMENT, MY_EVALUATIONS, MY_REVIEWS, FOLLOWING } =
    projectScopeFilterTypesDict;

const { AFTER, BEFORE, BETWEEN } = projectDateFilterTypesDict;

const {
    DEPARTMENT_ID,
    FINANCIAL_ID,
    IS_PAUSED,
    RETENTION_CODE_ID,
    STATUS,
    TEMPLATE_ID,
    TITLE,
    // Dates
    CONTRACTOR_SELECTED_DATE,
    CREATED_AT,
    POSTED_AT,
    POST_SCHEDULED_AT,
    PRE_PROPOSAL_DATE,
    PROPOSAL_DEADLINE,
    QA_DEADLINE,
    QA_RESPONSE_DEADLINE,
    RELEASE_PROJECT_DATE,
    REMOVED_FROM_PUBLIC_VIEW,
} = projectFilterTypesDict;

export const dateFilterOptions = [
    {
        label: 'After',
        value: AFTER,
    },
    {
        label: 'Before',
        value: BEFORE,
    },
    {
        label: 'Between',
        value: BETWEEN,
    },
];

export const form = 'projectListFilters';

export const fieldsNames = ['filters'];

export const fieldNamesDict = listToDict(fieldsNames);

export const SCOPE = 'scope';

const intakeTypeOptions = [
    {
        label: 'Department Name',
        value: DEPARTMENT_ID,
    },
    {
        label: 'Project ID',
        value: FINANCIAL_ID,
    },
    {
        label: 'Status',
        value: STATUS,
    },
    {
        label: 'Title',
        value: TITLE,
    },
    {
        label: 'On Hold',
        value: IS_PAUSED,
    },
    {
        label: 'Created Date',
        value: CREATED_AT,
    },
];

const documentTypeOptions = [
    ...intakeTypeOptions,
    {
        label: 'Template',
        value: TEMPLATE_ID,
    },
];

const purchaseTypeOptions = [
    ...documentTypeOptions,
    {
        label: 'Retention Policy',
        value: RETENTION_CODE_ID,
    },
    // Dates (in order of project life-cycle)
    {
        label: 'Post Scheduled Date',
        value: POST_SCHEDULED_AT,
    },
    {
        label: 'Posted Date',
        value: POSTED_AT,
    },
    {
        label: 'Release Date',
        value: RELEASE_PROJECT_DATE,
    },
    {
        label: 'Pre-Proposal Date',
        value: PRE_PROPOSAL_DATE,
    },
    {
        label: 'Q&A Deadline',
        value: QA_DEADLINE,
    },
    {
        label: 'Q&A Response Deadline',
        value: QA_RESPONSE_DEADLINE,
    },
    {
        label: 'Response Deadline',
        value: PROPOSAL_DEADLINE,
    },
    {
        label: 'Vendor Selected Date',
        value: CONTRACTOR_SELECTED_DATE,
    },
    {
        label: 'Removed from public view',
        value: REMOVED_FROM_PUBLIC_VIEW,
    },
];

export const typeOptions = ({ isDocBuilder, isIntake } = {}) => {
    if (isIntake) {
        return intakeTypeOptions;
    }
    if (isDocBuilder) {
        return documentTypeOptions;
    }
    return purchaseTypeOptions;
};

export const scopeFilterButtons = (resourceName) => {
    const isProjects = resourceName === 'Projects';
    const isIntake = resourceName === 'Requests';
    return [
        {
            icon: 'pencil',
            key: 'user',
            text: `My ${resourceName}`,
        },
        ...(isProjects || isIntake
            ? [
                  {
                      icon: 'search',
                      key: 'reviews',
                      scope: MY_REVIEWS,
                      text: 'My Reviews',
                  },
              ]
            : []),
        ...(isProjects
            ? [
                  {
                      icon: 'comment-o',
                      key: 'comments',
                      scope: MY_COMMENTS,
                      text: 'My Comments',
                  },
              ]
            : []),
        ...(isProjects
            ? [
                  {
                      icon: 'star',
                      key: 'evaluations',
                      scope: MY_EVALUATIONS,
                      text: 'My Evaluations',
                  },
              ]
            : []),
        ...(isProjects || isIntake
            ? [
                  {
                      icon: 'rss',
                      key: 'following',
                      scope: FOLLOWING,
                      text: 'Following',
                  },
              ]
            : []),
        {
            icon: 'university',
            key: 'department',
            scope: MY_DEPARTMENT,
            text: `Departmental ${resourceName}`,
        },
        {
            icon: 'files-o',
            key: 'all',
            scope: ALL_PROJECTS,
            text: `All ${resourceName}`,
        },
    ];
};

export const isPausedOptions = [
    {
        label: 'Yes',
        value: true,
    },
    {
        label: 'No',
        value: false,
    },
];

export const RETENTION_CODES = 'retentionCodes';
