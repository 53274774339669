import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { Box, Typography } from '@og-pro/ui';

import { AssociatedLineItems } from './AssociatedLineItems';
import { RequisitionAttachment } from '../../Attachments/RequisitionAttachment';
import { fieldNames, getPurchaseOrderTypeOptions } from '../../../constants';
import { qaTagPageName } from '../../../../constants';
import { InputText, SearchSelect } from '../../../../../../../components';
import { limitTextLength } from '../../../../../../../Forms/normalizers';
import { FLAGS, useFlags } from '../../../../../../../lib/launchdarkly';

const { COMMENT_TO_VENDOR, PURCHASE_ORDER_TYPE } = fieldNames;

export const ForPurchaseOrder = ({
    change,
    disabled,
    fieldName,
    fields,
    index,
    showFormValidation,
    showVendorSelection,
}) => {
    // this size is arbitrary, it is just here to prevent unreasonably large inputs
    const normalizeText = limitTextLength(2000);

    const enableAttachments = useFlags(FLAGS.ENABLE_REQUISITIONS_ATTACHMENTS);

    return (
        <>
            <Typography marginBottom={3} variant="h4">
                For the Purchase Order
            </Typography>
            <Field
                characterLimit={2000}
                component={InputText}
                help="This will appear as text on the Purchase Order. Comment will be truncated to 60 characters on the PO."
                label="Comment to Vendor"
                minRows={4}
                name={`${fieldName}.${COMMENT_TO_VENDOR}`}
                normalize={normalizeText}
                qaTag={`${qaTagPageName}-commentToVendor`}
                type="textarea"
                useOpenGovStyle
            />
            <div className="row">
                <div className="col-xs-12 col-md-6">
                    <Field
                        component={SearchSelect}
                        disabled={disabled}
                        isSearchable={false}
                        label="Purchase Order Type"
                        name={`${fieldName}.${PURCHASE_ORDER_TYPE}`}
                        options={getPurchaseOrderTypeOptions(true)}
                        qaTag={`${qaTagPageName}-purchaseOrderType`}
                        showValidation={showFormValidation}
                        useOpenGovStyle
                    />
                </div>
                {showVendorSelection && (
                    <div className="col-xs-12 col-md-6">
                        <AssociatedLineItems
                            change={change}
                            disabled={disabled}
                            fieldName={fieldName}
                            fields={fields}
                            index={index}
                            showFormValidation={showFormValidation}
                        />
                    </div>
                )}
            </div>
            {enableAttachments && (
                <div className="row">
                    <Box paddingLeft={2}>
                        <RequisitionAttachment
                            disabled={disabled}
                            fieldNamePrefix={fieldName}
                            title="Attachment(s) to the Purchase Order"
                        />
                    </Box>
                </div>
            )}
        </>
    );
};

ForPurchaseOrder.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    fieldName: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    showFormValidation: PropTypes.bool,
    showVendorSelection: PropTypes.bool,
};
