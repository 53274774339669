import { isDateInOpenPeriods } from '../../../../../shared_config/requisitions';
import { REQUIRED_TEXT } from '../../../Forms/validation';
import { fieldNames } from './constants';

const { FISCAL_PERIOD_TAG_ID, EXPECTED_PURCHASE_ORDER_DATE } = fieldNames;

export const validate = (values, props) => {
    const { hasFMS, fiscalPeriods } = props;

    const errors = {};
    if (!hasFMS) {
        if (!values[FISCAL_PERIOD_TAG_ID]) {
            errors[FISCAL_PERIOD_TAG_ID] = REQUIRED_TEXT;
        }
    }

    const expectedPurchaseOrderDate = values[EXPECTED_PURCHASE_ORDER_DATE];

    if (hasFMS && !expectedPurchaseOrderDate) {
        errors[EXPECTED_PURCHASE_ORDER_DATE] = REQUIRED_TEXT;
    }

    if (hasFMS && !isDateInOpenPeriods(expectedPurchaseOrderDate, fiscalPeriods)) {
        errors[EXPECTED_PURCHASE_ORDER_DATE] =
            'Expected Purchase Order Date must be within an Open Fiscal Period';
    }

    return errors;
};
