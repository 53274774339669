import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

import { ProcuratedStarsDisplay } from '../ProcuratedStarsDisplay';
import { PROCURATED_BADGE_URL } from '../constants';
import { getProcuratedVendorJS } from '../../../containers/selectors';
import { loadProcuratedRating } from '../../../actions/ratings';
import { USE_PROCURATED } from '../../../../../shared_config/procurated';

export const ProcuratedBadge = ({ badgeInHover, supplierId, ratingFromParent }) => {
    const dispatch = useDispatch();
    const procuratedVendor = useSelector(getProcuratedVendorJS);
    const [hovering, setIsHovering] = useState(false);
    const styles = require('./index.scss');
    const hasRatingFromParent = ratingFromParent !== undefined;

    useEffect(() => {
        if (!hasRatingFromParent && USE_PROCURATED && supplierId) {
            dispatch(loadProcuratedRating({ supplierId }));
        }
    }, [supplierId]);

    if (!USE_PROCURATED || (!procuratedVendor && !hasRatingFromParent)) {
        return null;
    }

    let rating;

    if (hasRatingFromParent) {
        rating = ratingFromParent;
    } else if (procuratedVendor && supplierId === parseInt(procuratedVendor.partnerId, 10)) {
        rating = procuratedVendor.rating;
    }

    const ratingFound = !isNil(rating);

    return (
        <div
            aria-label={`${rating} star rating from Procurated.com`}
            className={styles.starsDisplayContainer}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            {!hovering && badgeInHover && <i className={`${styles.greyStar} fa fa-star`} />}
            {(!badgeInHover || hovering) && (
                <img
                    alt="Procurated Badge"
                    className={`img-rounded ${styles.logo}`}
                    src={PROCURATED_BADGE_URL}
                />
            )}
            {hovering && <ProcuratedStarsDisplay starSizeMultiplier={3} value={rating} />}
            <span className={styles.badgeText}>{ratingFound ? rating : 'No Reviews'}</span>
        </div>
    );
};

ProcuratedBadge.propTypes = {
    badgeInHover: PropTypes.bool,
    supplierId: PropTypes.number,
    ratingFromParent: PropTypes.number,
};
