import { listToDict } from '../../../../../shared_config/helpers';

export const fields = [
    'background',
    'hideContact',
    'hideContactAddress',
    'hideProcurementContact',
    'hideTimeline',
    'summary',
];
export const fieldNames = listToDict(fields);
