import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DropdownButton, OverlayTrigger, Panel, Popover } from 'react-bootstrap';
import { renderToStaticMarkup } from 'react-dom/server';

import { retrieveVendors } from './helpers';
import { StatusColumnRenderer } from './StatusColumnRenderer';
import {
    AgGridReact,
    Button,
    ButtonGroup,
    MenuItem,
    ProcuratedVendorCell,
    VendorListCategorySearchForm,
} from '..';
import { notificationLevels } from '../../../../shared_config/governmentSubscriptions';
import { languageDisplayNameMap, languages } from '../../../../shared_config/vendorLanguages';
import {
    certificationsStringBuilder,
    sortedCertificationsList,
} from '../../../../shared_config/certifications';
import {
    vendorEmergencyDesignationsColumnMapping,
    vendorEmergencyDesignationsStringBuilder,
} from '../../../../shared_config/vendors';
import { useSetAriaLabel } from '../../hooks';

// We only want local overrides which is why this is done here instead of via bsStyle/className
// TODO: Would love to get custom-styles working: https://react-bootstrap.github.io/utilities/custom-styles/
const PANEL_STYLES = {
    border: 'none',
    boxShadow: 'none',
};

const PANEL_BODY_STYLES = {
    padding: 0,
};

const PANEL_HEADING_STYLES = {
    backgroundColor: 'transparent',
    border: 'none',
    paddingLeft: 0,
    paddingRight: 0,
};

const VendorNameCell = (params) => <ProcuratedVendorCell params={params} />;

const DummyHeader = () => {
    return <label aria-label="Select" />;
};

export const VendorDataGrid = ({
    hideStatusColumn,
    includeExpandedData,
    addVendorsToList,
    blockVendors,
    emailVendors,
    emailVendorsBySearchParams,
    inviteVendorsToProject,
    inviteVendorsToProjectBySearchParams,
    loadProcuratedRatingsClient,
    searchActive,
    searchParams,
    addVendorsToListBySearch,
    activeSearchParams,
    vendorSearchDownloading,
    vendorSearch,
    governmentId,
    downloadVendorSearch,
    showVendorProfileModal,
}) => {
    const styles = require('./index.scss');
    useSetAriaLabel('columns-to-show', '.ag-virtual-list-viewport');
    const [categorySearchOpen, setCategorySearchOpen] = useState(false);
    const [api, setApi] = useState(null);
    const [selectedUserIds, setSelectedUserIds] = useState([]);

    const defaultColDef = {
        cellClass: ['wrapText'], // Used exclusively for Excel export styles
    };

    const clearSelection = () => {
        if (api) {
            api.deselectAll();
            setSelectedUserIds([]);
        }
    };

    /**
     * Given a row of data from the grid, get a unique ID for it.
     *
     * @param {GridVendorUser} data The raw data in this row from `vendors`
     * @return {number} The unique id of the row (the vendor user ID)
     */
    const getRowNodeId = (data) => {
        if (data.isPendingUser && data.isPendingUser !== 'false') {
            return `pendingUser${data.id}`;
        }
        return data.userId;
    };

    /**
     * Callback for saving a reference to the underlying AgReactGrid's API once it is ready. We need
     * access to the API to do things such as export data to a CSV.
     *
     * @param {object} params The `onGridReady` callback params
     * @param {object} params.api The underlying AgReactGrid's API
     */
    const handleGridReady = (params) => {
        const searchApi = retrieveVendors(
            vendorSearch,
            governmentId,
            searchParams,
            clearSelection,
            loadProcuratedRatingsClient
        );
        params.api.setServerSideDatasource(searchApi);
        setApi(params.api);
    };

    const handleCategorySubmit = (formData) => {
        const categoryData = formData.categories || [];
        if (!categoryData.length) {
            return;
        }
        const searchApi = retrieveVendors(
            vendorSearch,
            governmentId,
            {
                ...searchParams,
                categories: categoryData.length ? categoryData.map((cat) => cat.code) : undefined,
            },
            clearSelection,
            loadProcuratedRatingsClient
        );
        api.setServerSideDatasource(searchApi);
    };

    const clearCategorySearch = () => {
        const searchApi = retrieveVendors(
            vendorSearch,
            governmentId,
            { ...searchParams },
            clearSelection,
            loadProcuratedRatingsClient
        );
        api.setServerSideDatasource(searchApi);
        setCategorySearchOpen(false);
    };

    const handleSelectionChanged = () => {
        const newSelectedUserIds = api.getSelectedRows().map((row) => row.userId);
        setSelectedUserIds(newSelectedUserIds);
    };

    const showVendorProfileModalWrapper = (params) => {
        // Pending users may not have a vendor account
        const vendorId = get(params, 'data.vendorId');
        if (vendorId) {
            showVendorProfileModal(vendorId);
        }
    };

    const handleExportToCSVClick = () => {
        downloadVendorSearch(governmentId);
    };

    const toggleCategorySearch = () => {
        setCategorySearchOpen(!categorySearchOpen);
    };

    const isRowSelectable = (rowNode) => {
        return rowNode.data && !rowNode.data.isPendingUser;
    };

    const clearSelectionButton = () => {
        if (!selectedUserIds.length) {
            return;
        }
        return (
            <Button
                onClick={clearSelection}
                qaTag="vendorDataGrid-clearSelection"
                style={{ marginRight: '10px' }}
            >
                Clear Selection
            </Button>
        );
    };

    const blockVendorsWrapper = (userIds, isBlocked) => {
        return blockVendors(userIds, isBlocked).then((resultOrError) => {
            if (!(resultOrError instanceof Error)) {
                clearCategorySearch();
            }
        });
    };

    const selectedVendorsButton = () => {
        if (
            (selectedUserIds.length === 0 ||
                (!addVendorsToList && !inviteVendorsToProject && !blockVendors)) &&
            !searchActive
        ) {
            return;
        }

        if (
            searchActive &&
            selectedUserIds.length === 0 &&
            (!!addVendorsToListBySearch || !!inviteVendorsToProjectBySearchParams)
        ) {
            return (
                <DropdownButton
                    bsSize="sm"
                    id="selected-vendors-button"
                    style={{ marginRight: '10px' }}
                    title="Take Action on Active Search"
                >
                    {addVendorsToListBySearch && (
                        <MenuItem
                            onClick={() => addVendorsToListBySearch(activeSearchParams)}
                            qaTag="vendorData-addUsers"
                        >
                            <i className="fa fa-fw fa-plus" /> Add Users from Search to a Vendor
                            List
                        </MenuItem>
                    )}
                    {inviteVendorsToProjectBySearchParams && (
                        <MenuItem
                            onClick={() => inviteVendorsToProjectBySearchParams(activeSearchParams)}
                            qaTag="vendorData-inviteVendors"
                        >
                            <i className="fa fa-fw fa-send" /> Invite Vendors from Search to a
                            Project
                        </MenuItem>
                    )}
                    {emailVendorsBySearchParams && (
                        <MenuItem
                            onClick={() => emailVendorsBySearchParams(activeSearchParams)}
                            qaTag="vendorData-sendVendorsEmail"
                        >
                            <i className="fa fa-fw fa-envelope" /> Send Vendors from Search an Email
                        </MenuItem>
                    )}
                </DropdownButton>
            );
        }

        return (
            <DropdownButton
                bsSize="sm"
                id="selected-vendors-button"
                style={{ marginRight: '10px' }}
                title={`Take Action on ${selectedUserIds.length} Vendors`}
            >
                {addVendorsToList && (
                    <MenuItem
                        onClick={() => addVendorsToList(selectedUserIds)}
                        qaTag="vendorData-addVendorsToList"
                    >
                        <i className="fa fa-fw fa-plus" />{' '}
                        {`Add ${selectedUserIds.length} Vendors to a List`}
                    </MenuItem>
                )}
                {inviteVendorsToProject && (
                    <MenuItem
                        onClick={() => inviteVendorsToProject(selectedUserIds)}
                        qaTag="vendorData-addVendorsToProject"
                    >
                        <i className="fa fa-fw fa-send" />{' '}
                        {`Invite ${selectedUserIds.length} Vendors to Project`}
                    </MenuItem>
                )}
                {emailVendors && (
                    <MenuItem
                        onClick={() => emailVendors(selectedUserIds)}
                        qaTag="vendorData-emailVendors"
                    >
                        <i className="fa fa-fw fa-envelope" />{' '}
                        {`Email ${selectedUserIds.length} Vendors`}
                    </MenuItem>
                )}
                {blockVendors && (
                    <MenuItem
                        onClick={() => blockVendorsWrapper(selectedUserIds, true)}
                        qaTag="vendorData-blockVendors"
                    >
                        <i className="fa fa-fw fa-ban" />{' '}
                        {`Block ${selectedUserIds.length} Vendors`}
                    </MenuItem>
                )}
                {blockVendors && (
                    <MenuItem
                        onClick={() => blockVendorsWrapper(selectedUserIds, false)}
                        qaTag="vendorData-unblockVendors"
                    >
                        <i className="fa fa-fw fa-check" />{' '}
                        {`Unblock ${selectedUserIds.length} Vendors`}
                    </MenuItem>
                )}
            </DropdownButton>
        );
    };

    const columnDefs = [
        {
            checkboxSelection: true,
            pinned: 'left',
            headerName: 'Select',
            headerTooltip: 'Select all',
            headerComponentFramework: DummyHeader,
            suppressMenu: true,
            width: 50,
        },
        {
            cellClass: styles.vendorNameCell,
            cellRendererFramework: VendorNameCell,
            field: 'name',
            headerName: 'Vendor & Rating (Powered by Procurated)',
            headerTooltip: 'Vendor & Rating (Powered by Procurated)',
            onCellClicked: showVendorProfileModalWrapper,
            width: 400,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                defaultOption: 'contains',
                suppressAndOrCondition: true,
                filterOptions: ['contains'],
            },
        },
        {
            field: 'fullName',
            headerName: 'Subscriber',
            headerTooltip: 'Subscriber',
            width: 150,
            sortable: false,
            filter: 'agTextColumnFilter',
            filterParams: {
                defaultOption: 'contains',
                suppressAndOrCondition: true,
                filterOptions: ['contains'],
            },
        },
        {
            field: 'email',
            headerName: 'Email',
            headerTooltip: 'Email',
            width: 150,
            sortable: false,
            filter: 'agTextColumnFilter',
            filterParams: {
                defaultOption: 'contains',
                suppressAndOrCondition: true,
                filterOptions: ['contains'],
            },
        },
        {
            valueGetter: (params) => {
                if (params?.data === undefined) return;
                const { isBlocked, isPendingUser } = params.data;

                if (isBlocked) {
                    return 'Blocked';
                }
                if (isPendingUser) {
                    return 'Invited';
                }
                return 'Activated';
            },
            cellRenderer: (params) => {
                const isBlocked = get(params.data, 'isBlocked');
                const isPendingUser = get(params.data, 'isPendingUser');
                const notificationLevel = get(params.data, 'notificationLevel');

                if (process.env.SERVER || isBlocked || isPendingUser) {
                    return params.value;
                }

                switch (notificationLevel) {
                    case notificationLevels.NONE:
                    case notificationLevels.CATEGORIES: {
                        const ReactEl = React.createElement(StatusColumnRenderer, {
                            notificationLevel,
                            params,
                        });
                        const htmlMarkup = renderToStaticMarkup(ReactEl);

                        const element = document.createElement('div');
                        element.innerHTML = htmlMarkup;

                        return element;
                    }
                    default:
                        return params.value;
                }
            },
            headerName: 'Status',
            headerTooltip: renderToStaticMarkup(
                <div>
                    Vendor registration status
                    <br />
                    <br />
                    Notification legend:
                    <br />
                    <i className="fa fa-fw fa-filter" /> Opted to only receive automated new project
                    emails that match their category codes.
                    <br />
                    <i className="fa fa-fw fa-bell-slash" /> Opted out of all automated new project
                    emails. Only notified when invited directly.
                </div>
            ),
            width: 100,
            sortable: false,
            hide: hideStatusColumn,
        },
        {
            field: 'address1',
            headerName: 'Address',
            headerTooltip: 'Address',
            width: 120,
            sortable: false,
        },
        {
            field: 'city',
            headerName: 'City',
            headerTooltip: 'City',
            width: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                defaultOption: 'contains',
                suppressAndOrCondition: true,
                filterOptions: ['contains'],
            },
        },
        {
            field: 'state',
            headerName: 'State',
            headerTooltip: 'State',
            width: 90,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                defaultOption: 'contains',
                suppressAndOrCondition: true,
                filterOptions: ['contains'],
            },
        },
        {
            field: 'zipCode',
            headerName: 'Zip',
            headerTooltip: 'Zip',
            width: 75,
            sortable: false,
            filter: 'agTextColumnFilter',
            filterParams: {
                defaultOption: 'contains',
                suppressAndOrCondition: true,
                filterOptions: ['contains'],
            },
        },
        {
            field: 'phoneComplete',
            headerName: 'Phone',
            headerTooltip: 'Phone',
            width: 120,
            sortable: false,
        },
        {
            field: 'website',
            headerName: 'Website',
            headerTooltip: 'Website',
            width: 150,
            sortable: false,
        },
        {
            field: 'languages',
            filter: 'agSetColumnFilter',
            filterParams: {
                values: languages,
                valueFormatter: ({ value }) => {
                    return languageDisplayNameMap[value];
                },
                suppressMiniFilter: true,
                suppressSorting: true,
            },
            headerName: 'Languages',
            headerTooltip: 'Languages',
            width: 150,
            sortable: false,
        },
    ];

    if (includeExpandedData) {
        columnDefs.push(
            {
                field: 'subscribedAt',
                headerName: 'Date Added',
                headerTooltip: 'Date Added',
                valueFormatter: (params) => {
                    if (params && params.value) {
                        return moment(params.value).format('l');
                    }
                },
                valueGetter: (params) => {
                    if (params && params.data) {
                        return params.data.subscribedAt && new Date(params.data.subscribedAt);
                    }
                },
                width: 150,
                sortable: false,
            },
            {
                field: 'stateOfIncorporation',
                headerName: 'State of Incorporation',
                headerTooltip: 'State of Incorporation',
                width: 100,
                sortable: false,
            },
            {
                field: 'businessType',
                headerName: 'Business Type',
                headerTooltip: 'Business Type',
                width: 150,
                sortable: false,
            },
            {
                field: 'ein',
                headerName: 'EIN',
                headerTooltip: 'Employer Identification Number',
                width: 100,
                sortable: false,
            },
            {
                field: 'uniqueEntityId',
                headerName: 'UEI',
                headerTooltip: 'Unique Entity Identifier',
                width: 100,
                sortable: false,
            },
            {
                field: 'duns',
                headerName: 'DUNS',
                headerTooltip: 'Dun & Bradstreet Number',
                width: 100,
                sortable: false,
            },
            {
                field: 'selfReportedCertifications',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: sortedCertificationsList.map((cert) => cert.title),
                    suppressMiniFilter: true,
                    suppressSorting: true,
                },
                headerName: 'Self-Reported Certifications',
                headerTooltip: 'Self-Reported Certifications',
                width: 450,
                sortable: false,
                valueGetter: (params) => {
                    if (params && params.data) {
                        return certificationsStringBuilder(params.data);
                    }
                },
                cellRendererFramework: (params) => {
                    if (params && params.value) {
                        const certifications = params.value.split(', ');
                        if (certifications.length <= 2) {
                            return params.value;
                        }
                        const visibleCerts = certifications.slice(0, 2).join(', ').concat(',');
                        const hiddenCerts = certifications.slice(2);
                        return (
                            <div>
                                {visibleCerts}
                                <OverlayTrigger
                                    overlay={<Popover>{hiddenCerts.join(', ')}</Popover>}
                                    placement="top"
                                    rootClose
                                    trigger="click"
                                >
                                    <Button
                                        bsStyle="link"
                                        qaTag={`vendorDataGrid-showMoreCertifications${params.rowIndex}`}
                                        style={{ padding: '0 0 5px 3px' }}
                                    >
                                        +{hiddenCerts.length} more
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        );
                    }
                    return null;
                },
            },
            {
                field: 'emergencyClassifications',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: Object.keys(vendorEmergencyDesignationsColumnMapping),
                    suppressMiniFilter: true,
                    suppressSorting: true,
                },
                headerName: 'Emergency Classifications',
                headerTooltip: 'Emergency Classifications',
                width: 450,
                sortable: false,
                valueGetter: (params) => {
                    if (params && params.data) {
                        return vendorEmergencyDesignationsStringBuilder(params.data);
                    }
                },
            }
        );
    }

    return (
        <Panel defaultExpanded style={PANEL_STYLES}>
            <Panel.Heading style={PANEL_HEADING_STYLES}>
                <Panel.Title className={styles.panelTitle}>
                    <div className="pull-left">Vendors</div>
                    <div className={`no-print ${styles.exportButtons}`}>
                        <ButtonGroup bsSize="sm">
                            {clearSelectionButton()}
                            {selectedVendorsButton()}
                            {!categorySearchOpen && (
                                <Button
                                    onClick={toggleCategorySearch}
                                    qaTag="vendorDataGrid-searchByCategory"
                                    style={{ marginRight: '10px' }}
                                >
                                    <i className="fa fa-search" /> Search by Category
                                </Button>
                            )}
                            {!selectedUserIds.length && (
                                <Button
                                    onClick={handleExportToCSVClick}
                                    qaTag="vendorDataGrid-exportToCsv"
                                >
                                    <i className="fa fa-download" />{' '}
                                    {vendorSearchDownloading ? 'Downloading' : 'Export to CSV'}
                                </Button>
                            )}
                        </ButtonGroup>
                    </div>
                    <div className="clearfix" />
                </Panel.Title>
            </Panel.Heading>
            <Panel.Body style={PANEL_BODY_STYLES}>
                {categorySearchOpen && (
                    <VendorListCategorySearchForm
                        active={categorySearchOpen}
                        clearSearch={clearCategorySearch}
                        onSubmit={handleCategorySubmit}
                    />
                )}
                <AgGridReact
                    autoHeightMaxRows={20}
                    cacheBlockSize={200}
                    columns={columnDefs}
                    defaultColDef={defaultColDef}
                    getRowNodeId={getRowNodeId}
                    isRowSelectable={isRowSelectable}
                    onGridReady={handleGridReady}
                    onSelectionChanged={handleSelectionChanged}
                    pagination
                    paginationPageSize={15}
                    rowHeight={40}
                    rowModelType="serverSide"
                    showSideBarFilters
                    suppressContextMenu
                />
            </Panel.Body>
        </Panel>
    );
};

VendorDataGrid.propTypes = {
    addVendorsToList: PropTypes.func,
    blockVendors: PropTypes.func,
    downloadVendorSearch: PropTypes.func,
    emailVendors: PropTypes.func,
    emailVendorsBySearchParams: PropTypes.func,
    governmentId: PropTypes.number,
    hideStatusColumn: PropTypes.bool,
    includeExpandedData: PropTypes.bool,
    inviteVendorsToProject: PropTypes.func,
    inviteVendorsToProjectBySearchParams: PropTypes.func,
    loadProcuratedRatingsClient: PropTypes.func.isRequired,
    // searchParams are default search we want to display, like designations/emergency
    searchParams: PropTypes.shape({
        isEmergencySearchDesignation: PropTypes.bool,
        designations: PropTypes.arrayOf(PropTypes.number),
    }),
    showVendorProfileModal: PropTypes.func.isRequired,
    vendorSearch: PropTypes.func.isRequired,
    vendorSearchDownloading: PropTypes.bool,
    addVendorsToListBySearch: PropTypes.func,
    searchActive: PropTypes.bool,
    // activeSearchParams is the search the user is currently performing
    activeSearchParams: PropTypes.any,
};
