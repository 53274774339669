import { createSelector } from 'reselect';

import { questionStatusTypes } from '../../../../../../../shared_config/questions';

const { DRAFT } = questionStatusTypes;

const getInitiallySelectedQuestionIds = (state) =>
    state.questions.get('initialQuestionIdsToRelease');

export const getInitiallySelectedQuestionIdsJS = createSelector(
    [getInitiallySelectedQuestionIds],
    (rawQuestionIds) => {
        if (rawQuestionIds) {
            return rawQuestionIds.toJS();
        }
        return [];
    }
);

export const getQuestionsAwaitingReview = (props) =>
    props.questions.filter((question) => {
        return question.status === DRAFT;
    });
