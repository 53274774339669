import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadVendorData } from '../../../../../../../actions/requisitionsApprovals';
import { getRequisitionJS } from '../../../../../../../selectors/govApp';
import { buildMap } from '../../../../../../../../../shared_config/helpers';

export const useGetRequisitionVendorData = ({ vendors }) => {
    const requisition = useSelector(getRequisitionJS);
    const [vendorData, setVendorData] = useState({ loading: false, error: null });

    const dispatch = useDispatch();

    const vendorIds = vendors.map((vendor) => vendor.pro_vendor_id).filter(Boolean);
    const contractIds = vendors.map((vendor) => vendor.contract_id).filter(Boolean);

    useEffect(() => {
        const fetchData = async () => {
            setVendorData({ loading: true, error: null });
            try {
                const result = await dispatch(loadVendorData(requisition.id));
                setVendorData({
                    contractIds: buildMap(result.contracts, 'id'),
                    loading: false,
                    vendorIds: buildMap(result.vendors, 'id'),
                });
            } catch (dataError) {
                setVendorData({ loading: false, error: dataError });
            }
        };

        if (vendorIds.length > 0 || contractIds.length > 0) {
            fetchData();
        }
    }, [vendorIds.join(), contractIds.join()]); // eslint-disable-line react-hooks/exhaustive-deps

    return vendorData;
};
