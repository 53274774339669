import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RequisitionsApprovalBody } from './RequisitionsApprovalBody';
import { RequisitionsApprovalHeader } from './RequisitionsApprovalHeader';
import { RequisitionsApprovalHistory } from './RequisitionsApprovalHistory';
import { getIsBackgroundRefreshing, getIsLoadingSteps, getLoadStepsError } from './selectors';
import { useRequisitionBudgetCheckOnLoad, useRequisitionSocket } from '../hooks';
import connectData from '../../ConnectData';
import {
    loadRequisition,
    loadReviewSequenceSteps,
    resetRequisition,
} from '../../../actions/requisitions';
import {
    loadRequisitionLogs,
    loadRequisitionRelations,
} from '../../../actions/requisitionsApprovals';
import { LoadingError, LoadingSpinner, Main, PageTitle } from '../../../components';
import {
    getRequisitionIsCopying,
    getRequisitionIsLoading,
    getRequisitionIsUpdating,
    getRequisitionJS,
    getRequisitionLoadError,
    getReviewSequenceSteps,
} from '../../../selectors/govApp';

const fetchData = (getState, dispatch, location, params) => {
    const requisitionId = Number.parseInt(params.requisitionId, 10);
    return Promise.all([
        dispatch(loadRequisition(requisitionId)),
        dispatch(loadReviewSequenceSteps(requisitionId)),
        dispatch(loadRequisitionLogs(requisitionId)),
        dispatch(loadRequisitionRelations(requisitionId)),
    ]);
};

const ConnectedRequisitionsApproval = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const requisitionId = Number.parseInt(params.requisitionId, 10);

    const requisition = useSelector(getRequisitionJS);
    const reviewSequenceSteps = useSelector(getReviewSequenceSteps);

    useRequisitionSocket(requisitionId);
    useRequisitionBudgetCheckOnLoad(requisition);

    useEffect(() => {
        // this runs when component unmounts
        return () => {
            dispatch(resetRequisition());
        };
    }, []);

    const isLoading = useSelector(getRequisitionIsLoading);
    const loadError = useSelector(getRequisitionLoadError);

    const isLoadingSteps = useSelector(getIsLoadingSteps);
    const loadStepsError = useSelector(getLoadStepsError);

    const isUpdating = useSelector(getRequisitionIsUpdating);
    const isCopying = useSelector(getRequisitionIsCopying);

    const isBackgroundRefreshing = useSelector(getIsBackgroundRefreshing);

    if (isLoading || isLoadingSteps) {
        return <LoadingSpinner />;
    }

    if (loadError || loadStepsError || !requisition) {
        return <LoadingError error={loadError} />;
    }

    const isDisabled = isUpdating || isCopying || isBackgroundRefreshing;

    const styles = require('./index.scss');
    return (
        <>
            <PageTitle title="Requisition Approval" />
            <div className={styles.page}>
                <RequisitionsApprovalHeader disabled={isDisabled} requisition={requisition} />
                <Main>
                    <div className={classNames('row', styles.pageContainer)}>
                        <div className={classNames('col-md-9', styles.bodyContainer)}>
                            <RequisitionsApprovalBody
                                disabled={isDisabled}
                                requisition={requisition}
                                reviewSequenceSteps={reviewSequenceSteps}
                            />
                        </div>
                        <div className={classNames('col-md-3', styles.historySidebarContainer)}>
                            <RequisitionsApprovalHistory
                                disabled={isDisabled}
                                requisition={requisition}
                                reviewSequenceSteps={reviewSequenceSteps}
                            />
                        </div>
                    </div>
                </Main>
            </div>
        </>
    );
};

export const RequisitionsApproval = connectData(fetchData)(ConnectedRequisitionsApproval);
