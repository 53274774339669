import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { destroy } from 'redux-form';
import { Outlet } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { getProjectUrl } from './selectors';
import { form as proposalCreateForm } from '../ProposalCreate';
import { getProposalJS, isProposalEditable } from '../selectors';
import { getIsAuctionLive } from '../ReverseAuction/ReverseAuctionLineItemDetails/selectors';
import connectData from '../../ConnectData';
import { getPublicProjectJS, isSubscribedToProject } from '../../selectors';
import { loadProposal, shouldLoadProposal } from '../../../actions/vendProposals';
import {
    AddendumsConfirmAlert,
    LoadingSpinner,
    LoadingError,
    StatusLabel,
} from '../../../components';
import { ProjectNavbar } from '../../../components/PublicApp';
import { proposalStatusesDict } from '../../../../../shared_config/proposals';

const { PUBLISHED } = proposalStatusesDict;

function fetchData(getState, dispatch, location, params) {
    const proposalId = Number.parseInt(params.proposalId, 10);
    if (shouldLoadProposal(getState(), proposalId)) {
        return dispatch(loadProposal(proposalId));
    }
}

const mapStateToProps = (state) => {
    return {
        isAuctionLive: getIsAuctionLive(state),
        isEditable: isProposalEditable(state),
        isSubscribed: isSubscribedToProject(state),
        loadError: state.vendProposals.get('loadError'),
        loading: state.vendProposals.get('loading'),
        project: getPublicProjectJS(state),
        proposal: getProposalJS(state),
        publicUrl: getProjectUrl(state),
    };
};

const mapDispatchToProps = {
    destroyForm: destroy,
};

// @connectData
// @connect
class ConnectedProposalNav extends Component {
    static propTypes = {
        destroyForm: PropTypes.func.isRequired,
        isAuctionLive: PropTypes.bool,
        isEditable: PropTypes.bool.isRequired,
        isSubscribed: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        loadError: PropTypes.string,
        params: PropTypes.object.isRequired,
        project: PropTypes.object,
        proposal: PropTypes.object,
        publicUrl: PropTypes.string,
    };

    componentWillUnmount() {
        this.props.destroyForm(proposalCreateForm);
    }

    render() {
        const {
            isAuctionLive,
            isEditable,
            isSubscribed,
            loading,
            loadError,
            params,
            project,
            proposal,
            publicUrl,
        } = this.props;

        if (loading) {
            return <LoadingSpinner centered />;
        }
        if (loadError || !proposal) {
            return <LoadingError error={loadError} />;
        }

        const { vendorId, proposalId } = params;

        const urlPathname = `/vendors/${vendorId}/proposals/${proposalId}`;
        const proposalItem = isEditable
            ? {
                  title: 'Response',
                  link: `${urlPathname}/edit`,
              }
            : {
                  title: 'Response Details',
                  link: urlPathname,
                  indexRoute: true,
              };

        const reverseAuctionItem = {
            title: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    Reverse Auction
                    {isAuctionLive && (
                        <StatusLabel
                            bsStyle="bg-royal"
                            style={{ marginLeft: '8px', padding: '4px 6px' }}
                        >
                            LIVE
                        </StatusLabel>
                    )}
                </div>
            ),
            link: `${urlPathname}/projects/${project.id}/reverse-auction`,
        };
        const showReverseAuctionTab =
            project.template.isReverseAuction &&
            proposal.status === PUBLISHED &&
            !proposal.isDisqualified &&
            !proposal.isExcluded;

        const navItems = [
            proposalItem,
            ...(showReverseAuctionTab ? [reverseAuctionItem] : []),
            {
                title: 'Project Information',
                link: `${urlPathname}/projects/${project.id}`,
            },
        ];

        const timezone = project.government.organization.timezone;

        return (
            <ProjectNavbar
                navItems={navItems}
                project={project}
                publicUrl={publicUrl}
                showTimer={proposal.status !== PUBLISHED}
                subscribed={isSubscribed}
                timezone={timezone}
            >
                <AddendumsConfirmAlert />
                <Outlet />
            </ProjectNavbar>
        );
    }
}

export const ProposalNav = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProposalNav);
