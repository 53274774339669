import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'redux-form';
import { Draggable } from 'react-beautiful-dnd';

import { Toggle } from '../../../../../../../components';
import { sectionTypes, sectionTypeNames } from '../../../../../../../../../shared_config/sections';
import { DrawerContentSection } from '../../../../../../../components/SDv2';
import { ProjectCreateManageSectionContext } from '../../../context';
import { ProjectCreateDrawerContentEditSectionBlock } from './EditSectionBlock';

const { DIVIDER } = sectionTypeNames;

const SortableItem = ({ children, allowSorting, editing, index, id, isChild }) => {
    if (editing) {
        const styles = require('../index.scss');

        return (
            <div
                className={classnames(styles.addSectionBlock, {
                    [styles.addSectionBlockSpacing]: true,
                    [styles.childSection]: isChild,
                })}
            >
                <ProjectCreateDrawerContentEditSectionBlock />
            </div>
        );
    }

    if (!allowSorting) {
        return children;
    }

    return (
        <Draggable draggableId={`projectCreateSectionItem:${id}`} index={index} key={id}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {children}
                </div>
            )}
        </Draggable>
    );
};

SortableItem.propTypes = {
    allowSorting: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    editing: PropTypes.bool.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    index: PropTypes.number.isRequired,
    isChild: PropTypes.bool,
};

export const ProjectCreateDrawerContentSection = ({
    section,
    allowSorting,
    isChild,
    useSectionDividers,
}) => {
    const { data: manageSectionData, setData: setManageSectionData } = useContext(
        ProjectCreateManageSectionContext
    );
    const [expanded, setExpanded] = useState(true);

    const isDivider = section.section_type === DIVIDER;
    const isFirstDivider = useSectionDividers && section.index === 0 && isDivider;
    const elementAllowSorting = allowSorting && !isFirstDivider;

    const isEditingSection = manageSectionData.active && manageSectionData.index === section.index;

    const onSectionClick = (e) => {
        e.stopPropagation();

        setManageSectionData({
            active: true,
            index: section.index,
        });
    };

    return (
        <>
            <SortableItem
                allowSorting={elementAllowSorting}
                editing={isEditingSection}
                id={section.id}
                index={section.index}
                isChild={isChild}
            >
                <DrawerContentSection
                    actionsComponent={
                        <div onClick={(e) => e.stopPropagation()}>
                            <Field
                                aria-label="Display Section"
                                component={Toggle}
                                disabled={isFirstDivider || section.isHiddenByLogic}
                                leftLabel="Hide"
                                name={`projectSections.${section.index}.isDisplayed`}
                                qaTag="projectSectionsEditItem-displaySection"
                                rightLabel="Show"
                                useOpenGovStyle
                            />
                        </div>
                    }
                    allowDragAndDrop={allowSorting}
                    expanded={expanded}
                    isChild={isChild}
                    onClick={onSectionClick}
                    onExpandSectionClick={setExpanded}
                    section={section}
                    useSectionDividers={useSectionDividers}
                />
            </SortableItem>

            {!!section.children?.length && expanded && (
                <>
                    {section.children.map((child) => {
                        return (
                            <ProjectCreateDrawerContentSection
                                allowSorting={allowSorting}
                                isChild
                                key={child.index}
                                section={child}
                            />
                        );
                    })}
                </>
            )}
        </>
    );
};

ProjectCreateDrawerContentSection.propTypes = {
    allowSorting: PropTypes.bool,
    isChild: PropTypes.bool,
    section: PropTypes.shape({
        children: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                index: PropTypes.number,
                title: PropTypes.string,
                section_type: PropTypes.oneOf(sectionTypes),
            })
        ),
        id: PropTypes.number,
        index: PropTypes.number,
        isHiddenByLogic: PropTypes.bool,
        isRemovable: PropTypes.bool,
        numbering: PropTypes.string,
        manualNumber: PropTypes.string,
        title: PropTypes.string,
        section_type: PropTypes.oneOf(sectionTypes),
    }),
    useSectionDividers: PropTypes.bool,
};
