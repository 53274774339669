import { isEmail } from 'validator';

import { fieldNames } from './constants';

const { EMAILS, USER_EMAILS, VENDOR_LIST_ID } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (
        !values[VENDOR_LIST_ID] &&
        !values[EMAILS] &&
        (!values[USER_EMAILS] || values[USER_EMAILS].length === 0)
    ) {
        errors[USER_EMAILS] = 'Please select at least one vendor to invite or email';
        errors[EMAILS] = 'Please select at least one vendor to invite or email';
        errors[VENDOR_LIST_ID] = 'Please select at least one vendor to invite or email';
    }

    if (values[EMAILS]) {
        const invalidEmail = values[EMAILS].split(',').find((email) => !isEmail(email.trim()));
        if (invalidEmail) {
            errors[EMAILS] = `Invalid email address provided: "${invalidEmail}"`;
        }
    }

    return errors;
};
