import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FieldArray } from 'redux-form';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { v4 as UUIDv4 } from 'uuid';
import { Box, Typography } from '@og-pro/ui';

import { PricingTables } from './PricingTables';
import { Legend } from './PriceTableBuilders/components';
import { defaultPriceTableValues, LUMP_SUM } from './constants';
import { parsePricingTableFile } from './parseFile';
import { getPriceTables, getSuggestedPricingSOW } from './selectors';
import { getProjectJS } from '../../selectors';
import { GuidedSectionButton } from '../components';
import {
    fieldNames,
    priceTableFieldNames,
    priceTableItemFieldNames,
    projectFormProps,
} from '../constants';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import {
    Button,
    CDSButton,
    CDSButtonGroup,
    FormError,
    HelpIcon,
    StyledDropzone,
    SuggestedContentModal,
    Main,
} from '../../../../components';
import { ListError } from '../../../../components/GovApp';
import { getMaxNumberFromList, stripHtml } from '../../../../utils';
import { sectionTypeNames } from '../../../../../../shared_config/sections';
import { subsectionTypeNames } from '../../../../../../shared_config/subsections';
import { getDndStyle } from '../../../../constants/styles';
import { showSuggestedContentModal } from '../../../../actions/app';

const { PRICE_TABLES } = fieldNames;

const {
    HAS_SALES_TAX_ROW,
    HAS_TOTAL_ROW,
    ORDER_BY_ID: PRICE_TABLE_ORDER_BY_ID,
    PRICE_ITEMS,
} = priceTableFieldNames;

const { LINE_ITEM, ORDER_BY_ID: PRICE_ITEM_ORDER_BY_ID } = priceTableItemFieldNames;

const { PRICING } = sectionTypeNames;
const { BODY } = subsectionTypeNames;

const mapStateToProps = (state) => {
    return {
        hasPriceTables: getPriceTables(state).length > 0,
        priceTableValues: getPriceTables(state),
        project: getProjectJS(state),
        suggestedPricingSOW: getSuggestedPricingSOW(state),
    };
};

const mapDispatchToProps = {
    showConfirmationModal: showConfirmationSimpleModal,
    showSuggestedContentModal,
};

// @connect
class ConnectedPricing extends Component {
    static propTypes = {
        ...projectFormProps,
        array: PropTypes.shape({
            move: PropTypes.func.isRequired,
            push: PropTypes.func.isRequired,
            shift: PropTypes.func.isRequired,
        }).isRequired,
        hasPriceTables: PropTypes.bool.isRequired,
        isOGThemeEnabledForComponents: PropTypes.bool,
        change: PropTypes.func.isRequired,
        priceTableValues: PropTypes.array.isRequired,
        project: PropTypes.shape({
            government: PropTypes.shape({
                id: PropTypes.number.isRequired,
                salesTax: PropTypes.number,
            }).isRequired,
            status: PropTypes.string.isRequired,
            template: PropTypes.shape({
                isReverseAuction: PropTypes.bool.isRequired,
            }).isRequired,
        }).isRequired,
        showConfirmationModal: PropTypes.func.isRequired,
        showHelpModal: PropTypes.func.isRequired,
        showSuggestedContentModal: PropTypes.func.isRequired,
        suggestedPricingSOW: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showFileUploadOptions: false,
            stateKey: 1,
            uploadFileError: undefined,
        };
    }

    componentDidMount() {
        const { markBuilderSectionVisited, priceTableValues } = this.props;

        if (priceTableValues.length > 0) {
            markBuilderSectionVisited({
                hasIncludedItems: true,
                onClose: this.hideHelpModal,
            });
        }
    }

    formName = PRICING;

    get styles() {
        return require('./index.scss');
    }

    get auctionMaxFractionDigits() {
        return this.props.project.auctionMaxFractionDigits;
    }

    get isReverseAuction() {
        return this.props.project.template.isReverseAuction;
    }

    addPriceTableHandler = (
        priceItems = undefined,
        priceTableConfig = defaultPriceTableValues,
        orderById
    ) => {
        const { array, priceTableValues } = this.props;

        if (priceItems === undefined) {
            priceItems = [
                {
                    newPriceItemUuid: UUIDv4(),
                    orderById: 1,
                },
            ];
        }

        let reverseAuctionOptions;
        if (this.isReverseAuction) {
            reverseAuctionOptions = {
                [HAS_TOTAL_ROW]: false,
                [HAS_SALES_TAX_ROW]: false,
            };
        }

        array.push(PRICE_TABLES, {
            ...priceTableConfig,
            ...reverseAuctionOptions,
            priceItems,
            orderById:
                orderById || getMaxNumberFromList(priceTableValues, PRICE_TABLE_ORDER_BY_ID) + 1,
        });
    };

    addSuggestedPricingItem = (tableIdx) => (item) => {
        const { array, priceTableValues } = this.props;
        const table = priceTableValues[tableIdx];
        const { priceItems } = table;
        const arrayKey = `${PRICE_TABLES}[${tableIdx}].${PRICE_ITEMS}`;

        const newPriceItemData = {
            description: stripHtml(item.description),
            newPriceItemUuid: UUIDv4(),
            quantity: item.miscData === LUMP_SUM ? 1 : null,
            unitToMeasure: item.miscData,
        };

        // Add suggested item to the array
        if (this.isReverseAuction && priceItems[0].description) {
            this.addPriceTableHandler([
                {
                    ...newPriceItemData,
                    orderById: 1,
                },
            ]);
        } else {
            array.push(arrayKey, {
                ...newPriceItemData,
                orderById: getMaxNumberFromList(table.priceItems, PRICE_ITEM_ORDER_BY_ID) + 1,
                sharedId: item.sharedId, // Not currently used
            });
        }

        // If there was only a single empty item remove it
        if (priceItems.length === 1) {
            const priceItem = priceItems[0];
            if (!priceItem.description) {
                return array.shift(arrayKey);
            }
        }
    };

    autoNumberLineItems = () => {
        const { change, priceTableValues, showConfirmationModal } = this.props;
        showConfirmationModal(
            () => {
                let lineItemNum = 0;
                priceTableValues.forEach((table, tableIdx) => {
                    table.priceItems.forEach((item, itemIdx) => {
                        if (item.isHeaderRow) {
                            return;
                        }

                        change(
                            `${PRICE_TABLES}[${tableIdx}].${PRICE_ITEMS}[${itemIdx}].${LINE_ITEM}`,
                            (lineItemNum += 1).toString()
                        );
                    });
                });
            },
            {
                bsStyle: 'primary',
                btnText: 'Auto Number',
                cancelText: 'Cancel',
                icon: 'sort-numeric-asc',
                iconSize: '',
                text: 'Would you like to auto number the Line Items column? This will fill in any blanks and overwrite any numbering or lettering you may have entered.',
                title: 'Auto Number Line Items',
            }
        );
    };

    hideHelpModal = () => {
        const { hasPriceTables, markBuilderSectionVisited } = this.props;

        if (!hasPriceTables) {
            markBuilderSectionVisited();
            this.addPriceTableHandler();
        }
    };

    showHelpModal = () => {
        const { showHelpModal } = this.props;

        showHelpModal({
            onClose: this.hideHelpModal,
        });
    };

    copyPriceTable = (priceTable) => {
        const { array, priceTableValues } = this.props;

        array.push(PRICE_TABLES, {
            ...priceTable,
            orderById: getMaxNumberFromList(priceTableValues, PRICE_TABLE_ORDER_BY_ID) + 1,
        });
    };

    handleDragEnd = (result) => {
        const {
            array: { move },
            change,
            priceTableValues,
        } = this.props;

        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        if (newLocation !== undefined && newLocation !== originLocation) {
            move(PRICE_TABLES, originLocation, newLocation);

            priceTableValues.forEach((priceTable, idx) => {
                change(`${PRICE_TABLES}[${idx}].${PRICE_TABLE_ORDER_BY_ID}`, idx + 1);
            });

            // Update stateKey to force re-mounting of tables
            this.setState((prevState) => ({ stateKey: prevState.stateKey + 1 }));
        }
    };

    renderSuggestedContentModal() {
        const {
            disabled,
            priceTableValues,
            project: {
                government: { id: govId },
            },
            suggestedPricing,
            suggestedPricingSOW,
        } = this.props;

        const sharedProps = {
            addPriceTableHandler: this.addPriceTableHandler,
            disabled,
            govId,
            hasMultiItems: true,
            isReverseAuction: this.isReverseAuction,
            options: priceTableValues.map((table, i) => ({
                title: table.title || `Table ${i + 1}`,
                clickHandler: this.addSuggestedPricingItem(i),
            })),
            sectionType: PRICING,
            subsectionType: BODY,
        };

        return (
            <SuggestedContentModal
                pricingProps={{
                    ...sharedProps,
                    description:
                        'Click to add any of the below pricing ' +
                        'suggestions that are relevant to your project:',
                    suggestedItems: suggestedPricing,
                }}
                sowProps={{
                    ...sharedProps,
                    description:
                        'All Scope of Work items have been ' +
                        'included below. Select any Scope item ' +
                        'to add as a pricing item:',
                    suggestedItems: suggestedPricingSOW,
                }}
            />
        );
    }

    renderAddPriceTableButton() {
        const { hasPriceTables, isOGThemeEnabledForComponents, projectSubmitting, updating } =
            this.props;

        const buttonProps = !hasPriceTables
            ? {
                  type: 'required',
                  text: 'Create Pricing Table',
                  info: 'Create a pricing table for vendors to complete with their response',
                  onClick: () => this.showHelpModal(),
                  className: this.styles.initButton,
              }
            : {
                  type: 'optional',
                  text: 'Add Another Pricing Table',
                  customTypeText: 'Optional',
                  onClick: () => this.addPriceTableHandler(),
              };

        if (isOGThemeEnabledForComponents) {
            return (
                <CDSButtonGroup>
                    <CDSButton
                        onClick={buttonProps.onClick}
                        qaTag="pricing-createPricingTable"
                        variant="secondary"
                    >
                        <i className="fa fa-plus" /> {buttonProps.text}
                    </CDSButton>
                </CDSButtonGroup>
            );
        }

        return (
            <GuidedSectionButton
                {...buttonProps}
                disabled={updating || projectSubmitting}
                qaTag="pricing-createPricingTable"
            />
        );
    }

    renderPriceTables() {
        const {
            change,
            disabled,
            isOGThemeEnabledForComponents,
            project: {
                government: { salesTax },
            },
            projectSubmitting,
            showConfirmationModal,
            showFormErrors,
            updating,
        } = this.props;

        return (
            <DragDropContext onDragEnd={this.handleDragEnd}>
                <Droppable droppableId="priceTables" isDropDisabled={disabled} type={PRICE_TABLES}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getDndStyle(snapshot)}
                            {...provided.droppableProps}
                        >
                            <FieldArray
                                auctionMaxFractionDigits={this.auctionMaxFractionDigits}
                                change={change}
                                component={PricingTables}
                                copyPriceTable={this.copyPriceTable}
                                disabled={updating || projectSubmitting}
                                isReverseAuction={this.isReverseAuction}
                                key={this.state.stateKey}
                                name={PRICE_TABLES}
                                salesTax={salesTax}
                                showConfirmationModal={showConfirmationModal}
                                showHelpModal={this.showHelpModal}
                                showValidation={showFormErrors}
                                useOpenGovStyle={isOGThemeEnabledForComponents}
                            />
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }

    renderUploadFileToggleButton() {
        const { isOGThemeEnabledForComponents, projectSubmitting, updating } = this.props;

        const { showFileUploadOptions } = this.state;

        const buttonProps = !showFileUploadOptions
            ? {
                  type: 'optional',
                  text: 'Show File Upload Options',
                  icon: 'fa fa-caret-right',
                  info: 'Click to see options for creating a pricing table from a file',
                  onClick: () =>
                      this.setState({
                          showFileUploadOptions: true,
                      }),
              }
            : {
                  type: 'optional',
                  text: 'Hide File Upload Options',
                  icon: 'fa fa-caret-down',
                  info: 'Click to hide options for creating a pricing table from a file',
                  customIcon: 'minus',
                  onClick: () =>
                      this.setState({
                          showFileUploadOptions: false,
                      }),
              };

        if (isOGThemeEnabledForComponents) {
            return (
                <CDSButtonGroup>
                    <CDSButton
                        onClick={buttonProps.onClick}
                        qaTag="pricing-fileUploadOptions"
                        variant="secondary"
                    >
                        <i className={buttonProps.icon} /> {buttonProps.text}
                    </CDSButton>
                </CDSButtonGroup>
            );
        }

        return (
            <GuidedSectionButton
                {...buttonProps}
                disabled={updating || projectSubmitting}
                qaTag="pricing-fileUploadOptions"
            />
        );
    }

    renderUploadFileDropzone() {
        const {
            isOGThemeEnabledForComponents,
            project: {
                government: { salesTax },
            },
        } = this.props;
        const { showFileUploadOptions, uploadFileError } = this.state;
        const { exampleFileLink, fileUploadError } = this.styles;

        let uploadFileErrorElement;
        if (uploadFileError) {
            uploadFileErrorElement = (
                <p className={`text-center text-danger ${fileUploadError}`}>
                    <strong>File Upload Error: {uploadFileError.message}</strong>
                </p>
            );
        }

        if (!showFileUploadOptions) {
            return;
        }

        const csvFileUrl =
            'https://procure-now.s3.us-west-2.amazonaws.com/downloads/Pricing_Table_Template.csv?v=0';
        const xlsxFileUrl =
            'https://procure-now.s3.us-west-2.amazonaws.com/downloads/Pricing_Table_Template.xlsx?v=0';

        return (
            <Box
                className={classnames({
                    [this.styles.instructions]: isOGThemeEnabledForComponents,
                })}
            >
                <p
                    className={classnames({
                        [exampleFileLink]: !isOGThemeEnabledForComponents,
                    })}
                >
                    <strong>Step 1: </strong>
                    <span className="text-primary">
                        <a href={xlsxFileUrl}>
                            Click here to download a correctly-formatted example pricing table file
                            [*.xlsx]
                        </a>
                        &nbsp;
                        <a href={csvFileUrl}>[*.csv]</a>.
                    </span>
                </p>
                <p className={classnames({ 'text-center': !isOGThemeEnabledForComponents })}>
                    <strong>Step 2:</strong> Complete the pricing table file.
                    <HelpIcon
                        tooltip={
                            <p>
                                <strong>Instructions:</strong>
                                <br />
                                Use the &quot;Table Name&quot; column to upload multiple tables at
                                once. Leave column blank when uploading single table.
                                <br />
                                <br />
                                Fill out the &quot;Description&quot; and &quot;Unit of Measure&quot;
                                columns. Optionally enter values for the &quot;Quantity&quot; and
                                &quot;Unit Cost&quot; columns. Vendors will supply the values for
                                all cells left blank.
                                <br />
                                <br />
                                You can optionally add custom columns by filling in the columns
                                under any of the &quot;Custom&quot; headers. Custom columns are
                                totally optional. Use none or up to 5.
                                <br />
                                <br />
                                <strong>Important:</strong>
                                <br />
                                Please do not change the names of the column headers! Any columns
                                with unrecognized headers will be ignored. Headers can be renamed
                                after uploading.
                            </p>
                        }
                    />
                </p>
                {isOGThemeEnabledForComponents && (
                    <Box component="p" pb={2}>
                        <strong>Step 3: </strong>
                        Drop or click below to upload your correctly-formatted *.csv, *.xls, *.xlsx,
                        or *.ods pricing table file.
                    </Box>
                )}
                <Box
                    className={classnames({
                        [this.styles.attachmentsContainer]: isOGThemeEnabledForComponents,
                    })}
                >
                    <StyledDropzone
                        dropzoneClassName={
                            isOGThemeEnabledForComponents ? this.styles.dropzone : null
                        }
                        dropzoneContainerClassName={
                            isOGThemeEnabledForComponents ? this.styles.dropzoneContainer : null
                        }
                        icons={['file-o', 'file-excel-o', 'table']}
                        label={isOGThemeEnabledForComponents ? '' : 'Pricing Table File'}
                        labelId="Pricing Table File"
                        multiple={false}
                        onDropAccepted={(files) => {
                            parsePricingTableFile(files[0], { salesTax })
                                .then((result) => {
                                    this.setState({
                                        uploadFileError: undefined,
                                    });

                                    const lastOrderById = getMaxNumberFromList(
                                        this.props.priceTableValues,
                                        PRICE_TABLE_ORDER_BY_ID
                                    );
                                    result.forEach((priceTable, index) => {
                                        this.addPriceTableHandler(
                                            priceTable.priceItems,
                                            priceTable.priceTableConfig,
                                            lastOrderById + index + 1
                                        );
                                    });
                                })
                                .catch((error) => {
                                    this.setState({
                                        uploadFileError: error,
                                    });
                                });
                        }}
                        text={
                            isOGThemeEnabledForComponents ? null : (
                                <>
                                    <strong>Step 3: </strong>
                                    Drop or click to upload your correctly-formatted *.csv, *.xls,
                                    *.xlsx, or *.ods pricing table file here
                                </>
                            )
                        }
                    >
                        {isOGThemeEnabledForComponents ? (
                            <Box className={this.styles.dropzoneContent}>
                                <i className="fa fa-upload" /> Drag a file here to upload or{' '}
                                <span className={this.styles.link}>
                                    click here to select a file
                                </span>
                            </Box>
                        ) : null}
                    </StyledDropzone>
                </Box>
                {uploadFileErrorElement}
            </Box>
        );
    }

    render() {
        const {
            hasPriceTables,
            isOGThemeEnabledForComponents,
            priceTableValues,
            showFormErrors,
            suggestedPricing,
            suggestedPricingSOW,
            updateError,
        } = this.props;

        const { fileUploadDivider, pricingTables } = this.styles;

        const hasSuggestedItems =
            hasPriceTables && (suggestedPricing.length > 0 || suggestedPricingSOW.length > 0);

        if (isOGThemeEnabledForComponents) {
            const hasAnyValue = priceTableValues.length > 0 && !priceTableValues[0].omitLineItem;
            const showToolbar = hasSuggestedItems || hasAnyValue;

            return (
                <Box className={this.styles.container}>
                    {showToolbar && (
                        <Box className={this.styles.toolbar}>
                            {hasAnyValue && (
                                <Box mr={1}>
                                    <CDSButton
                                        onClick={this.autoNumberLineItems}
                                        qaTag="pricing-autoNumberLineItems"
                                        size="small"
                                        variant="text"
                                    >
                                        <i className="fa fa-sort-numeric-asc" /> Auto Number Line
                                        Items
                                    </CDSButton>
                                </Box>
                            )}
                            {hasSuggestedItems && (
                                <Box mr={1}>
                                    <CDSButton
                                        onClick={this.props.showSuggestedContentModal}
                                        qaTag="pricing-showSuggestedItems"
                                        size="small"
                                        variant="text"
                                    >
                                        <i className="fa fa-list-ul" /> Show Suggested Items
                                    </CDSButton>
                                </Box>
                            )}
                            <Box flex={1} pr={1} textAlign="right">
                                <Legend
                                    className={this.styles.legend}
                                    showValidation={showFormErrors}
                                />
                            </Box>
                        </Box>
                    )}
                    <Box mt={3}>{this.renderPriceTables()}</Box>
                    <Box>{this.renderAddPriceTableButton()}</Box>
                    {!this.isReverseAuction && (
                        <Box>
                            <Typography align="center" variant="h5">
                                OR
                            </Typography>
                            {this.renderUploadFileToggleButton()}
                            {this.renderUploadFileDropzone()}
                        </Box>
                    )}
                    {this.renderSuggestedContentModal()}
                </Box>
            );
        }

        return (
            <>
                <Main className="row">
                    <FormError error={updateError} />
                    <FieldArray
                        component={ListError}
                        name={PRICE_TABLES}
                        showError={!!showFormErrors}
                    />
                    <div className={classnames('col-xs-12', this.styles.viewButtonContainer)}>
                        {hasSuggestedItems && (
                            <Button
                                bsSize="sm"
                                className={this.styles.viewButton}
                                onClick={this.props.showSuggestedContentModal}
                                qaTag="pricing-showSuggestedItems"
                            >
                                <i className="fa fa-list-ul" />
                                &nbsp; Show Suggested Items
                            </Button>
                        )}
                        {priceTableValues.length > 0 && !priceTableValues[0].omitLineItem && (
                            <div className="pull-right">
                                <Button
                                    bsSize="sm"
                                    className={this.styles.viewButton}
                                    onClick={this.autoNumberLineItems}
                                    qaTag="pricing-autoNumberLineItems"
                                >
                                    <i className="fa fa-sort-numeric-asc" />
                                    &nbsp; Auto Number Line Items
                                </Button>
                            </div>
                        )}
                    </div>
                    <div className="col-xs-12">
                        <div className={pricingTables}>
                            {this.renderPriceTables()}
                            {this.renderAddPriceTableButton()}
                            {!this.isReverseAuction && (
                                <>
                                    <p className={`text-center text-warning ${fileUploadDivider}`}>
                                        <strong>OR</strong>
                                    </p>
                                    {this.renderUploadFileToggleButton()}
                                    {this.renderUploadFileDropzone()}
                                </>
                            )}
                        </div>
                    </div>
                </Main>
                {this.renderSuggestedContentModal()}
            </>
        );
    }
}

export const Pricing = connect(mapStateToProps, mapDispatchToProps)(ConnectedPricing);
