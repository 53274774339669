import { SCOPE_OF_WORK_PROMPT } from '../../../../shared_config/generativeAi';
import { resourceManager } from '../../helpers';

export const GENERATE_AI_SCOPE_CONTENT = 'projectLibrary/GENERATE_AI_SCOPE_CONTENT';
export const GENERATE_AI_SCOPE_CONTENT_SUCCESS = 'projectLibrary/GENERATE_AI_SCOPE_CONTENT_SUCCESS';
export const GENERATE_AI_SCOPE_CONTENT_FAIL = 'projectLibrary/GENERATE_AI_SCOPE_CONTENT_FAIL';

export const generateAiScopeContent = ({ projectId, prompt }, govId, setLoadingFalse) => {
    return resourceManager({
        method: 'post',
        url: `/government/${govId}/generative-ai`,
        requestOptions: {
            data: { projectId, promptsKey: SCOPE_OF_WORK_PROMPT, userPrompt: prompt },
        },
        onStart: ({ dispatch }) => dispatch({ type: GENERATE_AI_SCOPE_CONTENT }),
        onSuccess: ({ result, dispatch }) => {
            dispatch({ type: GENERATE_AI_SCOPE_CONTENT_SUCCESS, result });
            setLoadingFalse();
        },
        onFailure: ({ error, dispatch }) => {
            dispatch({ type: GENERATE_AI_SCOPE_CONTENT_FAIL, error });
            setLoadingFalse();
        },
    });
};

export const clearAiScopeContent = () => {
    return { type: GENERATE_AI_SCOPE_CONTENT };
};
