import { fieldNames, progressSectionsDict } from './constants';
import { hasFormErrors } from '../../../helpers';
import {
    arrayError,
    budget,
    contact,
    department,
    evaluationCriteriaItem,
    financialId,
    rawSummary,
    requisitionIdentifier,
    sectionsValidate,
    title,
} from '../../../Forms/validation';
import { mappedProcurementContactFields } from '../../../../../shared_config/contacts';

const { PROCUREMENT_CONTACT_ID } = fieldNames;
const { OVERVIEW, PROPOSALS, CRITERIA, EVALUATORS } = progressSectionsDict;

function projectDetailValidate(values, props) {
    // Skip validation unless it's an evaluation only project
    if (!values.isEvaluationOnly) return {};

    return {
        ...title(values),
        ...financialId(values, props),
        ...requisitionIdentifier(values),
        ...budget(values, props),
        ...rawSummary(values),
        ...contact(values),
        ...contact(values, {
            fieldNames: mappedProcurementContactFields,
            idFieldName: PROCUREMENT_CONTACT_ID,
        }),
        ...department(values),
    };
}

function proposalDocumentsValidate(values, hasQuestionnaire) {
    const errors = {};

    // Skip validation if project has questionnaire section
    if (hasQuestionnaire) return errors;

    if (!values.proposalDocuments || values.proposalDocuments.length === 0) {
        errors.proposalDocuments = arrayError('Please add at least one response document');
        return errors;
    }

    errors.proposalDocuments = values.proposalDocuments.map((doc) => {
        return title(doc);
    });

    return errors;
}

function scoringCriteriaValidate(values) {
    const errors = {};

    if (!values.scoringCriteria || values.scoringCriteria.length === 0) {
        errors.scoringCriteria = arrayError('Please add at least one evaluation criteria');
        return errors;
    }

    errors.scoringCriteria = values.scoringCriteria.map((crit) => {
        return evaluationCriteriaItem(crit);
    });

    return errors;
}

function evaluatorsValidate(values) {
    const errors = {};

    if (!values.evaluators || values.evaluators.length === 0) {
        errors.evaluators = arrayError('At least one evaluator must be added');
        return errors;
    }

    return errors;
}

export const validateAll = (values, props) => {
    const evaluation = values.evaluation || {};
    const { hasQuestionnaire } = props;

    // Run validation functions
    const projectDetailErrors = projectDetailValidate(values, props);
    const proposalDocsErrors = proposalDocumentsValidate(evaluation, hasQuestionnaire);
    const scoringCriteriaErrors = scoringCriteriaValidate(evaluation);
    const evaluatorsErrors = evaluatorsValidate(evaluation);

    const sectionsErrors = sectionsValidate(
        [OVERVIEW, projectDetailErrors],
        [PROPOSALS, proposalDocsErrors],
        [CRITERIA, scoringCriteriaErrors],
        [EVALUATORS, evaluatorsErrors]
    );
    const formHasErrors = hasFormErrors(sectionsErrors);

    return {
        allSections: formHasErrors,
        evaluation: {
            ...proposalDocsErrors,
            ...scoringCriteriaErrors,
            ...evaluatorsErrors,
        },
        ...projectDetailErrors,
        ...sectionsErrors,
    };
};
