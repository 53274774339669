import classnames from 'classnames';
import { get, omit } from 'lodash';
import React, { PureComponent } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ListGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { v4 as UUIDv4 } from 'uuid';
import { Box } from '@og-pro/ui';

import {
    criteriaFieldNames,
    evaluationPhaseFieldNames,
    EVALUATION_CRITERIA,
    EVALUATION_CRITERIA_LIST_PROPTYPES,
} from './constants';
import { EvaluationCriteriaItem } from './EvaluationCriteriaItem';
import { EvaluationPhaseSelectModal } from './EvaluationPhaseSelectModal';
import { EvaluationCriteriaSDv2List } from './EvaluationCriteriaSDv2List';
import { OptionDropdown } from './OptionDropdown';
import { ListError } from '../..';
import { Button, CDSButton, InputText } from '../../..';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import { getDndStyle } from '../../../../constants/styles';
import {
    defaultClientScoringMethod,
    defaultScoringWeight,
} from '../../../../../../shared_config/evaluations';

const { IS_ADMIN_SCORED, IS_PUBLICLY_HIDDEN, ORDER_BY_ID, SCORING_METHOD, WEIGHT } =
    criteriaFieldNames;

const { TITLE } = evaluationPhaseFieldNames;

const mapDispatchToProps = {
    showConfirmationSimpleModal,
};

// @connect
class ConnectedEvaluationCriteriaList extends PureComponent {
    static propTypes = EVALUATION_CRITERIA_LIST_PROPTYPES;

    constructor(props) {
        super(props);

        /**
         * `formKey` is used to force re-mounting of the form. This is
         * necessary because updating the RichInputText value does not
         * actually update the component. It needs to be unmounted and
         * remounted to render the new state. RichInputText should be
         * refactored to update when `value` changes.
         */
        this.state = {
            formKey: 0,
            showPhaseEditInput: false,
            showPhaseSelectModal: false,
        };
    }

    componentDidMount() {
        const { fields, skipEmptyFormInitialization } = this.props;

        // Add an empty item if no items in array
        if (fields.length === 0 && !skipEmptyFormInitialization) {
            return this.addField();
        }
    }

    get styles() {
        return require('./EvaluationCriteriaList.scss');
    }

    addField = (scoringCriterium) => {
        const { fields } = this.props;
        const lastItem = fields.get(fields.length - 1);

        return fields.push({
            [IS_ADMIN_SCORED]: get(lastItem, IS_ADMIN_SCORED) || false,
            [IS_PUBLICLY_HIDDEN]: get(lastItem, IS_PUBLICLY_HIDDEN) || false,
            [ORDER_BY_ID]: get(lastItem, ORDER_BY_ID, 0) + 1,
            sharedId: UUIDv4(),
            [SCORING_METHOD]: get(lastItem, SCORING_METHOD, defaultClientScoringMethod),
            [WEIGHT]: get(lastItem, WEIGHT, defaultScoringWeight),
            ...scoringCriterium,
        });
    };

    addPhase = (evaluationPhase) => {
        const { fields } = this.props;

        const lastItem = fields.get(fields.length - 1);

        evaluationPhase.scoringCriteria.forEach((scoringCriterium, index) => {
            this.addField({
                ...omit(scoringCriterium, [
                    'id',
                    'created_at',
                    'updated_at',
                    'evaluation_id',
                    'evaluation_phase_id',
                ]),
                orderById: get(lastItem, ORDER_BY_ID, 0) + 1 + index,
            });
        });
    };

    editPhaseTitleHandler = () => {
        this.setState((prevState) => {
            return {
                showPhaseEditInput: !prevState.showPhaseEditInput,
            };
        });
    };

    handleDragEnd = (result) => {
        const {
            fields: { forEach, move },
            change,
        } = this.props;

        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        if (newLocation !== undefined && newLocation !== originLocation) {
            move(originLocation, newLocation);

            forEach((name, index) => {
                change(`${name}.${ORDER_BY_ID}`, index + 1);
            });
        }

        // Update formKey to force re-mounting of form components
        this.setState((prevState) => ({ formKey: prevState.formKey + 1 }));
    };

    moveFieldUp = (index) => {
        const { change, fields } = this.props;

        if (index === 0) return;

        fields.swap(index, index - 1);

        fields.forEach((name, i) => {
            change(`${name}.${ORDER_BY_ID}`, i + 1);
        });
    };

    moveFieldDown = (index) => {
        const { change, fields } = this.props;

        if (index === fields.length) return;

        fields.swap(index, index + 1);

        fields.forEach((name, i) => {
            change(`${name}.${ORDER_BY_ID}`, i + 1);
        });
    };

    insertField = (insertAfterOrderById) => {
        const { change, fields } = this.props;
        const lastItem = fields.get(fields.length - 1);

        let insertAfterIndex;
        fields.forEach((name, index) => {
            const criteria = fields.get(index);
            const oldOrderById = criteria.orderById;
            if (oldOrderById === insertAfterOrderById) {
                insertAfterIndex = index + 1;
            }
            if (oldOrderById > insertAfterOrderById) {
                change(`${name}.${ORDER_BY_ID}`, oldOrderById + 1);
            }
        });

        fields.splice(insertAfterIndex, 0, {
            orderById: insertAfterOrderById + 1,
            sharedId: UUIDv4(),
            scoringMethod: get(lastItem, SCORING_METHOD, defaultClientScoringMethod),
            weight: get(lastItem, WEIGHT, defaultScoringWeight),
        });

        // Update formKey to force re-mounting of form components
        return this.setState((prevState) => ({ formKey: prevState.formKey + 1 }));
    };

    removePhase = () => {
        const { deletePhaseHandler } = this.props;

        return this.props.showConfirmationSimpleModal(deletePhaseHandler, {
            text:
                'Deleting the evaluation phase will also delete all ' +
                'criteria items in the phase. Please confirm you would ' +
                'like to proceed.',
            icon: 'trash-o',
            btnText: 'Delete',
        });
    };

    removeField = (index) => {
        const { fields } = this.props;

        fields.remove(index);

        // Update formKey to force re-mounting of form components
        this.setState((prevState) => ({ formKey: prevState.formKey + 1 }));
    };

    togglePhaseSelectModal = () => {
        this.setState((prevState) => ({ showPhaseSelectModal: !prevState.showPhaseSelectModal }));
    };

    renderEvaluationCriteriaListItems = () => {
        const {
            change,
            disabled,
            fields,
            isEvaluationReleased,
            isMultiPhase,
            isOGThemeEnabledForComponents,
            isTemplate,
            renderQuestionLogicIcon,
            totalWeight,
            useRawDescription,
        } = this.props;

        return fields.map((member, index) => {
            const criteria = fields.get(index);
            const key = `${this.state.formKey}.${criteria.id}`;
            const questionLogicIcon = renderQuestionLogicIcon && renderQuestionLogicIcon(criteria);

            return (
                <Draggable draggableId={key} index={index} key={key}>
                    {(provided) =>
                        criteria.isHiddenByLogic ? (
                            // Hide the item, but include drag props so it properly reorders
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                                <div style={{ display: 'none' }} {...provided.dragHandleProps} />
                            </div>
                        ) : (
                            <EvaluationCriteriaItem
                                arrayName={member}
                                change={change}
                                criteria={criteria}
                                disabled={disabled}
                                draggableProvided={provided}
                                index={index}
                                insertField={this.insertField}
                                isEvaluationReleased={isEvaluationReleased}
                                isLastItem={index === fields.length - 1}
                                isLocked={criteria.isLocked}
                                isMultiPhase={isMultiPhase}
                                isNew={!criteria.id}
                                isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                                isTemplate={isTemplate}
                                moveFieldDown={this.moveFieldDown}
                                moveFieldUp={this.moveFieldUp}
                                questionLogicIcon={questionLogicIcon}
                                remove={this.removeField}
                                showFormErrors={this.props.showFormErrors}
                                totalWeight={totalWeight}
                                useRawDescription={useRawDescription}
                                weight={criteria.weight}
                            />
                        )
                    }
                </Draggable>
            );
        });
    };

    renderPhaseTitle() {
        const { disabled, evaluationPhaseArrayName, evaluationTitle, showFormErrors } = this.props;

        const { showPhaseEditInput } = this.state;

        if (showPhaseEditInput) {
            return (
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    name={`${evaluationPhaseArrayName}.${TITLE}`}
                    placeholder="Enter name for phase"
                    qaTag="evaluationCriteria-phaseName"
                    showValidation={showFormErrors}
                    type="text"
                />
            );
        }

        if (!evaluationTitle && showFormErrors) {
            return (
                <h5 className={classnames(this.styles.headerText, 'text-danger')}>
                    <i className="fa fa-exclamation-triangle" /> Evaluation title required!
                </h5>
            );
        }

        return <h5 className={this.styles.headerText}>{evaluationTitle}</h5>;
    }

    renderPanelHeader() {
        const { disabled, evaluationPhaseArrayName } = this.props;

        return (
            <div className={this.styles.tableHeader}>
                <div className="row">
                    <div className="col-xs-10">{this.renderPhaseTitle()}</div>
                    <div className="col-xs-2 text-right">
                        <OptionDropdown
                            deleteHandler={this.removePhase}
                            disabled={disabled}
                            editHandler={this.editPhaseTitleHandler}
                            id={evaluationPhaseArrayName}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderList() {
        const { canAddPhase, disabled, isOGThemeEnabledForComponents, isTemplate, meta } =
            this.props;

        const { showPhaseSelectModal } = this.state;

        return (
            <div>
                <ListError meta={meta} />
                <DragDropContext onDragEnd={this.handleDragEnd}>
                    <Droppable
                        droppableId="evaluationCriteriaList"
                        isDropDisabled={disabled}
                        type={EVALUATION_CRITERIA}
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getDndStyle(snapshot)}
                                {...provided.droppableProps}
                            >
                                <ListGroup>{this.renderEvaluationCriteriaListItems()}</ListGroup>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div className="text-center">
                    {canAddPhase && (
                        <Button
                            className={this.styles.addPhase}
                            disabled={disabled}
                            onClick={this.togglePhaseSelectModal}
                            qaTag="evaluationCriteria-addCriteriaFromPhase"
                        >
                            <i className="fa fa-star" /> Add Criteria From Phase
                        </Button>
                    )}
                    {isOGThemeEnabledForComponents && !isTemplate && (
                        <Box mb={4} mt={2}>
                            <CDSButton
                                disabled={disabled}
                                onClick={() => this.addField()}
                                qaTag="evaluationCriteria-addCriteria"
                                size="small"
                                variant="text"
                            >
                                <i className="fa fa-plus" /> Add Evaluation Criteria
                            </CDSButton>
                        </Box>
                    )}
                    {!isOGThemeEnabledForComponents && (
                        <Button
                            className={this.styles.addBtn}
                            disabled={disabled}
                            onClick={() => this.addField()}
                            qaTag="evaluationCriteria-addCriteria"
                        >
                            <i className="fa fa-plus" /> Add Evaluation Criteria
                        </Button>
                    )}
                </div>
                {showPhaseSelectModal && (
                    <EvaluationPhaseSelectModal
                        hideModal={this.togglePhaseSelectModal}
                        onSelect={this.addPhase}
                    />
                )}
            </div>
        );
    }

    render() {
        const { isMultiPhase, isOGThemeEnabledForComponents } = this.props;

        if (isMultiPhase) {
            if (isOGThemeEnabledForComponents) {
                return (
                    <EvaluationCriteriaSDv2List
                        {...this.props}
                        handleDragEnd={this.handleDragEnd}
                        removePhase={this.removePhase}
                    >
                        {this.renderList()}
                    </EvaluationCriteriaSDv2List>
                );
            }

            return (
                <div className={this.styles.evaluationPhaseContainer}>
                    {this.renderPanelHeader()}
                    {this.renderList()}
                </div>
            );
        }

        return this.renderList();
    }
}

export const EvaluationCriteriaList = connect(
    null,
    mapDispatchToProps
)(ConnectedEvaluationCriteriaList);
