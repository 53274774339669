import { createSelector } from 'reselect';

import { hasUserRolesSubscription } from '../../containers/GovApp/selectors';
import { getActiveUsersJS } from '../../containers/selectors';
import { userHasPermissionRole } from '../../helpers';
import { userRolePermissions } from '../../../../shared_config/userRoles';

const { IS_TEMPLATE_ADMIN } = userRolePermissions;

const getRawTemplateQuestions = (state) => state.templateQuestions.get('templateQuestions');

export const getTemplateAdminUserList = createSelector(
    [getActiveUsersJS, hasUserRolesSubscription],
    (users, hasUserRoles) => {
        return users
            .filter((user) => userHasPermissionRole(user, IS_TEMPLATE_ADMIN, hasUserRoles))
            .map((user) => ({
                label: user.displayName,
                user,
                value: user.id,
            }));
    }
);

export const getTemplateQuestions = createSelector([getRawTemplateQuestions], (templateQuestions) =>
    templateQuestions.toJS()
);
