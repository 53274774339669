import React from 'react';
import { Field } from 'redux-form';

import { SearchSelect } from '../../../../../../components';
import { useQueryParam } from '../../../../../../hooks';
import { qaTagPageName, fieldNames } from '../../constants';
import { getSortingOptionAndDirection, sortingOptions } from './constants';

export const RequisitionListSorting = () => {
    const styles = require('./index.scss');

    const { SORTING } = fieldNames;

    const [, , setQueryParams] = useQueryParam('sort');

    const onChange = (option) => {
        const { sort, sortDirection } = getSortingOptionAndDirection(option);

        setQueryParams({
            sort,
            sortDirection,
            page: 1,
        });
    };

    return (
        <Field
            component={SearchSelect}
            formClassName={styles.sortingContainer}
            label="Sort By:"
            labelClassName={styles.label}
            name={SORTING}
            onChange={onChange}
            options={sortingOptions}
            overrideFeedback
            placeholder="Select..."
            qaTag={`${qaTagPageName}-sorting`}
            selectClassName={styles.select}
        />
    );
};
