import { createSelector } from 'reselect';

import { getApprovalsListJS } from '../../../../../containers/GovApp/selectors';
import { getUserJS } from '../../../../../containers/selectors';
import { approvalStatusesDict } from '../../../../../../../shared_config/approval';

const { APPROVED } = approvalStatusesDict;

export const getActiveReviewStep = createSelector([getApprovalsListJS], (approvals) => {
    const activeReviewStep = approvals.find((reviewStep) => {
        return !reviewStep.projectApprovals.every((approver) => approver.status === APPROVED);
    });

    return activeReviewStep || null;
});

export const getUserApprovalInActiveReviewStep = createSelector(
    [getActiveReviewStep, getUserJS],
    (activeReviewStep, user) => {
        if (activeReviewStep) {
            return activeReviewStep.projectApprovals.find(
                (approval) => approval.user_id === user.id
            );
        }

        return null;
    }
);

export const getStatusLabelMap = createSelector(
    [getApprovalsListJS, getActiveReviewStep],
    (approvals, activeReviewStep) => {
        const statusLabelMap = {};

        approvals.forEach((reviewStep) => {
            if (reviewStep.id === activeReviewStep?.id) {
                statusLabelMap[reviewStep.id] = {
                    bsStyle: 'primary',
                    text: 'Active',
                };
            } else if (
                reviewStep.projectApprovals.every((approver) => approver.status === APPROVED)
            ) {
                statusLabelMap[reviewStep.id] = {
                    bsStyle: 'success',
                    text: 'Completed',
                };
            } else {
                statusLabelMap[reviewStep.id] = {
                    bsStyle: 'muted',
                    text: 'Not Started',
                };
            }
        });

        return statusLabelMap;
    }
);
