// Kebab case on flags
export const FLAGS = {
    TEST_FLAG: 'pro-test-flag',
    VENDOR_DISCOVERY_AI: 'pro-vendor-discovery-ai',
    PLATFORM_PASSWORD_RESET: 'pro-platform-password-reset',
    ACCOUNT_SPLIT_AUTO_ADJUSTMENTS: 'pro-requisition-line-item-account-split-auto-adjustments',
    SHOW_SERVICE_CLOUD_CHAT_BOT: 'pro-show-service-cloud-chat-bot',
    ENABLE_MUI_TEXTAREA_AUTOSIZE: 'pro-enable-mui-textarea-autosize',
    ENABLE_REACT_TABLE_8: 'pro-enable-react-table-8',
    ENABLE_REQUISITIONS_ATTACHMENTS: 'PRO-3460-requisitions-attachment',
    ATTACHMENTS_SCANNING: 'pro-4363-attachment-scanning',
    ENABLE_SUPPLIER_NETWORK_SEARCH_DIALOG: 'pro-enable-supplier-network-search-dialog',
    PUBLIC_VENDOR_SEARCH_PORTAL: 'pro-4968-public-vendor-search-portal',
    ALL_PROPOSALS_REPORT: 'pro-all-proposals-report',
    SHOW_CERTIFICATIONS_IMPORT: 'pro-4468-enable-vendor-certifications-import',
    DOING_BUSINESS_AS: 'pro-5274-doing-business-as-field',
    ENABLE_GLOBAL_NAV_GOV_USERS: 'pro-enable-global-nav',
    ENABLE_VENDOR_LISTS_UPDATE: 'pro-5282-updated-vendor-lists',
    ENABLE_SIGNATURE_SECTION: 'pro-enable-signature-section',
    ENABLE_PUBLIC_CONTRACTS_TAB: 'pro-5387-public-contracts-tab',
    ENABLE_HIDE_SECTION_NUMBERING: 'pro-enable-hide-section-numbering',
    ENABLE_SINGLE_SECTION_EXPORT: 'pro-5862-enable-single-section-export',
    ENABLE_STATES_IN_ESNA: 'pro-enable-states-in-esna',
    ENABLE_PIGGYBACK_LANGUAGE: 'pro-6167-piggyback-language',
    ENABLE_EXCEPTION_SEQUENCES: 'pro-enable-exception-sequences',
};
