import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GroupPositionsCreateButton } from './GroupPositionsCreateButton';
import { GroupPositionsModal } from './GroupPositionsModal';
import { GroupPositionsTable } from './GroupPositionsTable';
import connectData from '../../../ConnectData';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import { deletePosition, loadPositions } from '../../../../actions/requisitionsAdmin';
import {
    LoadingError,
    LoadingSpinner,
    Main,
    SectionTitle,
    ZeroState,
} from '../../../../components';
import { getGroupPositions } from '../../../../selectors/govApp';

const fetchData = (getState, dispatch) => {
    return dispatch(loadPositions());
};

const ConnectedRequisitionsGroupPositions = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);

    const groupPositions = useSelector(getGroupPositions);
    const isLoadingGroupPositions = useSelector((state) =>
        state.requisitionsAdmin.get('loadingPositions')
    );
    const loadingGroupPositionsError = useSelector((state) =>
        state.requisitionsAdmin.get('loadPositionsError')
    );

    const dispatch = useDispatch();

    const showGroupPositionModalHandler = (data = null) => {
        setModalData(data);
        setShowModal(true);
    };

    const hideGroupPositionModalHandler = () => {
        setModalData(null);
        setShowModal(false);
    };

    const deleteGroupPositionHandler = (positionId) => {
        dispatch(
            showConfirmationSimpleModal(() => dispatch(deletePosition(positionId)), {
                btnText: 'Delete Position',
                text: 'Are you sure you want to delete this group position?',
            })
        );
    };

    if (isLoadingGroupPositions) {
        return <LoadingSpinner />;
    }

    if (loadingGroupPositionsError) {
        return <LoadingError error={loadingGroupPositionsError} />;
    }

    return (
        <Main>
            <SectionTitle
                info="Define the positions that are assigned to groups of individuals for use in workflows"
                title="Group Positions"
            />
            <div className="row">
                <div className="col-lg-offset-1 col-lg-10">
                    <div className="text-right">
                        <GroupPositionsCreateButton
                            onClick={() => showGroupPositionModalHandler()}
                        />
                    </div>
                    {groupPositions.length === 0 ? (
                        <ZeroState title="No group positions have been created yet" />
                    ) : (
                        <GroupPositionsTable
                            onDelete={deleteGroupPositionHandler}
                            onEdit={showGroupPositionModalHandler}
                            positions={groupPositions}
                        />
                    )}
                    {showModal && (
                        <GroupPositionsModal
                            groupPosition={modalData}
                            hideModal={hideGroupPositionModalHandler}
                        />
                    )}
                </div>
            </div>
        </Main>
    );
};

export const RequisitionsGroupPositions = connectData(fetchData)(
    ConnectedRequisitionsGroupPositions
);
