import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { getProjectJS, getProposalVendorJS } from '../../selectors';
import connectData from '../../../ConnectData';
import { govLoadProposalVendor } from '../../../../actions/govProposals';
import {
    BackButton,
    LoadingError,
    LoadingSpinner,
    VendorProfile as VendorProfileComponent,
} from '../../../../components';
import { projectStatusesDict } from '../../../../../../shared_config/projects';

const { EVALUATION } = projectStatusesDict;

const mapStateToProps = (state, props) => {
    const proposalUrl = props.location.pathname.replace(/\/vendor\/?$/i, '');
    return {
        loadError: state.govProposals.get('loadVendorError'),
        loading: state.govProposals.get('loadingVendor'),
        project: getProjectJS(state),
        proposalUrl,
        vendor: getProposalVendorJS(state),
    };
};

// @connectData
// @connect
class ConnectedProposalVendorProfile extends Component {
    static propTypes = {
        isProposalEvaluation: PropTypes.bool,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        params: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
        project: PropTypes.shape({
            status: PropTypes.string.isRequired,
        }).isRequired,
        proposalUrl: PropTypes.string.isRequired,
        vendor: PropTypes.object,
    };

    render() {
        const {
            isProposalEvaluation,
            loadError,
            loading,
            project: { status },
            proposalUrl,
            vendor,
        } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError || !vendor) {
            return <LoadingError error={loadError} />;
        }

        return (
            <div className="row">
                <div className="col-sm-8 col-sm-offset-2">
                    {!isProposalEvaluation && (
                        <BackButton text="Back to Response" to={proposalUrl} />
                    )}
                    <VendorProfileComponent
                        isRatingEnabled={status !== EVALUATION}
                        useHorizontalProcuratedBox
                        vendor={vendor}
                    />
                </div>
            </div>
        );
    }
}

export const ProposalVendorProfile = compose(
    withRouter,
    connect(mapStateToProps)
)(ConnectedProposalVendorProfile);

function fetchVendor(getState, dispatch, location, params) {
    const proposalId = Number.parseInt(params.proposalId, 10);
    return dispatch(govLoadProposalVendor(proposalId));
}

// Government sourcing container
ProposalVendorProfile.Sourcing = connectData(fetchVendor)(ProposalVendorProfile);

// Government evaluation container
ProposalVendorProfile.Evaluation = connectData(fetchVendor)(ProposalVendorProfile);

// Government proposal evaluation container
const ProposalVendorProfileProposalEvaluation = (props) => {
    return <ProposalVendorProfile isProposalEvaluation {...props} />;
};
ProposalVendorProfile.ProposalEvaluation = connectData(fetchVendor)(
    ProposalVendorProfileProposalEvaluation
);
