import React from 'react';
import { Outlet } from 'react-router-dom';

import { GenericError, ResetContract } from '../components';
import { Contract } from '../../components';
import * as Gov from '../../containers/GovApp';
import { clientNavigationOnly, legacyFetchData, requireContractSubscription } from '../loaders';

export const ContractSubroutes = (getState, dispatch) => {
    return [
        {
            element: (
                <ResetContract>
                    <Gov.ContractCreate />
                </ResetContract>
            ),
            errorElement: <GenericError />,
            loader: clientNavigationOnly,
            path: 'create',
        },
        {
            element: (
                <ResetContract>
                    <Gov.ContractNav />
                </ResetContract>
            ),
            loader: legacyFetchData(getState, dispatch, Gov.ContractNav),
            path: ':contractId',
            children: [
                {
                    element: <Contract.Government />,
                    index: true,
                },
                {
                    element: <Gov.ContractChecklistsList />,
                    loader: legacyFetchData(getState, dispatch, Gov.ContractChecklistsList),
                    path: 'checklists',
                },
                {
                    element: <Gov.ContractChecklist />,
                    loader: legacyFetchData(getState, dispatch, Gov.ContractChecklist),
                    path: 'checklists/:checklistId',
                },
                {
                    element: <Gov.ContractContacts />,
                    path: 'contacts',
                },
                {
                    element: <Gov.ContractEmailLog />,
                    path: 'email-log',
                },
                {
                    element: <Gov.ContractInsurances />,
                    path: 'insurance',
                },
                {
                    element: <Gov.ContractNotifications />,
                    loader: legacyFetchData(getState, dispatch, Gov.ContractNotifications),
                    path: 'notifications',
                },
                {
                    element: <Gov.ContractPublicAuditLog />,
                    loader: legacyFetchData(getState, dispatch, Gov.ContractPublicAuditLog),
                    path: 'public-audit-log',
                },
                {
                    element: <Gov.ContractSpendManagement />,
                    loader: legacyFetchData(getState, dispatch, Gov.ContractSpendManagement),
                    path: 'spend-management',
                },
                {
                    element: <Gov.ContractReviewsNav />,
                    path: 'reviews',
                    children: [
                        {
                            element: <Gov.ContractReviewsList />,
                            index: true,
                            loader: legacyFetchData(getState, dispatch, Gov.ContractReviewsList),
                        },
                        {
                            element: <Gov.ContractReviewNav />,
                            loader: legacyFetchData(getState, dispatch, Gov.ContractReviewNav),
                            path: ':contractReviewId',
                            children: [
                                {
                                    element: <Gov.ContractReviewForm />,
                                    path: 'edit',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
};

export default (getState, dispatch) => {
    return [
        {
            element: <Outlet />,
            loader: requireContractSubscription(getState),
            path: 'contracts',
            children: [
                {
                    element: <Gov.GovernmentContractsListNav />,
                    index: true,
                },
                ...ContractSubroutes(getState, dispatch),
            ],
        },
    ];
};
