import { LOCATION_TYPES } from '../../../shared_config/vendors/expanded-supplier-network';

const normalizeLocationType = (type) => {
    switch (type?.toLowerCase()?.trim()) {
        case 'city':
            return LOCATION_TYPES.CITY;
        case 'state':
        case 'province':
        case 'state or province':
            return LOCATION_TYPES.STATE;
        default:
            return LOCATION_TYPES.ZIP_CODE;
    }
};

/**
 * Converts a findAddressCandidate candidate from the ESRI ARCGIS API into
 * a normalized object.
 * https://developers.arcgis.com/rest/geocode/api-reference/geocoding-find-address-candidates.htm
 *
 * @param {object} data An object as returned by the ESRI ARCGIS API
 * @returns {object}
 */
export const normalizeFindAddressCandidate = (data) => {
    if (!data) {
        throw new Error("No data supplied, expected find address candidates' candidate.");
    }

    return {
        location: data.location,
        name: data.attributes.ShortLabel,
        city: data.attributes.City || null,
        state: data.attributes.Region || null,
        stateCode: data.attributes.RegionAbbr || null,
        country: data.attributes.Country || 'USA',
        label: data.attributes.LongLabel,
        type: normalizeLocationType(data.attributes.Type),
    };
};
