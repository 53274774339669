import { debounce, isNil, omit } from 'lodash';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import {
    getFilterFormValues,
    getParsedQueryParams,
    getProjectSortByFieldOptions,
} from './selectors';
import { getPortalUrl, hasDocBuilderSubscription, hasSourcingSubscription } from '../selectors';
import { getProjectStatusOptions } from '../../../selectors/govApp';
import connectData from '../../ConnectData';
import {
    getProjectsJS,
    getUserJS,
    getUserOrganizationTimezone,
    isProjectCreatorUser,
    isSystemAdminUser,
} from '../../selectors';
import { storeLastFilter } from '../../../actions/auth';
import { loadGovernmentRetentionCodes } from '../../../actions/retention';
import { showProjectCreateModal } from '../../../actions/project/create/projectCreate';
import { loadProjects, PROJECTS_LIST_LIMIT } from '../../../actions/projects';
import { Main, PageTitle, TourButton } from '../../../components';
import { getUserDocBuilderHomepage, getUserProjectHomepage } from '../../../helpers';
import { filterTypesDict } from '../../../../../shared_config/filters';
import {
    projectScopeFilterTypesDict,
    projectSortFieldAndDirectionMap,
} from '../../../../../shared_config/projects';
import ControlNav from './ControlNav';
import ActionButtons from './ActionButtons';
import ProjectListGroup from './ProjectListGroup';
import ProjectListBody from './ProjectListBody';
import { MINIMUM_QUERY_LENGTH } from './ProjectQuickSearchForm/validate';
import { SEARCH_PRICE_ITEMS, QUICK_SEARCH_QUERY } from './ProjectQuickSearchForm/constants';
import { prepareGetProjectsQuery } from './util';
import { CooperativeContractBar } from '../../../components/CooperativeContractBar';

const { MY_COMMENTS, MY_DEPARTMENT, MY_EVALUATIONS, MY_REVIEWS, FOLLOWING } =
    projectScopeFilterTypesDict;

const { DOC_BUILDER, PROJECT, INTAKE } = filterTypesDict;

const DEFAULT_PAGE = 1;

const mapStateToProps = (state, props) => {
    const user = getUserJS(state);

    return {
        docBuilderHomepage: getUserDocBuilderHomepage(user),
        hasDocBuilder: hasDocBuilderSubscription(state),
        hasSourcing: hasSourcingSubscription(state),
        initialFilterFormValues: getFilterFormValues(state, props),
        isProjectCreator: isProjectCreatorUser(state),
        isSystemAdmin: isSystemAdminUser(state),
        loadError: state.projects.get('loadProjectsError'),
        loading: !!state.projects.get('loadingProjects'),
        portalUrl: getPortalUrl(state),
        projectHomepage: getUserProjectHomepage(user),
        projectSortByFieldOptions: getProjectSortByFieldOptions(state, props),
        projectStatusOptions: getProjectStatusOptions(state, props),
        projects: getProjectsJS(state),
        projectsCount: state.projects.get('projectsCount'),
        queryParams: getParsedQueryParams(state, props),
        timezone: getUserOrganizationTimezone(state),
        user,
    };
};

const mapDispatchToProps = {
    loadProjects,
    showProjectCreateModal,
    storeLastFilter,
};

// @connectData
// @connect
class ConnectedProjectsList extends Component {
    static propTypes = {
        docBuilderHomepage: PropTypes.string.isRequired,
        filterType: PropTypes.string.isRequired,
        hasDocBuilder: PropTypes.bool.isRequired,
        hasSourcing: PropTypes.bool.isRequired,
        initialFilterFormValues: PropTypes.object,
        isProjectCreator: PropTypes.bool.isRequired,
        isSystemAdmin: PropTypes.bool.isRequired,
        isDocBuilder: PropTypes.bool,
        isIntake: PropTypes.bool,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        loadProjects: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        params: PropTypes.shape({
            governmentId: PropTypes.string.isRequired,
        }).isRequired,
        portalUrl: PropTypes.string.isRequired,
        projectHomepage: PropTypes.string.isRequired,
        projects: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                isLibrary: PropTypes.bool.isRequired,
            })
        ).isRequired,
        projectsCount: PropTypes.number,
        projectSortByFieldOptions: PropTypes.array.isRequired,
        projectStatusOptions: PropTypes.array.isRequired,
        queryParams: PropTypes.shape({
            filterId: PropTypes.number,
            page: PropTypes.number,
            quickSearchQuery: PropTypes.string,
            scope: PropTypes.string,
            searchPriceItems: PropTypes.bool,
            sort: PropTypes.string,
            sortDirection: PropTypes.string,
            status: PropTypes.string,
        }).isRequired,
        router: PropTypes.object.isRequired,
        showProjectCreateModal: PropTypes.func.isRequired,
        storeLastFilter: PropTypes.func.isRequired,
        timezone: PropTypes.string.isRequired,
        user: PropTypes.shape({
            government: PropTypes.shape({
                code: PropTypes.string.isRequired,
            }).isRequired,
            organization: PropTypes.shape({
                logo: PropTypes.string,
                name: PropTypes.string,
                zipCode: PropTypes.string,
            }).isRequired,
        }).isRequired,
    };

    static defaultProps = {
        projectsCount: 0,
    };

    get styles() {
        return require('./index.scss');
    }

    get isFiltered() {
        const { queryParams } = this.props;

        const scopeFilters = [MY_COMMENTS, MY_DEPARTMENT, MY_EVALUATIONS, MY_REVIEWS, FOLLOWING];
        const { scope, ...nonScopeFilters } = queryParams;

        // The list is considered filtered for certain scope filters and all non-scope filters
        return (
            scopeFilters.some((filter) => filter === scope) ||
            Object.keys(nonScopeFilters).length > 0
        );
    }

    get paginationPage() {
        const {
            queryParams: { page },
        } = this.props;

        if (page) {
            return page;
        }
        return DEFAULT_PAGE;
    }

    get paginationPages() {
        const { projectsCount } = this.props;

        return Math.ceil(projectsCount / PROJECTS_LIST_LIMIT);
    }

    debouncedSearchProjects = debounce((params) => {
        this.executeSearch(
            {
                quickSearchQuery: params[QUICK_SEARCH_QUERY],
                searchPriceItems: params[SEARCH_PRICE_ITEMS] || undefined,
            },
            true
        );
    }, 500);

    executeSearch = (searchData, isNewQuery) => {
        const {
            location: { pathname },
            queryParams,
            router,
        } = this.props;

        const searchQuery = {
            ...omit(queryParams, ['filterId']),
            ...searchData,
        };

        // If making a new query reset search results to page 1
        if (isNewQuery && searchQuery.hasOwnProperty('page')) {
            searchQuery.page = 1;
        }

        // Make the filter sticky
        this.storeLastFilter(searchQuery);
        router.push({ pathname, search: `?${qs.stringify(searchQuery)}` });
    };

    resetFilters = () => {
        const {
            location: { pathname },
            router,
        } = this.props;

        this.storeLastFilter({});
        router.push({ pathname });
    };

    exportProjectList = () => {
        const { isDocBuilder, isIntake, queryParams } = this.props;

        const projectQueryParams = {
            ...prepareGetProjectsQuery(queryParams, isDocBuilder, isIntake),
            exportData: true,
        };

        return this.props.loadProjects(projectQueryParams);
    };

    pageResults = (page) => {
        this.executeSearch({ page });
    };

    projectSetSortDirection = (sortDirection) => {
        const sort = this.props.queryParams.hasOwnProperty('sort')
            ? this.props.queryParams.sort
            : 'created_at';

        if (sort) {
            this.executeSearch({ sort, sortDirection }, true);
        }
    };

    projectSortByField = (sort) => {
        const sortDirection = projectSortFieldAndDirectionMap[sort];

        this.executeSearch({ sort, sortDirection }, true);
    };

    projectStatusSearch = ({ status }) => {
        const { queryParams } = this.props;

        // Since the form is not reinitialized between lists it gets passed `undefined` on URL
        // changes which causes it to always to update on page change, which is undesirable.
        // Additionally, we set a default value to always populate the filter, but as a result
        // a search is attempted when there is no query status and form is initialized to `all`.
        // To prevent this we don't execute the search on this condition.
        const statusAlreadyDefaultedToAll = status === 'all' && !queryParams.status;
        if (status && !statusAlreadyDefaultedToAll) {
            this.executeSearch({ status }, true);
        }
    };

    projectQuickSearch = ({ searchPriceItems, quickSearchQuery }) => {
        const { isDocBuilder, isIntake, queryParams } = this.props;

        const projectQueryParams = {
            ...prepareGetProjectsQuery(queryParams, isDocBuilder, isIntake),
            page: 1,
            quickSearchQuery,
            searchPriceItems,
        };

        if (
            searchPriceItems &&
            (isNil(quickSearchQuery) || quickSearchQuery.trim().length < MINIMUM_QUERY_LENGTH)
        ) {
            return;
        }

        return this.debouncedSearchProjects(projectQueryParams);
    };

    routeToPortal = () => {
        const { portalUrl } = this.props;

        window.open(portalUrl, '_blank');
    };

    storeLastFilter = (filterData) => {
        const { filterType } = this.props;

        // Make the filter sticky
        this.props.storeLastFilter(filterType, filterData);
    };

    render() {
        const {
            hasSourcing,
            initialFilterFormValues,
            filterType,
            hasDocBuilder,
            isDocBuilder,
            isIntake,
            loading,
            loadError,
            params: { governmentId },
            projects,
            projectsCount,
            projectSortByFieldOptions,
            projectStatusOptions,
            queryParams: {
                filterId,
                sort,
                sortDirection,
                status,
                quickSearchQuery,
                searchPriceItems,
            },
            timezone,
            user: {
                organization: { logo, name, zipCode },
            },
        } = this.props;

        const paginationProps = {
            activePage: this.paginationPage,
            isLoading: loading,
            totalPages: this.paginationPages,
            projectsCount,
            onSelect: this.pageResults,
        };
        const pageTitle = filterType === INTAKE ? 'Intake List' : 'Project List';

        return (
            <div className={`row ${this.styles.projectListContainer}`}>
                <PageTitle title={pageTitle} />
                <h1 className="visually-hidden">{pageTitle}</h1>
                <div className="col-sm-4 col-md-3">
                    <div className={this.styles.controlCol}>
                        <div className={this.styles.tutorialContainer}>
                            <TourButton
                                className="tutorial-btn"
                                qaTag="dashboard-tour"
                                text="Tour Dashboard"
                            />
                        </div>
                        <ControlNav
                            filterId={filterId}
                            initialFilterFormValues={initialFilterFormValues}
                            isDocBuilder={isDocBuilder}
                            isIntake={isIntake}
                            logo={logo}
                            onSearch={this.executeSearch}
                            onStoreLastFilter={this.storeLastFilter}
                        >
                            {!isDocBuilder && (
                                <ActionButtons
                                    governmentId={governmentId}
                                    hasSourcing={hasSourcing}
                                    isIntake={isIntake}
                                    onRouteToPortal={this.routeToPortal}
                                />
                            )}
                        </ControlNav>
                        {isIntake && (
                            <CooperativeContractBar
                                publicEntityName={name}
                                sizing="xs"
                                userZipCode={zipCode}
                            />
                        )}
                    </div>
                </div>
                <Main className="col-sm-8 col-md-9">
                    <ProjectListBody
                        hasDocBuilder={hasDocBuilder}
                        initialQuickSearchFormValues={{ quickSearchQuery, searchPriceItems }}
                        isDocBuilder={isDocBuilder}
                        isIntake={isIntake}
                        onExportProjectList={this.exportProjectList}
                        onProjectQuickSearch={this.projectQuickSearch}
                        onProjectSetSortDirection={this.projectSetSortDirection}
                        onProjectSortByField={this.projectSortByField}
                        onProjectStatusSearch={this.projectStatusSearch}
                        paginationProps={paginationProps}
                        projectSortByFieldOptions={projectSortByFieldOptions}
                        projectStatusOptions={projectStatusOptions}
                        resetFilters={this.resetFilters}
                        sort={sort || 'created_at'}
                        sortDirection={sortDirection || 'DESC'}
                        status={status}
                    >
                        <ProjectListGroup
                            governmentId={governmentId}
                            hasSourcing={hasSourcing}
                            isDocBuilder={isDocBuilder}
                            isFiltered={this.isFiltered}
                            isIntake={isIntake}
                            isLoading={loading}
                            loadError={loadError}
                            priceItemDescriptionFilter={quickSearchQuery}
                            projects={projects}
                            timezone={timezone}
                        />
                    </ProjectListBody>
                </Main>
            </div>
        );
    }
}

export const ProjectsList = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProjectsList);

const fetchData = ({ isDocBuilder, isIntake } = {}) => {
    // eslint-disable-next-line
    return (getState, dispatch, location, params) => {
        const queryParams = prepareGetProjectsQuery(
            // 11/4/21: `arrayLimit` added to prevent qs from converting array of `ids` to object
            // during parsing when more than 20 ids are present. https://github.com/ljharb/qs
            qs.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 }),
            isDocBuilder,
            isIntake
        );
        const retentionCodes = getState().retention.get('retentionCodes').toJS();
        if (!retentionCodes.length) {
            const governmentId = getState().auth.get('user').get('government_id');
            dispatch(loadGovernmentRetentionCodes({ governmentId }));
        }
        return dispatch(loadProjects(queryParams));
    };
};

// Purchases list container
const ProjectsListPurchases = (props) => {
    return <ProjectsList filterType={PROJECT} {...props} />;
};
ProjectsList.Purchases = connectData(fetchData())(ProjectsListPurchases);

// DocBuilder list container
const ProjectsListDocuments = (props) => {
    return <ProjectsList filterType={DOC_BUILDER} isDocBuilder {...props} />;
};
ProjectsList.Documents = connectData(fetchData({ isDocBuilder: true }))(ProjectsListDocuments);

// Intake list container
const ProjectsListIntakes = (props) => {
    return <ProjectsList filterType={INTAKE} isIntake {...props} />;
};
ProjectsList.Intakes = connectData(fetchData({ isIntake: true }))(ProjectsListIntakes);
