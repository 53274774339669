import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EntityPositionsCreateButton } from './EntityPositionsCreateButton';
import { EntityPositionsModal } from './EntityPositionsModal';
import { EntityPositionsTable } from './EntityPositionsTable';
import connectData from '../../../ConnectData';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import { deletePosition, loadPositions } from '../../../../actions/requisitionsAdmin';
import {
    LoadingError,
    LoadingSpinner,
    Main,
    SectionTitle,
    ZeroState,
} from '../../../../components';
import { getEntityPositions } from '../../../../selectors/govApp';

const fetchData = (getState, dispatch) => {
    return dispatch(loadPositions());
};

export const ConnectedRequisitionsEntityPositions = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);

    const entityPositions = useSelector(getEntityPositions);
    const isLoadingEntityPositions = useSelector((state) =>
        state.requisitionsAdmin.get('loadingPositions')
    );
    const loadingEntityPositionsError = useSelector((state) =>
        state.requisitionsAdmin.get('loadPositionsError')
    );

    const dispatch = useDispatch();

    const showEntityPositionModalHandler = (data = null) => {
        setModalData(data);
        setShowModal(true);
    };

    const hideEntityPositionModalHandler = () => {
        setModalData(null);
        setShowModal(false);
    };

    const deleteEntityPositionHandler = (positionId) => {
        dispatch(
            showConfirmationSimpleModal(() => dispatch(deletePosition(positionId)), {
                btnText: 'Delete Position',
                text: 'Are you sure you want to delete this entity position?',
            })
        );
    };

    if (isLoadingEntityPositions) {
        return <LoadingSpinner />;
    }

    if (loadingEntityPositionsError) {
        return <LoadingError error={loadingEntityPositionsError} />;
    }

    return (
        <Main>
            <SectionTitle
                info="Define the positions that are assigned to specific individuals"
                title="Entity Positions"
            />
            <div className="row">
                <div className="col-lg-offset-1 col-lg-10">
                    <div className="text-right">
                        <EntityPositionsCreateButton
                            onClick={() => showEntityPositionModalHandler()}
                        />
                    </div>
                    {entityPositions.length === 0 ? (
                        <ZeroState title="No entity positions have been created yet" />
                    ) : (
                        <EntityPositionsTable
                            onDelete={deleteEntityPositionHandler}
                            onEdit={showEntityPositionModalHandler}
                            positions={entityPositions}
                        />
                    )}
                    {showModal && (
                        <EntityPositionsModal
                            entityPosition={modalData}
                            hideModal={hideEntityPositionModalHandler}
                        />
                    )}
                </div>
            </div>
        </Main>
    );
};

export const RequisitionsEntityPositions = connectData(fetchData)(
    ConnectedRequisitionsEntityPositions
);
