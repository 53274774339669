import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import {
    AddendumsConfirmSection,
    CompanySection,
    DisqualifiedWell,
    DocumentsSection,
    ExcludedWell,
    PriceTablesSection,
    NoBidWell,
    QuestionnaireSection,
    ReverseAuctionWell,
    SubmittedWell,
    VendorActivityButton,
} from './components';
import { getVendorPriceTableExportFilename } from './helpers';
import { Button, ButtonGroup, Print, SectionTitle } from '..';
import { ReportsModalButton } from '../../containers/GovApp';
import { proposalStatusesDict } from '../../../../shared_config/proposals';

const { NO_BID } = proposalStatusesDict;

export class ProposalDetails extends PureComponent {
    static propTypes = {
        canGovUserEdit: PropTypes.bool,
        documentsViewerUrl: PropTypes.string,
        displayExcludedWell: PropTypes.bool,
        displayPricingUnsealedInfo: PropTypes.bool,
        displayVendorConfirmationWell: PropTypes.bool,
        excludeDocs: PropTypes.bool,
        isDocx: PropTypes.bool,
        isVendorView: PropTypes.bool,
        // Used by `ReportsModalButton`
        module: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
        project: PropTypes.shape({
            auctionMaxFractionDigits: PropTypes.number,
            bidPricingUnsealedAt: PropTypes.string,
            government: PropTypes.shape({
                organization: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                }),
                salesTax: PropTypes.number,
            }),
            id: PropTypes.number.isRequired,
            showBidsWithPricing: PropTypes.bool,
            status: PropTypes.string.isRequired,
            template: PropTypes.shape({
                isReverseAuction: PropTypes.bool.isRequired,
            }).isRequired,
        }),
        proposal: PropTypes.shape({
            companyName: PropTypes.string,
            disqualifiedAt: PropTypes.string,
            disqualifiedReason: PropTypes.string,
            disqualifier: PropTypes.shape({
                displayName: PropTypes.string,
            }),
            id: PropTypes.number.isRequired,
            isDisqualified: PropTypes.bool.isRequired,
            isExcluded: PropTypes.bool.isRequired,
            isGovernmentSubmitted: PropTypes.bool.isRequired,
            isPricingUnsealed: PropTypes.bool,
            noBidReason: PropTypes.string,
            pricingUnsealedAt: PropTypes.string,
            status: PropTypes.string.isRequired,
            submittedAt: PropTypes.instanceOf(Date),
            submitter: PropTypes.shape({
                displayName: PropTypes.string,
            }),
            supplementalAttachments: PropTypes.array,
        }),
        showReportButton: PropTypes.bool,
        showVendorActivityButton: PropTypes.bool,
        supplementalsViewerUrl: PropTypes.string,
        updateQuestionnaireResponse: PropTypes.func,
        vendorUrl: PropTypes.string.isRequired,
    };

    static defaultProps = {
        excludeDocs: false,
        proposal: undefined,
        showVendorActivityButton: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            isPrinterView: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    getAddendumsSection = () => {
        return this.addendumsSection;
    };

    getAddendumsSectionPrintButton = () => {
        return <Print content={this.getAddendumsSection} trigger={this.renderPrintIcon} />;
    };

    getCompanySection = () => {
        return this.companySection;
    };

    getCompanySectionPrintButton = () => {
        return <Print content={this.getCompanySection} trigger={this.renderPrintIcon} />;
    };

    getDocumentsSection = () => {
        return this.documentsSection;
    };

    getDocumentsSectionPrintButton = () => {
        return <Print content={this.getDocumentsSection} trigger={this.renderPrintIcon} />;
    };

    getPriceTablesSection = () => {
        return this.priceTablesSection;
    };

    getPriceTablesSectionPrintButton = () => {
        return (
            <Print
                content={this.getPriceTablesSection}
                onAfterPrint={this.resetPrintView}
                onBeforeGetContent={this.setContentForPrintView}
                trigger={this.renderPrintIcon}
            />
        );
    };

    getProposalRef = () => {
        return this.proposalRef;
    };

    getQuestionnaireSection = () => {
        return this.questionnaireSection;
    };

    getQuestionnaireSectionPrintButton = () => {
        return <Print content={this.getQuestionnaireSection} trigger={this.renderPrintIcon} />;
    };

    renderPrintIcon = () => {
        return <i className={`fa fa-print no-print ${this.styles.printIcon}`} />;
    };

    renderPrintProposalButton = () => {
        return (
            <Button bsSize="sm">
                <i className="fa fa-print" /> Print
            </Button>
        );
    };

    resetPrintView = () => this.setState({ isPrinterView: false });

    setContentForPrintView = () => {
        this.setState({ isPrinterView: true });

        // Wait one second to allow the browser to redraw with the new settings
        // https://www.ag-grid.com/javascript-grid-for-print/
        return new Promise((resolve) => {
            setTimeout(resolve, 1000);
        });
    };

    renderDocxProposal() {
        const {
            proposal: { companyName },
        } = this.props;

        return (
            <>
                <h2 style={{ textAlign: 'center' }}>
                    {`${companyName || 'Unnamed Company'} Response`}
                </h2>
                {this.renderSections()}
            </>
        );
    }

    renderPricingUnsealed() {
        const {
            project: { bidPricingUnsealedAt },
            proposal: { pricingUnsealedAt },
        } = this.props;

        const pricingUnsealedDate = pricingUnsealedAt || bidPricingUnsealedAt;

        const text = pricingUnsealedDate
            ? `Pricing unsealed at ${moment(pricingUnsealedDate).format('lll')}`
            : 'Pricing is sealed';

        return (
            <p className="text-center">
                <em>{text}</em>
            </p>
        );
    }

    setCompanySectionRef = (ref) => {
        this.companySection = ref;
    };

    setDocumentsSectionRef = (ref) => {
        this.documentsSection = ref;
    };

    setPriceTablesSectionRef = (ref) => {
        this.priceTablesSection = ref;
    };

    setProposalRef = (ref) => {
        this.proposalRef = ref;
    };

    setQuestionnaireSectionRef = (ref) => {
        this.questionnaireSection = ref;
    };

    setAddendumsSectionRef = (ref) => {
        this.addendumsSection = ref;
    };

    renderSections() {
        const {
            canGovUserEdit,
            excludeDocs,
            isDocx,
            isVendorView,
            project,
            project: {
                auctionMaxFractionDigits,
                government: { salesTax },
                template: { isReverseAuction },
            },
            proposal,
            updateQuestionnaireResponse,
            vendorUrl,
        } = this.props;

        const { isPrinterView } = this.state;
        const isPricingSealed =
            !project.showBidsWithPricing && !proposal.isPricingUnsealed && !isVendorView;

        const sections = [
            <CompanySection
                isDocx={isDocx}
                key="companySection"
                printButton={this.getCompanySectionPrintButton()}
                project={project}
                proposal={proposal}
                ref={this.setCompanySectionRef}
                vendorUrl={vendorUrl}
            />,
        ];

        if (proposal.status === NO_BID) {
            return sections;
        }

        return sections.concat([
            <AddendumsConfirmSection
                isDocx={isDocx}
                isPrinterView={isPrinterView}
                key="addendumsSection"
                printButton={this.getAddendumsSectionPrintButton()}
                proposal={proposal}
                ref={this.setAddendumsSectionRef}
            />,
            <DocumentsSection
                excludeDocs={excludeDocs}
                isDocx={isDocx}
                key="documentsSection"
                printButton={this.getDocumentsSectionPrintButton()}
                proposal={proposal}
                ref={this.setDocumentsSectionRef}
            />,
            <QuestionnaireSection
                canGovUserEdit={canGovUserEdit}
                isDocx={isDocx}
                isPricingSealed={isPricingSealed}
                key="questionnaireSection"
                printButton={this.getQuestionnaireSectionPrintButton()}
                proposal={proposal}
                ref={this.setQuestionnaireSectionRef}
                updateQuestionnaireResponse={updateQuestionnaireResponse}
            />,
            <PriceTablesSection
                auctionMaxFractionDigits={auctionMaxFractionDigits}
                exportFileName={getVendorPriceTableExportFilename(project, proposal)}
                isDocx={isDocx}
                isPricingSealed={isPricingSealed}
                isPrinterView={isPrinterView}
                isReverseAuction={isReverseAuction}
                key="priceTableSection"
                printButton={this.getPriceTablesSectionPrintButton()}
                proposal={proposal}
                ref={this.setPriceTablesSectionRef}
                salesTax={salesTax}
            />,
        ]);
    }

    render() {
        const {
            displayExcludedWell,
            displayPricingUnsealedInfo,
            displayVendorConfirmationWell,
            documentsViewerUrl,
            isDocx,
            isVendorView,
            project: {
                template: { isReverseAuction },
            },
            project,
            proposal,
            showReportButton,
            showVendorActivityButton,
            supplementalsViewerUrl,
            vendorUrl,
        } = this.props;

        if (!proposal) {
            return null;
        }

        if (isDocx) {
            return this.renderDocxProposal();
        }

        const { companyName, isDisqualified, isExcluded, isGovernmentSubmitted, status } = proposal;

        const suppDocsLength = proposal.supplementalAttachments
            ? proposal.supplementalAttachments.length
            : 0;

        return (
            <div ref={this.setProposalRef}>
                <SectionTitle title={`${companyName || 'Unnamed Company'} Response`} />
                {displayPricingUnsealedInfo && this.renderPricingUnsealed()}
                {isDisqualified && <DisqualifiedWell proposal={proposal} />}
                {status === NO_BID && <NoBidWell proposal={proposal} />}
                {displayExcludedWell && isExcluded && <ExcludedWell />}
                {isReverseAuction && isVendorView && (
                    <ReverseAuctionWell
                        isProposalDetailsPage
                        project={project}
                        proposal={proposal}
                    />
                )}
                <SubmittedWell
                    displayVendorConfirmationWell={displayVendorConfirmationWell}
                    project={project}
                    proposal={proposal}
                />
                <div className={`no-print ${this.styles.navButtons}`}>
                    <ButtonGroup bsSize="sm" className="pull-left">
                        <Print
                            content={this.getProposalRef}
                            onAfterPrint={this.resetPrintView}
                            onBeforeGetContent={this.setContentForPrintView}
                            trigger={this.renderPrintProposalButton}
                        />
                        {showReportButton && (
                            <ReportsModalButton
                                dataForReport={this.props}
                                type="proposalDocumentReport"
                            />
                        )}
                    </ButtonGroup>
                    <ButtonGroup bsSize="sm">
                        {showVendorActivityButton && !proposal.isGovernmentSubmitted && (
                            <VendorActivityButton project={project} proposal={proposal} />
                        )}
                        {documentsViewerUrl && (
                            <Button qaTag="proposeDetails-documentViewer" to={documentsViewerUrl}>
                                <i className="fa fa-search" /> Document Viewer
                            </Button>
                        )}
                        {!isGovernmentSubmitted && (
                            <Button qaTag="proposeDetails-vendorProfile" to={vendorUrl}>
                                <i className="fa fa-user-circle" /> Vendor Profile
                            </Button>
                        )}
                        {supplementalsViewerUrl && (
                            <Button
                                qaTag="proposeDetails-supplementalDocuments"
                                to={supplementalsViewerUrl}
                            >
                                <i className="fa fa-folder-open" /> Supplemental Documents
                                {suppDocsLength > 0 ? ` (${suppDocsLength})` : ''}
                            </Button>
                        )}
                    </ButtonGroup>
                </div>
                {this.renderSections()}
            </div>
        );
    }
}
