import { get } from 'lodash';
import { createSelector } from 'reselect';
import { createSearchParams } from 'react-router-dom';

import {
    getCurrentEvaluation,
    getEvaluationPath,
    getEvaluationProjectJS,
    getQueryPhase,
    isEvaluationEditor,
    isEvaluationViewer,
    isEvaluator,
    isViewingPastPhase,
} from '../selectors';
import { getUserJS } from '../../selectors';
import { getTotalWeight as getTotalWeightHelper } from '../../../helpers';
import { evaluationStatuses, SCORED } from '../../../../../shared_config/evaluations';

const { COMPLETE, RELEASED } = evaluationStatuses;

const getPathname = (state, props) => props.location.pathname;
const getEvaluationModalData = (state) => state.proposalEvaluations.get('evaluationModalData');

export const canEvaluate = createSelector(
    [getCurrentEvaluation, isEvaluator, isEvaluationEditor],
    (evaluation, isUserEvaluator, isEditor) => {
        if (evaluation && (isUserEvaluator || isEditor)) {
            return evaluation.status === RELEASED;
        }
        return false;
    }
);

export const getTotalWeight = createSelector([getCurrentEvaluation], getTotalWeightHelper);

export const hasQuestionnaire = createSelector([getEvaluationProjectJS], (project) => {
    if (!project) return true;

    return project.questionnaires.length > 0;
});

export const hasAdminScoredCriteria = createSelector([getCurrentEvaluation], (evaluation) => {
    return !!evaluation && evaluation.scoringCriteria.some((criteria) => criteria.isAdminScored);
});

export const isAdminScorecardComplete = createSelector([getCurrentEvaluation], (evaluation) => {
    return !!get(evaluation, 'adminScorecard.isComplete');
});

export const getEvaluationLocationObject = createSelector(
    [getEvaluationPath, getQueryPhase],
    (evaluationPath, phase) => {
        return (evaluationRoute, query) => {
            return {
                pathname: `${evaluationPath}${evaluationRoute || ''}`,
                search: createSearchParams({
                    phase,
                    ...query,
                }).toString(),
            };
        };
    }
);

export const getNavItems = createSelector(
    [
        getCurrentEvaluation,
        getUserJS,
        getEvaluationPath,
        getEvaluationLocationObject,
        isEvaluationViewer,
        isEvaluationEditor,
        getPathname,
        hasAdminScoredCriteria,
    ],
    (
        evaluation,
        user,
        evaluationPath,
        getEvalPathObj,
        isViewer,
        isEditor,
        pathname,
        hasAdminScoring
    ) => {
        if (!evaluation) {
            return [];
        }

        const {
            evaluators,
            hasConsensusEvaluation,
            isLineItemAward,
            isLowestPrice,
            status,
            type: evaluationType,
        } = evaluation;

        const isScoredEvaluation = evaluationType === SCORED;
        const isReleased = status === RELEASED || status === COMPLETE;
        const isComplete = status === COMPLETE;
        const isUserAnEvaluator = evaluators.some((evaluator) => evaluator.id === user.id);
        const evaluatorsPath = '/evaluators';
        const proposalsPath = '/proposals';
        const proposalEvalPath = '/proposal-evaluations';
        const aggregatePath = '/aggregate-evaluations';
        const consensusPath = '/consensus-scorecard';

        const navItems = [
            {
                title: 'Project Documents',
                link: getEvalPathObj('/document'),
                active: !!pathname.match(new RegExp(`^${evaluationPath}/document`)),
            },
        ];

        if (isScoredEvaluation) {
            navItems.unshift({
                title: 'Evaluation Overview',
                link: getEvalPathObj(),
                indexRoute: true,
                active: !!pathname.match(
                    new RegExp(`^${evaluationPath}/(addenda|q-and-a)|^${evaluationPath}$`)
                ),
            });
        }
        if (isViewer || isComplete) {
            navItems.push({
                title: 'Responses',
                link: getEvalPathObj(proposalsPath),
                active: !!pathname.match(new RegExp(`^${evaluationPath}${proposalsPath}`)),
            });
        }
        if (isScoredEvaluation && (isViewer || isComplete)) {
            navItems.push({
                title: 'Evaluators',
                link: getEvalPathObj(evaluatorsPath),
                active: !!pathname.match(new RegExp(`^${evaluationPath}${evaluatorsPath}`)),
            });
        }
        if (isScoredEvaluation && hasAdminScoring && ((isReleased && isEditor) || isComplete)) {
            navItems.push({
                title: 'Admin Scores',
                link: getEvalPathObj('/admin-scorecard'),
            });
        }
        if (isScoredEvaluation && isUserAnEvaluator && isReleased) {
            navItems.push({
                title: 'My Evaluations',
                link: getEvalPathObj(proposalEvalPath),
                active: !!pathname.match(new RegExp(`^${evaluationPath}${proposalEvalPath}`)),
            });
        }
        if (isScoredEvaluation && ((isReleased && isEditor) || isComplete)) {
            navItems.push({
                title: 'Aggregate Evaluations',
                link: getEvalPathObj(aggregatePath),
                active: !!pathname.match(new RegExp(`^${evaluationPath}${aggregatePath}`)),
            });
        }
        if (isLowestPrice && (isViewer || isComplete)) {
            navItems.push({
                title: 'Bid Tabulations',
                link: getEvalPathObj('/bid-tabulations'),
            });
        }
        if (isLineItemAward && (isViewer || isComplete)) {
            navItems.push({
                title: 'Line Item Awards',
                link: getEvalPathObj('/line-item-award'),
            });
            navItems.push({
                title: 'Awards by Vendor',
                link: getEvalPathObj('/line-item-award/vendors'),
            });
        }
        if (hasConsensusEvaluation && (isReleased || isComplete)) {
            navItems.push({
                title: 'Consensus Scorecard',
                link: getEvalPathObj(consensusPath),
                active: !!pathname.match(new RegExp(`^${evaluationPath}${consensusPath}`)),
            });
        }
        if (isComplete) {
            navItems.push({
                title: 'Selected Vendor(s)',
                link: getEvalPathObj('/selected-proposal'),
            });
        }

        return navItems;
    }
);

export const getEvaluationPhaseSelectOptions = createSelector(
    [getEvaluationProjectJS],
    (project) => {
        if (!project) {
            return [];
        }

        const {
            evaluation: { phaseNumber },
            evaluationAudits,
        } = project;

        return [
            {
                label: `Phase ${phaseNumber} (current)`,
                value: phaseNumber,
            },
        ].concat(
            evaluationAudits.map((evaluationAudit) => {
                const auditPhaseNumber = evaluationAudit.data.evaluation.phaseNumber;
                return {
                    label: `Phase ${auditPhaseNumber}`,
                    value: auditPhaseNumber,
                };
            })
        );
    }
);

export const getSelectedEvaluationPhase = createSelector(
    [getEvaluationProjectJS, getEvaluationPhaseSelectOptions, getQueryPhase],
    (project, evaluationPhaseSelectOptions, queryPhase) => {
        if (!project) {
            return undefined;
        }

        const {
            evaluation: { phaseNumber },
        } = project;

        if (!queryPhase) {
            return phaseNumber;
        }

        const phase = evaluationPhaseSelectOptions.find((opt) => opt.value === queryPhase);
        return phase ? phase.value : phaseNumber;
    }
);

export const getEvaluationModalProposal = createSelector(
    [getEvaluationModalData, getEvaluationProjectJS],
    (modalData, project) => {
        if (modalData && project) {
            const { proposalId, userId } = modalData.toJS();

            const proposal = project.scoredProposals.find((prop) => prop.id === proposalId);

            const proposalEvaluation = userId
                ? proposal.proposalEvaluations.find((propEval) => propEval.user_id === userId)
                : proposal.consensusEvaluation;

            return {
                proposalEvaluation,
                ...proposal,
            };
        }
    }
);

export const isConsensusScorecardPage = createSelector(
    [getPathname],
    (pathname) => !!pathname.match('/consensus-scorecard')
);

export const isEvaluatorScorecardReadOnly = createSelector(
    [isViewingPastPhase, getCurrentEvaluation],
    (isPastPhase, evaluation) => {
        return isPastPhase || (evaluation && evaluation.status === COMPLETE);
    }
);
