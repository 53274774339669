import { gql } from 'graphql-request';

export const glOpenFiscalPeriods = gql`
    query {
        glFiscalPeriods(is_year_open: true, is_period_open: true) {
            name
            year
            description
            calendar_month
            fiscal_period_code
        }
    }
`;

export const glLookupAccount = (graphQLQueryFields) => gql`
  query(
    $fund_no:String,
    $account_object:String,
    $department_no:String,
    $project_no:String,
    $project_category:String,
    $program_no:String,
    $account_user1:String,
    $account_user2:String,
    $account_user3:String,
    $account_compressed:String,
    $account_compressed_partial:String) {
      searchDistinctAccountSegments(
        fund_no:$fund_no,
        account_object:$account_object,
        department_no:$department_no,
        project_no:$project_no,
        project_category:$project_category,
        program_no:$program_no,
        account_user1:$account_user1,
        account_user2:$account_user2,
        account_user3:$account_user3,
        account_compressed:$account_compressed,
        status: ACTIVE,
        account_compressed_partial:$account_compressed_partial) {
          ${
              graphQLQueryFields.includes('account_compressed')
                  ? 'account_pseudo_key, description, account_compressed_formatted, is_expense_account, account_type'
                  : ''
          }
          ${graphQLQueryFields}
        }
   }
`;

export const glCoaSettings = gql`
    query {
        glCoaSettings {
            id
            segment_def_label
            segment_def_value
            segment_name
            segment_separator
            segment_size
            short_label
        }
    }
`;

/* allow_ordering is used to indicate that you're allowed to order from a vendor.
 * Instead of a boolean the options are YES and NO. This is hard coded to "YES" to
 * ensure that users are always ordering from a valid vendor.
 */
export const searchVendors = gql`
    query SearchVendors($search: String!) {
        vendors(allow_ordering: YES, search: $search) {
            data {
                vendor_name
                vendor_number
                default_email_address
                vendor_addresses {
                    entity_address_1
                    entity_address_2
                    entity_city
                    entity_long_state
                    entity_zip_11
                }
                payment_terms {
                    description
                    payment_terms_code
                }
            }
        }
    }
`;

export const vendorSelection = gql`
    query {
        paymentTerms {
            description
            payment_terms_code
        }
        accountsPayableSettings {
            default_delivery_location_code
        }
    }
`;
export const getDeliveryCodesPage = (oneIndexedPageNumber) => gql`
    query {
        deliveryCodes(first: 20, page: ${oneIndexedPageNumber} orderBy: { column: DELIVERY_LOCATION_CODE, order: ASC }) {
            paginatorInfo {
                hasMorePages
            }
            data {
                entity_name
                delivery_location_code
            }
        }
    }
`;
export const glAccountBudgetSummary = gql`
    query ($accountPseudoKey: Int!, $fiscalPeriodYear: String!) {
        glAccountBudgetSummary(
            account_pseudo_key: $accountPseudoKey
            fiscal_year: $fiscalPeriodYear
        ) {
            revised_budget_amount
            account_group_revised_budget_amount
            transaction_amount
            account_group_transaction_amount
            encumbrance_amount
            account_group_encumbrance_amount
            req_preencumbrance_amount
            account_group_req_preencumbrance_amount
            available_budget
            account_group_available_budget
            available_budget_with_req
            account_group_available_budget_with_req
            account_group_name
            account {
                account_compressed
            }
        }
    }
`;

export const paymentTerms = gql`
    query {
        paymentTerms {
            description
            payment_terms_code
        }
    }
`;

export const glSearchAccountByPseudoKey = gql`
    query ($accountPseudoKey: Int) {
        glAccountsSearch(account_pseudo_key: $accountPseudoKey) {
            edges {
                node {
                    account_pseudo_key
                    description
                    account_compressed
                    account_compressed_formatted
                    is_expense_account
                }
            }
        }
    }
`;
