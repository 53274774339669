import { flatten, get, isNumber, sortBy, truncate } from 'lodash';
import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

import { fieldNames, form as purchaseOrderForm } from './constants';
import {
    getBudgetAllocations,
    getContractBudgetJS,
    getContractLineItemsJS,
    getPurchaseOrders,
} from '../selectors';
import { getFiscalYearTagsJS } from '../../../selectors';
import {
    getStatusText,
    purchaseOrderStatusTypes,
} from '../../../../../../shared_config/purchaseOrders';

const { CONTRACT_PARTY_ID, PRICE_ITEM_ID, TAG_ID } = fieldNames;

export const NO_LINE_ITEM = -1;

const getTagIdFormValue = (state) => formValueSelector(purchaseOrderForm)(state, TAG_ID);
const getContractPartyId = (state) =>
    formValueSelector(purchaseOrderForm)(state, CONTRACT_PARTY_ID);
const getSelectedLineItemId = (state) => formValueSelector(purchaseOrderForm)(state, PRICE_ITEM_ID);
export const getSelectedContractPartyId = (state) =>
    formValueSelector(purchaseOrderForm)(state, CONTRACT_PARTY_ID);

const getCurrentPurchaseOrder = (purchaseOrder, priceItem) => {
    if (purchaseOrder) {
        return priceItem.purchaseOrders.find((po) => po.id === purchaseOrder.id);
    }

    return undefined;
};

const getPriceItemDiscount = (purchaseOrder, vendorPriceItem) => {
    if (purchaseOrder) {
        return purchaseOrder.purchaseOrderItem.discount;
    }

    if (vendorPriceItem) {
        return vendorPriceItem.discount;
    }

    return 0;
};

const getPriceItemQuantity = (priceItem, vendorPriceItem) => {
    if (vendorPriceItem && isNumber(vendorPriceItem.quantity)) {
        return vendorPriceItem.quantity;
    }
    return priceItem.quantity || 0;
};

const getPriceItemQuantityRequested = (purchaseOrder) => {
    if (purchaseOrder) {
        return purchaseOrder.purchaseOrderItem.quantity;
    }

    return 0;
};

const getPriceItemUnitPrice = (purchaseOrder, vendorPriceItem) => {
    if (purchaseOrder) {
        return purchaseOrder.purchaseOrderItem.unitPrice;
    }

    if (vendorPriceItem) {
        return vendorPriceItem.unitPrice;
    }

    return 0;
};

const getMatchingContract = (contracts, contractPartyId) => {
    if (contracts) {
        return contracts.find((contract) => contract.contractParty.id === contractPartyId);
    }

    return undefined;
};

const getVendorPriceItem = (contract, priceItem) => {
    return priceItem.vendorPriceItems.find((vendorItem) => {
        const vendorId = get(vendorItem, ['proposal', 'vendor', 'id']);
        return contract && contract.contractParty.vendor_id === vendorId;
    });
};

const getTotalQuantityPurchased = (purchaseOrders, priceItemId) => {
    return purchaseOrders.reduce((acc, currentPurchaseOrder) => {
        return (
            acc +
            currentPurchaseOrder.priceItems.reduce((priceItemAcc, currentPriceItem) => {
                if (currentPriceItem.id === priceItemId) {
                    return priceItemAcc + currentPriceItem.purchaseOrderItem.quantity;
                }

                return priceItemAcc;
            }, 0)
        );
    }, 0);
};

const filterLineItemsNotAwardedToSelectedVendor = (contract, contractPartyId, priceItem) => {
    const matchingContract = getMatchingContract(contract, contractPartyId);
    const vendorPriceItem = getVendorPriceItem(matchingContract, priceItem);

    return vendorPriceItem && !!vendorPriceItem.lineItemAward;
};

const buildPriceItem = (contract, contractPartyId, priceItem, purchaseOrder, purchaseOrders) => {
    const matchingContract = getMatchingContract(contract, contractPartyId);
    const vendorPriceItem = getVendorPriceItem(matchingContract, priceItem);
    const currentPurchaseOrder = getCurrentPurchaseOrder(purchaseOrder, priceItem);
    const purchaseOrderValues = {
        quantity: getPriceItemQuantity(priceItem, vendorPriceItem),
        discount: getPriceItemDiscount(currentPurchaseOrder, vendorPriceItem),
        quantityRequested: getPriceItemQuantityRequested(currentPurchaseOrder),
        totalQuantityPurchased: getTotalQuantityPurchased(purchaseOrders, priceItem.id),
        unitPrice: getPriceItemUnitPrice(currentPurchaseOrder, vendorPriceItem),
    };

    // has to run after the other values have been established
    purchaseOrderValues.availableQuantity =
        purchaseOrderValues.quantity - purchaseOrderValues.totalQuantityPurchased;

    return {
        ...priceItem,
        ...purchaseOrderValues,
    };
};

export const purchaseOrderStatusOptions = purchaseOrderStatusTypes.map((status) => {
    return {
        label: getStatusText(status),
        value: status,
    };
});

export const getContractPartySelectOptions = createSelector(
    [getContractBudgetJS],
    (contractBudget) => {
        return (get(contractBudget, 'contracts') || []).map((contractBudgetContract) => {
            return {
                label: contractBudgetContract.contractParty.companyName,
                value: contractBudgetContract.contractParty.id,
            };
        });
    }
);

export const getContractEvaluations = createSelector(
    [getContractLineItemsJS],
    (contractLineItems) => contractLineItems.map(({ evaluation }) => evaluation)
);

export const getBudgetPriceTables = createSelector([getContractLineItemsJS], (lineItemInfo) =>
    lineItemInfo.reduce((items, lineItem) => [...items, ...lineItem.priceTables], [])
);

export const getPurchaseOrderPriceTables = createSelector(
    [
        getContractLineItemsJS,
        getSelectedContractPartyId,
        getContractBudgetJS,
        getPurchaseOrders,
        getContractEvaluations,
        (state, purchaseOrder) => purchaseOrder,
    ],
    (lineItemInfo, contractPartyId, budget, purchaseOrders, evaluations, purchaseOrder) => {
        const priceTables = lineItemInfo.reduce(
            (items, lineItem) => [...items, ...lineItem.priceTables],
            []
        );

        // lift purchaseOrderItems into priceItem object
        return priceTables.map((priceTable) => {
            return {
                ...priceTable,
                hasSalesTaxRow: priceTable.priceItems.some((priceItem) => priceItem.taxable),
                priceItems: priceTable.priceItems
                    .filter((priceItem) => {
                        const isLineItemAward = get(evaluations, [0, 'isLineItemAward']);

                        if (isLineItemAward) {
                            return filterLineItemsNotAwardedToSelectedVendor(
                                budget.contracts,
                                contractPartyId,
                                priceItem
                            );
                        }

                        return true;
                    })
                    .map((priceItem) => {
                        return buildPriceItem(
                            budget.contracts,
                            contractPartyId,
                            priceItem,
                            purchaseOrder,
                            purchaseOrders
                        );
                    }),
            };
        });
    }
);

export const getBudgetPriceItems = createSelector([getBudgetPriceTables], (priceTables) =>
    flatten(priceTables.map((priceTable) => priceTable.priceItems))
);

export const getPurchaseOrderPriceItems = createSelector([getBudgetPriceTables], (priceTables) =>
    flatten(priceTables.map((priceTable) => priceTable.priceItems))
);

export const getSelectedVendorPriceItem = createSelector(
    [getSelectedLineItemId, getSelectedContractPartyId, getBudgetPriceItems, getContractBudgetJS],
    (selectedLineItemId, contractPartyId, budgetPriceItems, budget) => {
        const selectedPriceItem = budgetPriceItems.find((item) => item.id === selectedLineItemId);
        if (selectedPriceItem) {
            const matchedContract = budget.contracts.find(
                (contract) => contract.contractParty.id === contractPartyId
            );

            return selectedPriceItem.vendorPriceItems.find((vendorItem) => {
                const vendorId = get(vendorItem, ['proposal', 'vendor', 'id']);
                return matchedContract && matchedContract.contractParty.vendor_id === vendorId;
            });
        }
    }
);

export const getLineItemSelectOptions = createSelector(
    [getBudgetPriceItems, getContractPartyId, getContractBudgetJS, getContractEvaluations],
    (priceItems, contractPartyId, contractBudget, evaluations) => {
        // Find selected contract party object based on contractPartyId
        const selectedContractParty = contractBudget.contracts
            .map((contract) => contract.contractParty)
            .find((contractParty) => contractParty.id === contractPartyId);

        // Find selected vendor ID based on contract party
        const vendorId = get(selectedContractParty, 'vendor_id');
        const isLineItemAward = get(evaluations, [0, 'isLineItemAward']);

        const availablePriceItems = priceItems.filter((priceItem) =>
            isLineItemAward
                ? // Filter price items by whether they've been awarded to the selected vendor
                  priceItem.vendorPriceItems.find(
                      (item) =>
                          item.lineItemAward &&
                          item.proposal.vendor &&
                          item.proposal.vendor.id === vendorId
                  )
                : // All line items are available to all vendors in a lowest cost award scenario
                  priceItem
        );

        const lineItemOptions = sortBy(availablePriceItems, ['orderById'], ['asc']).map((item) => {
            const description = truncate(item.description, { length: 75, separator: ' ' });
            return {
                value: item.id,
                label: `${item.lineItem} - ${description}`,
            };
        });

        return [
            {
                label: 'No line item',
                value: NO_LINE_ITEM,
            },
            ...lineItemOptions,
        ];
    }
);

export const getFormPurchaseOrders = createSelector(
    [getPurchaseOrders, getTagIdFormValue],
    (purchaseOrders, tagIdFormValue) => {
        return purchaseOrders.filter((purchaseOrder) => {
            return !!tagIdFormValue && tagIdFormValue === purchaseOrder.tag_id;
        });
    }
);

export const getFormBudgetAllocation = createSelector(
    [getBudgetAllocations, getTagIdFormValue],
    (budgetAllocations, tagIdFormValue) => {
        if (tagIdFormValue) {
            return budgetAllocations.find((budgetAllocation) => {
                return tagIdFormValue === budgetAllocation.tag_id;
            });
        }
    }
);

export const getFormFiscalYearTag = createSelector(
    [getFiscalYearTagsJS, getTagIdFormValue],
    (fiscalYearTags, tagIdFormValue) => {
        return fiscalYearTags.find((fiscalYearTag) => fiscalYearTag.id === tagIdFormValue);
    }
);
