import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Checkbox } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { LoginEmailForm } from './LoginEmailForm';
import { EMAIL } from './LoginEmailForm/constants';
import { LoginPasswordForm } from './LoginPasswordForm';
import { USE_OG_PLATFORM } from '../../../constants';
import { PageTitle } from '../../../components';

export const LoginForm = ({ currentUserEmail, isSessionTimeout, onLogin }) => {
    const [loginStep, setLoginStep] = useState(1);
    const [loginEmail, setLoginEmail] = useState(isSessionTimeout ? currentUserEmail : '');
    const [usePlatform, setUsePlatform] = useState(!!USE_OG_PLATFORM);

    const loginEmailSuccessHandler = (email) => {
        setLoginEmail(email);
        setLoginStep((prevStep) => prevStep + 1);
    };
    const backStepHandler = () => setLoginStep((prevStep) => prevStep - 1);

    const form =
        isSessionTimeout || loginStep === 2 ? (
            <LoginPasswordForm
                backHandler={backStepHandler}
                email={loginEmail}
                initialValues={{ [EMAIL]: loginEmail }}
                isSessionTimeout={isSessionTimeout}
                onLogin={onLogin}
            />
        ) : (
            <LoginEmailForm
                initialValues={{ [EMAIL]: loginEmail }}
                onSuccess={loginEmailSuccessHandler}
                usePlatform={usePlatform}
            />
        );

    const styles = require('./index.scss');

    return (
        <>
            <PageTitle title="Login" />
            <h1 className={`${styles.title}`}>Login</h1>
            {form}
            <div className={styles.forgotPassword}>
                <Link className={`pseudoLink ${styles.forgotPassword}`} to="/reset-password">
                    Forgot Password?
                </Link>
                {(__DEV__ || __RDEV__ || __STAGING__ || __PERF__ || __INT__) && (
                    <Checkbox
                        checked={usePlatform}
                        onChange={(e) => setUsePlatform(e.target.checked)}
                        style={{ marginBottom: 0 }}
                    >
                        Login with Platform (In Testing <i className="fa fa-flask" />)
                    </Checkbox>
                )}
            </div>
        </>
    );
};

LoginForm.propTypes = {
    currentUserEmail: PropTypes.string,
    isSessionTimeout: PropTypes.bool,
    onLogin: PropTypes.func,
};
