import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { arrayPush, Field, FieldArray } from 'redux-form';

import { fieldNames } from './constants';
import {
    Attachable,
    AttachmentList,
    Button,
    DateTimePicker,
    HelpBlock,
    RichTextInput,
    ProjectOverviewFormSection,
} from '../..';
import { attachmentTypesDict } from '../../../../../shared_config/attachments';

const { OTHER, PROJECT_DOCUMENT } = attachmentTypesDict;

const {
    ATTACHMENTS,
    CONTRACTOR_SELECTED_DATE,
    PROPOSAL_DEADLINE,
    RAW_SUMMARY,
    RELEASE_PROJECT_DATE,
} = fieldNames;

const mapDispatchToProps = {
    arrayPush,
};

// @connect
class ConnectedProjectInformationForm extends Component {
    static propTypes = {
        arrayPush: PropTypes.func.isRequired,
        attachmentsHelpText: PropTypes.string,
        contractorSelectedDateValue: PropTypes.instanceOf(Date),
        form: PropTypes.string.isRequired,
        hideAttachments: PropTypes.bool,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        proposalDealineValue: PropTypes.instanceOf(Date),
        releaseProjectDateValue: PropTypes.instanceOf(Date),
        showDates: PropTypes.bool,
        showFormErrors: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        updating: PropTypes.bool,
    };

    static defaultProps = {
        attachmentsHelpText: undefined,
        hideAttachments: false,
        showDates: false,
        showFormErrors: false,
        tagOptions: undefined,
        templateVariableOptions: undefined,
        updating: false,
    };

    constructor(props) {
        super(props);
        this.state = { attachmentType: PROJECT_DOCUMENT };
    }

    get styles() {
        return require('./index.scss');
    }

    toggleAttachmentType = () => {
        this.setState((prevState) => ({
            attachmentType: prevState.attachmentType === OTHER ? PROJECT_DOCUMENT : OTHER,
        }));
    };

    uploadAttachment = (fileData) => {
        const { form } = this.props;
        const { attachment, signedData } = fileData;

        return this.props.arrayPush(form, ATTACHMENTS, {
            appendixId: attachment.appendixId,
            bucket: signedData.bucket,
            filename: signedData.filename,
            path: signedData.key,
            title: attachment.title,
            type: this.state.attachmentType,
            url: signedData.signedGetUrl,
        });
    };

    renderAttachmentBlock() {
        const { attachmentsHelpText, form, hideAttachments, project, updating } = this.props;

        const { attachmentType } = this.state;

        if (hideAttachments) {
            return null;
        }

        return (
            <div>
                <div className={`row ${this.styles.attachmentBlock}`}>
                    <div className="col-xs-12">
                        <div>
                            <b>Bid Documents (optional)</b>
                        </div>
                        {attachmentsHelpText && <HelpBlock>{attachmentsHelpText}</HelpBlock>}
                        <p>
                            <em>Upload Type:</em>
                            &nbsp;
                            {attachmentType === OTHER ? 'Attachments' : 'Project Documents'}
                            &nbsp;-&nbsp;
                            <Button
                                bsStyle="link"
                                onClick={this.toggleAttachmentType}
                                qaTag="projectInformationForm-switchTo"
                                zeroPadding
                            >
                                Switch to&nbsp;
                                {attachmentType === OTHER ? 'Project Documents' : 'Attachments'}
                            </Button>
                        </p>
                        <Attachable
                            disabled={updating}
                            form={form}
                            hideAppendixLetter={attachmentType === PROJECT_DOCUMENT}
                            onUploadComplete={this.uploadAttachment}
                            projectId={project.id}
                        />
                    </div>
                </div>
                <FieldArray
                    attachmentType={PROJECT_DOCUMENT}
                    component={AttachmentList}
                    disabled={updating}
                    form={form}
                    hideNoAttachments
                    label="Uploaded Project Documents:"
                    name={ATTACHMENTS}
                />
                <FieldArray
                    attachmentType={OTHER}
                    component={AttachmentList}
                    disabled={updating}
                    form={form}
                    hideNoAttachments
                    name={ATTACHMENTS}
                />
            </div>
        );
    }

    renderDates() {
        const {
            contractorSelectedDateValue,
            proposalDealineValue,
            releaseProjectDateValue,
            showDates,
            showFormErrors,
            updating,
        } = this.props;

        if (!showDates) {
            return null;
        }

        return (
            <div>
                <div className="row">
                    <div className="col-md-8">
                        <Field
                            component={DateTimePicker}
                            disabled={updating}
                            dropUp
                            horizontal
                            label="Release Project Date"
                            name={RELEASE_PROJECT_DATE}
                            qaTag="projectInformationForm-releaseProjectDate"
                            showValidation={showFormErrors}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <Field
                            component={DateTimePicker}
                            disabled={updating}
                            dropUp
                            horizontal
                            label="Response Deadline Date"
                            min={releaseProjectDateValue}
                            name={PROPOSAL_DEADLINE}
                            qaTag="projectInformationForm-proposalDeadline"
                            showValidation={showFormErrors}
                        />
                    </div>
                    {this.renderDateDifference(
                        releaseProjectDateValue,
                        proposalDealineValue,
                        'to collect proposals'
                    )}
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <Field
                            component={DateTimePicker}
                            disabled={updating}
                            dropUp
                            horizontal
                            label="Contractor Selection Date"
                            min={proposalDealineValue}
                            name={CONTRACTOR_SELECTED_DATE}
                            qaTag="projectInformationForm-contractorSelectedDate"
                            showValidation={showFormErrors}
                        />
                    </div>
                    {this.renderDateDifference(
                        proposalDealineValue,
                        contractorSelectedDateValue,
                        'to evaluate proposals'
                    )}
                </div>
            </div>
        );
    }

    renderDateDifference(date1, date2, text) {
        if (!date1 || !date2) return null;
        const days = moment(date2).diff(moment(date1), 'days');
        return (
            <div className={`text-muted hidden-xs hidden-sm ${this.styles.dateText}`}>
                <strong>{days} days</strong> {text}
            </div>
        );
    }

    render() {
        const { showFormErrors, tagOptions, templateVariableOptions, updating } = this.props;

        return (
            <>
                <ProjectOverviewFormSection
                    {...this.props}
                    disabled={updating}
                    hasCategories
                    showProcurementContact
                />
                <Field
                    component={RichTextInput}
                    disabled={updating}
                    label="Project Summary"
                    name={RAW_SUMMARY}
                    placeholder="Enter Text"
                    qaTag="projectInformationForm-projectSummary"
                    showValidation={showFormErrors}
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                    toolbarPlacement="bottom"
                />
                {this.renderDates()}
                {this.renderAttachmentBlock()}
            </>
        );
    }
}

export const ProjectInformationForm = connect(
    null,
    mapDispatchToProps
)(ConnectedProjectInformationForm);
