import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Outlet } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { getDocumentsTabName } from './selectors';
import { ApprovalsModal } from '..';
import {
    getApprovalsListJS,
    getDashboardPath,
    getIntakeDashboardPath,
    getIntakePath,
    getReviewPath,
    getReviewProjectJS,
    isBuilderDraftPage,
    isBuilderEditor,
    isBuilderOwner,
    isGlobalEditorForProject,
    isIntakeEditor,
    isIntakeOwner,
    isSubscribed,
} from '../selectors';
import connectData from '../../ConnectData';
import { getUserOrganizationTimezone } from '../../selectors';
import {
    loadApprovals,
    shouldLoadApprovals,
    showModal as showApprovalsModalHandler,
} from '../../../actions/approvals';
import { menuActionHandler as menuAction } from '../../../actions/govProjects';
import { shouldShowComments } from '../../../actions/govComments';
import { ConnectedClients, LoadingSpinner, LoadingError, TourButton } from '../../../components';
import { ProjectNavbar, ApprovalsFooter } from '../../../components/GovApp';
import { getUserApprovalInActiveReviewStep } from '../../../components/GovApp/Approvals/ApprovalsDashboard/ApprovalList/selectors';
import { projectStatusesDict } from '../../../../../shared_config/projects';
import { isPurchase, scrollToHashAnchorElement } from '../../../helpers';

const { REQUEST_DRAFT, REVIEW } = projectStatusesDict;

function fetchData(getState, dispatch, location, params) {
    const projectId = Number.parseInt(params.projectId, 10);
    if (shouldLoadApprovals(getState())) {
        return dispatch(loadApprovals(projectId));
    }
    return Promise.resolve();
}

const mapStateToProps = (state, props) => {
    const { isIntake } = props;
    const showTourButton = isIntake ? false : isBuilderDraftPage(state, props);

    return {
        documentsTabName: getDocumentsTabName(state),
        hasApprovals: getApprovalsListJS(state).length > 0,
        isGlobalEditor: isGlobalEditorForProject(state),
        loadApproversError: state.approvals.get('loadError'),
        loadCommentsError: state.govComments.get('loadError'),
        loadingApprovers: state.approvals.get('loading'),
        loadingComments: state.govComments.get('loading'),
        project: getReviewProjectJS(state),
        showTourButton,
        subscribed: isSubscribed(state),
        showComments: shouldShowComments(state),
        timezone: getUserOrganizationTimezone(state),
    };
};

const mapDispatchToProps = {
    menuActionHandler: menuAction,
    showApprovalsModal: showApprovalsModalHandler,
};

// @connectData
// @connect
class ConnectedProjectNav extends Component {
    static propTypes = {
        dashboardPath: PropTypes.string.isRequired,
        documentsTabName: PropTypes.string.isRequired,
        hasApprovals: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        isGlobalEditor: PropTypes.bool.isRequired,
        isOwner: PropTypes.bool.isRequired,
        isIntake: PropTypes.bool,
        loadApproversError: PropTypes.string,
        loadCommentsError: PropTypes.string,
        loadingApprovers: PropTypes.bool.isRequired,
        loadingComments: PropTypes.bool.isRequired,
        location: PropTypes.object.isRequired,
        menuActionHandler: PropTypes.func.isRequired,
        params: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
        project: PropTypes.shape({
            isEvaluationOnly: PropTypes.bool.isRequired,
            isLibrary: PropTypes.bool.isRequired,
            isPostOnly: PropTypes.bool.isRequired,
            status: PropTypes.string.isRequired,
            upfrontQuestions: PropTypes.array.isRequired,
            projectSections: PropTypes.array.isRequired,
            type: PropTypes.string,
        }),
        projectNavPath: PropTypes.string.isRequired,
        showApprovalsModal: PropTypes.func.isRequired,
        showComments: PropTypes.bool.isRequired,
        showTourButton: PropTypes.bool.isRequired,
        subscribed: PropTypes.bool,
        timezone: PropTypes.string.isRequired,
        userApprovalInActiveReviewStep: PropTypes.shape({
            status: PropTypes.string.isRequired,
        }),
    };

    componentDidMount() {
        window.scroll(0, 0);
    }

    componentDidUpdate() {
        if (this.props.location.hash) {
            scrollToHashAnchorElement(this.props.location.hash);
        }
    }

    get styles() {
        return require('./ProjectNav.scss');
    }

    menuActionHandler = (type) => {
        return this.props.menuActionHandler(type, this.props.project);
    };

    get navItems() {
        const {
            documentsTabName,
            hasApprovals,
            isIntake,
            location: { pathname },
            project,
            project: { isEvaluationOnly, isLibrary, isPostOnly, status, upfrontQuestions },
            projectNavPath,
            showComments,
        } = this.props;

        const displayOverviewTab = isPurchase(project.type);

        const navItems = [
            {
                title: documentsTabName,
                link: `${projectNavPath}/document`,
                indexRoute: !displayOverviewTab,
                className: 'project-documents',
            },
        ];

        if (displayOverviewTab) {
            navItems.unshift({
                title: 'Overview',
                link: projectNavPath,
                indexRoute: true,
            });
        }

        // Only show project documents for library, post only and evaluation only projects
        if (isLibrary || isPostOnly || isEvaluationOnly) {
            return navItems;
        }

        if (upfrontQuestions.length > 0) {
            navItems.push({
                title: 'Document Setup',
                link: `${projectNavPath}/setup`,
                className: 'document-setup',
            });
        }

        navItems.push({
            title: 'Attachments',
            link: `${projectNavPath}/attachments`,
            className: 'attachments',
        });

        if (!isIntake) {
            navItems.push({
                title: 'Approvals',
                link: `${projectNavPath}/approvals`,
                className: 'approvals-dashboard',
            });
        }

        if (isIntake && (status !== REQUEST_DRAFT || hasApprovals)) {
            navItems.push({
                title: 'Process Request',
                link: `${projectNavPath}/approvals`,
                className: 'process-request',
            });
        }

        if (showComments) {
            navItems.push({
                title: 'All Comments',
                link: `${projectNavPath}/comments`,
                className: 'all-comments',
            });
        }

        const revisionsPath = `${projectNavPath}/revisions`;
        navItems.push({
            title: 'Revision History',
            link: `${revisionsPath}/versions`,
            active: !!pathname.match(new RegExp(`^${revisionsPath}`)),
            className: 'revision-history',
        });

        return navItems;
    }

    renderTourButton() {
        return (
            <TourButton
                className={`tutorial-btn ${this.styles.tourButton}`}
                qaTag="projectDashboard-btn-tour"
                text="Show Tutorial"
            />
        );
    }

    render() {
        const {
            dashboardPath,
            showTourButton,
            isEditor,
            isGlobalEditor,
            isIntake,
            isOwner,
            loadApproversError,
            loadCommentsError,
            loadingApprovers,
            loadingComments,
            project,
            showApprovalsModal,
            showComments,
            subscribed,
            timezone,
            userApprovalInActiveReviewStep,
        } = this.props;

        if (loadingComments || loadingApprovers) {
            return <LoadingSpinner centered />;
        }

        if (loadCommentsError || loadApproversError || !project) {
            return <LoadingError error={loadCommentsError} />;
        }

        return (
            <div>
                <div className={this.styles.tourButtonContainer}>
                    <ConnectedClients />
                    {showTourButton && this.renderTourButton()}
                </div>
                <ProjectNavbar
                    actionHandler={this.menuActionHandler}
                    dashboardPath={dashboardPath}
                    isBuilder={!isIntake}
                    isEditor={isEditor}
                    isGlobalEditor={isGlobalEditor}
                    isIntake={isIntake}
                    isOwner={isOwner}
                    navItems={this.navItems}
                    project={project}
                    showComments={showComments}
                    subscribed={subscribed}
                    timezone={timezone}
                >
                    <Outlet />
                </ProjectNavbar>
                {project.status === REVIEW && userApprovalInActiveReviewStep && (
                    <ApprovalsFooter
                        approvalStatus={userApprovalInActiveReviewStep.status}
                        showApprovalsModal={(type) =>
                            showApprovalsModal(type, userApprovalInActiveReviewStep)
                        }
                    />
                )}
                <ApprovalsModal />
            </div>
        );
    }
}

export const ProjectNav = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProjectNav);

const ProjectIntakeNav = (props) => {
    return <ProjectNav isIntake {...props} />;
};
ProjectNav.Intake = compose(
    connectData(fetchData),
    withRouter,
    connect((state, props) => {
        return {
            dashboardPath: getIntakeDashboardPath(state, props),
            isEditor: isIntakeEditor(state),
            isOwner: isIntakeOwner(state),
            projectNavPath: getIntakePath(state, props),
        };
    })
)(ProjectIntakeNav);

const ProjectBuilderNav = (props) => {
    return <ProjectNav {...props} />;
};
ProjectNav.Builder = compose(
    connectData(fetchData),
    withRouter,
    connect((state, props) => {
        return {
            dashboardPath: getDashboardPath(state, props),
            isEditor: isBuilderEditor(state),
            isOwner: isBuilderOwner(state),
            projectNavPath: getReviewPath(state, props),
            userApprovalInActiveReviewStep: getUserApprovalInActiveReviewStep(state),
        };
    })
)(ProjectBuilderNav);
