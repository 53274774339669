import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { PROCURATED_LOGO_URL } from '../constants';
import { Link, ProcuratedStarsDisplay } from '../..';
import { loadProcuratedRating } from '../../../actions/ratings';
import { getProcuratedVendorJS } from '../../../containers/selectors';
import { USE_PROCURATED } from '../../../../../shared_config/procurated';

export const ProcuratedBox = ({ isLarge, supplierId, vendorSelfManagement }) => {
    const dispatch = useDispatch();
    const procuratedVendor = useSelector(getProcuratedVendorJS);
    const vendorFound = procuratedVendor && procuratedVendor.matched;
    const styles = require('./index.scss');

    const procuratedBoxStyle = isLarge ? styles.procuratedBox : styles.smallProcuratedBox;
    const hasRating = vendorFound && procuratedVendor.rating;
    const ratingText = hasRating
        ? `${procuratedVendor.rating} out of 5`
        : 'No public-sector reviews';
    const ratingTextStyle = hasRating ? styles.ratingText : styles.mutedRatingText;
    const starSizeMultiplier = isLarge ? 5 : 4;

    useEffect(() => {
        if (USE_PROCURATED && supplierId) {
            dispatch(loadProcuratedRating({ supplierId }));
        }
    }, [supplierId]);

    if (!USE_PROCURATED || !procuratedVendor) {
        return null;
    }

    const renderManageButton = () => {
        return (
            <Link
                className={`btn btn-primary ${styles.manageButton}`}
                href={procuratedVendor && procuratedVendor.selfViewUrl}
                qaTag="procuratedBox-manageOrCreateProfile"
                referrerPolicy="strict-origin"
                target="_blank"
            >
                {vendorFound ? 'Manage Your Profile' : 'Create Your Supplier Profile'}
            </Link>
        );
    };

    const renderContactProcurated = () => {
        return (
            <div className={styles.contactProcurated} key="contactProcurated">
                <div className={styles.muted}>OpenGov users are seeing your rating&nbsp;</div>
                <Link
                    href={procuratedVendor && procuratedVendor.contactSalesUrl}
                    referrerPolicy="strict-origin"
                    target="_blank"
                >
                    <u>Contact Procurated to enhance your profile</u>
                </Link>
            </div>
        );
    };

    const renderReadWriteButtons = () => {
        return (
            <div className={styles.buttonBox} key="readWriteButtons">
                <Link
                    className={`btn btn-primary ${styles.buttonLeft}`}
                    href={procuratedVendor && procuratedVendor.profileUrl}
                    qaTag="procuratedBox-readReviews"
                    referrerPolicy="strict-origin"
                    target="_blank"
                >
                    {procuratedVendor && procuratedVendor.reviewCount > 0
                        ? 'Read Reviews '
                        : 'View Profile '}
                    <i className="fa fa-external-link" />
                </Link>
                <Link
                    bsStyle="primary"
                    className={`btn btn-primary-outline ${styles.buttonRight}`}
                    href={procuratedVendor && procuratedVendor.writeReviewUrl}
                    qaTag="procuratedBox-writeAReviewSecondary"
                    referrerPolicy="strict-origin"
                    target="_blank"
                >
                    Write a Review&nbsp;
                    <i className="fa fa-external-link" />
                </Link>
            </div>
        );
    };

    const renderWriteAReview = () => {
        return (
            <div key="writeAReview">
                <Link
                    className={`btn btn-primary ${styles.manageButton}`}
                    href={procuratedVendor.writeReviewUrl}
                    qaTag="procuratedBox-writeAReview"
                    referrerPolicy="strict-origin"
                    target="_blank"
                >
                    Write a Review&nbsp;
                    <i className="fa fa-external-link" />
                </Link>
            </div>
        );
    };

    /**
     * Determines which buttons to show based on the configuration
     * @returns {Element[]} Array of functions that return button components
     */
    const renderButtons = () => {
        const buttons = [];
        if (vendorSelfManagement) {
            buttons.push(renderManageButton);
            if (vendorFound && procuratedVendor.claimedStatus !== 'pro') {
                buttons.push(renderContactProcurated);
            }
        } else if (vendorFound) {
            buttons.push(renderReadWriteButtons);
        } else {
            buttons.push(renderWriteAReview);
        }

        return buttons.map((buttonFn) => buttonFn());
    };

    return (
        <div
            className={procuratedBoxStyle}
            style={!hasRating ? { height: 'fit-content' } : undefined}
        >
            <div className={styles.starsDisplayContainer}>
                <ProcuratedStarsDisplay
                    starSizeMultiplier={starSizeMultiplier}
                    value={procuratedVendor && procuratedVendor.rating}
                />
                <div className={ratingTextStyle}>{ratingText}</div>
            </div>
            {!!vendorFound && (
                <div>{procuratedVendor.reviewCount} ratings & reviews from the public-sector</div>
            )}
            {renderButtons()}
            <div className={styles.logoContainer}>
                <span>Powered by </span>
                <img alt="Procurated Logo" className={`${styles.logo}`} src={PROCURATED_LOGO_URL} />
            </div>
        </div>
    );
};

ProcuratedBox.propTypes = {
    isLarge: PropTypes.bool,
    supplierId: PropTypes.number.isRequired,
    vendorSelfManagement: PropTypes.bool,
};
