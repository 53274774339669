import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Accordion, AccordionDetails, buildQaTag } from '@og-pro/ui';

import { getPriceItemExtendedPrice } from '../../../../../../../../../shared_config/priceTables/requisitionUtils';
import { LineItemSummary } from '../../../FormComponents/PurchaseDetails/PriceItem/LineItemSummary';
import { AccountSplitReview } from './AccountSplit';
import {
    getGovernmentReqSetting,
    getGovernmentSalesTax,
    getIsRequisitionUsingFMS,
} from '../../../../../../../selectors/govApp';
import { showAccountInformationOptionsValues } from '../../../../../../../../../shared_config/requisitions';

export const ReviewPriceItem = ({ expanded, handleExpand, fields, index, ...props }) => {
    const styles = require('./index.scss');
    const hasFMS = useSelector(getIsRequisitionUsingFMS);

    const tax = useSelector(getGovernmentSalesTax);

    const priceItem = fields.get(index);
    const {
        custom2: internalNote,
        discount,
        discountType,
        quantity,
        unitPrice,
        taxable,
        attachments,
    } = priceItem;

    const extendedPrice = useMemo(
        () =>
            getPriceItemExtendedPrice(
                quantity,
                unitPrice,
                discount,
                discountType,
                taxable ? tax : null
            ),
        [priceItem]
    );

    const { showAccountInformation } = useSelector(getGovernmentReqSetting);

    return (
        <Accordion
            data-qa={buildQaTag(`requisitionCreate-priceItem${index}`, 'accordion')}
            expanded={expanded}
            onChange={handleExpand(index)}
        >
            <LineItemSummary
                extendedPrice={extendedPrice}
                index={index}
                lineItemNumber={index + 1}
                priceItem={priceItem}
                {...props}
            />
            <AccordionDetails className={styles.details}>
                {(showAccountInformation !== showAccountInformationOptionsValues.HIDDEN ||
                    hasFMS) && <AccountSplitReview priceItem={priceItem} {...props} />}
                {internalNote && (
                    <div className={styles.moreInformation}>
                        <h5>Internal Note</h5>
                        <div>{internalNote}</div>
                    </div>
                )}
                {attachments && attachments.length > 0 && (
                    <div className={classnames('col-xs-12', styles.attachmentSection)}>
                        <label>Attachments</label>
                        <div className={styles.attachmentList}>
                            {attachments.map((attachment) => {
                                return (
                                    <div className={styles.attachmentItem} key={attachment.url}>
                                        <a
                                            href={attachment.url}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            <i className="fa fa-file-text-o" />{' '}
                                            {attachment.title || attachment.filename}
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

ReviewPriceItem.propTypes = {
    expanded: PropTypes.bool,
    fields: PropTypes.shape({
        get: PropTypes.func.isRequired,
    }).isRequired,
    handleExpand: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    tax: PropTypes.number,
};
