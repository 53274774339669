import classnames from 'classnames';
import { round } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import { Box } from '@og-pro/ui';

import { criteriaFieldNames } from './constants';
import {
    Button,
    CDSButton,
    Checkbox,
    DragIcon,
    HelpIcon,
    InputText,
    RichTextInput,
    NumberWidget,
    ProgressBar,
    SearchSelect,
    RadioGroup,
} from '../../..';
import {
    isCostFormulaScoringCriterium,
    scoringMethodOptions,
} from '../../../../../../shared_config/evaluations';
import { ContentBlock } from '../../../SDv2/ContentBlock';
import { normalizeBooleans } from '../../../../helpers';

const {
    DESCRIPTION,
    IS_ADMIN_SCORED,
    IS_PUBLICLY_HIDDEN,
    RAW_DESCRIPTION,
    SCORING_METHOD,
    TITLE,
    WEIGHT,
} = criteriaFieldNames;

export class EvaluationCriteriaItem extends PureComponent {
    static propTypes = {
        arrayName: PropTypes.string.isRequired,
        change: PropTypes.func.isRequired,
        criteria: PropTypes.shape({
            orderById: PropTypes.number,
            scoringMethod: PropTypes.number,
        }).isRequired,
        disabled: PropTypes.bool,
        draggableProvided: PropTypes.object.isRequired,
        moveFieldDown: PropTypes.func,
        moveFieldUp: PropTypes.func,
        index: PropTypes.number.isRequired,
        insertField: PropTypes.func.isRequired,
        isEvaluationReleased: PropTypes.bool,
        isLastItem: PropTypes.bool,
        isLocked: PropTypes.bool,
        isOGThemeEnabledForComponents: PropTypes.bool,
        isMultiPhase: PropTypes.bool,
        isNew: PropTypes.bool.isRequired,
        isTemplate: PropTypes.bool,
        questionLogicIcon: PropTypes.node,
        remove: PropTypes.func.isRequired,
        showFormErrors: PropTypes.bool,
        totalWeight: PropTypes.number,
        useRawDescription: PropTypes.bool,
        weight: PropTypes.number,
    };

    static defaultProps = {
        disabled: false,
        showFormErrors: false,
        totalWeight: undefined,
        weight: 0,
    };

    constructor(props) {
        super();

        this.state = {
            advancedSettings: !!props.isTemplate,
        };
    }

    get styles() {
        if (this.props.isOGThemeEnabledForComponents) {
            return require('./EvaluationCriteriaSDv2Item.scss');
        }

        return require('./EvaluationCriteriaItem.scss');
    }

    get showWeight() {
        const { totalWeight } = this.props;

        return totalWeight || null;
    }

    toggleAdvancedSettings = () => {
        this.setState((s) => ({ advancedSettings: !s.advancedSettings }));
    };

    handleDeleteClick = () => {
        const { index, remove } = this.props;

        remove(index);
    };

    insertHandler = () => {
        const {
            insertField,
            criteria: { orderById },
        } = this.props;

        insertField(orderById);
    };

    normalizeWeightInput = (value) => {
        // Prevents value from having more than 2 decimals places
        if (value) {
            return Math.round(value * 100) / 100;
        }
        return value;
    };

    scoringMethodChange = (value) => {
        const { arrayName, change } = this.props;

        if (isCostFormulaScoringCriterium({ [SCORING_METHOD]: value })) {
            change(`${arrayName}.${IS_ADMIN_SCORED}`, true);
        }
    };

    renderDeleteButton() {
        const { disabled } = this.props;

        return (
            <Button
                aria-label="Remove Button"
                bsStyle="link"
                className={this.styles.removeBtn}
                disabled={disabled}
                onClick={this.handleDeleteClick}
                qaTag="evaluationCriteriaItem-deleteItem"
            >
                <i className="fa fa-times fa-2x text-danger" />
            </Button>
        );
    }

    renderInsertButton() {
        const { disabled } = this.props;

        return (
            <Button
                aria-label="Insert Button"
                bsStyle="link"
                className={this.styles.addBtn}
                disabled={disabled}
                onClick={this.insertHandler}
                qaTag="evaluationCriteriaItem-insertItem"
                tooltip="Insert after"
                tooltipPlacement="right"
            >
                <i className="fa fa-plus fa-2x" />
            </Button>
        );
    }

    render() {
        const {
            arrayName,
            criteria,
            disabled,
            draggableProvided,
            moveFieldDown,
            moveFieldUp,
            index,
            isEvaluationReleased,
            isLastItem,
            isLocked,
            isMultiPhase,
            isNew,
            isOGThemeEnabledForComponents,
            isTemplate,
            questionLogicIcon,
            showFormErrors,
            totalWeight,
            useRawDescription,
            weight,
        } = this.props;

        const descriptionField = useRawDescription
            ? `${arrayName}.${RAW_DESCRIPTION}`
            : `${arrayName}.${DESCRIPTION}`;
        const isReleasedAndExisting = !isNew && isEvaluationReleased;
        const isDisabled = disabled || isLocked || isReleasedAndExisting;
        const isAdminScoredDisabled = isCostFormulaScoringCriterium(criteria);
        const progressLabel =
            this.showWeight && weight ? `${round((weight / totalWeight) * 100, 1)}%` : null;

        if (isOGThemeEnabledForComponents) {
            return (
                <span ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                    <ContentBlock className={this.styles.contentBlock} withActions>
                        <ContentBlock.Main
                            hideInputsBorders={false}
                            p={3}
                            pb={this.state.advancedSettings ? 0 : 3}
                        >
                            <Box mb={3}>
                                <Field
                                    component={InputText}
                                    disabled={isDisabled}
                                    hasFeedback={false}
                                    label="Title"
                                    name={`${arrayName}.${TITLE}`}
                                    qaTag="evaluationCriteriaItem-title"
                                    showValidation={showFormErrors}
                                    type="text"
                                    useOpenGovStyle
                                />
                            </Box>
                            <Box mb={3}>
                                <Field
                                    component={RichTextInput}
                                    disabled={isDisabled}
                                    forcePaddedContent
                                    label="Description (optional)"
                                    minRows={1}
                                    name={descriptionField}
                                    showValidation={showFormErrors}
                                    useOpenGovStyle
                                    useSharedTextareaToolbar
                                />
                            </Box>
                            <Box display="flex" mb={3}>
                                <Box flex={1} mr={3}>
                                    <Field
                                        component={SearchSelect}
                                        disabled={isDisabled}
                                        label="Scoring Method"
                                        name={`${arrayName}.${SCORING_METHOD}`}
                                        onChange={this.scoringMethodChange}
                                        options={scoringMethodOptions}
                                        showValidation={showFormErrors}
                                        useOpenGovStyle
                                    />
                                </Box>
                                <Box flex={1}>
                                    <Field
                                        component={InputText}
                                        disabled={isDisabled}
                                        hasDecimal
                                        label="Weight (Points)"
                                        min={1}
                                        name={`${arrayName}.${WEIGHT}`}
                                        normalize={this.normalizeWeightInput}
                                        showValidation={showFormErrors}
                                        type="number"
                                        useOpenGovStyle
                                    />
                                </Box>
                            </Box>
                            <Box mb={3}>
                                <ProgressBar
                                    bsStyle="royal"
                                    footerLabel={`${weight} / ${totalWeight || 100} Points`}
                                    label={progressLabel}
                                    max={totalWeight}
                                    min={0}
                                    noBottomMargin
                                    now={weight}
                                    useOpenGovStyle
                                />
                            </Box>
                            <Box className={this.styles.advancedSettingsContainer} pt={2}>
                                {!isTemplate && (
                                    <Box>
                                        <CDSButton
                                            className={this.styles.advancedSettingsButton}
                                            onClick={() => this.toggleAdvancedSettings()}
                                            size="small"
                                            variant="text"
                                        >
                                            <i
                                                className={classnames('fa', {
                                                    'fa-chevron-right':
                                                        !this.state.advancedSettings,
                                                    'fa-chevron-down': this.state.advancedSettings,
                                                })}
                                            />{' '}
                                            {this.state.advancedSettings ? 'Hide' : 'Show'}&nbsp;
                                            Advanced Display and Scoring Settings
                                        </CDSButton>
                                    </Box>
                                )}

                                {this.state.advancedSettings && (
                                    <Box mt={isTemplate ? 0 : 3}>
                                        <Box mb={2}>
                                            <Field
                                                component={RadioGroup}
                                                defaultChecked={false}
                                                disabled={isDisabled}
                                                groupLabel=""
                                                inline
                                                label="Should the Scoring Method and Weight Display to Vendors?"
                                                name={`${arrayName}.${IS_PUBLICLY_HIDDEN}`}
                                                normalize={normalizeBooleans}
                                                options={[
                                                    {
                                                        name: 'Display Publicly',
                                                        value: false,
                                                        help: 'The scoring method and weight for this item will be visible on the vendor portal.',
                                                    },
                                                    {
                                                        name: 'Do Not Display Publicly',
                                                        value: true,
                                                        help: 'The scoring method and weight will not be shown on the vendor portal.',
                                                    },
                                                ]}
                                                qaTag="evaluationCriteriaItem-hideScoring"
                                                useOpenGovStyle
                                            />
                                        </Box>
                                        <Field
                                            component={RadioGroup}
                                            defaultChecked={false}
                                            disabled={isDisabled || isAdminScoredDisabled}
                                            groupLabel=""
                                            inline
                                            label="Who Should Score This Criteria?"
                                            name={`${arrayName}.${IS_ADMIN_SCORED}`}
                                            normalize={normalizeBooleans}
                                            options={[
                                                {
                                                    name: 'Evaluator Scoring',
                                                    value: false,
                                                    help: 'The score will be provided by each individual evaluator.',
                                                },
                                                {
                                                    name: 'Admin Scoring',
                                                    value: true,
                                                    help: 'The score will be provided by an admin and set to the same across all scorecards.',
                                                },
                                            ]}
                                            qaTag="evaluationCriteriaItem-adminScoring"
                                            useOpenGovStyle
                                        />
                                    </Box>
                                )}
                            </Box>
                        </ContentBlock.Main>
                        <ContentBlock.ActionSidebar>
                            {!isTemplate && (
                                <ContentBlock.ButtonGroup>
                                    {index !== 0 && moveFieldUp && (
                                        <ContentBlock.Button>
                                            <Button
                                                bsStyle="link"
                                                onClick={() => moveFieldUp(index)}
                                            >
                                                <i aria-hidden="true" className="fa fa-arrow-up" />
                                            </Button>
                                        </ContentBlock.Button>
                                    )}
                                    <ContentBlock.Button>
                                        <DragIcon
                                            dragHandleProps={draggableProvided.dragHandleProps}
                                        />
                                    </ContentBlock.Button>
                                    {!isLastItem && moveFieldDown && (
                                        <ContentBlock.Button>
                                            <Button
                                                bsStyle="link"
                                                onClick={() => moveFieldDown(index)}
                                            >
                                                <i
                                                    aria-hidden="true"
                                                    className="fa fa-arrow-down"
                                                />
                                            </Button>
                                        </ContentBlock.Button>
                                    )}
                                </ContentBlock.ButtonGroup>
                            )}

                            {!!questionLogicIcon && (
                                <ContentBlock.ButtonGroup>
                                    <ContentBlock.Button>{questionLogicIcon}</ContentBlock.Button>
                                    {
                                        /* when it's a template we should add the drag icon here
                                        because otherwise react dnd complains. And if we put it above it will show an
                                        empty button group and it breaks the ui */
                                        isTemplate && (
                                            <ContentBlock.Button>
                                                <DragIcon
                                                    disabled
                                                    dragHandleProps={
                                                        draggableProvided.dragHandleProps
                                                    }
                                                />
                                            </ContentBlock.Button>
                                        )
                                    }
                                </ContentBlock.ButtonGroup>
                            )}
                            {!isTemplate && (
                                <ContentBlock.ButtonGroup>
                                    <Button
                                        aria-label="Remove Button"
                                        bsStyle="link"
                                        disabled={disabled}
                                        onClick={this.handleDeleteClick}
                                        qaTag="evaluationCriteriaItem-deleteItem"
                                    >
                                        <i className="fa fa-trash" />
                                    </Button>
                                </ContentBlock.ButtonGroup>
                            )}
                        </ContentBlock.ActionSidebar>
                    </ContentBlock>
                </span>
            );
        }

        // NOTE: `ref` cannot be passed to `react-bootstrap` components at this time, so we must
        // manually set up the `<ListGroupItem>` component.
        return (
            <span
                className={classnames('list-group-item', isMultiPhase && this.styles.multiItem)}
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
            >
                <div className={this.styles.criteria}>
                    <div className="row">
                        <div className="col-xs-10 col-md-11">
                            <div className="row">
                                <div className="col-sm-8">
                                    <Field
                                        component={InputText}
                                        disabled={isDisabled}
                                        hasFeedback={false}
                                        label="Title"
                                        name={`${arrayName}.${TITLE}`}
                                        placeholder="Title"
                                        qaTag="evaluationCriteriaItem-title"
                                        showValidation={showFormErrors}
                                        type="text"
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Field
                                        component={SearchSelect}
                                        disabled={isDisabled}
                                        label="Scoring Method"
                                        name={`${arrayName}.${SCORING_METHOD}`}
                                        onChange={this.scoringMethodChange}
                                        options={scoringMethodOptions}
                                        qaTag="evaluationCriteriaItem-scoringMethod"
                                        showValidation={showFormErrors}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-8">
                                    <Field
                                        component={RichTextInput}
                                        disabled={isDisabled}
                                        label="Description (optional)"
                                        minRows={1}
                                        name={descriptionField}
                                        placeholder="Criteria description"
                                        qaTag="evaluationCriteriaItem-description"
                                        showValidation={showFormErrors}
                                        toolbarPlacement="bottom"
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Field
                                        component={NumberWidget}
                                        disabled={isDisabled}
                                        hasDecimal
                                        label="Weight (Points)"
                                        min={1}
                                        name={`${arrayName}.${WEIGHT}`}
                                        normalize={this.normalizeWeightInput}
                                        qaTag="evaluationCriteriaItem-weight"
                                        showValidation={showFormErrors}
                                    />
                                    <Field
                                        component={Checkbox}
                                        disabled={isDisabled || isAdminScoredDisabled}
                                        inline
                                        name={`${arrayName}.${IS_ADMIN_SCORED}`}
                                        qaTag="evaluationCriteriaItem-adminScoring"
                                        text={
                                            <>
                                                Admin scoring
                                                <HelpIcon
                                                    tooltip={
                                                        `${
                                                            isAdminScoredDisabled
                                                                ? 'NOTE: This scoring method requires admin scoring to be used. '
                                                                : ''
                                                        }` +
                                                        'Use this option when the scoring should be provided by an admin and set to the same score across all evaluator scorecards.'
                                                    }
                                                />
                                            </>
                                        }
                                    />
                                    <Field
                                        component={Checkbox}
                                        disabled={isDisabled}
                                        inline
                                        name={`${arrayName}.${IS_PUBLICLY_HIDDEN}`}
                                        qaTag="evaluationCriteriaItem-hideScoring"
                                        text={
                                            <>
                                                Hide scoring method & weight
                                                <HelpIcon tooltip="Select this option when you do not want to publicy display the scoring method and weight for this item" />
                                            </>
                                        }
                                    />
                                </div>
                            </div>
                            {this.showWeight && (
                                <div className="row">
                                    <div className="col-xs-8">
                                        <ProgressBar
                                            bsStyle="royal"
                                            label={progressLabel}
                                            max={totalWeight}
                                            min={0}
                                            now={weight}
                                        />
                                    </div>
                                    <div className="hidden-xs col-xs-4 text-muted">
                                        {weight} / {totalWeight} Points
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-xs-2 col-md-1 text-center">
                            <div className={this.styles.controlButtons}>
                                <div className={this.styles.editingControls}>
                                    {this.renderDeleteButton()}
                                    <DragIcon
                                        className={this.styles.dragIcon}
                                        disabled={disabled}
                                        dragHandleProps={draggableProvided.dragHandleProps}
                                    />
                                    {this.renderInsertButton()}
                                </div>
                                {!!questionLogicIcon && (
                                    <div className={this.styles.questionLogicIcon}>
                                        {questionLogicIcon}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {(isReleasedAndExisting || isLocked) && (
                    <div className="row">
                        <div className="col-xs-12 text-center text-info">
                            <i className="fa fa-info-circle" />
                            &nbsp;
                            {isReleasedAndExisting &&
                                'Evaluation scoring has begun, so criteria can no longer be modified'}
                            {isLocked &&
                                !isReleasedAndExisting &&
                                'Evaluation item was copied from the previous phase and cannot be modified'}
                        </div>
                    </div>
                )}
            </span>
        );
    }
}
