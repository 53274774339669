import { get } from 'lodash';
import { createSelector } from 'reselect';

import { getProjectJS } from './selectors';
import {
    getContractJS,
    getUserJS,
    isBidAmenderUser,
    isBidPosterUser,
    isContractAdminUser,
    isDepartmentBidAmenderUser,
    isDepartmentBidPosterUser,
    isDepartmentContractAdminUser,
    isDepartmentEditorUser,
    isGlobalEditorUser,
} from '../../selectors';
import { moduleTypesDict } from '../../../../../shared_config/modules';
import { projectStatusesDict } from '../../../../../shared_config/projects';

const { BUILDER, EVALUATION, INTAKE, SOURCING } = moduleTypesDict;

const { AWARD_PENDING, CLOSED, DRAFT, OPEN, POST_PENDING, REQUEST_REVIEW, REQUEST_DRAFT, REVIEW } =
    projectStatusesDict;

function permissionCheck(role) {
    return (permissions) => !!permissions[role];
}

const getModuleData = createSelector([getProjectJS], (project) => {
    return get(project, 'moduleData') || {};
});

const getIntakePermissions = createSelector([getModuleData], (moduleData) => {
    return get(moduleData, [INTAKE, 'permissions']) || {};
});

const getBuilderPermissions = createSelector([getModuleData], (moduleData) => {
    return get(moduleData, [BUILDER, 'permissions']) || {};
});

const getSourcingPermissions = createSelector([getModuleData], (moduleData) => {
    return get(moduleData, [SOURCING, 'permissions']) || {};
});

const getEvaluationPermissions = createSelector([getModuleData], (moduleData) => {
    return get(moduleData, [EVALUATION, 'permissions']) || {};
});

export const isIntakeOwner = createSelector([getIntakePermissions], permissionCheck('isOwner'));

export const isIntakeEditor = createSelector([getIntakePermissions], permissionCheck('isEditor'));

export const isIntakeViewer = createSelector([getIntakePermissions], permissionCheck('isViewer'));

export const isIntakePermitted = createSelector(
    [getIntakePermissions],
    permissionCheck('isPermitted')
);

export const isBuilderOwner = createSelector([getBuilderPermissions], permissionCheck('isOwner'));

export const isBuilderEditor = createSelector([getBuilderPermissions], permissionCheck('isEditor'));

export const isBuilderViewer = createSelector([getBuilderPermissions], permissionCheck('isViewer'));

export const isBuilderPermitted = createSelector(
    [getBuilderPermissions],
    permissionCheck('isPermitted')
);

export const isContractEditor = createSelector(
    [getContractJS],
    (contract) => !!(contract && contract.isEditor)
);

export const isContractViewer = createSelector(
    [getContractJS],
    (contract) => !!(contract && contract.isViewer)
);

export const isSourcingOwner = createSelector([getSourcingPermissions], permissionCheck('isOwner'));

export const isSourcingEditor = createSelector(
    [getSourcingPermissions],
    permissionCheck('isEditor')
);

export const isSourcingViewer = createSelector(
    [getSourcingPermissions],
    permissionCheck('isViewer')
);

export const isSourcingPermitted = createSelector(
    [getSourcingPermissions],
    permissionCheck('isPermitted')
);

export const isEvaluationOwner = createSelector(
    [getEvaluationPermissions],
    permissionCheck('isOwner')
);

export const isEvaluationEditor = createSelector(
    [getEvaluationPermissions],
    permissionCheck('isEditor')
);

export const isEvaluationViewer = createSelector(
    [getEvaluationPermissions],
    permissionCheck('isViewer')
);

export const isEvaluationPermitted = createSelector(
    [getEvaluationPermissions],
    permissionCheck('isPermitted')
);

export const isDocumentViewer = createSelector(
    [getProjectJS, isIntakeViewer, isBuilderViewer],
    (project, intakeViewer, builderViewer) => {
        if (project && project.isIntake) {
            return intakeViewer;
        }
        return builderViewer;
    }
);

export const isDocumentEditor = createSelector(
    [getProjectJS, isIntakeEditor, isBuilderEditor],
    (project, intakeEditor, builderEditor) => {
        if (project && project.isIntake) {
            return intakeEditor;
        }
        return builderEditor;
    }
);

export const getBidPosterAuthorized = createSelector(
    [getProjectJS, getUserJS, isBidPosterUser, isDepartmentBidPosterUser],
    (project, user, isBidPoster, isDepartmentBidPoster) => {
        if (project && user) {
            const isProjectCreator = user.id === project.user_id;
            const isDepartmentBid = user.department_id === project.department_id;

            const globalCreate = isProjectCreator && project.template.isGlobalCreate;
            const departmentBidPoster = isDepartmentBid && isDepartmentBidPoster;
            return globalCreate || departmentBidPoster || isBidPoster;
        }
    }
);

export const isDocumentEditable = createSelector(
    [
        getProjectJS,
        getUserJS,
        isIntakeEditor,
        isBuilderEditor,
        isBidAmenderUser,
        isDepartmentBidAmenderUser,
        getBidPosterAuthorized,
    ],
    (
        project,
        user,
        intakeEditor,
        builderEditor,
        isBidAmender,
        isDepartmentBidAmender,
        bidPosterAuthorized
    ) => {
        if (project && project.isIntake && intakeEditor) {
            return [REQUEST_DRAFT, REQUEST_REVIEW].includes(project.status);
        }
        if (project && builderEditor) {
            const isDepartmentBid = user.department_id === project.department_id;
            const isAmender = isBidAmender || (isDepartmentBidAmender && isDepartmentBid);

            // Only projects in draft and review can be edited
            const editableStatuses = [DRAFT, REVIEW];
            // Allow bid amenders and bid posters of `globalCreate` projects edit access after project has been posted
            if (isAmender || bidPosterAuthorized) {
                editableStatuses.push(POST_PENDING, OPEN);
            }
            return editableStatuses.includes(project.status);
        }
        return false;
    }
);

export const isGlobalEditorForProject = createSelector(
    [isGlobalEditorUser, isDepartmentEditorUser, getUserJS, getProjectJS],
    (isGlobalEditor, isDepartmentEditor, user, project) => {
        if (isGlobalEditor) {
            return true;
        }
        if (!isDepartmentEditor) {
            return false;
        }
        return user?.department_id === project?.department_id;
    }
);

export const isContractAdminForProject = createSelector(
    [isContractAdminUser, isDepartmentContractAdminUser, getUserJS, getProjectJS],
    (isContractAdmin, isDepartmentContractAdmin, user, project) => {
        if (isContractAdmin) {
            return true;
        }
        if (!isDepartmentContractAdmin) {
            return false;
        }
        return user?.department_id === project?.department_id;
    }
);

export const isContractAdminForContract = createSelector(
    [isContractAdminUser, isDepartmentContractAdminUser, getUserJS, getContractJS],
    (isContractAdmin, isDepartmentContractAdmin, user, contract) => {
        if (isContractAdmin) {
            return true;
        }
        if (!isDepartmentContractAdmin) {
            return false;
        }
        return user?.department_id === contract?.department_id;
    }
);

// Attachments are also editable in `postPending` and `open` status
export const isAttachmentsEditable = createSelector(
    [getProjectJS, isDocumentEditable, isSourcingEditor],
    (project, isEditable, sourcingEditor) => {
        // Regular permissions are in effect for attachments
        if (isEditable) {
            return true;
        }
        // Plus sourcing editors also can edit projects before the proposal deadline
        if (project && sourcingEditor) {
            return [POST_PENDING, OPEN].includes(project.status);
        }
        return false;
    }
);

export const areProposalsEditable = createSelector(
    [getProjectJS, isEvaluationEditor, isSourcingEditor],
    (project, evaluationEditor, sourcingEditor) => {
        if (project && (evaluationEditor || sourcingEditor)) {
            const { status } = project;
            return status !== AWARD_PENDING && status !== CLOSED;
        }
        return false;
    }
);
