import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { createRequisitionGroup } from '../../../../actions/requisitionGroups';
import { LoadingButton } from '../../../../components';

export const RequisitionGroupCreateButton = ({ bsSize, routingHandler }) => {
    const [creating, setCreating] = useState(false);
    const [createError, setCreateError] = useState(null);
    const dispatch = useDispatch();

    const createHandler = () => {
        setCreating(true);
        setCreateError(null);
        dispatch(createRequisitionGroup())
            .then((reviewGroup) => {
                setCreating(false);
                routingHandler(reviewGroup.id);
            })
            .catch((error) => {
                setCreating(false);
                setCreateError(error.message);
            });
    };

    return (
        <>
            <LoadingButton
                bsSize={bsSize}
                bsStyle="primary"
                disabled={creating}
                icon="fa-plus"
                loading={creating}
                onClick={createHandler}
                qaTag="requisitionGroup-create"
                text="New Requisition Group"
            />
            {createError && <div className="error-block">{createError}</div>}
        </>
    );
};

RequisitionGroupCreateButton.propTypes = {
    bsSize: PropTypes.string,
    routingHandler: PropTypes.func.isRequired,
};
