import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { priceTableFieldNames } from '../../../../../../constants';
import { HelpIcon, Label, SearchSelect, Toggle } from '../../../../../../../../../components';
import {
    DISCOUNT_ONLY,
    UNIT_PRICE,
} from '../../../../../../../../../../../shared_config/priceTables';
import { searchSelectOptions, searchSelectStyles } from '../constants';

const {
    HAS_DISCOUNT,
    HAS_NO_BID,
    HAS_PERCENTAGE,
    HAS_QUANTITY,
    HAS_SALES_TAX_ROW,
    HAS_TOTAL_ROW,
    HEADER_UNIT_PRICE,
    SPECIFY_QUANTITY,
    SPECIFY_UNIT_PRICE,
} = priceTableFieldNames;

export class StandardToggles extends PureComponent {
    static propTypes = {
        arrayName: PropTypes.string.isRequired,
        change: PropTypes.func.isRequired,
        columnChangeHandler: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        fields: PropTypes.shape({
            forEach: PropTypes.func.isRequired,
        }).isRequired,
        hasPercentage: PropTypes.bool,
        hasQuantity: PropTypes.bool,
        hasTotalRow: PropTypes.bool,
        isReverseAuction: PropTypes.bool,
        salesTax: PropTypes.number,
    };

    static defaultProps = {
        disabled: false,
    };

    get styles() {
        return require('../index.scss');
    }

    hasQuantityHandler = (e, value) => {
        const { arrayName, change, columnChangeHandler, isReverseAuction } = this.props;

        if (!isReverseAuction) {
            change(`${arrayName}.${HAS_TOTAL_ROW}`, value);
        }
        change(`${arrayName}.${SPECIFY_QUANTITY}`, value);
        columnChangeHandler();
    };

    updateCellContent = (cellName, value) => {
        const {
            change,
            fields: { forEach },
        } = this.props;

        forEach((priceItemMember) => change(`${priceItemMember}.${cellName}`, value));
    };

    clearDiscountOnlyCol = () => {
        this.updateCellContent(DISCOUNT_ONLY, false);
    };

    render() {
        const {
            arrayName,
            change,
            columnChangeHandler,
            disabled,
            hasPercentage,
            hasQuantity,
            hasTotalRow,
            isReverseAuction,
            salesTax,
        } = this.props;

        return (
            <>
                <h5 className={this.styles.optionsTitle}>Table Options:</h5>
                <div className="row">
                    <div className={`col-xs-12 col-sm-6 ${this.styles.colToggle}`}>
                        <Label htmlFor="NoBidToggle" label="Include No Bid column" />
                        <HelpIcon tooltip="Allows vendors to optionally no bid line items instead of requiring them to enter a price" />
                    </div>
                    <div className="col-xs-12 col-sm-3">
                        <Field
                            component={Toggle}
                            disabled={disabled}
                            labelId="NoBidToggle"
                            name={`${arrayName}.${HAS_NO_BID}`}
                            onChange={columnChangeHandler}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className={`col-xs-12 col-sm-6 ${this.styles.colToggle}`}>
                        <Label
                            htmlFor="ReplaceUnitCost"
                            label="Replace Unit Cost with Percentage"
                        />
                        <HelpIcon tooltip="Replace the Unit Cost column with a Percentage column. Will disable Quantity column and totaling capabilities." />
                    </div>
                    <div className="col-xs-12 col-sm-3">
                        <Field
                            component={Toggle}
                            disabled={disabled}
                            labelId="ReplaceUnitCost"
                            name={`${arrayName}.${HAS_PERCENTAGE}`}
                            onChange={(e, value) => {
                                if (value) {
                                    change(`${arrayName}.${HAS_DISCOUNT}`, false);
                                    this.clearDiscountOnlyCol();
                                }
                                change(`${arrayName}.${HAS_QUANTITY}`, !value);
                                change(
                                    `${arrayName}.${HEADER_UNIT_PRICE}`,
                                    value ? 'Percentage' : 'Unit Cost'
                                );
                                this.hasQuantityHandler(e, !value);
                            }}
                        />
                    </div>
                </div>
                {!hasPercentage && (
                    <div className="row">
                        <div className={`col-xs-12 col-sm-6 ${this.styles.colToggle}`}>
                            <Label
                                htmlFor="IncludeDiscountPercentage"
                                label="Include Discount percentage column"
                            />
                            <HelpIcon tooltip="Add a Discount percentage column. Allows vendors to apply a discount to the unit price." />
                        </div>
                        <div className="col-xs-12 col-sm-3">
                            <Field
                                component={Toggle}
                                disabled={disabled}
                                labelId="IncludeDiscountPercentage"
                                name={`${arrayName}.${HAS_DISCOUNT}`}
                                onChange={(e, value) => {
                                    if (!value) {
                                        this.clearDiscountOnlyCol();
                                    }
                                    columnChangeHandler();
                                }}
                            />
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className={`col-xs-12 col-sm-6 ${this.styles.colToggle}`}>
                        <Label htmlFor="IncludeQuantity" label="Include Quantity column" />
                        <HelpIcon tooltip="Add a Quantity column to the table that allows you to specify the quantity for each item. Not recommended when doing rate based pricing." />
                    </div>
                    <div className="col-xs-12 col-sm-3">
                        <Field
                            component={Toggle}
                            disabled={hasPercentage || disabled}
                            labelId="IncludeQuantity"
                            name={`${arrayName}.${HAS_QUANTITY}`}
                            onChange={(e, value) => this.hasQuantityHandler(e, value)}
                        />
                    </div>
                </div>
                {hasQuantity && (
                    <>
                        <div className="row">
                            <div className={`col-xs-12 col-sm-6 ${this.styles.colToggle}`}>
                                <Label
                                    htmlFor="IncludeTotalRow"
                                    label="Include total row at bottom of table"
                                />
                                <HelpIcon tooltip="Add a total row to the bottom of the table that will automatically sum the costs (only available when quantity is included)." />
                            </div>
                            <div className="col-xs-12 col-sm-3">
                                <Field
                                    component={Toggle}
                                    disabled={disabled || isReverseAuction}
                                    labelId="IncludeTotalRow"
                                    name={`${arrayName}.${HAS_TOTAL_ROW}`}
                                    onChange={(e, value) => {
                                        if (!value) {
                                            change(`${arrayName}.${HAS_SALES_TAX_ROW}`, value);
                                        }
                                        if (value) {
                                            this.clearDiscountOnlyCol();
                                        }
                                        columnChangeHandler();
                                    }}
                                />
                            </div>
                        </div>
                        {salesTax && (
                            <div className="row">
                                <div className={`col-xs-12 col-sm-6 ${this.styles.colToggle}`}>
                                    <Label
                                        htmlFor="IncludeSalesTax"
                                        label="Include sales tax row at bottom of table"
                                    />
                                    <HelpIcon
                                        tooltip={
                                            !hasTotalRow
                                                ? 'Include the total row if you would like to enable this setting'
                                                : 'Add a sales tax row to the bottom of the table that will automatically apply sales tax to items marked as taxable and add it to the total.'
                                        }
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-3">
                                    <Field
                                        component={Toggle}
                                        disabled={!hasTotalRow || disabled || isReverseAuction}
                                        labelId="IncludeSalesTax"
                                        name={`${arrayName}.${HAS_SALES_TAX_ROW}`}
                                        onChange={columnChangeHandler}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className={`col-xs-12 col-sm-6 ${this.styles.colToggle}`}>
                                <b>Who specifies quantity of items</b>
                                <HelpIcon tooltip="Choose whether you or the vendor will specify the quantity for items. Default is for you to provide the quantities." />
                            </div>
                            <div className="col-xs-12 col-sm-3">
                                <Field
                                    aria-label={`${arrayName}.${SPECIFY_QUANTITY}`}
                                    component={SearchSelect}
                                    disabled={disabled}
                                    formClassName={this.styles.searchSelect}
                                    isSearchable={false}
                                    name={`${arrayName}.${SPECIFY_QUANTITY}`}
                                    onChange={columnChangeHandler}
                                    options={searchSelectOptions}
                                    styles={searchSelectStyles}
                                />
                            </div>
                        </div>
                    </>
                )}
                <div className="row">
                    <div className={`col-xs-12 col-sm-6 ${this.styles.colToggle}`}>
                        <b>Who specifies unit cost of items</b>
                        <HelpIcon tooltip="Choose whether you or the vendor will specify the unit cost for items. Default is for vendors to provide the unit costs." />
                    </div>
                    <div className="col-xs-12 col-sm-3">
                        <Field
                            aria-label={`${arrayName}.${SPECIFY_UNIT_PRICE}`}
                            component={SearchSelect}
                            disabled={disabled}
                            formClassName={this.styles.searchSelect}
                            isSearchable={false}
                            name={`${arrayName}.${SPECIFY_UNIT_PRICE}`}
                            onChange={(e, value) => {
                                if (!value) {
                                    this.updateCellContent(UNIT_PRICE, null);
                                }
                                columnChangeHandler();
                            }}
                            options={searchSelectOptions}
                            styles={searchSelectStyles}
                        />
                    </div>
                </div>
            </>
        );
    }
}
