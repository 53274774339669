import { fromJS } from 'immutable';

import * as requisitionsActions from '../actions/requisitions';
import * as requisitionsApprovalsActions from '../actions/requisitionsApprovals';

const initialState = fromJS({
    backgroundRefresh: false,
    loadingRequisitionLogs: false,
    loadRequisitionLogsError: null,
    loadingReqRelations: false,
    loadReqRelationsError: null,
    rejectionNoteModalData: null,
    reqRelations: [],
    requisitionLogs: [],
    updatingStepPositionApprover: false,
    updateStepPositionApproverError: null,
    showMissingVendorBanner: false,
});

export default function requisitionsApprovalsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case requisitionsApprovalsActions.BACKGROUND_REFRESH_START:
            return state.set('backgroundRefresh', true);
        case requisitionsApprovalsActions.BACKGROUND_REFRESH_END:
            return state.set('backgroundRefresh', false);
        case requisitionsApprovalsActions.CREATE_LOG_SUCCESS:
            return state.set(
                'requisitionLogs',
                state.get('requisitionLogs').push(fromJS(action.result))
            );
        case requisitionsApprovalsActions.HIDE_REJECTION_NOTE_MODAL:
            return state.merge(
                fromJS({
                    rejectionNoteModalData: null,
                })
            );
        case requisitionsApprovalsActions.LOAD_LOGS:
            return state.merge(
                fromJS({
                    loadingRequisitionLogs: true,
                    loadRequisitionLogsError: null,
                })
            );
        case requisitionsApprovalsActions.LOAD_LOGS_FAILURE:
            return state.merge(
                fromJS({
                    loadingRequisitionLogs: false,
                    loadRequisitionLogsError: action.error && action.error.message,
                })
            );
        case requisitionsApprovalsActions.LOAD_LOGS_SUCCESS:
            return state.merge(
                fromJS({
                    loadingRequisitionLogs: false,
                    requisitionLogs: action.result,
                })
            );
        case requisitionsApprovalsActions.LOAD_RELATIONS:
            return state.merge(
                fromJS({
                    loadingReqRelations: true,
                    loadReqRelationsError: null,
                })
            );
        case requisitionsApprovalsActions.LOAD_RELATIONS_FAILURE:
            return state.merge(
                fromJS({
                    loadingReqRelations: false,
                    loadReqRelationsError: action.error && action.error.message,
                })
            );
        case requisitionsApprovalsActions.LOAD_RELATIONS_SUCCESS:
            return state.merge(
                fromJS({
                    loadingReqRelations: false,
                    reqRelations: action.result,
                })
            );
        case requisitionsApprovalsActions.CREATE_RELATION_SUCCESS:
            return state.set(
                'reqRelations',
                state.get('reqRelations').unshift(fromJS(action.result))
            );
        case requisitionsApprovalsActions.DELETE_RELATION_SUCCESS:
            return state.set(
                'reqRelations',
                state
                    .get('reqRelations')
                    .filter((reqRelation) => reqRelation.get('id') !== action.result.id)
            );
        case requisitionsApprovalsActions.SHOW_REJECTION_NOTE_MODAL:
            return state.merge(
                fromJS({
                    rejectionNoteModalData: action.data,
                })
            );
        case requisitionsApprovalsActions.UPDATE_APPROVER:
            return state.merge(
                fromJS({
                    updatingStepPositionApprover: true,
                    updateStepPositionApproverError: null,
                })
            );
        case requisitionsApprovalsActions.UPDATE_APPROVER_FAILURE:
            return state.merge(
                fromJS({
                    updatingStepPositionApprover: false,
                    updateStepPositionApproverError: action.error && action.error.message,
                })
            );
        case requisitionsApprovalsActions.UPDATE_APPROVER_SUCCESS:
            return state.merge(
                fromJS({
                    updatingStepPositionApprover: false,
                })
            );
        case requisitionsApprovalsActions.SHOW_MISSING_VENDOR_BANNER:
            return state.merge(fromJS({ showMissingVendorBanner: true }));
        case requisitionsApprovalsActions.HIDE_MISSING_VENDOR_BANNER:
            return state.merge(fromJS({ showMissingVendorBanner: false }));
        case requisitionsApprovalsActions.SHOW_MISSING_VENDOR_ADDRESS_BANNER:
            return state.merge(fromJS({ showMissingVendorAddressBanner: true }));
        case requisitionsApprovalsActions.HIDE_MISSING_VENDOR_ADDRESS_BANNER:
            return state.merge(fromJS({ showMissingVendorAddressBanner: false }));
        case requisitionsActions.RESET_REQUISITION:
            return initialState;
        default:
            return state;
    }
}
