import { hexToRGB } from '../../../../../../../shared_config/helpers';
import { visualizationColors } from './constants';

export const highlightPoint = (point, name) => {
    if (!point || !point.update) return;

    const colors = visualizationColors[name];

    point.update({
        color: colors[point.colorIndex],
    });
};

export const unhighlightPoint = (point, name) => {
    if (!point || !point.update) return;

    const colors = visualizationColors[name];

    point.update({
        color: hexToRGB(colors[point.colorIndex], 0.3),
    });
};

export const defaultLabelFormatter = (name, y) => {
    return `
    <div style="display:flex;">
        <p style="font-size: 14px; font-weight: 400;">${name}:</p>
        <p style="font-size: 14px;">${y}</p>
    </div>
    `;
};

// Loads a custom SVG icon that is rendered in the Chart context menu
export const loadMoreIcon = (Highcharts) => {
    Highcharts.SVGRenderer.prototype.symbols.more = function (x, y, w, h) {
        const r = w / 12; // radius of each circle
        const gap = w / 4; // gap between circles
        const centerY = y + h / 2; // vertical center of the icon

        const path = [
            // Circle 1
            'M',
            x + w / 2,
            centerY - gap - r,
            'a',
            r,
            r,
            0,
            1,
            0,
            r * 2,
            0,
            'a',
            r,
            r,
            0,
            1,
            0,
            -r * 2,
            0,

            // Circle 2
            'M',
            x + w / 2,
            centerY,
            'a',
            r,
            r,
            0,
            1,
            0,
            r * 2,
            0,
            'a',
            r,
            r,
            0,
            1,
            0,
            -r * 2,
            0,

            // Circle 3
            'M',
            x + w / 2,
            centerY + gap + r,
            'a',
            r,
            r,
            0,
            1,
            0,
            r * 2,
            0,
            'a',
            r,
            r,
            0,
            1,
            0,
            -r * 2,
            0,
        ];

        return path;
    };
};
