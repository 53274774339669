import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box } from '@og-pro/ui';

import { CDSButton, Button, Link } from '..';
import { getFileExtensionIcon } from '../../helpers';

export class FileUploadAttachment extends Component {
    static propTypes = {
        attachment: PropTypes.shape({
            created_at: PropTypes.string,
            fileExtension: PropTypes.string, // Only not available when attachment created from form without being saved
            filename: PropTypes.string.isRequired,
            url: PropTypes.string, // Only not available when attachment created from form without being saved
        }).isRequired,
        className: PropTypes.string,
        deleteHandler: PropTypes.func,
        downloadAttachments: PropTypes.func,
        disabled: PropTypes.bool,
        isGovernmentView: PropTypes.bool,
        isLast: PropTypes.bool,
        isSubscribed: PropTypes.bool,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),
        requireConfirmation: PropTypes.bool,
        showDate: PropTypes.bool,
        useListItemStyle: PropTypes.bool,
        useOpenGovStyle: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
        deleteHandler: undefined,
        disabled: false,
        useListItemStyle: false,
    };

    get styles() {
        return require('./index.scss');
    }

    constructor(props) {
        super(props);

        this.state = {
            deleting: false,
            deleteError: null,
        };
    }

    deleteHandler = () => {
        const { requireConfirmation } = this.props;

        // eslint-disable-next-line no-alert
        if (!requireConfirmation || window.confirm('Are you sure?')) {
            return this.deleteAttachment();
        }
    };

    deleteAttachment = () => {
        const { attachment, deleteHandler } = this.props;

        this.setState({
            deleting: true,
            deleteError: null,
        });

        return Promise.resolve(deleteHandler(attachment)).catch((error) => {
            this.setState({
                deleting: false,
                deleteError: error.message || 'Unknown error',
            });
        });
    };

    downloadHandler = () => {
        const { attachment, isSubscribed, project } = this.props;

        this.props.downloadAttachments(project, isSubscribed, [attachment]);
    };

    renderLink = () => {
        const {
            attachment: { fileExtension, filename, url },
            downloadAttachments,
            isGovernmentView,
        } = this.props;

        const fileIcon = getFileExtensionIcon(fileExtension);

        if (!url && downloadAttachments === undefined) {
            return (
                <div>
                    <i className={`fa fa-${fileIcon} ${this.styles.fileIcon}`} />
                    {filename}
                </div>
            );
        }

        if (isGovernmentView || downloadAttachments === undefined) {
            return (
                <Link
                    className={this.styles.attachment}
                    href={url}
                    qaTag={`fileUpload-${filename}`}
                >
                    <i className={`fa fa-${fileIcon} ${this.styles.fileIcon}`} />
                    {filename}
                </Link>
            );
        }

        return (
            <Link
                className={this.styles.attachment}
                onClick={this.downloadHandler}
                qaTag={`fileUpload-${filename}`}
            >
                <i aria-label="File Icon" className={`fa fa-${fileIcon} ${this.styles.fileIcon}`} />
                {filename}
            </Link>
        );
    };

    render() {
        const {
            attachment: { created_at: createdAt },
            className,
            deleteHandler,
            disabled,
            isLast,
            showDate,
            useListItemStyle,
            useOpenGovStyle,
        } = this.props;

        const { deleteError, deleting } = this.state;

        if (useOpenGovStyle) {
            return (
                <Box
                    alignItems="center"
                    className={classnames(this.styles.useOpenGovStyle, {
                        [this.styles.last]: isLast,
                    })}
                    display="flex"
                >
                    <Box className={this.styles.link} flex={1}>
                        {this.renderLink()}
                    </Box>
                    {deleteHandler && (
                        <CDSButton
                            noPadding
                            onClick={this.deleteHandler}
                            qaTag="attachment-delete"
                            variant="text"
                        >
                            <i className="fa fa-times" />
                        </CDSButton>
                    )}
                </Box>
            );
        }

        return (
            <div
                className={classnames(
                    this.styles.container,
                    useListItemStyle && this.styles.listItemStyle,
                    className
                )}
            >
                {deleteHandler && (
                    <Button
                        bsSize="xs"
                        bsStyle="link"
                        className={this.styles.deleteButton}
                        disabled={disabled || deleting}
                        onClick={this.deleteHandler}
                        qaTag="uploadAttachmentForm-delete"
                    >
                        <i className="fa fa-lg fa-trash-o text-danger" />
                    </Button>
                )}
                {this.renderLink()}
                {showDate && !!createdAt && (
                    <div className={classnames('text-muted', this.styles.date)}>
                        {moment(createdAt).format('lll')}
                    </div>
                )}
                {deleteError && (
                    <div className={classnames('error-block', this.styles.deleteError)}>
                        {deleteError}
                    </div>
                )}
            </div>
        );
    }
}
