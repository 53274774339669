import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as UUIDv4 } from 'uuid';

import { Button } from '../..';
import { addUser, inviteUser } from '../../../actions/admin';
import { UserForm } from '../../../containers/Admin/UserList/UserForm';
import { form } from '../../../containers/Admin/UserList/constants';
import { getUserJS } from '../../../containers/selectors';
import { basicUserRoles } from '../../../../../shared_config/userRoles';

const { USER } = basicUserRoles;

const AddUserModal = ({ closeModal }) => {
    const [updating, setUpdating] = useState(false);
    const dispatch = useDispatch();

    const handleCancel = () => {
        closeModal();
    };

    const submitHandler = (userUuid, data) => {
        setUpdating(true);
        dispatch(
            inviteUser(userUuid, data, {
                onSuccess: (createdUser) => {
                    closeModal();
                    dispatch(addUser(createdUser.department_id, { ...createdUser, uuid: null }));
                },
                onFailure: () => setUpdating(false),
            })
        );
    };

    const user = useSelector(getUserJS);
    const uuid = UUIDv4();
    const newUserData = { uuid, role: USER, department_id: user.department_id };

    return (
        <Modal bsSize="lg" onHide={handleCancel} show>
            <Modal.Header closeButton>
                <Modal.Title className="text-center">Invite New User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UserForm
                    cancelHandler={handleCancel}
                    currentUser={user}
                    disabled={updating}
                    form={`${form}${uuid}`}
                    initialValues={newUserData}
                    isModalView
                    shouldShowNewUserForm
                    submitHandler={submitHandler}
                    user={newUserData}
                />
            </Modal.Body>
        </Modal>
    );
};

AddUserModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
};

export const AddUserButton = ({ buttonProps, children }) => {
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <>
            <Button {...buttonProps} onClick={handleShowModal}>
                {children || 'Add User'}
            </Button>
            {showModal && <AddUserModal closeModal={handleCloseModal} />}
        </>
    );
};

AddUserButton.propTypes = {
    buttonProps: PropTypes.object,
    children: PropTypes.node,
};
