import { createSelector } from 'reselect';

import { getUserJS, isRequisitionGlobalEditorUser } from '../../selectors';
import { getRequisitionCurrentStep, getReviewSequenceSteps } from '../../../selectors/govApp';
import { approvalStatusTypes } from '../../../../../shared_config/requisitions';

export const getIsLoadingSteps = (state) => state.requisitions.get('loadingReviewSequenceSteps');
export const getLoadStepsError = (state) => state.requisitions.get('loadReviewSequenceStepsError');
export const getIsBackgroundRefreshing = (state) =>
    state.requisitionsApprovals.get('backgroundRefresh');
export const getShowMissingVendorBanner = (state) =>
    state.requisitionsApprovals.get('showMissingVendorBanner');

export const getShowMissingVendorAddressBanner = (state) =>
    state.requisitionsApprovals.get('showMissingVendorAddressBanner');

const getRawRequisitionLogs = (state) => state.requisitionsApprovals.get('requisitionLogs');

export const getCurrentStepUserApprovalsData = createSelector(
    [getUserJS, isRequisitionGlobalEditorUser, getRequisitionCurrentStep],
    (user, isRequisitionGlobalEditor, currentStep) => {
        if (currentStep) {
            const getStepPositionApproverFromStepPositionApproval = (stepPositionApproval) => {
                const { stepPositionApprovers } = stepPositionApproval;

                // Requisition global editors will always be an approver of the position. Match as follows:
                // 1. If global editor is an approver use their own stepPositionApprover
                // 2. Use the stepPositionApprover for the primary approver
                // 3. Use any stepPositionApprover (this should never be necessary)
                if (isRequisitionGlobalEditor) {
                    return (
                        stepPositionApprovers.find((approver) => approver.user_id === user.id) ||
                        stepPositionApprovers.find((approver) => approver.isPrimary) ||
                        stepPositionApprovers[0]
                    );
                }

                // For regular users, only return a stepPositionApprover if it belongs to the user
                return stepPositionApprovers.find((approver) => approver.user_id === user.id);
            };

            const userApprovals = [];

            currentStep.stepApproval.stepPositionApprovals.forEach((stepPositionApproval) => {
                const stepPositionApprover =
                    getStepPositionApproverFromStepPositionApproval(stepPositionApproval);

                if (stepPositionApprover) {
                    // Include all data associated with the approval
                    userApprovals.push({
                        stepApproval: currentStep.stepApproval,
                        stepPositionApproval,
                        stepPositionApprover,
                    });
                }
            });

            return userApprovals;
        }
        return [];
    }
);

export const getRejectedStep = createSelector([getReviewSequenceSteps], (reviewSequenceSteps) => {
    return reviewSequenceSteps.find(
        ({ stepApproval }) => stepApproval.status === approvalStatusTypes.REJECTED
    );
});

export const getAllRequisitionLogs = createSelector(
    [getRawRequisitionLogs],
    (rawRequisitionLogs) => {
        return rawRequisitionLogs.toJS().reverse();
    }
);
