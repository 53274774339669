import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ApprovalWorkflowForm } from '../ApprovalWorkflowForm';
import { fieldNames, form } from '../constants';
import { Button, Label, UserProfileList } from '../../../../components';
import { approvalWorkflowTypesDict } from '../../../../../../shared_config/approval';

const { APPROVAL_WORKFLOW_STEPS, DEPARTMENT_IDS } = fieldNames;
const { BUILDER, CONTRACT, INTAKE } = approvalWorkflowTypesDict;

export const ApprovalWorkflowItem = ({
    approvalWorkflow,
    approvalWorkflow: { approvalWorkflowSteps, departments, id, updateError, updating },
    deleteApprovalWorkflow,
    departmentsSelectOptions,
    multiUserSelect,
    selectedModuleType,
    updateApprovalWorkflow,
    usersSelectOptions,
}) => {
    const [showApprovalForm, setShowApprovalForm] = useState(false);

    const styles = require('../index.scss');

    const hideForm = () => setShowApprovalForm(false);
    const showForm = () => setShowApprovalForm(true);

    const deleteApprovalWorkflowHandler = () => {
        deleteApprovalWorkflow(approvalWorkflow.id);
    };

    const updateApprovalWorkflowHandler = (data) => {
        updateApprovalWorkflow(approvalWorkflow.id, data).then((workflowOrError) => {
            if (!(workflowOrError instanceof Error)) {
                hideForm();
            }
        });
    };

    if (showApprovalForm) {
        const approvalWorkflowStepValues = approvalWorkflowSteps.map((step) => {
            return {
                ...step,
                userIds: multiUserSelect ? step.users.map((user) => user.id) : step.users[0].id,
            };
        });

        return (
            <ApprovalWorkflowForm
                closeForm={hideForm}
                departments={departments.map((dept) => dept.id)}
                departmentsSelectOptions={departmentsSelectOptions}
                disabled={updating}
                form={`${form}${id}`}
                initialValues={{
                    [APPROVAL_WORKFLOW_STEPS]: approvalWorkflowStepValues,
                    [DEPARTMENT_IDS]: departments.map((dept) => dept.id),
                }}
                multiUserSelect={multiUserSelect}
                onSubmit={updateApprovalWorkflowHandler}
                selectedModuleType={selectedModuleType}
                serverError={updateError}
                usersSelectOptions={usersSelectOptions}
            />
        );
    }

    return (
        <div className={`row ${styles.workflowItem}`}>
            <div className={`col-sm-4 ${styles.departmentsColumn}`}>
                {departments.map((department) => (
                    <div className={styles.departmentDisplayItem} key={department.id}>
                        {department.name}
                    </div>
                ))}
            </div>
            <div className={`hidden-xs col-sm-1 text-primary ${styles.arrowIcon}`}>
                <i className="fa fa-lg fa-arrow-right" />
            </div>
            <div className={`col-sm-5 ${styles.approversColumn}`}>
                {selectedModuleType === INTAKE && (
                    <div style={{ marginBottom: '20px' }}>
                        <UserProfileList
                            maxDisplayableUsers={6}
                            users={approvalWorkflowSteps[0].users}
                        />
                    </div>
                )}
                {(selectedModuleType === BUILDER || selectedModuleType === CONTRACT) &&
                    approvalWorkflowSteps.map((step, index) => {
                        return (
                            <div className={styles.reviewStep} key={step.id}>
                                <div>
                                    <Label
                                        className={styles.approvalStepNumberLabel}
                                        label={`Review Step ${index + 1}`}
                                    />
                                </div>
                                <div style={{ marginBottom: '20px' }}>
                                    <UserProfileList maxDisplayableUsers={6} users={step.users} />
                                </div>
                            </div>
                        );
                    })}
            </div>
            <div className="col-sm-2 text-right">
                <Button
                    aria-label="Edit Workflow Group Button"
                    bsStyle="link"
                    className={styles.linkButton}
                    disabled={updating}
                    onClick={showForm}
                    qaTag="approvalPresetWorkflow-editWorkflow"
                >
                    <i className="fa fa-lg fa-pencil" />
                </Button>
                &nbsp;
                <Button
                    aria-label="Delete Workflow Group Button"
                    bsStyle="link"
                    className={styles.linkButton}
                    disabled={updating}
                    onClick={deleteApprovalWorkflowHandler}
                    qaTag="approvalPresetWorkflow-removeWorkflow"
                >
                    <i className="fa fa-lg fa-times text-danger" />
                </Button>
            </div>
        </div>
    );
};

ApprovalWorkflowItem.propTypes = {
    approvalWorkflow: PropTypes.shape({
        approvalWorkflowSteps: PropTypes.array.isRequired,
        departments: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
            })
        ).isRequired,
        id: PropTypes.number.isRequired,
        users: PropTypes.arrayOf(
            PropTypes.shape({
                displayName: PropTypes.string.isRequired,
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
        updateError: PropTypes.string,
        updating: PropTypes.bool,
    }).isRequired,
    deleteApprovalWorkflow: PropTypes.func.isRequired,
    departmentsSelectOptions: PropTypes.array.isRequired,
    multiUserSelect: PropTypes.bool.isRequired,
    selectedModuleType: PropTypes.string.isRequired,
    updateApprovalWorkflow: PropTypes.func.isRequired,
    usersSelectOptions: PropTypes.array.isRequired,
};
