import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from '..';
import { ProposalMenuItem } from './ProposalMenuItem';
import { VIEW_PROPOSAL, EDIT_PROPOSAL, DELETE_PROPOSAL } from '../../constants/proposalActions';
import { proposalStatusesDict } from '../../../../shared_config/proposals';

const { DRAFT, PUBLISHED } = proposalStatusesDict;

export class ProposalMenuDropdown extends PureComponent {
    static propTypes = {
        status: PropTypes.string.isRequired,
        isOwner: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        actionHandler: PropTypes.func.isRequired,
        className: PropTypes.string,
        noCaret: PropTypes.bool,
        icon: PropTypes.string,
        bsSize: PropTypes.string,
        projectTitle: PropTypes.string.isRequired,
    };

    static defaultProps = {
        className: '',
        icon: 'list-ul',
    };

    renderMenuItem(menuText, icon, action) {
        return (
            <ProposalMenuItem
                action={action}
                icon={icon}
                key={icon}
                onClick={this.props.actionHandler}
                text={menuText}
            />
        );
    }

    renderDeleteItem() {
        if (!this.props.isOwner) return null;
        return this.renderMenuItem('Delete', 'trash', DELETE_PROPOSAL);
    }

    renderEditItem() {
        if (!this.props.isOwner && !this.props.isEditor) return null;
        return this.renderMenuItem('Edit response', 'pencil', EDIT_PROPOSAL);
    }

    renderViewItem() {
        return this.renderMenuItem('View response', 'search', VIEW_PROPOSAL);
    }

    renderMenuItems() {
        switch (this.props.status) {
            case DRAFT:
                return [this.renderEditItem(), this.renderDeleteItem()];
            case PUBLISHED:
                return [this.renderViewItem()];
            default:
                return [];
        }
    }

    render() {
        const { className, noCaret, icon, bsSize, projectTitle } = this.props;
        const MenuItems = this.renderMenuItems();
        return (
            <Dropdown
                bsSize={bsSize}
                className={className}
                disabled={MenuItems.length === 0}
                onClick={(e) => e.stopPropagation()}
                pullRight
                qaTag="vendorApp-proposalsMenu"
            >
                <Dropdown.Toggle
                    aria-label={`Dropdown action menu for ${projectTitle}`}
                    noCaret={noCaret}
                >
                    <i className={`fa fa-lg fa-${icon}`} />
                </Dropdown.Toggle>
                <Dropdown.Menu>{MenuItems}</Dropdown.Menu>
            </Dropdown>
        );
    }
}
