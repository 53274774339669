import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { BaseApprovalsModal } from './BaseApprovalsModal';
import {
    ApproveModalBody,
    EditDecisionModalBody,
    EditNoteModalBody,
    MarkApprovedModalBody,
    RejectModalBody,
    ResendApprovalModalBody,
    ReviewerReminderEmailModalBody,
} from './ModalBody';
import { approvalStatusesDict } from '../../../../../../shared_config/approval';

const { APPROVED, PENDING, REJECTED } = approvalStatusesDict;

export class ApprovalsModal extends PureComponent {
    static propTypes = {
        approval: PropTypes.object.isRequired,
        approveHandler: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        rejectHandler: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired,
        showModal: PropTypes.func.isRequired,
        submitError: PropTypes.string,
        type: PropTypes.string.isRequired,
        updateHandler: PropTypes.func.isRequired,
    };

    getModalTitle() {
        switch (this.props.type) {
            case 'approve':
                return 'Approve Project';
            case 'reject':
                return 'Reject Project';
            case 'edit':
                return 'Revise Project Approval';
            case 'comment':
                return 'Edit Approval Note';
            case 'markApproved':
                return 'Mark Project Approved';
            case 'reviewerReminder':
                return this.props.approval.reReview
                    ? 'Send Request Another Review Email'
                    : 'Send Reminder Email';
            case 'resend':
                return 'Resend Approval';
            default:
                return null;
        }
    }

    renderModalBody() {
        const {
            approval: {
                comment,
                id,
                reReview,
                reminderHandler,
                reviewStepId,
                status,
                user: { displayName },
            },
            approveHandler,
            hideModal,
            rejectHandler,
            showModal,
            submitError,
            type,
            updateHandler,
        } = this.props;

        const defaultProps = {
            hideModal,
            note: comment,
            submitError,
        };

        const pending = status === PENDING;
        const approved = status === APPROVED;
        const rejected = status === REJECTED;

        switch (type) {
            case 'approve':
                return (
                    <ApproveModalBody
                        {...defaultProps}
                        approveHandler={approveHandler}
                        approved={approved}
                    />
                );
            case 'reject':
                return (
                    <RejectModalBody
                        {...defaultProps}
                        rejectHandler={rejectHandler}
                        rejected={rejected}
                    />
                );
            case 'edit':
                return (
                    <EditDecisionModalBody
                        {...defaultProps}
                        approved={approved}
                        showModal={showModal}
                    />
                );
            case 'comment':
                return <EditNoteModalBody {...defaultProps} submitHandler={updateHandler} />;
            case 'markApproved':
                return (
                    <MarkApprovedModalBody
                        {...defaultProps}
                        approveHandler={approveHandler}
                        approved={approved}
                        name={displayName}
                    />
                );
            case 'reviewerReminder':
                return (
                    <ReviewerReminderEmailModalBody
                        approvalId={id}
                        hideModal={hideModal}
                        reReview={reReview}
                        reminderHandler={reminderHandler}
                        reviewStepId={reviewStepId}
                    />
                );
            case 'resend':
                return (
                    <ResendApprovalModalBody
                        {...defaultProps}
                        approvalId={id}
                        name={displayName}
                        pending={pending}
                    />
                );
            default:
                return null;
        }
    }

    render() {
        const { show, hideModal } = this.props;

        return (
            <BaseApprovalsModal hideModal={hideModal} showModal={show} title={this.getModalTitle()}>
                {this.renderModalBody()}
            </BaseApprovalsModal>
        );
    }
}
