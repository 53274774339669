import PropTypes from 'prop-types';
import React from 'react';
import { NavDropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { LinkContainer } from '../..';
import { GovNavItems } from './GovNavItems';
import { MenuItem } from '../../MenuItem/MenuItem';
import { projectTypesPathsDict } from '../../../../../shared_config/projects';
import {
    getHistoricContractStackedAnalytics,
    getHistoricPurchaseStackedAnalytics,
} from '../../../containers/GovApp/AggregateAnalytics/selectors';

const { PROJECTS, CONTRACTS } = projectTypesPathsDict;

export const AnalyticsNav = ({
    governmentId,
    pathname,
    user: {
        government: { hasRequisitionOnly },
        isExternal,
        userPermissions: {
            isContractAdmin,
            isDepartmentContractAdmin,
            isDepartmentEditor,
            isGlobalEditor,
            isSystemAdmin,
        },
    },
}) => {
    const navItems = [];

    const hasPurchaseTemplates =
        useSelector((state) => getHistoricPurchaseStackedAnalytics(state)).series.length > 0;
    const hasContractsTemplates =
        useSelector((state) => getHistoricContractStackedAnalytics(state)).series.length > 0;
    if (!isExternal && !hasRequisitionOnly) {
        navItems.push({
            className: 'calendar-nav',
            icon: null,
            path: 'calendar',
            title: 'Calendar',
        });
    }
    const dropdownTitle = <span>Analytics</span>;
    const isActive = pathname.match(/\/analytics\//);

    const hasProjectPermission = isSystemAdmin || isGlobalEditor || isDepartmentEditor;
    const hasContractPermission = isSystemAdmin || isContractAdmin || isDepartmentContractAdmin;
    const hasAnalyticPermission = hasProjectPermission || hasContractPermission;

    return (
        <>
            {hasAnalyticPermission && !hasRequisitionOnly && (
                <NavDropdown
                    className={classnames('analytics-nav', isActive && 'active')}
                    id="analytics-nav-dropdown"
                    title={dropdownTitle}
                >
                    {hasPurchaseTemplates && hasProjectPermission ? (
                        <LinkContainer
                            key="projects-analytics"
                            to={`/governments/${governmentId}/analytics/${PROJECTS}`}
                        >
                            <MenuItem qaTag="analyticsNav-projects">
                                <i className="fa fa-folder-open" /> Projects
                            </MenuItem>
                        </LinkContainer>
                    ) : (
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">
                                    {hasProjectPermission
                                        ? 'No projects found'
                                        : 'You do not have permission to view projects'}
                                </Tooltip>
                            }
                            placement="right"
                        >
                            <MenuItem disabled qaTag="analyticsNav-projects">
                                <i className="fa fa-folder-open" /> Projects
                            </MenuItem>
                        </OverlayTrigger>
                    )}
                    {hasContractsTemplates && hasContractPermission ? (
                        <LinkContainer
                            key="contracts-analytics"
                            to={`/governments/${governmentId}/analytics/${CONTRACTS}`}
                        >
                            <MenuItem qaTag="analyticsNav-contracts">
                                <i className="fa fa-file-text" /> Contracts
                            </MenuItem>
                        </LinkContainer>
                    ) : (
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">
                                    {hasContractPermission
                                        ? 'No contracts templates found'
                                        : 'You do not have permission to view contracts'}
                                </Tooltip>
                            }
                            placement="right"
                        >
                            <MenuItem disabled qaTag="analyticsNav-contracts">
                                <i className="fa fa-file-text" /> Contracts
                            </MenuItem>
                        </OverlayTrigger>
                    )}
                </NavDropdown>
            )}
            <GovNavItems governmentId={governmentId} navItems={navItems} />
        </>
    );
    // ];
};

AnalyticsNav.propTypes = {
    governmentId: PropTypes.number.isRequired,
    pathname: PropTypes.string.isRequired,
    user: PropTypes.shape({
        government: PropTypes.shape({
            hasRequisitionOnly: PropTypes.bool.isRequired,
        }).isRequired,
        isExternal: PropTypes.bool.isRequired,
        userPermissions: PropTypes.shape({
            isContractAdmin: PropTypes.bool.isRequired,
            isDepartmentContractAdmin: PropTypes.bool.isRequired,
            isDepartmentEditor: PropTypes.bool.isRequired,
            isGlobalEditor: PropTypes.bool.isRequired,
            isSystemAdmin: PropTypes.bool.isRequired,
        }).isRequired,
    }).isRequired,
};
