import classnames from 'classnames';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FieldArray } from 'redux-form';
import { Box, Typography } from '@og-pro/ui';

import { AddCriteriaButton, GuidedSectionButton } from '../components';
import { projectFormProps, criteriaFieldNames, fieldNames } from '../constants';
import { getCurrentCriteriaValues } from '../selectors';
import { isSingleEmptyCriteriaItem } from '../utils';
import { showPartnerResourcesModal, showSuggestedContentModal } from '../../../../actions/app';
import { showModal } from '../../../../actions/projectLibrary';
import {
    Button,
    CriteriaForm,
    FormError,
    HelpToolTip,
    Main,
    PartnerResourcesModal,
    ProjectAttachmentBlock,
    SuggestedContentModal,
    Well,
} from '../../../../components';
import { ListError } from '../../../../components/GovApp';
import { attachmentTypesDict } from '../../../../../../shared_config/attachments';
import { sectionTypeNames } from '../../../../../../shared_config/sections';
import { subsectionTypeNames } from '../../../../../../shared_config/subsections';
import { getMaxNumberFromList } from '../../../../utils';
import { GenerativeAiPromptScope } from './GenerativeAiPromptScope/GenerativeAiPromptScope';
import { PROMPT } from './GenerativeAiPromptScope/constants';
import { ScopeSDv2 } from './ScopeSDv2';
import { aiEnablementData } from '../../App/selectors';
import { withListItemRefs } from '../../../../hocs';

const { SCOPE } = sectionTypeNames;
const { BODY } = subsectionTypeNames;
const { FUNCTIONAL, PROJECT_DOCUMENT } = attachmentTypesDict;
const { CRITERIA } = fieldNames;
const { ORDER_BY_ID } = criteriaFieldNames;

const mapStateToProps = (state, props) => {
    return {
        isGenerativeAiEnabled: !!aiEnablementData(state),
        // Scope sections can be set up to work differently than the standard "Scope of Work"
        // section
        scopeCriteria: getCurrentCriteriaValues(state, props),
    };
};

const mapDispatchToProps = {
    showLibraryModal: showModal,
    showPartnerResourcesModal,
    showSuggestedContentModal,
};

// @connect
class ConnectedScope extends Component {
    static propTypes = {
        ...projectFormProps,
        isGenerativeAiEnabled: PropTypes.bool.isRequired,
        isOGThemeEnabledForComponents: PropTypes.bool,
        // comes from the withListItemRefs HOC to handle focusing new list items
        focusListItem: PropTypes.func.isRequired,
        listItemRefs: PropTypes.array.isRequired,
        updateListItemRefs: PropTypes.func.isRequired,
        scopeCriteria: PropTypes.array.isRequired,
        showLibraryModal: PropTypes.func.isRequired,
        showPartnerResourcesModal: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            alertRef: React.createRef(),
            showAiForm: false,
            showSuccessAlert: false,
        };
    }

    componentDidMount() {
        const { markBuilderSectionVisited, scopeCriteria } = this.props;

        markBuilderSectionVisited({
            hasIncludedItems: scopeCriteria.length > 0,
            onClose: this.hideHelpModal,
        });
    }

    get formKey() {
        const { projectSection, projectSubsectionsMap } = this.props;

        return `${CRITERIA}.${projectSection.id}_${projectSubsectionsMap[BODY].id}`;
    }

    get useManualNumbering() {
        return this.props.project.useManualNumbering;
    }

    get styles() {
        return require('./Scope.scss');
    }

    showLibraryModal = () => {
        const {
            project: { categories },
            showLibraryModal,
        } = this.props;

        return showLibraryModal(this.copyHandler, { categories });
    };

    copyHandler = (selectedItems, shouldShowSuccessAlert) => {
        const { array, projectSection, projectSubsectionsMap, scopeCriteria } = this.props;

        const maxCriteriaOrderId = getMaxNumberFromList(scopeCriteria, ORDER_BY_ID) + 1;
        selectedItems.forEach((item, idx) => {
            array.push(this.formKey, {
                project_section_id: projectSection.id,
                project_subsection_id: projectSubsectionsMap[BODY].id,
                section_type: SCOPE,
                subsection_type: BODY,
                orderById: maxCriteriaOrderId + idx,
                title: item.title,
                rawDescription: item.description,
            });
        });

        if (shouldShowSuccessAlert) {
            this.setState({ showSuccessAlert: true });
            window.scrollTo(0, this.state.alertRef.current?.offsetTop);
            setTimeout(() => {
                this.setState({ showSuccessAlert: false });
            }, 5000);
        }

        // If the first item is empty remove it
        if (isSingleEmptyCriteriaItem(scopeCriteria)) {
            return this.removeEmptySingleItem();
        }
    };

    addCriteria = (extraData = {}) => {
        const {
            array,
            focusListItem,
            projectSection,
            projectSubsectionsMap,
            scopeCriteria,
            updateListItemRefs,
        } = this.props;

        updateListItemRefs(null, () => {
            focusListItem(0);
        });

        return array.push(this.formKey, {
            orderById: getMaxNumberFromList(scopeCriteria, ORDER_BY_ID) + 1,
            project_section_id: projectSection.id,
            project_subsection_id: projectSubsectionsMap[BODY].id,
            section_type: SCOPE,
            subsection_type: BODY,
            ...extraData,
        });
    };

    addSuggestedItem = (extraData = {}) => {
        const { scopeCriteria } = this.props;
        this.addCriteria(extraData);

        // If the first item is empty remove it
        if (isSingleEmptyCriteriaItem(scopeCriteria)) {
            return this.removeEmptySingleItem();
        }
    };

    hideHelpModal = () => {
        const { markBuilderSectionVisited, scopeCriteria } = this.props;

        const noScopeCriteria = scopeCriteria.length === 0;

        if (noScopeCriteria) {
            this.addCriteria();
            markBuilderSectionVisited();
        }
    };

    insertCriteria = (insertAfterOrderById = 0) => {
        const {
            array,
            change,
            focusListItem,
            projectSection,
            projectSubsectionsMap,
            scopeCriteria,
            updateListItemRefs,
        } = this.props;

        let insertAfterIndex = 0;
        scopeCriteria.forEach((criteria, index) => {
            const oldOrderById = criteria.orderById;
            if (oldOrderById === insertAfterOrderById) {
                insertAfterIndex = index + 1;
            }

            if (oldOrderById > insertAfterOrderById) {
                change(`${this.formKey}[${index}].${ORDER_BY_ID}`, oldOrderById + 1);
            }
        });

        updateListItemRefs(null, () => {
            focusListItem(insertAfterIndex);
        });

        return array.splice(this.formKey, insertAfterIndex, 0, {
            orderById: insertAfterOrderById + 1,
            project_section_id: projectSection.id,
            project_subsection_id: projectSubsectionsMap[BODY].id,
            section_type: SCOPE,
            subsection_type: BODY,
        });
    };

    showHelpModal = () => {
        const { showHelpModal } = this.props;

        showHelpModal({
            onClose: this.hideHelpModal,
        });
    };

    toggleAiForm = () => {
        const { initiateSneakyUpdate } = this.props;
        if (!this.state.showAiForm) {
            initiateSneakyUpdate();
        }
        this.setState((prevState) => ({
            showAiForm: !prevState.showAiForm,
        }));
    };

    removeEmptySingleItem = () => {
        const { array, updateListItemRefs } = this.props;

        updateListItemRefs(0);

        array.shift(this.formKey);
    };

    renderInitialAddCriteria() {
        const { disabled, projectSection, showFormErrors } = this.props;

        return (
            <div>
                <FieldArray
                    component={ListError}
                    displayError={
                        'You must include at least one Scope of Work item. ' +
                        'Please click the button below to begin.'
                    }
                    name={this.formKey}
                    showError={!!showFormErrors}
                />
                <GuidedSectionButton
                    className={this.styles.initButton}
                    disabled={disabled}
                    onClick={this.showHelpModal}
                    text={`Start ${projectSection.title}`}
                    type="required"
                />
            </div>
        );
    }

    renderAttachmentBlock = () => {
        const {
            disabled,
            form,
            isOGThemeEnabledForComponents,
            project: { id: projectId, isIntake, template },
        } = this.props;

        if (isOGThemeEnabledForComponents) {
            return null;
        }

        return (
            <>
                {template.omitDocx && !isIntake && (
                    <Box
                        className={classnames({
                            [this.styles.attachments]: isOGThemeEnabledForComponents,
                        })}
                    >
                        {isOGThemeEnabledForComponents && (
                            <Box className={this.styles.heading}>
                                <Typography variant="h3">Uploaded Project Documents</Typography>
                                <Typography>Upload your project documents (if needed).</Typography>
                            </Box>
                        )}
                        <ProjectAttachmentBlock
                            attachmentListLabel="Uploaded Project Documents:"
                            disabled={disabled}
                            form={form}
                            formKey={PROJECT_DOCUMENT}
                            hideAppendixLetter
                            hideNoAttachments={isOGThemeEnabledForComponents}
                            isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                            label="your project documents (if needed)"
                            listAttachmentType={PROJECT_DOCUMENT}
                            projectId={projectId}
                            uploadAttachmentType={PROJECT_DOCUMENT}
                        />
                    </Box>
                )}
                <Box
                    className={classnames({
                        [this.styles.attachments]: isOGThemeEnabledForComponents,
                    })}
                >
                    {isOGThemeEnabledForComponents && (
                        <Box className={this.styles.heading}>
                            <Typography variant="h3">Supporting Attachments</Typography>
                            <Typography>
                                Upload a file or spreadsheet containing detailed requirements for
                                your project (if needed).
                            </Typography>
                        </Box>
                    )}
                    <ProjectAttachmentBlock
                        disabled={disabled}
                        form={form}
                        formKey={FUNCTIONAL}
                        hideNoAttachments={isOGThemeEnabledForComponents}
                        isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                        label={
                            'a file or spreadsheet containing detailed requirements for your ' +
                            'project (if needed)'
                        }
                        listAttachmentType={FUNCTIONAL}
                        projectId={projectId}
                        uploadAttachmentType={FUNCTIONAL}
                    />
                </Box>
            </>
        );
    };

    renderAddCriteria() {
        const { disabled } = this.props;

        return (
            <div className="col-xs-12 text-center">
                <AddCriteriaButton disabled={disabled} onClick={this.addCriteria} text="Add Item" />
            </div>
        );
    }

    renderSearchLibrary() {
        const { isGenerativeAiEnabled } = this.props;
        const { showAiForm } = this.state;
        const aiButtonContent = showAiForm ? (
            'Cancel'
        ) : (
            <>
                <i className="fa fa-lightbulb-o" />
                &nbsp;Use AI&nbsp;&nbsp;
            </>
        );
        // Have to hand-roll the `disabled` button treatment because using the `disabled` prop
        // causes a styled `div` to be added to the dom which breaks the flexbox layout.
        const buttonClass = classnames(
            this.styles.libraryButton,
            showAiForm && this.styles.disabled
        );

        return (
            <Well className={this.styles.library}>
                <div
                    className={classnames(
                        this.styles.libraryButtonsContainer,
                        isGenerativeAiEnabled && this.styles.fourButtonsContainer
                    )}
                >
                    <div className={this.styles.libraryText}>Resources:</div>
                    {isGenerativeAiEnabled && (
                        <Button
                            bsSize="sm"
                            className={this.styles.libraryButton}
                            onClick={this.toggleAiForm}
                            qaTag="scope-useAi"
                            tooltip={
                                showAiForm
                                    ? ''
                                    : 'Use AI to generate some scope of work items for your project'
                            }
                        >
                            {aiButtonContent}
                        </Button>
                    )}
                    <Button
                        bsSize="sm"
                        className={buttonClass}
                        onClick={showAiForm ? () => {} : this.showLibraryModal}
                        qaTag="scope-scopeOfWorkLibrary"
                        tooltip={
                            showAiForm
                                ? ''
                                : 'Search our library of published projects for scope of work items to use in your project'
                        }
                    >
                        <i className="fa fa-folder-open" /> Scope of Work Library
                    </Button>
                    <Button
                        bsSize="sm"
                        className={buttonClass}
                        onClick={showAiForm ? () => {} : this.props.showSuggestedContentModal}
                        qaTag="scope-suggestedContent"
                        tooltip={
                            showAiForm ? '' : 'Content suggestions curated by your organization'
                        }
                    >
                        <i className="fa fa-list" /> Suggested Content
                    </Button>
                    <Button
                        bsSize="sm"
                        className={buttonClass}
                        onClick={showAiForm ? () => {} : this.props.showPartnerResourcesModal}
                        qaTag="scope-partnerResources"
                        tooltip={
                            showAiForm
                                ? ''
                                : 'Our recommended partners that can help on your project scope'
                        }
                    >
                        <i className="fa fa-external-link" /> Partner Resources
                    </Button>
                </div>
            </Well>
        );
    }

    suggestedContentModal = () => {
        const {
            builderDisplayName,
            disabled,
            project: { government_id: govId },
            scopeCriteria,
            suggestedScope,
        } = this.props;

        return (
            <SuggestedContentModal
                sowProps={{
                    addCriteria: this.addSuggestedItem,
                    criteria: scopeCriteria,
                    description:
                        'Content suggestions curated by your organization. Click to add ' +
                        `any relevant items to your ${builderDisplayName.toLowerCase()}.`,
                    disabled,
                    govId,
                    sectionType: SCOPE,
                    subsectionType: BODY,
                    suggestedItems: suggestedScope,
                }}
            />
        );
    };

    renderContent() {
        const {
            builderDisplayName,
            change,
            disabled,
            form,
            isGenerativeAiEnabled,
            listItemRefs,
            project: { government_id: govId, id: projectId, title: projectTitle },
            projectSection,
            scopeCriteria,
            showComments,
            showFormErrors,
            tagOptions,
            templateVariableOptions,
            updateListItemRefs,
        } = this.props;
        const { showAiForm } = this.state;

        if (scopeCriteria.length === 0 && !showAiForm) {
            return this.renderInitialAddCriteria();
        }

        const styles = require('./Scope.scss');

        const itemRefsProps = {
            listItemRefs,
            updateListItemRefs,
        };

        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        {this.renderSearchLibrary()}
                        {isGenerativeAiEnabled && showAiForm && (
                            <div>
                                <GenerativeAiPromptScope
                                    addSuggestedItems={this.copyHandler}
                                    govId={govId}
                                    initialValues={{ [PROMPT]: projectTitle || '' }}
                                    projectId={projectId}
                                    toggleAiForm={this.toggleAiForm}
                                />
                            </div>
                        )}
                        {!showAiForm && (
                            <>
                                {this.state.showSuccessAlert && (
                                    <Well
                                        bsSize="sm"
                                        className={styles.successAlert}
                                        ref={this.state.alertRef}
                                    >
                                        <div className="text-left">
                                            <i
                                                className={`fa fa-check-circle ${styles.checkCircle}`}
                                            />
                                            &nbsp;&nbsp;Successfully copied
                                        </div>
                                    </Well>
                                )}
                                <label>
                                    Define the requirements for this{' '}
                                    {builderDisplayName.toLowerCase()}
                                    <HelpToolTip
                                        onClick={this.showHelpModal}
                                        text="Click to see instructions"
                                    />
                                </label>
                                <FieldArray
                                    {...itemRefsProps}
                                    change={change}
                                    component={CriteriaForm}
                                    disabled={disabled}
                                    form={form}
                                    insertItem={this.insertCriteria}
                                    name={this.formKey}
                                    projectSection={projectSection}
                                    showComments={showComments}
                                    showValidation={showFormErrors}
                                    tagOptions={tagOptions}
                                    templateVariableOptions={templateVariableOptions}
                                    useManualNumbering={this.useManualNumbering}
                                    useRawDescription
                                />
                                <div className="row">{this.renderAddCriteria()}</div>
                                <div className={`row ${styles.attachmentBlock}`}>
                                    <div className="col-xs-12 col-md-offset-1 col-md-10">
                                        {this.renderAttachmentBlock()}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {this.suggestedContentModal()}
            </div>
        );
    }

    render() {
        const { isOGThemeEnabledForComponents, updateError } = this.props;

        if (isOGThemeEnabledForComponents) {
            return (
                <>
                    <ScopeSDv2
                        {...{
                            change: this.props.change,
                            copyHandler: this.copyHandler,
                            disabled: this.props.disabled,
                            form: this.props.form,
                            formKey: this.formKey,
                            insertCriteria: this.insertCriteria,
                            listItemRefs: this.props.listItemRefs,
                            project: this.props.project,
                            projectSection: this.props.projectSection,
                            renderAttachmentBlock: this.renderAttachmentBlock,
                            scopeCriteria: this.props.scopeCriteria,
                            showAiForm: this.state.showAiForm,
                            showComments: this.props.showComments,
                            showFormErrors: this.props.showFormErrors,
                            showLibraryModal: this.showLibraryModal,
                            showPartnerResourcesModal: this.props.showPartnerResourcesModal,
                            showSuggestedContentModal: this.props.showSuggestedContentModal,
                            updateListItemRefs: this.props.updateListItemRefs,
                            tagOptions: this.props.tagOptions,
                            templateVariableOptions: this.props.templateVariableOptions,
                            toggleAiForm: this.toggleAiForm,
                        }}
                    />
                    {this.suggestedContentModal()}
                    <PartnerResourcesModal type="scope" />
                </>
            );
        }

        const styles = require('./Scope.scss');
        return (
            <Main className={styles.container}>
                <FormError error={updateError} />
                {this.renderContent()}
                <PartnerResourcesModal type="scope" />
            </Main>
        );
    }
}

export const Scope = compose(connect(mapStateToProps, mapDispatchToProps))(
    withListItemRefs(ConnectedScope, { propMapKey: 'scopeCriteria' })
);
