import { IMMEDIATELY, NEVER, YEARS } from '../../../../../../shared_config/recordsRetention';

export const form = 'policyForm';

export const policyTimelineOptions = [
    {
        name: 'Years',
        value: YEARS,
        hasTextField: true,
    },
    {
        name: 'Never (Keep Live Indefinitely)',
        value: NEVER,
    },
    {
        name: 'Immediately Upon Project Close',
        value: IMMEDIATELY,
    },
];
