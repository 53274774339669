import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { MultipleChoiceInput } from '../../../..';
import {
    ALLOW_MULTI_SELECT,
    DATA,
    OPTIONS,
} from '../../../../../../../shared_config/questionnaires';

export class MultipleChoiceResponseForm extends PureComponent {
    static propTypes = {
        data: PropTypes.shape({
            allowMultiSelect: PropTypes.bool,
            options: PropTypes.array,
        }),
        isOGThemeEnabledForComponents: PropTypes.bool,
        disabled: PropTypes.bool,
        input: PropTypes.object.isRequired,
        meta: PropTypes.object.isRequired,
        showValidation: PropTypes.bool,
    };

    render() {
        const { disabled, input, isOGThemeEnabledForComponents, meta, showValidation } = this.props;

        const allowMultiSelect = !!get(this.props, [DATA, ALLOW_MULTI_SELECT]);
        const options = (get(this.props, [DATA, OPTIONS]) || []).map((option) => {
            return {
                label: option,
                value: option,
            };
        });

        return (
            <>
                {allowMultiSelect && (
                    <p className="questionnaire-multiple-choice-prompt">Select all that apply</p>
                )}
                <MultipleChoiceInput
                    disabled={disabled}
                    input={input}
                    isMultiSelect={allowMultiSelect}
                    meta={meta}
                    options={options}
                    showValidation={showValidation}
                    useOpenGovStyle={isOGThemeEnabledForComponents}
                />
            </>
        );
    }
}
