import React from 'react';
import last from 'lodash/last';

import { ConditionalLogicIcon } from './index';
import { questionnaireQuestionConditionalTypes } from '../../../../../shared_config/questionnaires';
import {
    questionLogicLogicableModelNames,
    questionLogicLinkableModelNames,
} from '../../../../../shared_config/questionLogics';

export const VendorQuestionnaireRenderLogicIcon =
    (
        addConditionalLogicHandler,
        questionnaires,
        questionnaireConditionalEnabled,
        isOGThemeEnabledForComponents = false
    ) =>
    (questionnaire, showCreateQuestionnaireModal) => {
        const onConditionalAddQuestionClick = (questionLogicData) => {
            // Find last sub question (if there are any)
            const subConditionalQuestionId = last(questionnaire.conditionalSubQuestionIds || []);
            const subConditionalQuestion = questionnaires.find(
                (question) => subConditionalQuestionId && question.id === subConditionalQuestionId
            );
            // Use the last sub question or the current question if no sub questions
            const upfrontQuestionToInsertAfter = subConditionalQuestion || questionnaire;
            const insertAfterQuestionId = upfrontQuestionToInsertAfter.orderById;

            showCreateQuestionnaireModal({
                insertAfterQuestionId,
                omitFormatTypes: true,
                onComplete: (newCreatedQuestion) => {
                    addConditionalLogicHandler({
                        ...questionLogicData,
                        logicable: questionLogicLogicableModelNames.QUESTIONNAIRE,
                        logicable_id: questionnaire.id,
                        linkable_id: newCreatedQuestion.id,
                        linkable: questionLogicLinkableModelNames.LINKED_QUESTIONNAIRE,
                    });
                },
                // Used to temporarily hide the conditional question from the form
                // The entire form will be submitted and the return value will be used to replace
                // the entire form. There is too much data to keep track of in the form to attempt
                // to set the form state properly. Thus we just hide the intermediate state until
                // the full form updates.
                questionnaireDataToAddAfterCreation: {
                    isHiddenByLogic: true,
                },
            });
        };

        if (
            !questionnaireConditionalEnabled ||
            !questionnaireQuestionConditionalTypes.includes(questionnaire.type)
        ) {
            return null;
        }

        return (
            <div>
                <ConditionalLogicIcon
                    onAddQuestion={onConditionalAddQuestionClick}
                    onAddTemplateQuestion={null}
                    parentQuestion={questionnaire}
                    useOpenGovStyle={isOGThemeEnabledForComponents}
                />
            </div>
        );
    };
