import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Panel } from 'react-bootstrap';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { AddVendorsToListModal } from '../AddVendorsToList/modal';
import { InviteVendorsToProjectModal } from '../InviteVendorsToProjectModal/modal';
import {
    getIsVendorListSearch,
    getShowAddVendorListModal,
    getShowCustomEmailModal,
    getShowInviteVendorListToProjectModal,
    getVendorList,
    getVendorListLoading,
    getVendorListLoadingError,
} from '../selectors';
import { getPortalUrl } from '../../App/selectors';
import VendorManagementHeader from '../VendorManagementHeader';
import connectData from '../../../ConnectData';
import {
    addOrRemoveVendors,
    deleteVendorList,
    loadVendorList,
    showAddVendorsToListModal,
    toggleInviteVendorListToProjectModal,
    loadProcuratedRatingsClient,
} from '../../../../actions/vendorList';
import { toggleCustomEmailModal } from '../../../../actions/app';
import { showVendorProfileModal } from '../../../../actions/vendorProfile';
import { VendorList } from '../../../../components/VendorList';
import { CustomEmailModal, LoadingError, LoadingSpinner } from '../../../../components';
import { FLAGS, withFlags } from '../../../../lib/launchdarkly';
import { VendorListManagerV2 } from '../VendorListManagerV2';

function fetchData(getState, dispatch, location, params) {
    const promises = [];
    const governmentId = Number.parseInt(params.governmentId, 10);

    promises.push(dispatch(loadVendorList(governmentId, params.vendorListId)));

    return Promise.all(promises);
}

const mapStateToProps = (state, props) => {
    const vendorListId = Number.parseInt(props.params.vendorListId, 10);
    const governmentId = Number.parseInt(props.params.governmentId, 10);
    return {
        addVendorsToListModalOpen: getShowAddVendorListModal(state),
        customEmailModalOpen: getShowCustomEmailModal(state),
        governmentId,
        inviteToProjectModalOpen: getShowInviteVendorListToProjectModal(state),
        loadingVendorList: getVendorListLoading(state),
        isVendorListSearch: getIsVendorListSearch(state),
        loadingVendorListError: getVendorListLoadingError(state),
        portalUrl: getPortalUrl(state),
        vendorList: getVendorList(state, vendorListId),
    };
};

const mapDispatchToProps = {
    deleteVendorList,
    loadProcuratedRatingsClient,
    removeVendors: addOrRemoveVendors,
    showAddVendorsToListModal,
    showVendorProfileModal,
    toggleInviteVendorListToProjectModal,
    toggleCustomEmailModal,
};

class ConnectedVendorList extends React.Component {
    constructor(props) {
        super(props);

        this.state = { ratings: [] };
    }

    static propTypes = {
        addVendorsToListModalOpen: PropTypes.bool.isRequired,
        customEmailModalOpen: PropTypes.bool.isRequired,
        getFlag: PropTypes.func.isRequired,
        governmentId: PropTypes.number.isRequired,
        inviteToProjectModalOpen: PropTypes.bool.isRequired,
        isVendorListSearch: PropTypes.bool.isRequired,
        loadingVendorList: PropTypes.bool.isRequired,
        loadingVendorListError: PropTypes.bool,
        location: PropTypes.object.isRequired,
        loadProcuratedRatingsClient: PropTypes.func.isRequired,
        portalUrl: PropTypes.string.isRequired,
        removeVendors: PropTypes.func.isRequired,
        showAddVendorsToListModal: PropTypes.func.isRequired,
        showVendorProfileModal: PropTypes.func.isRequired,
        toggleInviteVendorListToProjectModal: PropTypes.func,
        toggleCustomEmailModal: PropTypes.func.isRequired,
        vendorList: PropTypes.any,
    };

    get styles() {
        return require('./styles.scss');
    }

    componentDidUpdate(prev) {
        if (prev.loadingVendorList && !this.props.loadingVendorList && this.props.vendorList) {
            this.fetchRatings();
        }
    }

    fetchRatings = async () => {
        const { vendorList } = this.props;
        const supplierIds = vendorList.listedUsers.map((user) => user.organization.vendor.id);

        const res = await this.props.loadProcuratedRatingsClient({ supplierIds });

        this.setState({ ratings: res || [] });
    };

    render() {
        const {
            addVendorsToListModalOpen,
            customEmailModalOpen,
            getFlag,
            governmentId,
            inviteToProjectModalOpen,
            isVendorListSearch,
            loadingVendorList,
            loadingVendorListError,
            location: { pathname },
            portalUrl,
            removeVendors,
            vendorList,
        } = this.props;

        const enableVendorListsUpdate = getFlag(FLAGS.ENABLE_VENDOR_LISTS_UPDATE);

        if (enableVendorListsUpdate) {
            return <VendorListManagerV2 />;
        }

        if (loadingVendorList) {
            return <LoadingSpinner text="Loading Vendors" />;
        }

        if (loadingVendorListError) {
            return <LoadingError />;
        }

        const selectedUserData = isVendorListSearch
            ? { userIds: vendorList.listedUsers.map((user) => user.id) }
            : { vendorListId: vendorList.id };

        const listedUsersWithRating = vendorList.listedUsers.map((user) => {
            const { id } = user.organization.vendor;
            const vendorRating = this.state.ratings.find((rating) => {
                return parseInt(rating.partnerId, 10) === id;
            });

            if (!vendorRating) {
                return user;
            }

            return {
                ...user,
                organization: {
                    ...user.organization,
                    vendor: {
                        ...user.organization.vendor,
                        rating: vendorRating.rating,
                    },
                },
            };
        });

        return (
            <div className={this.styles.container}>
                <Panel>
                    <Panel.Body>
                        <VendorManagementHeader
                            governmentId={governmentId}
                            pathname={pathname}
                            portalUrl={portalUrl}
                        />
                        <VendorList
                            {...vendorList}
                            addOrRemoveVendors={removeVendors}
                            isVendorListSearch={isVendorListSearch}
                            listedUsers={listedUsersWithRating}
                            showAddVendorsToListModal={this.props.showAddVendorsToListModal}
                            showCustomEmailModal={this.props.toggleCustomEmailModal}
                            showInviteToProjectModal={
                                this.props.toggleInviteVendorListToProjectModal
                            }
                            showVendorProfileModal={this.props.showVendorProfileModal}
                        />
                        {addVendorsToListModalOpen && (
                            <AddVendorsToListModal
                                governmentId={governmentId}
                                id={vendorList.id}
                                initialValues={{
                                    vendorListId: vendorList.id,
                                }}
                            />
                        )}
                    </Panel.Body>
                </Panel>
                {inviteToProjectModalOpen && (
                    <InviteVendorsToProjectModal initialValues={selectedUserData} />
                )}
                {customEmailModalOpen && (
                    <CustomEmailModal
                        data={selectedUserData}
                        hideModal={this.props.toggleCustomEmailModal}
                    />
                )}
            </div>
        );
    }
}

export const VendorListManager = compose(
    connectData(fetchData),
    withRouter,
    withFlags(),
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedVendorList);
