import PropTypes from 'prop-types';
import React from 'react';

import { RequisitionGroupReviewSequence } from './RequisitionGroupReviewSequence';
import {
    TemplateListTimestampColumns,
    TemplateStatusLabel,
    UserProfilePicture,
} from '../../../../components';
import { ARCHIVED, PUBLISHED } from '../../../../../../shared_config/reviewGroups';

export const RequisitionGroupsListItem = ({
    requisitionGroup: {
        created_at: createdAt,
        icon,
        lastUpdatedAt,
        name,
        reviewSequence,
        status,
        user,
    },
}) => {
    const styles = require('./index.scss');

    return (
        <div className="row">
            <div className="col-md-9">
                <div className="row">
                    <div className="col-md-5">
                        <span className={styles.groupName}>
                            <i className={`fa fa-fw fa-${icon}`} />
                            {name || 'Untitled'}
                        </span>
                        <TemplateStatusLabel
                            className={styles.statusLabel}
                            isArchived={status === ARCHIVED}
                            isPublished={status === PUBLISHED}
                        />
                    </div>
                    <div className="col-md-3 hidden-xs hidden-sm">
                        {user && (
                            <>
                                <UserProfilePicture horizontal user={user} />
                                &nbsp;&nbsp;
                                {user.displayName}
                            </>
                        )}
                    </div>
                    <div className="col-md-4 hidden-xs hidden-sm">
                        {reviewSequence && (
                            <RequisitionGroupReviewSequence reviewSequence={reviewSequence} />
                        )}
                    </div>
                </div>
            </div>
            <div className="col-md-3 hidden-xs hidden-sm">
                <TemplateListTimestampColumns createdAt={createdAt} lastUpdatedAt={lastUpdatedAt} />
            </div>
        </div>
    );
};

RequisitionGroupsListItem.propTypes = {
    requisitionGroup: PropTypes.shape({
        created_at: PropTypes.string.isRequired,
        icon: PropTypes.string,
        name: PropTypes.string,
        lastUpdatedAt: PropTypes.string.isRequired,
        reviewSequence: PropTypes.object,
        status: PropTypes.number.isRequired,
        user: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
        }),
    }).isRequired,
};
