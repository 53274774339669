import { ALL_SECTIONS, formSectionNames } from '../constants';
import validateInfo from './validateInfo';
import validateSteps from './validateSteps';
import { sectionsValidate } from '../../../../../../Forms/validation';
import { hasFormErrors } from '../../../../../../helpers';

const { INFO, STEPS } = formSectionNames;

export const validate = (values) => {
    const infoErrors = validateInfo(values);
    const stepsErrors = validateSteps(values);

    const sectionErrors = sectionsValidate([INFO, infoErrors], [STEPS, stepsErrors]);

    return {
        [ALL_SECTIONS]: hasFormErrors(sectionErrors),
        ...infoErrors,
        ...stepsErrors,
        ...sectionErrors,
    };
};
