import { last } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { fieldNames } from './constants';
import { TemplateQuestionAddButton } from '../../../TemplateQuestionAddButton';
import { ConditionalLogicIcon } from '../../../ConditionalLogicIcon';
import { QuestionnairesForm } from '../../../../../components';
import {
    upfrontQuestionTypesDisplay,
    upfrontQuestionConditionalTypes,
} from '../../../../../../../shared_config/questionnaires';
import {
    questionLogicLogicableModelNames,
    questionLogicLinkableModelNames,
} from '../../../../../../../shared_config/questionLogics';
import { OGThemeContext } from '../../../ogThemeProvider';

const { UPFRONT_QUESTIONS } = fieldNames;

export class TemplateUpfrontQuestionsForm extends PureComponent {
    static propTypes = {
        addConditionalLogicHandler: PropTypes.func.isRequired,
        array: PropTypes.object.isRequired,
        change: PropTypes.func.isRequired,
        createTemplateQuestion: PropTypes.func.isRequired,
        createUpfrontQuestion: PropTypes.func.isRequired,
        createUpfrontQuestionError: PropTypes.string,
        disabled: PropTypes.bool,
        isDirty: PropTypes.bool,
        showFormValidation: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        updateTemplate: PropTypes.func.isRequired,
        upfrontQuestions: PropTypes.array.isRequired,
    };

    static contextType = OGThemeContext;

    get styles() {
        return require('./index.scss');
    }

    renderConditionalQuestionLogicIcon = (upfrontQuestion, showCreateQuestionnaireModal) => {
        const { isOGThemeEnabledForComponents } = this.context;

        if (!upfrontQuestionConditionalTypes.includes(upfrontQuestion.type)) {
            return null;
        }

        const getInsertAfterQuestionId = (upfrontQuestions) => {
            // Find last sub question (if there are any)
            const subConditionalQuestionId = last(upfrontQuestion.conditionalSubQuestionIds || []);
            const subConditionalQuestion = upfrontQuestions.find(
                (question) => subConditionalQuestionId && question.id === subConditionalQuestionId
            );

            // Use the last sub question or the current question if no sub questions
            const upfrontQuestionToInsertAfter = subConditionalQuestion || upfrontQuestion;
            return upfrontQuestionToInsertAfter.orderById;
        };

        const addQuestionHandler = (questionLogicData) => {
            const { addConditionalLogicHandler, upfrontQuestions } = this.props;

            // Insert after the last sub question or the current question if no sub questions
            const insertAfterQuestionId = getInsertAfterQuestionId(upfrontQuestions);

            showCreateQuestionnaireModal({
                insertAfterQuestionId,
                onComplete: (questionnaire) => {
                    addConditionalLogicHandler({
                        ...questionLogicData,
                        logicable: questionLogicLogicableModelNames.UPFRONT_QUESTION,
                        linkable_id: questionnaire.id,
                        linkable: questionLogicLinkableModelNames.LINKED_UPFRONT_QUESTION,
                    });
                },
                // Used to temporarily hide the conditional question from the form
                // The entire form will be submitted and the return value will be used to replace
                // the entire form. There is too much data to keep track of in the form to attempt
                // to set the form state properly. Thus we just hide the intermediate state until
                // the full form updates.
                questionnaireDataToAddAfterCreation: {
                    isHiddenByLogic: true,
                },
            });
        };

        const addTemplateQuestionHandler = (questionLogicData, templateQuestionId) => {
            const {
                addConditionalLogicHandler,
                array,
                change,
                createTemplateQuestion,
                upfrontQuestions,
            } = this.props;

            // Insert after the last sub question or the current question if no sub questions
            const insertAfterQuestionId = getInsertAfterQuestionId(upfrontQuestions);

            return createTemplateQuestion(templateQuestionId, { silent: true }).then(
                (createdQuestionnaire) => {
                    // If creation fails do nothing further
                    if (!createdQuestionnaire || createdQuestionnaire instanceof Error) {
                        return;
                    }

                    // Re-order all questions downstream of the sub-question insertion
                    let insertAfterIndex;
                    upfrontQuestions.forEach((upfrontQ, index) => {
                        const oldOrderById = upfrontQ.orderById;
                        if (oldOrderById === insertAfterQuestionId) {
                            insertAfterIndex = index + 1;
                        }
                        if (oldOrderById > insertAfterQuestionId) {
                            change(`${UPFRONT_QUESTIONS}[${index}].orderById`, oldOrderById + 1);
                        }
                    });

                    // Inserts the new question into the upfront questions form array and
                    // temporarily hides the item as the whole form will be updated shortly
                    const newQuestionnaire = {
                        ...createdQuestionnaire,
                        orderById: insertAfterQuestionId + 1,
                        isHiddenByLogic: true,
                    };
                    array.splice(UPFRONT_QUESTIONS, insertAfterIndex, 0, newQuestionnaire);

                    // Includes a save of the template after question logic creation to preserve the inserted state of questionnaires
                    return addConditionalLogicHandler({
                        ...questionLogicData,
                        logicable: questionLogicLogicableModelNames.UPFRONT_QUESTION,
                        linkable_id: createdQuestionnaire.id,
                        linkable: questionLogicLinkableModelNames.LINKED_UPFRONT_QUESTION,
                    });
                }
            );
        };

        return (
            <ConditionalLogicIcon
                onAddQuestion={addQuestionHandler}
                onAddTemplateQuestion={addTemplateQuestionHandler}
                parentQuestion={upfrontQuestion}
                useOpenGovStyle={isOGThemeEnabledForComponents}
            />
        );
    };

    render() {
        const {
            array,
            change,
            createTemplateQuestion,
            createUpfrontQuestion,
            createUpfrontQuestionError,
            disabled,
            isDirty,
            showFormValidation,
            tagOptions,
            templateVariableOptions,
            updateTemplate,
            upfrontQuestions,
        } = this.props;
        const { isOGThemeEnabledForComponents } = this.context;
        const styles = this.styles;

        const TemplateQuestionAddButtonComponent = (size = null, nextOrderById = null) => (
            <div className={styles.templateQuestionAddButtonContainer}>
                <TemplateQuestionAddButton
                    addQuestion={(data, opt = {}) =>
                        createTemplateQuestion(data, opt, nextOrderById)
                    }
                    disabled={disabled}
                    isDirty={isDirty}
                    size={size}
                    updateTemplate={updateTemplate}
                    upfrontQuestionFormValues={upfrontQuestions}
                    useOpenGovStyle={isOGThemeEnabledForComponents}
                />
            </div>
        );

        return (
            <QuestionnairesForm
                TemplateQuestionAddButton={TemplateQuestionAddButtonComponent}
                addToBottomOnly
                allowDefaultValue
                array={array}
                change={change}
                createBeforeAddingError={createUpfrontQuestionError}
                createBeforeAddingHandler={createUpfrontQuestion}
                disabled={disabled}
                formKey={UPFRONT_QUESTIONS}
                label="List the questions you would like the document editor to answer"
                questionTypes={upfrontQuestionTypesDisplay}
                questionnaireDisplayName="Question"
                questionnaires={upfrontQuestions}
                renderQuestionLogicIcon={this.renderConditionalQuestionLogicIcon}
                requireByDefault
                showFormErrors={showFormValidation}
                tagOptions={tagOptions}
                templateVariableOptions={templateVariableOptions}
                usesTemplateQuestions
            />
        );
    }
}
