import { downloadCSV } from '../helpers';
import { getOpenSearchObject } from '../helpers/vendorSearch';
import { showSnackbar } from './notification';

export const SEARCH_VENDORS = 'public/vendor/SEARCH_VENDORS';
export const SEARCH_VENDORS_SUCCESS = 'public/vendor/SEARCH_VENDORS_SUCCESS';
export const SEARCH_VENDORS_FAIL = 'public/vendor/SEARCH_VENDORS_FAIL';

export function searchVendors(governmentId, search, isPublic) {
    return (dispatch, getState, client) => {
        dispatch({ type: SEARCH_VENDORS });

        const data = getOpenSearchObject(search);

        return client
            .post(
                isPublic
                    ? `/government/${governmentId}/vendor-search/searchPublic`
                    : `/government/${governmentId}/vendor-search/searchInternal`,
                {
                    data,
                }
            )
            .then((response) => {
                dispatch({ type: SEARCH_VENDORS_SUCCESS, response });
                return response;
            })
            .catch((error) => {
                dispatch({ type: SEARCH_VENDORS_FAIL, error });
            });
    };
}

export function downloadVendorSearch(governmentId, search, isPublic) {
    return (dispatch, getState, client) => {
        const data = getOpenSearchObject(search);

        return client
            .post(
                isPublic
                    ? `/government/${governmentId}/vendor-search/downloadPublic`
                    : `/government/${governmentId}/vendor-search/downloadInternal`,
                {
                    data,
                }
            )
            .then((result) => {
                downloadCSV(result, `vendors-${Date.now()}.csv`);
            })
            .catch(() => {
                dispatch(showSnackbar('Error Downloading', { isError: true }));
            });
    };
}
