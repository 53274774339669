exports.actionTypes = {
    CREATE: 'create',
    APPROVE: 'approve',
    REJECT: 'reject',
    EDIT: 'edit',
    SUBMIT: 'submit',
    STATUS_CHANGE: 'statusChange',
    ON_HOLD: 'onHold',
    OFF_HOLD: 'offHold',
    CLOSED_PO_CREATED: 'closedPoCreated',
    CLOSED_CANCELLED: 'closedCancelled',
    CLOSED_REJECTED: 'closedRejected',
    COMMENT: 'comment',
    BUDGET_OVERRIDE: 'budgetOverride',
};
