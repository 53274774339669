import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Checkbox, ListGroup, ListGroupItem } from 'react-bootstrap';

import { LINE_ITEM_AWARD, SCORED } from '../../../../../../../../shared_config/evaluations';
import { evaluationReportOptionsList } from '../../constants';

export class EvaluationSelectOptions extends PureComponent {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        evaluation: PropTypes.object.isRequired,
        reportName: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showAnonymizedEvaluators: false,
            showPercentageTotals: false,
            useLandscape: true,
        };
    }

    toggleLandscape = () => {
        this.setState((prevState) => ({ useLandscape: !prevState.useLandscape }));
    };

    toggleShowAnonymizedEvaluators = () => {
        this.setState((prevState) => ({
            showAnonymizedEvaluators: !prevState.showAnonymizedEvaluators,
        }));
    };

    toggleShowPercentageTotals = () => {
        this.setState((prevState) => ({ showPercentageTotals: !prevState.showPercentageTotals }));
    };

    getOptionText = (reportOption) => {
        const { evaluation } = this.props;
        let optionText = reportOption;

        if (evaluation.type === LINE_ITEM_AWARD) {
            optionText = reportOption === 'details' ? 'line item awards' : 'awards by vendor';
        }

        return startCase(optionText);
    };

    renderEvaluations() {
        const { evaluation, onClick } = this.props;
        const { showAnonymizedEvaluators, showPercentageTotals, useLandscape } = this.state;

        return (
            <>
                {evaluation.type === SCORED && (
                    <>
                        <Checkbox
                            checked={showAnonymizedEvaluators}
                            onChange={this.toggleShowAnonymizedEvaluators}
                        >
                            Anonymize evaluators
                        </Checkbox>
                        <Checkbox
                            checked={showPercentageTotals}
                            onChange={this.toggleShowPercentageTotals}
                        >
                            Show totals as percentages
                        </Checkbox>
                    </>
                )}
                <Checkbox checked={useLandscape} onChange={this.toggleLandscape}>
                    Landscape Layout
                </Checkbox>
                <ListGroup>
                    {evaluationReportOptionsList.map((reportOption) => (
                        <ListGroupItem
                            key={reportOption}
                            onClick={() =>
                                onClick({
                                    evaluation,
                                    reportOption,
                                    showAnonymizedEvaluators,
                                    showPercentageTotals,
                                    useLandscape,
                                })
                            }
                        >
                            <i className="fa fa-angle-right pull-right" />
                            {this.getOptionText(reportOption)}
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </>
        );
    }

    render() {
        const { reportName } = this.props;

        return (
            <>
                <h5 style={{ marginBottom: 15 }}>Choose which type of {reportName}</h5>
                {this.renderEvaluations()}
            </>
        );
    }
}
