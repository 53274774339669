import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { validate } from './validate';
import { fieldNames } from '../constants';
import { Button, HelpIcon, InputText, Toggle } from '../../../../components';
import { userRolePermissions } from '../../../../../../shared_config/userRoles';

const { IS_DEPARTMENT_EDITOR, IS_GLOBAL_EDITOR } = userRolePermissions;

const { NAME } = fieldNames;

const mapStateToProps = (state, props) => {
    return {
        isDepartmentEditorValue: formValueSelector(props.form)(state, IS_DEPARTMENT_EDITOR),
        isGlobalEditorValue: formValueSelector(props.form)(state, IS_GLOBAL_EDITOR),
    };
};

const formConfig = {
    validate,
};

// @connect
// @reduxForm
class ConnectedUserRoleForm extends PureComponent {
    static propTypes = {
        closeForm: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        isNew: PropTypes.bool,
        options: PropTypes.array.isRequired,
        serverError: PropTypes.string,
    };

    renderControlButtons() {
        const { closeForm, disabled, isNew } = this.props;

        return (
            <div className="text-center">
                <Button
                    block
                    bsStyle={isNew ? 'success' : 'primary'}
                    disabled={disabled}
                    type="submit"
                >
                    {isNew ? (
                        <span>
                            <i className="fa fa-plus" /> Create Role
                        </span>
                    ) : (
                        <span>
                            <i className="fa fa-pencil" /> Update
                        </span>
                    )}
                </Button>
                <Button bsSize="sm" bsStyle="link" disabled={disabled} onClick={closeForm}>
                    Cancel
                </Button>
            </div>
        );
    }

    render() {
        const { disabled, handleSubmit, options, serverError } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-9">
                        <Field
                            aria-label="Enter Role Name"
                            autoFocus
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            help="Enter a name for the role"
                            name={NAME}
                            placeholder="Role name"
                            qaTag="userRoleForm-name"
                            type="text"
                        />
                    </div>
                    <div className="col-sm-3">{this.renderControlButtons()}</div>
                </div>
                {serverError && (
                    <div className="col-xs-12 text-danger text-right">{serverError}</div>
                )}
                <div className="row">
                    <div className="col-sm-9 text-right">
                        {options.map(({ groupName, hide: hideGroup, permissions }) => {
                            if (hideGroup) {
                                return null;
                            }
                            return (
                                <div key={groupName}>
                                    <label>{groupName} Permissions:</label>
                                    {permissions.map(
                                        ({ disabledFn, help, hide, label, name, onChange }) => {
                                            if (hide) {
                                                return null;
                                            }
                                            return (
                                                <Field
                                                    component={Toggle}
                                                    disabled={
                                                        disabled ||
                                                        (disabledFn && disabledFn(this.props))
                                                    }
                                                    key={name}
                                                    label={
                                                        <span>
                                                            {label}
                                                            <HelpIcon tooltip={help} />
                                                        </span>
                                                    }
                                                    labelInline
                                                    labelInlineTogglePosition="right"
                                                    name={name}
                                                    onChange={onChange && onChange(this.props)}
                                                    qaTag={`userRoleForm-${name}`}
                                                />
                                            );
                                        }
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </form>
        );
    }
}

export const UserRoleForm = compose(
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedUserRoleForm);
