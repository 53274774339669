const { priceItemFieldNames, discountTypes } = require('.');

const { QUANTITY, TAXABLE, UNIT_PRICE, DISCOUNT, DISCOUNT_TYPE } = priceItemFieldNames;

const DISCOUNT_PCT_CALCULATION_DIGITS = 4;

exports.isPercentageDiscount = (discountType) => {
    return discountType === discountTypes.PERCENTAGE;
};

exports.isAmountDiscount = (discountType) => {
    return discountType === discountTypes.DOLLAR_AMOUNT;
};

const addTax = (amount, taxRate) => {
    return amount + amount * (taxRate / 100);
};

exports.getPriceItemExtendedPrice = (quantity, unitPrice, discount, discountType, tax) => {
    let price = quantity * unitPrice;

    if (exports.isPercentageDiscount(discountType)) {
        const decimal = discount / 100;
        price -= Math.round(price * decimal * 10 ** DISCOUNT_PCT_CALCULATION_DIGITS) / 10000;
    }

    if (exports.isAmountDiscount(discountType)) {
        price -= discount;
    }

    if (tax) {
        price = addTax(price, tax);
    }

    // Round up to 2 decimal places
    return Math.ceil(price * 100) / 100;
};

exports.getPriceItemsSummary = (priceItems, salesTaxRate = 0) => {
    if (!priceItems || !Array.isArray(priceItems) || !priceItems.length) {
        return {
            subTotal: 0,
            taxes: 0,
            total: 0,
        };
    }

    let subTotal = 0;
    let taxableSubTotal = 0;

    priceItems.forEach((priceItem) => {
        const quantity = Number(priceItem[QUANTITY]);
        const unitPrice = Number(priceItem[UNIT_PRICE]);
        const discount = Number(priceItem[DISCOUNT]);
        const discountType = Number(priceItem[DISCOUNT_TYPE]);

        const itemTotal = exports.getPriceItemExtendedPrice(
            quantity,
            unitPrice,
            discount,
            discountType
        );
        subTotal += itemTotal;

        if (priceItem[TAXABLE]) {
            taxableSubTotal += itemTotal;
        }
    });

    const taxes = taxableSubTotal * (salesTaxRate / 100);
    const total = subTotal + taxes;

    return {
        subTotal,
        taxes,
        total,
    };
};
