import {
    defaultSectionConfigsMap,
    sectionTypeNames,
} from '../../../../../../../shared_config/sections';
import { templateSectionTypesDict } from '../../../../../../../shared_config/templateSections';

const { SCOPE, QUESTIONNAIRE, PRICING, EVALUATION_PHASE, TERMS, TEXT_AREA } = sectionTypeNames;

const { CONTRACT, GENERAL, PURCHASE } = templateSectionTypesDict;

const getSectionOptions = (projectType) => {
    switch (projectType) {
        case PURCHASE:
            return [SCOPE, QUESTIONNAIRE, PRICING, EVALUATION_PHASE, TERMS, TEXT_AREA];
        case GENERAL:
        case CONTRACT:
            return [TERMS, TEXT_AREA];
        default:
            return [];
    }
};

export const getSectionSelectOptions = (projectType) => {
    return getSectionOptions(projectType).map((sectionType) => {
        const { title, icon, section_type: type } = defaultSectionConfigsMap[sectionType];

        return {
            icon,
            label: title,
            value: type,
        };
    });
};
