import { last } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { CategorySelect } from './CategorySelect';
import { CreateConfirmation } from './CreateConfirmation';
import { ModuleSelect } from './ModuleSelect';
import { ProjectSelect } from './ProjectSelect';
import { Button, LoadingError, TemplateSelect } from '../../../../components';
import { projectTypesDict } from '../../../../../../shared_config/projects';
import { ReviewGroupSelect } from './ReviewGroupSelect';
import { RequisitionCreateConfirmation } from './RequisitionCreateConfirmation';

const { PURCHASE } = projectTypesDict;

const IMAGE = 'https://assets.procurement.opengov.com/assets/loading-spinner.gif';

const REQ_CREATE_REVIEW_GROUP = 'REQ_CREATE_REVIEW_GROUP';
const REQ_CREATE_CONFIRMATION = 'REQ_CREATE_CONFIRMATION';

export class ModalContent extends PureComponent {
    static propTypes = {
        createBuilderProject: PropTypes.func.isRequired,
        createContract: PropTypes.func.isRequired,
        createEvaluationProject: PropTypes.func.isRequired,
        createPostProject: PropTypes.func.isRequired,
        createIntakeProject: PropTypes.func.isRequired,
        createRequisition: PropTypes.func.isRequired,
        defaultProcurementContactId: PropTypes.number,
        error: PropTypes.string,
        hasContracting: PropTypes.bool.isRequired,
        hasDocBuilder: PropTypes.bool.isRequired,
        hasEvaluation: PropTypes.bool.isRequired,
        hasIntake: PropTypes.bool.isRequired,
        hasRequisition: PropTypes.bool.isRequired,
        hasSourcing: PropTypes.bool.isRequired,
        initialData: PropTypes.object,
        isContractAdmin: PropTypes.bool.isRequired,
        isProjectCreator: PropTypes.bool.isRequired,
        isTemplateAdmin: PropTypes.bool.isRequired,
        hideCreateModal: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        loadingText: PropTypes.string,
        resetCreateError: PropTypes.func.isRequired,
        showLibraryModal: PropTypes.func.isRequired,
        templates: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                projectType: PropTypes.string.isRequired,
            })
        ).isRequired,
        user: PropTypes.object.isRequired,
        usesCategorySelect: PropTypes.bool.isRequired,
    };

    state = {
        associatedProject: null,
        categories: [],
        copiedScope: [],
        fromIntake: false,
        isContracts: null,
        isEvaluationOnly: null,
        isPostOnly: null,
        isIntake: null,
        isDocBuilder: false,
        scopeCriteria: [],
        steps: [],
        template: null,
        type: PURCHASE,
    };

    UNSAFE_componentWillMount() {
        const { hasContracting, hasIntake, hasRequisition, initialData, usesCategorySelect } =
            this.props;

        if (initialData) {
            this.setState(initialData);
            if (initialData.steps) {
                return;
            }

            if (initialData.isContracts || initialData.isIntake) {
                return this.setState({
                    steps: [usesCategorySelect ? 3 : 6],
                });
            }

            if (initialData.isRequisition) {
                return this.setState({
                    steps: [REQ_CREATE_REVIEW_GROUP],
                });
            }
        }

        // Render module select page if gov has contracting, intakes, or requisitions
        if (hasContracting || hasIntake || hasRequisition || !this.canCreateProjects) {
            this.setState({ steps: [1] });
            return;
        }

        // Otherwise automatically select the new project option and move to the next page
        this.moduleSelectHandler();
    }

    get canCreateProjects() {
        const { isProjectCreator } = this.props;

        return isProjectCreator || this.templates.length > 0;
    }

    get currentPage() {
        return last(this.state.steps);
    }

    get isWritingProject() {
        const { isContracts, isEvaluationOnly, isPostOnly, isIntake } = this.state;
        return !isContracts && !isEvaluationOnly && !isPostOnly && !isIntake;
    }

    get templates() {
        const { isProjectCreator, templates } = this.props;

        const { type } = this.state;

        return templates.filter((template) => {
            return template.projectType === type && (isProjectCreator || template.isGlobalCreate);
        });
    }

    getModalData = (options = {}) => {
        const { associatedProject, categories, createData } = this.state;

        const categoriesData = options.fullCategoryObjects
            ? categories
            : categories.map((cat) => cat.id);

        return {
            ...createData,
            categories: categoriesData,
            projectAssociationId: associatedProject ? associatedProject.id : undefined,
        };
    };

    backHandler = () => {
        this.setState((prevState) => {
            return {
                steps: prevState.steps.slice(0, prevState.steps.length - 1),
            };
        });
    };

    categorySelectHandler = (categories) => {
        if (this.isWritingProject && this.templates.length === 1) {
            this.setState({ categories });
            this.templateSelectHandler(this.templates[0]);
            return;
        }

        this.setState((prevState) => {
            return {
                categories,
                steps: [...prevState.steps, this.isWritingProject ? 5 : 6],
            };
        });
    };

    createContract = () => {
        this.props.createContract(this.getModalData({ fullCategoryObjects: true }));
    };

    createEvaluationProject = () => {
        this.props.createEvaluationProject(this.getModalData());
    };

    createPostProject = () => {
        this.props.createPostProject(this.getModalData());
    };

    createProjectHandler = () => {
        const { isContracts, isEvaluationOnly, isPostOnly, isIntake } = this.state;

        if (isIntake) {
            this.createIntakeProject();
        } else if (isPostOnly) {
            this.createPostProject();
        } else if (isEvaluationOnly) {
            this.createEvaluationProject();
        } else if (isContracts) {
            this.createContract();
        } else {
            this.createBuilderProject();
        }
    };

    createRequisitionHandler = () => {
        this.props.createRequisition(this.state.createData);
    };

    createIntakeProject = () => {
        const { copiedScope, scopeCriteria } = this.state;

        this.props.createIntakeProject({
            ...this.getModalData(),
            scopeCriteria: scopeCriteria.concat(copiedScope),
        });
    };

    createBuilderProject = () => {
        const { createBuilderProject } = this.props;

        const { copiedScope, scopeCriteria, template } = this.state;

        createBuilderProject({
            ...this.getModalData(),
            scopeCriteria: scopeCriteria.concat(copiedScope),
            template_id: template.id,
        });
    };

    hideModal = () => {
        this.props.hideCreateModal();
    };

    moduleSelectHandler = (data = {}) => {
        const { isContracts, isDocBuilder, isIntake, isRequisition } = data;

        if (isContracts) {
            return this.projectSelectHandler({ isContracts: true });
        }

        if (isIntake) {
            return this.projectSelectHandler({ isIntake: true });
        }

        if (isRequisition) {
            return this.requisitionSelectHandler();
        }

        this.setState((prevState) => {
            return {
                isDocBuilder,
                steps: [...prevState.steps, 2],
            };
        });
    };

    requisitionSelectHandler = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                steps: [...prevState.steps, REQ_CREATE_REVIEW_GROUP],
            };
        });
    };

    reviewGroupSelectHandler = (data = {}) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                ...data,
                steps: [...prevState.steps, REQ_CREATE_CONFIRMATION],
            };
        });
    };

    projectSelectHandler = (data = {}) => {
        const { isContracts, isEvaluationOnly, isPostOnly, isIntake, type = PURCHASE } = data;

        const { usesCategorySelect } = this.props;

        const { associatedProject, categories, fromIntake } = this.state;

        // Reset template in case it was set earlier
        // (isPostOnly, isEvaluationOnly & isContracts projects do not use template)
        const projectSelectData = {
            isContracts,
            isEvaluationOnly,
            isPostOnly,
            isIntake,
            isDocBuilder: type !== PURCHASE,
            template: null,
            type,
        };

        if (associatedProject) {
            this.setState(projectSelectData, () => {
                this.categorySelectHandler(associatedProject.categories);
            });
        } else if (fromIntake) {
            this.setState(projectSelectData, () => this.categorySelectHandler(categories));
        } else if (!usesCategorySelect || projectSelectData.isDocBuilder) {
            // Use the category select handler to choose the next step when not selecting categories
            this.setState(projectSelectData, () => this.categorySelectHandler([]));
        } else {
            this.setState((prevState) => {
                return {
                    ...projectSelectData,
                    steps: [...prevState.steps, 3],
                };
            });
        }
    };

    scopeSelectHandler = (scopeCriteria) => {
        this.setState({ scopeCriteria });
    };

    showLibraryModal = () => {
        const { showLibraryModal } = this.props;
        const { categories } = this.state;

        showLibraryModal(this.scopeSelectHandler, {
            categories,
        });
    };

    submitProjectOwnershipForm = (projectOwnershipFormData) => {
        this.setState((prevState) => {
            return {
                createData: {
                    ...prevState.createData,
                    ...projectOwnershipFormData,
                },
            };
        }, this.createProjectHandler);
    };

    submitRequisitionCreateForm = (requisitionCreateFormData) => {
        this.setState((prevState) => {
            return {
                createData: {
                    ...prevState.createData,
                    ...requisitionCreateFormData,
                },
            };
        }, this.createRequisitionHandler);
    };

    templateSelectHandler = (template) => {
        this.setState((prevState) => {
            return {
                steps: [...prevState.steps, 6],
                template,
            };
        });
    };

    renderContent() {
        const {
            defaultProcurementContactId,
            error,
            hasContracting,
            hasDocBuilder,
            hasEvaluation,
            hasIntake,
            hasRequisition,
            hasSourcing,
            isContractAdmin,
            isTemplateAdmin,
            loading,
            resetCreateError,
            user,
            usesCategorySelect,
        } = this.props;

        const {
            associatedProject,
            categories,
            copiedScope,
            createData,
            isContracts,
            isDocBuilder,
            isEvaluationOnly,
            isPostOnly,
            isIntake,
            scopeCriteria,
            template,
            type,
        } = this.state;

        if (loading) {
            return this.renderLoading();
        }

        if (error) {
            return (
                <LoadingError
                    error={error}
                    info={<span className="pseudoLink">Click here to try again</span>}
                    noOffset
                    onClick={resetCreateError}
                />
            );
        }

        switch (this.currentPage) {
            case 1:
                return (
                    <ModuleSelect
                        clickHandler={this.moduleSelectHandler}
                        hasContracting={hasContracting}
                        hasIntake={hasIntake}
                        hasProject={hasDocBuilder || hasSourcing || hasEvaluation}
                        hasRequisition={hasRequisition}
                        isContractAdmin={isContractAdmin}
                        isProjectCreator={this.canCreateProjects}
                    />
                );
            case 2:
                return (
                    <ProjectSelect
                        clickHandler={this.projectSelectHandler}
                        hasContracting={hasContracting}
                        hasDocBuilder={hasDocBuilder}
                        hasEvaluation={hasEvaluation}
                        hasSourcing={hasSourcing}
                        isDocBuilder={isDocBuilder}
                    />
                );
            case 3:
                return (
                    <CategorySelect
                        categories={categories}
                        clickHandler={this.categorySelectHandler}
                        isBuilder={this.isWritingProject}
                        isContracts={isContracts}
                        showNextArrow
                    />
                );
            case 4:
                // Used to be budget select. Can be removed now.
                return null;
            case 5: {
                return (
                    <TemplateSelect
                        isTemplateAdmin={isTemplateAdmin}
                        onSelect={this.templateSelectHandler}
                        templateAdminUrl={`/governments/${user.government_id}/templates-admin`}
                        templateSupportUrl={user.government.templateSupportUrl}
                        templates={this.templates}
                        type={type}
                    />
                );
            }
            case 6:
                return (
                    <CreateConfirmation
                        associatedProject={associatedProject}
                        categories={categories}
                        copiedScopeCount={copiedScope.length + scopeCriteria.length}
                        createData={createData}
                        defaultProcurementContactId={defaultProcurementContactId}
                        isContracts={isContracts}
                        isDocBuilder={isDocBuilder}
                        isEvaluationOnly={isEvaluationOnly}
                        isIntake={isIntake}
                        isPostOnly={isPostOnly}
                        showLibraryModal={this.showLibraryModal}
                        submitHandler={this.submitProjectOwnershipForm}
                        template={template && template.title}
                        type={type}
                        user={user}
                        usesCategorySelect={usesCategorySelect}
                    />
                );
            case REQ_CREATE_REVIEW_GROUP:
                return <ReviewGroupSelect clickHandler={this.reviewGroupSelectHandler} />;
            case REQ_CREATE_CONFIRMATION:
                return (
                    <RequisitionCreateConfirmation
                        data={this.state}
                        submitHandler={this.submitRequisitionCreateForm}
                        user={user}
                    />
                );
            default:
                // Needed since initial render has no step defined
                return null;
        }
    }

    renderFooter() {
        const styles = require('./shared.scss');
        if (this.state.steps.length === 1 || this.props.loading) {
            return (
                <Button
                    className={styles.closeBtn}
                    onClick={this.hideModal}
                    qaTag="projectCreateModal-close"
                >
                    Close
                </Button>
            );
        }

        return (
            <div className={styles.backBtnContainer}>
                <Button
                    className={styles.backBtn}
                    onClick={this.backHandler}
                    qaTag="projectCreateModal-back"
                >
                    <i className="fa fa-arrow-left" />
                    &nbsp;Back
                </Button>
            </div>
        );
    }

    renderLoading() {
        const styles = require('./shared.scss');

        return (
            <div className={styles.loadingContainer}>
                <div className={styles.loadingBody}>
                    <img alt="Loading spinner" className={styles.loadingSpinner} src={IMAGE} />
                </div>
                <h4>{this.props.loadingText}</h4>
            </div>
        );
    }

    render() {
        const { integration } = this.state;

        const styles = require('./shared.scss');
        return (
            <Modal className={styles.projectCreateModal} onHide={this.hideModal} show>
                <Modal.Body>
                    {integration && (
                        <Alert bsStyle="info" className={styles.integrationAlert}>
                            Using data provided from integration partner
                            {integration !== true && (
                                <span>
                                    : <strong>{integration}</strong>
                                </span>
                            )}
                        </Alert>
                    )}
                    <div className={styles.projectCreateModalContainer}>{this.renderContent()}</div>
                </Modal.Body>
                <Modal.Footer>{this.renderFooter()}</Modal.Footer>
            </Modal>
        );
    }
}
