import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getProjectsJS, getCategoriesJS, getProjectJS } from '../selectors';
import * as libraryActions from '../../../../actions/projectLibrary';
import { sectionTypeNames } from '../../../../../../shared_config/sections';
import { subsectionTypeNames } from '../../../../../../shared_config/subsections';

const { SCOPE } = sectionTypeNames;
const { BODY } = subsectionTypeNames;

const mapStateToProps = (state) => ({
    categories: getCategoriesJS(state),
    projects: getProjectsJS(state),
    projectsCount: state.projectLibrary.get('projectsCount'),
    paginationPage: state.projectLibrary.get('paginationPage'),
    selectedProject: getProjectJS(state),
    sortField: state.projectLibrary.get('sortField'),
    sortDirection: state.projectLibrary.get('sortDirection'),
    searchValue: state.projectLibrary.get('searchValue'),
    currentSearch: state.projectLibrary.get('currentSearch'),
    loading: state.projectLibrary.get('loading'),
    loaded: state.projectLibrary.get('loaded'),
    loadError: state.projectLibrary.get('loadError'),
    loadingProject: state.projectLibrary.get('loadingProject'),
    loadProjectError: state.projectLibrary.get('loadProjectError'),
});

export function ProjectLibraryHOC(InnerComponent) {
    class OuterComponent extends Component {
        static propTypes = {
            projects: PropTypes.array.isRequired,
            projectsCount: PropTypes.number,
            paginationPage: PropTypes.number.isRequired,
            categories: PropTypes.array.isRequired,
            selectedProject: PropTypes.object,
            sortField: PropTypes.string.isRequired,
            sortDirection: PropTypes.string.isRequired,
            searchValue: PropTypes.string.isRequired,
            currentSearch: PropTypes.string,
            loading: PropTypes.bool.isRequired,
            loaded: PropTypes.bool.isRequired,
            loadingProject: PropTypes.bool.isRequired,
            loadError: PropTypes.string,
            loadProjectError: PropTypes.string,
            isModal: PropTypes.bool,
            resetLibrary: PropTypes.func.isRequired,
            selectCategories: PropTypes.func.isRequired,
            searchLibrary: PropTypes.func.isRequired,
            loadProject: PropTypes.func.isRequired,
            toggleScopeItem: PropTypes.func.isRequired,
            changePage: PropTypes.func.isRequired,
            copyFunction: PropTypes.func,
            selectAllScope: PropTypes.func.isRequired,
            pageResults: PropTypes.func.isRequired,
            incrementCopyCount: PropTypes.func.isRequired,
            selectSort: PropTypes.func.isRequired,
            toggleSortDirection: PropTypes.func.isRequired,
            updateSearchField: PropTypes.func.isRequired,
        };

        categoriesSelector = (e, categories) => {
            this.props.selectCategories(categories);
            this.props.pageResults(1);
            this.props.searchLibrary();
        };

        selectScopeItem = (scopeItem) => {
            this.props.toggleScopeItem(scopeItem.id);
        };

        pageResults = (pageNum) => {
            this.props.pageResults(pageNum);
            this.props.searchLibrary();
        };

        sortSelector = (sortOption) => {
            this.props.selectSort(sortOption);
            this.props.searchLibrary();
        };

        sortDirectionSelector = () => {
            this.props.toggleSortDirection();
            this.props.searchLibrary();
        };

        copyScopeItems = (scopeItems) => {
            const selectedCriteria = scopeItems
                .filter((crit) => crit.selected)
                .map((crit, idx) => ({
                    title: crit.title,
                    description: crit.description,
                    orderById: idx + 1,
                    section_type: SCOPE,
                    subsection_type: BODY,
                }));
            this.props.copyFunction(selectedCriteria);
            this.props.incrementCopyCount();
            if (this.props.isModal) {
                return this.props.resetLibrary();
            }
        };

        searchSubmitHandler = (formData) => {
            const { summarySearch } = formData;
            this.props.pageResults(1);
            this.props.updateSearchField(summarySearch);
            this.props.searchLibrary();
        };

        render() {
            return (
                <InnerComponent
                    {...this.props}
                    categoriesSelectHandler={this.categoriesSelector}
                    copyClickHandler={this.copyScopeItems}
                    pageResults={this.pageResults}
                    searchSubmitHandler={this.searchSubmitHandler}
                    selectClickHandler={this.selectScopeItem}
                    sortDirectionHandler={this.sortDirectionSelector}
                    sortHandler={this.sortSelector}
                />
            );
        }
    }

    return connect(mapStateToProps, libraryActions)(OuterComponent);
}
