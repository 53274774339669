import { browserHistory } from '@og-pro-migration-tools/react-router';

import { showConfirmationSimpleModal } from './confirmation';
import { resetError } from './error';
import { showNotification, showSnackbar } from './notification';
import { getUserHomepage } from '../helpers';
import { getControlPanelLoginUrl } from '../utils';
import { isTier1SupportUser } from '../../../shared_config/helpers';
import { basicUserRoles } from '../../../shared_config/userRoles';
import { userStatusTypes } from '../../../shared_config/users';

const { BATMAN } = basicUserRoles;

const { ACTIVE } = userStatusTypes;

export const LOAD = 'auth/LOAD';
export const LOAD_SUCCESS = 'auth/LOAD_SUCCESS';
export const LOAD_FAIL = 'auth/LOAD_FAIL';
export const LOGIN = 'auth/LOGIN';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'auth/LOGIN_FAIL';
export const LOGIN_FAIL_REMOVE = 'auth/LOGIN_FAIL_REMOVE';
export const ROUTE_LOGIN_EMAIL = 'auth/ROUTE_LOGIN_EMAIL';
export const TOKEN_LOGIN = 'auth/TOKEN_LOGIN';
export const TOKEN_LOGIN_SUCCESS = 'auth/TOKEN_LOGIN_SUCCESS';
export const TOKEN_LOGIN_FAIL = 'auth/TOKEN_LOGIN_FAIL';
export const RESET_PASSWORD = 'auth/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'auth/RESET_PASSWORD_FAIL';
export const SET_PASSWORD = 'auth/SET_PASSWORD';
export const SET_PASSWORD_SUCCESS = 'auth/SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAIL = 'auth/SET_PASSWORD_FAIL';

export const ACCOUNT_ACTIVATION_RESENT = 'auth/ACCOUNT_ACTIVATION_RESENT';
export const REQUEST_TO_JOIN_PENDING = 'auth/REQUEST_TO_JOIN_PENDING';
export const FOUND_ORGANIZATION_TO_JOIN = 'auth/FOUND_ORGANIZATION_TO_JOIN';

export const REQUEST_TO_JOIN = 'auth/REQUEST_TO_JOIN';
export const REQUEST_TO_JOIN_SUCCESS = 'auth/REQUEST_TO_JOIN_SUCCESS';
export const REQUEST_TO_JOIN_FAIL = 'auth/REQUEST_TO_JOIN_FAIL';

export const CANCEL_REQUEST_TO_JOIN = 'auth/CANCEL_REQUEST_TO_JOIN';
export const CANCEL_REQUEST_TO_JOIN_FAIL = 'auth/CANCEL_REQUEST_TO_JOIN_FAIL';

export const VENDOR_SIGNUP = 'auth/VENDOR_SIGNUP';
export const VENDOR_SIGNUP_SUCCESS = 'auth/VENDOR_SIGNUP_SUCCESS';
export const VENDOR_SIGNUP_FAIL = 'auth/VENDOR_SIGNUP_FAIL';

export const RESET_AUTH = 'auth/RESET_AUTH';

export const LOGOUT = 'auth/LOGOUT';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'auth/LOGOUT_FAIL';

export const SHOW_LOGIN_MODAL = 'auth/SHOW_LOGIN_MODAL';
export const HIDE_LOGIN_MODAL = 'auth/HIDE_LOGIN_MODAL';

export const CREATE_FILTER = 'auth/CREATE_FILTER';
export const CREATE_FILTER_FAIL = 'auth/CREATE_FILTER_FAIL';
export const CREATE_FILTER_SUCCESS = 'auth/CREATE_FILTER_SUCCESS';

export const DELETE_FILTER = 'auth/DELETE_FILTER';
export const DELETE_FILTER_FAIL = 'auth/DELETE_FILTER_FAIL';
export const DELETE_FILTER_SUCCESS = 'auth/DELETE_FILTER_SUCCESS';

export const MAKE_FILTER_DEFAULT = 'auth/MAKE_FILTER_DEFAULT';
export const MAKE_FILTER_DEFAULT_FAIL = 'auth/MAKE_FILTER_DEFAULT_FAIL';
export const MAKE_FILTER_DEFAULT_SUCCESS = 'auth/MAKE_FILTER_DEFAULT_SUCCESS';

export const SHOW_MANAGE_SAVED_FILTER_MODAL = 'auth/SHOW_MANAGE_SAVED_FILTER_MODAL';
export function showManageSavedFilterModal(filter) {
    return { type: SHOW_MANAGE_SAVED_FILTER_MODAL, filter };
}

export const HIDE_MANAGE_SAVED_FILTER_MODAL = 'auth/HIDE_MANAGE_SAVED_FILTER_MODAL';
export function hideManageSavedFilterModal() {
    return { type: HIDE_MANAGE_SAVED_FILTER_MODAL };
}

export const STORE_LAST_FILTER = 'auth/STORE_LAST_FILTER';

export const UPDATE_FILTER = 'auth/UPDATE_FILTER';
export const UPDATE_FILTER_FAIL = 'auth/UPDATE_FILTER_FAIL';
export const UPDATE_FILTER_SUCCESS = 'auth/UPDATE_FILTER_SUCCESS';

function accountActivationResent(email) {
    return (dispatch) => {
        dispatch({ type: ACCOUNT_ACTIVATION_RESENT, email });
        browserHistory.push('/resend-activation');
    };
}

function requestToJoinPending(user) {
    return (dispatch) => {
        dispatch({ type: REQUEST_TO_JOIN_PENDING, user });
        browserHistory.push('/request-pending');
    };
}

export function resetAuth() {
    return { type: RESET_AUTH };
}

export function load() {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD });
        return client
            .get('/auth/load')
            .then((result) => dispatch({ type: LOAD_SUCCESS, result }))
            .catch((error) => {
                dispatch({ type: LOAD_FAIL, error });
            });
    };
}

export function loginFailError(errorMessage, isSessionTimeout) {
    return {
        error: errorMessage,
        isSessionTimeout,
        type: LOGIN_FAIL,
    };
}

export function login(username, password, opts = {}) {
    return (dispatch, getState, client) => {
        const { onComplete, isSessionTimeout } = opts;
        dispatch({ type: LOGIN });

        const postData = {
            data: { username, password },
        };
        return client
            .post('/auth/login', postData)
            .then((result) => {
                dispatch({ type: LOGIN_SUCCESS, result });

                // Use the onComplete handler if one is provided
                if (onComplete) {
                    // Do not return onComplete in case it errors
                    // (do not want to catch onComplete errors here)
                    return setTimeout(() => onComplete(result));
                }

                // Route to last page if user's cookie expired mid-session
                if (getState().error.get('nextRoute')) {
                    browserHistory.push(getState().error.get('nextRoute'));
                    return dispatch(resetError());
                }

                // Route support users to the support app dashboard
                if (process.env.APP_NAME === 'support') {
                    if (isTier1SupportUser(result)) {
                        return browserHistory.push('/users');
                    }
                    return browserHistory.push('/governments');
                }

                // Route government users to government dashboard
                const governmentId = result.government_id;
                if (governmentId) {
                    return browserHistory.push(getUserHomepage(result));
                }

                // Route vendor users to vendor dashboard
                const vendorId = result.vendor_id;
                if (vendorId) {
                    return browserHistory.push(`/vendors/${vendorId}`);
                }

                // Route all other users to homepage
                return browserHistory.push('/');
            })
            .catch((err) => {
                dispatch(loginFailError(err.message, isSessionTimeout));
            });
    };
}

export function loginRouter(username, usePlatform, onSuccess) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOGIN });

        const postData = {
            data: { username, usePlatform },
        };
        return client
            .post('/auth/router', postData)
            .then(({ loginWithOpenGov }) => {
                dispatch({ type: ROUTE_LOGIN_EMAIL, loginWithOpenGov });
                if (loginWithOpenGov) {
                    window.location.href = getControlPanelLoginUrl(username);
                    return;
                }
                onSuccess(username);
            })
            .catch((err) => dispatch(loginFailError(err.message)));
    };
}

export function resetPassword(email) {
    return (dispatch, getState, client) => {
        dispatch({ type: RESET_PASSWORD });

        const postData = {
            data: { email },
        };
        return client
            .post('/auth/reset-password', postData)
            .then((result) => {
                dispatch({ type: RESET_PASSWORD_SUCCESS });
                if (result.isInvited) {
                    return dispatch(accountActivationResent(result.user.email));
                }
                if (result.isRequested) {
                    return dispatch(requestToJoinPending(result.user));
                }
                if (result.isManagedByPlatform) {
                    const ogPlatformPasswordReset = `${
                        process.env.OG_CONTROL_PANEL_URL
                    }/auth/users/sign_in?login_hint=${encodeURIComponent(
                        result.user.email
                    )}&send_reset=true&return_to=${process.env.API_HOST}/auth/opengov`;
                    window.location.href = ogPlatformPasswordReset;
                    return;
                }

                dispatch(
                    showNotification(
                        'An email has been sent to you with a link to reset your password',
                        { duration: 8000 }
                    )
                );
                browserHistory.push('/login');
            })
            .catch((error) => {
                dispatch({ type: RESET_PASSWORD_FAIL, error: error.message });
            });
    };
}

export function resetPasswordOld(email) {
    return (dispatch, getState, client) => {
        dispatch({ type: RESET_PASSWORD });

        const postData = {
            data: { email },
        };
        return client
            .post('/auth/reset-password', postData)
            .then((result) => {
                dispatch({ type: RESET_PASSWORD_SUCCESS });
                if (result.isInvited) {
                    return dispatch(accountActivationResent(result.user.email));
                }
                if (result.isRequested) {
                    return dispatch(requestToJoinPending(result.user));
                }
                if (result.isManagedByPlatform) {
                    setTimeout(() => {
                        const ogPlatformPasswordReset = `${process.env.OG_CONTROL_PANEL_URL}/auth/users/password/new`;
                        window.location.href = ogPlatformPasswordReset;
                    }, 3000);
                    return dispatch(
                        showConfirmationSimpleModal(() => {}, {
                            bsSize: 'sm',
                            bsStyle: 'opengov',
                            btnText: 'Okay',
                            hideCancelButton: true,
                            icon: null,
                            text: 'Your user account is managed by OpenGov.\n\nIn a few seconds, you will be redirected to OpenGov to reset your password.',
                            title: 'Reset Password',
                        })
                    );
                }

                dispatch(
                    showNotification(
                        'An email has been sent to you with a link to reset your password',
                        { duration: 8000 }
                    )
                );
                browserHistory.push('/login');
            })
            .catch((error) => {
                dispatch({ type: RESET_PASSWORD_FAIL, error: error.message });
            });
    };
}

export function tokenLogin(id, token) {
    return (dispatch, getState, client) => {
        dispatch({ type: TOKEN_LOGIN });

        const postData = {
            data: { id, token },
        };

        return client
            .post('/auth/token-login', postData)
            .then((result) => {
                dispatch({ type: TOKEN_LOGIN_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: TOKEN_LOGIN_FAIL, error });
            });
    };
}

export function activatePendingUser(pendingUserId, token) {
    return (dispatch, getState, client) => {
        const data = { pendingUserId, token };
        return client.post('/user/activate-pending-user', { data });
    };
}

export function removeLoginError() {
    return {
        type: LOGIN_FAIL_REMOVE,
    };
}

export function logout() {
    return (dispatch, getState, client) => {
        dispatch({ type: LOGOUT });
        return client
            .get('/auth/logout')
            .then((result) => {
                browserHistory.push('/login');
                dispatch({ type: LOGOUT_SUCCESS, result });
                dispatch(
                    showNotification('You have logged out of your account. Have a great day :)')
                );
                if (window.analytics) {
                    // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#reset-or-log-out
                    window.analytics.reset();

                    if (window.Intercom) {
                        window.Intercom('shutdown');
                    }
                    if (window.pendo) {
                        // From Pendo support:
                        // `pendo.clearSession()` should be called as well as `reset` to set the
                        // current visitorId to an anonymous one for the current session.
                        window.pendo.clearSession();
                    }
                }
                if (result.redirectTo) {
                    window.location.href = result.redirectTo;
                }
            })
            .catch((error) => dispatch({ type: LOGOUT_FAIL, error }));
    };
}

export function setPassword(newPassword1, newPassword2) {
    return (dispatch, getState, client) => {
        dispatch({ type: SET_PASSWORD });

        const postData = {
            data: { newPassword1, newPassword2 },
        };
        return client
            .post('/auth/set-password', postData)
            .then(() => {
                return dispatch(logout());
            })
            .then(() => {
                browserHistory.push('/login');
                dispatch({ type: SET_PASSWORD_SUCCESS });
                dispatch(showNotification('Successfully set new password'));
            })
            .catch((error) => {
                dispatch({ type: SET_PASSWORD_FAIL, error: error.message });
            });
    };
}

export function isLoaded(state) {
    return state.auth && state.auth.get('loaded');
}

export function isLoggedIn(state) {
    return state.auth && state.auth.getIn(['user', 'status']) === ACTIVE;
}

export function isLoggingOut(state) {
    return state.auth && state.auth.get('loggingOut');
}

export function isGovUser(state, governmentId) {
    const userGovernmentId = state.auth && state.auth.getIn(['user', 'government_id']);
    return userGovernmentId && String(userGovernmentId) === governmentId;
}

export function isGovActive(state) {
    return (
        state.auth &&
        (state.auth.getIn(['user', 'organization', 'isActive']) ||
            state.auth.getIn(['user', 'role']) === BATMAN)
    );
}

export function isVendorUser(state, vendorId) {
    const userVendorId = state.auth && state.auth.getIn(['user', 'vendor_id']);
    return userVendorId && String(userVendorId) === vendorId;
}

export function isContractUser(state) {
    return state.auth && state.auth.getIn(['user', 'government', 'hasContracting']);
}

export function isSourcingUser(state) {
    return state.auth && state.auth.getIn(['user', 'government', 'hasSourcing']);
}

export function isDocBuilderUser(state) {
    return state.auth && state.auth.getIn(['user', 'government', 'hasDocBuilder']);
}

export function isEvaluationUser(state) {
    return state.auth && state.auth.getIn(['user', 'government', 'hasEvaluation']);
}

export function isIntakeUser(state) {
    return state.auth && state.auth.getIn(['user', 'government', 'hasIntake']);
}

export function isRequisitionUser(state) {
    return state.auth && state.auth.getIn(['user', 'government', 'hasRequisition']);
}

export function showLoginModal(message, loginHandler, opts = {}) {
    return { type: SHOW_LOGIN_MODAL, message, loginHandler, opts };
}

export function hideLoginModal() {
    return { type: HIDE_LOGIN_MODAL };
}

export function vendorSignUp(data) {
    return (dispatch, getState, client) => {
        dispatch({ type: VENDOR_SIGNUP });
        return client
            .post('/user/vendor', { data })
            .then((result) => {
                if (result.isInvited) {
                    return dispatch(accountActivationResent(result.user.email));
                }
                if (result.isRequested) {
                    return dispatch(requestToJoinPending(result.user));
                }
                if (result.foundMatch) {
                    return dispatch({
                        type: FOUND_ORGANIZATION_TO_JOIN,
                        email: result.email,
                        organization: result.organization,
                    });
                }
                return dispatch({ type: VENDOR_SIGNUP_SUCCESS, email: result.email });
            })
            .catch((error) => {
                dispatch({ type: VENDOR_SIGNUP_FAIL, error });
            });
    };
}

export function requestToJoinOrganization(data) {
    return (dispatch, getState, client) => {
        dispatch({ type: REQUEST_TO_JOIN });
        return client
            .post('/user/request-invite', { data })
            .then((result) => {
                dispatch({ type: REQUEST_TO_JOIN_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: REQUEST_TO_JOIN_FAIL, error });
            });
    };
}

export function cancelRequestToJoinOrganization(userId) {
    return (dispatch, getState, client) => {
        dispatch({ type: CANCEL_REQUEST_TO_JOIN });
        client
            .del(`/user/${userId}/reject`)
            .then(() => {
                browserHistory.push('/signup');
                dispatch(resetAuth());
                dispatch(
                    showNotification('Request canceled! Sign-up to create a new account.', {
                        duration: 8000,
                    })
                );
            })
            .catch((error) => dispatch({ type: CANCEL_REQUEST_TO_JOIN_FAIL, error }));
    };
}

export function createFilter(data) {
    return (dispatch, getState, client) => {
        dispatch({ type: CREATE_FILTER });
        return client
            .post('/filters', { data })
            .then((result) => {
                dispatch({ type: CREATE_FILTER_SUCCESS, result });
                dispatch(showSnackbar('Filter Created'));
                return result;
            })
            .catch((error) => {
                dispatch({ type: CREATE_FILTER_FAIL, error });
                dispatch(showNotification(`Error: ${error.message}`, { type: 'danger' }));
                return error;
            });
    };
}

export function deleteFilter(filterId) {
    return (dispatch, getState, client) => {
        dispatch({ type: DELETE_FILTER });
        return client
            .del(`/filters/${filterId}`)
            .then((result) => {
                dispatch({ type: DELETE_FILTER_SUCCESS, result });
                dispatch(showSnackbar('Filter Deleted'));
            })
            .catch((error) => {
                dispatch({ type: DELETE_FILTER_FAIL, error });
                dispatch(showNotification(`Error: ${error.message}`, { type: 'danger' }));
            });
    };
}

export function makeFilterDefault(filterId) {
    return (dispatch, getState, client) => {
        dispatch({ type: MAKE_FILTER_DEFAULT });
        return client
            .post(`/filters/${filterId}/default`)
            .then((result) => {
                dispatch({ type: MAKE_FILTER_DEFAULT_SUCCESS, result });
                dispatch(showSnackbar('Filter Defaulted'));
            })
            .catch((error) => {
                dispatch({ type: MAKE_FILTER_DEFAULT_FAIL, error });
                dispatch(showNotification(`Error: ${error.message}`, { type: 'danger' }));
            });
    };
}

export function updateFilter(filterId, data) {
    return (dispatch, getState, client) => {
        dispatch({ type: UPDATE_FILTER });
        return client
            .put(`/filters/${filterId}`, { data })
            .then((result) => {
                dispatch({ type: UPDATE_FILTER_SUCCESS, result });
                dispatch(showSnackbar('Filter Updated'));
            })
            .catch((error) => {
                dispatch({ type: UPDATE_FILTER_FAIL, error });
                dispatch(showNotification(`Error: ${error.message}`, { type: 'danger' }));
            });
    };
}

export function updateSettingsFilter(type, filterData) {
    return (dispatch, getState, client) => {
        const data = { ...filterData, type };
        return client
            .post('/filters/settings', { data })
            .then((result) => {
                const actionType = result.created ? CREATE_FILTER_SUCCESS : UPDATE_FILTER_SUCCESS;
                dispatch({ type: actionType, result });
                dispatch(showSnackbar('Setting Updated'));
            })
            .catch((error) => {
                dispatch(showSnackbar(`Error: ${error.message}`, { type: 'danger' }));
            });
    };
}

export function storeLastFilter(filterType, filter) {
    return { type: STORE_LAST_FILTER, filter, filterType };
}

export function checkSession(onLoginSuccess, onSessionExpiration, resetSessionDate) {
    return (dispatch, getState, client) => {
        return client
            .get('/auth/session')
            .then(() => resetSessionDate())
            .catch((err) => {
                if (err.status === 401) {
                    if (onSessionExpiration) {
                        onSessionExpiration();
                    }

                    const loginHandler = () => {
                        resetSessionDate();
                        if (onLoginSuccess) {
                            onLoginSuccess();
                        }
                        dispatch(hideLoginModal());
                    };

                    dispatch(
                        showLoginModal(
                            'Your session has timed out. Please log in again to continue working.',
                            loginHandler,
                            { isSessionTimeout: true }
                        )
                    );
                }
            });
    };
}
