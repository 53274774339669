import { createSelector } from 'reselect';

import { getUserJS } from '../../containers/selectors';
import { financialSystems } from '../../../../shared_config/financialSystems';

// create a selector for the financial system integration
export const getGovernmentFMS = createSelector([getUserJS], (user) => {
    return user.government.financialSystemIntegration;
});

export const getIsGovernmentUsingFMS = createSelector(
    [getGovernmentFMS],
    (financialSystemIntegration) => {
        return financialSystemIntegration !== financialSystems.NONE;
    }
);

export const getGovernmentSalesTax = createSelector(
    [getUserJS],
    (user) => user.government.salesTax
);
