import { basicUserRoles } from '../../../shared_config/userRoles';

const { ADMIN, BATMAN } = basicUserRoles;

// Checks if the user has the specified permission role
export function userHasPermissionRole(user, permissionType, hasUserRoles) {
    if (!hasUserRoles) {
        return user.role === ADMIN || user.role === BATMAN;
    }
    return user.userRoles.some((role) => role[permissionType]);
}
