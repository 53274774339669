import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
    getUserContractHomepage,
    getUserIntakeHomepage,
    getUserProjectHomepage,
} from '../../../../helpers';
import { createNavItemId, ReactRouterLink, usePathUrl } from './utils';
import { getUserJS } from '../../../../containers/selectors';

export const useProjectNavItems = () => {
    const location = useLocation();
    const user = useSelector(getUserJS);

    const {
        government: { hasContracting, hasIntake, hasRequisition, hasRequisitionOnly, hasSourcing },
        government_id: governmentId,
        isExternal,
        userPermissions: { isVendorAdmin },
    } = user;
    const { pathname } = location;

    const { getPathUrl } = usePathUrl(governmentId);

    const navItems = [];

    const requisitionsNavItem = {
        id: createNavItemId('requisitions'),
        label: hasRequisitionOnly ? 'Requests' : 'Requests for Purchase',
        linkComponent: ReactRouterLink,
        url: getPathUrl('requisitions'),
        isActive: !!pathname.match(/\/governments\/\d+\/requisitions$/),
    };

    const intakeNavItem = {
        id: createNavItemId('intakes'),
        label: 'Intake',
        linkComponent: ReactRouterLink,
        url: getUserIntakeHomepage(user),
        isActive: !!pathname.match(/\/intakes$/),
    };

    if (hasIntake && hasRequisition) {
        navItems.push({
            id: createNavItemId('requests'),
            label: 'Requests',
            isActive:
                !!pathname.match(/\/intakes$/) ||
                !!pathname.match(/\/governments\/\d+\/requisitions$/),
            submenuSections: [
                {
                    items: [requisitionsNavItem, intakeNavItem],
                },
            ],
        });
    } else if (hasIntake) {
        navItems.push(intakeNavItem);
    } else if (hasRequisition) {
        navItems.push(requisitionsNavItem);
    }

    if (!hasRequisitionOnly) {
        navItems.push({
            id: createNavItemId('solicitations'),
            label: 'Solicitations',
            linkComponent: ReactRouterLink,
            url: getUserProjectHomepage(user),
            isActive: !!pathname.match(/\/governments\/\d+\/projects$/),
        });
    }

    if (hasContracting && !isExternal) {
        navItems.push({
            id: createNavItemId('contracts'),
            label: 'Contracts',
            linkComponent: ReactRouterLink,
            url: getUserContractHomepage(user),
            isActive: !!pathname.match(/\/governments\/\d+\/(contracts|documents)$/),
        });
    }

    if (hasSourcing && isVendorAdmin) {
        navItems.push({
            id: createNavItemId('vendors'),
            label: 'Vendors',
            linkComponent: ReactRouterLink,
            url: getPathUrl('vendors'),
            isActive: !!pathname.match(/\/governments\/\d+\/vendors$/),
        });
    }

    return navItems;
};
