import PropTypes from 'prop-types';
import React from 'react';

import {
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { RequisitionsListItem } from './RequisitionsListItem';
import { RequisitionDashboardEmptyState } from './EmptyState';
import { columns } from './constants';

export const RequisitionsList = ({ requisitions }) => {
    if (requisitions.length === 0) {
        return <RequisitionDashboardEmptyState />;
    }

    return (
        <TableContainer
            sx={{
                overflowX: 'auto',
                border: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
                borderBottom: 0,
            }}
        >
            <MuiTable aria-label="Requisitions List">
                <TableHead>
                    <TableRow>
                        {Object.keys(columns).map((col) => {
                            const column = columns[col];
                            return (
                                <TableCell
                                    align={column.headAlign}
                                    key={col}
                                    style={{ minWidth: column.width }}
                                >
                                    <Typography fontWeight="500" variant="h4">
                                        {column.label}
                                    </Typography>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {requisitions.map((requisition) => (
                        <RequisitionsListItem key={requisition.id} requisition={requisition} />
                    ))}
                </TableBody>
            </MuiTable>
        </TableContainer>
    );
};

RequisitionsList.propTypes = {
    requisitions: PropTypes.array.isRequired,
};
