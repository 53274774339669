import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';

import { EvaluationScorecardModal as EvaluationScorecardModalComponent } from './components';
import { getProposalCriteriaScoreFormValues } from './selectors';
import {
    canEvaluate,
    getEvaluationModalProposal,
    getTotalWeight,
    isConsensusScorecardPage,
} from '../selectors';
import { getEvaluationProjectJS } from '../../selectors';
import {
    hideEvaluationScorecard,
    updateProposalCriteriaScores,
    updateProposalEvaluation,
} from '../../../../actions/proposalEvaluations';
import { proposalEvaluationStatuses } from '../../../../../../shared_config/proposalEvaluations';

const { OPEN, COMPLETE } = proposalEvaluationStatuses;

const mapStateToProps = (state, props) => {
    return {
        canEvaluate: canEvaluate(state, props),
        isConsensusView: isConsensusScorecardPage(state, props),
        project: getEvaluationProjectJS(state),
        proposal: getEvaluationModalProposal(state),
        proposalCriteriaScores: getProposalCriteriaScoreFormValues(state),
        showModal: state.proposalEvaluations.get('showScorecardModal'),
        totalWeight: getTotalWeight(state, props),
    };
};

const mapDispatchToProps = {
    hideModal: hideEvaluationScorecard,
    updateProposalCriteriaScores,
    updateProposalEvaluation,
};

// @withRouter
// @connect
class ConnectedEvaluationScorecardModal extends Component {
    static propTypes = {
        canEvaluate: PropTypes.bool.isRequired,
        hideModal: PropTypes.func.isRequired,
        isConsensusView: PropTypes.bool.isRequired,
        params: PropTypes.shape({
            evaluatorId: PropTypes.string,
        }).isRequired,
        project: PropTypes.object,
        proposal: PropTypes.shape({
            proposalEvaluation: PropTypes.shape({
                status: PropTypes.string.isRequired,
            }).isRequired,
        }),
        proposalCriteriaScores: PropTypes.array.isRequired,
        showModal: PropTypes.bool.isRequired,
        totalWeight: PropTypes.number.isRequired,
        updateProposalCriteriaScores: PropTypes.func.isRequired,
        updateProposalEvaluation: PropTypes.func.isRequired,
    };

    updateProposalEvaluation = (data) => {
        const {
            isConsensusView,
            params: { evaluatorId },
            proposal,
        } = this.props;

        return this.props.updateProposalEvaluation(proposal, data, evaluatorId, isConsensusView);
    };

    submitProposalEvaluation = () => {
        return this.updateProposalEvaluation({ status: COMPLETE });
    };

    unsubmitProposalEvaluation = () => {
        return this.updateProposalEvaluation({ status: OPEN });
    };

    render() {
        const {
            hideModal,
            isConsensusView,
            params: { evaluatorId },
            project,
            proposal,
            proposalCriteriaScores,
            showModal,
            totalWeight,
        } = this.props;

        if (!showModal) {
            return null;
        }

        const { status } = proposal.proposalEvaluation;
        return (
            <EvaluationScorecardModalComponent
                canEvaluate={this.props.canEvaluate}
                evaluatorId={evaluatorId && Number.parseInt(evaluatorId, 10)}
                hideModal={hideModal}
                isCommentRequired={project.evaluation.isCommentRequired}
                isCompleteStatus={status === COMPLETE}
                isConsensusView={isConsensusView}
                proposal={proposal}
                proposalCriteriaScores={proposalCriteriaScores}
                scoringCriteria={project.evaluation.scoringCriteria}
                submitProposalEvaluation={this.submitProposalEvaluation}
                totalWeight={totalWeight}
                unsubmitProposalEvaluation={this.unsubmitProposalEvaluation}
                updateProposalCriteriaScores={this.props.updateProposalCriteriaScores}
            />
        );
    }
}

export const EvaluationScorecardModal = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedEvaluationScorecardModal);
