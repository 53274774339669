import { createSelector } from 'reselect';

import { fieldNamesDict } from './constants';
import { getContractJS } from '../../../selectors';
import { contractInsuranceFormFieldsDict } from '../../../../../../shared_config/contracts';

const { APPROVED_DATE, EXPIRATION_DATE, REQUESTED_DATE } = contractInsuranceFormFieldsDict;

const { CONTRACT_INSURANCES } = fieldNamesDict;

export const getContractInsuranceDates = createSelector([getContractJS], (contract) => {
    if (contract) {
        return contract[CONTRACT_INSURANCES].map((contractInsurance) => {
            return {
                ...contractInsurance,
                [APPROVED_DATE]: contractInsurance[APPROVED_DATE]
                    ? new Date(contractInsurance[APPROVED_DATE])
                    : null,
                [EXPIRATION_DATE]: contractInsurance[EXPIRATION_DATE]
                    ? new Date(contractInsurance[EXPIRATION_DATE])
                    : null,
                [REQUESTED_DATE]: contractInsurance[REQUESTED_DATE]
                    ? new Date(contractInsurance[REQUESTED_DATE])
                    : null,
            };
        });
    }
});
