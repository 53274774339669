import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';

import { useSelector } from 'react-redux';

import {
    activeRequestsColors,
    visualizationFontFamily,
    chartBaseProps,
    activeRequestsVisualization,
    titleBaseStyles,
    legendBaseProps,
    exportingBaseProps,
} from './constants';
import { useQueryParam } from '../../../../../hooks';
import { highlightPoint, defaultLabelFormatter, unhighlightPoint, loadMoreIcon } from './helpers';
import { getInitialParams } from '../helpers';
import { getIsGroupAdminOrLeader } from '../selectors';
import { getRequisitionStatusData } from '../../../../../../../shared_config/requisitions';
import { useRenderChart } from './hooks/useRenderChart';
import {
    chartInteractionParamsDict,
    handleChartInteractionInitialize,
} from './helpers/chartInteractions';

const { STATUS } = chartInteractionParamsDict;

// When a slice is too small compared to the chart, we need to adjust its y value to make it visible
// Reference: https://opengovinc.atlassian.net/browse/PRO-5548
const adjustDataForVisibility = (data) => {
    // Calculate total sum of all y values
    const totalSum = data.reduce((acc, point) => acc + point.y, 0);

    // Calculate average y value
    const averageY = totalSum / data.length;

    // Define a threshold as 10% of the average y value
    const threshold = 0.1 * averageY;

    const getAdjustedY = (y) => {
        // If the y value is 0, we don't need to adjust it
        if (y === 0) {
            return y;
        }

        // If the y value is below the threshold, we need to adjust it
        if (y < threshold) {
            return threshold;
        }

        // If the y value is above the threshold, we don't need to adjust it
        return y;
    };

    // Adjust data only for points with y values below the threshold
    const adjustedData = data.map((point) => ({
        ...point,
        adjustedY: getAdjustedY(point.y),
        originalY: point.y, // Store the original y value
    }));

    return adjustedData;
};

export const ActiveRequests = ({ data }) => {
    const [status, , setQueryParams] = useQueryParam(STATUS);

    const isAdminOrLeader = useSelector((state) => getIsGroupAdminOrLeader(state));

    const initialParams = getInitialParams(isAdminOrLeader);

    const renderChart = () => {
        loadMoreIcon(Highcharts);

        const adjustedData = adjustDataForVisibility(data);

        const chart = Highcharts.chart(activeRequestsVisualization, {
            chart: {
                type: 'pie',
                ...chartBaseProps,
            },
            title: {
                text: 'All Active Requests',
                align: 'left',
                y: 20,
                x: 5,
                style: titleBaseStyles,
            },
            tooltip: {
                pointFormatter() {
                    const { originalY } = this;
                    if (originalY > 0) {
                        return `${originalY} Request${originalY > 1 ? 's' : ''}`;
                    }
                    return null;
                },
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    borderWidth: 3,
                    cursor: 'pointer',
                    colors: activeRequestsColors,
                    center: ['60%', '50%'],
                    size: '100%',
                    minPointSize: 50,
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: true,
                    events: {
                        click(e) {
                            const clickedPoint = e.point;

                            const selectedStatus = e.point.options.status;

                            const { points } = this;

                            if (clickedPoint.selected) {
                                setQueryParams({
                                    [STATUS]: null,
                                });
                                points.forEach((point) =>
                                    highlightPoint(point, activeRequestsVisualization)
                                );
                            } else {
                                setQueryParams({
                                    ...initialParams,
                                    [STATUS]: selectedStatus,
                                });

                                points.forEach((point) =>
                                    unhighlightPoint(point, activeRequestsVisualization)
                                );
                                highlightPoint(clickedPoint, activeRequestsVisualization);
                            }
                        },
                        // Add data-qa attribute to each point
                        afterAnimate() {
                            const { points } = this;
                            points.forEach((point) => {
                                point.graphic.element.setAttribute(
                                    'data-qa',
                                    `allActiveRequests-${point.name}`
                                );
                            });
                        },
                    },
                },
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    type: 'pie',
                    name: 'All Active Requests',
                    innerSize: '50%',
                    data: adjustedData.map((point) => ({ ...point, y: point.adjustedY })), // Use adjustedY for the chart
                },
            ],
            legend: legendBaseProps,
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 300,
                        },
                        chartOptions: {
                            legend: {
                                align: 'center',
                                verticalAlign: 'bottom',
                                layout: 'horizontal',
                                itemMarginTop: 5,
                                x: 0,
                                itemStyle: {
                                    fontSize: '12px',
                                },
                                padding: 0,
                            },
                            title: {
                                style: {
                                    fontSize: '16px',
                                },
                            },
                            chart: {
                                height: 260,
                            },
                            plotOptions: {
                                pie: {
                                    center: ['50%', '55%'],
                                    size: '110%',
                                },
                            },
                        },
                    },
                    {
                        condition: {
                            minWidth: 301,
                        },
                        chartOptions: {
                            legend: {
                                align: 'right',
                                verticalAlign: 'middle',
                                layout: 'vertical',
                                itemMarginTop: 10,
                                itemStyle: {
                                    fontFamily: visualizationFontFamily,
                                },
                                x: -10,
                                y: 10,
                                labelFormatter() {
                                    const { name, originalY } = this;

                                    return defaultLabelFormatter(name, originalY);
                                },
                            },
                            title: {
                                style: {
                                    fontSize: '14px',
                                },
                            },
                            plotOptions: {
                                pie: {
                                    size: '110%',
                                },
                            },
                        },
                    },
                    {
                        condition: {
                            minWidth: 340,
                        },
                        chartOptions: {
                            plotOptions: {
                                pie: {
                                    size: '110%',
                                },
                            },
                            title: {
                                style: {
                                    fontSize: '18px',
                                },
                            },
                        },
                    },
                    {
                        condition: {
                            minWidth: 400,
                        },
                        chartOptions: {
                            plotOptions: {
                                pie: {
                                    size: '120%',
                                },
                            },
                        },
                    },
                ],
            },
            exporting: {
                ...exportingBaseProps,
            },
        });

        const { points } = chart.series[0];

        handleChartInteractionInitialize(
            status,
            points,
            activeRequestsVisualization,
            getRequisitionStatusData(status).longName
        );
    };

    useRenderChart(activeRequestsVisualization, data, renderChart, status);

    return <div id={activeRequestsVisualization} />;
};

ActiveRequests.propTypes = {
    data: PropTypes.array.isRequired,
};
