import { createSelector } from 'reselect';

import { getProposalJS } from '../../selectors';
import {
    getIsAuctionEnded,
    getPublicProjectJS,
    getReverseAuctionItemsJS,
    getUserJS,
} from '../../../App/selectors';
import { getBidRankingMap } from '../../../../helpers/bidRankingHelper';
import { isAuctionLive } from '../../../../../../shared_config/reverseAuctions/util';

export const getIsAuctionLive = createSelector(
    [getIsAuctionEnded, getPublicProjectJS],
    (isAuctionEnded, project) => {
        // `auctionEnded` gets set by the socket broadcast when auction ends
        if (isAuctionEnded) return false;

        if (project) {
            return isAuctionLive(project);
        }

        return false;
    }
);

export const getVendorPriceItemBids = createSelector(
    [getReverseAuctionItemsJS, getProposalJS, getUserJS],
    (reverseAuctionItems, proposal, user) => {
        const activeBids = [];
        const noBids = [];
        const rankAndBidMap = {};

        reverseAuctionItems.forEach((item) => {
            const vendorPriceItems = item.priceItems[0].vendorPriceItems;

            const bidRankingMap = getBidRankingMap(
                vendorPriceItems
                    .filter((vendorPriceItem) => !vendorPriceItem.noBid)
                    .map((vendorPriceItem) => ({
                        id: vendorPriceItem.id,
                        value: vendorPriceItem.reverseAuctionBids[0].unitPrice,
                    }))
            );

            vendorPriceItems.forEach((vendorPriceItem) => {
                if (vendorPriceItem.proposal.id === proposal.id) {
                    if (vendorPriceItem.noBid || vendorPriceItem.unitPrice === null) {
                        noBids.push(item);
                    } else {
                        let proxyBid;

                        if (proposal.project.allowsProxyBidding) {
                            proxyBid = item.priceItems[0].proxyBids.find(
                                (proxy) => proxy.vendor_id === user.vendor.id
                            );
                        }

                        rankAndBidMap[item.id] = {
                            currentVendorBid: vendorPriceItem.reverseAuctionBids[0].unitPrice,
                            currentVendorProxyBid: proxyBid || null,
                            rank: bidRankingMap[vendorPriceItem.id],
                            vendorPriceItemId: vendorPriceItem.id,
                        };

                        activeBids.push(item);
                    }
                }
            });
        });

        return { activeBids, noBids, rankAndBidMap };
    }
);
