import { get, isNumber, orderBy, partition, round } from 'lodash';
import { createSelector } from 'reselect';

import { currencyFormatter } from '../../../../helpers';
import { LOWEST_PRICE } from '../../../../../../shared_config/evaluations';

const getProject = (state, props) => props.project;
const getBidTabulationTotals = (state) => state.proposalEvaluations.get('bidTabulationTotals');
const getShowPercentageTotals = (state) => state.proposalEvaluations.get('showPercentageTotals');

const getLowestPriceProposals = (proposalsData, bidTabulationSortAsc, auctionMaxFractionDigits) => {
    return orderBy(proposalsData, ['total'], [bidTabulationSortAsc ? 'asc' : 'desc']).map(
        (proposalData) => {
            const formatterOpts = auctionMaxFractionDigits
                ? { maximumFractionDigits: auctionMaxFractionDigits, useSameMinAndMax: true }
                : { use4FractionDigits: true };
            const formattedValue = currencyFormatter({ value: proposalData.total }, formatterOpts);

            return {
                label: `${proposalData.vendorName} (${formattedValue})`,
                value: proposalData.proposalId,
            };
        }
    );
};

const getHighestScoredProposals = (
    proposals,
    hasConsensusEvaluation,
    showPercentageTotals,
    usesRankedScoring
) => {
    let totalScoreKey = showPercentageTotals ? 'totalScore' : 'totalScoreByPoints';
    let sortDirection = 'desc';

    if (hasConsensusEvaluation) {
        totalScoreKey = `consensusEvaluation.${totalScoreKey}`;
    } else if (usesRankedScoring) {
        totalScoreKey = 'rankedScore';
        sortDirection = 'asc';
    }

    const [scored, unscored] = partition(proposals, (prop) => isNumber(get(prop, totalScoreKey)));

    return [...orderBy(scored, [totalScoreKey], [sortDirection]), ...unscored].map((proposal) => {
        const companyName = proposal.companyName || 'Unnamed Company';
        const score = get(proposal, totalScoreKey);
        const scoreText = isNumber(score) ? round(score, 2) : 'N/A';

        return {
            label: `${companyName} (${scoreText})`,
            value: proposal.id,
        };
    });
};

// This might be aggregate proposals, or it might be bid tab with totals data
export const getProposalsJS = createSelector(
    [getProject, getBidTabulationTotals, getShowPercentageTotals],
    (project, bidTabulationTotals, showPercentageTotals) => {
        const {
            auctionMaxFractionDigits,
            evaluation: { bidTabulationSortAsc, hasConsensusEvaluation, type },
            government: { usesRankedScoring },
            scoredProposals,
            template: { isReverseAuction },
        } = project;

        const maxFractionDigits = isReverseAuction ? auctionMaxFractionDigits : null;

        if (type === LOWEST_PRICE) {
            return getLowestPriceProposals(
                bidTabulationTotals.toJS(),
                bidTabulationSortAsc,
                maxFractionDigits
            );
        }

        return getHighestScoredProposals(
            scoredProposals,
            hasConsensusEvaluation,
            showPercentageTotals,
            usesRankedScoring
        );
    }
);
