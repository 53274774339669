import moment from 'moment';
import { startCase } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { ListGroup, ListGroupItem, Panel } from 'react-bootstrap';
import { useLocation, createSearchParams } from 'react-router-dom';

import connectData from '../../ConnectData';
import { getUserJS } from '../../selectors';
import {
    Button,
    ButtonGroup,
    ChecklistsList,
    DashboardControlNav,
    LoadingError,
    LoadingSpinner,
    RouterLink,
    SectionTitle,
    StatusLabel,
    ZeroState,
} from '../../../components';
import { checklistsFilters } from './constants';
import { loadPublicAwardedContracts } from '../../../actions/vendProposals';
import { getAwardedContracts } from '../AwardedContracts/selectors';
import { checklistStatusTypes } from '../../../../../shared_config/checklists';
import { contractStatusClass } from '../../../components/helpers/statusHelper';

const { RELEASED } = checklistStatusTypes;

function fetchData(getState, dispatch, location) {
    const checklistsFilter = location.searchParams?.get('filter') || RELEASED;
    return dispatch(loadPublicAwardedContracts(checklistsFilter));
}

// @connectData
const ConnectedContractsChecklists = () => {
    const { pathname } = useLocation();
    const contracts = useSelector(getAwardedContracts);
    const loading = useSelector((state) => state.vendProposals.get('loadingAwardedContracts'));
    const loadError = useSelector((state) => state.vendProposals.get('awardedContractsError'));
    const user = useSelector(getUserJS);

    const styles = require('./index.scss');

    const renderFilterButtons = () => {
        const filterButtons = checklistsFilters.map((btn) => (
            <Button
                className={`filter-${btn.key} ${styles.filterBtn}`}
                key={btn.key}
                qaTag={`contractsChecklists-${btn.qaTagDescription}`}
                to={{ pathname, search: createSearchParams({ filter: btn.filter }).toString() }}
            >
                <i className={`fa fa-fw fa-${btn.icon}`} /> {btn.text}
            </Button>
        ));

        return (
            <div>
                <strong>Find Checklists</strong>
                <ButtonGroup block vertical>
                    {filterButtons}
                </ButtonGroup>
            </div>
        );
    };

    const renderSideBar = () => {
        return (
            <DashboardControlNav logoUrl={user.organization.logo}>
                {renderFilterButtons()}
            </DashboardControlNav>
        );
    };

    const renderContractsList = () => {
        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        if (!contracts) {
            return <ZeroState title="No Contracts with Checklists" />;
        }

        return (
            <ListGroup>
                {contracts.map((contract) => {
                    const {
                        checklists,
                        government: {
                            code,
                            organization: { logo, name },
                        },
                        id,
                        startDate,
                        status,
                    } = contract;

                    return (
                        <ListGroupItem key={contract.id}>
                            <div className="row">
                                <div className="col-xs-9 col-sm-8">
                                    <RouterLink
                                        qaTag="checklistsList-viewContract"
                                        to={`/portal/${code}/contracts/${id}`}
                                    >
                                        <h4 className={styles.contractTitle}>{contract.title}</h4>
                                    </RouterLink>
                                    <div className={styles.mutedInfo}>
                                        Start Date: {moment(startDate).format('ll')}
                                    </div>
                                    <div className={styles.statusLabel}>
                                        <StatusLabel bsStyle={contractStatusClass(status)}>
                                            {startCase(status)}
                                        </StatusLabel>
                                    </div>
                                </div>
                                <div className={`col-xs-3 col-sm-4 ${styles.logoContainer}`}>
                                    <img
                                        alt={name}
                                        className={`img-rounded ${styles.logo}`}
                                        src={logo}
                                    />
                                    <div className={styles.mutedInfo}>{name}</div>
                                </div>
                                <div className="col-xs-12">
                                    <ChecklistsList
                                        checklists={checklists}
                                        contractChecklistsPath={pathname}
                                    />
                                </div>
                            </div>
                        </ListGroupItem>
                    );
                })}
            </ListGroup>
        );
    };

    return (
        <div className="row">
            <div className="col-md-3">{renderSideBar()}</div>
            <div className="col-md-9">
                <Panel className={styles.checklistsContainer} id="skip">
                    <Panel.Body>
                        <SectionTitle
                            info="Onboarding checklists for your awarded contracts"
                            title="Contracts Checklists"
                        />
                        {renderContractsList()}
                    </Panel.Body>
                </Panel>
            </div>
        </div>
    );
};

export const ContractsChecklists = connectData(fetchData)(ConnectedContractsChecklists);
