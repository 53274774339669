import classNames from 'classnames';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isInvalid } from 'redux-form';

import { fieldNames, form, qaTagPageName } from './constants';
import { OverBudgetNoteForm } from './OverBudgetNoteForm';
import { getRequisitionBudgetOverrideData } from './selectors';
import { hideRequisitionApprovalModal } from '../../../actions/requisitions';
import { Button, LoadingButton } from '../../../components';
import { actionLabelNames } from '../../../lib/ogFinancials/constants';
import {
    getRequisitionApprovalModalData,
    getRequisitionCurrentStep,
    getRequisitionJS,
} from '../../../selectors/govApp';
import { reviewTypes } from '../../../../../shared_config/reviewSequences';

const { OVER_BUDGET_NOTE } = fieldNames;
const { SAVE, SUBMIT, APPROVE } = actionLabelNames;

const pastParticiple = {
    [SAVE]: 'saved',
    [SUBMIT]: 'submitted',
    [APPROVE]: 'approved',
};

export const RequisitionApprovalModal = () => {
    const dispatch = useDispatch();

    const [isUpdating, setIsUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);
    const [overBudgetNote, setOverBudgetNote] = useState(undefined);

    const { isOverBudget } = useSelector(getRequisitionJS);
    const currentStep = useSelector(getRequisitionCurrentStep);
    const { allowOverBudget, requireOverBudgetNote } = useSelector(
        getRequisitionBudgetOverrideData
    );
    const { onConfirm, data } = useSelector(getRequisitionApprovalModalData);
    const isOverBudgetNoteFormInvalid = useSelector(isInvalid(form));

    const {
        actionLabel = APPROVE,
        bsStyle = 'primary',
        btnText = 'Confirm',
        icon = 'fa-check',
        text = 'Are you sure?',
    } = data;

    const styles = require('./index.scss');

    const cancelHandler = () => dispatch(hideRequisitionApprovalModal());
    const submitHandler = () => {
        // Prevent submission if there is a form error (will be no errors when form is not present)
        if (isOverBudgetNoteFormInvalid) {
            setUpdateError('Please fix form errors before submitting');
            return;
        }

        const additionalData = {};
        if (isOverBudget) {
            additionalData.allowOverBudget = true;
        }
        if (overBudgetNote && overBudgetNote.trim()) {
            additionalData.overBudgetNote = overBudgetNote.trim();
        }

        setIsUpdating(true);
        setUpdateError(null);
        return onConfirm(additionalData)
            .then((resultOrError) => {
                if (resultOrError instanceof Error) {
                    throw resultOrError;
                }
                dispatch(hideRequisitionApprovalModal());
            })
            .catch((e) => {
                setIsUpdating(false);
                setUpdateError(e?.message);
            });
    };

    // Close modal on unmount
    useEffect(() => cancelHandler, []);

    let modalTitle;
    let modalBody;
    let cancelButtonText = 'Cancel';
    let showApproveButton = true;
    const skipOverBudgetNotes = currentStep?.reviewType === reviewTypes.CONFIRM;

    if (isOverBudget && !allowOverBudget) {
        modalTitle = (
            <span>
                <i className={classNames('fa fa-warning', styles.warningIcon)} /> Cannot{' '}
                {capitalize(actionLabel)} an <span className={styles.overBudget}>Over Budget</span>{' '}
                Request
            </span>
        );
        modalBody = (
            <p>
                Requests that fail the budget check are not allowed to be{' '}
                {pastParticiple[actionLabel]}. Please update the request{' '}
                {actionLabel === APPROVE ? 'or notify someone who can ' : ''}
                before moving forward.
            </p>
        );
        cancelButtonText = 'Return to Request';
        showApproveButton = false;
    } else if (isOverBudget && !skipOverBudgetNotes) {
        modalTitle = (
            <span>
                <i className={classNames('fa fa-warning', styles.warningIcon)} /> This Request is{' '}
                <span className={styles.overBudget}>Over Budget</span>
            </span>
        );
        modalBody = (
            <>
                <OverBudgetNoteForm
                    onChange={(values) => setOverBudgetNote(values[OVER_BUDGET_NOTE])}
                    requireOverBudgetNote={requireOverBudgetNote}
                />
                <p>{text}</p>
                <p>Reviewers of this request will see the failed budget check after you submit.</p>
            </>
        );
    } else {
        modalTitle = `${capitalize(actionLabel)} Confirmation Required`;
        modalBody = <p>{text}</p>;
    }

    return (
        <Modal className={styles.approvalModal} onHide={cancelHandler} show>
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalBody}</Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={isUpdating}
                    onClick={cancelHandler}
                    qaTag={`${qaTagPageName}-cancel`}
                >
                    {cancelButtonText}
                </Button>
                {showApproveButton && (
                    <LoadingButton
                        bsStyle={bsStyle}
                        disabled={isUpdating}
                        icon={icon}
                        loading={isUpdating}
                        onClick={submitHandler}
                        qaTag={`${qaTagPageName}-submit`}
                        text={btnText}
                    />
                )}
                {updateError && <div className="error-block">{updateError}</div>}
            </Modal.Footer>
        </Modal>
    );
};
