import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from 'react-bootstrap';
import Media from 'react-media';

import {
    COMMENT,
    DESCRIPTION,
    DISCOUNT,
    LINE_ITEM,
    NO_BID,
    QUANTITY,
    TOTAL_COST,
    UNIT_PRICE,
    UNIT_TO_MEASURE,
} from '../../../../shared_config/priceTables';
import { AWARD_ID, AWARD_TYPE, PROJECT_ID, VENDOR_PRICE_ITEM_ID } from './constants';
import { AgGridReact, AgGridReactPanelHeading, Button } from '..';
import { calculatePriceItemTotalCost, currencyFormatter, percentFormatter } from '../../helpers';
import { formatPercent, cleanAgGridUniqueColName } from '../helpers/utils';
import { SCREEN_SM_MAX } from '../../constants/mediaQuery';
import { LineItemCellRenderer } from './LineItemCellRenderer';
import { conditionalPropCheck } from '../../utils';
import {
    dataTypesDict,
    DOCX_TABLE_LANDSCAPE_WIDTH,
    DOCX_TABLE_PORTRAIT_WIDTH,
} from '../../constants';
import { FIXED_TOOLBAR_HEIGHT } from '../../constants/styles';
import { WithStickyHeader } from '../../hocs';

const { BOOLEAN, NUMBER, STRING } = dataTypesDict;

const PANEL_BODY_STYLES = {
    padding: 0,
};

export class LineItemAwardTable extends React.Component {
    static propTypes = {
        auctionMaxFractionDigits: PropTypes.number,
        defaultTitle: PropTypes.string,
        isDocx: PropTypes.bool,
        isViewOnly: PropTypes.bool,
        lineItemAward: PropTypes.shape({
            hasQuantity: PropTypes.bool.isRequired,
            omitLineItem: PropTypes.bool.isRequired,
            priceTable: PropTypes.object.isRequired,
            rows: PropTypes.arrayOf(
                PropTypes.shape({
                    description: conditionalPropCheck(STRING, ({ props }) => !props.isHeaderRow),
                    discountOnly: conditionalPropCheck(BOOLEAN, ({ props }) => !props.isHeaderRow),
                    isHeaderRow: PropTypes.bool,
                    lineItem: PropTypes.string,
                    priceItemId: PropTypes.number.isRequired,
                    unitToMeasure: conditionalPropCheck(STRING, ({ props }) => !props.isHeaderRow),
                    vendorResponses: PropTypes.arrayOf(
                        PropTypes.shape({
                            comment: PropTypes.string,
                            discount: PropTypes.number,
                            noBid: PropTypes.bool,
                            quantity: PropTypes.number.isRequired,
                            unitPrice: conditionalPropCheck(
                                [NUMBER, STRING], // Unit price is 'N/A' when the price item is `discountOnly`
                                ({ props }) => !props.discountOnly
                            ),
                        })
                    ).isRequired,
                })
            ).isRequired,
            title: PropTypes.string,
        }).isRequired,
        proposalsData: PropTypes.arrayOf(
            PropTypes.shape({
                proposalId: PropTypes.number.isRequired,
                vendorCity: PropTypes.string,
                vendorName: PropTypes.string.isRequired,
                vendorState: PropTypes.string,
            })
        ),
        showCustomColumns: PropTypes.bool,
        useLandscape: PropTypes.bool,
    };

    static defaultProps = {
        defaultTitle: 'Table',
    };

    constructor(props) {
        super(props);

        this.state = {
            hideAllColumns: !this.props.isDocx,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    get currencyFormatterOpts() {
        const { auctionMaxFractionDigits } = this.props;
        if (auctionMaxFractionDigits) {
            return { maximumFractionDigits: auctionMaxFractionDigits, useSameMinAndMax: true };
        }
        return { use4FractionDigits: true };
    }

    generateColDefs(isMobile) {
        const {
            lineItemAward: { hasQuantity, omitLineItem, priceTable },
            proposalsData,
            showCustomColumns,
        } = this.props;
        const { hideAllColumns } = this.state;

        const baseColumnChildren = [];
        const quantityColumn = {
            cellStyle: this.getCellStyle,
            cellClassRules: {
                // Used exclusively for Excel export styles
                headerRow: (params) => params.data.isHeaderRow,
            },
            headerName: priceTable.headerQuantity,
            width: 105,
            suppressMenu: true,
            hide: !hasQuantity,
        };

        if (!omitLineItem) {
            baseColumnChildren.push({
                headerName: priceTable.headerLineItem,
                field: LINE_ITEM,
                cellStyle: this.getCellStyle,
                cellClassRules: {
                    // Used exclusively for Excel export styles
                    headerRow: (params) => params.data.isHeaderRow,
                },
                width: 115,
                pinned: isMobile ? undefined : 'left',
                suppressMenu: true,
                colSpan: (params) => {
                    if (params.data.isHeaderRow) {
                        return Infinity;
                    }
                    return 1;
                },
            });
        }

        baseColumnChildren.push(
            {
                headerName: priceTable.headerDescription,
                field: DESCRIPTION,
                cellClass: ['wrapText'], // Used exclusively for Excel export styles
                cellClassRules: {
                    // Used exclusively for Excel export styles
                    headerRow: (params) => params.data.isHeaderRow,
                },
                autoHeight: true,
                cellStyle: this.getCellStyle,
                width: isMobile ? 150 : 300,
                pinned: isMobile ? undefined : 'left',
                suppressMenu: true,
            },
            {
                headerName: priceTable.headerUnitToMeasure,
                field: UNIT_TO_MEASURE,
                cellStyle: this.getCellStyle,
                cellClassRules: {
                    // Used exclusively for Excel export styles
                    headerRow: (params) => params.data.isHeaderRow,
                },
                width: 150,
                pinned: isMobile ? undefined : 'left',
                suppressMenu: true,
            }
        );

        // Quantity appears on left side of the line when gov defined, and right side when vendor defined
        if (priceTable.specifyQuantity) {
            baseColumnChildren.splice(baseColumnChildren.length - 1, 0, {
                // Insert before UNIT_TO_MEASURE
                ...quantityColumn,
                field: QUANTITY,
                pinned: isMobile ? undefined : 'left',
            });
        }

        const baseColumns = [
            {
                headerName: undefined, // This is intentionally done for styling purposes
                marryChildren: true,
                suppressColumnsToolPanel: true,
                suppressMovable: true,
                children: baseColumnChildren,
            },
        ];

        const vendorColumns = proposalsData.map((proposalData) => {
            const { vendorCity, vendorName, vendorState } = proposalData;
            const headerTooltip =
                vendorCity && vendorState
                    ? `${vendorCity}, ${vendorState}`
                    : 'No city or state provided';
            const customColumns = [];
            const children = [];

            if (showCustomColumns) {
                [1, 2, 3, 4, 5].forEach((customColNum) => {
                    if (priceTable[`hasCustom${customColNum}`]) {
                        customColumns.push({
                            headerName: priceTable[`headerCustom${customColNum}`],
                            field: `${proposalData.proposalId}:custom${customColNum}`,
                            cellStyle: this.getCellStyle,
                            cellClassRules: {
                                // Used exclusively for Excel export styles
                                headerRow: (params) => params.data.isHeaderRow,
                            },
                            width: 120,
                            hide: hideAllColumns,
                            suppressMenu: true,
                        });
                    }
                });
            }

            if (!priceTable.specifyQuantity) {
                children.push({
                    ...quantityColumn,
                    field: `${proposalData.proposalId}:${QUANTITY}`,
                    hide: !hasQuantity || hideAllColumns,
                });
            }

            children.push(
                {
                    headerName: priceTable.headerUnitPrice,
                    colId: `${proposalData.proposalId}:${UNIT_PRICE}`, // Prevents ag-grid from appending _1 to the colId: https://github.com/ag-grid/ag-grid/issues/2889
                    field: `${proposalData.proposalId}:${UNIT_PRICE}`,
                    noBidField: `${proposalData.proposalId}:${NO_BID}`,
                    cellClass: [priceTable.hasPercentage ? 'percent' : '4FractionCurrency'], // Used exclusively for Excel export styles
                    cellClassRules: {
                        // Used exclusively for Excel export styles
                        primaryAward: (params) =>
                            params.data[params.colDef.awardField] === 'primary',
                        backupAward: (params) => params.data[params.colDef.awardField] === 'backup',
                        headerRow: (params) => params.data.isHeaderRow,
                    },
                    valueFormatter: priceTable.hasPercentage
                        ? this.unitPricePercentageFormatter(proposalData.proposalId)
                        : this.unitPriceCurrencyFormatter(proposalData.proposalId),
                    width: 150,
                    suppressMenu: true,
                    cellRendererSelector: (params) => {
                        if (params.data.isHeaderRow) {
                            return null;
                        }
                        return { component: 'lineItemCellRenderer' };
                    },
                    cellStyle: this.getCellStyle,
                    cellRendererParams: {
                        hasPercentage: priceTable.hasPercentage,
                        isViewOnly: this.props.isViewOnly,
                    },
                    awardField: `${proposalData.proposalId}:${AWARD_TYPE}`,
                },
                {
                    headerName: priceTable.headerDiscount,
                    colId: `${proposalData.proposalId}:${DISCOUNT}`, // Prevents ag-grid from appending _1 to the colId: https://github.com/ag-grid/ag-grid/issues/2889
                    field: `${proposalData.proposalId}:${DISCOUNT}`,
                    noBidField: `${proposalData.proposalId}:${NO_BID}`,
                    cellClass: ['percent'], // Used exclusively for Excel export styles
                    cellStyle: this.getCellStyle,
                    cellClassRules: {
                        // Used exclusively for Excel export styles
                        headerRow: (params) => params.data.isHeaderRow,
                    },
                    valueFormatter: this.unitPricePercentageFormatter(proposalData.proposalId),
                    width: 135,
                    suppressMenu: true,
                    hide: !priceTable.hasDiscount,
                },
                {
                    headerName: priceTable.headerTotal,
                    colId: `${proposalData.proposalId}:${TOTAL_COST}`, // Prevents ag-grid from appending _1 to the colId: https://github.com/ag-grid/ag-grid/issues/2889
                    field: `${proposalData.proposalId}:${TOTAL_COST}`,
                    noBidField: `${proposalData.proposalId}:${NO_BID}`,
                    cellClass: ['4FractionCurrency'], // Used exclusively for Excel export styles
                    cellStyle: this.getCellStyle,
                    cellClassRules: {
                        // Used exclusively for Excel export styles
                        headerRow: (params) => params.data.isHeaderRow,
                    },
                    valueFormatter: this.totalCostFormatter(proposalData.proposalId),
                    width: 160,
                    suppressMenu: true,
                    hide: true,
                }
            );

            if (priceTable.hasComment) {
                children.push({
                    headerName: priceTable.headerComment,
                    field: `${proposalData.proposalId}:${COMMENT}`,
                    width: 120,
                    hide: true,
                    suppressMenu: true,
                    cellStyle: this.getCellStyle,
                    cellClassRules: {
                        // Used exclusively for Excel export styles
                        headerRow: (params) => params.data.isHeaderRow,
                    },
                });
            }

            children.push(...customColumns);

            return {
                headerName: vendorName,
                marryChildren: true,
                children,
                headerTooltip,
            };
        });

        return baseColumns.concat(vendorColumns);
    }

    getCellStyle(params) {
        const { node } = params;

        // Base styles for all cells
        const styles = {
            lineHeight: '18px',
            paddingBottom: '4px',
            paddingTop: '4px',
            whiteSpace: 'normal',
        };

        if (params.colDef.field === DESCRIPTION) {
            styles.whiteSpace = 'pre-wrap';
        }

        if (node.data.isHeaderRow) {
            return {
                ...styles,
                backgroundColor: '#eee',
                fontWeight: 'bold',
            };
        }

        if (node.rowPinned) {
            styles.fontWeight = 'bold';
        }

        return styles;
    }

    getRowNodeId(data) {
        if (data.isSummaryRow) {
            return 'summaryRow';
        }

        return data.priceItemId;
    }

    generateRows() {
        const {
            lineItemAward: { rows },
            proposalsData,
        } = this.props;

        return rows.map((row) => {
            const lineItemAwardRow = {
                description: row.description,
                isHeaderRow: row.isHeaderRow,
                lineItem: row.lineItem,
                priceItemId: row.priceItemId,
                quantity: row.quantity,
                unitToMeasure: row.unitToMeasure,
            };

            row.vendorResponses.forEach((vendorResponse, i) => {
                const proposalId = proposalsData[i].proposalId;
                const totalCost = calculatePriceItemTotalCost({ vendorResponse });
                lineItemAwardRow[`${proposalId}:${COMMENT}`] = vendorResponse.comment;
                lineItemAwardRow[`${proposalId}:${DISCOUNT}`] = vendorResponse.discount;
                lineItemAwardRow[`${proposalId}:${NO_BID}`] = vendorResponse.noBid;
                lineItemAwardRow[`${proposalId}:${QUANTITY}`] = vendorResponse.quantity;
                lineItemAwardRow[`${proposalId}:${TOTAL_COST}`] = totalCost;
                lineItemAwardRow[`${proposalId}:${UNIT_PRICE}`] = vendorResponse.unitPrice;
                lineItemAwardRow[`${proposalId}:${AWARD_TYPE}`] = vendorResponse.awardType;
                lineItemAwardRow[`${proposalId}:${AWARD_ID}`] = vendorResponse.lineItemAwardId;
                lineItemAwardRow[`${proposalId}:${VENDOR_PRICE_ITEM_ID}`] =
                    vendorResponse.vendorPriceItemId;
                lineItemAwardRow[`${proposalId}:${PROJECT_ID}`] = vendorResponse.projectId;
                lineItemAwardRow[`${proposalId}:custom1`] = vendorResponse.custom1;
                lineItemAwardRow[`${proposalId}:custom2`] = vendorResponse.custom2;
                lineItemAwardRow[`${proposalId}:custom3`] = vendorResponse.custom3;
                lineItemAwardRow[`${proposalId}:custom4`] = vendorResponse.custom4;
                lineItemAwardRow[`${proposalId}:custom5`] = vendorResponse.custom5;
            });

            return lineItemAwardRow;
        });
    }

    unitPriceCurrencyFormatter = (proposalId) => (params) => {
        if (params.data.isHeaderRow) {
            return '';
        }

        const noBid = params.data[`${proposalId}:${NO_BID}`];
        if (noBid) {
            return 'No Bid';
        }

        return currencyFormatter(params, this.currencyFormatterOpts);
    };

    unitPricePercentageFormatter = (proposalId) => (params) => {
        if (params.data.isHeaderRow) {
            return '';
        }

        const noBid = params.data[`${proposalId}:${NO_BID}`];
        if (noBid) {
            return 'No Bid';
        }

        return percentFormatter(params);
    };

    totalCostFormatter = (proposalId) => (params) => {
        const noBid = params.data[`${proposalId}:${NO_BID}`];
        if (noBid) {
            return 'No Bid';
        }
        return currencyFormatter(params, this.currencyFormatterOpts);
    };

    /**
     * Callback for saving a reference to the underlying AgReactGrid's API once it is ready. We need
     * access to the API to do things such as export data to a CSV.
     *
     * @param {object} params The `onGridReady` callback params
     * @param {object} params.api The underlying AgReactGrid's API
     */
    handleGridReady = (params) => {
        this.setState({ gridApi: params.api });
    };

    /**
     * Callback for transforming cell data when it is being exported to Excel.
     *
     * @param {object} params The `processCellCallback` callback params
     * @return {any} The value of the cell, transformed if necessary
     */
    handleProcessCell = (params) => {
        // Replace no bid cell values of 0 with "No Bid" text
        const [proposalId, colId] = (params.column.colId || '').split(':');
        const cleanColId = cleanAgGridUniqueColName(colId);

        if (
            (cleanColId === DISCOUNT || cleanColId === TOTAL_COST || cleanColId === UNIT_PRICE) &&
            params.node.data[`${proposalId}:${NO_BID}`]
        ) {
            return 'No Bid';
        }

        return params.value;
    };

    toggleHideAllColumns = () =>
        this.setState((prevState) => ({
            hideAllColumns: !prevState.hideAllColumns,
        }));

    renderGridButtons() {
        const { hideAllColumns } = this.state;
        return [
            <Button
                id="column-toggle"
                key="column-toggle"
                onClick={this.toggleHideAllColumns}
                tooltip={
                    hideAllColumns
                        ? 'Click to show all available columns'
                        : 'Click to hide all columns except Unit Cost'
                }
            >
                <i className={hideAllColumns ? 'fa fa-plus-square-o' : 'fa fa-minus-square-o'} />
                &nbsp;{hideAllColumns ? 'Show All Columns' : 'Hide All Columns'}
            </Button>,
        ];
    }

    getDocxCellWidths = (columnNum) => {
        const tableWidth = this.props.useLandscape
            ? DOCX_TABLE_LANDSCAPE_WIDTH
            : DOCX_TABLE_PORTRAIT_WIDTH;

        if (columnNum <= 5) {
            return [tableWidth - 100 * (columnNum - 1), 100];
        }
        if (columnNum <= 7) {
            return [tableWidth - 80 * (columnNum - 1), 80];
        }
        if (columnNum <= 9) {
            return [tableWidth - 60 * (columnNum - 1), 60];
        }
        return [tableWidth / columnNum, tableWidth / columnNum];
    };

    renderDocxTableHeader(columnsData, totalCols) {
        const thStyle = { backgroundColor: '#003c81' };
        const pStyle = { textAlign: 'center', color: '#FFFFFF' };

        const [descriptionWidth, otherWidth] = this.getDocxCellWidths(columnsData.length);

        const columnHeaders = columnsData.map((columnData, i) => {
            const { field, headerName } = columnData;

            const width = field === DESCRIPTION ? descriptionWidth : otherWidth;
            const colSpan = columnData.children.length;

            return (
                <th colSpan={colSpan} key={`${field} ${i}`} style={thStyle} width={width}>
                    <p className="no-trailing-space" style={pStyle}>
                        <strong>{headerName}</strong>
                    </p>
                </th>
            );
        });

        const generateDetailsHeader = () => {
            return columnsData.map((columnData) => {
                const [childDescriptionWidth, childOtherWidth] = this.getDocxCellWidths(totalCols);

                return columnData.children.map((child, j) => {
                    const width =
                        child.field === DESCRIPTION ? childDescriptionWidth : childOtherWidth;

                    if (child.hide) {
                        return null;
                    }

                    return (
                        <th key={`${child.field} ${j}`} style={thStyle} width={width}>
                            <p className="no-trailing-space" style={pStyle}>
                                <strong>{child.headerName}</strong>
                            </p>
                        </th>
                    );
                });
            });
        };

        return (
            <>
                <tr className="repeat-header-row" key="headerRow1">
                    {columnHeaders}
                </tr>
                <tr className="repeat-header-row" key="headerRow2">
                    {generateDetailsHeader()}
                </tr>
            </>
        );
    }

    renderDocxTableRows(columnsData, totalCols) {
        return this.generateRows().map((row) => {
            if (row.isHeaderRow) {
                return (
                    <tr key={row.priceItemId}>
                        <td
                            colSpan={totalCols}
                            style={{ backgroundColor: '#eee', fontWeight: 'bold' }}
                        >
                            <p>{row.lineItem}</p>
                        </td>
                    </tr>
                );
            }

            return (
                <tr key={row.priceItemId}>
                    {columnsData.map((col) => {
                        return col.children.map((child, j) => {
                            const field = child.field;
                            const awardType = row[child.awardField];
                            const noBid = row[child.noBidField];
                            const style = {};
                            let value = row[field];

                            if (child.hide) {
                                return null;
                            }

                            if (child.cellClass) {
                                const name = child.cellClass[0];

                                if (name === '4FractionCurrency') {
                                    value = noBid
                                        ? 'No Bid'
                                        : currencyFormatter({ value }, this.currencyFormatterOpts);
                                }
                                if (name === 'currency') {
                                    value = noBid ? 'No Bid' : currencyFormatter({ value });
                                }
                                if (name === 'percent') {
                                    value = noBid ? 'No Bid' : formatPercent(value);
                                }
                            }

                            if (awardType) {
                                style.color = 'white';
                                style.backgroundColor =
                                    awardType === 'primary' ? 'forestgreen' : 'gray';
                            }

                            return (
                                <td key={`${row.priceItemId} ${j}`} style={style}>
                                    <p style={{ textAlign: 'center' }}>{value}</p>
                                </td>
                            );
                        });
                    })}
                </tr>
            );
        });
    }

    renderDocxTable() {
        const {
            lineItemAward: { priceTable, title },
            defaultTitle,
            useLandscape,
        } = this.props;

        const columnsData = this.generateColDefs();
        const maxColCount = useLandscape ? 10 : 5;
        let currTableIndex = 0;
        let vendorColCount = 0;

        const tableData = columnsData.reduce((tables, colData, i) => {
            const children = colData.children.filter((child) => !child.hide);

            if (i !== 0) {
                if (vendorColCount >= maxColCount) {
                    currTableIndex += 1;
                    vendorColCount = 0;
                }

                if (!tables[currTableIndex]) {
                    tables.push([{ ...columnsData[0] }]);
                }

                tables[currTableIndex].push({ ...colData, children });

                vendorColCount += children.length;
            }

            return tables;
        }, []);

        return tableData.map((data, i) => {
            const totalCols = data[0].children.length + data[1].children.length * (data.length - 1);
            const tableCountText =
                tableData.length > 1 ? `(Table ${i + 1} of ${tableData.length})` : '';

            return (
                <div key={`table ${i}`}>
                    {(title || defaultTitle) && (
                        <p>
                            <strong>{(title || defaultTitle).toUpperCase()}</strong>
                            <span style={{ fontSize: 10 }}>&nbsp;&nbsp;{tableCountText}</span>
                            {!priceTable.hasPercentage && (
                                <span>
                                    <br />
                                    Primary award cells are green and Backup award cells are gray
                                </span>
                            )}
                        </p>
                    )}
                    <table
                        style={{ fontSize: totalCols >= maxColCount ? '8pt' : '10pt' }}
                        width={
                            useLandscape ? DOCX_TABLE_LANDSCAPE_WIDTH : DOCX_TABLE_PORTRAIT_WIDTH
                        }
                    >
                        <thead>{this.renderDocxTableHeader(data, totalCols)}</thead>
                        <tbody>{this.renderDocxTableRows(data, totalCols)}</tbody>
                    </table>
                </div>
            );
        });
    }

    render() {
        const {
            lineItemAward: { title },
            defaultTitle,
            isDocx,
        } = this.props;

        const { gridApi } = this.state;

        if (isDocx) {
            return this.renderDocxTable();
        }

        return (
            <Panel className={this.styles.panel} defaultExpanded>
                <AgGridReactPanelHeading
                    buttons={this.renderGridButtons()}
                    gridApi={gridApi}
                    processCellCallback={this.handleProcessCell}
                    title={title || defaultTitle}
                />
                <Panel.Body style={PANEL_BODY_STYLES}>
                    <Media query={`(max-width: ${SCREEN_SM_MAX}px)`}>
                        {(matches) => (
                            <WithStickyHeader offset={FIXED_TOOLBAR_HEIGHT}>
                                <AgGridReact
                                    autoHeightMaxRows={30}
                                    columns={this.generateColDefs(matches)}
                                    frameworkComponents={{
                                        lineItemCellRenderer: LineItemCellRenderer,
                                    }}
                                    getRowNodeId={this.getRowNodeId}
                                    onGridReady={this.handleGridReady} // Overriding the default to prevent a bug with lineItemCellRenderer losing styling when scrolling
                                    rowBuffer={500}
                                    rows={this.generateRows()}
                                    showToolPanelColumnExpandAll
                                    showToolPanelColumnFilter
                                    showToolPanelColumnSelectAll
                                />
                            </WithStickyHeader>
                        )}
                    </Media>
                </Panel.Body>
            </Panel>
        );
    }
}
