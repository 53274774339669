import { createSelector } from 'reselect';

import { isConsensusScorecardPage } from '../selectors';
import {
    getCurrentScoredProposals,
    getEvaluationProjectJS,
    getEvaluator,
    isEvaluationEditor,
    isViewingPastPhase,
} from '../../selectors';
import { getTotalWeight } from '../../../../helpers';
import { evaluationStatuses } from '../../../../../../shared_config/evaluations';

const { COMPLETE } = evaluationStatuses;

const isConsensusView = (state, props) => props.isConsensusView;

const getProposalEvaluations = (scoredProposals, evaluator, isConsensus) => {
    if (!evaluator && !isConsensus) {
        return [];
    }

    return scoredProposals.map((proposal) => {
        const proposalEvaluation = isConsensus
            ? proposal.consensusEvaluation
            : proposal.proposalEvaluations.find((propEval) => propEval.user_id === evaluator.id);
        return {
            ...proposal,
            proposalEvaluation,
        };
    });
};

export const getProposalEvaluationsListJS = createSelector(
    [getCurrentScoredProposals, getEvaluator, isConsensusView],
    getProposalEvaluations
);

export const getAllEvaluationPhaseScores = createSelector(
    [getEvaluationProjectJS, getEvaluator, isConsensusView],
    (project, evaluator, isConsensus) => {
        if (!project || (project.evaluationAudits || []).length === 0) {
            return undefined;
        }

        return [project, ...project.evaluationAudits.map((audit) => audit.data)].map(
            ({ evaluation, scoredProposals }) => {
                const proposals = getProposalEvaluations(scoredProposals, evaluator, isConsensus);
                return {
                    evaluation,
                    proposals: proposals.filter((proposal) => proposal.proposalEvaluation),
                    totalWeight: getTotalWeight(evaluation),
                };
            }
        );
    }
);

export const shouldShowScoresOnly = createSelector(
    [getEvaluationProjectJS, isViewingPastPhase, isConsensusScorecardPage, isEvaluationEditor],
    (project, isPastPhase, isConsensus, isEditor) => {
        if (isPastPhase || (isConsensus && !isEditor)) {
            return true;
        }
        return !!project && project.evaluation.status === COMPLETE;
    }
);
