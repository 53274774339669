import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { submit, isInvalid } from 'redux-form';
import { createSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { pageTitles } from './constants';
import { VendorActivateForm } from './VendorActivateForm';
import {
    form as vendorActivateForm,
    fields as vendorActivateFields,
    organizationFields as vendorActivateOrganizationFields,
} from './VendorActivateForm/constants';
import { SecurityInvited } from '../Account';
import { form as securityInvitedForm } from '../Account/SecurityInvited/constants';
import { getUserJS } from '../selectors';
import * as activateActions from '../../actions/account/activate';
import { saveVendor } from '../../actions/account/personal';
import { save as saveSecurity } from '../../actions/account/securityInvited';
import { basicUserRoles } from '../../../../shared_config/userRoles';
import { Main, PageTitle, ProgressBar } from '../../components';

const { USER } = basicUserRoles;

const mapStateToProps = (state) => {
    return {
        activating: state.accountActivate.get('activating'),
        isSecurityInvitedInvalid: isInvalid(securityInvitedForm)(state),
        isVendorActivateInvalid: isInvalid(vendorActivateForm)(state),
        personalError: state.accountPersonal.get('error'),
        personalSaving: state.accountPersonal.get('saving'),
        securityError: state.accountSecurityInvited.get('error'),
        securitySaving: state.accountSecurityInvited.get('saving'),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    ...activateActions,
    saveVendor,
    securitySave: saveSecurity,
    submitForm: submit,
};

// @connect
class ConnectedActivateVendor extends Component {
    static propTypes = {
        activate: PropTypes.func.isRequired,
        activating: PropTypes.bool,
        isSecurityInvitedInvalid: PropTypes.bool.isRequired,
        isVendorActivateInvalid: PropTypes.bool.isRequired,
        location: PropTypes.object.isRequired,
        personalError: PropTypes.string,
        personalSaving: PropTypes.bool,
        router: PropTypes.object.isRequired,
        saveVendor: PropTypes.func.isRequired,
        securityError: PropTypes.string,
        securitySave: PropTypes.func.isRequired,
        securitySaving: PropTypes.bool,
        submitForm: PropTypes.func.isRequired,
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            showErrorFields: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    get currentPage() {
        const { page } = this.props.location.query;
        return Number.isNaN(Number.parseFloat(page)) ? 0 : Number.parseInt(page, 10);
    }

    get invalid() {
        switch (this.currentPage) {
            case 1:
                return this.props.isVendorActivateInvalid;
            case 2:
                return this.props.isSecurityInvitedInvalid;
            default:
                return false;
        }
    }

    get saving() {
        return this.props.securitySaving || this.props.personalSaving;
    }

    get error() {
        return this.props.personalError || this.props.securityError;
    }

    get formName() {
        switch (this.currentPage) {
            case 1:
                return vendorActivateForm;
            case 2:
                return securityInvitedForm;
            default:
                return null;
        }
    }

    get isFormPage() {
        const currentPage = this.currentPage;
        return currentPage === 1 || currentPage === 2;
    }

    vendorActivateSubmitHandler = (data) => {
        const { user } = this.props;

        return this.props.saveVendor(user.id, data).then(() => {
            if (!this.props.personalError) {
                this.incrementPage();
            }
        });
    };

    securitySubmitHandler = (data) => {
        return this.props.securitySave(data, false).then(() => {
            if (!this.props.securityError) {
                this.incrementPage();
            }
        });
    };

    triggerSubmit = () => {
        const formName = this.formName;
        if (formName) {
            return this.props.submitForm(formName);
        }
    };

    updatePage = (pageChange) => {
        const {
            location: { pathname, query },
            router,
        } = this.props;

        const newPage = this.currentPage + pageChange;
        return router.push({
            pathname,
            search: createSearchParams({ ...query, page: newPage }).toString(),
        });
    };

    incrementPage = () => {
        this.setState({ showErrorFields: false });
        return this.updatePage(1);
    };

    decrementPage = () => {
        this.setState({ showErrorFields: false });
        return this.updatePage(-1);
    };

    handleOnErrorClicked = () => {
        this.setState({ showErrorFields: false });
        setTimeout(() => {
            this.setState({ showErrorFields: true });
        }, 250);
    };

    renderLogo() {
        if (this.isFormPage) {
            return null;
        }

        return (
            <div className="text-center">
                <img
                    alt="OpenGov Procurement logo: blue"
                    className={this.styles.logo}
                    src="https://assets.procurement.opengov.com/assets/opengov/opengov_pro_logo_blue.svg"
                />
            </div>
        );
    }

    renderProgressBar() {
        if (!this.isFormPage) {
            return null;
        }

        const { isSecurityInvitedInvalid, isVendorActivateInvalid } = this.props;
        const currentPage = this.currentPage;

        const pagesComplete =
            (!isVendorActivateInvalid ? 1 : 0) +
            (currentPage === 2 && !isSecurityInvitedInvalid ? 1 : 0);
        const percentComplete = (pagesComplete / 2) * 100;

        const isComplete = percentComplete === 100;
        const style = isComplete ? 'success' : undefined;
        const label = isComplete ? (
            <span>
                <i className="fa fa-check" /> Complete!
            </span>
        ) : undefined;

        return (
            <ProgressBar
                ariaLabel="Vendor Activation Progress"
                bsStyle={style}
                label={label}
                now={percentComplete || 1}
                striped={!isComplete}
            />
        );
    }

    renderContent() {
        const {
            user,
            user: { organization },
        } = this.props;

        switch (this.currentPage) {
            case 0:
                return this.renderWelcomePage();
            case 1: {
                const initialValues = {
                    ...pick(user, vendorActivateFields),
                    organization: pick(organization, vendorActivateOrganizationFields),
                };
                return (
                    <VendorActivateForm
                        disabledCompanyName={!!organization.name && user.role === USER}
                        includePhone={!organization.phone}
                        initialValues={initialValues}
                        onSubmit={this.vendorActivateSubmitHandler}
                        saving={this.saving}
                        showErrorFields={this.state.showErrorFields}
                    />
                );
            }
            case 2:
                return (
                    <SecurityInvited
                        onSubmit={this.securitySubmitHandler}
                        parentSubmit
                        showErrorFields={this.state.showErrorFields}
                    />
                );
            case 3:
                return this.renderConfirmationPage();
            default:
                return this.renderWelcomePage();
        }
    }

    renderConfirmationPage() {
        const { activate, activating } = this.props;

        const icon = activating ? 'fa-spinner fa-spin' : 'fa-user-plus';
        return (
            <div>
                <h1
                    className={classNames(
                        'text-procurenow',
                        this.styles.topHeader,
                        this.styles.titleFontSize
                    )}
                >
                    Account Activation
                </h1>
                <h2 className={classNames(this.styles.subText, this.styles.subtitleFontSize)}>
                    Thank you for setting up your account!
                </h2>
                <h2 className={classNames(this.styles.subHeader, this.styles.subtitleFontSize)}>
                    Please click the button below to activate your account.
                </h2>
                <div className="text-center">
                    <Button
                        bsSize="large"
                        bsStyle="success"
                        className={this.styles.activateButton}
                        disabled={activating}
                        onClick={activate}
                    >
                        <i className={`fa fa-fw ${icon}`} />
                        &nbsp;
                        {activating ? 'Activating' : 'Activate'}
                    </Button>
                </div>
                <div className="text-center">
                    <Button bsStyle="link" disabled={activating} onClick={this.decrementPage}>
                        <i className="fa fa-arrow-left" /> Go Back
                    </Button>
                </div>
            </div>
        );
    }

    renderWelcomePage() {
        return (
            <div>
                <h2 className={`text-procurenow ${this.styles.topHeader}`}>
                    Welcome to OpenGov Procurement!
                </h2>
                <h4 className={this.styles.subText}>We are excited to have you join!</h4>
                <h4 className={this.styles.subHeader}>Let&#39;s set-up your account</h4>
                <div>
                    <Button
                        bsSize="large"
                        bsStyle="success"
                        className={`center-block ${this.styles.startButton}`}
                        onClick={this.incrementPage}
                    >
                        Start <i className="fa fa-angle-double-right" />
                    </Button>
                </div>
            </div>
        );
    }

    renderHeader() {
        const pageTitle = pageTitles[this.currentPage];
        if (!pageTitle) {
            return null;
        }

        return <h1 className={this.styles.pageTitle}>{pageTitle}</h1>;
    }

    renderButtons() {
        if (!this.isFormPage) {
            return null;
        }

        const error = this.error;
        const nextContent = (
            <span>
                Next <i className="fa fa-angle-right" />
            </span>
        );

        return (
            <div className="row">
                <div className="col-xs-6 text-right">
                    <Button bsSize="large" disabled={this.saving} onClick={this.decrementPage}>
                        <i className="fa fa-angle-left" /> Previous
                    </Button>
                </div>
                <div className="col-xs-6">
                    <Button
                        bsSize="large"
                        bsStyle="primary"
                        disabled={this.saving}
                        onClick={this.invalid ? this.handleOnErrorClicked : this.triggerSubmit}
                        type="submit"
                    >
                        {this.saving ? 'Saving...' : nextContent}
                    </Button>
                </div>
                {error && <div className="error-block col-xs-12">{error}</div>}
            </div>
        );
    }

    render() {
        /**
         * There is an issue with token log in on the server. Consequently the login does not
         * happen until the client is loaded, so the user will not be present on initial render
         */
        if (!this.props.user) {
            return null;
        }

        const disabled = this.invalid || this.saving;

        return (
            <Main className="row">
                <PageTitle title="Activate Vendor" />
                {this.renderLogo()}
                <Panel
                    className={`col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8 col-lg-offset-3 col-lg-6 ${this.styles.panel}`}
                >
                    <Panel.Body>
                        <div className="row">
                            <div className="col-xs-12 col-md-offset-1 col-md-10 col-lg-offset-2 col-lg-8">
                                {this.renderHeader()}
                                {this.renderProgressBar()}
                                <div
                                    className="row"
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13 && !disabled) {
                                            this.triggerSubmit();
                                        }
                                    }}
                                >
                                    {this.renderContent()}
                                </div>
                                {this.renderButtons()}
                            </div>
                        </div>
                    </Panel.Body>
                </Panel>
            </Main>
        );
    }
}

export const ActivateVendor = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedActivateVendor);
