import { listToDict } from '../../../../../shared_config/helpers';
import { fieldTypes, fieldTypesValues } from '../../../../../shared_config/signatures';

export const fieldNames = listToDict(['footer', 'introduction', 'signaturesBlocks', 'blankBlocks']);
export const blockFieldNames = listToDict(['styling', 'pageBreakAfter', 'fields']);
export const fieldsEditableFieldNames = listToDict(['label', 'value']);

export const defaultBlock = {
    styling: 'default',
    pageBreakAfter: false,
    fields: [fieldTypesValues[fieldTypes.SIGNATURE], fieldTypesValues[fieldTypes.NAME]],
};

export const defaultSignatureHeaderBlock = {
    styling: 'header',
    pageBreakAfter: false,
    fields: [fieldTypesValues[fieldTypes.HEADER]],
};

export const stampOptions = [
    {
        label: 'Small (1 inch)',
        value: '1in',
    },
    {
        label: 'Medium (5 inches)',
        value: '5in',
    },
    {
        label: 'Large (10 inches)',
        value: '10in',
    },
];
