import { groupBy, map, meanBy, range, round, sortBy, startCase, upperCase } from 'lodash';
import moment from 'moment';
import qs from 'qs';
import { createSelector } from 'reselect';

import { projectPhases, customColors } from './constants';
import {
    getNonSpecialTemplatePurchaseSelectOptions,
    getTemplateDocBuilderSelectOptions,
    getWorkloadWeightStatuses,
    hasAwardPendingProjectStatus,
    hasDocBuilderSubscription,
    hasEvaluationSubscription,
    hasIntakeSubscription,
    hasSourcingSubscription,
} from '../selectors';
import { getInvitedUsersJS, getUserJS } from '../../selectors';
import { buildMap } from '../../../../../shared_config/helpers';
import {
    builderProjectStatuses,
    projectScopeFilterTypesDict,
    projectStatuses,
    projectStatusesDict,
} from '../../../../../shared_config/projects';
import { CONTRACTS, DOCUMENTS, PROJECTS } from '../../VendorApp/Awards/constants';

const {
    REQUEST_DRAFT,
    REQUEST_REVIEW,
    DRAFT,
    REVIEW,
    FINAL, // Used in place of 'open' for non-marketplace governments
    POST_PENDING,
    OPEN,
    AUCTION_PENDING,
    REVERSE_AUCTION,
    PENDING,
    EVALUATION,
    AWARD_PENDING,
    CLOSED,
} = projectStatusesDict;

const { ALL_PROJECTS } = projectScopeFilterTypesDict;

const statusColorMap = {
    [REQUEST_DRAFT]: '#f4c37d',
    [REQUEST_REVIEW]: '#f0ad4e',
    [DRAFT]: '#85d0e7',
    [REVIEW]: '#5bc0de',
    [FINAL]: '#31b0d5',
    [POST_PENDING]: '#80c780',
    [OPEN]: '#5cb85c',
    [AUCTION_PENDING]: '#52a552',
    [REVERSE_AUCTION]: '#52a552',
    [PENDING]: '#449d44',
    [EVALUATION]: '#886ab5',
    [AWARD_PENDING]: '#6e4e9e',
};

const getCycleTimes = (state) => state.analytics.get('cycleTimes');
const getProjects = (state) => state.analytics.get('aggregateProjects');
const getRawStartDate = (state) => state.analytics.get('aggregateStartDate');
const getRawEndDate = (state) => state.analytics.get('aggregateEndDate');
const getWorkloadWeights = (state) => state.analytics.get('workloadWeights');

const getCycleTimesJS = createSelector([getCycleTimes], (rawCycleTimes) => {
    if (rawCycleTimes) {
        return rawCycleTimes.toJS();
    }
    return {};
});

const getCycleTimePhaseAverages = createSelector([getCycleTimesJS], (cycleTimes) => {
    const cycleTimePhaseCounts = {};
    const cycleTimePhaseTotals = {};

    projectStatuses.forEach((projectStatus) => {
        cycleTimePhaseCounts[projectStatus] = 0;
        cycleTimePhaseTotals[projectStatus] = 0;
    });

    if (!cycleTimes) {
        return cycleTimePhaseCounts;
    }

    Object.values(cycleTimes).forEach((projectCycleTimesPhases) => {
        projectStatuses.forEach((projectStatus) => {
            if (projectCycleTimesPhases[projectStatus]) {
                cycleTimePhaseCounts[projectStatus]++;
                cycleTimePhaseTotals[projectStatus] += projectCycleTimesPhases[projectStatus];
            }
        });
    });

    return projectStatuses.reduce((averagesMap, projectStatus) => {
        averagesMap[projectStatus] = cycleTimePhaseCounts[projectStatus]
            ? cycleTimePhaseTotals[projectStatus] / cycleTimePhaseCounts[projectStatus]
            : 0;

        return averagesMap;
    }, {});
});

export const getCycleTimeSeriesData = (isProjectsPath) =>
    createSelector(
        [
            getCycleTimePhaseAverages,
            hasAwardPendingProjectStatus,
            hasEvaluationSubscription,
            hasSourcingSubscription,
            hasDocBuilderSubscription,
            hasIntakeSubscription,
        ],
        (
            cycleTimePhaseAverages,
            hasAwardPendingStatus,
            hasEvaluation,
            hasSourcing,
            hasDocBuilder,
            hasIntake
        ) => {
            // Data is in milliseconds: 1000 -> seconds, 60 -> minutes, 60 -> hours, 24 -> days
            const millisecondsPerDay = 1000 * 60 * 60 * 24;
            const statusDaysMap = projectStatuses.reduce((daysMap, projectStatus) => {
                const days = cycleTimePhaseAverages[projectStatus] / millisecondsPerDay;

                daysMap[projectStatus] = days === 0 ? 0 : Math.round(Math.max(0.1, days) * 10) / 10;

                return daysMap;
            }, {});

            const series = [];

            if (hasEvaluation && isProjectsPath) {
                if (hasAwardPendingStatus) {
                    series.push({
                        description: 'Average number of days projects spent in Award Pending',
                        label: statusDaysMap[AWARD_PENDING]
                            ? `${statusDaysMap[AWARD_PENDING]} Days`
                            : 'N/A',
                        name: 'Award Pending',
                        data: [
                            statusDaysMap[AWARD_PENDING] > 0
                                ? Math.max(statusDaysMap[AWARD_PENDING], 1)
                                : 0,
                        ],
                        color: statusColorMap[AWARD_PENDING],
                    });
                }

                series.push({
                    description: 'Average number of days projects spent in Evaluation',
                    label: statusDaysMap[EVALUATION] ? `${statusDaysMap[EVALUATION]} Days` : 'N/A',
                    name: 'Evaluation',
                    data: [
                        statusDaysMap[EVALUATION] > 0 ? Math.max(statusDaysMap[EVALUATION], 1) : 0,
                    ],
                    color: statusColorMap[EVALUATION],
                });
            }

            if (hasSourcing && isProjectsPath) {
                series.push(
                    {
                        description: 'Average number of days projects spent in Pending',
                        label: statusDaysMap[PENDING] ? `${statusDaysMap[PENDING]} Days` : 'N/A',
                        name: 'Pending',
                        data: [
                            statusDaysMap[PENDING] > 0 ? Math.max(statusDaysMap[PENDING], 1) : 0,
                        ],
                        color: statusColorMap[PENDING],
                    },
                    {
                        description: 'Average number of days projects spent in Reverse Auction',
                        label: statusDaysMap[REVERSE_AUCTION]
                            ? `${statusDaysMap[REVERSE_AUCTION]} Days`
                            : 'N/A',
                        name: 'Reverse Auction',
                        data: [
                            statusDaysMap[REVERSE_AUCTION] > 0
                                ? Math.max(statusDaysMap[REVERSE_AUCTION], 1)
                                : 0,
                        ],
                        color: statusColorMap[REVERSE_AUCTION],
                    },
                    {
                        description: 'Average number of days projects spent in Auction Pending',
                        label: statusDaysMap[AUCTION_PENDING]
                            ? `${statusDaysMap[AUCTION_PENDING]} Days`
                            : 'N/A',
                        name: 'Auction Pending',
                        data: [
                            statusDaysMap[AUCTION_PENDING] > 0
                                ? Math.max(statusDaysMap[AUCTION_PENDING], 1)
                                : 0,
                        ],
                        color: statusColorMap[AUCTION_PENDING],
                    },
                    {
                        description: 'Average number of days projects spent in Open',
                        label: statusDaysMap[OPEN] ? `${statusDaysMap[OPEN]} Days` : 'N/A',
                        name: 'Open',
                        data: [statusDaysMap[OPEN] > 0 ? Math.max(statusDaysMap[OPEN], 1) : 0],
                        color: statusColorMap[OPEN],
                    },
                    {
                        description: 'Average number of days projects spent in Post Pending',
                        label: statusDaysMap[POST_PENDING]
                            ? `${statusDaysMap[POST_PENDING]} Days`
                            : 'N/A',
                        name: 'Post Pending',
                        data: [
                            statusDaysMap[POST_PENDING] > 0
                                ? Math.max(statusDaysMap[POST_PENDING], 1)
                                : 0,
                        ],
                        color: statusColorMap[POST_PENDING],
                    }
                );
            }

            if (hasDocBuilder) {
                series.push(
                    {
                        description: 'Average number of days projects spent in Final',
                        label: statusDaysMap[FINAL] ? `${statusDaysMap[FINAL]} Days` : 'N/A',
                        name: 'Final',
                        data: [statusDaysMap[FINAL] > 0 ? Math.max(statusDaysMap[FINAL], 1) : 0],
                        color: statusColorMap[FINAL],
                    },
                    {
                        description: 'Average number of days projects spent in Review',
                        label: statusDaysMap[REVIEW] ? `${statusDaysMap[REVIEW]} Days` : 'N/A',
                        name: 'Review',
                        data: [statusDaysMap[REVIEW] > 0 ? Math.max(statusDaysMap[REVIEW], 1) : 0],
                        color: statusColorMap[REVIEW],
                    },
                    {
                        description: 'Average number of days projects spent in Draft',
                        label: statusDaysMap[DRAFT] ? `${statusDaysMap[DRAFT]} Days` : 'N/A',
                        name: 'Draft',
                        data: [statusDaysMap[DRAFT] > 0 ? Math.max(statusDaysMap[DRAFT], 1) : 0],
                        color: statusColorMap[DRAFT],
                    }
                );
            }

            if (hasIntake && isProjectsPath) {
                series.push(
                    {
                        description: 'Average number of days project requests spent in Review',
                        label: statusDaysMap[REQUEST_REVIEW]
                            ? `${statusDaysMap[REQUEST_REVIEW]} Days`
                            : 'N/A',
                        name: 'Request Issued',
                        data: [
                            statusDaysMap[REQUEST_REVIEW] > 0
                                ? Math.max(statusDaysMap[REQUEST_REVIEW], 1)
                                : 0,
                        ],
                        color: statusColorMap[REQUEST_REVIEW],
                    },
                    {
                        description: 'Average number of days project requests spent in Draft',
                        label: statusDaysMap[REQUEST_DRAFT]
                            ? `${statusDaysMap[REQUEST_DRAFT]} Days`
                            : 'N/A',
                        name: 'Request Pending',
                        data: [
                            statusDaysMap[REQUEST_DRAFT] > 0
                                ? Math.max(statusDaysMap[REQUEST_DRAFT], 1)
                                : 0,
                        ],
                        color: statusColorMap[REQUEST_DRAFT],
                    }
                );
            }

            return series;
        }
    );

const getProjectsJS = createSelector([getProjects], (rawProjects) => {
    if (rawProjects) {
        return rawProjects.toJS();
    }
    return [];
});

export const hasProjectsJS = createSelector([getProjectsJS], (projects) => {
    return projects.length > 0;
});

const getStartDate = createSelector([getRawStartDate], (rawStartDate) => {
    if (rawStartDate) {
        return moment(rawStartDate).set({ hours: 0, minutes: 0 });
    }
});

const getEndDate = createSelector([getRawEndDate], (rawEndDate) => {
    if (rawEndDate) {
        return moment(rawEndDate).set({ hours: 23, minutes: 59, seconds: 0 });
    }
});

const getDateRangeUnits = createSelector([getStartDate, getEndDate], (startDate, endDate) => {
    if (!startDate || !endDate) return null;

    const dateRange = endDate.diff(startDate, 'days') + 1;
    if (dateRange <= 14) {
        return {
            interval: 'days',
            format: 'MMM D',
            startDate: startDate.set({ hours: 0, minutes: 0, seconds: 0 }),
        };
    }
    if (dateRange <= 40) {
        return {
            interval: 'days',
            intervalCount: 3,
            format: 'MMM D',
            startDate: startDate.set({ hours: 0, minutes: 0, seconds: 0 }),
        };
    }
    if (dateRange <= 100) {
        return {
            interval: 'weeks',
            format: 'MMM D',
            startDate: startDate.set({ hours: 0, minutes: 0, seconds: 0 }),
        };
    }
    if (dateRange <= 365 * 2) {
        return {
            interval: 'months',
            format: 'MMM',
            startDate: startDate.set({ date: 1, hours: 0, minutes: 0, seconds: 0 }),
        };
    }
    if (dateRange <= 365 * 6) {
        return {
            interval: 'months',
            format: 'MMM YYYY',
            intervalCount: 3,
            startDate: startDate.set({ date: 1, hours: 0, minutes: 0, seconds: 0 }),
        };
    }
    return {
        interval: 'years',
        format: 'YYYY',
        startDate: startDate.set({ month: 0, date: 1, hours: 0, minutes: 0, seconds: 0 }),
    };
});

const computeRange = createSelector([getDateRangeUnits, getEndDate], (units, endDate) => {
    if (!units || !endDate) return null;

    const { startDate, interval, intervalCount } = units;
    const date = moment(startDate);

    const dateRange = [];
    while (date.isBefore(endDate)) {
        dateRange.push(date.clone());
        date.add(intervalCount || 1, interval);
    }
    return dateRange;
});

const getProjectsByDate = createSelector(
    [getProjectsJS, computeRange, getEndDate, getDateRangeUnits],
    (projects, dateRange, endDate, units) => {
        if (!dateRange || !endDate || !units) return [];

        const mappedProjects = projects.map((project) => ({
            ...project,
            created_at: moment(project.created_at),
        }));

        return dateRange.map((date, idx) => {
            const startDate = date;
            const computedEndDate = dateRange[idx + 1] || endDate;
            return {
                // Changes format from 'Jan' to 'Jan 2017'
                categoryName:
                    date.format(units.format) === 'Jan'
                        ? date.format('MMM YYYY')
                        : date.format(units.format),
                projects: mappedProjects.filter((project) => {
                    return project.created_at.isBetween(startDate, computedEndDate);
                }),
            };
        });
    }
);

// Was used to display a line graph. Currently unused, but left here in case
// it ever needs to be re-enabled.
// const getHistoricAnalytics = createSelector(
//     [getProjectsByDate],
//     (mappedData) => {
//         return {
//             categories: mappedData.map(data => data.categoryName),
//             series: [{
//                 name: 'Projects',
//                 data: mappedData.map(data => data.projects.length),
//                 color: customColors[0],
//             }],
//         };
//     }
// );

export const getHistoricPurchaseStackedAnalytics = createSelector(
    [getProjectsByDate, getNonSpecialTemplatePurchaseSelectOptions],
    (mappedData, templateOptions) => {
        const seriesData = templateOptions.map((templateOption, idx) => {
            return {
                name: `${templateOption.label} Projects`,
                color: customColors[idx],
                data: mappedData.map(
                    (data) =>
                        data.projects.filter((proj) => proj.template_id === templateOption.value)
                            .length
                ),
            };
        });
        return {
            categories: mappedData.map((data) => data.categoryName),
            series: seriesData,
        };
    }
);

export const getHistoricContractStackedAnalytics = createSelector(
    [getProjectsByDate, getTemplateDocBuilderSelectOptions],
    (mappedData, templateOptions) => {
        const seriesData = templateOptions.map((templateOption, idx) => {
            return {
                name: `${templateOption.label} Contract Documents`,
                color: customColors[idx],
                data: mappedData.map(
                    (data) =>
                        data.projects.filter((proj) => proj.template_id === templateOption.value)
                            .length
                ),
            };
        });
        return {
            categories: mappedData.map((data) => data.categoryName),
            series: seriesData,
        };
    }
);

export const getDepartmentAnalytics = createSelector([getProjectsJS], (projects) => {
    const departmentProjects = groupBy(projects, 'department.name');
    return map(departmentProjects, (deptProjects, deptName) => ({
        name: deptName,
        y: deptProjects.length,
    }));
});

const getTimelineData = createSelector([getProjectsJS], (projects) => {
    const momentProjects = projects
        .filter((project) => project.status === CLOSED && !project.isIntake) // Intakes don't have timelines and can cause `NaN` values
        .map((project) => ({
            ...project,
            createdAt: moment(project.created_at),
            releaseDate: moment(project.releaseProjectDate),
            proposalDeadline: moment(project.proposalDeadline),
            contractorSelectedDate: moment(project.contractorSelectedDate),
        }));

    return momentProjects.map((project) => ({
        ...project,
        writingTime: project.releaseDate.diff(project.createdAt, 'days'),
        proposalTime: project.proposalDeadline.diff(project.releaseDate, 'days'),
        selectionTime: project.contractorSelectedDate.diff(project.proposalDeadline, 'days'),
        totalTime: project.contractorSelectedDate.diff(project.createdAt, 'days'),
    }));
});

export const getTimelineAnalytics = createSelector([getTimelineData], (timelineData) => {
    const templateProjects = groupBy(timelineData, 'template_id');
    const unsortedTemplateProjects = Object.values(templateProjects).map((projects) => ({
        name: upperCase(projects[0].template.title),
        data: [
            round(meanBy(projects, 'writingTime') / 7, 2),
            round(meanBy(projects, 'proposalTime') / 7, 2),
            round(meanBy(projects, 'selectionTime') / 7, 2),
            round(meanBy(projects, 'totalTime') / 7, 2),
        ],
    }));

    return sortBy(unsortedTemplateProjects, 'name').map((chartData, idx) => {
        return { ...chartData, color: customColors[idx] };
    });
});

export const getTimelinePercentageAnalytics = createSelector([getTimelineData], (timelineData) => {
    const averagedData = [
        round(meanBy(timelineData, 'writingTime') / 7, 2),
        round(meanBy(timelineData, 'proposalTime') / 7, 2),
        round(meanBy(timelineData, 'selectionTime') / 7, 2),
        round(meanBy(timelineData, 'totalTime') / 7, 2),
    ];
    return [
        {
            type: 'pie',
            name: '% of Project Time',
            innerSize: '40%',
            data: range(projectPhases.length - 1).map((idx) => [
                projectPhases[idx],
                averagedData[idx],
            ]),
        },
    ];
});

export const getTemplateCounts = createSelector([getProjectsJS], (projects) => {
    const groupedTemplateProjects = groupBy(projects, 'template_id');
    const unsortedChartData = Object.values(groupedTemplateProjects).map((templateProjects) => ({
        category: upperCase(templateProjects[0].template.title),
        projectCount: templateProjects.length,
    }));

    const chartData = sortBy(unsortedChartData, 'category');

    return {
        categories: chartData.map((data) => data.category),
        series: [
            {
                name: 'Number of Projects',
                data: chartData.map((data) => data.projectCount),
            },
        ],
    };
});

export const getWorkloadWeightsJS = (projectTypePath, router) =>
    createSelector(
        [getWorkloadWeights, getInvitedUsersJS, getWorkloadWeightStatuses, getUserJS],
        (rawWorkloadWeights, users, workloadWeightStatuses, user) => {
            const workloadByUser = rawWorkloadWeights ? rawWorkloadWeights.toJS() : [];
            const usersMap = buildMap(users, 'id');
            const unsortedWorkloadWeightsList = Object.keys(workloadByUser).reduce(
                (workloadList, procurementContactId) => {
                    const procurementUser = usersMap[procurementContactId];
                    if (procurementUser) {
                        workloadList.push({
                            userName: procurementUser.displayName,
                            workload: workloadByUser[procurementContactId],
                        });
                    }
                    return workloadList;
                },
                []
            );

            const workloadWeightsList = sortBy(unsortedWorkloadWeightsList, 'userName');

            const unassignedWorkload = workloadByUser.unassigned;
            if (unassignedWorkload) {
                workloadWeightsList.push({
                    userName: 'Unassigned',
                    workload: unassignedWorkload,
                });
            }

            return {
                categories: workloadWeightsList.map((data) => data.userName),
                series: (projectTypePath === PROJECTS
                    ? workloadWeightStatuses
                    : builderProjectStatuses
                ).map((status) => {
                    return {
                        name: startCase(status),
                        data: workloadWeightsList.map((data) => {
                            return {
                                y: data.workload[status] || 0,
                                events: {
                                    click: () => {
                                        const queryString = qs.stringify({
                                            ids: data.workload.projectIds,
                                            scope: ALL_PROJECTS,
                                        });
                                        router.push(
                                            `/governments/${user.government_id}/${
                                                projectTypePath === CONTRACTS
                                                    ? DOCUMENTS // When viewing the workload weights for contracts, we want to link to the documents page, not the contracts project list page
                                                    : PROJECTS
                                            }?${queryString}`
                                        );
                                    },
                                },
                            };
                        }),
                        color: statusColorMap[status],
                    };
                }),
            };
        }
    );
