import PropTypes from 'prop-types';
import React from 'react';

import { LinkContainer, UnderlineNav } from '../../../../components';

export const NavItems = ({ items, requisitionsAdminPath }) => {
    const styles = require('./index.scss');

    return (
        <UnderlineNav className={styles.navItems}>
            {items.map(({ isActive, path, title }, index) => {
                return (
                    <LinkContainer
                        active={isActive}
                        key={index}
                        to={`${requisitionsAdminPath}${path}`}
                    >
                        <UnderlineNav.NavItem
                            overrideIsActive={false}
                            qaTag={`requisitionAdminNav-${title}`}
                        >
                            {title}
                        </UnderlineNav.NavItem>
                    </LinkContainer>
                );
            })}
        </UnderlineNav>
    );
};

NavItems.propTypes = {
    items: PropTypes.array.isRequired,
    requisitionsAdminPath: PropTypes.string.isRequired,
};
