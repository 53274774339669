import { get, partial } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { CDSButton, CDSButtonGroup } from '../../../..';
import { QuestionnaireListItem } from './QuestionnaireListItem';
import { QuestionnaireSubList } from './QuestionnaireSubList';
import { BID_BOND, DATA } from '../../../../../../../shared_config/questionnaires';
import { numberItems } from '../../../../../../../shared_config/helpers';
import { useOGTheme } from '../../../../../containers/GovApp/ogThemeProvider';
import { reorderAndNumber, processDragEvent } from './utils';

// Component reshapes the data for the questionnaire to conform to a nested list structure
// Needed so drag and drop can be properly contained within the nested list
export const QuestionnaireFormattedList = ({
    change,
    disabled,
    editHandler,
    fields,
    formErrors,
    isTemplate,
    renderQuestionLogicIcon,
    showEditModal,
    showConfirmationSimpleModal,
    showCreateQuestionnaireModal,
    tagOptions,
    templateVariableOptions,
    TemplateQuestionAddButton,
    useRawPrompt,
    usesTemplateQuestions,
}) => {
    const { isOGThemeEnabledForComponents } = useOGTheme();
    const editQuestion = (index, questionnaireFormData) => {
        const onEdit = partial(editHandler, index);
        showEditModal(questionnaireFormData, { onEdit, tagOptions, templateVariableOptions });
    };

    const insertQuestion = (insertAfterQuestionId) => {
        showCreateQuestionnaireModal({ insertAfterQuestionId });
    };

    const removeField = (index) => {
        showConfirmationSimpleModal(() => fields.remove(index), {
            text: 'Are you sure you want to remove this item?',
        });
    };

    const moveFieldUp = (index, dragType) => {
        if (index === 0) return;

        processDragEvent(fields, dragType, index, index - 1, false);

        // Relies on actions in `processDragEvent` being reflected in store so
        // setTimeout to ensure processDragEvent propagated and there's no race condition
        setTimeout(() => reorderAndNumber(fields, change, dragType), 0);
    };

    const moveFieldDown = (index, dragType) => {
        if (index === fields.length) return;

        const questionnaire = fields.get(index);

        processDragEvent(
            fields,
            dragType,
            index,
            index + 1 + questionnaire.conditionalSubQuestionIds.length
        );

        // Relies on actions in `processDragEvent` being reflected in store so
        // setTimeout to ensure processDragEvent propagated and there's no race condition
        setTimeout(() => reorderAndNumber(fields, change, dragType), 0);
    };

    const questionnaires = fields.map((member, index) => {
        const questionnaire = fields.get(index);
        return {
            ...questionnaire,
            index,
        };
    });
    const numberedQuestionnaires = numberItems(questionnaires);

    return numberedQuestionnaires
        .map((questionnaireData) => {
            const { index, ...questionnaire } = questionnaireData;

            // Currently, only the Bid Bond questionnaire type is being checked for errors at the list level
            const hasError = formErrors
                ? questionnaire.type === BID_BOND && !!get(formErrors.questionnaires[index], [DATA])
                : false;

            const questionLogicIcon = renderQuestionLogicIcon
                ? renderQuestionLogicIcon(questionnaire, showCreateQuestionnaireModal)
                : undefined;

            return {
                disabled,
                hasError,
                index,
                insertQuestion,
                isLastQuestion: index === fields.length - 1,
                moveQuestionUp: moveFieldUp,
                moveQuestionDown: moveFieldDown,
                onClick: editQuestion,
                questionLogicIcon,
                questionnaire,
                remove: removeField,
                useRawPrompt,
                usesTemplateQuestions,
            };
        })
        .map((questionnaireListItemProps, curIndex, array) => {
            const { index, questionnaire } = questionnaireListItemProps;

            const questionnaireId = questionnaire.id || questionnaire.uuid;
            const draggableProps = {
                draggableId: `questionnaireListItem:${questionnaireId}`,
                index,
                key: questionnaireId,
            };

            if (questionnaire.isConditionalSubQuestion || questionnaire.isHiddenByLogic) {
                // Hide the questionnaire item, but include drag props so it properly reorders
                return (
                    <Draggable {...draggableProps}>
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                                <div style={{ display: 'none' }} {...provided.dragHandleProps} />
                            </div>
                        )}
                    </Draggable>
                );
            }

            // Build sub-questions list by looking at next items in array
            const conditionalSubQuestionsToRender = [];
            let nextIndex = curIndex + 1;
            let nextItem = array[nextIndex];

            while (
                nextItem &&
                nextItem.questionnaire.isConditionalSubQuestion &&
                questionnaire.conditionalSubQuestionIds?.includes(nextItem.questionnaire.id)
            ) {
                conditionalSubQuestionsToRender.push(nextItem);
                nextItem = array[++nextIndex];
            }

            const nextOrderById = conditionalSubQuestionsToRender.length
                ? conditionalSubQuestionsToRender[conditionalSubQuestionsToRender.length - 1]
                      .questionnaire.orderById
                : questionnaire.orderById;

            return (
                <Draggable {...draggableProps}>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                            <QuestionnaireListItem
                                draggableProvided={provided}
                                isTemplate={isTemplate}
                                isTemplateQuestion={
                                    usesTemplateQuestions && questionnaire.isTemplate
                                }
                                {...questionnaireListItemProps}
                            />
                            {conditionalSubQuestionsToRender.length > 0 && (
                                <QuestionnaireSubList
                                    conditionalSubQuestions={conditionalSubQuestionsToRender}
                                    disabled={disabled}
                                    isTemplate={isTemplate}
                                    parentQuestionnaireId={questionnaireId}
                                    usesTemplateQuestions={usesTemplateQuestions}
                                />
                            )}
                            {isOGThemeEnabledForComponents && !isTemplate && (
                                <CDSButtonGroup>
                                    <CDSButton
                                        disabled={disabled}
                                        onClick={() => insertQuestion(nextOrderById)}
                                        size="small"
                                        variant="secondary-alt"
                                    >
                                        <i className="fa fa-plus" /> Add a Question
                                    </CDSButton>
                                    {!!TemplateQuestionAddButton &&
                                        TemplateQuestionAddButton('small', nextOrderById)}
                                </CDSButtonGroup>
                            )}
                        </div>
                    )}
                </Draggable>
            );
        });
};

QuestionnaireFormattedList.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    editHandler: PropTypes.func.isRequired,
    fields: PropTypes.shape({
        get: PropTypes.func.isRequired,
        map: PropTypes.func.isRequired,
        remove: PropTypes.func.isRequired,
        swap: PropTypes.func.isRequired,
    }).isRequired,
    formErrors: PropTypes.object,
    isTemplate: PropTypes.bool,
    renderQuestionLogicIcon: PropTypes.func,
    showConfirmationSimpleModal: PropTypes.func.isRequired,
    showCreateQuestionnaireModal: PropTypes.func.isRequired,
    showEditModal: PropTypes.func.isRequired,
    tagOptions: PropTypes.array,
    TemplateQuestionAddButton: PropTypes.func,
    templateVariableOptions: PropTypes.array,
    useRawPrompt: PropTypes.bool,
    usesTemplateQuestions: PropTypes.bool,
};

QuestionnaireFormattedList.defaultProps = {
    tagOptions: undefined,
    templateVariableOptions: undefined,
    useRawPrompt: false,
};
