import { fromJS, List } from 'immutable';

import * as attachmentsActions from '../actions/attachments';
import * as govProposalActions from '../actions/govProposals';
import { attachmentTypesDict } from '../../../shared_config/attachments';

const { PROPOSAL_SUPPLEMENTAL_DOCUMENT } = attachmentTypesDict;

const initialState = fromJS({
    creating: false,
    createError: null,
    disqualifying: false,
    disqualifyError: null,
    downloadDeferred: false,
    downloading: false,
    loadAllError: null,
    loadPricingError: null,
    loaded: false,
    loading: false,
    loadingAll: false,
    loadingPricing: false,
    proposals: [],
    proposalsPricingData: null,
    showCreateModal: false,
    updated: false,
    updateError: null,
    updating: false,
});

function attachmentReducer(state, action) {
    if (state.get('id') !== action.attachmentId) {
        return state;
    }

    switch (action.type) {
        case govProposalActions.DELETE_ATTACHMENT:
            return state.merge(
                fromJS({
                    deleting: true,
                    deleteError: null,
                })
            );
        case govProposalActions.DELETE_ATTACHMENT_FAIL:
            return state.merge(
                fromJS({
                    deleting: false,
                    deleteError: action.error && action.error.message,
                })
            );
        default:
            return state;
    }
}

function uploadReducer(state, action) {
    if (state.get('id') !== action.uploadId) {
        return state;
    }

    switch (action.type) {
        case attachmentsActions.UPLOAD_PROPOSAL:
        case govProposalActions.CREATE_ATTACHMENT:
            return state.merge(
                fromJS({
                    uploading: true,
                    uploadError: null,
                })
            );
        case attachmentsActions.UPLOAD_PROPOSAL_PROGRESS:
            return state.set('uploadProgress', action.progress);
        case attachmentsActions.UPLOAD_PROPOSAL_SUCCESS:
            return state.set('uploadProgress', 100);
        case attachmentsActions.UPLOAD_PROPOSAL_FAIL:
        case govProposalActions.CREATE_ATTACHMENT_FAIL:
            return state.merge(
                fromJS({
                    uploading: false,
                    uploadError: action.error && action.error.message,
                    uploadProgress: null,
                })
            );
        default:
            return state;
    }
}

export default function govProposalsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case govProposalActions.LOAD:
            return state.merge(
                fromJS({
                    loading: true,
                    loadError: null,
                })
            );
        case govProposalActions.LOAD_SUCCESS:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: true,
                    proposal: fromJS(action.result),
                })
            );
        case govProposalActions.LOAD_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: false,
                    loadError: action.error && action.error.message,
                })
            );
        case govProposalActions.LOAD_ALL:
            return state.merge(
                fromJS({
                    loadingAll: true,
                    loadAllError: null,
                })
            );
        case govProposalActions.LOAD_ALL_SUCCESS:
            return state.merge(
                fromJS({
                    loadingAll: false,
                    proposals: fromJS(action.result),
                })
            );
        case govProposalActions.LOAD_ALL_FAIL:
            return state.merge(
                fromJS({
                    loadingAll: false,
                    loadAllError: action.error && action.error.message,
                })
            );
        case govProposalActions.LOAD_VENDOR:
            return state.merge(
                fromJS({
                    loadingVendor: true,
                    loadVendorError: null,
                })
            );
        case govProposalActions.LOAD_VENDOR_SUCCESS:
            return state.merge(
                fromJS({
                    loadingVendor: false,
                    vendor: fromJS(action.result),
                })
            );
        case govProposalActions.LOAD_VENDOR_FAIL:
            return state.merge(
                fromJS({
                    loadingVendor: false,
                    loadVendorError: action.error && action.error.message,
                })
            );
        case govProposalActions.DOWNLOAD_ALL:
            return state.merge(
                fromJS({
                    downloading: true,
                    downloadDeferred: false,
                    downloadError: null,
                })
            );
        case govProposalActions.DOWNLOAD_ALL_SUCCESS:
            return state.merge(
                fromJS({
                    downloading: false,
                    downloadUrl: action.result,
                })
            );
        case govProposalActions.DOWNLOAD_ALL_SUCCESS_QUEUED:
            return state.merge(
                fromJS({
                    downloading: false,
                    downloadDeferred: true,
                })
            );
        case govProposalActions.DOWNLOAD_ALL_FAIL:
            return state.merge(
                fromJS({
                    downloading: false,
                    downloadError: action.error && action.error.message,
                })
            );
        case govProposalActions.DOWNLOAD_ALL_RESET:
            return state.merge(
                fromJS({
                    downloading: false,
                    downloadUrl: null,
                    downloadError: null,
                })
            );
        case govProposalActions.INITIATE_CREATE:
            return state.set('showCreateModal', true);
        case govProposalActions.RESET_CREATE:
            return state.merge(
                fromJS({
                    creating: false,
                    createError: null,
                    showCreateModal: false,
                })
            );
        case govProposalActions.CREATE:
            return state.merge(
                fromJS({
                    creating: true,
                    createError: null,
                })
            );
        case govProposalActions.CREATE_SUCCESS:
            return state.merge(
                fromJS({
                    creating: false,
                    proposal: fromJS(action.result),
                })
            );
        case govProposalActions.CREATE_FAIL:
            return state.merge(
                fromJS({
                    creating: false,
                    createError: action.error && action.error.message,
                })
            );
        case govProposalActions.UPDATE:
            return state.merge(
                fromJS({
                    updating: true,
                    updated: false,
                    updateError: null,
                })
            );
        case govProposalActions.UPDATE_SUCCESS:
            return state.merge(
                fromJS({
                    updating: false,
                    updated: true,
                    proposal: fromJS(action.result),
                })
            );
        case govProposalActions.UPDATE_FAIL:
            return state.merge(
                fromJS({
                    updating: false,
                    updateError: action.error && action.error.message,
                })
            );
        case govProposalActions.DISQUALIFY:
            return state.merge(
                fromJS({
                    disqualifying: true,
                    disqualifyError: null,
                })
            );
        case govProposalActions.DISQUALIFY_SUCCESS:
            return state.merge(
                fromJS({
                    disqualifying: false,
                    proposal: fromJS(action.result),
                })
            );
        case govProposalActions.DISQUALIFY_ERROR:
            return state.merge(
                fromJS({
                    disqualifying: false,
                    disqualifyError: action.error && action.error.message,
                })
            );
        case govProposalActions.LOAD_PRICING:
            return state.merge(
                fromJS({
                    loadingPricing: true,
                    loadPricingError: null,
                })
            );
        case govProposalActions.LOAD_PRICING_SUCCESS:
            return state.merge(
                fromJS({
                    loadingPricing: false,
                    proposalsPricingData: fromJS(action.result),
                })
            );
        case govProposalActions.LOAD_PRICING_FAIL:
            return state.merge(
                fromJS({
                    loadingPricing: false,
                    loadPricingError: action.error && action.error.message,
                })
            );
        case govProposalActions.DELETE_SUCCESS: {
            const newState =
                state.getIn(['proposal', 'id']) === action.proposalId
                    ? state.set('proposal', null)
                    : state;

            return newState.set(
                'proposals',
                newState
                    .get('proposals')
                    .filter((proposal) => proposal.get('id') !== action.proposalId)
            );
        }
        case govProposalActions.START_UPLOAD:
            return state.setIn(
                ['proposal', 'uploads'],
                (state.getIn(['proposal', 'uploads']) || new List()).push(fromJS(action.result))
            );
        case attachmentsActions.UPLOAD_PROPOSAL:
        case attachmentsActions.UPLOAD_PROPOSAL_PROGRESS:
        case attachmentsActions.UPLOAD_PROPOSAL_SUCCESS:
        case attachmentsActions.UPLOAD_PROPOSAL_FAIL:
        case govProposalActions.CREATE_ATTACHMENT:
        case govProposalActions.CREATE_ATTACHMENT_FAIL:
            return state.setIn(
                ['proposal', 'uploads'],
                (state.getIn(['proposal', 'uploads']) || new List()).map((upload) =>
                    uploadReducer(upload, action)
                )
            );
        case govProposalActions.COMPLETE_UPLOAD:
            return state.setIn(
                ['proposal', 'uploads'],
                state
                    .getIn(['proposal', 'uploads'])
                    .filter((upload) => upload.get('id') !== action.uploadId)
            );
        case govProposalActions.CREATE_ATTACHMENT_SUCCESS: {
            const attachmentsToUpdate =
                action.result.type === PROPOSAL_SUPPLEMENTAL_DOCUMENT
                    ? ['proposal', 'supplementalAttachments']
                    : ['proposal', 'attachments'];
            return state.setIn(
                attachmentsToUpdate,
                state.getIn(attachmentsToUpdate).push(fromJS(action.result))
            );
        }
        case govProposalActions.DELETE_ATTACHMENT:
        case govProposalActions.DELETE_ATTACHMENT_FAIL: {
            const attachmentsToUpdate = action.isSupplemental
                ? ['proposal', 'supplementalAttachments']
                : ['proposal', 'attachments'];
            return state.setIn(
                attachmentsToUpdate,
                state
                    .getIn(attachmentsToUpdate)
                    .map((attachment) => attachmentReducer(attachment, action))
            );
        }
        case govProposalActions.DELETE_ATTACHMENT_SUCCESS: {
            const attachmentsToUpdate = action.isSupplemental
                ? ['proposal', 'supplementalAttachments']
                : ['proposal', 'attachments'];
            return state.setIn(
                attachmentsToUpdate,
                state
                    .getIn(attachmentsToUpdate)
                    .filter((attach) => attach.get('id') !== action.attachmentId)
            );
        }
        case govProposalActions.TOGGLE_QUESTIONNAIRE_IS_FAILED:
            return state.setIn(
                ['proposal', 'questionnaireResponses'],
                state.getIn(['proposal', 'questionnaireResponses']).map((questionnaireResponse) => {
                    if (questionnaireResponse.get('id') === action.questionnaireResponseId) {
                        return questionnaireResponse.set('isFailed', action.isFailed);
                    }
                    return questionnaireResponse;
                })
            );
        default:
            return state;
    }
}
