import { floor, pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { DropdownButton, Panel } from 'react-bootstrap';
import { renderToStaticMarkup } from 'react-dom/server';
import Media from 'react-media';

import { QuestionnaireResponseRenderer } from './QuestionnaireResponseRenderer';
import { QuestionnaireSelectForm } from './QuestionnaireSelectForm';
import { AgGridReact, AgGridReactPanelHeading } from '..';
import { getRawQuestionnaireResponse } from '../Questionnaire/utils';
import { SCREEN_SM_MAX } from '../../constants/mediaQuery';
import { RESPONSE_DATA, SECTION_HEADER, TITLE } from '../../../../shared_config/questionnaires';
import { FIXED_TOOLBAR_HEIGHT } from '../../constants/styles';
import { WithStickyHeader } from '../../hocs';

/**
 * @typedef ColDef
 * @property {string} row.colDef.field The `field` value of the column for an individual cell
 */

/**
 * @typedef GridQuestionnaire
 * @property {number} id The id of the questionnaire
 * @property {string} [prompt] The prompt of the questionnaire, if any
 * @property {QuestionnaireResponse[]} questionnaireResponses Responses to the questionnaire
 * @property {string} title The title of the questionnaire
 * @property {string} type The type of the questionnaire
 */

// We only want local overrides which is why this is done here instead of via bsStyle/className
// TODO: Would love to get custom-styles working: https://react-bootstrap.github.io/utilities/custom-styles/
const PANEL_STYLES = {
    border: 'none',
    boxShadow: 'none',
};

const PANEL_BODY_STYLES = {
    padding: 0,
};

const QUESTION_COLUMN = 'questionColumn';

export class ProposalQuestionnaireComparisonTable extends PureComponent {
    static propTypes = {
        evaluatorId: PropTypes.number,
        isPricingSealed: PropTypes.bool.isRequired,
        proposals: PropTypes.arrayOf(
            PropTypes.shape({
                companyName: PropTypes.string.isRequired,
                id: PropTypes.number.isRequired,
                isGovernmentSubmitted: PropTypes.bool.isRequired,
                isPricingUnsealed: PropTypes.bool.isRequired,
            })
        ).isRequired,
        questionnaires: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                prompt: PropTypes.string,
                questionnaireResponses: PropTypes.arrayOf(PropTypes.object).isRequired,
                title: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
            })
        ).isRequired,
        showEvaluationScorecard: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            questionnairesDisplay: props.questionnaires.map(() => true),
        };
    }

    get styles() {
        return require('./index.scss');
    }

    get vendorColumnWidth() {
        const { proposals } = this.props;
        const numProposals = proposals.length;
        const tableWidth = 835;

        if (numProposals <= 3) return floor(tableWidth / numProposals);
        return 255;
    }

    /**
     * Gets the style for an individual cell in the table.
     *
     * @param {object} params The callback params from underlying AgGridReact
     * @param {ColDef} params.colDef The column definition for the cell being checked
     * @param {GridQuestionnaire} params.data The questionnaire data from `getFormattedQuestionnaireDataForGrid`
     * @return {object} The style object for the cell
     */
    getCellStyle(params) {
        const { data } = params;

        // Base styles for all cells
        const styles = {
            lineHeight: '18px',
            paddingBottom: '4px',
            paddingTop: '4px',
            whiteSpace: 'normal',
        };

        if (data.type === SECTION_HEADER) {
            styles.fontWeight = 'bold';
            styles.textDecoration = 'underline';
        }

        return styles;
    }

    generateColDefs(isMobile) {
        const { isPricingSealed: isAllPricingSealed, proposals } = this.props;

        const baseColumns = [
            {
                colId: QUESTION_COLUMN,
                headerName: 'Question',
                field: TITLE,
                cellClass: ['wrapText'], // Used exclusively for Excel export styles
                autoHeight: true,
                cellStyle: this.getCellStyle,
                width: isMobile ? 150 : 250,
                pinned: isMobile ? undefined : 'left',
                suppressMenu: true,
            },
        ];

        const vendorColumns = proposals.map((proposal) => {
            const { evaluatorId, showEvaluationScorecard } = this.props;

            return {
                headerComponent: 'proposalEvaluationsCompareTableHeader',
                headerComponentParams: {
                    companyName: proposal.companyName,
                    showEvaluationScorecard: evaluatorId
                        ? () => showEvaluationScorecard(proposal.id, evaluatorId)
                        : null,
                },
                headerName: proposal.companyName,
                field: `${proposal.id}:${RESPONSE_DATA}`,
                cellClass: proposal.isGovernmentSubmitted ? this.styles.govSubmitted : undefined,
                cellStyle: this.getCellStyle,
                autoHeight: true,
                width: this.vendorColumnWidth,
                suppressMenu: true,
                cellRenderer: (params) => {
                    const isPricingSealed = isAllPricingSealed && !proposal.isPricingUnsealed;
                    const ReactEl = React.createElement(QuestionnaireResponseRenderer, {
                        isPricingSealed,
                        params,
                    });
                    const htmlMarkup = renderToStaticMarkup(ReactEl);

                    const element = document.createElement('div');
                    element.innerHTML = htmlMarkup;

                    return element;
                },
                sortable: false,
            };
        });

        return baseColumns.concat(vendorColumns);
    }

    /**
     * Get questionnaire and questionnaire responses in the correct format for the AgGridReact.
     *
     * @return {GridQuestionnaire[]} The questionnaire in the proper format
     */
    getFormattedQuestionnaireDataForGrid = () => {
        const { proposals, questionnaires } = this.props;
        const { questionnairesDisplay } = this.state;
        const questionnaireFields = ['containsPricing', 'data', 'id', 'prompt', 'title', 'type'];

        return questionnaires.map((questionnaire, index) => {
            const row = pick(questionnaire, questionnaireFields);

            questionnaire.questionnaireResponses.forEach((questionnaireResponse, responseIndex) => {
                const proposalId = proposals[responseIndex].id;
                row[`${proposalId}:${RESPONSE_DATA}`] = {
                    ...pick(questionnaire, questionnaireFields),
                    questionnaireResponse,
                };
            });

            row.isDisplayed = questionnairesDisplay[index];

            return row;
        });
    };

    /**
     * Given a row of data from the grid, get a unique ID for it.
     *
     * @param {GridQuestionnaire} data The raw data in this row from `getFormattedQuestionnaireDataForGrid`
     * @return {number} The unique id of the row in the questionnaire
     */
    getRowNodeId = (data) => {
        return data.id;
    };

    /**
     * Callback for saving a reference to the underlying AgReactGrid's API once it is ready. We need
     * access to the API to do things such as export data to a CSV.
     *
     * @param {object} params The `onGridReady` callback params
     * @param {object} params.api The underlying AgReactGrid's API
     */
    handleGridReady = (params) => {
        this.setState({ gridApi: params.api });
    };

    /**
     * Callback for transforming cell data when it is being exported to Excel.
     *
     * @param {object} params The `processCellCallback` callback params
     * @return {any} The value of the cell, transformed if necessary
     */
    handleProcessCell = (params) => {
        if (params.column.colId !== QUESTION_COLUMN) {
            return getRawQuestionnaireResponse(params.value);
        }

        return params.value;
    };

    renderGridButtons() {
        const { questionnaires } = this.props;

        const { questionnairesDisplay } = this.state;

        return [
            <DropdownButton
                bsSize="sm"
                id="proposal-compare-row-options"
                key="proposal-compare-row-options"
                title={
                    <>
                        <i className="fa fa-filter" /> Select Questions
                    </>
                }
            >
                <QuestionnaireSelectForm
                    arrayName="questionnairesDisplay"
                    initialValues={{
                        questionnairesDisplay,
                        selectAll: true,
                    }}
                    onChange={(data) => {
                        this.setState({ questionnairesDisplay: data.questionnairesDisplay });
                    }}
                    questionnaires={questionnaires}
                />
            </DropdownButton>,
        ];
    }

    // We always want to use the external filter
    isExternalFilterPresent = () => true;

    doesExternalFilterPass = (node) => node.data.isDisplayed;

    render() {
        const { gridApi } = this.state;

        return (
            <Panel defaultExpanded style={PANEL_STYLES}>
                <AgGridReactPanelHeading
                    buttons={this.renderGridButtons()}
                    gridApi={gridApi}
                    processCellCallback={this.handleProcessCell}
                    title="Vendor Questionnaires"
                />
                <Panel.Body style={PANEL_BODY_STYLES}>
                    <Media query={`(max-width: ${SCREEN_SM_MAX}px)`}>
                        {(matches) => (
                            <WithStickyHeader offset={FIXED_TOOLBAR_HEIGHT}>
                                <AgGridReact
                                    columns={this.generateColDefs(matches)}
                                    doesExternalFilterPass={this.doesExternalFilterPass}
                                    domLayout="autoHeight"
                                    getRowNodeId={this.getRowNodeId}
                                    isExternalFilterPresent={this.isExternalFilterPresent}
                                    onGridReady={this.handleGridReady}
                                    rows={this.getFormattedQuestionnaireDataForGrid()}
                                />
                            </WithStickyHeader>
                        )}
                    </Media>
                </Panel.Body>
            </Panel>
        );
    }
}
