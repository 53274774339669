import React from 'react';
import { Outlet } from 'react-router-dom';

import {
    RequisitionGroupEdit,
    RequisitionGroupsList,
    RequisitionGroupsNav,
    ReviewSequenceEdit,
    ReviewSequencesList,
} from '../../../containers/Requisitions';
import {
    legacyFetchData,
    requireSystemAdmin,
    requireRequisitionsSubscription,
} from '../../loaders';

export default (getState, dispatch) => {
    return [
        {
            element: <Outlet />,
            loader: async (props) => {
                const permissionRedirect = await requireSystemAdmin(getState)(props);

                if (permissionRedirect) {
                    return permissionRedirect;
                }

                return requireRequisitionsSubscription(getState)(props);
            },
            path: 'requisition-groups',
            children: [
                {
                    element: <RequisitionGroupsNav />,
                    children: [
                        {
                            element: <RequisitionGroupsList />,
                            loader: legacyFetchData(getState, dispatch, RequisitionGroupsList),
                            index: true,
                        },
                        {
                            element: <ReviewSequencesList />,
                            loader: legacyFetchData(getState, dispatch, ReviewSequencesList),
                            path: 'sequences',
                        },
                        {
                            element: <ReviewSequenceEdit />,
                            loader: legacyFetchData(getState, dispatch, ReviewSequenceEdit),
                            path: 'sequences/:reviewSequenceId',
                        },
                        {
                            element: <RequisitionGroupEdit />,
                            loader: legacyFetchData(getState, dispatch, RequisitionGroupEdit),
                            path: ':requisitionGroupId',
                        },
                    ],
                },
            ],
        },
    ];
};
