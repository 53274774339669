import React from 'react';

export const TemplateListTimestampHeaders = () => {
    return (
        <div className="row">
            <div className="col-md-6">Created</div>
            <div className="col-md-6">Last Updated</div>
        </div>
    );
};
