import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { getIsApprovalComplete, getReviewStepApproverMap } from './selectors';
import {
    getApprovalsListJS,
    getBuilderDisplayName,
    getContractModulePath,
    getReviewProjectJS,
    getIntakeDashboardPath,
    getUserApprovalJS,
    isBuilderEditor,
    isGlobalEditorForProject,
    isIntakeEditor,
    getWritingPath,
} from '../selectors';
import { getActiveUsersSelectOptions, getInvitedUsersJS, getUserJS } from '../../selectors';
import { showPartnerResourcesModal } from '../../../actions/app';
import * as approvalsActions from '../../../actions/approvals';
import {
    menuActionHandler as menuAction,
    showInstructionsModal,
} from '../../../actions/govProjects';
import { showProjectCreateModal } from '../../../actions/project/create/projectCreate';
import { LoadingError, PageTitle } from '../../../components';
import { ApprovalsDashboard, ProcessIntake } from '../../../components/GovApp';
import {
    CLOSE_PROJECT,
    CREATE_CONTRACT_FROM_INTAKE,
    FINALIZE,
    INTAKE_INCOMPLETE,
} from '../../../constants/menuActions';
import { projectStatusesDict } from '../../../../../shared_config/projects';
import { getOrganization } from '../../../components/connected/OrganizationInfoForm/selectors';

const { DRAFT, REQUEST_REVIEW, REVIEW } = projectStatusesDict;

const mapStateToProps = (state, props) => {
    return {
        addApproversError: state.approvals.get('addError'),
        addingApprovers: state.approvals.get('adding'),
        approvals: getApprovalsListJS(state),
        builderDisplayName: getBuilderDisplayName(state),
        loadApprovalsError: !!state.approvals.get('loadError'),
        organization: getOrganization(state),
        project: getReviewProjectJS(state),
        user: getUserJS(state),
        writingPath: getWritingPath(state, props),
    };
};

const mapDispatchToProps = {
    ...approvalsActions,
    menuActionHandler: menuAction,
    showInstructions: showInstructionsModal,
    showPartnerResourcesModal,
    showProjectCreateModal,
};

// @connect
class ConnectedApprovals extends Component {
    static propTypes = {
        addApproversError: PropTypes.string,
        addApprovers: PropTypes.func.isRequired,
        addingApprovers: PropTypes.bool.isRequired,
        approvalReminder: PropTypes.func.isRequired,
        approvals: PropTypes.array.isRequired,
        builderDisplayName: PropTypes.string.isRequired,
        contractsProjectPath: PropTypes.string,
        inDraft: PropTypes.bool,
        inReview: PropTypes.bool.isRequired,
        instructionType: PropTypes.string.isRequired,
        isApprovalComplete: PropTypes.bool,
        isEditor: PropTypes.bool.isRequired,
        isGlobalEditorUser: PropTypes.bool,
        isIntake: PropTypes.bool,
        loadApprovalsError: PropTypes.bool.isRequired,
        menuActionHandler: PropTypes.func.isRequired,
        organization: PropTypes.object.isRequired,
        project: PropTypes.object.isRequired,
        removeApprover: PropTypes.func.isRequired,
        removeReviewStep: PropTypes.func.isRequired,
        reviewStepApproversMap: PropTypes.object,
        intakeDashboardPath: PropTypes.string,
        showInstructions: PropTypes.func.isRequired,
        showPartnerResourcesModal: PropTypes.func.isRequired,
        showProjectCreateModal: PropTypes.func.isRequired,
        showModal: PropTypes.func.isRequired,
        updateApprovers: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        userApproval: PropTypes.object,
        users: PropTypes.array.isRequired,
        writingPath: PropTypes.string.isRequired,
    };

    addApproversSubmitHandler = (formData) => {
        const { project, addApprovers } = this.props;
        const { approverIds } = formData;
        const userIds = approverIds.map((user) => user.value);
        return addApprovers(project.id, userIds);
    };

    updateApproversSubmitHandler = (formData, reviewStepId) => {
        const { project, updateApprovers } = this.props;
        const { approverIds } = formData;
        const userIds = approverIds.map((user) => user.value);
        return updateApprovers(project.id, userIds, reviewStepId);
    };

    handleCloseOut = () => {
        const { menuActionHandler, project } = this.props;

        menuActionHandler(CLOSE_PROJECT, project);
    };

    handleContractCreate = () => {
        const { menuActionHandler, project } = this.props;

        menuActionHandler(CREATE_CONTRACT_FROM_INTAKE, project);
    };

    handlePublish = () => {
        const { menuActionHandler, project } = this.props;

        menuActionHandler(FINALIZE, project);
    };

    handleIntakeIncomplete = () => {
        const { menuActionHandler, project } = this.props;

        menuActionHandler(INTAKE_INCOMPLETE, project);
    };

    showInstructionsModal = () => {
        const { instructionType, isEditor, showInstructions, userApproval } = this.props;

        showInstructions(instructionType, {
            isAssignedUser: !!userApproval,
            isEditor,
        });
    };

    render() {
        const {
            addApprovers,
            addApproversError,
            addingApprovers,
            approvalReminder,
            approvals,
            builderDisplayName,
            contractsProjectPath,
            inDraft,
            inReview,
            isApprovalComplete,
            isEditor,
            isGlobalEditorUser,
            isIntake,
            loadApprovalsError,
            organization,
            project,
            intakeDashboardPath,
            showModal,
            removeApprover,
            removeReviewStep,
            reviewStepApproversMap,
            user,
            userApproval,
            users,
            writingPath,
        } = this.props;

        if (loadApprovalsError) {
            return <LoadingError />;
        }

        if (isIntake) {
            return (
                <ProcessIntake
                    addApproversError={addApproversError}
                    addApproversHandler={addApprovers}
                    addingApprovers={addingApprovers}
                    approvals={approvals}
                    closeOutHandler={this.handleCloseOut}
                    contractsProjectPath={contractsProjectPath}
                    createContractHandler={this.handleContractCreate}
                    inReview={inReview}
                    intakeDashboardPath={intakeDashboardPath}
                    intakeIncompleteHandler={this.handleIntakeIncomplete}
                    isAbleToAssignApprover={!!userApproval || isGlobalEditorUser}
                    isAssignedUser={!!userApproval}
                    isEditor={isEditor}
                    organization={organization}
                    project={project}
                    reminderHandler={approvalReminder}
                    showInstructionsModal={this.showInstructionsModal}
                    showModal={showModal}
                    showPartnerResourcesModal={this.props.showPartnerResourcesModal}
                    showProjectCreateModal={this.props.showProjectCreateModal}
                    user={user}
                    users={users}
                />
            );
        }

        return (
            <>
                <PageTitle title="Approvals" />
                <ApprovalsDashboard
                    addApproversError={addApproversError}
                    addApproversHandler={this.addApproversSubmitHandler}
                    addingApprovers={addingApprovers}
                    approvals={approvals}
                    builderDisplayName={builderDisplayName}
                    inDraft={inDraft}
                    inReview={inReview}
                    isApprovalComplete={isApprovalComplete}
                    isEditor={isEditor}
                    project={project}
                    publishHandler={this.handlePublish}
                    reminderHandler={approvalReminder}
                    removeApproverHandler={removeApprover}
                    removeReviewStepHandler={removeReviewStep}
                    reviewStepApproversMap={reviewStepApproversMap}
                    showInstructionsModal={this.showInstructionsModal}
                    showModal={showModal}
                    updateApproversHandler={this.updateApproversSubmitHandler}
                    user={user}
                    users={users}
                    writingPath={writingPath}
                />
            </>
        );
    }
}

export const Approvals = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedApprovals);

const IntakeApprovals = (props) => {
    return <Approvals isIntake {...props} />;
};
Approvals.Intake = compose(
    withRouter,
    connect((state, props) => {
        return {
            contractsProjectPath: getContractModulePath(state, props),
            inReview: state.govProjects.getIn(['selectedProject', 'status']) === REQUEST_REVIEW,
            instructionType: 'intakeIssued',
            isEditor: isIntakeEditor(state),
            intakeDashboardPath: getIntakeDashboardPath(state, props),
            userApproval: getUserApprovalJS(state),
            users: getActiveUsersSelectOptions(state),
            isGlobalEditorUser: isGlobalEditorForProject(state),
        };
    })
)(IntakeApprovals);

const BuilderApprovals = (props) => {
    return <Approvals {...props} />;
};
Approvals.Builder = connect((state) => {
    return {
        inDraft: state.govProjects.getIn(['selectedProject', 'status']) === DRAFT,
        inReview: state.govProjects.getIn(['selectedProject', 'status']) === REVIEW,
        instructionType: 'approvals',
        isApprovalComplete: getIsApprovalComplete(state),
        isEditor: isBuilderEditor(state),
        reviewStepApproversMap: getReviewStepApproverMap(state),
        users: getInvitedUsersJS(state),
    };
})(BuilderApprovals);
