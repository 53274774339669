import React from 'react';
import { useSelector } from 'react-redux';
import { NoSsr } from '@mui/material';

import {
    ADDITIONAL_INFORMATION,
    ATTACHMENTS,
    GENERAL_INFORMATION,
    PURCHASE_DETAILS,
    sectionsTypes,
    VENDOR_SELECTION,
} from '../../../../../constants/requisitionsCreate';
import { getRequisitionsCreateSections } from '../../selectors';
import {
    ReviewAdditionalInformation,
    ReviewAttachments,
    ReviewGeneralInformation,
    ReviewPurchaseDetails,
    ReviewVendorSelection,
} from '../ReviewComponents';
import { Button } from '../../../../../components';
import { qaTagPageName } from '../../constants';
import { useRequisitionSectionClick } from '../useRequisitionSectionClick';
import { BudgetCheckInfoBanner } from '../../../BudgetCheckInfoBanner';

const sectionComponents = {
    [GENERAL_INFORMATION]: ReviewGeneralInformation,
    [PURCHASE_DETAILS]: ReviewPurchaseDetails,
    [VENDOR_SELECTION]: ReviewVendorSelection,
    [ADDITIONAL_INFORMATION]: ReviewAdditionalInformation,
    [ATTACHMENTS]: ReviewAttachments,
};

export const ReviewAndSubmit = () => {
    const sections = useSelector(getRequisitionsCreateSections);
    const styles = require('./index.scss');

    const handleEditClick = useRequisitionSectionClick();

    return (
        <NoSsr>
            <div className={styles.container}>
                <BudgetCheckInfoBanner />
                {sectionsTypes.map((sectionKey) => {
                    const section = sections[sectionKey];

                    const Component = sectionComponents[sectionKey];

                    if (!Component || section.disabled) {
                        return null;
                    }

                    return (
                        <div className={styles.formContainer} key={section.type}>
                            <div className={styles.titleContainer}>
                                <h3>{section.name}</h3>
                                <Button
                                    bsStyle="link"
                                    className={styles.linkButton}
                                    onClick={() => handleEditClick(sectionKey)}
                                    qaTag={`${qaTagPageName}-edit-${section.type}`}
                                    zeroPadding
                                >
                                    <i className="fa fa-pencil-square-o" />
                                    Edit
                                </Button>
                            </div>
                            <Component />
                        </div>
                    );
                })}
            </div>
        </NoSsr>
    );
};
