import PropTypes from 'prop-types';
import React from 'react';

export const RequisitionGroupReviewSequence = ({ reviewSequence: { icon, isDefault, name } }) => {
    return (
        <>
            <div>
                {icon && (
                    <>
                        <i className={`fa fa-fw fa-${icon}`} />
                        &nbsp;
                    </>
                )}
                {name || 'Untitled'}
            </div>
            {isDefault && (
                <div style={{ lineHeight: '1.2em' }}>
                    <small className="text-muted">
                        <em>Default Sequence</em>
                    </small>
                </div>
            )}
        </>
    );
};

RequisitionGroupReviewSequence.propTypes = {
    reviewSequence: PropTypes.shape({
        icon: PropTypes.string,
        isDefault: PropTypes.bool.isRequired,
        name: PropTypes.string,
    }).isRequired,
};
