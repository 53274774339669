import { DROPPABLE_CHILDREN_TYPE } from './constants';
import { ORDER_BY_ID } from '../../../../../../../shared_config/questionnaires';

const isSubChildDragEvent = (dragType) => dragType.includes(DROPPABLE_CHILDREN_TYPE);

// Item is being dragged from top to bottom
// Any children of item should be added after the item
const getDragDownDestinationLocation = (fields, newLocation) => {
    let destinationLocation = newLocation;
    let questionnaireToReplace = fields.get(newLocation);

    // When replacing a parent question, move location beyond all of its child questions
    if ((questionnaireToReplace.conditionalSubQuestionIds || []).length > 0) {
        return destinationLocation + questionnaireToReplace.conditionalSubQuestionIds.length;
    }

    let wasDraggedToConditionalSubQuestion;
    // When replacing a child question, move location beyond all child questions
    while (questionnaireToReplace && questionnaireToReplace.isConditionalSubQuestion) {
        questionnaireToReplace = fields.get(++destinationLocation);
        wasDraggedToConditionalSubQuestion = true;
    }

    // If we did the routine we need to stop go back to the last item
    if (wasDraggedToConditionalSubQuestion) {
        destinationLocation--;
    }

    return destinationLocation;
};

// Item is being dragged from bottom to top
// Any children of item should be added after the item
const getDragUpDestinationLocation = (fields, newLocation, childrenAccountedFor) => {
    let destinationLocation = newLocation;
    let questionnaireToReplace = fields.get(newLocation);

    if (childrenAccountedFor) {
        // When replacing a child question, move location beyond all child questions
        while (questionnaireToReplace && questionnaireToReplace.isConditionalSubQuestion) {
            questionnaireToReplace = fields.get(++destinationLocation);
        }
    } else if (!childrenAccountedFor && questionnaireToReplace.isConditionalSubQuestion) {
        // when we are moving with the arrows, children are not accounted for
        // we are moving an element up and it might have a parent with children above
        // so instead of moving just 1 position up we need to move 1 position + whatever children it has
        destinationLocation -= questionnaireToReplace.conditionalSubQuestionNumber;
    }

    return destinationLocation;
};

export const processDragEvent = (
    fields,
    dragType,
    originLocation,
    newLocation,
    childrenAccountedFor = true
) => {
    if (isSubChildDragEvent(dragType)) {
        // Do a basic move for children drag events
        return fields.move(originLocation, newLocation);
    }

    // Logic for computing destination location and re-locating children changes based on
    // moving up or down
    const isDraggingDown = newLocation > originLocation;

    // Compute new location (different logic for whether item moves up or down)
    const destinationLocation = isDraggingDown
        ? getDragDownDestinationLocation(fields, newLocation)
        : getDragUpDestinationLocation(fields, newLocation, childrenAccountedFor);

    // Move item to the new computed location
    fields.move(originLocation, destinationLocation);

    const questionnaireToMove = fields.get(originLocation);

    // When item to move has children, move each child as well
    (questionnaireToMove.conditionalSubQuestionIds || []).forEach((_subQuestionId, index) => {
        if (isDraggingDown) {
            // When dragging down we continually move children using the same origin and location as
            // the child will take the place of the parent on each move. Parent will have moved into
            // destination location, but vacated original location meaning the new destination
            // location is now the same
            return fields.move(originLocation, destinationLocation);
        }
        // When dragging up we need to increment to find the child's origin and the new location
        // to move it to. The parent will have moved into original destination location, so we need
        // to go to the next location for the child
        const newOriginLocation = originLocation + index + 1;
        const newDestinationLocation = destinationLocation + index + 1;
        fields.move(newOriginLocation, newDestinationLocation);
    });
};

export const reorderAndNumber = (fields, change, dragType) => {
    let counter = 0;
    // All moves have been completed, so re-number based on current array order
    fields.forEach((name, index) => {
        if (isSubChildDragEvent(dragType)) {
            // Renumber sub-questions based on current index position
            const { isConditionalSubQuestion } = fields.get(index);
            if (isConditionalSubQuestion) {
                change(`${name}.conditionalSubQuestionNumber`, ++counter);
            } else {
                counter = 0;
            }
        }
        // Reorder the based on current index positions
        change(`${name}.${ORDER_BY_ID}`, index + 1);
    });
};
