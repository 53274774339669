import { listToDict } from '../../../../shared_config/helpers';

const priceItemFields = [
    'attachments',
    'description',
    'discount',
    'discountType',
    'orderById',
    'custom1',
    'custom2',
    'vendorAssignmentUuid',
    'type',
    'quantity',
    'unitPrice',
    'unitToMeasure',
    'taxable',
    'clientVendorAssignmentUuid',
    'vendorId',
    'vendorIdModal',
    'vendorName',
    'accountSplitPriceItems',
];

export const priceItemFieldNames = listToDict(priceItemFields);
