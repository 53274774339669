import { createSelector } from 'reselect';

import { getApprovalsListJS } from '../selectors';
import { getInvitedUsersJS } from '../../selectors';
import { approvalStatusesDict } from '../../../../../shared_config/approval';

const { APPROVED } = approvalStatusesDict;

export const getReviewStepApproverMap = createSelector(
    [getInvitedUsersJS, getApprovalsListJS],
    (users, approvals) => {
        const reviewStepApproverMap = {};

        approvals.forEach((reviewStep) => {
            const approvalSet = new Set(
                reviewStep.projectApprovals.map((approver) => approver.user_id)
            );

            reviewStepApproverMap[reviewStep.id] = users.map((user) => {
                return {
                    ...user,
                    isApprover: approvalSet.has(user.id),
                };
            });
        });

        return reviewStepApproverMap;
    }
);

export const getIsApprovalComplete = createSelector([getApprovalsListJS], (approvals) => {
    return approvals.every((reviewStep) =>
        reviewStep.projectApprovals.every((approval) => approval.status === APPROVED)
    );
});
