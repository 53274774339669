import React from 'react';
import { Panel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Outlet, useParams, useLocation } from 'react-router-dom';
import { NoSsr } from '@mui/material';

import { Nav, NavItem, SectionTitle } from '../../../components';
import { isEmbedded } from '../../../helpers';
import { FLAGS, useFlags } from '../../../lib/launchdarkly';
import { getGovernmentJS } from '../selectors';

export const GovPortalMain = () => {
    const params = useParams();
    const location = useLocation();

    const { displayPublicVendorInformation } = useSelector(getGovernmentJS);

    const { governmentCode } = params;

    const isEmbed = isEmbedded(location);

    const portalPath = `/portal/${isEmbed ? 'embed/' : ''}${governmentCode}`;

    const enablePublicPortalVendorSearch = useFlags(FLAGS.PUBLIC_VENDOR_SEARCH_PORTAL);

    return (
        <Panel>
            <Panel.Body>
                {!isEmbed && <SectionTitle title="Procurement Portal" />}
                <Nav bsStyle="tabs" className="no-print">
                    <NavItem
                        data-tag="publicPortal-projects"
                        key="projects-list"
                        to={`${portalPath}${isEmbed ? '/project-list' : ''}`}
                    >
                        Projects
                    </NavItem>
                    <NavItem
                        data-tag="publicPortal-calendar"
                        key="calendar"
                        to={`${portalPath}/calendar`}
                    >
                        Calendar
                    </NavItem>
                    <NoSsr>
                        {enablePublicPortalVendorSearch && displayPublicVendorInformation && (
                            <NavItem
                                data-tag="publicPortal-vendors"
                                key="vendors"
                                to={`${portalPath}/vendors`}
                            >
                                Vendors
                            </NavItem>
                        )}
                    </NoSsr>
                </Nav>
                <Outlet />
            </Panel.Body>
        </Panel>
    );
};
