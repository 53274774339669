import { createSelector } from 'reselect';

import { getReqRelations } from '../../../../../../selectors/govApp/reqRelations';
import { contractStatusesDict } from '../../../../../../../../shared_config/contracts';

export const getSuggestedVendorContracts = createSelector([getReqRelations], (reqRelations) => {
    return reqRelations
        .flatMap(({ contracts }) => contracts)
        .filter(
            (contract) =>
                contract.status !== contractStatusesDict.ENDED && contract.contractParty?.vendor_id
        );
});
