import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ListGroup as ListGroupBS, ListGroupItem } from 'react-bootstrap';

export class ListGroup extends PureComponent {
    static propTypes = {
        items: PropTypes.array,
        showIcon: PropTypes.bool,
        itemTitleKey: PropTypes.string,
        onClick: PropTypes.func.isRequired,
    };

    static defaultProps = {
        items: [],
        showIcon: true,
        itemTitleKey: 'listName',
    };

    renderIcon() {
        if (!this.props.showIcon) return null;

        const styles = require('./ListGroup.scss');
        return (
            <span className={styles.listIcon}>
                <i className={`fa fa-fw fa-lg fa-circle-thin ${styles.open}`} />
                <i className={`fa fa-fw fa-lg fa-check-circle ${styles.checked}`} />
            </span>
        );
    }

    renderListItems() {
        const styles = require('./ListGroup.scss');
        return this.props.items.map((item, idx) => (
            <ListGroupItem
                className={styles.listItem}
                key={item.key || idx}
                onClick={() => this.props.onClick(item)}
            >
                {this.renderIcon()}
                <span className={styles.listText}>{item[this.props.itemTitleKey] || item}</span>
            </ListGroupItem>
        ));
    }

    render() {
        return <ListGroupBS>{this.renderListItems()}</ListGroupBS>;
    }
}
