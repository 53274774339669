import { fromJS } from 'immutable';

import * as requisitionsActions from '../actions/requisitions';
import * as requisitionsCreateActions from '../actions/requisitionsCreate';
import { sectionsMap } from '../constants/requisitionsCreate';

const initialState = fromJS({
    initialVendorSearch: {},
    sections: sectionsMap,
});

export default function requisitionsCreateReducer(state = initialState, action = {}) {
    switch (action.type) {
        case requisitionsCreateActions.SET_SECTION_DISABLED:
            return state.setIn(['sections', action.payload.section, 'disabled'], true);
        case requisitionsCreateActions.SET_SECTION_ENABLED:
            return state.setIn(['sections', action.payload.section, 'disabled'], false);
        case requisitionsCreateActions.SET_SECTION_COMPLETED:
            return state.setIn(['sections', action.payload.section, 'completed'], true);
        case requisitionsCreateActions.SET_SECTION_INCOMPLETE:
            return state.setIn(['sections', action.payload.section, 'completed'], false);
        case requisitionsCreateActions.SET_SECTION_EDITABLE:
            return state.setIn(['sections', action.payload.section, 'isEditable'], true);
        case requisitionsCreateActions.SET_SECTION_UNEDITABLE:
            return state.setIn(['sections', action.payload.section, 'isEditable'], false);
        case requisitionsCreateActions.SET_INITIAL_VENDOR_SELECTION_INPUT_DATA:
            return state.set('initialVendorSearch', fromJS(action.payload));
        case requisitionsActions.RESET_REQUISITION:
            return initialState;
        default:
            return state;
    }
}
