import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';
import { createSearchParams } from 'react-router-dom';

import { getPostingPath, getProjectJS } from '../../selectors';
import { createAddendum } from '../../../../actions/addendums';
import { hideAutomatedAddendumModal } from '../../../../actions/project/create/projectCreate';
import { loadAndCompareRevisions, loadRevisions } from '../../../../actions/revisions';
import { Button, LoadingButton } from '../../../../components';
import { addendumTypesDict } from '../../../../../../shared_config/addendums';

const { ADDENDUM } = addendumTypesDict;

const mapStateToProps = (state, props) => {
    return {
        postingPath: getPostingPath(state, props),
        project: getProjectJS(state),
    };
};

const mapDispatchToProps = {
    createAddendum,
    hideModal: hideAutomatedAddendumModal,
    loadAndCompareRevisions,
    loadRevisions,
};

// @connect
class ConnectedAutomatedAddendumModal extends Component {
    static propTypes = {
        createAddendum: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        loadAndCompareRevisions: PropTypes.func.isRequired,
        loadRevisions: PropTypes.func.isRequired,
        project: PropTypes.object.isRequired,
        postingPath: PropTypes.string.isRequired,
        router: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            createError: null,
            creating: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    createAddendum = () => {
        const {
            hideModal,
            postingPath,
            project,
            project: {
                id,
                government: { code },
            },
            router,
        } = this.props;

        const diffUrl = `${process.env.SITE_URL}/portal/${code}/projects/${id}/addenda?addendum-id={{= addendumId }}&addendum-display=diff`;
        const addendumData = {
            description:
                '<p>' +
                'Please use the ' +
                `<a href="${diffUrl}">See What Changed</a> ` +
                'link to view all the changes made by this addendum.' +
                '</p>',
            type: ADDENDUM,
        };

        const onSuccess = (addendum) => {
            hideModal();
            router.push({
                pathname: `${postingPath}/addenda`,
                search: createSearchParams({ 'addendum-id': addendum.id }).toString(),
            });
        };

        this.setState({ creating: true, createError: null });

        let lastDocumentVersion;
        return this.props
            .loadRevisions(id, { retrowError: true })
            .then(({ documentVersions }) => {
                lastDocumentVersion = documentVersions[0];

                if (!lastDocumentVersion) {
                    throw new Error('No previous versions. Could not create automated addendum.');
                }

                return this.props.loadAndCompareRevisions(
                    id,
                    lastDocumentVersion.project_audit_id,
                    project,
                    { isPublic: true, rethrowError: true }
                );
            })
            .then(({ diff }) => {
                addendumData.diff = {
                    document_version1_id: lastDocumentVersion.id,
                    html: diff,
                };
                return this.props.createAddendum(id, addendumData, {
                    onSuccess,
                    rethrowError: true,
                });
            })
            .catch((error) => this.setState({ creating: false, createError: error.message }));
    };

    render() {
        const { hideModal } = this.props;

        const { createError, creating } = this.state;

        return (
            <Modal onHide={hideModal} show>
                <Modal.Body>
                    <div className="text-center text-primary">
                        <span className="fa-stack fa-3x">
                            <i className="fa fa-stack-2x fa-circle-thin" />
                            <i className="fa fa-stack-1x fa-magic" />
                        </span>
                    </div>
                    <h4 className={this.styles.title}>Create Automated Addendum</h4>
                    <p>
                        We can create an automated addendum based off the updates you have made to
                        the document since the last version.
                    </p>
                    <div className="text-center">
                        <img
                            alt="Addendum diff example"
                            className={this.styles.image}
                            src="https://assets.procurement.opengov.com/assets/addendum-diff-sample.png"
                        />
                    </div>
                    <p>
                        Creating an automated addendum will only draft the addendum. You will be
                        able to review it and decide if you want to make any changes before
                        releasing.
                    </p>
                    <p>
                        If you have more updates to make you&#39;ll always have the option of
                        generating an addendum based off the diff of the updates when you are ready.
                    </p>
                    <div className={this.styles.buttons}>
                        <Button onClick={hideModal}>No Thanks</Button>
                        &nbsp;&nbsp;
                        <LoadingButton
                            bsStyle="primary"
                            disabled={creating}
                            icon="fa-magic"
                            loading={creating}
                            onClick={this.createAddendum}
                            text="Create Automated Addendum"
                        />
                    </div>
                    {createError && <div className="error-block">{createError}</div>}
                </Modal.Body>
            </Modal>
        );
    }
}

export const AutomatedAddendumModal = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedAutomatedAddendumModal);
