import PropTypes from 'prop-types';
import React from 'react';
import { DropdownButton } from 'react-bootstrap';

import { MenuItem } from '../../../../../../../components';
import { buildMap } from '../../../../../../../../../shared_config/helpers';
import {
    allowedIntakeSections,
    defaultSectionConfigs,
    sectionTypeNames,
} from '../../../../../../../../../shared_config/sections';

const { DIVIDER } = sectionTypeNames;
const sectionTypeOptions = defaultSectionConfigs.filter((config) => !config.isHidden);

export const ProjectSectionAddButton = ({
    addSection,
    disabled,
    isDocBuilder,
    isIntake,
    projectSectionFormValues,
    useSectionDividers,
}) => {
    const sectionsMap = buildMap(projectSectionFormValues, 'section_type');

    const generalSectionOptions = [];
    const specialSectionOptions = [];

    sectionTypeOptions.forEach((sectionTypeOption) => {
        const {
            icon,
            isDocBuilderAllowed,
            isGeneral,
            section_type: sectionType,
            title,
        } = sectionTypeOption;

        if (isIntake && !allowedIntakeSections.includes(sectionType)) {
            return;
        }

        if (sectionType === DIVIDER && !useSectionDividers) {
            return;
        }

        const menuItem = (
            <MenuItem
                key={sectionType}
                onClick={() => addSection(sectionTypeOption)}
                qaTag={`templateAdmin-${title}`}
            >
                <i className={`fa fa-fw fa-${icon}`} /> {title}
            </MenuItem>
        );

        if (isGeneral) {
            generalSectionOptions.push(menuItem);
            // Special use sections should only be allowed to be added once
        } else if (!sectionsMap[sectionType]) {
            // Special use sections should only be added to non-docBuilders unless specified
            if (!isDocBuilder || isDocBuilderAllowed) {
                specialSectionOptions.push(menuItem);
            }
        }
    });

    const hasNoOptions = generalSectionOptions.length === 0 && specialSectionOptions.length === 0;

    return (
        <DropdownButton
            bsStyle="primary"
            disabled={disabled}
            id="add-project-section-button"
            title={
                <span>
                    <i className="fa fa-plus" /> Add Section
                </span>
            }
        >
            {hasNoOptions && (
                <MenuItem disabled qaTag="templateAdmin-noSectionsAvailable">
                    No sections available
                </MenuItem>
            )}
            {generalSectionOptions.length > 0 && (
                <>
                    <MenuItem header qaTag="templateAdmin-generalUseSectionsToAdd">
                        General Use Sections to Add:
                    </MenuItem>
                    {generalSectionOptions}
                </>
            )}
            {specialSectionOptions.length > 0 && (
                <>
                    <MenuItem header qaTag="templateAdmin-specialUseSectionsToAdd">
                        Special Use Sections to Add:
                    </MenuItem>
                    {specialSectionOptions}
                </>
            )}
        </DropdownButton>
    );
};

ProjectSectionAddButton.propTypes = {
    addSection: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isDocBuilder: PropTypes.bool.isRequired,
    isIntake: PropTypes.bool.isRequired,
    projectSectionFormValues: PropTypes.array.isRequired,
    useSectionDividers: PropTypes.bool.isRequired,
};
