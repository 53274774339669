import {
    COMMENT,
    DESCRIPTION,
    DISCOUNT,
    DISCOUNT_ONLY,
    LINE_ITEM,
    NO_BID,
    QUANTITY,
    TAXABLE,
    TOTAL_COST,
    UNIT_TO_MEASURE,
    UNIT_PRICE,
    defaultColumnData,
    defaultColumnOrder,
    priceTableFieldNames,
} from '../../../../../../shared_config/priceTables';

const {
    COLUMN_ORDER,
    HAS_DISCOUNT,
    HAS_NO_BID,
    HAS_PERCENTAGE,
    HAS_QUANTITY,
    HAS_SALES_TAX_ROW,
    HAS_TOTAL_ROW,
    HEADER_COMMENT,
    HEADER_DESCRIPTION,
    HEADER_DISCOUNT,
    HEADER_DISCOUNT_ONLY,
    HEADER_LINE_ITEM,
    HEADER_NO_BID,
    HEADER_QUANTITY,
    HEADER_TAXABLE,
    HEADER_TOTAL,
    HEADER_UNIT_PRICE,
    HEADER_UNIT_TO_MEASURE,
    OMIT_LINE_ITEM,
    SPECIFY_QUANTITY,
    SPECIFY_UNIT_PRICE,
} = priceTableFieldNames;

export const LUMP_SUM = 'lump sum';

export const defaultPriceTableValues = {
    [COLUMN_ORDER]: defaultColumnOrder,
    [HAS_DISCOUNT]: false,
    [HAS_NO_BID]: false,
    [HAS_PERCENTAGE]: false,
    [HAS_QUANTITY]: true,
    [HAS_SALES_TAX_ROW]: false,
    [HAS_TOTAL_ROW]: true,
    [HEADER_COMMENT]: defaultColumnData[COMMENT].defaultHeaderName,
    [HEADER_DESCRIPTION]: defaultColumnData[DESCRIPTION].defaultHeaderName,
    [HEADER_DISCOUNT]: defaultColumnData[DISCOUNT].defaultHeaderName,
    [HEADER_DISCOUNT_ONLY]: defaultColumnData[DISCOUNT_ONLY].defaultHeaderName,
    [HEADER_LINE_ITEM]: defaultColumnData[LINE_ITEM].defaultHeaderName,
    [HEADER_NO_BID]: defaultColumnData[NO_BID].defaultHeaderName,
    [HEADER_UNIT_TO_MEASURE]: defaultColumnData[UNIT_TO_MEASURE].defaultHeaderName,
    [HEADER_UNIT_PRICE]: defaultColumnData[UNIT_PRICE].defaultHeaderName,
    [HEADER_QUANTITY]: defaultColumnData[QUANTITY].defaultHeaderName,
    [HEADER_TAXABLE]: defaultColumnData[TAXABLE].defaultHeaderName,
    [HEADER_TOTAL]: defaultColumnData[TOTAL_COST].defaultHeaderName,
    [OMIT_LINE_ITEM]: false,
    [SPECIFY_QUANTITY]: true,
    [SPECIFY_UNIT_PRICE]: false,
};
