import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { reduxForm, FieldArray } from 'redux-form';

import { fieldNames } from './constants';
import { formConfig } from '../constants';
import { ProposalDocumentsUploadList } from './ProposalDocumentsUploadList';
import { ProposalSectionButtons } from '../ProposalSectionButtons';
import { recordDocumentTypesDict } from '../../../../../../shared_config/recordsRetention';

const { DOCUMENTS } = recordDocumentTypesDict;

const { PROPOSAL_DOCUMENTS } = fieldNames;

// @reduxForm
class ConnectedProposalDocumentsUpload extends PureComponent {
    static propTypes = {
        deleteAttachment: PropTypes.func.isRequired,
        dirty: PropTypes.bool,
        disabled: PropTypes.bool,
        // eslint-disable-next-line react/no-unused-prop-types
        form: PropTypes.string.isRequired, // Needed for @reduxForm above
        onSave: PropTypes.func.isRequired,
        proposalDocuments: PropTypes.array.isRequired,
        sections: PropTypes.array.isRequired,
        showValidation: PropTypes.bool,
        updateError: PropTypes.string,
        uploadHandler: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            deleteAttachment,
            dirty,
            disabled,
            onSave,
            proposalDocuments,
            sections,
            showValidation,
            updateError,
            uploadHandler,
        } = this.props;

        const { header } = this.styles;

        return (
            <div>
                <h4 className={header}>Proposal Documents to Upload</h4>
                <FieldArray
                    component={ProposalDocumentsUploadList}
                    deleteAttachment={deleteAttachment}
                    disabled={disabled}
                    name={PROPOSAL_DOCUMENTS}
                    proposalDocuments={proposalDocuments}
                    showValidation={showValidation}
                    uploadHandler={uploadHandler}
                />
                <ProposalSectionButtons
                    disabled={disabled}
                    isFormDirty={dirty}
                    onSave={onSave}
                    section={DOCUMENTS}
                    sections={sections}
                    updateError={updateError}
                />
            </div>
        );
    }
}

export const ProposalDocumentsUpload = reduxForm(formConfig)(ConnectedProposalDocumentsUpload);
