import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, formValueSelector } from 'redux-form';
import { Box, Typography } from '@og-pro/ui';

import { fieldNames } from './constants';
import { getTimelines } from '../../../containers/GovApp/selectors';
import { timelineDatesDict } from '../../../../../shared_config/timelines';
import { Toggle } from '../..';

const {
    BACKGROUND,
    HIDE_CONTACT,
    HIDE_CONTACT_ADDRESS,
    HIDE_PROCUREMENT_CONTACT,
    HIDE_TIMELINE,
    SUMMARY,
} = fieldNames;

export const LegacyIntroductionForm = (props) => {
    const backgroundContent = useSelector((state) =>
        formValueSelector(props.form)(state, BACKGROUND)
    );
    const summaryContent = useSelector((state) => formValueSelector(props.form)(state, SUMMARY));
    const timelineData = useSelector((state) => getTimelines(state, props));
    const styles = require('./index.scss');

    const { disabled, isReverseAuction, hideTimeline, links, showContactAddress } = props;

    return (
        <Box className={styles.container}>
            <Box className={styles.warning} mb={3}>
                <Box display="flex">
                    <Box mr={2}>
                        <i className="fa fa-info-circle" />
                    </Box>
                    <Box flex={1}>
                        <Typography variant="h4">This section type has been replaced.</Typography>
                        <div>
                            These variables can be edited on the Summary & Background and Timeline
                            pages. You may continue to use this section as is or remove this section
                            and use these variables within your document as needed.
                        </div>
                    </Box>
                </Box>
            </Box>
            <Box className={styles.content}>
                {!!props.renderSummaryComments && (
                    <Box className={styles.commentContainer}>{props.renderSummaryComments()}</Box>
                )}
                <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
                    <Box>
                        <Typography variant="h3">Summary</Typography>
                    </Box>
                    <Box>
                        <Typography
                            className={styles.link}
                            component={Link}
                            to={links.summaryAndBackground}
                            variant="h4"
                        >
                            Edit in Summary & Background
                        </Typography>
                    </Box>
                </Box>
                <Box className={styles.info}>
                    The summary should be 1-3 paragraphs. Its purpose is to give the vendor a brief
                    idea of what the project aims to accomplish and to spark interest in potential
                    quality vendors.
                </Box>
                <Box mt={2}>
                    {!summaryContent && <span>No Summary has been written for this project.</span>}
                    {!!summaryContent && (
                        // eslint-disable-next-line react/no-danger
                        <span dangerouslySetInnerHTML={{ __html: summaryContent }} />
                    )}
                </Box>
            </Box>
            <Box className={styles.content}>
                {!!props.renderBackgroundComments && (
                    <Box className={styles.commentContainer}>
                        {props.renderBackgroundComments()}
                    </Box>
                )}
                <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
                    <Box>
                        <Typography variant="h3">Background</Typography>
                    </Box>
                    <Box>
                        <Typography
                            className={styles.link}
                            component={Link}
                            to={links.summaryAndBackground}
                            variant="h4"
                        >
                            Edit in Summary & Background
                        </Typography>
                    </Box>
                </Box>
                <Box className={styles.info}>
                    Background information should be relevant to your project and help the vendor
                    better understand why this project is important, what the challenges are, or
                    what would hit the ball out of the park.
                </Box>
                <Box mt={2}>
                    {!backgroundContent && (
                        <span>No Background has been written for this project.</span>
                    )}
                    {!!backgroundContent && (
                        // eslint-disable-next-line react/no-danger
                        <span dangerouslySetInnerHTML={{ __html: backgroundContent }} />
                    )}
                </Box>
            </Box>
            <Box className={styles.content}>
                {!!props.renderContactComments && (
                    <Box className={styles.commentContainer}>{props.renderContactComments()}</Box>
                )}
                <Typography variant="h3">Contact Information</Typography>
                <Box mt={3}>
                    <Box mb={3}>
                        <Field
                            component={Toggle}
                            disabled={disabled}
                            inverseToggleDisplay
                            label="Display Project Contact"
                            leftLabel="OFF"
                            name={HIDE_CONTACT}
                            qaTag={`legacyIntroduction-${HIDE_CONTACT}`}
                            rightLabel="ON"
                            useOpenGovStyle
                        />
                    </Box>
                    <Box mb={3}>
                        <Field
                            component={Toggle}
                            disabled={disabled}
                            inverseToggleDisplay
                            label="Display Procurement Contact"
                            leftLabel="OFF"
                            name={HIDE_PROCUREMENT_CONTACT}
                            qaTag={`legacyIntroduction-${HIDE_PROCUREMENT_CONTACT}`}
                            rightLabel="ON"
                            useOpenGovStyle
                        />
                    </Box>
                    {showContactAddress && (
                        <Field
                            component={Toggle}
                            disabled={disabled}
                            inverseToggleDisplay
                            label="Display Contact Address"
                            leftLabel="OFF"
                            name={HIDE_CONTACT_ADDRESS}
                            qaTag={`legacyIntroduction-${HIDE_CONTACT_ADDRESS}`}
                            rightLabel="ON"
                            useOpenGovStyle
                        />
                    )}
                </Box>
            </Box>
            {!hideTimeline && (
                <Box className={styles.content}>
                    {!!props.renderTimelineComments && (
                        <Box className={styles.commentContainer}>
                            {props.renderTimelineComments()}
                        </Box>
                    )}
                    <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
                        <Box>
                            <Typography variant="h3">Timeline</Typography>
                        </Box>
                        <Box>
                            <Typography
                                className={styles.link}
                                component={Link}
                                to={links.timeline}
                                variant="h4"
                            >
                                Edit in Timeline
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={styles.info}>
                        Outline the dates that make up the project’s timeline.
                    </Box>
                    <Box mt={2}>
                        <Box className={styles.bottomBordered} pb={1}>
                            <Typography variant="h5">Event</Typography>
                        </Box>
                        <Box className={styles.timeline} mb={2}>
                            {timelineData
                                .filter((timeline) => {
                                    const auctionCheck =
                                        timeline.projectField !==
                                            timelineDatesDict.AUCTION_END_DATE ||
                                        (timeline.projectField ===
                                            timelineDatesDict.AUCTION_END_DATE &&
                                            isReverseAuction);
                                    const removedCheck =
                                        timeline.isIncluded ||
                                        timeline.required ||
                                        timeline.isCustomDate;

                                    return auctionCheck && removedCheck;
                                })
                                .map((timeline, i) => {
                                    return (
                                        <Box
                                            className={styles.bottomBordered}
                                            key={timeline.uuid || timeline.id || i}
                                            pb={2}
                                            pt={2}
                                        >
                                            {timeline.title}
                                        </Box>
                                    );
                                })}
                        </Box>
                        <Field
                            component={Toggle}
                            disabled={disabled}
                            inverseToggleDisplay
                            label="Display Timeline in this Section"
                            leftLabel="OFF"
                            name={HIDE_TIMELINE}
                            qaTag={`legacyIntroduction-${HIDE_TIMELINE}`}
                            rightLabel="ON"
                            useOpenGovStyle
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

LegacyIntroductionForm.propTypes = {
    disabled: PropTypes.bool,
    form: PropTypes.string.isRequired,
    hideTimeline: PropTypes.bool,
    isReverseAuction: PropTypes.bool,
    links: PropTypes.shape({
        summaryAndBackground: PropTypes.string.isRequired,
        timeline: PropTypes.string.isRequired,
    }).isRequired,
    renderBackgroundComments: PropTypes.func,
    renderContactComments: PropTypes.func,
    renderSummaryComments: PropTypes.func,
    renderTimelineComments: PropTypes.func,
    showContactAddress: PropTypes.bool,
};
