import PropTypes from 'prop-types';
import React from 'react';
import { tokens } from '@opengov/capital-style';

import { DocxHeading } from '../DocxHeading';
import { fieldTypes } from '../../../../shared_config/signatures';
import { signaturesBlocksToColumns } from '../../helpers/signatures';

const DataLine = ({ value }) => (
    <td
        style={{
            borderBottom: `1px solid black`,
        }}
    >
        <p style={{ marginBottom: 0, marginTop: tokens.layout.unit1 }}>{value}</p>
    </td>
);

DataLine.propTypes = {
    value: PropTypes.string.isRequired,
};

const SignatureField = ({ field }) => {
    switch (field.type) {
        case fieldTypes.DATE:
            return <DataLine value={new Date(field.value).toLocaleDateString()} />;
        default:
            return <DataLine value={field.value} />;
    }
};

SignatureField.propTypes = {
    field: PropTypes.shape({
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    }).isRequired,
};

const SignatureBlock = ({ block, rightSide }) => {
    if (block.styling === 'header') {
        return (
            <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: block.fields[0].value,
                }}
            />
        );
    }

    return (
        <table style={{ border: 'none', width: '50%' }}>
            {block.fields.length > 0 ? (
                <>
                    {block.fields.map((field) => {
                        return (
                            <tr key={field.label}>
                                <td style={{ width: '20%' }}>
                                    <p
                                        style={
                                            rightSide
                                                ? {
                                                      marginBottom: 0,
                                                      marginLeft: tokens.layout.unit1,
                                                      marginTop: tokens.layout.unit1,
                                                  }
                                                : {
                                                      marginBottom: 0,
                                                      marginTop: tokens.layout.unit1,
                                                  }
                                        }
                                    >
                                        {field.label}:
                                    </p>
                                </td>
                                <SignatureField field={field} />
                            </tr>
                        );
                    })}
                </>
            ) : null}
        </table>
    );
};

SignatureBlock.propTypes = {
    block: PropTypes.shape({
        fields: PropTypes.array.isRequired,
        styling: PropTypes.oneOf(['header', 'default']).isRequired,
    }).isRequired,
    rightSide: PropTypes.bool,
};

export const SignatureDocx = ({ projectSection, signatures, useManualNumbering }) => {
    const sectionSignatures = signatures.filter(
        (signature) => signature.project_section_id === projectSection.id
    );

    return (
        <>
            <DocxHeading
                domElement="h2"
                legacy={<h2>{projectSection.title}</h2>}
                numbering={
                    useManualNumbering
                        ? projectSection.manualNumber
                        : `${projectSection.sectionNumber}.`
                }
                title={projectSection.title}
            />
            {sectionSignatures.map((rawSignature) => {
                const signature = signaturesBlocksToColumns(rawSignature);

                const left = signature.signaturesBlocks[0];
                const right = signature.signaturesBlocks[1];

                const maxLength = Math.max(left?.length || 0, right?.length || 0);

                return (
                    <>
                        {/* eslint-disable-next-line react/no-danger */}
                        <div dangerouslySetInnerHTML={{ __html: signature.introduction }} />
                        {[...Array(maxLength).keys()].map((index) => {
                            const leftBlock = left ? left[index] : null;
                            const rightBlock = right ? right[index] : null;

                            return (
                                <>
                                    <table key={index} style={{ border: 'none', width: '100%' }}>
                                        <tr>
                                            <td>
                                                {leftBlock ? (
                                                    <SignatureBlock block={leftBlock} />
                                                ) : null}
                                            </td>
                                            <td>
                                                {rightBlock ? (
                                                    <SignatureBlock block={rightBlock} rightSide />
                                                ) : null}
                                            </td>
                                        </tr>
                                    </table>
                                    {(leftBlock?.pageBreakAfter || rightBlock?.pageBreakAfter) && (
                                        <p style={{ breakAfter: 'page' }}>&nbsp;</p>
                                    )}
                                </>
                            );
                        })}
                        {/* eslint-disable-next-line react/no-danger */}
                        <div dangerouslySetInnerHTML={{ __html: signature.footer }} />
                    </>
                );
            })}
        </>
    );
};

SignatureDocx.propTypes = {
    projectSection: PropTypes.shape({
        id: PropTypes.number.isRequired,
        manualNumber: PropTypes.string,
        sectionNumber: PropTypes.number,
        title: PropTypes.string.isRequired,
    }).isRequired,
    signatures: PropTypes.array.isRequired,
    useManualNumbering: PropTypes.bool,
};
