import { getCountryNameFromCode } from '../../../../shared_config/data';

export function countryCode(values, keyName = 'countryCode', opts = {}) {
    const errors = {};
    const countryCodeValue = values[keyName];

    if (countryCodeValue) {
        if (countryCodeValue.length > 2) {
            errors[keyName] = 'This field must be a two letter country code (ex: US)';
        } else if (!getCountryNameFromCode(countryCodeValue)) {
            errors[keyName] = 'Must be a valid country';
        }
    } else if (!opts.optional) {
        errors[keyName] = 'Country Code is required.';
    }

    return errors;
}
