import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TemplateSectionAddForm } from './TemplateSectionAddForm';
import { TEMPLATE_SECTION_ID } from './TemplateSectionAddForm/constants';
import { getAvailableTemplateSections } from '../../../../selectors';
import { showConfirmationSimpleModal } from '../../../../../../../actions/confirmation';
import { OutlineButton } from '../../../../../../../components';
import { buildMap } from '../../../../../../../../../shared_config/helpers';
import {
    allowedIntakeSections,
    defaultSectionConfigsMap,
    sectionTypeNames,
} from '../../../../../../../../../shared_config/sections';

const { EVALUATION_CRITERIA, EVALUATION_PHASE } = sectionTypeNames;

const isEvaluationSection = (projectSection) => {
    return (
        projectSection.section_type === EVALUATION_CRITERIA ||
        projectSection.section_type === EVALUATION_PHASE
    );
};

export const TemplateSectionAddButton = ({
    addSection,
    disabled,
    isDirty,
    isDocBuilder,
    isIntake,
    projectSectionFormValues,
    updateTemplate,
}) => {
    const templateSections = useSelector(getAvailableTemplateSections);
    const dispatch = useDispatch();
    const sectionsMap = buildMap(projectSectionFormValues, 'section_type');
    const [showButton, setShowButton] = useState(true);
    const templateProjectSectionsInUse = projectSectionFormValues.filter((sec) => sec.isTemplate);
    const templateSectionSharedIdsInUse = buildMap(templateProjectSectionsInUse, 'sharedId');
    const hasEvaluation = projectSectionFormValues.some(isEvaluationSection);

    const templateSectionOptions = templateSections
        .filter((templateSection) => {
            const {
                projectSection,
                projectSection: { section_type: sectionType, sharedId },
            } = templateSection;

            const sectionConfig = defaultSectionConfigsMap[sectionType];

            if (isIntake && !allowedIntakeSections.includes(sectionType)) {
                return false;
            }

            // Template section can only be used once per template
            if (templateSectionSharedIdsInUse[sharedId]) {
                return false;
            }

            // Only one type of evaluation section can be used
            if (isEvaluationSection(projectSection) && hasEvaluation) {
                return false;
            }

            // General use sections are always allowed
            if (sectionConfig.isGeneral) {
                return true;
            }

            // Handle case where special section is allowed and may have already been added
            const specialSectionNotUsed = !sectionsMap[sectionType];
            const isSpecialSectionAllowed = !isDocBuilder || sectionConfig.isDocBuilderAllowed;
            return isSpecialSectionAllowed && specialSectionNotUsed;
        })
        .map((templateSection) => {
            const {
                id,
                projectSection: { section_type: sectionType, shortName, title },
            } = templateSection;
            const sectionConfig = defaultSectionConfigsMap[sectionType];

            return {
                label: `${title} (${shortName})`,
                icon: sectionConfig.icon,
                value: id,
            };
        });

    const addSectionHandler = (formData) => {
        setShowButton(true);

        const addTemplateSection = () => {
            addSection(formData[TEMPLATE_SECTION_ID]);
        };

        const onConfirm = () => {
            const attemptUpdate = updateTemplate();

            // If validation fails, `updateTemplate` does not return a Promise and we should not
            // proceed with adding the template section
            if (typeof attemptUpdate.then === 'function') {
                attemptUpdate.then(addTemplateSection);
            }
        };

        // If form is not isDirty we can proceed with adding the section
        if (!isDirty) {
            return addTemplateSection();
        }

        dispatch(
            showConfirmationSimpleModal(onConfirm, {
                bsStyle: 'primary',
                btnText: 'Save Changes',
                cancelText: 'Discard Changes',
                icon: 'check',
                onCancel: addTemplateSection,
                text: 'You have unsaved changes.\n\nWould you like to save your changes before adding this section?\nIf you choose not to save, all unsaved changes will be lost.',
                title: 'Save Changes?',
            })
        );
    };

    if (showButton) {
        return (
            <OutlineButton
                bsStyle="primary"
                disabled={disabled}
                onClick={() => setShowButton(false)}
                qaTag="templateAdmin-sharedSectionsToAdd"
            >
                <i className="fa fa-plus" /> Add Shared Section&nbsp;
                <i className="fa fa-caret-down" />
            </OutlineButton>
        );
    }

    return <TemplateSectionAddForm onChange={addSectionHandler} options={templateSectionOptions} />;
};

TemplateSectionAddButton.propTypes = {
    addSection: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isDirty: PropTypes.bool,
    isDocBuilder: PropTypes.bool.isRequired,
    isIntake: PropTypes.bool.isRequired,
    projectSectionFormValues: PropTypes.array.isRequired,
    updateTemplate: PropTypes.func.isRequired,
};
