import React from 'react';
import { Panel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Outlet, useParams, useLocation } from 'react-router-dom';

import { NavItems } from './NavItems';
import { getRequisitionsAdminNavItems } from './selectors';
import { getRequisitionsAdminPath } from '../selectors';

export const RequisitionsAdminNav = () => {
    const params = useParams();
    const location = useLocation();
    const navItems = useSelector((state) => getRequisitionsAdminNavItems(state, { location }));
    const requisitionsAdminPath = useSelector((state) =>
        getRequisitionsAdminPath(state, { params })
    );

    const styles = require('./index.scss');

    return (
        <Panel>
            <Panel.Body>
                <h1 className={styles.header}>Requisitions Admin</h1>
                <NavItems items={navItems} requisitionsAdminPath={requisitionsAdminPath} />
                <Outlet />
            </Panel.Body>
        </Panel>
    );
};
