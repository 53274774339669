import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Prompt, Public, QuestionAttachments, Required, Title } from '.';
import { QuestionnaireResponsePassFail } from './QuestionnaireResponsePassFail';
import { MAX_LENGTH } from '../../../../../../shared_config/questionnaires';

export class QuestionPrompt extends PureComponent {
    static propTypes = {
        attachments: PropTypes.array,
        canGovUserEdit: PropTypes.bool,
        children: PropTypes.node,
        conditionalSubQuestionNumber: PropTypes.number,
        data: PropTypes.shape({
            attachments: PropTypes.array,
        }),
        hideRequired: PropTypes.bool,
        htmlFor: PropTypes.string,
        isDocx: PropTypes.bool,
        isOGThemeEnabledForComponents: PropTypes.bool,
        isPublic: PropTypes.bool,
        isRequired: PropTypes.bool,
        isRevisionsDiff: PropTypes.bool,
        isTitle: PropTypes.bool,
        projectId: PropTypes.number,
        prompt: PropTypes.string,
        promptClassName: PropTypes.string,
        questionnaireResponse: PropTypes.shape({
            id: PropTypes.number,
            isFailed: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(null)]),
        }),
        rawPrompt: PropTypes.string,
        sectionNumber: PropTypes.number,
        showPublicDisclaimer: PropTypes.bool,
        showValidation: PropTypes.bool,
        subPrompt: PropTypes.string,
        subsectionNumber: PropTypes.number,
        title: PropTypes.string,
        titleClassName: PropTypes.string,
        updateQuestionnaireResponse: PropTypes.func,
        useManualNumbering: PropTypes.bool,
        useRawPrompt: PropTypes.bool,
        useSectionDividers: PropTypes.bool,
        valid: PropTypes.bool,
        warning: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    };

    static defaultProps = {
        children: undefined,
        hideRequired: false,
        htmlFor: undefined,
        isDocx: false,
        isOGThemeEnabledForComponents: false,
        isPublic: false,
        isRequired: false,
        isRevisionsDiff: false,
        isTitle: false,
        prompt: undefined,
        promptClassName: undefined,
        rawPrompt: undefined,
        sectionNumber: undefined,
        showPublicDisclaimer: false,
        showValidation: false,
        subsectionNumber: undefined,
        title: undefined,
        titleClassName: undefined,
        useRawPrompt: false,
        valid: false,
        warning: undefined,
    };

    render() {
        const {
            attachments,
            canGovUserEdit,
            children,
            conditionalSubQuestionNumber,
            data,
            htmlFor,
            isDocx,
            isOGThemeEnabledForComponents,
            isPublic,
            isRequired,
            isRevisionsDiff,
            isTitle,
            hideRequired,
            prompt,
            promptClassName,
            projectId,
            questionnaireResponse,
            rawPrompt,
            sectionNumber,
            showPublicDisclaimer,
            showValidation,
            subPrompt,
            subsectionNumber,
            title,
            titleClassName,
            updateQuestionnaireResponse,
            useManualNumbering,
            useRawPrompt,
            useSectionDividers,
            valid,
            warning,
        } = this.props;

        const allowMultiSelect = !!get(data, ['allowMultiSelect']);
        const questionnaireAttachments = attachments || get(data, 'attachments') || [];
        const maxLength = get(data, MAX_LENGTH);

        const style = conditionalSubQuestionNumber && isDocx ? { marginBottom: 15 } : {};

        return (
            <div style={style}>
                <Title
                    className={titleClassName}
                    conditionalSubQuestionNumber={conditionalSubQuestionNumber}
                    hasPrompt={!!(useRawPrompt ? rawPrompt : prompt) || allowMultiSelect}
                    htmlFor={htmlFor}
                    isDocx={isDocx}
                    isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                    isRequired={isRequired}
                    isTitle={isTitle}
                    sectionNumber={sectionNumber}
                    showValidation={showValidation}
                    subsectionNumber={subsectionNumber}
                    title={title}
                    useManualNumbering={useManualNumbering}
                    useSectionDividers={useSectionDividers}
                    valid={valid}
                    warning={warning}
                />
                {questionnaireResponse && updateQuestionnaireResponse && (
                    <QuestionnaireResponsePassFail
                        disabled={!canGovUserEdit}
                        isDocx={isDocx}
                        isFailed={questionnaireResponse.isFailed}
                        projectId={projectId}
                        questionnaireResponseId={questionnaireResponse.id}
                        updateQuestionnaireResponse={updateQuestionnaireResponse}
                    />
                )}
                <Prompt
                    className={promptClassName}
                    isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                    prompt={useRawPrompt ? rawPrompt : prompt}
                />
                {subPrompt && !isOGThemeEnabledForComponents && (
                    <Prompt className={promptClassName} prompt={subPrompt} />
                )}

                {maxLength && !isOGThemeEnabledForComponents && (
                    <p className={promptClassName}>
                        <em>Maximum response length: {maxLength} characters</em>
                    </p>
                )}
                {questionnaireAttachments.length > 0 && (
                    <QuestionAttachments
                        attachments={questionnaireAttachments}
                        isDocx={isDocx}
                        isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                        isRevisionsDiff={isRevisionsDiff}
                    />
                )}
                {children}
                {!hideRequired && <Required isRequired={isRequired} />}
                {showPublicDisclaimer && <Public isPublic={isPublic} />}
            </div>
        );
    }
}
