import { LOWEST_PRICE, SCORED, LINE_ITEM_AWARD } from '../../../../../shared_config/evaluations';

export const form = 'evaluationTypeForm';

export const TYPE = 'type';

export const evaluationTypeOptions = [
    {
        label: 'Best Value',
        tooltip:
            'Select vendor(s) based on how a scoring committee rates them against a set ' +
            'of criteria.',
        value: SCORED,
    },
    {
        label: 'Lump Sum/Lowest Price',
        tooltip:
            'Select vendor(s) based on price exclusively, assuming they have met the ' +
            'minimum criteria to participate.',
        value: LOWEST_PRICE,
    },
    {
        label: 'Line Item Award',
        tooltip:
            'Select one or more vendors for each line item, based on price or other criteria of your choosing.',
        value: LINE_ITEM_AWARD,
    },
];
