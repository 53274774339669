import { useSelector } from 'react-redux';

import { getUserJS } from '../../../../containers/selectors';

export const useContextSwitcherOptions = () => {
    const user = useSelector(getUserJS);

    return {
        activeProductKey: 'procurement',
        auth0Config: {
            auth0clientID: process.env.AUTH0_CLIENT_ID,
            auth0domain: process.env.AUTH0_URL,
            auth0callbackUrl: `${process.env.SITE_URL}/login`,
        },
        baseUrl: process.env.OG_CONTROL_PANEL_URL ?? 'https://controlpanel.ogintegration.us',
        userUUID: user.platformUuid,
        activeEntityId: user.government.code,
        activeEntityIdType: 'BY_PROCUREMENT_ID',
    };
};
