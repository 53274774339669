import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { GenericFormBuilder } from '../../SharedFormBuilder';
import { InputText } from '../../../..';
import { DATA, VALUE } from '../../../../../../../shared_config/questionnaires';

export class ExternalFormBuilder extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        form: PropTypes.string.isRequired,
        includeContainsPricingOption: PropTypes.bool,
        isTitleEditingDisabled: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        useRawPrompt: PropTypes.bool,
    };

    static defaultProps = {
        tagOptions: undefined,
        templateVariableOptions: undefined,
        useRawPrompt: false,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            disabled,
            form,
            includeContainsPricingOption,
            isTitleEditingDisabled,
            tagOptions,
            templateVariableOptions,
            useRawPrompt,
        } = this.props;

        return (
            <GenericFormBuilder
                disabled={disabled}
                form={form}
                includeContainsPricingOption={includeContainsPricingOption}
                isTitleEditingDisabled={isTitleEditingDisabled}
                tagOptions={tagOptions}
                templateVariableOptions={templateVariableOptions}
                useRawPrompt={useRawPrompt}
            >
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    label="DocuSign PowerForm URL"
                    name={`${DATA}.${VALUE}`}
                    placeholder="https://docusign.net/Member/PowerFormSigning.aspx?PowerFormId=b45b1273-1f23-4896-8e79-2ecb0a6c7ffd&env=demo&acct=1066dddc-7a33-4d58-8843-90b25086ec74&v=2"
                    type="text"
                />
                <div className={this.styles.docuSignBrandText}>
                    Automated document completion via integration with <strong>DocuSign</strong>
                    <img
                        alt="DocuSign logo"
                        className={`img-rounded ${this.styles.logo}`}
                        src="https://assets.procurement.opengov.com/assets/docusign-logo.jpeg"
                    />
                </div>
                <div className={this.styles.docuSignInfoText}>
                    <small>
                        OpenGov has a DocuSign integration! You will need a DocuSign account and
                        PowerForm to use this question option. Please contact us for help in setting
                        up the OpenGov integration on your DocuSign account.
                    </small>
                </div>
            </GenericFormBuilder>
        );
    }
}
