import { v4 as UUIDv4 } from 'uuid';
import { reset } from 'redux-form';

import { showSnackbar, showNotification } from './notification';
import { trackEvent } from '../helpers';
import { basicUserRoles } from '../../../shared_config/userRoles';
import { userStatusTypes } from '../../../shared_config/users';
import { form as bulkUpdateContactsForm } from '../containers/Admin/UserList/constants';
import { hideConfirmationModal, updatingConfirmationModal } from './confirmation';

const { USER } = basicUserRoles;
const { DISABLED } = userStatusTypes;

export const LOAD_USERS = 'admin/LOAD_USERS';
export const LOAD_USERS_SUCCESS = 'admin/LOAD_USERS_SUCCESS';
export const LOAD_USERS_FAIL = 'admin/LOAD_USERS_FAIL';
export const TOGGLE_SORT_USERS_DIRECTION = 'admin/TOGGLE_SORT_USERS_DIRECTION';
export const SET_USERS_LIST_SEARCH = 'admin/SET_USERS_LIST_SEARCH';
export const ADD_DEPARTMENT_SUCCESS = 'admin/ADD_DEPARTMENT_SUCCESS';
export const UPDATE_DEPARTMENT_SUCCESS = 'admin/UPDATE_DEPARTMENT_SUCCESS';
export const DELETE_DEPARTMENT_SUCCESS = 'admin/DELETE_DEPARTMENT_SUCCESS';
export const INVITE = 'admin/INVITE';
export const INVITE_SUCCESS = 'admin/INVITE_SUCCESS';
export const INVITE_FAIL = 'admin/INVITE_FAIL';
export const RESEND_INVITE = 'admin/RESEND_INVITE';
export const RESEND_INVITE_SUCCESS = 'admin/RESEND_INVITE_SUCCESS';
export const RESEND_INVITE_FAIL = 'admin/RESEND_INVITE_FAIL';
export const UPDATE_USER = 'admin/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'admin/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'admin/UPDATE_USER_FAIL';
export const DISABLE_USER = 'admin/DISABLE_USER';
export const DISABLE_USER_SUCCESS = 'admin/DISABLE_USER_SUCCESS';
export const DISABLE_USER_FAIL = 'admin/DISABLE_USER_FAIL';
export const ENABLE_USER = 'admin/ENABLE_USER';
export const ENABLE_USER_SUCCESS = 'admin/ENABLE_USER_SUCCESS';
export const ENABLE_USER_FAIL = 'admin/ENABLE_USER_FAIL';
export const REJECT_USER_REQUEST = 'admin/REJECT_USER_REQUEST';
export const REJECT_USER_REQUEST_SUCCESS = 'admin/REJECT_USER_REQUEST_SUCCESS';
export const REJECT_USER_REQUEST_FAIL = 'admin/REJECT_USER_REQUEST_FAIL';
export const ADD_USER = 'admin/ADD_USER';
export const REMOVE_USER = 'admin/REMOVE_USER';
export const CHANGE_TAB = 'admin/CHANGE_TAB';

export const LOAD_SUGGESTED_CONTENT = 'admin/LOAD_SUGGESTED_CONTENT';
export const LOAD_SUGGESTED_CONTENT_SUCCESS = 'admin/LOAD_SUGGESTED_CONTENT_SUCCESS';
export const LOAD_SUGGESTED_CONTENT_FAIL = 'admin/LOAD_SUGGESTED_CONTENT_FAIL';

export const POST_SUGGESTED_CONTENT = 'admin/POST_SUGGESTED_CONTENT';
export const POST_SUGGESTED_CONTENT_SUCCESS = 'admin/POST_SUGGESTED_CONTENT_SUCCESS';
export const POST_SUGGESTED_CONTENT_FAIL = 'admin/POST_SUGGESTED_CONTENT_FAIL';

export const EDIT_SUGGESTED_CONTENT = 'admin/EDIT_SUGGESTED_CONTENT';
export const EDIT_SUGGESTED_CONTENT_SUCCESS = 'admin/EDIT_SUGGESTED_CONTENT_SUCCESS';
export const EDIT_SUGGESTED_CONTENT_FAIL = 'admin/EDIT_SUGGESTED_CONTENT_FAIL';

export const DELETE_SUGGESTED_CONTENT = 'admin/DELETE_SUGGESTED_CONTENT';
export const DELETE_SUGGESTED_CONTENT_SUCCESS = 'admin/DELETE_SUGGESTED_CONTENT_SUCCESS';
export const DELETE_SUGGESTED_CONTENT_FAIL = 'admin/DELETE_SUGGESTED_CONTENT_FAIL';

export const SORT_SUGGESTED_CONTENT = 'admin/SORT_SUGGESTED_CONTENT';

export const TOGGLE_ADD_CONTENT_FORM = 'admin/TOGGLE_ADD_CONTENT_FORM';
export const TOGGLE_DISABLED_USERS = 'admin/TOGGLE_DISABLED_USERS';
export const TOGGLE_EDIT_CONTENT_FORM = 'admin/TOGGLE_EDIT_CONTENT_FORM';

export function changeTab(tabName) {
    return {
        type: CHANGE_TAB,
        tabName,
    };
}

export function toggleDisabledUsers() {
    return { type: TOGGLE_DISABLED_USERS };
}

export function toggleSortUsersDirection() {
    return { type: TOGGLE_SORT_USERS_DIRECTION };
}

export function setUsersListSearch(value) {
    return { type: SET_USERS_LIST_SEARCH, value };
}

/* USER ACTIONS */

export function loadUsers(orgId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_USERS });

        return client
            .get(`/organization/${orgId}/user`)
            .then((result) => {
                dispatch({ type: LOAD_USERS_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_USERS_FAIL, error });
            });
    };
}

export function addUser(deptId, userData) {
    return {
        type: ADD_USER,
        user: {
            uuid: UUIDv4(),
            role: USER,
            department_id: deptId,
            ...userData,
        },
    };
}

export function removeUser(uuid) {
    return { type: REMOVE_USER, uuid };
}

export function inviteUser(uuidOrId, data, opts = {}) {
    return (dispatch, getState, client) => {
        const { existingUser, onSuccess, onFailure } = opts;
        const key = existingUser ? 'id' : 'uuid';

        dispatch({ type: INVITE, [key]: uuidOrId });

        client
            .post('/user/invite', { data })
            .then((result) => {
                trackEvent('Invited User');
                dispatch(showSnackbar(`Invite Sent! (${result.email})`));
                dispatch({ type: INVITE_SUCCESS, result, [key]: uuidOrId });
                if (onSuccess) {
                    onSuccess(result);
                }
            })
            .catch((error) => {
                dispatch({ type: INVITE_FAIL, error, [key]: uuidOrId });
                dispatch(showNotification(error.message, { type: 'danger' }));
                if (onFailure) {
                    onFailure();
                }
            });
    };
}

export function resendInvite(id) {
    return (dispatch, getState, client) => {
        dispatch({ type: RESEND_INVITE, id });

        client
            .post(`/user/${id}/invite`)
            .then(() => {
                dispatch({ type: RESEND_INVITE_SUCCESS, id });
                dispatch(showSnackbar('Invite Email Sent!'));
            })
            .catch((error) => {
                dispatch({ type: RESEND_INVITE_FAIL, error, id });
                dispatch(showNotification(error.message, { type: 'danger' }));
            });
    };
}

export function updateUser(id, data, onSuccess) {
    return (dispatch, getState, client) => {
        dispatch({ type: UPDATE_USER, id });

        client
            .put(`/user/${id}`, { data })
            .then((result) => {
                dispatch({ type: UPDATE_USER_SUCCESS, result, id });
                dispatch(showSnackbar(`${result.displayName} Updated`));
                if (onSuccess) {
                    onSuccess();
                }
            })
            .catch((error) => {
                dispatch({ type: UPDATE_USER_FAIL, error, id });
                dispatch(showNotification(error.message, { type: 'danger' }));
            });
    };
}

export function disableUser(id) {
    return (dispatch, getState, client) => {
        dispatch({ type: DISABLE_USER, id });

        const data = { status: DISABLED };

        client
            .put(`/user/${id}`, { data })
            .then((result) => {
                dispatch({ type: DISABLE_USER_SUCCESS, result, id });
                dispatch(showSnackbar(`${result.displayName} Disabled`));
            })
            .catch((error) => {
                dispatch({ type: DISABLE_USER_FAIL, error, id });
                dispatch(showNotification(error.message, { type: 'danger' }));
            });
    };
}

export function enableUser(id, status) {
    return (dispatch, getState, client) => {
        dispatch({ type: ENABLE_USER, id });

        const data = { status };

        client
            .put(`/user/${id}`, { data })
            .then((result) => {
                dispatch({ type: ENABLE_USER_SUCCESS, result, id });
                dispatch(showSnackbar(`${result.displayName} Enabled`));
            })
            .catch((error) => {
                dispatch({ type: ENABLE_USER_FAIL, error, id });
                dispatch(showNotification(error.message, { type: 'danger' }));
            });
    };
}

export function rejectUserRequest(id) {
    return (dispatch, getState, client) => {
        dispatch({ type: REJECT_USER_REQUEST, id });

        client
            .del(`/user/${id}/reject`)
            .then((result) => {
                dispatch({ type: REJECT_USER_REQUEST_SUCCESS, result, id });
                dispatch(showSnackbar(`${result.displayName} Rejected`));
            })
            .catch((error) => {
                dispatch({ type: REJECT_USER_REQUEST_FAIL, error, id });
                dispatch(showNotification(error.message, { type: 'danger' }));
            });
    };
}

export function shouldLoadUsers(state) {
    if (state.admin.get('loadingUsers')) return false;
    return !state.admin.get('loadedUsers') || !state.admin.get('users');
}

/* DEPARTMENT ACTIONS */

export function createDepartment(orgId, department) {
    return (dispatch, getState, client) => {
        return client.post('/departments', { data: department }).then((result) => {
            dispatch({ type: ADD_DEPARTMENT_SUCCESS, result });
            dispatch(showSnackbar('Department Added'));
        });
    };
}

export function updateDepartment(orgId, id, data) {
    return (dispatch, getState, client) => {
        return client.put(`/departments/${id}`, { data }).then((result) => {
            dispatch({ type: UPDATE_DEPARTMENT_SUCCESS, result });
            dispatch(showSnackbar('Department Updated'));
        });
    };
}

export function deleteDepartment(orgId, id) {
    return (dispatch, getState, client) => {
        return client.del(`/departments/${id}`).then((result) => {
            dispatch({ type: DELETE_DEPARTMENT_SUCCESS, result });
            dispatch(showSnackbar('Department Deleted'));
        });
    };
}

/* SUGGESTED CONTENT ACTIONS */

export function shouldLoadSuggestedContent(state) {
    return !state.admin.get('loadedSuggestedContent');
}

export function loadSuggestedContent(governmentId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_SUGGESTED_CONTENT });
        return client
            .get(`/government/${governmentId}/content`)
            .then((result) => {
                dispatch({ type: LOAD_SUGGESTED_CONTENT_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_SUGGESTED_CONTENT_FAIL, error });
            });
    };
}

export function toggleSuggestedContentForm(show, contentId) {
    if (contentId) {
        return { type: TOGGLE_EDIT_CONTENT_FORM, show, contentId };
    }
    return { type: TOGGLE_ADD_CONTENT_FORM, show };
}

export function addSuggestedContent(govId, contentData) {
    return (dispatch, getState, client) => {
        dispatch({ type: POST_SUGGESTED_CONTENT });
        return client
            .post(`/government/${govId}/content`, { data: contentData })
            .then((result) => {
                dispatch(showSnackbar('Suggested content added'));
                dispatch({ type: POST_SUGGESTED_CONTENT_SUCCESS, result });
                dispatch(toggleSuggestedContentForm(false));
            })
            .catch((error) => {
                dispatch({ type: POST_SUGGESTED_CONTENT_FAIL, error });
            });
    };
}

export function editSuggestedContent(contentId) {
    return (dispatch, getState, client) => (data) => {
        dispatch({ type: EDIT_SUGGESTED_CONTENT, contentId });
        return client
            .put(`/content/${contentId}`, { data })
            .then((result) => {
                dispatch(showSnackbar('Suggested content updated'));
                dispatch({ type: EDIT_SUGGESTED_CONTENT_SUCCESS, result, contentId });
            })
            .catch((error) => {
                dispatch({ type: EDIT_SUGGESTED_CONTENT_FAIL, error, contentId });
            });
    };
}

export function deleteSuggestedContent(contentId) {
    return (dispatch, getState, client) => {
        dispatch({ type: DELETE_SUGGESTED_CONTENT, contentId });
        return client
            .del(`/content/${contentId}`)
            .then(() => {
                dispatch(showSnackbar('Suggested content deleted'));
                dispatch({ type: DELETE_SUGGESTED_CONTENT_SUCCESS, contentId });
            })
            .catch((error) => {
                dispatch({ type: DELETE_SUGGESTED_CONTENT_FAIL, error, contentId });
            });
    };
}

export function sortContent(field) {
    return (dispatch) => {
        dispatch({ type: SORT_SUGGESTED_CONTENT, field });
    };
}

/* ORGANIZATION UPDATE ACTIONS */

export const UPLOAD_LOGO = 'admin/UPLOAD_LOGO';
export const UPLOAD_LOGO_SUCCESS = 'admin/UPLOAD_LOGO_SUCCESS';
export const UPLOAD_LOGO_FAIL = 'admin/UPLOAD_LOGO_FAIL';

export function uploadLogo(imageDataUrl) {
    return (dispatch, getState, client) => {
        const data = { imageDataUrl };
        const orgId = getState().auth.getIn(['user', 'organization', 'id']);
        dispatch({ type: UPLOAD_LOGO, logoUrl: imageDataUrl });
        return client
            .put(`/organization/${orgId}/logo`, { data })
            .then(({ logoUrl }) => {
                dispatch({ type: UPLOAD_LOGO_SUCCESS, logoUrl });
            })
            .catch((error) => {
                dispatch({ type: UPLOAD_LOGO_FAIL, error });
            });
    };
}

export const UPDATE_ORG = 'admin/UPDATE_ORG';
export const UPDATE_ORG_SUCCESS = 'admin/UPDATE_ORG_SUCCESS';
export const UPDATE_ORG_FAIL = 'admin/UPDATE_ORG_FAIL';

export function updateOrganization(orgId, data, options = {}) {
    return (dispatch, getState, client) => {
        dispatch({ type: UPDATE_ORG });
        return client
            .put(`/organization/${orgId}`, { data })
            .then((result) => {
                if (!options.hideToast) {
                    dispatch(showSnackbar('Profile Saved'));
                }

                dispatch({ type: UPDATE_ORG_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: UPDATE_ORG_FAIL, error });
            });
    };
}

export const SHOW_ADMIN_USER_LIST_MODAL = 'admin/SHOW_ADMIN_USER_LIST_MODAL';
export const HIDE_ADMIN_USER_LIST_MODAL = 'admin/HIDE_ADMIN_USER_LIST_MODAL';

export function showAdminUsersModal() {
    return { type: SHOW_ADMIN_USER_LIST_MODAL };
}

export function hideAdminUsersModal() {
    return { type: HIDE_ADMIN_USER_LIST_MODAL };
}

export const LOAD_TEMPLATE_WORKLOAD_WEIGHTS = 'admin/LOAD_TEMPLATE_WORKLOAD_WEIGHTS';
export const LOAD_TEMPLATE_WORKLOAD_WEIGHTS_SUCCESS =
    'admin/LOAD_TEMPLATE_WORKLOAD_WEIGHTS_SUCCESS';
export const LOAD_TEMPLATE_WORKLOAD_WEIGHTS_FAIL = 'admin/LOAD_TEMPLATE_WORKLOAD_WEIGHTS_FAIL';

export function loadTemplatesWithWorkloadWeights() {
    return (dispatch, getState, client) => {
        const govId = getState().auth.getIn(['user', 'government_id']);
        dispatch({ type: LOAD_TEMPLATE_WORKLOAD_WEIGHTS });
        return client
            .get(`/government/${govId}/workload-weights`)
            .then((result) => dispatch({ type: LOAD_TEMPLATE_WORKLOAD_WEIGHTS_SUCCESS, result }))
            .catch((error) => dispatch({ type: LOAD_TEMPLATE_WORKLOAD_WEIGHTS_FAIL, error }));
    };
}

export const UPDATE_WORKLOAD_WEIGHTS_SUCCESS = 'admin/UPDATE_WORKLOAD_WEIGHTS_SUCCESS';

export function updateWorkloadWeight(workloadWeightId, data) {
    return (dispatch, getState, client) => {
        const govId = getState().auth.getIn(['user', 'government_id']);
        return client
            .put(`/government/${govId}/workload-weights/${workloadWeightId}`, { data })
            .then((result) => {
                dispatch({ type: UPDATE_WORKLOAD_WEIGHTS_SUCCESS, result });
                return result;
            });
    };
}

export const LOAD_AUTO_NUMBERS = 'admin/LOAD_AUTO_NUMBERS';
export const LOAD_AUTO_NUMBERS_SUCCESS = 'admin/LOAD_AUTO_NUMBERS_SUCCESS';
export const LOAD_AUTO_NUMBERS_FAIL = 'admin/LOAD_AUTO_NUMBERS_FAIL';

export function loadAutoNumbers() {
    return (dispatch, getState, client) => {
        const govId = getState().auth.getIn(['user', 'government_id']);
        dispatch({ type: LOAD_AUTO_NUMBERS });
        return client
            .get(`/government/${govId}/auto-numbers`)
            .then((result) => dispatch({ type: LOAD_AUTO_NUMBERS_SUCCESS, result }))
            .catch((error) => dispatch({ type: LOAD_AUTO_NUMBERS_FAIL, error }));
    };
}

export function updateAutoNumber(autoNumberId, data) {
    return (dispatch, getState, client) => {
        const govId = getState().auth.getIn(['user', 'government_id']);
        return client
            .put(`/government/${govId}/auto-numbers/${autoNumberId}`, { data })
            .then((result) => {
                dispatch({ type: LOAD_AUTO_NUMBERS_SUCCESS, result });
                dispatch(showSnackbar('Auto numbering updated'));
                return result;
            });
    };
}

export const UPDATE_OUTLINE_NUMBERING = 'admin/UPDATE_OUTLINE_NUMBERING';
export const UPDATE_OUTLINE_NUMBERING_SUCCESS = 'admin/UPDATE_OUTLINE_NUMBERING_SUCCESS';
export const UPDATE_OUTLINE_NUMBERING_FAIL = 'admin/UPDATE_OUTLINE_NUMBERING_FAIL';

export function updateOutlineNumbering(outlineNumberingId, data) {
    return (dispatch, getState, client) => {
        const govId = getState().auth.getIn(['user', 'government_id']);
        dispatch({ type: UPDATE_OUTLINE_NUMBERING });
        return client
            .put(`/government/${govId}/outline-numbering/${outlineNumberingId}`, { data })
            .then((result) => {
                dispatch({ type: UPDATE_OUTLINE_NUMBERING_SUCCESS, result });
                dispatch(showSnackbar('Outline numbering updated'));
                return result;
            })
            .catch((error) => dispatch({ type: UPDATE_OUTLINE_NUMBERING_FAIL, error }));
    };
}

export const SHOW_BULK_UPDATE_MODAL = 'admin/SHOW_BULK_UPDATE_MODAL';
export const HIDE_BULK_UPDATE_MODAL = 'admin/HIDE_BULK_UPDATE_MODAL';

export function bulkUpdateContacts(data) {
    return (dispatch, getState, client) => {
        const govId = getState().auth.getIn(['user', 'government_id']);
        dispatch(updatingConfirmationModal());
        return client
            .put(`/government/${govId}/bulk-update-contacts`, { data })
            .then((result) => {
                dispatch(reset(bulkUpdateContactsForm));
                dispatch(hideConfirmationModal());
                dispatch(showSnackbar('Contacts Updated'));
                return result;
            })
            .catch((error) => {
                dispatch(reset(bulkUpdateContactsForm));
                dispatch(
                    showSnackbar(`Contacts failed to update: ${error.message}`, {
                        isError: true,
                    })
                );
            });
    };
}

export function showBulkUpdateModal(value) {
    return { type: SHOW_BULK_UPDATE_MODAL, value };
}

export function hideBulkUpdateModal() {
    return { type: HIDE_BULK_UPDATE_MODAL };
}
