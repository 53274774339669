import { SOURCE_CODE_SVG } from '../../../../shared_config/tinymce';
import { projectIntroTemplateVariableOptions } from '../../containers/GovApp/constants';

export const insertTag = (editor, tagName, tagLink, linkOnly) => {
    editor.insertContent(
        editor.dom.createHTML(
            'a',
            { href: tagLink },
            editor.dom.encode(linkOnly ? tagLink : `${tagName}`)
        )
    );
};

export const insertTemplateVariable = (editor, label, value) => {
    const isProjectIntroVariable = projectIntroTemplateVariableOptions.some(
        (variable) => variable.value === value
    );
    const tagType = isProjectIntroVariable ? 'div' : 'span';

    // There seems to be a timing error when using the insertContent method on the TinyMCE editor
    // Under the hood, insertContent calls editor.execCommand('mceInsertContent', content) - https://github.com/tinymce/tinymce/blob/develop/modules/tinymce/src/core/main/ts/api/Editor.ts#L865
    // This execCommand dispatches an event that seems to not get executed immediately - https://github.com/tinymce/tinymce/blob/develop/modules/tinymce/src/core/main/ts/api/EditorCommands.ts#L74
    // Making this run on the next event cycle seems to allow the HTML for the template variable to be inserted
    setTimeout(() => {
        editor.insertContent(
            `<${tagType} data-templatevariable="${value}" class="templateTag mceNonEditable">${SOURCE_CODE_SVG} ${label}</${tagType}>`
        );
    });
};
