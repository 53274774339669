import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from '..';

export class ZeroState extends PureComponent {
    static propTypes = {
        button: PropTypes.node,
        buttonClickHandler: PropTypes.func,
        buttonProps: PropTypes.object,
        buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        containerClass: PropTypes.string,
        info: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { button, buttonClickHandler, buttonProps, buttonText, containerClass, info, title } =
            this.props;

        return (
            <div className={classNames(this.styles.container, 'row text-muted', containerClass)}>
                <div className="col-sm-10 col-sm-offset-1">
                    <div className={this.styles.header}>{title}</div>
                    {button && <div className={this.styles.button}>{button}</div>}
                    {buttonText && (
                        <div className={this.styles.button}>
                            <Button
                                bsSize="lg"
                                bsStyle="success"
                                onClick={buttonClickHandler}
                                qaTag={`zeroState-${buttonText}`}
                                {...buttonProps}
                            >
                                {buttonText}
                            </Button>
                        </div>
                    )}
                    {info && <div className={this.styles.info}>{info}</div>}
                </div>
            </div>
        );
    }
}
