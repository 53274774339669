import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { AssignIntakeForm } from './AssignIntakeForm';
import { fieldNames } from './AssignIntakeForm/constants';
import { IntakeStatus } from './IntakeStatus';
import { ApprovalStatus, ApprovalViewStatus, RemindButton } from '../components';
import {
    AdminUserListButton,
    Button,
    Main,
    OutlineButton,
    PartnerResourcesModal,
    SectionTitle,
    UserProfilePicture,
    Well,
} from '../../..';
import { projectStatusesDict } from '../../../../../../shared_config/projects';
import { userRolePermissions } from '../../../../../../shared_config/userRoles';
import { CooperativeContractBar } from '../../../CooperativeContractBar';

const { REQUEST_DRAFT } = projectStatusesDict;

const { IS_SYSTEM_ADMIN } = userRolePermissions;

const { APPROVER_IDS } = fieldNames;

const dateFormat = 'M/D/YY h:mma';

export class ProcessIntake extends PureComponent {
    static propTypes = {
        addApproversError: PropTypes.string,
        addApproversHandler: PropTypes.func.isRequired,
        addingApprovers: PropTypes.bool.isRequired,
        approvals: PropTypes.arrayOf(
            PropTypes.shape({
                projectApprovals: PropTypes.arrayOf(
                    PropTypes.shape({
                        created_at: PropTypes.string.isRequired,
                        remindError: PropTypes.string,
                        status: PropTypes.string.isRequired,
                        user: PropTypes.shape({
                            displayName: PropTypes.string.isRequired,
                            firstName: PropTypes.string,
                        }).isRequired,
                        user_id: PropTypes.number.isRequired,
                    })
                ),
            })
        ).isRequired,
        closeOutHandler: PropTypes.func.isRequired,
        contractsProjectPath: PropTypes.string.isRequired,
        createContractHandler: PropTypes.func.isRequired,
        inReview: PropTypes.bool.isRequired,
        isAbleToAssignApprover: PropTypes.bool.isRequired,
        isAssignedUser: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        organization: PropTypes.shape({
            name: PropTypes.string.isRequired,
            zipCode: PropTypes.string.isRequired,
        }).isRequired,
        project: PropTypes.shape({
            categories: PropTypes.array.isRequired,
            closeOutReason: PropTypes.string,
            contact_id: PropTypes.number.isRequired,
            department_id: PropTypes.number.isRequired,
            id: PropTypes.number.isRequired,
            intakeProjectId: PropTypes.number,
            status: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            user_id: PropTypes.number.isRequired,
        }).isRequired,
        reminderHandler: PropTypes.func.isRequired,
        intakeDashboardPath: PropTypes.string.isRequired,
        intakeIncompleteHandler: PropTypes.func.isRequired,
        showInstructionsModal: PropTypes.func.isRequired,
        showModal: PropTypes.func.isRequired,
        showPartnerResourcesModal: PropTypes.func.isRequired,
        showProjectCreateModal: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        users: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showForm: false,
        };
    }

    name = 'project request';

    get styles() {
        return require('./index.scss');
    }

    get options() {
        const {
            approvals,
            closeOutHandler,
            contractsProjectPath,
            createContractHandler,
            project,
            intakeDashboardPath,
            intakeIncompleteHandler,
        } = this.props;

        return [
            {
                bsStyle: 'success',
                icon: 'bullhorn',
                onClick: this.createDocumentHandler,
                optionInfo:
                    'Move forward with the request by getting the solicitation process started',
                optionText: 'Create Solicitation',
                selectedInfo: (
                    <p>
                        A solicitation was created from this {this.name}
                        <br />
                        <br />
                        <OutlineButton block bsStyle="success" to={intakeDashboardPath}>
                            View Solicitation <i className="fa fa-external-link" />
                        </OutlineButton>
                    </p>
                ),
                selectedText: 'Solicitation Document Created',
            },
            {
                bsStyle: 'info',
                icon: 'edit',
                onClick: createContractHandler,
                optionInfo: 'Skip the solicitation process and move directly into contract.',
                optionText: 'Create Contract',
                selectedInfo: (
                    <p>
                        A contract was created directly from this {this.name}
                        <br />
                        <br />
                        <OutlineButton block bsStyle="info" to={contractsProjectPath}>
                            View Contracts <i className="fa fa-external-link" />
                        </OutlineButton>
                    </p>
                ),
                selectedText: 'Contract Created',
            },
            {
                bsStyle: 'warning',
                icon: 'archive',
                onClick: closeOutHandler,
                optionInfo:
                    "Use this option if the request should not move forward or needs to be processed outside of OpenGov Procurement's system",
                optionText: 'Close Request',
                selectedBsStyle: 'info',
                selectedInfo: (
                    <p>
                        The {this.name} was closed for the following reason:
                        <br />
                        <em>{project.closeOutReason}</em>
                    </p>
                ),
                selectedText: 'Request Closed',
            },
            {
                bsStyle: 'danger',
                icon: 'undo',
                onClick: intakeIncompleteHandler,
                optionInfo:
                    'Not enough information provided to process the request. Move the request back to Draft and ask for more information.',
                optionText: 'Incomplete Request',
                selectedInfo: (
                    <p>
                        More information is needed before the request can be processed:
                        <br />
                        <em>{get(approvals, '0.projectApprovals.0.comment')}</em>
                    </p>
                ),
                selectedText: 'More Information Requested',
            },
        ];
    }

    assignUserHandler = (data) => {
        const { addApproversHandler, project } = this.props;

        return addApproversHandler(project.id, [data[APPROVER_IDS]]).then((resultOrError) => {
            if (!(resultOrError instanceof Error)) {
                this.setState({ showForm: false });
            }
        });
    };

    createDocumentHandler = () => {
        const { project, showProjectCreateModal, user } = this.props;

        showProjectCreateModal({
            categories: project.categories,
            createData: {
                contact_id: project.contact_id,
                department_id: project.department_id,
                intakeId: project.id,
                user_id: user.id,
            },
            fromIntake: true,
            steps: [2],
        });
    };

    toggleForm = () => this.setState((prevState) => ({ showForm: !prevState.showForm }));

    renderAssignedUserForm() {
        const {
            addApproversError,
            addingApprovers,
            approvals,
            isAbleToAssignApprover,
            isEditor,
            users,
        } = this.props;

        return (
            <AssignIntakeForm
                addingApprovers={addingApprovers}
                initialValues={{
                    [APPROVER_IDS]: get(approvals, '0.projectApprovals.0.user_id'),
                }}
                isAbleToAssignApprover={isAbleToAssignApprover}
                isEditor={isEditor}
                onSubmit={this.assignUserHandler}
                submitServerError={addApproversError}
                users={users}
            />
        );
    }

    renderEditUserButton() {
        const { approvals, inReview } = this.props;

        const { showForm } = this.state;

        if (approvals.length === 0 || !inReview) {
            return null;
        }

        const icon = showForm ? 'ban' : 'pencil';
        const text = showForm ? 'Cancel' : 'Edit';

        return (
            <Button
                bsSize="sm"
                bsStyle="link"
                className={this.styles.editButton}
                onClick={this.toggleForm}
                qaTag="processRequest-editUser"
            >
                <i className={`fa fa-${icon}`} /> {text}
            </Button>
        );
    }

    renderAssignedUser() {
        const { approvals, inReview, isAssignedUser, isEditor, reminderHandler, showModal } =
            this.props;

        const { showForm } = this.state;

        if (approvals.length === 0) {
            if (!inReview) {
                return (
                    <div className="text-center">
                        <em>No User Assigned</em>
                    </div>
                );
            }

            return (
                <>
                    <div className={`text-danger ${this.styles.noUserContainer}`}>
                        <i className="fa fa-exclamation-triangle " /> Please assign a user to
                        process your request!
                    </div>
                    {this.renderAssignedUserForm()}
                </>
            );
        }

        if (showForm) {
            return this.renderAssignedUserForm();
        }

        const approval = approvals[0].projectApprovals[0];

        const { created_at: createdAt, remindError, status, user } = approval;

        return (
            <div>
                <div className={this.styles.statusIcon}>
                    <ApprovalStatus isIntake status={status} />
                </div>
                <div>
                    <UserProfilePicture horizontal user={user} />
                    &nbsp;
                    <strong>{user.displayName}</strong>
                </div>
                <div className={this.styles.assignedDate}>
                    Assigned: {moment(createdAt).format(dateFormat)}
                </div>
                <div>
                    <ApprovalViewStatus approval={approval} isIntake isViewOnly />
                </div>
                {inReview && isEditor && !isAssignedUser && (
                    <RemindButton
                        approval={approval}
                        block
                        className={this.styles.remindButton}
                        reminderHandler={reminderHandler}
                        showModal={showModal}
                    />
                )}
                {remindError && <div className="error-block text-center">{remindError}</div>}
            </div>
        );
    }

    renderOptions() {
        const { approvals, isAssignedUser, isEditor } = this.props;

        if (approvals.length === 0) {
            const info = (
                <div>
                    Please assign a user who will be in charge of processing the request.
                    <br />
                    Not sure who to assign?&nbsp;
                    <AdminUserListButton permissionType={IS_SYSTEM_ADMIN} text="Contact an Admin" />
                </div>
            );

            return (
                <IntakeStatus
                    bsStyle="info"
                    icon="user-plus"
                    info={info}
                    title="Please Assign a User"
                />
            );
        }

        if (!isAssignedUser) {
            const assignedUser = approvals[0].projectApprovals[0].user;

            const info = (
                <>
                    <p>
                        {assignedUser.displayName} has been assigned to the {this.name}.
                        <br />
                        The request is pending until {assignedUser.firstName || 'the user'}{' '}
                        completes processing.
                    </p>
                    {isEditor && <p>You may edit the assigned user as needed.</p>}
                </>
            );

            return (
                <IntakeStatus bsStyle="info" icon="clock-o" info={info} title="Request Pending" />
            );
        }

        return (
            <>
                <ListGroup>
                    {this.options.map((option, index) => {
                        const { bsStyle, icon, onClick, optionInfo, optionText } = option;

                        const item = (
                            <ListGroupItem key={index} onClick={onClick}>
                                <i
                                    className={`fa fa-fw fa-4x fa-${icon} text-${bsStyle} ${this.styles.optionIcon}`}
                                />
                                <div className={this.styles.optionText}>
                                    <h4 className={`text-${bsStyle}`}>{optionText}</h4>
                                    {optionInfo}
                                </div>
                                <div className="clearfix" />
                            </ListGroupItem>
                        );

                        return item;
                    })}
                </ListGroup>
                <div className="text-info text-center">
                    <i className="fa fa-info-circle" /> Please select an option above to process the
                    request
                </div>
                <Well className={this.styles.partnerResources}>
                    <h5>Looking for more options?</h5>
                    <Button
                        block
                        onClick={this.props.showPartnerResourcesModal}
                        qaTag="processRequest-viewPartnerResources"
                    >
                        <i className="fa fa-external-link" /> View Partner Resources
                    </Button>
                </Well>
            </>
        );
    }

    renderSelectedOption() {
        const { project } = this.props;

        let selectedOptionIndex = 2;
        if (project.intakeProjectId) {
            selectedOptionIndex = 0;
        } else if (project.closeOutReason === 'Contract Created From Intake') {
            selectedOptionIndex = 1;
        } else if (project.status === REQUEST_DRAFT) {
            selectedOptionIndex = 3;
        }
        const selectedOption = this.options[selectedOptionIndex];
        return (
            <IntakeStatus
                {...selectedOption}
                bsStyle={selectedOption.selectedBsStyle || selectedOption.bsStyle}
                info={selectedOption.selectedInfo}
                title={selectedOption.selectedText}
            />
        );
    }

    render() {
        const {
            inReview,
            isAssignedUser,
            organization: { name, zipCode },
            project: { title },
            showInstructionsModal,
        } = this.props;

        let assignedLabel = 'Request Status:';
        if (!inReview) {
            assignedLabel = 'Selected Option:';
        } else if (isAssignedUser) {
            assignedLabel = 'Select an Option:';
        }

        return (
            <Main>
                <SectionTitle
                    info={`Route the ${this.name} to its next step`}
                    onHelpClick={inReview ? showInstructionsModal : undefined}
                    title="Process Request"
                />
                <div className="row">
                    <div className="col-md-5 col-lg-offset-1 col-lg-4">
                        <div>
                            <b>Assigned User:</b>
                        </div>
                        &nbsp;
                        {this.renderEditUserButton()}
                        <Well>{this.renderAssignedUser()}</Well>
                        <CooperativeContractBar
                            projectTitle={title}
                            publicEntityName={name}
                            sizing="sm"
                            userZipCode={zipCode}
                        />
                    </div>
                    <div className="col-md-7 col-lg-6">
                        <b>{assignedLabel}</b>

                        {inReview ? this.renderOptions() : this.renderSelectedOption()}
                    </div>
                </div>
                <PartnerResourcesModal type="intake" />
            </Main>
        );
    }
}
