import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { HelpIcon, InputText, SearchSelect, Toggle } from '../../../../../../../../../components';
import { dollarString } from '../../../../../../../../../Forms/normalizers';
import { priceTableFieldNames } from '../../../../../../constants';
import { searchSelectOptions, searchSelectStyles } from '../constants';
import { maskNumberWithCommas } from '../../../../../../../../../Forms/maskers';
import { MaskedInputText } from '../../../../../../../../../hocs';
import { UNIT_PRICE } from '../../../../../../../../../../../shared_config/priceTables';

const { HAS_NO_BID, MIN_BID_DECREMENT, SPECIFY_UNIT_PRICE } = priceTableFieldNames;

export const ReverseAuctionToggles = (props) => {
    const {
        arrayName,
        auctionMaxFractionDigits,
        change,
        columnChangeHandler,
        fields: { forEach },
        disabled = false,
        showValidation = false,
    } = props;

    const styles = require('../index.scss');
    const MaskedDecrementInput = useCallback(MaskedInputText(InputText), []);
    const normalizedDecrement = (value) =>
        dollarString(value, { maximumFractionDigits: auctionMaxFractionDigits });

    const generatePlaceholder = () => {
        if (auctionMaxFractionDigits <= 0 || !auctionMaxFractionDigits) return '1.00';
        const zeros = '0'.repeat(auctionMaxFractionDigits - 1);
        return `0.${zeros}1`;
    };

    return (
        <>
            <h5 className={styles.optionsTitle}>Bid Options:</h5>
            <div className="row">
                <div className={`col-xs-12 col-sm-6 ${styles.colToggle}`}>
                    <Field
                        component={MaskedDecrementInput}
                        disabled={disabled}
                        hasFeedback={false}
                        inputGroupPrefix="$"
                        label="Minimum Bid Decrement*"
                        mask={maskNumberWithCommas}
                        name={`${arrayName}.${MIN_BID_DECREMENT}`}
                        normalizer={normalizedDecrement}
                        placeholder={generatePlaceholder()}
                        showValidation={showValidation}
                        type="text"
                    />
                </div>
            </div>
            <h5 className={styles.optionsTitle}>Table Options:</h5>
            <div className="row">
                <div className={`col-xs-12 col-sm-6 ${styles.colToggle}`}>
                    Include No Bid column
                    <HelpIcon tooltip="Allows vendors to optionally no bid line items instead of requiring them to enter a price" />
                </div>
                <div className="col-xs-12 col-sm-3">
                    <Field
                        component={Toggle}
                        disabled={disabled}
                        name={`${arrayName}.${HAS_NO_BID}`}
                        onChange={columnChangeHandler}
                    />
                </div>
            </div>
            <div className="row">
                <div className={`col-xs-12 col-sm-6 ${styles.colToggle}`}>
                    Who specifies unit cost of items
                    <HelpIcon tooltip="Choose whether you or the vendor will specify the unit cost for items. Default is for vendors to provide the unit costs." />
                </div>
                <div className="col-xs-12 col-sm-3">
                    <Field
                        component={SearchSelect}
                        disabled={disabled}
                        formClassName={styles.searchSelect}
                        isSearchable={false}
                        name={`${arrayName}.${SPECIFY_UNIT_PRICE}`}
                        onChange={(e, value) => {
                            if (!value) {
                                forEach((priceItemMember) =>
                                    change(`${priceItemMember}.${UNIT_PRICE}`, null)
                                );
                            }
                            columnChangeHandler();
                        }}
                        options={searchSelectOptions}
                        styles={searchSelectStyles}
                    />
                </div>
            </div>
        </>
    );
};

ReverseAuctionToggles.propTypes = {
    arrayName: PropTypes.string.isRequired,
    auctionMaxFractionDigits: PropTypes.number.isRequired,
    change: PropTypes.func.isRequired,
    columnChangeHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fields: PropTypes.shape({
        forEach: PropTypes.func.isRequired,
    }).isRequired,
    showValidation: PropTypes.bool,
};
