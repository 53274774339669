import { createSelector } from 'reselect';

import { getReviewProjectJS } from '../../../containers/GovApp/selectors';
import {
    addendumStatusTypes,
    amendableProjectStatuses,
} from '../../../../../shared_config/addendums';
import { proposalStatusesDict } from '../../../../../shared_config/proposals';

const { NO_BID } = proposalStatusesDict;

const { DRAFT, RELEASED } = addendumStatusTypes;

const getAddendums = (state, props) => props.addendums;
const getProposals = (state) => state.addendums.get('proposals');

export const getReleasedAddendumsJS = createSelector([getAddendums], (addendums) =>
    addendums.filter((addendum) => addendum.status === RELEASED)
);

export const getDraftAddendumsJS = createSelector([getAddendums], (addendums) =>
    addendums.filter((addendum) => addendum.status === DRAFT)
);

export const isProjectInAmendableStatus = createSelector([getReviewProjectJS], (project) => {
    if (project) {
        return amendableProjectStatuses.includes(project.status);
    }
    return false;
});

export const getAddendumProposals = createSelector(
    [getReleasedAddendumsJS, getProposals],
    (addendums, proposals) => {
        return proposals
            .toJS()
            .filter((proposal) => proposal.status !== NO_BID)
            .map((proposal) => {
                const addendumConfirms = addendums.map((addendum) => {
                    return proposal.addendumConfirms.find((addendumConfirm) => {
                        return addendumConfirm.addendum_id === addendum.id;
                    });
                });
                return {
                    ...proposal,
                    addendumConfirms,
                };
            });
    }
);
