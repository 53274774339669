import { listToDict } from '../../../../../../../shared_config/helpers';

const fields = [
    'allowSubstitutionRequests',
    'hasSealedBid',
    'isAutoBidUnsealed',
    'isPrivate',
    'requiresInvitation',
    'isPublicBidPricingResult',
    'isPublicBidResult',
    'isPublicBidTotalOnly',
    'template',
    'timelineConfig',
    'useManualNumbering',
    'useSectionDividers',
    'user_id',
];
export const fieldNames = listToDict(fields);

const templateFields = [
    'docx_attachment_id',
    'forceSealedBidStatus',
    'icon',
    'isGlobalCreate',
    'isReverseAuction',
    'omitDocx',
    'processAcronym',
    'procurement_tag_id',
    'substitutionRequestFormUrl',
    'title',
];
export const templateFieldNames = listToDict(templateFields);

const timelineConfigFields = ['hasAuctionEndDate', 'proposalDeadlineName'];
export const timelineConfigFieldNames = listToDict(timelineConfigFields);
