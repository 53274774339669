import { certificationsList } from '../../../../shared_config/certifications';
import { vendorEmergencyDesignationsColumnMapping } from '../../../../shared_config/vendors';
import { USE_PROCURATED } from '../../../../shared_config/procurated';
import { trackEvent } from '../../helpers';

/**
 * Reaches out to retrieve Procurated ratings for a set of vendors
 * @param {*} params Request parameters
 * @param {*} response The result of the get vendor list call
 * @param {*} loadProcuratedRatingsClient Function that reaches out to the Procurated services
 * @returns List of vendors with ratings
 */
const getVendorRatings = (params, response, loadProcuratedRatingsClient) => {
    if (USE_PROCURATED && response.results && response.results.length > 0) {
        const suppliers = response.results;
        const supplierIds = suppliers.map((result) => result.vendorId);
        return loadProcuratedRatingsClient({
            supplierIds,
        })
            .then((ratingsResponse) => {
                const suppliersWithRatings = suppliers.map((supplier) => {
                    const vendorRating = ratingsResponse.find((rating) => {
                        return parseInt(rating.partnerId, 10) === supplier.vendorId;
                    });

                    return {
                        ...supplier,
                        rating: vendorRating && vendorRating.rating,
                    };
                });

                params.successCallback(suppliersWithRatings, response.total);
            })
            .catch(() => {
                // We don't want to fail vendor list if ratings are unavailable
                params.successCallback(suppliers, response.total);
            });
    }

    return params.successCallback(response.results, response.total);
};

/**
 * Returns the getRows function, which AG Grid uses to sync row data from the server
 * @param {Function} serverFunction Function to search for vendors
 * @param {number} governmentId ID of the government the vendors are subscribed to
 * @param {string[]} initialSearchParams Search parameters for vendor search
 * @param {Function} clearSelection Deselects all rows in vendor list
 * @param {Function} loadProcuratedRatingsClient Get list of ratings from Procurated without
 * @returns getRows function
 */
export function retrieveVendors(
    serverFunction,
    governmentId,
    initialSearchParams,
    clearSelection,
    loadProcuratedRatingsClient
) {
    return {
        getRows(params) {
            if (clearSelection) {
                clearSelection();
            }
            const perPage = params.request.endRow - params.request.startRow;
            const page = params.request.endRow / perPage - 1;
            const sortParams = {};
            const filterParams = initialSearchParams ? { ...initialSearchParams } : {};
            filterParams.designations = [...(filterParams.designations || [])];
            filterParams.emergencyDesignations = [...(filterParams.emergencyDesignations || [])];
            if (params.request.sortModel && params.request.sortModel.length) {
                params.request.sortModel.forEach((item) => {
                    const { colId, sort } = item;
                    let direction = 'ASC';
                    if (sort !== 'asc') {
                        direction = 'DESC';
                    }
                    sortParams[colId.replace('organization.', '')] = direction;
                });
            }
            if (params.request.filterModel) {
                Object.keys(params.request.filterModel).forEach((key) => {
                    if (key === 'emergencyClassifications') {
                        params.request.filterModel[key].values.forEach((certTitle) => {
                            filterParams.emergencyDesignations.push(
                                vendorEmergencyDesignationsColumnMapping[certTitle]
                            );
                        });
                    } else if (key === 'selfReportedCertifications') {
                        params.request.filterModel[key].values.forEach((certTitle) => {
                            const foundCert = certificationsList.find(
                                (cert) => cert.title === certTitle
                            );
                            filterParams.designations.push(foundCert.id);
                        });
                    } else if (key === 'languages') {
                        filterParams.languages = params.request.filterModel[key].values;
                    } else {
                        const colId = key.replace('organization.', '');
                        filterParams[colId] = params.request.filterModel[key].filter;
                    }
                });
            }
            const searchFilters = {
                perPage,
                page,
                sort: sortParams,
                search: filterParams,
                isEmergencySearchDesignation: !!(initialSearchParams || {})
                    .isEmergencySearchDesignation,
            };

            return serverFunction(governmentId, searchFilters)
                .then((response) => {
                    const trackingObject = {
                        governmentId,
                        ...searchFilters?.search,
                        resultCount: response.total,
                    };

                    Object.keys(trackingObject).forEach((key) => {
                        // if key is not string or number, remove it
                        if (
                            typeof trackingObject[key] !== 'string' &&
                            typeof trackingObject[key] !== 'number'
                        ) {
                            delete trackingObject[key];
                        }
                    });

                    trackEvent('Vendor Search', trackingObject);
                    return getVendorRatings(params, response, loadProcuratedRatingsClient);
                })
                .catch(() => {
                    params.failCallback();
                });
        },
    };
}
