import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { createSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';

import { PersonalAddress, PersonalContact, PersonalEmail, PersonalName, Security } from '.';
import { fields as addressFields } from './PersonalAddress/constants';
import { fields as contactFields } from './PersonalContact/constants';
import { fields as emailFields } from './PersonalEmail/constants';
import { fields as nameFields } from './PersonalName/constants';
import { getUserJS } from '../selectors';
import { Nav, NavItem, PageTitle } from '../../components';
import { USE_OG_PLATFORM } from '../../constants';

const mapStateToProps = (state) => {
    return {
        personalSaving: state.accountPersonal.get('saving'),
        securitySaving: state.accountSecurity.get('saving'),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    initializeForm: initialize,
};

// @connect
class ConnectedAccount extends Component {
    static propTypes = {
        children: PropTypes.node,
        initializeForm: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
        location: PropTypes.object,
        personalSaving: PropTypes.bool,
        securitySaving: PropTypes.bool,
        user: PropTypes.object.isRequired,
    };

    get styles() {
        return require('./Account.scss');
    }

    renderPersonalAddress() {
        return (
            <div className={this.styles.personal}>
                <PageTitle title="Update Address" />
                <h2 className={this.styles.titleText}>Update Address</h2>
                <PersonalAddress initialValues={pick(this.props.user, addressFields)} />
            </div>
        );
    }

    renderPersonalContact() {
        return (
            <div className={this.styles.personal}>
                <PageTitle title="Update Contact Information" />
                <h2 className={this.styles.titleText}>Update Contact Information</h2>
                <PersonalContact initialValues={pick(this.props.user, contactFields)} />
            </div>
        );
    }

    renderPersonalEmail() {
        const { styles } = this;
        const { user } = this.props;
        const { isVendor } = this.props.user;
        const { personal, titleText } = styles;

        let title = 'Update Email and Notification Settings';
        if (isVendor) {
            title = 'Update Email Address';
        } else if (USE_OG_PLATFORM) {
            title = 'Update Email Notification Settings';
        }

        return (
            <div className={personal}>
                <PageTitle title={title} />
                <h2 className={titleText}>{title}</h2>
                <PersonalEmail initialValues={pick(user, emailFields)} isVendor={isVendor} />
            </div>
        );
    }

    renderPersonalName() {
        return (
            <div className={this.styles.personal}>
                <PageTitle title="Name" />
                <h2 className={this.styles.titleText}>Update Name</h2>
                <PersonalName initialValues={pick(this.props.user, nameFields)} />
            </div>
        );
    }

    renderContent() {
        const { location, children } = this.props;

        // TODO: Refactor to make account a layout wrapper rather than using
        // the same component and filtering by query params. Will be more
        // efficient as data loading can be done separately by each section.
        // For now only notifications will be loaded within the account wrapper
        // UPDATE 7/1/20: notifications tab has been removed
        if (children) return children;

        switch (location.query.section) {
            case 'address':
                return this.renderPersonalAddress();
            case 'contact':
                return this.renderPersonalContact();
            case 'email':
                return this.renderPersonalEmail();
            case 'name':
                return this.renderPersonalName();
            case 'security':
                return <Security />;
            default:
                return this.renderPersonalName();
        }
    }

    renderNavItems() {
        const {
            location,
            location: { pathname },
            personalSaving,
            securitySaving,
            user,
        } = this.props;

        const sections = [{ title: 'Name' }, { title: 'Contact' }, { title: 'Address' }];

        if (user.isVendor || !USE_OG_PLATFORM) {
            sections.push({ title: 'Email' }, { title: 'Security' });
        } else {
            sections.push({ title: 'Email Notifications', sectionName: 'email' });
        }

        const disabled = securitySaving || personalSaving;
        const basePath = pathname.replace(/\/account.+/, '/account');

        return sections.map((item, index) => {
            const section = (item.sectionName || item.title).toLowerCase();
            const currentSection = location.query && location.query.section;

            let route;
            let isActive;
            if (item.path) {
                route = { pathname: `${basePath}/${item.path}` };
                isActive = !!pathname.match(item.path);
            } else {
                route = { pathname: basePath, search: createSearchParams({ section }).toString() };
                isActive = currentSection
                    ? section === currentSection
                    : pathname === basePath && index === 0;
            }

            return (
                <NavItem
                    disabled={disabled}
                    key={index}
                    overrideIsActive={isActive}
                    qaTag={`account-${item.title}`}
                    to={route}
                >
                    {item.title}
                </NavItem>
            );
        });
    }

    render() {
        return (
            <div className="row">
                <h1 className="visually-hidden">Settings</h1>
                <Panel className="col-xs-10 col-xs-offset-1">
                    <Panel.Body>
                        <div>
                            <Nav bsStyle="tabs" justified>
                                {this.renderNavItems()}
                            </Nav>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    {this.renderContent()}
                                </div>
                            </div>
                        </div>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}

export const Account = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedAccount);
