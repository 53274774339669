import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { v4 as UUIDv4 } from 'uuid';

import {
    costFilterOptions,
    dateFilterFields,
    dateFilterOptions,
    fieldNames,
    form,
    termTypeOptions,
    trueFalseFilterOptions,
    typeOptions,
    statusFilterOptions,
} from './constants';
import { getInitialValues } from './selectors';
import { validate } from './validate';
import { Button, DateTimePicker, InputText, OutlineButton, SearchSelect } from '../..';
import {
    getContractTagSelectOptions,
    getDepartmentsSelectOptions,
    getProcurementContactSelectOptions,
} from '../../../containers/App/selectors';
import { maskNumberWithCommas } from '../../../Forms/maskers';
import { normalizeNumber } from '../../../Forms/normalizers';
import { MaskedInputText } from '../../../hocs';
import {
    contractDateFilterTypesDict,
    contractFilterTypesDict,
} from '../../../../../shared_config/contracts';
import { getRetentionCodesOptions } from '../../../containers/GovApp/selectors';

const { AFTER, BEFORE, BETWEEN } = contractDateFilterTypesDict;

const {
    BUDGET_AMOUNT,
    CONTRACT_ID,
    COOPERATIVE,
    DEPARTMENT,
    END_DATE,
    PIGGYBACK,
    PROCUREMENT_CONTACT,
    PUBLIC,
    REBID,
    RETENTION_CODE_ID,
    START_DATE,
    STATUS,
    TAG,
    TERM_TYPE,
    TITLE,
    VENDOR_NAME,
} = contractFilterTypesDict;

const { FILTERS } = fieldNames;

const searchSelectOptionPropType = PropTypes.arrayOf(
    PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
    })
);

const MaskedBudgetInput = MaskedInputText(InputText);

// Background: https://github.com/JedWatson/react-select/issues/1085
// Solution: https://github.com/JedWatson/react-select/issues/1076#issuecomment-253629943
const SELECT_MENU_STYLE = {
    menu: { zIndex: 5 },
};

const renderDateFilterFields = (fieldName, filterData) => {
    const styles = require('./index.scss');

    switch (filterData.dateFilterType) {
        case AFTER:
        case BEFORE:
            return <Field component={DateTimePicker} horizontal name={`${fieldName}.value`} />;
        case BETWEEN:
            return (
                <div className="row">
                    <div className={`col-xs-6 ${styles.betweenStart}`}>
                        <Field
                            component={DateTimePicker}
                            horizontal
                            name={`${fieldName}.value[0]`}
                        />
                    </div>
                    <div className={`col-xs-6 ${styles.betweenEnd}`}>
                        <Field
                            component={DateTimePicker}
                            horizontal
                            name={`${fieldName}.value[1]`}
                        />
                    </div>
                </div>
            );
        default:
            return null;
    }
};

const renderFilterValueField = (props, fieldName, filterData) => {
    const {
        change,
        contractTagFilterOptions,
        departmentOptions,
        procurementContactOptions,
        retentionCodesOptions,
    } = props;

    const now = moment();

    switch (filterData.type) {
        case BUDGET_AMOUNT:
            return (
                <div>
                    <Field
                        aria-label="Select Amount Filter Type"
                        blurInputOnSelect
                        component={SearchSelect}
                        name={`${fieldName}.costFilterType`}
                        options={costFilterOptions}
                        placeholder="Amount Filter Type"
                        styles={SELECT_MENU_STYLE}
                    />
                    <Field
                        component={MaskedBudgetInput}
                        inputGroupPrefix="$"
                        label="Contract Amount"
                        mask={maskNumberWithCommas}
                        name={`${fieldName}.value`}
                        normalizer={normalizeNumber}
                        placeholder="Enter Value"
                        type="text"
                    />
                </div>
            );
        case DEPARTMENT:
            return (
                <Field
                    aria-label="Select Department"
                    blurInputOnSelect
                    component={SearchSelect}
                    name={`${fieldName}.value`}
                    options={departmentOptions}
                />
            );
        case END_DATE:
        case START_DATE:
            return (
                <div>
                    <Field
                        aria-label="Set Date Filter Type"
                        blurInputOnSelect
                        component={SearchSelect}
                        name={`${fieldName}.dateFilterType`}
                        onChange={(event, value) => {
                            if (value === BETWEEN) {
                                change(`${fieldName}.value`, [
                                    now.toDate(),
                                    now.clone().add(1, 'year').toDate(),
                                ]);
                            } else {
                                change(`${fieldName}.value`, now.toDate());
                            }
                        }}
                        options={dateFilterOptions}
                        placeholder="Date Filter Type"
                    />
                    {renderDateFilterFields(fieldName, filterData)}
                </div>
            );
        case COOPERATIVE:
        case PIGGYBACK:
        case REBID:
        case PUBLIC:
            return (
                <Field
                    aria-label={`Select ${fieldName}.value`}
                    blurInputOnSelect
                    component={SearchSelect}
                    name={`${fieldName}.value`}
                    options={trueFalseFilterOptions}
                />
            );
        case STATUS:
            return (
                <Field
                    aria-label={`Select ${fieldName}.value`}
                    blurInputOnSelect
                    component={SearchSelect}
                    name={`${fieldName}.value`}
                    options={statusFilterOptions}
                />
            );
        case TAG:
            return (
                <Field
                    aria-label={`Select ${fieldName}.value`}
                    blurInputOnSelect
                    component={SearchSelect}
                    isMulti
                    name={`${fieldName}.value`}
                    options={contractTagFilterOptions}
                    placeholder="Select Tags"
                />
            );
        case TERM_TYPE:
            return (
                <Field
                    aria-label={`Select ${fieldName}.value`}
                    blurInputOnSelect
                    component={SearchSelect}
                    name={`${fieldName}.value`}
                    options={termTypeOptions}
                    placeholder="Select Term Type"
                />
            );
        case PROCUREMENT_CONTACT:
            return (
                <Field
                    aria-label={`Select ${fieldName}.value`}
                    blurInputOnSelect
                    component={SearchSelect}
                    name={`${fieldName}.value`}
                    options={procurementContactOptions}
                />
            );
        case RETENTION_CODE_ID:
            return (
                <Field
                    aria-label="Select Retention Code"
                    blurInputOnSelect
                    component={SearchSelect}
                    name={`${fieldName}.value`}
                    options={retentionCodesOptions}
                />
            );
        case CONTRACT_ID:
        case TITLE:
            return (
                <Field
                    aria-label={`Select ${fieldName}.value`}
                    component={InputText}
                    hasFeedback={false}
                    name={`${fieldName}.value`}
                    placeholder="Enter Text"
                    qaTag="contractListFiltersForm-title"
                    type="text"
                />
            );
        case VENDOR_NAME:
            return (
                <Field
                    aria-label={`Select ${fieldName}.value`}
                    component={InputText}
                    hasFeedback={false}
                    name={`${fieldName}.value`}
                    placeholder="Enter Text"
                    qaTag="contractListFiltersForm-vendorName"
                    type="text"
                />
            );

        default:
            return null;
    }
};

const submitHandler =
    ({ executeSearch }) =>
    (data) => {
        executeSearch(data, { resetPage: true });
    };

const renderFilter = (props, fieldName, filterData, fields, index) => {
    const { change } = props;

    const styles = require('./index.scss');

    return (
        <div className="row">
            <div className="col-xs-12" style={index === 0 ? { marginTop: 15 } : undefined}>
                <div className="pull-right">
                    <Button
                        aria-label="Remove Filter"
                        bsStyle="link"
                        className={styles.removeFilterButton}
                        onClick={() => {
                            fields.remove(index);
                            setTimeout(
                                props.handleSubmit(submitHandler(props)),
                                0,
                                fields.getAll()
                            );
                        }}
                        qaTag="contractListFiltersForm-removeFilter"
                    >
                        <i className="fa fa-times fa-lg text-danger" />
                    </Button>
                </div>
                <Field
                    blurInputOnSelect
                    component={SearchSelect}
                    label="Filter Type"
                    name={`${fieldName}.type`}
                    onChange={(nextFilterType) => {
                        // Keep the dates in the value when the filter changes between date types
                        const fieldValue = fields.get(index);
                        if (
                            !fieldValue.dateFilterType ||
                            !dateFilterFields.includes(nextFilterType)
                        ) {
                            change(`${fieldName}.value`, null);
                        }
                    }}
                    options={typeOptions}
                    placeholder="Select Filter Type"
                    styles={SELECT_MENU_STYLE}
                />
            </div>
            <div className="col-xs-12">{renderFilterValueField(props, fieldName, filterData)}</div>
        </div>
    );
};

const renderFilters = (props, { fields }) => {
    const styles = require('./index.scss');

    return (
        <div className={styles.filtersFormContainers}>
            <div className="row">
                <div className="col-xs-12">
                    <OutlineButton
                        block
                        bsStyle="primary"
                        onClick={() =>
                            fields.push({
                                localCreatingUUID: UUIDv4(),
                                type: undefined,
                                value: undefined,
                            })
                        }
                        qaTag="contractListFiltersForm-addListFilter"
                    >
                        <i className="fa fa-filter" />
                        &nbsp;Add List Filter
                    </OutlineButton>
                </div>
            </div>
            {fields.length > 0 && (
                <div className={styles.filtersList}>
                    {fields.map((fieldName, index) => {
                        const filterData = fields.get(index);

                        return (
                            <div key={filterData.localCreatingUUID}>
                                {renderFilter(props, fieldName, filterData, fields, index)}
                            </div>
                        );
                    })}
                    <div className="text-right">
                        <Button
                            bsSize="sm"
                            bsStyle="info"
                            qaTag="contractListFiltersForm-search"
                            type="submit"
                        >
                            <i className="fa fa-search" /> Search
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

const BaseContractListFiltersForm = (props) => {
    const styles = require('./index.scss');

    return (
        <form className={styles.formContainer} onSubmit={props.handleSubmit(submitHandler(props))}>
            <FieldArray
                component={(options) => renderFilters(props, options)}
                name={FILTERS}
                rerenderOnEveryChange
            />
        </form>
    );
};

BaseContractListFiltersForm.propTypes = {
    change: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    contractTagFilterOptions: searchSelectOptionPropType, // eslint-disable-line react/no-unused-prop-types
    departmentOptions: searchSelectOptionPropType, // eslint-disable-line react/no-unused-prop-types
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
    procurementContactOptions: searchSelectOptionPropType, // eslint-disable-line react/no-unused-prop-types
    retentionCodesOptions: searchSelectOptionPropType, // eslint-disable-line react/no-unused-prop-types
};

const FormConnectedContractListFiltersForm = reduxForm({
    // When switching between pages, this component will unmount, and therefore lose its form state
    // This will prevent the form state from being lost and will keep focus on text input fields when returning to the contracts page with text filters applied
    destroyOnUnmount: false,
    enableReinitialize: true,
    form,
    // This is needed so that filters that use text input fields do not lose focus when typing one character after searching
    // https://redux-form.com/8.3.0/docs/api/reduxform.md/#-code-keepdirtyonreinitialize-boolean-code-optional-
    keepDirtyOnReinitialize: true,
    validate,
})(BaseContractListFiltersForm);

const mapStateToProps = (state, props) => {
    return {
        contractTagFilterOptions: getContractTagSelectOptions(state),
        departmentOptions: getDepartmentsSelectOptions(state),
        initialValues: getInitialValues(props),
        procurementContactOptions: getProcurementContactSelectOptions(state),
        retentionCodesOptions: getRetentionCodesOptions(state),
    };
};

export const ContractListFiltersForm = connect(mapStateToProps)(
    FormConnectedContractListFiltersForm
);
