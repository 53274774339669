import { fieldNamesDict } from './constants';
import {
    projectDateFilterTypesDict,
    projectFilterTypesDict,
} from '../../../../../shared_config/projects';

const {
    DEPARTMENT_ID,
    IS_PAUSED,
    RETENTION_CODE_ID,
    STATUS,
    TEMPLATE_ID,
    TITLE,
    USER,
    // Dates
    CONTRACTOR_SELECTED_DATE,
    CREATED_AT,
    POST_SCHEDULED_AT,
    POSTED_AT,
    PRE_PROPOSAL_DATE,
    PROPOSAL_DEADLINE,
    QA_DEADLINE,
    QA_RESPONSE_DEADLINE,
    RELEASE_PROJECT_DATE,
    START_DATE,
} = projectFilterTypesDict;

const { BETWEEN } = projectDateFilterTypesDict;

const { FILTERS } = fieldNamesDict;

export function validate(values) {
    const filters = values[FILTERS] || [];

    const formErrors = {};

    const filterErrors = filters.map((filter) => {
        const currentFilterErrors = {};

        if (!filter.type) {
            currentFilterErrors.type = 'A type is required';
        }

        switch (filter.type) {
            case DEPARTMENT_ID:
            case RETENTION_CODE_ID:
            case STATUS:
            case TEMPLATE_ID:
                if (!filter.value && filter.value !== 0) {
                    currentFilterErrors.value = 'A value is required';
                }
                break;
            case IS_PAUSED:
                if (filter.value !== true && filter.value !== false) {
                    currentFilterErrors.value = 'A value is required';
                }
                break;
            case TITLE:
            case USER:
                break;
            // Dates
            case CONTRACTOR_SELECTED_DATE:
            case CREATED_AT:
            case POSTED_AT:
            case POST_SCHEDULED_AT:
            case PRE_PROPOSAL_DATE:
            case PROPOSAL_DEADLINE:
            case QA_DEADLINE:
            case QA_RESPONSE_DEADLINE:
            case RELEASE_PROJECT_DATE:
            case START_DATE:
                if (!filter.dateFilterType) {
                    currentFilterErrors.dateFilterType = 'A date filter range type is required';
                } else if (filter.dateFilterType === BETWEEN) {
                    currentFilterErrors.value = [null, null];

                    if (!filter.value[0]) {
                        currentFilterErrors.value[0] = 'A value is required';
                    }
                    if (!filter.value[1]) {
                        currentFilterErrors.value[1] = 'A value is required';
                    }

                    if (filter.value[0] && filter.value[1]) {
                        const firstDate = filter.value[0];
                        const secondDate = filter.value[1];

                        if (firstDate > secondDate) {
                            currentFilterErrors.value[0] =
                                'The first date must be before the second date';
                        }
                    }
                } else if (!filter.value) {
                    currentFilterErrors.value = 'A value is required';
                }
                break;
            default:
                break;
        }

        return currentFilterErrors;
    });

    formErrors[FILTERS] = filterErrors;

    return formErrors;
}
