import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

export const FiltersCountBadge = ({ value = 0 }) => {
    const styles = require('./index.scss');

    return (
        <div className={classNames(styles.filtersCount, value === 0 && styles.filtersCountZero)}>
            <span>{value}</span>
        </div>
    );
};

FiltersCountBadge.propTypes = {
    value: PropTypes.number,
};
