import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Outlet } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { loadUsers, shouldLoadUsers } from '../../../actions/admin';
import { hideConnectionAlert } from '../../../actions/notification';
import connectData from '../../ConnectData';
import { getShowConnectionAlert, getUserJS } from '../../selectors';
import { FLAGS, withFlags } from '../../../lib/launchdarkly';
import { getSFChatConfig } from '../../../constants/salesForceEmbeddedService';
import { getVendorChat } from '../../../helpers/salesForceChatHelper';

function fetchData(getState, dispatch) {
    const shouldRequestUsers = shouldLoadUsers(getState());
    if (!shouldRequestUsers) return Promise.resolve();

    const orgId = getState().auth.getIn(['user', 'organization', 'id']);
    return dispatch(loadUsers(orgId));
}

const mapStateToProps = (state) => {
    return {
        loadingUsers: state.admin.get('loadingUsers'),
        loadUsersError: state.admin.get('loadUsersError'),
        shouldShowConnectionAlert: getShowConnectionAlert(state),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    hideConnectionAlert,
};

// @connectData
// @connect
class ConnectedApp extends Component {
    static propTypes = {
        getFlag: PropTypes.func.isRequired,
        hideConnectionAlert: PropTypes.func.isRequired,
        params: PropTypes.shape({
            checklistId: PropTypes.string,
        }).isRequired,
        shouldShowConnectionAlert: PropTypes.bool.isRequired,
        user: PropTypes.object.isRequired,
    };

    componentDidMount() {
        const { user } = this.props;
        const enableServiceCloud = this.props.getFlag(FLAGS.SHOW_SERVICE_CLOUD_CHAT_BOT);

        if (enableServiceCloud && user && (__PROD__ || __STAGING__ || __INT__)) {
            const script = document.createElement('script');
            const chatConfig = getSFChatConfig();
            script.src = chatConfig.VENDOR_BOOTSTRAP_URL;

            script.onload = getVendorChat(user);
            document.body.appendChild(script);
        }

        if (window.analytics && user) {
            const {
                user: { organization },
            } = this.props;

            const identityProps = pick(user, [
                'city',
                'email',
                'firstName',
                'id',
                'lastLogin',
                'lastName',
                'phone',
                'role',
                'state',
                'title',
            ]);

            const userInfo = {
                address: user.address1,
                createdAt: user.created_at,
                name: user.fullName,
                organizationId: user.organization_id,
                salesforceId: organization.salesforceId,
                vendorId: user.vendor_id,
            };

            if (enableServiceCloud) {
                window.analytics.identify(user.email, {
                    ...identityProps,
                    ...userInfo,
                });
            } else {
                window.analytics.identify(
                    user.email,
                    {
                        ...identityProps,
                        ...userInfo,
                    },
                    {
                        Intercom: {
                            user_hash: user.intercomUserHash,
                        },
                    }
                );
            }

            const groupProps = pick(organization, [
                'city',
                'id',
                'isActive',
                'isInternal',
                'isVendor',
                'name',
                'ogEntityId',
                'ogEntityUuid',
                'salesforceId',
                'state',
                'timezone',
                'website',
            ]);
            window.analytics.group(`vend-${organization.id}`, {
                ...groupProps,
                address: organization.address1,
                createdAt: organization.created_at,
                vendorId: user.vendor_id,
            });

            // Track user identified event with app version
            window.analytics.track('User Identified', {
                appVersion: process.env.DIST_VERSION,
            });
        }
    }

    componentWillUnmount() {
        const { shouldShowConnectionAlert } = this.props;

        if (shouldShowConnectionAlert) {
            this.props.hideConnectionAlert();
        }
    }

    render() {
        const { user } = this.props;

        // On logout, the underlying component can get upset when no user is
        // specified, so prevent the component from rendering instead.
        if (!user) {
            return null;
        }

        return (
            <div className="container-fluid">
                <Outlet />
            </div>
        );
    }
}

export const App = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withFlags()
)(ConnectedApp);
