import { capitalize, findKey } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Panel } from 'react-bootstrap';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { LoadingSpinner, Nav, NavItem, NotFound, SectionTitle } from '../../../components';
import { NotificationsTable } from './NotificationsTable';
import { getUserJS } from '../../selectors';
import {
    loadInboxNotificationEmail,
    getInboxNotifications,
    updateInboxNotifications,
} from '../../../actions/inboxNotifications';
import { getInboxNotificationsJS } from './selectors';
import {
    inboxNotificationCategoryNames,
    inboxNotificationStatusesDict,
} from '../../../../../shared_config/audits';

const { COMPLETED, IN_PROGRESS, NEW } = inboxNotificationStatusesDict;

const mapStateToProps = (state) => {
    return {
        inboxNotifications: getInboxNotificationsJS(state),
        loadedInboxNotifications: state.inboxNotifications.get('loaded'),
        loadingInboxNotifications: state.inboxNotifications.get('loading'),
        updatingInboxNotifications: state.inboxNotifications.get('updating'),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    loadInboxNotificationEmail,
    getInboxNotifications,
    updateInboxNotifications,
};

// @connect
const ConnectedNotificationsInbox = ({
    inboxNotifications,
    loadedInboxNotifications,
    loadingInboxNotifications,
    updatingInboxNotifications,
    user: {
        government_id: govId,
        organization: { timezone },
    },
    ...props
}) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { tab, emailNotifId } = Object.fromEntries(searchParams);

    useEffect(() => {
        props.getInboxNotifications();
    }, []);

    const inboxNotificationsKeysArr = Object.keys(inboxNotifications);
    const defaultActiveTab = inboxNotificationsKeysArr[0];

    useEffect(() => {
        if (loadedInboxNotifications && !tab) {
            const tabToSet = findKey(inboxNotifications, (notifications) => {
                return notifications[NEW].length > 0 || notifications[IN_PROGRESS].length > 0;
            });
            navigate(
                `/governments/${govId}/notifications-inbox?tab=${tabToSet || defaultActiveTab}`
            );
        }
    }, [loadedInboxNotifications, tab]);

    const styles = require('./index.scss');

    const activeTab = Number.parseInt(tab || defaultActiveTab, 10);
    const notificationId = Number.parseInt(emailNotifId, 10);

    if (!inboxNotifications[activeTab]) {
        return <NotFound />;
    }

    return (
        <Panel id="skip">
            <Panel.Body>
                <SectionTitle
                    help={
                        'Notifications you receive via email will also be displayed here. ' +
                        'You may move these to "In Progress" and "Completed" to track the progress ' +
                        'of assigned tasks. Once a project is closed or contract end date is 90 days ' +
                        'in the past, associated notifications will no longer display here.'
                    }
                    helpPlacement="right"
                    title="Notifications Inbox"
                />
                <Nav bsStyle="tabs" className={styles.tabsNav}>
                    {inboxNotificationsKeysArr.map((key) => {
                        const categoryNum = parseInt(key, 10);
                        const categoryTitle = inboxNotificationCategoryNames[categoryNum];
                        const newItemsNum = inboxNotifications[categoryNum][NEW].length;

                        return (
                            <NavItem
                                className={activeTab === categoryNum ? 'active' : undefined}
                                key={categoryTitle}
                                overrideIsActive={false}
                                qaTag={`notifications-${categoryTitle}`}
                                to={{
                                    pathname,
                                    search: createSearchParams({ tab: categoryNum }).toString(),
                                }}
                            >
                                {`${capitalize(categoryTitle)}${
                                    newItemsNum ? ` (${newItemsNum})` : ''
                                }`}
                            </NavItem>
                        );
                    })}
                </Nav>
                {loadingInboxNotifications && <LoadingSpinner />}
                {Object.keys(inboxNotifications[activeTab]).map((status) => (
                    <div className={styles.notificationsTable} key={`${status}-${activeTab}`}>
                        <NotificationsTable
                            collapsed={status === COMPLETED}
                            displayOnLoadNotification={status === COMPLETED}
                            inboxNotifications={inboxNotifications[activeTab][status]}
                            loadInboxNotificationEmail={props.loadInboxNotificationEmail}
                            onLoadNotificationId={notificationId}
                            tableType={status}
                            timezone={timezone}
                            updateInboxNotifications={props.updateInboxNotifications}
                            updatingInboxNotifications={updatingInboxNotifications}
                        />
                    </div>
                ))}
            </Panel.Body>
        </Panel>
    );
};

ConnectedNotificationsInbox.propTypes = {
    inboxNotifications: PropTypes.object.isRequired,
    loadInboxNotificationEmail: PropTypes.func.isRequired,
    getInboxNotifications: PropTypes.func.isRequired,
    loadedInboxNotifications: PropTypes.bool.isRequired,
    loadingInboxNotifications: PropTypes.bool.isRequired,
    updateInboxNotifications: PropTypes.func.isRequired,
    updatingInboxNotifications: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        government_id: PropTypes.number.isRequired,
        organization: PropTypes.shape({
            timezone: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export const NotificationsInbox = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedNotificationsInbox);
