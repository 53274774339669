import { pick } from 'lodash';
import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

import { getUserJS } from '../../selectors';
import { getProjectJS } from '../selectors';
import { form, fields, fieldNames, dateFields } from './constants';
import { deserializeProjectDates } from '../../../helpers';
import { sectionTypeNames } from '../../../../../shared_config/sections';

const { QUESTIONNAIRE } = sectionTypeNames;

const { QUESTIONNAIRES } = fieldNames;

const formSelector = formValueSelector(form);

export const getQuestionnaireFormValues = (state) => formSelector(state, QUESTIONNAIRES) || [];

// This deserializer's main function is to convert dates.
// This makes it extremely easy to set initial values for redux-forms
export const getDeserializedProject = createSelector([getProjectJS, getUserJS], (project, user) => {
    if (!project) return null;

    // redux-forms submits all initial data passed in. Omit fields we do
    // not want to be manipulated, so they will not be part of submit data
    const cleansedProjectData = pick(project, [
        ...fields,
        'id',
        'isPostOnly',
        'projectSections',
        'status',
        'template',
        'wasPosted',
        'questionLogics', // had to add this so that we can add vendor conditional questions in the post create screen
    ]);

    // Convert project dates from UTC strings to the user's time as date objects
    const { timezone } = user.organization;
    const projectDates = deserializeProjectDates(cleansedProjectData, dateFields, timezone);

    return {
        ...cleansedProjectData,
        ...projectDates,
    };
});

/**
 * DGW 2-3-19 TODO:
 * This selector will quickly become unneeded as all posts will have a questionnaire
 * going forward. It's only needed right now in case there are existing projects built without
 * the questionnaire that need to be edited. Should be safe to remove in May 2019.
 */
export const hasQuestionnaire = createSelector([getProjectJS], (project) => {
    if (!project) return true;

    return !!project.projectSections.find((section) => section.section_type === QUESTIONNAIRE);
});
