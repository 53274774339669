import { createSelector } from 'reselect';

import { projectStatusesDict } from '../../../../../shared_config/projects';
import { proposalStatusesDict } from '../../../../../shared_config/proposals';

const { REVERSE_AUCTION } = projectStatusesDict;
const { NO_BID } = proposalStatusesDict;

const getProposals = (state) => state.vendProposals.get('list');
const getFollowedProjects = (state) => state.vendProposals.get('followedProjects');

export const getProposalsJS = createSelector([getProposals], (rawProposals) => {
    if (rawProposals) {
        return rawProposals.toJS();
    }
    return [];
});

export const getFollowedProjectsJS = createSelector([getFollowedProjects], (rawProjects) => {
    if (rawProjects) {
        return rawProjects.toJS();
    }
    return [];
});

export const getLiveReverseAuctionList = createSelector([getProposalsJS], (proposals) => {
    return proposals
        .filter((proposal) => {
            const {
                isDisqualified,
                isExcluded,
                project: { status: projectStatus },
                status: proposalStatus,
            } = proposal;

            return (
                projectStatus === REVERSE_AUCTION &&
                proposalStatus !== NO_BID &&
                !isDisqualified &&
                !isExcluded
            );
        })
        .map((proposal) => ({
            auctionEndDate: proposal.project.auctionEndDate,
            govTimezone: proposal.project.government.organization.timezone,
            projectId: proposal.project_id,
            proposalId: proposal.id,
            title: proposal.project.title,
            vendorId: proposal.vendor_id,
        }));
});
