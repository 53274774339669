import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { isDirty, reduxForm } from 'redux-form';

import { TemplateSectionBroadcastModal } from './TemplateSectionBroadcastModal';
import { TemplateSectionTitleMenu } from './TemplateSectionTitleMenu';
import {
    getTemplateSection,
    getTemplateSectionTagOptions,
    getTemplateSectionFormValues,
} from './selectors';
import { TemplateSectionForm } from './TemplateSectionForm';
import { form } from './TemplateSectionForm/constants';
import { validate } from './TemplateSectionForm/validate';
import { getTemplateSectionsPath } from '../selectors';
import { getTemplateSectionVariableOptions } from '../../constants';
import connectData from '../../../ConnectData';
import { isInitialClientLoaded, getUserJS } from '../../../selectors';
import { loadTemplateQuestionsList } from '../../../../actions/templateQuestions';
import {
    loadTemplateSection,
    resetTemplateSectionEdit,
    updateTemplateSection,
} from '../../../../actions/templateSections';
import {
    Button,
    LoadingError,
    LoadingSpinner,
    Main,
    RouteLeaveWarning,
} from '../../../../components';
import { getTemplateQuestions } from '../../../../selectors/govApp';
import { usesDocxHeadingFormatters } from '../../selectors';

const fetchData = (getState, dispatch, location, params) => {
    const templateSectionId = Number.parseInt(params.templateSectionId, 10);
    return Promise.all([
        dispatch(loadTemplateSection(templateSectionId)),
        dispatch(loadTemplateQuestionsList()),
    ]);
};

const mapStateToProps = (state) => {
    const isClientLoaded = isInitialClientLoaded(state);
    const templateSection = getTemplateSection(state);
    // 8/1/2023:
    // the -potential- usage of manual numbering is now determined by this gov setting
    // which when true enables you to use manual numbers / dividers
    const useManualNumbering = usesDocxHeadingFormatters(state);

    return {
        initialValues: isClientLoaded ? templateSection : undefined,
        templateSection,
        useManualNumbering, // Added here so it can also be passed to the validate function
    };
};

const formConfig = {
    enableReinitialize: true,
    form,
    validate,
};

// @connect
// @connectData
// @reduxForm
const ConnectedTemplateSectionEdit = (props) => {
    const params = useParams();
    const [showFormValidation, setShowFormValidation] = useState(false);
    const [showBroadcastModal, setShowBroadcastModal] = useState(false);

    const dispatch = useDispatch();
    const user = useSelector(getUserJS);
    const loading = useSelector((state) => state.templateSections.get('loadingItem'));
    const loadError = useSelector((state) => state.templateSections.get('loadItemError'));

    const checkingOut = useSelector((state) => state.templateSections.get('checkingOutItem'));
    const copying = useSelector((state) => state.templateSections.get('copyingItem'));
    const deleting = useSelector((state) => state.templateSections.get('deletingItem'));
    const updating = useSelector((state) => state.templateSections.get('updatingItem'));

    const templateSectionsPath = useSelector(() => getTemplateSectionsPath({ params }));
    const dirty = useSelector((state) => isDirty(form)(state));
    const formValues = useSelector(getTemplateSectionFormValues);

    const tagOptions = useSelector(getTemplateSectionTagOptions);
    const templateQuestions = useSelector(getTemplateQuestions);
    const templateVariableOptions = useSelector(() =>
        getTemplateSectionVariableOptions({
            templateQuestions,
            templateSection: formValues,
        })
    );
    RouteLeaveWarning({ blockingValue: dirty });

    const { initialize, useManualNumbering } = props;

    useEffect(() => {
        /**
         * This form does not properly initialize on the server. As a workaround, we always
         * initialize on the client after mount.
         */
        dispatch(initialize(props.templateSection)); // Initializes redux-form
    }, []);

    useEffect(() => {
        return () => dispatch(resetTemplateSectionEdit());
    }, []);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (loadError || !props.templateSection) {
        return <LoadingError error={loadError} />;
    }

    const {
        array,
        change,
        handleSubmit,
        submitFailed,
        templateSection,
        templateSection: { checked_out_user_id: checkedOutUserId, id, isPublished, projectSection },
    } = props;

    const updateTemplateSectionFn = (data) => {
        return dispatch(updateTemplateSection(id, data));
    };

    const toggleLive = () => {
        if (isPublished) {
            const submitData = {
                ...formValues,
                isPublished: false,
            };
            return updateTemplateSectionFn(submitData);
        }

        setShowFormValidation(true);

        return handleSubmit((data) => {
            const submitData = {
                ...data,
                isPublished: true,
            };
            setShowFormValidation(false);
            return updateTemplateSectionFn(submitData);
        })();
    };

    const saveTemplateSection = (additionalData) => {
        // If the template has been published we want to validate the form before submitting
        if (isPublished) {
            setShowFormValidation(true);
            return handleSubmit((data) => {
                setShowFormValidation(false);
                return updateTemplateSectionFn({ ...data, ...additionalData });
            })();
        }

        return updateTemplateSectionFn(formValues);
    };

    const isCheckedOutByUser = user.id === checkedOutUserId;
    const actionMenuDisabled = checkingOut || copying || deleting || updating;
    const contentDisabled = actionMenuDisabled || !isCheckedOutByUser;

    return (
        <Main>
            <div>
                <Button
                    bsSize="sm"
                    bsStyle="link"
                    qaTag="sharedSectionEdit-back"
                    to={templateSectionsPath}
                >
                    <i className="fa fa-angle-left" /> Shared Sections
                </Button>
            </div>
            <TemplateSectionTitleMenu
                deleting={deleting}
                disabled={actionMenuDisabled}
                setShowFormValidation={setShowFormValidation}
                showBroadcastModal={() => setShowBroadcastModal(true)}
                submitFailed={submitFailed}
                templateSection={templateSection}
                templateSectionsPath={templateSectionsPath}
                title={get(formValues, 'projectSection.title')}
                toggleLive={toggleLive}
                updateTemplateSection={saveTemplateSection}
                updating={updating}
            />
            <TemplateSectionForm
                array={array}
                change={change}
                disabled={contentDisabled}
                projectSection={projectSection}
                showFormValidation={showFormValidation}
                tagOptions={tagOptions}
                templateSectionId={id}
                templateVariableOptions={templateVariableOptions}
                useManualNumbering={useManualNumbering}
            />
            {showBroadcastModal && (
                <TemplateSectionBroadcastModal
                    disabled={contentDisabled}
                    hideModal={() => setShowBroadcastModal(false)}
                    submitFailed={submitFailed}
                    templateSection={templateSection}
                    updateTemplateSection={saveTemplateSection}
                />
            )}
        </Main>
    );
};

ConnectedTemplateSectionEdit.propTypes = {
    array: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    submitFailed: PropTypes.bool.isRequired,
    templateSection: PropTypes.shape({
        checked_out_user_id: PropTypes.number,
        id: PropTypes.number.isRequired,
        isPublished: PropTypes.bool.isRequired,
        projectSection: PropTypes.object.isRequired,
    }),
    useManualNumbering: PropTypes.bool.isRequired,
};

export const TemplateSectionEdit = compose(
    connectData(fetchData),
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedTemplateSectionEdit);
