import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field } from 'redux-form';

import {
    ALL_SECTIONS,
    fieldNames,
    FORM_SECTIONS_KEY,
    formSectionNames,
    formSections,
    incompleteSectionInstructions,
} from './constants';
import { generateReviewSequenceStep } from './helper';
import { ReviewSequenceInfo } from './ReviewSequenceInfo';
import { ReviewSequenceInfoSection } from './ReviewSequenceInfoSection';
import { ReviewSequenceSteps } from './ReviewSequenceSteps';
import { ReviewSequenceStepsSection } from './ReviewSequenceStepsSection';
import { SectionContainer, TemplateReviewSection } from '../../../../../components';

const { REVIEW_SEQUENCE_STEPS } = fieldNames;
const { INFO, STEPS } = formSectionNames;

export const ReviewSequenceForm = ({
    array,
    change,
    disabled,
    isPublished,
    reviewSequenceFormValues,
    setShowFormValidation,
    showFormValidation,
    toggleLive,
    updateReviewSequence,
}) => {
    const [openSectionNumber, setOpenSectionNumber] = useState(null);

    const openSectionHandler = (sectionNumber, shouldShowFormValidation = false) => {
        // No section should be selected if the numbers match (open section is being closed)
        const newSectionNumber = sectionNumber === openSectionNumber ? null : sectionNumber;
        setOpenSectionNumber(newSectionNumber);
        setShowFormValidation(shouldShowFormValidation);
    };

    const addReviewSequenceStep = (reviewSequenceStepData) => {
        array.push(REVIEW_SEQUENCE_STEPS, generateReviewSequenceStep(reviewSequenceStepData));
    };

    const formSectionProps = {
        change,
        disabled,
        showFormValidation,
    };

    return (
        <>
            <Field
                component={SectionContainer}
                info={<ReviewSequenceInfo reviewSequence={reviewSequenceFormValues} />}
                isOpen={openSectionNumber === 1}
                name={`${FORM_SECTIONS_KEY}.${INFO}`}
                number={1}
                onClick={openSectionHandler}
                title="Design your sequence"
            >
                <ReviewSequenceInfoSection {...formSectionProps} />
            </Field>
            <Field
                component={SectionContainer}
                info={
                    <ReviewSequenceSteps
                        reviewSequenceSteps={get(
                            reviewSequenceFormValues,
                            REVIEW_SEQUENCE_STEPS,
                            []
                        )}
                    />
                }
                isOpen={openSectionNumber === 2}
                name={`${FORM_SECTIONS_KEY}.${STEPS}`}
                number={2}
                onClick={openSectionHandler}
                title="Configure your review sequence"
            >
                <ReviewSequenceStepsSection
                    addReviewSequenceStep={addReviewSequenceStep}
                    {...formSectionProps}
                />
            </Field>
            {!isPublished && (
                <Field
                    component={SectionContainer}
                    isOpen={openSectionNumber === 3}
                    name={ALL_SECTIONS}
                    number={3}
                    onClick={openSectionHandler}
                    title="Review your sequence and set it live"
                >
                    <Field
                        component={TemplateReviewSection}
                        disabled={disabled}
                        formSectionKey={FORM_SECTIONS_KEY}
                        formSections={formSections}
                        incompleteSectionInstructions={incompleteSectionInstructions}
                        name={ALL_SECTIONS}
                        openSectionHandler={openSectionHandler}
                        readyText="This sequence will be available for use by your team as soon as it's live"
                        saveHandler={updateReviewSequence}
                        setLiveHandler={toggleLive}
                    />
                </Field>
            )}
        </>
    );
};

ReviewSequenceForm.propTypes = {
    array: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isPublished: PropTypes.bool.isRequired,
    reviewSequenceFormValues: PropTypes.object.isRequired,
    setShowFormValidation: PropTypes.func.isRequired,
    showFormValidation: PropTypes.bool,
    toggleLive: PropTypes.func.isRequired,
    updateReviewSequence: PropTypes.func.isRequired,
};
