import { uniq } from 'lodash';

import { showSnackbar } from './notification';
import { resourceManager } from '../helpers';

export const COPY_SEQUENCE = 'reviewSequences/COPY_SEQUENCE';
export const COPY_SEQUENCE_FAIL = 'reviewSequences/COPY_SEQUENCE_FAIL';
export const COPY_SEQUENCE_SUCCESS = 'reviewSequences/COPY_SEQUENCE_SUCCESS';

export const DELETE_SEQUENCE = 'reviewSequences/DELETE_SEQUENCE';
export const DELETE_SEQUENCE_FAIL = 'reviewSequences/DELETE_SEQUENCE_FAIL';
export const DELETE_SEQUENCE_SUCCESS = 'reviewSequences/DELETE_SEQUENCE_SUCCESS';

export const LOAD_SEQUENCE = 'reviewSequences/LOAD_SEQUENCE';
export const LOAD_SEQUENCE_FAIL = 'reviewSequences/LOAD_SEQUENCE_FAIL';
export const LOAD_SEQUENCE_SUCCESS = 'reviewSequences/LOAD_SEQUENCE_SUCCESS';

export const LOAD_SEQUENCES = 'reviewSequences/LOAD_SEQUENCES';
export const LOAD_SEQUENCES_FAIL = 'reviewSequences/LOAD_SEQUENCES_FAIL';
export const LOAD_SEQUENCES_SUCCESS = 'reviewSequences/LOAD_SEQUENCES_SUCCESS';

export const RESET_SEQUENCE_EDIT = 'reviewSequences/RESET_SEQUENCE_EDIT';
export const RESET_SEQUENCES = 'reviewSequences/RESET_SEQUENCES';

export const SET_DEFAULT = 'reviewSequences/SET_DEFAULT';
export const SET_DEFAULT_FAIL = 'reviewSequences/SET_DEFAULT_FAIL';
export const SET_DEFAULT_SUCCESS = 'reviewSequences/SET_DEFAULT_SUCCESS';

export const UPDATE_SEQUENCE = 'reviewSequences/UPDATE_SEQUENCE';
export const UPDATE_SEQUENCE_FAIL = 'reviewSequences/UPDATE_SEQUENCE_FAIL';
export const UPDATE_SEQUENCE_SUCCESS = 'reviewSequences/UPDATE_SEQUENCE_SUCCESS';
export const UPDATE_SEQUENCE_STOP = 'reviewSequences/UPDATE_SEQUENCE_STOP';

export const copyReviewSequence = (reviewSequenceId, opts = {}) => {
    return resourceManager({
        method: 'post',
        url: `/requisitions/review-sequences/${reviewSequenceId}/copy`,
        onStart: ({ dispatch }) => dispatch({ type: COPY_SEQUENCE }),
        onSuccess: ({ result, dispatch }) => {
            dispatch(showSnackbar('Sequence copied!'));
            dispatch({ type: COPY_SEQUENCE_SUCCESS, result });
            if (opts.onSuccess) {
                opts.onSuccess(result);
            }
            return result;
        },
        onFailure: ({ error, dispatch }) => dispatch({ type: COPY_SEQUENCE_FAIL, error }),
    });
};

export const createReviewSequence = (data) => {
    return resourceManager({
        method: 'post',
        requestOptions: { data },
        url: '/requisitions/review-sequences',
        onSuccess: ({ result, dispatch }) => {
            dispatch(showSnackbar('Sequence created!'));
            return result;
        },
    });
};

export const deleteReviewSequence = (reviewSequenceId, opts = {}) => {
    return resourceManager({
        method: 'del',
        url: `/requisitions/review-sequences/${reviewSequenceId}`,
        onStart: ({ dispatch }) => dispatch({ type: DELETE_SEQUENCE }),
        onSuccess: ({ result, dispatch }) => {
            dispatch(showSnackbar('Sequence deleted'));
            dispatch({ type: DELETE_SEQUENCE_SUCCESS, result });
            if (opts.onSuccess) {
                return opts.onSuccess(result);
            }
            return result;
        },
        onFailure: ({ error, dispatch }) => dispatch({ type: DELETE_SEQUENCE_FAIL, error }),
    });
};

export const loadReviewSequence = (reviewSequenceId) => {
    return resourceManager({
        method: 'get',
        url: `/requisitions/review-sequences/${reviewSequenceId}`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD_SEQUENCE }),
        onSuccess: ({ result, dispatch }) => dispatch({ type: LOAD_SEQUENCE_SUCCESS, result }),
        onFailure: ({ error, dispatch }) => dispatch({ type: LOAD_SEQUENCE_FAIL, error }),
    });
};

export const loadReviewSequences = (params, opts = {}) => {
    const { setLoaded } = opts;
    return resourceManager({
        method: 'get',
        requestOptions: { params },
        url: '/requisitions/review-sequences',
        onStart: ({ dispatch }) => dispatch({ type: LOAD_SEQUENCES }),
        onSuccess: ({ result, dispatch }) =>
            dispatch({ type: LOAD_SEQUENCES_SUCCESS, result, setLoaded }),
        onFailure: ({ error, dispatch }) => dispatch({ type: LOAD_SEQUENCES_FAIL, error }),
    });
};

export const resetReviewSequenceEdit = () => {
    return { type: RESET_SEQUENCE_EDIT };
};

export const resetReviewSequences = () => {
    return { type: RESET_SEQUENCES };
};

export const setDefaultReviewSequence = (reviewSequenceId) => {
    return resourceManager({
        method: 'post',
        url: `/requisitions/review-sequences/${reviewSequenceId}/default`,
        onStart: ({ dispatch }) => dispatch({ type: SET_DEFAULT }),
        onSuccess: ({ result, dispatch }) => {
            dispatch(showSnackbar('Sequence set as default!'));
            dispatch({ type: SET_DEFAULT_SUCCESS, result });
        },
        onFailure: ({ error, dispatch }) => dispatch({ type: SET_DEFAULT_FAIL, error }),
    });
};

export const updateReviewSequence = (reviewSequenceId, data, opts = {}) => {
    return resourceManager({
        method: 'put',
        requestOptions: { data },
        url: `/requisitions/review-sequences/${reviewSequenceId}`,
        onStart: ({ dispatch }) => dispatch({ type: UPDATE_SEQUENCE }),
        onSuccess: ({ result, dispatch }) => {
            dispatch({ type: UPDATE_SEQUENCE_SUCCESS, result });
            dispatch(showSnackbar(opts.successMessage || 'Sequence saved'));
            if (opts.onSuccess) {
                return opts.onSuccess(result);
            }
            return result;
        },
        onFailure: ({ error, dispatch }) => dispatch({ type: UPDATE_SEQUENCE_FAIL, error }),
    });
};

export const validateReviewGroups = (reviewSequenceId, reviewSequenceData) => {
    const positionIds = uniq(
        reviewSequenceData.reviewSequenceSteps.flatMap(({ reviewSequenceStepPositions }) =>
            reviewSequenceStepPositions.map(({ position_id: positionId }) => positionId)
        )
    );

    return resourceManager({
        method: 'post',
        requestOptions: { data: { positionIds } },
        url: `/requisitions/review-sequences/${reviewSequenceId}/review-groups/validate`,
        onStart: ({ dispatch }) => dispatch({ type: UPDATE_SEQUENCE }),
        onSuccess: ({ result, dispatch }) => {
            dispatch({ type: UPDATE_SEQUENCE_STOP });
            return result;
        },
        onFailure: ({ error, dispatch }) => {
            dispatch({ type: UPDATE_SEQUENCE_FAIL, error });
            throw error;
        },
    });
};
