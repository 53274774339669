import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, IconButton, Typography } from '@og-pro/ui';
import { Close as CloseIcon, FileUpload as FileUploadIcon } from '@mui/icons-material';

import { DynamsoftDocumentScanner, ProgressBar } from '../../../../../../components';
import { requisitionsCreateFormValueSelector } from '../../selectors';
import { deleteAttachment, uploadAttachment } from '../../../../../../actions/requisitions';
import { qaTagPageName } from '../../../constants';
import { UploadDropzone } from './UploadDropzone';
import { FLAGS, useFlags } from '../../../../../../lib/launchdarkly';
import { fieldNames } from '../../constants';

const { ATTACHMENTS } = fieldNames;
export const RequisitionAttachment = ({
    disabled,
    fieldNamePrefix,
    title,
    canRemove,
    removeFn,
    renderAsDropPanel,
}) => {
    const styles = require('./index.scss');

    const fieldName = fieldNamePrefix ? `${fieldNamePrefix}.${ATTACHMENTS}` : ATTACHMENTS;

    const [success, setSuccess] = useState(false);
    const [fileToUpload, setFileToUpload] = useState();
    const [uploadError, setUploadError] = useState();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const dispatch = useDispatch();
    const enableScanning = useFlags(FLAGS.ATTACHMENTS_SCANNING);
    const maxFileSize = 50;
    const labelId = 'Upload Attachment Label';
    const { vendorAssignmentUuid } = useSelector(
        (state) => requisitionsCreateFormValueSelector(state, fieldName) || {}
    );
    const requisitionId = useSelector((state) => requisitionsCreateFormValueSelector(state, 'id'));
    const attachments = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, fieldName)
    );

    const handleUpload = (files) => {
        const file = files[0];
        setFileToUpload(file);
        setUploadProgress(0);
        setUploading(true);
        setSuccess(false);
        setUploadError(null);
        dispatch(
            uploadAttachment(
                file,
                {
                    title: file.name,
                    requisitionId,
                    vendorAssignmentUuid,
                    fieldName,
                    attachments,
                },
                setUploadProgress
            )
        );
    };

    useEffect(() => {
        if (uploadProgress === 100) {
            setSuccess(true);
            setFileToUpload(null);
        }
        setTimeout(() => {
            setUploading(false);
        }, 2000);
        setTimeout(() => {
            setSuccess(false);
        }, 7000);
    }, [uploadProgress]);

    const [openedScanDialog, setOpenedScanDialog] = useState(false);

    const handleDelete = (attachment) => {
        dispatch(
            deleteAttachment(attachment.file, { attachment, fieldName, attachments, requisitionId })
        );
    };
    return (
        <Box className={styles.uploadBox} pl={0}>
            {disabled ? (
                <label>Uploaded Documents</label>
            ) : (
                <label>
                    {title}&nbsp;
                    {canRemove && (
                        <IconButton
                            onClick={removeFn}
                            qaTag={`requisitionCreate-${fieldName}-remove`}
                            size="small"
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    )}
                </label>
            )}

            {!uploading && !disabled && (
                <Box
                    className={
                        renderAsDropPanel ? styles.dropPanelContainer : styles.buttonContainer
                    }
                >
                    <Dialog
                        dialogTitle="Scan Documents"
                        fullWidth
                        maxWidth="xl"
                        onClose={() => setOpenedScanDialog(false)}
                        open={openedScanDialog}
                    >
                        <DynamsoftDocumentScanner
                            onClose={() => setOpenedScanDialog(false)}
                            onUpload={(files) => {
                                setOpenedScanDialog(false);
                                handleUpload(files);
                            }}
                        />
                    </Dialog>
                    <UploadDropzone
                        dropHandler={handleUpload}
                        labelId={labelId}
                        maxFileSize={maxFileSize}
                    >
                        <Box
                            alignItems="center"
                            display="flex"
                            justifyContent="space-between"
                            p={renderAsDropPanel ? 1 : 0}
                        >
                            <Box alignItems="center" display="flex">
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent={renderAsDropPanel ? 'center' : 'flex-start'}
                                >
                                    {renderAsDropPanel && (
                                        <>
                                            <FileUploadIcon fontSize="inherit" />
                                            <Typography>
                                                {' '}
                                                Drag a file here{enableScanning ? ', ' : ' or '}
                                            </Typography>
                                        </>
                                    )}
                                    <Button
                                        className={
                                            renderAsDropPanel ? styles.uploadButtonAsLink : ''
                                        }
                                        color="primary"
                                        onClick={(e) => e.preventDefault()}
                                        qaTag={`${qaTagPageName}-upload`}
                                        variant={renderAsDropPanel ? 'text' : 'outlined'}
                                    >
                                        {renderAsDropPanel ? 'select a file to upload' : 'Upload'}
                                    </Button>
                                </Box>
                                {enableScanning && (
                                    <Box alignItems="baseline" display="flex">
                                        <Typography> or </Typography>
                                        <Button
                                            className={
                                                renderAsDropPanel
                                                    ? styles.uploadButtonAsLink
                                                    : styles.scanLinkButton
                                            }
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setOpenedScanDialog(true);
                                            }}
                                            qaTag={`${qaTagPageName}-scan`}
                                            variant="text"
                                        >
                                            Scan Documents
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                            {success && (
                                <Box className={styles.success}>
                                    Upload Successful <i className="fa fa-check-circle" />
                                </Box>
                            )}
                        </Box>
                    </UploadDropzone>
                </Box>
            )}

            {uploading && (
                <Box>
                    <ProgressBar now={uploadProgress} useOpenGovStyle />
                    <div className={styles.uploadingLabel}>Uploading {fileToUpload?.name}</div>
                </Box>
            )}
            {uploadError && <div className="col-xs-12 text-center text-danger">{uploadError}</div>}
            {attachments && attachments.length > 0 && (
                <div className={classnames('col-xs-12', styles.attachmentList)}>
                    {attachments.map((attachment) => {
                        return (
                            <div className={styles.attachmentItem} key={attachment.url}>
                                <a href={attachment.url} rel="noopener noreferrer" target="_blank">
                                    <i className="fa fa-file-text-o" />{' '}
                                    {attachment.title || attachment.filename}
                                </a>
                                {!disabled && (
                                    <IconButton
                                        color="primary"
                                        onClick={() => handleDelete(attachment)}
                                        qaTag={`${qaTagPageName}-deleteAttachment`}
                                        size="small"
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                )}
                            </div>
                        );
                    })}
                </div>
            )}
        </Box>
    );
};

RequisitionAttachment.defaultProps = {
    disabled: false,
    title: 'Attachments',
    canRemove: false,
    renderAsDropPanel: false,
    removeFn: () => {},
};

RequisitionAttachment.propTypes = {
    disabled: PropTypes.bool,
    fieldNamePrefix: PropTypes.string,
    title: PropTypes.string,
    canRemove: PropTypes.bool,
    removeFn: PropTypes.func,
    renderAsDropPanel: PropTypes.bool,
};
