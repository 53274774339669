import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import {
    fieldNames,
    form,
    isDescriptionRequiredOptions,
    showAccountInformationOptions,
    useBudgetGroupOptions,
    usePreEncumbranceOptions,
} from './constants';
import { validate } from './validate';
import { LoadingButton, SearchSelect } from '../../../../../components';
import { getIsGovernmentUsingFMS } from '../../../../../selectors/govApp';

const { IS_DESCRIPTION_REQUIRED, USE_BUDGET_GROUP, USE_PRE_ENCUMBRANCE, SHOW_ACCOUNT_INFORMATION } =
    fieldNames;

const formConfig = {
    form,
    validate,
};

const ConnectedRequisitionsSettingsForm = ({ handleSubmit, updateError, updating }) => {
    const hasFMS = useSelector(getIsGovernmentUsingFMS);

    const sharedSearchSelectToggleProps = {
        backspaceRemovesValue: false,
        component: SearchSelect,
        disabled: updating,
        isSearchable: false,
        overrideFeedback: true,
        placeholder: 'Select an option',
    };

    return (
        <form onSubmit={handleSubmit}>
            {hasFMS && (
                <>
                    <Field
                        {...sharedSearchSelectToggleProps}
                        help="Determines whether to include pre-encumbered funds from existing requisitions when performing budget checks"
                        label="Pre-encumbrances in budget check"
                        name={USE_PRE_ENCUMBRANCE}
                        options={usePreEncumbranceOptions}
                    />
                    <Field
                        {...sharedSearchSelectToggleProps}
                        help="Configures the budget detail level to use with requisitions"
                        label="Default budget level"
                        name={USE_BUDGET_GROUP}
                        options={useBudgetGroupOptions}
                    />
                </>
            )}
            <Field
                {...sharedSearchSelectToggleProps}
                help="Specifies whether to require descriptions when creating requisitions"
                label="Requisition Name"
                name={IS_DESCRIPTION_REQUIRED}
                options={isDescriptionRequiredOptions}
            />
            {!hasFMS && (
                <Field
                    {...sharedSearchSelectToggleProps}
                    help="Determines whether to require account information, allow to put account information or just don't allow to add account information on price items"
                    label="Show Account Information"
                    name={SHOW_ACCOUNT_INFORMATION}
                    options={showAccountInformationOptions}
                />
            )}
            <div className="text-center">
                <LoadingButton
                    bsSize="lg"
                    bsStyle="primary"
                    disabled={updating}
                    icon="fa-pencil"
                    loading={updating}
                    qaTag="requisitionSettings-submit"
                    text="Update Settings"
                    type="submit"
                />
                {updateError && <div className="error-block">{updateError}</div>}
            </div>
        </form>
    );
};

ConnectedRequisitionsSettingsForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    updateError: PropTypes.string,
    updating: PropTypes.bool,
};

export const RequisitionsSettingsForm = reduxForm(formConfig)(ConnectedRequisitionsSettingsForm);
