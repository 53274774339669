import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { notificationLevels } from '../../../../shared_config/governmentSubscriptions';

export class StatusColumnRenderer extends Component {
    static propTypes = {
        notificationLevel: PropTypes.number.isRequired,
        params: PropTypes.shape({
            value: PropTypes.string.isRequired,
        }).isRequired,
    };

    render() {
        const { notificationLevel, params } = this.props;

        const icon = notificationLevel === notificationLevels.NONE ? 'fa-bell-slash' : 'fa-filter';

        return (
            <span>
                {params.value}&nbsp;
                <i className={`fa ${icon}`} style={{ fontSize: '0.9em' }} />
            </span>
        );
    }
}
