import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

import { form, fieldNames, evaluationFieldNames } from '../constants';
import { getTotalWeight as getTotalWeightHelper } from '../../../../../../shared_config/evaluations';

const { EVALUATION } = fieldNames;
const { SCORING_CRITERIA } = evaluationFieldNames;

const getEvaluationCreateFormCriteria = (state) =>
    formValueSelector(form)(state, `${EVALUATION}.${SCORING_CRITERIA}`);

export const getTotalWeight = createSelector(
    [getEvaluationCreateFormCriteria],
    (scoringCriteria) => {
        if (scoringCriteria) {
            return getTotalWeightHelper(scoringCriteria);
        }
    }
);
