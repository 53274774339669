import { addressFields } from '../../../constants/userFields';
import { billingAddressFields as billingAddressFieldsArr } from '../../../constants/organizationFields';
import { listToDict } from '../../../../../shared_config/helpers';
import { contactFieldNames } from '../../../../../shared_config/contacts';
import { languageDisplayNameMap, languages } from '../../../../../shared_config/vendorLanguages';

const {
    ADDRESS_1,
    ADDRESS_2,
    CITY,
    COUNTRY,
    COUNTRY_CODE,
    PHONE,
    PHONE_COUNTRY,
    PHONE_EXT,
    STATE,
    ZIP_CODE,
} = contactFieldNames;

export const form = 'organizationInfo';

export const fields = [
    ...addressFields,
    ...billingAddressFieldsArr,
    'description',
    'faxPhone',
    'faxPhoneCountry',
    'faxPhoneExt',
    'name',
    'phone',
    'phoneCountry',
    'phoneExt',
    'useDifferentBillingAddress',
    'vendor',
    'website',
];

export const vendorFields = [
    'businessType',
    'categories',
    'doingBusinessAs',
    'ein',
    'emergencyDisaster',
    'emergencyHealth',
    'emergencyOther',
    'duns',
    'languages',
    'stateOfIncorporation',
    'uniqueEntityId',
];

export const fieldNames = listToDict(fields);
export const vendorFieldNames = listToDict(vendorFields);
export const billingAddressFields = {
    [ADDRESS_1]: 'billingAddress1',
    [ADDRESS_2]: 'billingAddress2',
    [CITY]: 'billingCity',
    [COUNTRY]: 'billingCountry',
    [COUNTRY_CODE]: 'billingCountryCode',
    [STATE]: 'billingState',
    [ZIP_CODE]: 'billingZipCode',
};

export const faxPhoneFields = {
    [PHONE]: 'faxPhone',
    [PHONE_COUNTRY]: 'faxPhoneCountry',
    [PHONE_EXT]: 'faxPhoneExt',
};

export const businessTypeOptions = [
    'C Corporation',
    'S Corporation',
    'Limited Liability C Corporation',
    'Other Corporation',
    'Limited Liability Company (LLC)',
    'Partnership',
    'Limited Liability Partnership',
    'Sole Proprietor/Individual',
    'Single Member/LLC',
    'Trust/Estate',
    'Joint Venture',
    'Non-Profit Organization',
    'Municipal, State, Federal',
    'Other',
].map((type) => ({
    label: type,
    value: type,
}));

export const buildLanguageOptions = (languageList) => {
    if (!languageList || !Array.isArray(languageList)) {
        return [];
    }

    return languageList.map((language) => {
        return {
            label: languageDisplayNameMap[language],
            value: language,
        };
    });
};

export const languageOptions = buildLanguageOptions(languages);

export const emergencyInfoHelpText =
    'An emergency is defined as "a sudden, unexpected occurrence that poses a clear and imminent ' +
    'danger, requiring immediate action to prevent or mitigate the loss or impairment of life, ' +
    'health, property, or essential public services."';

export const privateOrgInfoHelpText =
    'This information is for agency use only and will not be displayed publicly.';

export const listsInfoHelpText = (vendorName) =>
    `Organizations have added ${vendorName} to the following lists 
for organizational purposes. Each organization can only view the lists that they have created.`;
