import PropTypes from 'prop-types';

import { contactFields } from '../../../constants/contactFields';
import { mappedProcurementContactFields } from '../../../../../shared_config/contacts';
import { listToDict } from '../../../../../shared_config/helpers';
import { timelineDates } from '../../../../../shared_config/timelines';

/**
 * Fields included for use as constants throughout the project create form
 */
const baseFields = [
    'attachments',
    'background',
    'budget',
    'categories',
    'contact_id',
    'criteria',
    'department_id',
    'departmentName',
    'departmentHead',
    'departmentHeadTitle',
    'evaluationPhases',
    'financialId',
    'priceTables',
    'procurement_contact_id',
    'questionnaires',
    'rawBackground',
    'rawSummary',
    'requisitionIdentifier',
    'signatures',
    'summary',
    'timelineConfig',
    'timelines',
    'title',
    'upfrontQuestions',
    ...contactFields,
    ...Object.values(mappedProcurementContactFields),
    ...timelineDates,
];
export const fieldNames = listToDict(baseFields);

const criteriaFields = [
    'description',
    'id',
    'instructionData',
    'instructions',
    'instructionType',
    'isReviewed',
    'isTitleLocked',
    'isUnused',
    'isUpload',
    'isUploadRequired',
    'needsReview',
    'orderById',
    'rawDescription',
    'section_type',
    'subsection_type',
    'title',
];
export const criteriaFieldNames = listToDict(criteriaFields);

const priceTableFields = [
    'columnOrder',
    'description',
    'hasDiscount',
    'hasNoBid',
    'hasPercentage',
    'hasQuantity',
    'hasSalesTaxRow',
    'hasTotalRow',
    'headerUnitPrice',
    'id',
    'maxBid',
    'minBid',
    'minBidDecrement',
    'orderById',
    'priceItems',
    'specifyQuantity',
    'specifyUnitPrice',
    'title',
];
export const priceTableFieldNames = listToDict(priceTableFields);

const priceTableItemFields = [
    'description',
    'id',
    'lineItem',
    'orderById',
    'quantity',
    'taxable',
    'unitPrice',
    'unitToMeasure',
];
export const priceTableItemFieldNames = listToDict(priceTableItemFields);

const evaluationPhaseFields = ['id', 'orderById', 'scoringCriteria', 'title'];
export const evaluationPhaseFieldNames = listToDict(evaluationPhaseFields);

const scoringCriteriaFields = [
    'description',
    'id',
    'orderById',
    'scoringMethod',
    'title',
    'weight',
];
export const scoringCriteriaFieldNames = listToDict(scoringCriteriaFields);

const attachmentFields = [
    'appendixId',
    'blobUrl',
    'bucket',
    'filename',
    'id',
    'path',
    'title',
    'type',
    'url',
    'uuid',
];
export const attachmentFieldNames = listToDict(attachmentFields);

export const form = 'projectCreate';

// Fake field used for verifying if entire form is valid
export const ALL_SECTIONS = 'allSections';

export const projectFieldsToOmit = [
    'created_at',
    'copyCount',
    'department',
    'government',
    'government_id',
    'isLibrary',
    'moduleData',
    'projectSections',
    'sectionDescriptions',
    'sections',
    'template',
    'template_id',
    'updated_at',
    'user',
    'user_id',
    'userPermissions',
];

/**
 * `destroyOnUnmount: false` is crucial. This is what lets the data
 * persist when switching routes and makes a multi-page form possible
 */
export const formConfig = {
    form,
    destroyOnUnmount: false,
};

export const projectFormProps = {
    array: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    builderDisplayName: PropTypes.string.isRequired,
    builderSection: PropTypes.object,
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    exportProject: PropTypes.func,
    exportProjectDocument: PropTypes.func,
    form: PropTypes.string.isRequired, // Comes from @reduxForm
    formValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialized: PropTypes.bool.isRequired, // Comes from @reduxForm
    invalid: PropTypes.bool,
    isBudgetRequired: PropTypes.bool.isRequired,
    isBudgetUsed: PropTypes.bool.isRequired,
    isProjectIdRequired: PropTypes.bool.isRequired,
    loaded: PropTypes.bool,
    loadingInitialData: PropTypes.bool.isRequired,
    loadInitialDataError: PropTypes.string,
    markBuilderSectionVisited: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    project: PropTypes.object,
    projectSection: PropTypes.object.isRequired,
    projectSubmitting: PropTypes.bool,
    projectSubsectionsMap: PropTypes.object.isRequired,
    resetScrollPageToTop: PropTypes.func.isRequired,
    resetSneakyUpdate: PropTypes.func.isRequired,
    reviewPath: PropTypes.string.isRequired,
    scrollToTop: PropTypes.bool.isRequired,
    scrollPageToTop: PropTypes.func.isRequired,
    shouldExport: PropTypes.bool,
    shouldExportDocument: PropTypes.bool,
    shouldSneakyUpdate: PropTypes.bool,
    shouldSubmit: PropTypes.bool,
    shouldUpdate: PropTypes.bool,
    showComments: PropTypes.bool,
    showFormErrors: PropTypes.bool.isRequired,
    showFormValidation: PropTypes.func.isRequired,
    sneakyNextRoute: PropTypes.string,
    submitProject: PropTypes.func.isRequired,
    suggestedPricing: PropTypes.array.isRequired,
    suggestedScope: PropTypes.array.isRequired,
    tagOptions: PropTypes.array,
    templateVariableOptions: PropTypes.array,
    timezone: PropTypes.string,
    update: PropTypes.func,
    updateError: PropTypes.string,
    updating: PropTypes.bool,
    user: PropTypes.object,
    writingSections: PropTypes.array.isRequired,
};
