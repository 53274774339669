import moment from 'moment';
import { sortBy, startCase, uniqBy } from 'lodash';
import React from 'react';

import { proposalStatusesDict } from '../../../../../../../shared_config/proposals';
import { SCORED } from '../../../../../../../shared_config/evaluations';
import { HtmlContent } from '../../../../../components';

const { NO_BID, PUBLISHED } = proposalStatusesDict;

export const getProjectSummaryOverviewData = (project, showAnonymizedEvaluators) => {
    const getAllEvaluators = () => {
        const allEvaluators = [...project.evaluation.evaluators];

        if (project.evaluationAudits.length > 0) {
            project.evaluationAudits.forEach((audit) => {
                allEvaluators.push(...audit.data.evaluation.evaluators);
            });
        }

        const dedupedEvaluators = sortBy(uniqBy(allEvaluators, 'id'), 'lastName');

        return dedupedEvaluators.map((evaluator) => evaluator.displayName).join(', ');
    };
    return [
        {
            description: 'Project Title',
            data: project.title,
        },
        {
            description: 'Project ID',
            data: project.financialId,
        },
        {
            description: 'Project Type',
            data: project.template.procurementClassification,
        },
        {
            description: 'Release Date',
            data: moment(project.releaseProjectDate).format('LL'),
        },
        {
            description: 'Due Date',
            data: moment(project.proposalDeadline).format('LL'),
        },
        {
            description: 'Procurement Agent',
            data: project.procurementFullName,
        },
        ...(project.evaluation && project.evaluation.type === SCORED && !showAnonymizedEvaluators
            ? [{ description: 'Evaluators', data: getAllEvaluators() }]
            : []),
        {
            description: 'Project Description',
            data: <HtmlContent content={project.summary} />,
        },
    ];
};

export const statusHistoryColumnDefs = [
    {
        Header: 'Date',
        id: 'date',
        accessor: (params) => moment(params.created_at).format('lll'),
    },
    {
        Header: 'Changed To',
        id: 'change-to',
        accessor: (params) => startCase(params.status),
    },
    {
        Header: 'Changed By',
        id: 'changed-by',
        accessor: (params) => params.user.displayName,
    },
];

const getStatusData = (row) => {
    const baseLabelStyle = {
        textAlign: 'center',
        fontWeight: 'bold',
    };

    if (row.isDisqualified) {
        return ['Disqualified', { ...baseLabelStyle, color: '#a94442' }];
    }
    if (row.isExcluded) {
        return ['Excluded', { ...baseLabelStyle, color: '#555' }];
    }
    if (row.status === NO_BID) {
        return ['No Bid', { ...baseLabelStyle, color: '#8a6d3b' }];
    }
    if (row.status === PUBLISHED) {
        return ['Submitted', { ...baseLabelStyle, color: '#3c763d' }];
    }
};

export const getProposalsListColumnDefs = (timezone) => {
    return [
        {
            Header: 'Status',
            id: 'proposal-status-column',
            accessor: (row) => {
                const [text, style] = getStatusData(row);
                return <span style={style}>{text}</span>;
            },
        },
        {
            Header: 'Vendor',
            id: 'vendor-name-column',
            accessor: (row) => (row && row.companyName ? row.companyName : 'Unnamed Company'),
        },
        {
            Header: 'Contact Info',
            id: 'vendor-contact-column',
            accessor: (row) => {
                return (
                    <>
                        <div>{row.contactFullName}</div>
                        <div>{row.contactEmail}</div>
                        <div>{row.contactPhoneComplete}</div>
                    </>
                );
            },
        },
        {
            Header: 'Submission Date',
            id: 'submission-date-column',
            accessor: (row) => {
                if (row.submittedAt) {
                    return moment.tz(row.submittedAt, timezone).format('lll');
                }
                return null;
            },
        },
    ];
};
