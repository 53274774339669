import { createSelector } from 'reselect';

import { getContractPath } from '../selectors';
import { getUserJS } from '../../selectors';

export const getNavItems = createSelector([getContractPath, getUserJS], (contractPath, user) => {
    const {
        government: { hideContractReview, hideContractSpend },
    } = user;

    return [
        {
            title: 'Contract Details',
            link: `${contractPath}`,
            indexRoute: true,
        },
        {
            title: 'Checklists',
            link: `${contractPath}/checklists`,
            path: '/checklists',
        },
        {
            title: 'Insurance',
            link: `${contractPath}/insurance`,
        },
        {
            title: 'Contacts',
            link: `${contractPath}/contacts`,
        },
        {
            title: 'Notifications',
            link: `${contractPath}/notifications`,
        },
        ...(hideContractSpend
            ? []
            : [
                  {
                      title: 'Spend Management',
                      link: `${contractPath}/spend-management`,
                  },
              ]),
        ...(hideContractReview
            ? []
            : [
                  {
                      title: 'Vendor Performance',
                      link: `${contractPath}/reviews`,
                      path: '/reviews',
                  },
              ]),
        {
            title: 'Email Log',
            link: `${contractPath}/email-log`,
            path: '/email-log',
        },
        {
            title: 'Public Audit Log',
            link: `${contractPath}/public-audit-log`,
            path: '/public-audit-log',
        },
    ];
});
