import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { getProposalJS } from '../selectors';
import { getPublicProjectJS, getUserJS } from '../../selectors';
import { recordPublicProjectVendorEvent } from '../../../actions/events';
import { NavItem } from '../../../components';
import { VENDORS_EVENTS_DICT } from '../../../../../shared_config/vendors/events';
import { isPurchase } from '../../../helpers';

const mapStateToProps = (state) => {
    return {
        project: getPublicProjectJS(state),
        proposal: getProposalJS(state),
        user: getUserJS(state),
    };
};

// @connect
class ConnectedProjectNav extends Component {
    static propTypes = {
        project: PropTypes.shape({
            bidResults: PropTypes.object,
            evaluation: PropTypes.shape({
                selectedProposals: PropTypes.array.isRequired,
            }),
            government_id: PropTypes.number.isRequired,
            id: PropTypes.number.isRequired,
            isEvaluationOnly: PropTypes.bool,
            isLibrary: PropTypes.bool,
            showPlanholders: PropTypes.isRequired,
            projectSections: PropTypes.array,
            type: PropTypes.string,
        }),
        proposal: PropTypes.shape({
            id: PropTypes.number.isRequired,
            vendor_id: PropTypes.number.isRequired,
        }).isRequired,
        recordPublicProjectVendorEvent: PropTypes.func.isRequired,
        user: PropTypes.object,
    };

    get styles() {
        return require('./index.scss');
    }

    get navItems() {
        const {
            proposal: { id: proposalId, vendor_id: vendorId },
            project: {
                bidResults,
                evaluation,
                id: projectId,
                isEvaluationOnly,
                isLibrary,
                showPlanholders,
                type,
            },
        } = this.props;

        const displayOverviewTab = isPurchase(type);
        const urlPathname = `/vendors/${vendorId}/proposals/${proposalId}/projects/${projectId}`;
        const navItems = [
            {
                title: 'Project Documents',
                link: `${urlPathname}/document`,
                indexRoute: !displayOverviewTab,
                onClick: this.onNavItemClick(VENDORS_EVENTS_DICT.PUBLIC_PROJECT_VIEW),
            },
        ];

        if (displayOverviewTab) {
            navItems.unshift({
                title: 'Overview',
                link: urlPathname,
                indexRoute: true,
            });
        }

        // Only show project documents for library and evaluation only projects
        if (isLibrary || isEvaluationOnly) {
            return navItems;
        }

        navItems.push(
            {
                title: 'Downloads',
                link: `${urlPathname}/downloads`,
                onClick: this.onNavItemClick(VENDORS_EVENTS_DICT.DOWNLOADS_VIEW),
            },
            {
                title: 'Addenda & Notices',
                link: `${urlPathname}/addenda`,
                onClick: this.onNavItemClick(VENDORS_EVENTS_DICT.ADDENDA_VIEW),
            },
            {
                title: 'Question & Answer',
                link: `${urlPathname}/q-and-a`,
                onClick: this.onNavItemClick(VENDORS_EVENTS_DICT.QUESTION_ANSWER_VIEW),
            }
        );

        if (showPlanholders) {
            navItems.push({
                title: 'Followers',
                link: `${urlPathname}/followers`,
                onClick: this.onNavItemClick(VENDORS_EVENTS_DICT.FOLLOWERS_VIEW),
            });
        }

        const selectedProposals = evaluation && evaluation.selectedProposals;
        if (bidResults || (selectedProposals && selectedProposals.length > 0)) {
            navItems.push({ title: 'Results', link: `${urlPathname}/selected-vendor` });
        }

        return navItems;
    }

    onNavItemClick = (type) => () => {
        const { project, user } = this.props;
        if (project && user && user.isVendor) {
            this.props.recordPublicProjectVendorEvent(project.id, type);
        }
    };

    render() {
        const { project, proposal } = this.props;

        if (!project || !proposal) {
            return null;
        }

        const navItems = this.navItems;

        const navigationItems = navItems.map((item) => (
            <NavItem
                key={`${item.title}:${item.link}`}
                to={item.link}
                {...(item.onClick ? { onClick: item.onClick } : {})}
                qaTag={`project-${item.title}`}
            >
                {item.title}
            </NavItem>
        ));

        return (
            <>
                <Nav
                    bsStyle="tabs"
                    className={`${this.styles.projectNav} no-print`}
                    justified={navItems.length <= 6}
                >
                    {navigationItems}
                </Nav>
                <div className={this.styles.childContainer}>
                    <Outlet />
                </div>
            </>
        );
    }
}

export const ProjectNav = connect(mapStateToProps, { recordPublicProjectVendorEvent })(
    ConnectedProjectNav
);
