import PropTypes from 'prop-types';
import React from 'react';

import { ContactSection } from './ContactSection';
import { RFPIntroduction } from './RFPIntroduction';
import { TimelineSection } from './TimelineSection';
import { SectionIntro } from '../SectionIntro';
import { ProjectDetailTitle } from '../..';
import { subsectionTypeNames } from '../../../../../../shared_config/subsections';
import { getSectionNumberingString } from '../../../../../../shared_config/helpers';
import { Background } from './Background';
import { BudgetAndPostInformation } from './BudgetAndPostInformation';

const { BACKGROUND, BODY } = subsectionTypeNames;

export const ProjectIntroduction = (props) => {
    const {
        description,
        isDocx = false,
        isEditable = false,
        government,
        project,
        projectSection,
        projectSubsectionsMap,
        sectionDescActions,
        showComments = false,
        tagOptions,
        templateVariableOptions,
        title = 'Introduction',
        useManualNumbering,
        useSectionDividers,
        ...restProps
    } = props;

    const projectSubsectionId = projectSubsectionsMap[BODY].id;

    let sectionNum = 0;
    const getItemNumber = () => {
        if (useManualNumbering) {
            return `${++sectionNum}.`;
        }

        return getSectionNumberingString({
            sectionNumber: projectSection.sectionNumber,
            subsectionNumber: projectSection.subsectionNumber,
            subSectionItemNumber: ++sectionNum,
        });
    };

    return (
        <div className="col-xs-12 col-md-offset-1 col-md-10 introduction-section">
            <BudgetAndPostInformation government={government} isDocx={isDocx} project={project} />
            <ProjectDetailTitle
                isDocx={isDocx}
                projectSection={projectSection}
                projectSubsectionId={projectSubsectionId}
                showComments={showComments}
                title={title}
                useManualNumbering={useManualNumbering}
                useSectionDividers={useSectionDividers}
            />
            <SectionIntro
                {...sectionDescActions}
                isDocx={isDocx}
                isEditable={isEditable}
                projectSectionId={projectSection.id}
                projectSubsectionId={projectSubsectionId}
                sectionDescription={description[projectSubsectionId]}
                tagOptions={tagOptions}
                templateVariableOptions={templateVariableOptions}
            />
            <RFPIntroduction {...{ ...props, ...restProps, itemNumber: getItemNumber() }} />
            {project.background && (
                <Background
                    {...{
                        ...props,
                        itemNumber: getItemNumber(),
                        projectSubsection: projectSubsectionsMap[BACKGROUND],
                    }}
                />
            )}
            {(!project.hideContact || !project.hideProcurementContact) && (
                <ContactSection {...{ ...props, ...restProps, itemNumber: getItemNumber() }} />
            )}
            {!project.hideTimeline && !project.isIntake && (
                <TimelineSection {...{ ...props, ...restProps, itemNumber: getItemNumber() }} />
            )}
        </div>
    );
};

ProjectIntroduction.propTypes = {
    description: PropTypes.object.isRequired,
    isDocx: PropTypes.bool,
    isEditable: PropTypes.bool,
    government: PropTypes.object.isRequired,
    project: PropTypes.shape({
        background: PropTypes.string,
        budget: PropTypes.shape({
            // `budget` should be required, but old project audits may not have it.
            amount: PropTypes.number,
        }),
        hideContact: PropTypes.bool.isRequired,
        hideContactAddress: PropTypes.bool.isRequired,
        hideProcurementContact: PropTypes.bool.isRequired,
        hideTimeline: PropTypes.bool.isRequired,
        isIntake: PropTypes.bool,
        status: PropTypes.string.isRequired,
        wasPosted: PropTypes.bool.isRequired,
    }).isRequired,
    projectSection: PropTypes.object.isRequired,
    projectSubsectionsMap: PropTypes.object.isRequired,
    sectionDescActions: PropTypes.object,
    showComments: PropTypes.bool,
    tagOptions: PropTypes.array,
    templateVariableOptions: PropTypes.array,
    title: PropTypes.string,
    useManualNumbering: PropTypes.bool,
    useSectionDividers: PropTypes.bool,
};
