import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createSearchParams } from 'react-router-dom';

import { UnderlineNav } from '../..';

export const TemplateListNav = ({
    createError,
    location: { pathname, query },
    navItems,
    selectedNavItem,
    SelectFilter,
    TemplateCreateButton,
    title,
}) => {
    const styles = require('./index.scss');
    return (
        <>
            <div className={classnames('row', styles.titleContainer)}>
                <h2 className="col-sm-6">{title}</h2>
                <div className={`col-sm-6 ${styles.buttonContainer}`}>
                    <TemplateCreateButton />
                    {createError && <div className="error-block">{createError}</div>}
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-sm-8">
                    <UnderlineNav className={styles.navItems}>
                        {navItems.map((item) => {
                            const { counter, name, status } = item;
                            return (
                                <UnderlineNav.NavItem
                                    className={classnames({
                                        active: status === selectedNavItem.status,
                                    })}
                                    key={status}
                                    qaTag={`${title}-${name}`}
                                    to={{
                                        pathname,
                                        search: createSearchParams({ ...query, status }).toString(),
                                    }}
                                >
                                    {name}&nbsp;
                                    <span className={styles.counter}>({counter})</span>
                                </UnderlineNav.NavItem>
                            );
                        })}
                    </UnderlineNav>
                </div>
                <div className="col-xs-12 col-sm-4 text-right">{SelectFilter}</div>
            </div>
        </>
    );
};

const navItemType = PropTypes.shape({
    counter: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
}).isRequired;

TemplateListNav.propTypes = {
    createError: PropTypes.string,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        query: PropTypes.object.isRequired,
    }).isRequired,
    navItems: PropTypes.arrayOf(navItemType).isRequired,
    selectedNavItem: navItemType,
    SelectFilter: PropTypes.node,
    TemplateCreateButton: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};
