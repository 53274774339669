import { round } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { createSearchParams } from 'react-router-dom';

import { GovQuestionItem } from './GovQuestionItem';
import { Button, LinkContainer } from '../../..';
import { questionStatusTypes } from '../../../../../../shared_config/questions';

const { DRAFT } = questionStatusTypes;

export class GovQuestionSidebar extends PureComponent {
    static propTypes = {
        canRelease: PropTypes.bool,
        pathname: PropTypes.string.isRequired,
        questions: PropTypes.array.isRequired,
        selectedNumber: PropTypes.number,
        showReleaseResponsesModal: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./shared.scss');
    }

    get questionsAwaitingReview() {
        const { questions } = this.props;

        return questions.filter((q) => q.status === DRAFT);
    }

    releaseResponses = () => {
        const { showReleaseResponsesModal } = this.props;

        showReleaseResponsesModal(this.questionsAwaitingReview.map((question) => question.id));
    };

    renderProgressBar(unapprovedCount, unansweredCount, awaitingReviewCount) {
        const { questions } = this.props;

        const totalCount = questions.length;
        const releasedCount = totalCount - unapprovedCount - unansweredCount - awaitingReviewCount;

        return (
            <ProgressBar className={this.styles.progressBar}>
                <ProgressBar
                    label={
                        releasedCount > 0
                            ? `${round((releasedCount / totalCount) * 100, 0)}%`
                            : undefined
                    }
                    max={totalCount}
                    min={0}
                    now={releasedCount}
                />
                <ProgressBar
                    bsStyle="info"
                    label={
                        awaitingReviewCount > 0
                            ? `${round((awaitingReviewCount / totalCount) * 100, 0)}%`
                            : undefined
                    }
                    max={totalCount}
                    min={0}
                    now={awaitingReviewCount}
                />
                <ProgressBar
                    bsStyle="warning"
                    label={
                        unapprovedCount > 0
                            ? `${round((unapprovedCount / totalCount) * 100, 0)}%`
                            : undefined
                    }
                    max={totalCount}
                    min={0}
                    now={unapprovedCount}
                />
            </ProgressBar>
        );
    }

    render() {
        const { canRelease, pathname, questions, selectedNumber } = this.props;

        const QuestionNavItems = questions.map((question) => (
            <LinkContainer
                key={question.id}
                to={{
                    pathname,
                    search: createSearchParams({
                        number: question.number || question.id,
                    }).toString(),
                }}
            >
                <GovQuestionItem question={question} selectedNumber={selectedNumber} />
            </LinkContainer>
        ));
        const unapprovedCount = questions.filter((q) => !q.isApproved).length;
        const unansweredCount = questions.filter((q) => !q.isAnswered).length - unapprovedCount;
        const awaitingReviewCount = this.questionsAwaitingReview.length;

        return (
            <div className={this.styles.govSidebar}>
                <h4 className={this.styles.govSidebarTitle}>Question Shortcuts</h4>
                <div>
                    <strong>{unapprovedCount}</strong> <em>Questions to Approve</em>
                </div>
                <div>
                    <strong>{unansweredCount}</strong> <em>Questions to Respond to</em>
                </div>
                <div className={this.styles.awaitingReviewCount}>
                    <strong>{awaitingReviewCount}</strong> <em>Responses to Review & Release</em>
                </div>
                {this.renderProgressBar(unapprovedCount, unansweredCount, awaitingReviewCount)}
                {awaitingReviewCount > 0 && canRelease && (
                    <div className="text-center">
                        <Button
                            block
                            bsStyle="primary"
                            className={this.styles.releaseButton}
                            onClick={this.releaseResponses}
                            qaTag="govQuestionSideBar-releaseResponses"
                        >
                            <i className="fa fa-send" /> Release Responses
                        </Button>
                    </div>
                )}
                <ul className={`list-unstyled ${this.styles.questionsTOC}`}>{QuestionNavItems}</ul>
            </div>
        );
    }
}
