import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { tokens } from '@opengov/capital-style';
import { Box } from '@og-pro/ui';

import {
    TemplateAttachmentsForm,
    TemplateEvaluationCriteriaForm,
    TemplateIntroductionForm,
    TemplateListForm,
    TemplateQuestionnairesForm,
    TemplateSectionDescriptionForm,
    TemplateTextAreaForm,
    TemplatePricing,
    TemplateDivider,
    TemplateSignaturesForm,
} from './components';
import { questionLogicLinkableModelNames } from '../../../../../../../shared_config/questionLogics';
import { sectionTypeNames } from '../../../../../../../shared_config/sections';
import { subsectionTypeNames } from '../../../../../../../shared_config/subsections';
import { OGThemeConsumer } from '../../../ogThemeProvider';

const { colors } = tokens;

const {
    ATTACHMENTS,
    DIVIDER,
    EVALUATION_CRITERIA,
    EVALUATION_PHASE,
    INTRODUCTION,
    PRICING,
    QUESTIONNAIRE,
    SCOPE,
    SIGNATURE,
    TERMS,
    TEXT_AREA,
} = sectionTypeNames;

const { BODY } = subsectionTypeNames;

const {
    ATTACHMENT: ATTACHMENT_LINKABLE,
    CRITERIA: CRITERIA_LINKABLE,
    QUESTIONNAIRE: QUESTIONNAIRE_LINKABLE,
    SCORING_CRITERIA: SCORING_CRITERIA_LINKABLE,
} = questionLogicLinkableModelNames;

const mapStateToProps = (state, props) => {
    const {
        projectSection: { projectSubsections },
    } = props;

    return {
        projectSubsectionBodyId: projectSubsections.find((sub) => sub.subsection_type === BODY).id,
    };
};

// @connect
class ConnectedProjectContentForm extends PureComponent {
    static propTypes = {
        addConditionalLogicHandler: PropTypes.func,
        array: PropTypes.object.isRequired,
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        form: PropTypes.string.isRequired,
        hideQuestionLogic: PropTypes.bool,
        isIntake: PropTypes.bool,
        isOGThemeEnabledForComponents: PropTypes.bool,
        isReverseAuction: PropTypes.bool,
        isSharedSectionForm: PropTypes.bool,
        projectId: PropTypes.number,
        projectSection: PropTypes.shape({
            id: PropTypes.number.isRequired,
            projectSubsections: PropTypes.arrayOf(
                PropTypes.shape({
                    subsection_type: PropTypes.string.isRequired,
                })
            ).isRequired,
            section_type: PropTypes.string.isRequired,
        }).isRequired,
        projectSubsectionBodyId: PropTypes.number.isRequired,
        showFormValidation: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        useManualNumbering: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    renderForm() {
        const {
            addConditionalLogicHandler,
            array,
            change,
            disabled,
            form,
            hideQuestionLogic,
            isIntake,
            isOGThemeEnabledForComponents,
            isReverseAuction,
            isSharedSectionForm,
            projectId,
            projectSection,
            showFormValidation,
            tagOptions,
            templateVariableOptions,
            useManualNumbering,
        } = this.props;

        const sharedProps = {
            disabled,
            form,
            hideQuestionLogic,
            isIntake,
            isSharedSectionForm,
            projectSection,
            showFormValidation,
            tagOptions,
            templateVariableOptions,
            useManualNumbering,
        };

        switch (projectSection.section_type) {
            case ATTACHMENTS:
                return (
                    <TemplateAttachmentsForm
                        projectId={projectId}
                        questionLogicLinkable={ATTACHMENT_LINKABLE}
                        {...sharedProps}
                    />
                );
            case EVALUATION_CRITERIA:
            case EVALUATION_PHASE:
                return (
                    <TemplateEvaluationCriteriaForm
                        array={array}
                        change={change}
                        isMultiPhase={projectSection.section_type === EVALUATION_PHASE}
                        questionLogicLinkable={SCORING_CRITERIA_LINKABLE}
                        {...sharedProps}
                    />
                );
            case INTRODUCTION:
                return (
                    <TemplateIntroductionForm
                        array={array}
                        change={change}
                        isReverseAuction={isReverseAuction}
                        {...sharedProps}
                    />
                );
            case PRICING:
                return <TemplatePricing />;
            case QUESTIONNAIRE:
                return (
                    <TemplateQuestionnairesForm
                        addConditionalLogicHandler={addConditionalLogicHandler}
                        array={array}
                        change={change}
                        createBeforeAdd
                        projectId={projectId}
                        questionLogicLinkable={QUESTIONNAIRE_LINKABLE}
                        {...sharedProps}
                    />
                );
            case SCOPE:
                // NOTE: At some point we'll want to make scope items behave differently from list items
                return (
                    <TemplateListForm
                        array={array}
                        change={change}
                        questionLogicLinkable={CRITERIA_LINKABLE}
                        {...sharedProps}
                    />
                );
            case SIGNATURE:
                return <TemplateSignaturesForm change={change} {...sharedProps} />;

            case TERMS:
                return (
                    <TemplateListForm
                        array={array}
                        change={change}
                        questionLogicLinkable={CRITERIA_LINKABLE}
                        {...sharedProps}
                    />
                );
            case TEXT_AREA:
                return <TemplateTextAreaForm array={array} change={change} {...sharedProps} />;
            case DIVIDER: {
                if (isOGThemeEnabledForComponents) {
                    return <TemplateDivider />;
                }

                return null;
            }
            default:
                return null;
        }
    }

    render() {
        const {
            change,
            disabled,
            form,
            projectSection: { id: projectSectionId, section_type: sectionType },
            projectSubsectionBodyId,
            showFormValidation,
            tagOptions,
            templateVariableOptions,
        } = this.props;

        return (
            <OGThemeConsumer>
                {({ isOGThemeEnabledForComponents }) => {
                    if (isOGThemeEnabledForComponents) {
                        return (
                            <>
                                {sectionType !== DIVIDER && (
                                    <Box
                                        borderBottom={`1px solid ${colors.colorGray200}`}
                                        marginBottom={2}
                                    >
                                        <TemplateSectionDescriptionForm
                                            change={change}
                                            disabled={disabled}
                                            form={form}
                                            projectSectionId={projectSectionId}
                                            projectSubsectionId={projectSubsectionBodyId}
                                            showFormValidation={showFormValidation}
                                            tagOptions={tagOptions}
                                            templateVariableOptions={templateVariableOptions}
                                        />
                                    </Box>
                                )}
                                {this.renderForm()}
                            </>
                        );
                    }
                    return (
                        <>
                            <div className={`row ${this.styles.sectionDescriptionContainer}`}>
                                <div className="col-md-offset-2 col-md-8">
                                    <TemplateSectionDescriptionForm
                                        change={change}
                                        disabled={disabled}
                                        form={form}
                                        projectSectionId={projectSectionId}
                                        projectSubsectionId={projectSubsectionBodyId}
                                        showFormValidation={showFormValidation}
                                        tagOptions={tagOptions}
                                        templateVariableOptions={templateVariableOptions}
                                    />
                                </div>
                            </div>
                            {this.renderForm()}
                        </>
                    );
                }}
            </OGThemeConsumer>
        );
    }
}

export const ProjectContentForm = connect(mapStateToProps)(ConnectedProjectContentForm);
