import moment from 'moment';
import { createSelector } from 'reselect';

import { getAllRequisitionLogs } from '../../../selectors';
import { getRequisitionJS } from '../../../../../../selectors/govApp';
import { actionTypes } from '../../../../../../../../shared_config/requisitionLogs';
import { reviewTypes } from '../../../../../../../../shared_config/reviewSequences';

const actionTypesToDisplay = [
    actionTypes.APPROVE,
    actionTypes.BUDGET_OVERRIDE,
    actionTypes.CLOSED_REJECTED,
    actionTypes.CLOSED_PO_CREATED,
    actionTypes.REJECT,
];

const getApproveActionName = (reviewType) => {
    switch (reviewType) {
        case reviewTypes.CONFIRM:
            return 'Acknowledged by';
        case reviewTypes.NOTIFY:
            return 'Notified';
        case reviewTypes.APPROVE:
        default:
            return 'Approved by';
    }
};

const getRequisitionLogText = (requisitionLog, reviewSequenceStep) => {
    const { actionType, date, user } = requisitionLog;
    if (actionType === actionTypes.APPROVE) {
        const action = getApproveActionName(reviewSequenceStep.reviewType);
        return `${action} ${user.displayName} on ${moment(date).format('M/D/YY [at] h:mma')}`;
    }
    if (actionType === actionTypes.BUDGET_OVERRIDE) {
        return `Budget Override by ${user.displayName} on ${moment(date).format(
            'M/D/YY [at] h:mma'
        )}`;
    }
    if (actionType === actionTypes.CLOSED_PO_CREATED) {
        return `Completed by ${user.displayName} on ${moment(date).format('M/D/YY [at] h:mma')}`;
    }
    if (actionType === actionTypes.REJECT || actionType === actionTypes.CLOSED_REJECTED) {
        return `Rejected by ${user.displayName} on ${moment(date).format('M/D/YY [at] h:mma')}`;
    }
    return 'Unknown event';
};

const getRequisitionLogsByStep = createSelector([getAllRequisitionLogs], (requisitionLogs) => {
    return requisitionLogs
        .filter((requisitionLog) => actionTypesToDisplay.includes(requisitionLog.actionType))
        .reduce((map, requisitionLog) => {
            const reviewSequenceStepId = requisitionLog.review_sequence_step_id;
            if (!reviewSequenceStepId) {
                return map;
            }
            if (!map[reviewSequenceStepId]) {
                map[reviewSequenceStepId] = [];
            }
            map[reviewSequenceStepId].push(requisitionLog);
            return map;
        }, {});
});

export const getStepRequisitionLogsDisplay = createSelector(
    [getRequisitionLogsByStep, getRequisitionJS, (_, reviewSequenceStep) => reviewSequenceStep],
    (requisitionLogsByStep, requisition, reviewSequenceStep) => {
        const requisitionLogs = requisitionLogsByStep[reviewSequenceStep.id] || [];

        const displayRequisitionLogs = requisitionLogs.map((requisitionLog) => {
            return {
                text: getRequisitionLogText(requisitionLog, reviewSequenceStep),
                details: requisitionLog.details,
            };
        });
        if (requisition.current_step_id === reviewSequenceStep.id) {
            const date = reviewSequenceStep.stepApproval.lastActionAt;
            displayRequisitionLogs.unshift({
                text: `Active as of ${moment(date).format('M/D/YY [at] h:mma')}`,
            });
        }

        return displayRequisitionLogs;
    }
);
