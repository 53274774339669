import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, formValueSelector } from 'redux-form';

import {
    useOpenFiscalPeriodsRange,
    useOpenFiscalPeriodsWithCalendarYear,
} from './useOpenFiscalPeriods';
import { getIsRequisitionUsingFMS } from '../../selectors/govApp';
import { findPeriodByDate } from '../../../../shared_config/requisitions';
import { getFiscalYearSelectOptions } from '../../containers/App/selectors';

export function useFiscalPeriodFormDisplay(
    formName,
    fullObjectFieldName,
    nonFmsFiscalPeriodFieldName,
    initialFiscalPeriod
) {
    const hasFMS = useSelector(getIsRequisitionUsingFMS);
    const dispatch = useDispatch();
    const fiscalPeriod = useSelector((state) =>
        formValueSelector(formName)(state, fullObjectFieldName)
    );

    const {
        data: openFiscalPeriodsRange,
        error: fiscalPeriodsRangeError,
        isError: isFiscalPeriodsRangeError,
        isLoading: isLoadingFiscalPeriodsRange,
    } = useOpenFiscalPeriodsRange({ enabled: hasFMS });

    const {
        data: fiscalPeriods,
        error: fiscalPeriodsError,
        isError: isFiscalPeriodsError,
        isLoading: isLoadingFiscalPeriods,
    } = useOpenFiscalPeriodsWithCalendarYear({ enabled: hasFMS });

    const fmsFiscalPeriod = useMemo(() => {
        return (
            (fiscalPeriod && fiscalPeriod.description) ||
            (initialFiscalPeriod && initialFiscalPeriod.description) ||
            'Select an Expected Purchase Order Date first'
        );
    }, [fiscalPeriod, initialFiscalPeriod]);

    const handleExpectedPurchaseOrderDateChange = (date) => {
        if (!date) {
            return;
        }
        const newFiscalPeriod = findPeriodByDate(fiscalPeriods, date);
        dispatch(change(formName, fullObjectFieldName, newFiscalPeriod));
        dispatch(change(formName, nonFmsFiscalPeriodFieldName, newFiscalPeriod?.description || ''));
    };

    const fiscalYearSelectOptions = useSelector(getFiscalYearSelectOptions);

    return {
        error: fiscalPeriodsRangeError || fiscalPeriodsError,
        fiscalYearSelectOptions,
        hasFMS,
        openFiscalPeriodsRange,
        isError: isFiscalPeriodsRangeError || isFiscalPeriodsError,
        isLoadingFiscalPeriodsRange,
        isLoadingFiscalPeriods,
        fmsFiscalPeriod,
        handleExpectedPurchaseOrderDateChange,
    };
}
