import { createSelector } from 'reselect';

const requisitionsAdminNavItems = [
    {
        path: '/settings',
        title: 'Settings',
    },
    {
        path: '/entity-positions',
        title: 'Entity Positions',
    },
    {
        path: '/group-positions',
        title: 'Group Positions',
    },
    {
        path: '/flags',
        title: 'Flags',
    },
];

const getSelectedRequisitionsAdminNavItem = createSelector(
    [(state, props) => props.location.pathname],
    (pathname) => {
        return (
            requisitionsAdminNavItems.find((item) => !!item.path && pathname.match(item.path)) ||
            requisitionsAdminNavItems[0]
        );
    }
);

export const getRequisitionsAdminNavItems = createSelector(
    [getSelectedRequisitionsAdminNavItem],
    (selectedNavItem) => {
        return requisitionsAdminNavItems.map((navItem) => {
            return {
                ...navItem,
                isActive: navItem.title === selectedNavItem.title,
            };
        });
    }
);
