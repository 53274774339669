import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Form } from 'react-bootstrap';

import { InputText, Button } from '../../../../components';
import { timelineDatesDict } from '../../../../../../shared_config/timelines';
import { validate } from './validate';
import { GOV_ADDED_VENDOR_NAME } from './constants';

const formConfig = {
    validate,
};

// @reduxForm
const ConnectedRsvpAddVendorForm = ({
    destroy,
    govAddVendorRsvp,
    handleSubmit,
    initialize,
    invalid,
    projectId,
    reset,
    rsvpingVendor,
    timelineId,
}) => {
    const [showForm, setShowForm] = useState(false);
    const styles = require('./RsvpAddVendorForm.scss');

    const showFormClickHandler = () => {
        if (showForm) {
            destroy();
        } else {
            initialize({ govAddVendorRsvp: undefined });
        }
        setShowForm(!showForm);
    };

    const submitHandler = (formData) => {
        const isPreProposalRsvp = timelineId === timelineDatesDict.PRE_PROPOSAL_DATE;
        const data = {
            ...formData,
            ...(isPreProposalRsvp ? { isPreProposalRsvp } : { timelineId }),
        };
        govAddVendorRsvp(projectId, data);
        reset();
    };

    return (
        <>
            <Button bsSize="small" onClick={showFormClickHandler}>
                {!showForm && <i className="fa fa-plus" />}
                &nbsp;{showForm ? 'Cancel' : 'Add Vendor'}
            </Button>
            <br />
            {showForm && (
                <Form
                    className={`pull-right ${styles.addVendorForm}`}
                    inline
                    onSubmit={handleSubmit(submitHandler)}
                >
                    <Field
                        component={InputText}
                        name={GOV_ADDED_VENDOR_NAME}
                        placeholder="Enter vendor name"
                    />
                    <Button
                        bsSize="small"
                        bsStyle="primary"
                        className={styles.addBtn}
                        disabled={invalid || rsvpingVendor}
                        type="submit"
                    >
                        <i className="fa fa-plus" />
                        &nbsp;Add
                    </Button>
                </Form>
            )}
        </>
    );
};

ConnectedRsvpAddVendorForm.propTypes = {
    destroy: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    govAddVendorRsvp: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    projectId: PropTypes.number.isRequired,
    reset: PropTypes.func.isRequired,
    rsvpingVendor: PropTypes.bool.isRequired,
    timelineId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export const RsvpAddVendorForm = reduxForm(formConfig)(ConnectedRsvpAddVendorForm);
