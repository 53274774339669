import { formValueSelector } from 'redux-form';
import { v4 as UUIDv4 } from 'uuid';

import { fieldNames } from './constants';
import { formConfig } from './form';
import { priceItemFieldNames } from '../../constants';
import { discountTypes } from '../../../../../../shared_config/priceTables';

export const requisitionsCreateFormValueSelector = formValueSelector(formConfig.form);

export const getEmptyPriceItem = (orderById) => {
    return {
        [priceItemFieldNames.ACCOUNT_SPLIT_PRICE_ITEMS]: [],
        [priceItemFieldNames.CUSTOM_1]: null,
        [priceItemFieldNames.CUSTOM_2]: null,
        [priceItemFieldNames.DESCRIPTION]: null,
        [priceItemFieldNames.DISCOUNT]: null,
        [priceItemFieldNames.DISCOUNT_TYPE]: discountTypes.DOLLAR_AMOUNT,
        [priceItemFieldNames.ORDER_BY_ID]: orderById ?? 1,
        [priceItemFieldNames.QUANTITY]: null,
        [priceItemFieldNames.TAXABLE]: false,
        [priceItemFieldNames.UNIT_PRICE]: null,
        [priceItemFieldNames.UNIT_TO_MEASURE]: null,
        [priceItemFieldNames.VENDOR_ASSIGNMENT_UUID]: UUIDv4(),
    };
};

const { ASSOCIATED_LINE_ITEMS, VENDORS } = fieldNames;

const addPriceItemToVendor = (
    dispatch,
    change,
    index,
    priceItemVendorAssignmentUuid,
    vendors,
    description,
    newVendorId
) => {
    const vendorIndex = vendors.findIndex((vendor) => vendor.vendorAssignmentUuid === newVendorId);

    // check if this line item is already associated
    const lineItemIsNotAssociated = vendors[vendorIndex].associatedLineItems?.every(
        (associatedLineItem) => associatedLineItem.value !== priceItemVendorAssignmentUuid
    );

    if (lineItemIsNotAssociated) {
        const newAssociatedLineItems = [
            ...vendors[vendorIndex].associatedLineItems,
            {
                label: `Line Item ${index + 1}`,
                value: priceItemVendorAssignmentUuid,
                description,
            },
        ];
        dispatch(
            change(`${VENDORS}[${vendorIndex}].${ASSOCIATED_LINE_ITEMS}`, newAssociatedLineItems)
        );
    }
};

const removePriceItemFromVendor = (
    dispatch,
    change,
    priceItemVendorAssignmentUuid,
    vendors,
    oldVendorId
) => {
    const vendorIndex = vendors.findIndex((vendor) => vendor.vendorAssignmentUuid === oldVendorId);

    const newAssociatedLineItems = vendors[vendorIndex].associatedLineItems.filter(
        (associatedLineItem) => associatedLineItem.value !== priceItemVendorAssignmentUuid
    );

    dispatch(change(`${VENDORS}[${vendorIndex}].${ASSOCIATED_LINE_ITEMS}`, newAssociatedLineItems));
};

export const updateVendorAssociatedPriceItems = (
    dispatch,
    change,
    index,
    priceItemVendorAssignmentUuid,
    vendors,
    description,
    newVendorId,
    oldVendorId
) => {
    // vendor is being added to line item
    if (newVendorId && !oldVendorId) {
        addPriceItemToVendor(
            dispatch,
            change,
            index,
            priceItemVendorAssignmentUuid,
            vendors,
            description,
            newVendorId
        );
    }

    // vendor is being changed
    if (newVendorId && oldVendorId) {
        /* there is no risk of a race condition because these are operating
         * on two different vendors
         */
        removePriceItemFromVendor(
            dispatch,
            change,
            priceItemVendorAssignmentUuid,
            vendors,
            oldVendorId
        );
        addPriceItemToVendor(
            dispatch,
            change,
            index,
            priceItemVendorAssignmentUuid,
            vendors,
            description,
            newVendorId
        );
    }

    // vendor is being removed from line item
    if (!newVendorId && oldVendorId) {
        removePriceItemFromVendor(
            dispatch,
            change,
            priceItemVendorAssignmentUuid,
            vendors,
            oldVendorId
        );
    }
};

export const hasInvalidVendorAddress = (vendor) => {
    return !vendor.address1 || !vendor.state || !vendor.city || !vendor.zipCode;
};
