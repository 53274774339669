import { fromJS, List, Map } from 'immutable';

import * as contractActions from '../actions/contracts';

const initialState = fromJS({
    addingSubscribers: false,
    addSubscribersError: null,
    checklists: [],
    contract: null,
    contractAttachmentsBeingCreated: {},
    contractBudget: null,
    contractComplaintModalData: null,
    contracts: [],
    contractsCount: 0,
    createContractError: null,
    createdContract: false,
    creatingContract: false,
    deleteContractError: null,
    deletedContract: false,
    deletingContract: false,
    lineItems: [],
    loadContractBudgetError: null,
    loadContractError: null,
    loadContractLineItemsError: null,
    loadContractNotificationsError: null,
    loadContractReviewError: null,
    loadContractsReviewError: null,
    loadContractsError: null,
    loadChecklistsError: null,
    loadedReqRelations: false,
    loadPublicAuditLogsError: null,
    loadingChecklists: false,
    loadedContract: false,
    loadedContractBudget: false,
    loadedContracts: false,
    loadingContract: false,
    loadingContractBudget: false,
    loadingContractLineItems: false,
    loadingContractReview: false,
    loadingContractReviews: false,
    loadingContracts: false,
    loadingContractsForCSVExport: false,
    loadingContractNotifications: false,
    loadingPublicAuditLogs: false,
    manageContactsError: null,
    managingContacts: false,
    manageInsurancesError: null,
    managingInsurances: false,
    publicAuditLogs: [],
    reqRelations: [],
    removeSubscriberError: null,
    removingSubscriber: false,
    saveContractPermissionError: null,
    savingContractPermission: false,
    showContractComplaintModal: false,
    showContractPermissionsModal: false,
    showDepartmentModal: false,
    updateContractError: null,
    updateRenewalError: null,
    updatingContract: false,
    updatingRenewal: false,
    vendorEmailAuditData: null,
});

function contractMilestonesReducer(state, action) {
    if (state.get('id') !== action.milestoneId) return state;

    switch (action.type) {
        case contractActions.UPDATE_MILESTONE_NOTIFICATIONS:
            return state.merge(
                fromJS({
                    notificationsUpdateError: null,
                    notificationsUpdating: true,
                })
            );
        case contractActions.UPDATE_MILESTONE_NOTIFICATIONS_FAIL:
            return state.merge(
                fromJS({
                    notificationsUpdateError: action.error && action.error.message,
                    notificationsUpdating: false,
                })
            );
        case contractActions.UPDATE_MILESTONE_NOTIFICATIONS_SUCCESS: {
            return state.merge(
                fromJS({
                    notificationsUpdating: false,
                    notifications: fromJS(action.result),
                })
            );
        }
        default:
            return state;
    }
}

function contractSubscribersReducer(state, action) {
    if (state.get('id') !== action.contractSubscriberId) return state;

    switch (action.type) {
        case contractActions.REMOVE_SUBSCRIBER:
            return state.merge(
                fromJS({
                    removingSubscriber: true,
                    removeSubscriberError: null,
                })
            );
        case contractActions.REMOVE_SUBSCRIBER_FAIL:
            return state.merge(
                fromJS({
                    removingSubscriber: false,
                    removeSubscriberError: action.error && action.error.message,
                })
            );
        default:
            return state;
    }
}

export default function contractsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case contractActions.ADD_SUBSCRIBERS:
            return state.merge(
                fromJS({
                    addingSubscribers: true,
                    addSubscribersError: null,
                })
            );
        case contractActions.ADD_SUBSCRIBERS_FAIL:
            return state.merge(
                fromJS({
                    addingSubscribers: false,
                    addSubscribersError: action.error && action.error.message,
                })
            );
        case contractActions.ADD_SUBSCRIBERS_SUCCESS:
            return state
                .set('addingSubscribers', false)
                .setIn(
                    ['contract', 'contractSubscribers'],
                    fromJS(action.result).concat(state.getIn(['contract', 'contractSubscribers']))
                );
        case contractActions.ATTACHMENT_PROGRESS:
            return state.setIn(
                ['contractAttachmentsBeingCreated', action.uploadId, 'progress'],
                action.progress
            );
        case contractActions.CHANGE_CONTRACT_PERMISSION:
            return state.merge(
                fromJS({
                    saveContractPermissionError: null,
                    savingContractPermission: true,
                })
            );
        case contractActions.CHANGE_CONTRACT_PERMISSION_FAIL:
            return state.merge(
                fromJS({
                    saveContractPermissionError: action.error.message,
                    savingContractPermission: false,
                })
            );
        case contractActions.CHANGE_CONTRACT_PERMISSION_SUCCESS: {
            const requestedRole = action.role;
            const updatedContractPermission = action.result;

            let permissions = state.getIn(['contract', 'permissions']);

            if (!requestedRole) {
                permissions = permissions.filter((contractPermission) => {
                    return contractPermission.get('user_id') !== updatedContractPermission.user_id;
                });
            } else {
                const existingContractPermissionIndex = permissions.findIndex(
                    (contractPermission) => {
                        return (
                            contractPermission.get('user_id') === updatedContractPermission.user_id
                        );
                    }
                );

                if (existingContractPermissionIndex === -1) {
                    permissions = permissions.unshift(fromJS(updatedContractPermission));
                } else {
                    permissions = permissions.setIn(
                        [existingContractPermissionIndex],
                        fromJS(updatedContractPermission)
                    );
                }
            }

            return state
                .set('savingContractPermission', false)
                .setIn(['contract', 'permissions'], permissions);
        }
        case contractActions.CREATE:
            return state.merge(
                fromJS({
                    creatingContract: true,
                    createContractError: null,
                })
            );
        case contractActions.CREATE_ATTACHMENT_SUCCESS: {
            let newState = state;

            if (!newState.get('contract').has('attachments')) {
                newState = newState.setIn(['contract', 'attachments'], new List());
            }

            newState = newState.deleteIn(['contractAttachmentsBeingCreated', action.uploadId]);

            return newState.setIn(
                ['contract', 'attachments'],
                newState.getIn(['contract', 'attachments']).push(fromJS(action.result))
            );
        }
        case contractActions.CREATE_CONTRACT_PUBLIC_AUDIT_LOG_SUCCESS: {
            return state.merge(
                fromJS({
                    publicAuditLogs: state.get('publicAuditLogs').unshift(fromJS(action.result)),
                })
            );
        }
        case contractActions.GET_ATTACHMENT_S3_URL:
            return state.setIn(
                ['contractAttachmentsBeingCreated', action.uploadId],
                new Map({ file: action.file, progress: 0.01 })
            );
        case contractActions.GET_ATTACHMENT_S3_URL_FAIL:
        case contractActions.UPLOAD_ATTACHMENT_TO_S3_FAIL:
        case contractActions.CREATE_ATTACHMENT_FAIL:
            return state.deleteIn(['contractAttachmentsBeingCreated', action.uploadId]);
        case contractActions.CREATE_FAIL:
            return state.merge(
                fromJS({
                    creatingContract: false,
                    createContractError: action.error.message,
                })
            );
        case contractActions.CREATE_SUCCESS:
            return state.merge(
                fromJS({
                    contract: fromJS(action.result),
                    createdContract: true,
                    creatingContract: false,
                })
            );
        case contractActions.CREATE_CONTRACT_COMPLAINT_SUCCESS:
            return state.setIn(
                ['contract', 'contractReviews'],
                state.getIn(['contract', 'contractReviews']).map((contractReview) => {
                    if (contractReview.get('id') !== action.result.contract_review_id) {
                        return contractReview;
                    }
                    return contractReview.set(
                        'contractComplaints',
                        contractReview.get('contractComplaints').unshift(fromJS(action.result))
                    );
                })
            );
        case contractActions.CREATE_CONTRACT_REVIEW_SUCCESS:
            return state.setIn(
                ['contract', 'contractReviews'],
                state.getIn(['contract', 'contractReviews']).unshift(fromJS(action.result))
            );
        case contractActions.DELETE:
            return state.merge(
                fromJS({
                    deletingContract: true,
                    deleteContractError: null,
                })
            );
        case contractActions.DELETE_FAIL:
            return state.merge(
                fromJS({
                    deletingContract: false,
                    deleteContractError: action.error.message,
                })
            );
        case contractActions.DELETE_SUCCESS:
            return state.merge(
                fromJS({
                    contract: fromJS(action.result), // NOTE: the delete contract API returns the deleted contract
                    deletedContract: true,
                    deletingContract: false,
                })
            );
        case contractActions.DELETE_ATTACHMENT_SUCCESS:
            return state.setIn(
                ['contract', 'attachments'],
                state
                    .getIn(['contract', 'attachments'])
                    .filter((attach) => attach.get('id') !== action.attachmentId)
            );
        case contractActions.DELETE_CONTRACT_COMPLAINT_SUCCESS:
            return state.setIn(
                ['contract', 'contractReviews'],
                state.getIn(['contract', 'contractReviews']).map((contractReview) => {
                    if (contractReview.get('id') !== action.result.contract_review_id) {
                        return contractReview;
                    }
                    return contractReview.set(
                        'contractComplaints',
                        contractReview.get('contractComplaints').filter((complaint) => {
                            return complaint.get('id') !== action.result.id;
                        })
                    );
                })
            );
        case contractActions.DELETE_CONTRACT_REVIEW_SUCCESS:
            return state.setIn(
                ['contract', 'contractReviews'],
                state
                    .getIn(['contract', 'contractReviews'])
                    .filter((review) => review.get('id') !== action.result.id)
            );
        case contractActions.HIDE_CONTRACT_COMPLAINT_MODAL:
            return state.merge(
                fromJS({
                    contractComplaintModalData: null,
                    showContractComplaintModal: false,
                })
            );
        case contractActions.HIDE_DEPARTMENT_MODAL:
            return state.set('showDepartmentModal', false);
        case contractActions.HIDE_PERMISSIONS_MODAL:
            return state.set('showContractPermissionsModal', false);
        case contractActions.LOAD:
            return state.merge(
                fromJS({
                    deleteContractError: null,
                    loadingContract: true,
                    loadContractError: null,
                    updateContractError: null,
                })
            );
        case contractActions.LOAD_CONTRACT_NOTIFICATIONS:
            return state.merge(
                fromJS({
                    loadingContractNotifications: true,
                    loadContractNotificationsError: null,
                })
            );
        case contractActions.LOAD_CONTRACT_NOTIFICATIONS_FAIL:
            return state.merge(
                fromJS({
                    loadingContractNotifications: false,
                    loadContractNotificationsError: action.error.message,
                })
            );
        case contractActions.LOAD_CONTRACT_NOTIFICATIONS_SUCCESS:
            return state.merge(
                fromJS({
                    contractMilestones: fromJS(action.result),
                    loadingContractNotifications: false,
                })
            );
        case contractActions.LOAD_CONTRACTS:
            return state.merge(
                fromJS({
                    loadingContracts: true,
                    loadContractsError: null,
                })
            );
        case contractActions.LOAD_CONTRACTS_FOR_CSV_EXPORT:
            return state.merge(
                fromJS({
                    loadingContractsForCSVExport: true,
                    loadContractsError: null,
                })
            );
        case contractActions.LOAD_CONTRACTS_FAIL:
            return state.merge(
                fromJS({
                    loadingContracts: false,
                    loadContractsError: action.error.message,
                })
            );
        case contractActions.LOAD_CONTRACTS_SUCCESS:
            return state.merge(
                fromJS({
                    contracts: fromJS(action.result),
                    contractsCount: action.count,
                    contractProcurementContacts: fromJS(action.contractProcurementContacts),
                    loadedContracts: true,
                    loadingContracts: false,
                })
            );
        case contractActions.LOAD_CONTRACTS_FOR_CSV_EXPORT_SUCCESS:
            return state.merge(
                fromJS({
                    loadedContracts: true,
                    loadingContractsForCSVExport: false,
                })
            );
        case contractActions.LOAD_FAIL:
            return state.merge(
                fromJS({
                    loadingContract: false,
                    loadContractError: action.error.message,
                })
            );
        case contractActions.LOAD_SUCCESS:
            return state.merge(
                fromJS({
                    contract: fromJS(action.result),
                    loadedContract: true,
                    loadingContract: false,
                })
            );
        case contractActions.LOAD_CONTRACT_LINE_ITEMS:
            return state.merge(
                fromJS({
                    lineItems: [],
                    loadContractLineItemsError: null,
                    loadingContractLineItems: true,
                })
            );
        case contractActions.LOAD_CONTRACT_LINE_ITEMS_SUCCESS:
            return state.merge(
                fromJS({
                    lineItems: fromJS(action.result),
                    loadingContractLineItems: false,
                })
            );
        case contractActions.LOAD_CONTRACT_LINE_ITEMS_FAIL:
            return state.merge(
                fromJS({
                    loadContractLineItemsError: action.error.message,
                    loadingContractLineItems: false,
                })
            );
        case contractActions.LOAD_CONTRACT_CHECKLISTS:
            return state.merge(
                fromJS({
                    loadChecklistsError: null,
                    loadingChecklists: true,
                })
            );
        case contractActions.LOAD_CONTRACT_CHECKLISTS_SUCCESS:
            return state.merge(
                fromJS({
                    checklists: fromJS(action.result),
                    loadingChecklists: false,
                })
            );
        case contractActions.LOAD_CONTRACT_CHECKLISTS_FAIL:
            return state.merge(
                fromJS({
                    loadChecklistsError: action.error.message,
                    loadingChecklists: false,
                })
            );
        case contractActions.LOAD_CONTRACT_PUBLIC_AUDIT_LOGS:
            return state.merge(
                fromJS({
                    loadingPublicAuditLogs: true,
                    loadPublicAuditLogsError: null,
                })
            );
        case contractActions.LOAD_CONTRACT_PUBLIC_AUDIT_LOGS_SUCCESS:
            return state.merge(
                fromJS({
                    loadingPublicAuditLogs: false,
                    publicAuditLogs: fromJS(action.result),
                })
            );
        case contractActions.LOAD_CONTRACT_PUBLIC_AUDIT_LOGS_FAIL:
            return state.merge(
                fromJS({
                    loadingPublicAuditLogs: false,
                    loadPublicAuditLogsError: action.error.message,
                })
            );
        case contractActions.LOAD_CONTRACT_REQ_RELATIONS_SUCCESS:
            return state.merge(
                fromJS({
                    loadedReqRelations: true,
                    reqRelations: action.result,
                })
            );
        case contractActions.CREATE_CONTRACT_REQ_RELATIONS_SUCCESS:
            return state.set(
                'reqRelations',
                state.get('reqRelations').concat(fromJS(action.result))
            );
        case contractActions.LOAD_CONTRACT_REVIEW:
            return state.merge(
                fromJS({
                    loadingContractReview: true,
                    loadContractReviewError: null,
                })
            );
        case contractActions.LOAD_CONTRACT_REVIEW_FAIL:
            return state.merge(
                fromJS({
                    loadingContractReview: false,
                    loadContractReviewError: action.error.message,
                })
            );
        case contractActions.LOAD_CONTRACT_REVIEW_SUCCESS:
            // If contract hasn't been loaded yet we can't update contract reviews (happens on refresh)
            if (!state.get('loadedContract')) {
                return state.set('loadingContractReview', false);
            }
            return state.set('loadingContractReview', false).setIn(
                ['contract', 'contractReviews'],
                state.getIn(['contract', 'contractReviews']).map((contractReview) => {
                    if (contractReview.get('id') === action.result.id) {
                        return fromJS(action.result);
                    }
                    return contractReview;
                })
            );
        case contractActions.LOAD_CONTRACT_REVIEWS:
            return state.merge(
                fromJS({
                    loadingContractsReview: true,
                    loadContractReviewsError: null,
                })
            );
        case contractActions.LOAD_CONTRACT_REVIEWS_FAIL:
            return state.merge(
                fromJS({
                    loadingContractsReview: false,
                    loadContractReviewsError: action.error.message,
                })
            );
        case contractActions.LOAD_CONTRACT_REVIEWS_SUCCESS:
            return state
                .set('loadingContractsReview', false)
                .setIn(['contract', 'contractReviews'], fromJS(action.result));
        case contractActions.LOAD_PURCHASE_ORDERS:
            return state.merge(
                fromJS({
                    loadingContractBudget: true,
                    loadContractBudgetError: null,
                })
            );
        case contractActions.LOAD_PURCHASE_ORDERS_FAIL:
            return state.merge(
                fromJS({
                    loadingContractBudget: false,
                    loadContractBudgetError: action.error.message,
                })
            );
        case contractActions.LOAD_PURCHASE_ORDERS_SUCCESS:
            return state.merge(
                fromJS({
                    contractBudget: fromJS(action.result),
                    loadingContractBudget: false,
                    loadedContractBudget: true,
                })
            );
        case contractActions.LOAD_VENDOR_EMAIL_AUDITS_SUCCESS:
            return state.merge(
                fromJS({
                    vendorEmailAuditData: action.result,
                })
            );
        case contractActions.PENDING_CONTRACT_DOCUMENT_CREATED:
            return state.setIn(
                ['contract', 'pendingAttachments'],
                state.getIn(['contract', 'pendingAttachments']).push(fromJS(action.result))
            );
        case contractActions.PENDING_CONTRACT_DOCUMENT_APPROVED:
            return state
                .setIn(
                    ['contract', 'attachments'],
                    state.getIn(['contract', 'attachments']).push(fromJS(action.result))
                )
                .setIn(
                    ['contract', 'pendingAttachments'],
                    state
                        .getIn(['contract', 'pendingAttachments'])
                        .filter((attachment) => attachment.get('id') !== action.result.id)
                );
        case contractActions.PENDING_CONTRACT_DOCUMENT_REJECTED:
            return state.setIn(
                ['contract', 'pendingAttachments'],
                state
                    .getIn(['contract', 'pendingAttachments'])
                    .filter((attachment) => attachment.get('id') !== action.result.id)
            );
        case contractActions.REMOVE_SUBSCRIBER:
        case contractActions.REMOVE_SUBSCRIBER_FAIL:
            return state.setIn(
                ['contract', 'contractSubscribers'],
                state
                    .getIn(['contract', 'contractSubscribers'])
                    .map((subscriber) => contractSubscribersReducer(subscriber, action))
            );
        case contractActions.REMOVE_SUBSCRIBER_SUCCESS:
            return state.setIn(
                ['contract', 'contractSubscribers'],
                state
                    .getIn(['contract', 'contractSubscribers'])
                    .filter((subscriber) => subscriber.get('id') !== action.result.id)
            );
        case contractActions.RESET:
            return initialState;
        case contractActions.RESET_CONTRACT_BUDGET:
            return state.set('loadedContractBudget', false);
        case contractActions.RESET_LOADED_CONTRACTS:
            return state.set('loadedContracts', false);
        case contractActions.SHOW_CONTRACT_COMPLAINT_MODAL:
            return state.merge(
                fromJS({
                    contractComplaintModalData: fromJS(action.data),
                    showContractComplaintModal: true,
                })
            );
        case contractActions.SHOW_DEPARTMENT_MODAL:
            return state.set('showDepartmentModal', true);
        case contractActions.SHOW_PERMISSIONS_MODAL:
            return state.set('showContractPermissionsModal', true);
        case contractActions.UPDATE:
            return state.merge(
                fromJS({
                    updatingContract: true,
                    updateContractError: null,
                })
            );
        case contractActions.UPDATE_FAIL:
            return state.merge(
                fromJS({
                    updatingContract: false,
                    updateContractError: action.error.message,
                })
            );
        case contractActions.UPDATE_BUDGET_SUCCESS:
            return state.setIn(['contract', 'budget'], fromJS(action.result));
        case contractActions.UPDATE_CONTACTS:
            return state.merge(
                fromJS({
                    manageContactsError: null,
                    managingContacts: true,
                })
            );
        case contractActions.UPDATE_CONTACTS_FAIL:
            return state.merge(
                fromJS({
                    manageContactsError: action.error.message,
                    managingContacts: false,
                })
            );
        case contractActions.UPDATE_CONTACTS_SUCCESS:
            return state.setIn(['contract', 'contacts'], fromJS(action.result)).merge(
                fromJS({
                    managingContacts: false,
                })
            );
        case contractActions.UPDATE_CONTRACT_COMPLAINT_SUCCESS:
            return state.setIn(
                ['contract', 'contractReviews'],
                state.getIn(['contract', 'contractReviews']).map((contractReview) => {
                    if (contractReview.get('id') !== action.result.contract_review_id) {
                        return contractReview;
                    }
                    return contractReview.set(
                        'contractComplaints',
                        contractReview.get('contractComplaints').map((contractComplaint) => {
                            if (contractComplaint.get('id') === action.result.id) {
                                return fromJS(action.result);
                            }
                            return contractComplaint;
                        })
                    );
                })
            );
        case contractActions.UPDATE_CONTRACT_REVIEW_SUCCESS:
            return state.setIn(
                ['contract', 'contractReviews'],
                state.getIn(['contract', 'contractReviews']).map((contractReview) => {
                    if (contractReview.get('id') === action.result.id) {
                        return fromJS(action.result);
                    }
                    return contractReview;
                })
            );
        case contractActions.UPDATE_INSURANCES:
            return state.merge(
                fromJS({
                    manageInsurancesError: null,
                    managingInsurances: true,
                })
            );
        case contractActions.UPDATE_INSURANCES_FAIL:
            return state.merge(
                fromJS({
                    manageInsurancesError: action.error.message,
                    managingInsurances: false,
                })
            );
        case contractActions.UPDATE_INSURANCES_SUCCESS:
            return state.setIn(['contract', 'contractInsurances'], fromJS(action.result)).merge(
                fromJS({
                    loadedContract: false, // Force contract to be reloaded as insurance milestones may have been added and should not be overridden
                    managingInsurances: false,
                })
            );
        case contractActions.UPDATE_SUCCESS:
            return state.merge(
                fromJS({
                    contract: fromJS(action.result),
                    updatingContract: false,
                })
            );
        case contractActions.UPDATE_MILESTONE_NOTIFICATIONS:
        case contractActions.UPDATE_MILESTONE_NOTIFICATIONS_FAIL:
        case contractActions.UPDATE_MILESTONE_NOTIFICATIONS_SUCCESS:
            return state.set(
                'contractMilestones',
                state
                    .get('contractMilestones')
                    .map((milestone) => contractMilestonesReducer(milestone, action))
            );
        case contractActions.UPDATE_RENEWAL:
            return state.merge(
                fromJS({
                    updatingRenewal: true,
                    updateRenewalError: null,
                })
            );
        case contractActions.UPDATE_RENEWAL_FAIL:
            return state.merge(
                fromJS({
                    updatingRenewal: false,
                    updateRenewalError: action.error.message,
                })
            );
        case contractActions.UPDATE_RENEWAL_SUCCESS:
            return state
                .merge(
                    fromJS({
                        updatingRenewal: false,
                    })
                )
                .setIn(
                    ['contract', 'contractRenewals'],
                    state.getIn(['contract', 'contractRenewals']).map((contractRenewal) => {
                        if (contractRenewal.get('id') === action.result.id) {
                            return fromJS(action.result);
                        }

                        return contractRenewal;
                    })
                );
        default:
            return state;
    }
}
