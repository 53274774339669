import { get } from 'lodash';

import { PROJECT_PROPERTIES_NAV } from './constants';

export const getProjectPropertiesNav = ({
    initiateSneakyUpdate,
    project,
    showFormErrors,
    formErrors = {},
    activeSectionId,
    setActiveSectionId,
}) => {
    return PROJECT_PROPERTIES_NAV.reduce((acc, navItem, index) => {
        if (navItem.shouldHide && navItem.shouldHide(project)) {
            return acc;
        }

        return acc.concat([
            {
                title: navItem.title,
                index,
                showValidationError:
                    showFormErrors && get(formErrors, ['projectProperties', navItem.errorsKey]),
                active: activeSectionId === index,
                onClick: () => {
                    initiateSneakyUpdate();
                    setActiveSectionId(index);
                },
                shouldHide: navItem.shouldHide,
            },
        ]);
    }, []);
};
