import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import { fieldNames, form } from './constants';
import { validate } from './validate';
import { getProjectJS } from '../selectors';
import { Button, InputText, SearchSelect, VendorSearchInput } from '../../../components';
import { hideVendorProjectInviteModal, inviteVendorsToProject } from '../../../actions/projectPost';
import { getVendorLists } from '../VendorManagement/selectors';

const { EMAILS, USER_EMAILS, VENDOR_LIST_ID } = fieldNames;

const mapStateToProps = (state) => {
    return {
        inviteError: state.projectPost.get('inviteVendorsError'),
        inviting: state.projectPost.get('invitingVendors'),
        project: getProjectJS(state),
        vendorLists: getVendorLists(state),
    };
};

const mapDispatchToProps = {
    hideModal: hideVendorProjectInviteModal,
    inviteVendorsToProject,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedVendorProjectInviteModal extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        invalid: PropTypes.bool.isRequired,
        inviteError: PropTypes.string,
        inviteVendorsToProject: PropTypes.func.isRequired,
        inviting: PropTypes.bool.isRequired,
        vendorLists: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
            })
        ),
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    inviteVendors = (rawData) => {
        const { hideModal, project } = this.props;

        const data = {};
        if (rawData[EMAILS]) {
            data[EMAILS] = rawData[EMAILS].split(',').map((email) => email.trim().toLowerCase());
        }

        if (rawData[USER_EMAILS]) {
            data[USER_EMAILS] = rawData[USER_EMAILS].map((userData) => userData.value);
        }

        if (rawData[VENDOR_LIST_ID]) {
            data[VENDOR_LIST_ID] = rawData[VENDOR_LIST_ID];
        }

        this.props.inviteVendorsToProject(project.id, data).then((usersOrError) => {
            if (!(usersOrError instanceof Error)) {
                hideModal();
            }
        });
    };

    renderBody() {
        const { handleSubmit, hideModal, invalid, inviteError, inviting, vendorLists } = this.props;

        return (
            <form onSubmit={handleSubmit(this.inviteVendors)}>
                <Field
                    closeMenuOnSelect={false}
                    component={VendorSearchInput}
                    disabled={inviting}
                    isClearable
                    isMulti
                    label="Search for a vendor"
                    name={USER_EMAILS}
                    optionValueField="email"
                />
                <div className="text-center text-muted">--- OR ---</div>
                <Field
                    component={InputText}
                    disabled={inviting}
                    hasFeedback={false}
                    help="Use commas to separate multiple emails"
                    label="Enter email addresses"
                    name={EMAILS}
                    placeholder="sales@globalcorp.com"
                    type="text"
                />
                {vendorLists.length > 0 && (
                    <>
                        <div className="text-center text-muted">--- OR ---</div>
                        <Field
                            component={SearchSelect}
                            disabled={inviting}
                            isClearable
                            label="Select a vendor list"
                            name={VENDOR_LIST_ID}
                            options={vendorLists.map((item) => {
                                return {
                                    label: item.name,
                                    value: item.id,
                                };
                            })}
                            placeholder="Select the vendor list to invite"
                        />
                    </>
                )}
                <div className="text-right">
                    <Button bsSize="sm" disabled={inviting} onClick={hideModal}>
                        Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button bsStyle="primary" disabled={invalid || inviting} type="submit">
                        <i className="fa fa-envelope" /> Invite to Project
                    </Button>
                </div>
                {inviteError && <div className="text-center error-block">{inviteError}</div>}
                {!invalid && (
                    <div className={`text-info ${this.styles.info}`}>
                        We will send email invitations to view and follow this project to the
                        specified vendors
                    </div>
                )}
            </form>
        );
    }

    render() {
        const { hideModal } = this.props;

        return (
            <Modal onHide={hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Invite Vendors to Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
            </Modal>
        );
    }
}

export const VendorProjectInviteModal = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedVendorProjectInviteModal);
