import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Box } from '@og-pro/ui';

import { form, fieldNames } from './constants';
import { validate } from './validate';
import { Button, Checkbox, InputText, SearchSelect, Well } from '../../../../../components';
import { CategorySearchInput } from '../../../..';
import { statusSelectOptions } from '../../selectors';
import { projectStatusFilterTypesDict } from '../../../../../../../shared_config/projects';

const {
    CATEGORIES,
    STATUS,
    PROJECT_ID,
    DEPARTMENT_ID,
    QUICK_SEARCH_QUERY,
    SEARCH_PRICE_ITEMS,
    TITLE,
} = fieldNames;

const { ALL: ALL_STATUS_FILTER } = projectStatusFilterTypesDict;

const formConfig = {
    enableReinitialize: true,
    form,
    validate,
};

// @reduxForm
class ConnectedGovProjectsFilterForm extends PureComponent {
    static propTypes = {
        change: PropTypes.func.isRequired,
        defaultCategorySet: PropTypes.number.isRequired,
        disabled: PropTypes.bool.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        isAdvancedSearch: PropTypes.bool,
        departmentSelectOptions: PropTypes.array,
        isLineItemFormActive: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            advancedSearchToggle: !!props.isAdvancedSearch,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    toggleAdvancedSearch = (e) => {
        const { change } = this.props;

        e.preventDefault();
        if (this.state.advancedSearchToggle) {
            this.removeAdvancedTextSearch();
            change(CATEGORIES, null);
        }

        this.setState((prevState) => {
            return {
                advancedSearchToggle: !prevState.advancedSearchToggle,
            };
        });
    };

    removeAdvancedTextSearch = () => {
        const { change } = this.props;

        change(TITLE, null);
        change(PROJECT_ID, null);
        change(QUICK_SEARCH_QUERY, null);
    };

    toggleSearchByLineItems = (isSearchByLineItem) => {
        const { change } = this.props;

        this.removeAdvancedTextSearch();

        // Change status to `all` when starting a line item search
        if (isSearchByLineItem) {
            change(STATUS, ALL_STATUS_FILTER);
        }
    };

    render() {
        const {
            defaultCategorySet,
            disabled,
            handleSubmit,
            departmentSelectOptions,
            isLineItemFormActive,
        } = this.props;

        const departmentSelectOptionsWithAll = [{ label: 'All Departments', value: 'all' }].concat(
            departmentSelectOptions
        );

        const btnIcon = disabled ? 'spinner fa-spin' : 'search';
        return (
            <Well>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <Field
                                aria-label="Filter by Department"
                                backspaceRemovesValue={false}
                                component={SearchSelect}
                                isClearable={false}
                                name={DEPARTMENT_ID}
                                options={departmentSelectOptionsWithAll}
                                overrideFeedback
                                placeholder="Filter by Department"
                                showValidation={false}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <Field
                                aria-label="Filter by Status"
                                backspaceRemovesValue={false}
                                component={SearchSelect}
                                isClearable={false}
                                name={STATUS}
                                options={statusSelectOptions}
                                overrideFeedback
                                placeholder="Filter by Status"
                                showValidation={false}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 text-right">
                            {!this.state.advancedSearchToggle && (
                                <>
                                    <Box
                                        bsStyle="primary"
                                        component={Button}
                                        disabled={disabled}
                                        mr={2}
                                        type="submit"
                                    >
                                        <i className={`fa fa-fw fa-${btnIcon}`} />
                                        &nbsp;Search
                                    </Box>

                                    <a href="" onClick={this.toggleAdvancedSearch}>
                                        Advanced Search
                                    </a>
                                </>
                            )}
                        </div>
                        <div
                            className={`col-xs-12 ${
                                this.state.advancedSearchToggle ? '' : 'hidden'
                            }`}
                            id="advancedSearch"
                        >
                            <div className="row">
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    {isLineItemFormActive ? (
                                        <Field
                                            aria-label="Search by Line Item Description"
                                            component={InputText}
                                            hasFeedback={false}
                                            name={QUICK_SEARCH_QUERY}
                                            placeholder="Search by Line Item Description"
                                            showValidation={false}
                                            type="text"
                                        />
                                    ) : (
                                        <>
                                            <Field
                                                aria-label="Search by Project Title"
                                                component={InputText}
                                                hasFeedback={false}
                                                name={TITLE}
                                                overrideFeedback
                                                placeholder="Search by Project Title"
                                                showValidation={false}
                                                type="text"
                                            />
                                            <Field
                                                aria-label="Enter Project ID"
                                                component={InputText}
                                                hasFeedback={false}
                                                name={PROJECT_ID}
                                                overrideFeedback
                                                placeholder="Enter Project ID"
                                                showValidation={false}
                                                type="text"
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <Field
                                        component={CategorySearchInput}
                                        defaultCategorySet={defaultCategorySet}
                                        disabled={disabled}
                                        disallowCustomCategories
                                        name={CATEGORIES}
                                        useSingleCodeSet
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <Field
                                        component={Checkbox}
                                        disabled={disabled}
                                        name={SEARCH_PRICE_ITEMS}
                                        onChange={this.toggleSearchByLineItems}
                                        text="Search by Line Items"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 text-right">
                                    <Box
                                        bsStyle="primary"
                                        component={Button}
                                        disabled={disabled}
                                        mr={2}
                                        type="submit"
                                    >
                                        <i className={`fa fa-fw fa-${btnIcon}`} />
                                        &nbsp;Search
                                    </Box>
                                    <a href="" onClick={this.toggleAdvancedSearch}>
                                        Simple Search
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Well>
        );
    }
}

export const GovProjectsFilterForm = reduxForm(formConfig)(ConnectedGovProjectsFilterForm);
