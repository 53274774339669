import React, { useEffect, useState } from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { userStatusTypes } from '../../../../shared_config/users';
import { tokenLogin } from '../../actions/auth';

const { ACTIVE, INVITED } = userStatusTypes;

export const RequireTokenInviteLogin = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.get('user'));
    const error = useSelector((state) => state.auth.get('tokenLoginError'));
    const [query] = useSearchParams();
    const { id, token } = Object.fromEntries(query);

    const doTokenLogin = async () => {
        dispatch(tokenLogin(id, token)).then(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        if (id && token && !user) {
            doTokenLogin();
        } else if (user) {
            setLoading(false);
        }
    }, []);

    if (!id || !token) {
        return <Navigate replace to="/not-found" />;
    }

    if (loading) {
        return null;
    }

    if (error) {
        return <Navigate replace to={`/not-found?${qs.stringify({ message: error.message })}`} />;
    }

    if (user) {
        if (user.get('status') === ACTIVE) {
            return <Navigate replace to="/" />;
        }

        if (user.get('status') !== INVITED) {
            return (
                <Navigate
                    replace
                    to={`/not-found?${qs.stringify({ message: 'Account could not be activated' })}`}
                />
            );
        }
    }

    return children;
};

RequireTokenInviteLogin.propTypes = {
    children: PropTypes.node.isRequired,
};
