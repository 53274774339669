import React, { useEffect } from 'react';
import { useRouteError, useNavigate } from 'react-router-dom';

import { NotFound } from '../../components';
import rollbar from '../../lib/rollbar';

// base component to handle errors thrown in loaders / actions
// expand as needed
export const GenericError = () => {
    const navigate = useNavigate();
    const error = useRouteError();
    const { status, data } = error;

    // remember this will run only in the client
    useEffect(() => {
        if (data?.redirectTo) {
            navigate(data.redirectTo);
        }
    }, [data, navigate]);

    // Report all unhandled errors to Rollbar
    useEffect(() => {
        if (status !== 404 && !data?.redirectTo) {
            rollbar.error(error);
        }
    }, [error, data, status]);

    if (status === 404) {
        return <NotFound />;
    }

    return 'There was an error processing your request. Please try again. If the problem persists, please contact support.';
};
