import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { isValid } from 'redux-form';

import { QuestionnaireReviewNoteForm } from './QuestionnaireReviewNoteForm';
import { form, NOTE } from './QuestionnaireReviewNoteForm/constants';
import { getQuestionnaireReviewNoteFormValues } from './selectors';
import { Button, OutlineButton } from '../..';
import { questionnaireReviewStatusTypes } from '../../../../../shared_config/checklists';

const { APPROVED, PENDING } = questionnaireReviewStatusTypes;

export const SwitchActionModal = ({
    actionSwitchHandler,
    hideModal,
    isGovernmentView,
    updateReviewError,
    updatingReview,
}) => {
    const [showValidation, setShowValidation] = useState(false);

    const questionnaireReviewNoteFormValues = useSelector(getQuestionnaireReviewNoteFormValues);
    const valid = useSelector((state) => isValid(form)(state));

    const submitHandler = (status) => () => {
        if (valid) {
            setShowValidation(false);
            return actionSwitchHandler(status, questionnaireReviewNoteFormValues[NOTE]);
        }
        setShowValidation(true);
    };

    const styles = require('./index.scss');
    const otherSide = isGovernmentView ? 'vendor' : 'agency';

    return (
        <Modal onHide={hideModal} show>
            <Modal.Header closeButton>
                <Modal.Title className="text-center">Approval Status Required</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                You are sending action over to the {otherSide}.<br />
                Please set your approval status on the response.
                <br />
                You may optionally provide a note along with your approval.
                <QuestionnaireReviewNoteForm
                    disabled={updatingReview}
                    showValidation={showValidation}
                />
                <div className={styles.switchActionButtons}>
                    <Button
                        bsSize="lg"
                        bsStyle="success"
                        className={styles.switchActionButton}
                        disabled={updatingReview}
                        onClick={submitHandler(APPROVED)}
                        qaTag="checklistSwitchActionModal-approved"
                    >
                        <i className="fa fa-check-square-o" /> Approved
                    </Button>
                    &nbsp;&nbsp;
                    <OutlineButton
                        bsSize="lg"
                        bsStyle="primary"
                        className={styles.switchActionButton}
                        disabled={updatingReview}
                        onClick={submitHandler(PENDING)}
                        qaTag="checklistSwitchActionModal-changesNeeded"
                    >
                        <i className="fa fa-refresh" /> Request Changes
                    </OutlineButton>
                    {updateReviewError && <div className="error-block">{updateReviewError}</div>}
                </div>
                Select &quot;Approved&quot; if item is approved and just needs {otherSide}&#39;s
                confirmation.
                <br />
                Select &quot;Request Changes&quot; if you need the {otherSide} to provide edits.
            </Modal.Body>
        </Modal>
    );
};

SwitchActionModal.propTypes = {
    actionSwitchHandler: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    isGovernmentView: PropTypes.bool,
    updateReviewError: PropTypes.string,
    updatingReview: PropTypes.bool,
};
