import { buildQaTag, componentNames } from '@og-pro/ui';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Panel } from 'react-bootstrap';

import { Collapse } from '..';
import { FiltersCountBadge } from '../FiltersDropdown/FiltersCountBadge';

export const FilterGroup = ({ title, groupFiltersSelected = 0, children, subtitle, className }) => {
    const styles = require('./index.scss');
    const [isOpen, setIsOpen] = useState(false);

    const tagWithComponentName = buildQaTag(`filterGroup-${title}`, componentNames.HEADING);

    return (
        <div className={className}>
            <Panel.Heading
                className={styles.heading}
                data-qa={tagWithComponentName}
                onClick={() => setIsOpen((prevState) => !prevState)}
            >
                <i
                    className={classNames(
                        `fa fa-fw`,
                        isOpen ? 'fa-angle-up' : 'fa-angle-right',
                        styles.caret
                    )}
                />
                <h3 className={styles.title}>{title}</h3>
                <div className={styles.filtersCountContainer}>
                    <FiltersCountBadge value={groupFiltersSelected} />
                </div>
            </Panel.Heading>
            {!isOpen && (
                <div className={styles.selectedOptions}>
                    <span>{subtitle}</span>
                </div>
            )}
            <Collapse in={isOpen} key={title}>
                {children}
            </Collapse>
        </div>
    );
};

FilterGroup.propTypes = {
    className: PropTypes.string,
    groupFiltersSelected: PropTypes.number,
    children: PropTypes.node,
    subtitle: PropTypes.string,
    title: PropTypes.string.isRequired,
};
