import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { getTextBasedResponseHelp } from '../../../utils';
import { RichTextInput } from '../../../..';
import { DATA, MAX_LENGTH } from '../../../../../../../shared_config/questionnaires';

export class LongAnswerResponseForm extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        input: PropTypes.object.isRequired,
        isOGThemeEnabledForComponents: PropTypes.func.isRequired,
        meta: PropTypes.object.isRequired,
        showValidation: PropTypes.bool,
    };

    render() {
        const { disabled, input, isOGThemeEnabledForComponents, meta, showValidation } = this.props;
        return (
            <RichTextInput
                disabled={disabled}
                help={getTextBasedResponseHelp(get(this.props, [DATA, MAX_LENGTH]), input.value)}
                input={input}
                meta={meta}
                minRows={3}
                minimalToolbar
                placeholder={isOGThemeEnabledForComponents ? null : 'Enter response'}
                removePastedWordFormatting
                showValidation={showValidation}
                useOpenGovStyle={isOGThemeEnabledForComponents}
                useSharedTextareaToolbar={isOGThemeEnabledForComponents}
            />
        );
    }
}
