import React from 'react';

import * as Gov from '../../containers/GovApp';
import { legacyFetchData } from '../loaders';

export default (getState, dispatch) => {
    return [
        {
            element: <Gov.EmergencyPortalNav />,
            path: 'emergency-portal',
            children: [
                {
                    element: <Gov.EmergencyProjectsList />,
                    loader: legacyFetchData(getState, dispatch, Gov.EmergencyProjectsList),
                    index: true,
                },
                {
                    element: <Gov.EmergencyProjectsList />,
                    loader: legacyFetchData(getState, dispatch, Gov.EmergencyProjectsList),
                    path: 'projects',
                },
                {
                    element: <Gov.EmergencyContractsList />,
                    loader: legacyFetchData(getState, dispatch, Gov.EmergencyContractsList),
                    path: 'contracts',
                },
                {
                    element: <Gov.EmergencyVendorsList />,
                    loader: legacyFetchData(getState, dispatch, Gov.EmergencyVendorsList),
                    path: 'vendors',
                },
            ],
        },
    ];
};
