import classNames from 'classnames';
import { upperCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Typography } from '@og-pro/ui';

import { RequisitionsCreateActionButtons } from './RequisitionsCreateActionButtons';
import { RequisitionsCreateActionError } from './RequisitionsCreateActionError';
import { RequisitionsCreateFormError } from './RequisitionCreateFormError';
import { StatusLabel } from '../../../../components';
import { getRequisitionStatusData } from '../../../../../../shared_config/requisitions';

export const RequisitionsCreateHeader = ({
    disabled,
    requestId,
    saveRequisition,
    showFormValidation,
    showGlobalFormValidation,
    status,
}) => {
    const styles = require('./index.scss');
    const { bsStyle, shortName } = getRequisitionStatusData(status);

    return (
        <div className={styles.headerContainer}>
            <div className="row">
                <div className="col-md-8">
                    <div className={styles.titleContainer}>
                        <h1>Request #{requestId}</h1>
                        <StatusLabel bsStyle={bsStyle} className={styles.statusLabel}>
                            {upperCase(shortName)}
                        </StatusLabel>
                    </div>
                    <Typography variant="body2">
                        All fields marked with an * are required.
                    </Typography>
                </div>
                <div className="col-md-4">
                    <RequisitionsCreateActionButtons
                        disabled={disabled}
                        saveRequisition={saveRequisition}
                        showGlobalFormValidation={showGlobalFormValidation}
                    />
                </div>
                <RequisitionsCreateActionError />
            </div>
            {showFormValidation && (
                <div className={classNames('row', styles.formErrorContainer)}>
                    <RequisitionsCreateFormError />
                </div>
            )}
        </div>
    );
};

RequisitionsCreateHeader.propTypes = {
    disabled: PropTypes.bool,
    requestId: PropTypes.string.isRequired,
    saveRequisition: PropTypes.func.isRequired,
    showGlobalFormValidation: PropTypes.func.isRequired,
    showFormValidation: PropTypes.bool.isRequired,
    status: PropTypes.number.isRequired,
};
