import { listToDict } from '../../../../../../shared_config/helpers';

export const form = 'templateAdminForm';

export const fields = ['projectSections', 'template', 'useSectionDividers'];

export const fieldNames = listToDict(fields);

export const formSections = [
    'template',
    'upfrontQuestionnaire',
    'sections',
    'content',
    'automationLogic',
];

export const formSectionNames = listToDict(formSections);

// Fake field used for verifying if the specified section is valid
export const FORM_SECTIONS_KEY = 'sections';

// Fake field used for verifying if entire form is valid
export const ALL_SECTIONS = 'allSections';

export const incompleteSectionInstructions = {
    [formSectionNames.TEMPLATE]: 'Finish naming your template',
    [formSectionNames.UPFRONT_QUESTIONNAIRE]: 'Your configuration questions are invalid',
    [formSectionNames.SECTIONS]: 'Your section configuration is invalid',
    [formSectionNames.CONTENT]: 'Your template content is invalid',
    [formSectionNames.AUTOMATION_LOGIC]: 'Your automation logic is invalid',
};

export const SHARED_SECTION_TEMP_FIELDNAME = 'template_shared_section';
export const ADD_SECTION_TEMP_FORM = 'templateAdminSharedSectionTempForm';
export const ADD_SECTION_TEMP_FIELDNAME = 'add_section';
