import { createSelector } from 'reselect';

import { getRequisitionCurrentStep, getRequisitionJS } from '../../../selectors/govApp';
import { isRequisitionClosed, isRequisitionDraft } from '../../../../../shared_config/requisitions';

export const getRequisitionBudgetOverrideData = createSelector(
    [getRequisitionJS, getRequisitionCurrentStep],
    (requisition, currentStep) => {
        if (!requisition || isRequisitionClosed(requisition.status)) {
            return {
                allowOverBudget: false,
                requireOverBudgetNote: false,
            };
        }
        if (isRequisitionDraft(requisition.status)) {
            return {
                allowOverBudget: requisition.reviewGroup?.allowOverBudget,
                requireOverBudgetNote: requisition.reviewGroup?.requireOverBudgetNote,
            };
        }
        return {
            allowOverBudget: currentStep?.allowOverBudget,
            requireOverBudgetNote: currentStep?.requireOverBudgetNote,
        };
    }
);
