import React, { useContext } from 'react';
import { formValueSelector } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import { QuestionnaireMultipleChoiceFormContext } from '../../../../../../../components/contexts';
import { form } from '../../../../forms/constants';
import { fieldNames } from '../../../../forms/TemplateUpfrontQuestionsForm/constants';
import { TemplateUpfrontQuestionsForm, TemplateQuestionLogicForm } from '../../../../forms';
import {
    BaseSectionsNavBackButton,
    BaseSectionsNavBackNextButtons,
} from '../../../../../../../components/SDv2/ProjectSectionsNav';
import { SDv2EditorLayout } from '../../../../../../../components/SDv2/Layout';
import { QuestionsAutomationNav } from '../../components/QuestionsAutomationNav';
import {
    NavScrollContainer,
    SectionHeaderViewTitleOnly,
} from '../../../../../../../components/SDv2';
import { TemplateEditV2FunctionsContext, TemplateEditV2NavContext } from '../../../context';
import { showQuestionLogicModal } from '../../../../../../../actions/templatesAdmin';
import { QUESTIONS_AUTOMATION_SECTION_TITLES } from '../../../constants';

const { UPFRONT_QUESTIONS } = fieldNames;
const selector = formValueSelector(form);

export const TemplateEditV2QuestionsAutomation = () => {
    const dispatch = useDispatch();
    const { activeSectionId, setActiveSectionId } = useContext(TemplateEditV2NavContext);
    const editContext = useContext(TemplateEditV2FunctionsContext);
    const upfrontQuestions = useSelector((state) => selector(state, UPFRONT_QUESTIONS));

    const onShowQuestionLogicModal = (data, index) => dispatch(showQuestionLogicModal(data, index));

    return (
        <SDv2EditorLayout
            content={
                <>
                    {/* The child is inside a bootstrap col, expects a row as a parent */}
                    {activeSectionId === 0 && (
                        <div>
                            <QuestionnaireMultipleChoiceFormContext.Provider
                                value={{
                                    isEditingDisabledSet:
                                        editContext.upfrontQuestionsUsedInQuestionLogicSet,
                                }}
                            >
                                <TemplateUpfrontQuestionsForm
                                    addConditionalLogicHandler={
                                        editContext.addConditionalLogicHandler
                                    }
                                    array={editContext.array}
                                    change={editContext.change}
                                    createTemplateQuestion={editContext.createTemplateQuestion}
                                    createUpfrontQuestion={editContext.createUpfrontQuestion}
                                    createUpfrontQuestionError={
                                        editContext.createUpfrontQuestionError
                                    }
                                    disabled={editContext.disabled}
                                    isDirty={editContext.dirty}
                                    showFormValidation={editContext.showValidation}
                                    tagOptions={editContext.tagOptions}
                                    templateVariableOptions={editContext.templateVariableOptions}
                                    updateTemplate={editContext.updateTemplate}
                                    upfrontQuestions={upfrontQuestions || []}
                                />
                            </QuestionnaireMultipleChoiceFormContext.Provider>
                        </div>
                    )}
                    {activeSectionId === 1 && (
                        <TemplateQuestionLogicForm
                            deleteQuestionLogicHandler={editContext.removeQuestionLogicHandler}
                            disabled={editContext.disabled}
                            showFormValidation={editContext.showValidation}
                            showQuestionLogicModal={onShowQuestionLogicModal}
                        />
                    )}
                </>
            }
            footer={
                <BaseSectionsNavBackNextButtons
                    firstSectionComponent={
                        <BaseSectionsNavBackButton
                            onClick={() => {
                                editContext.updateTemplateIfDirty();
                                editContext.router.push(
                                    editContext.buildRouteFunction('attachments')
                                );
                            }}
                        >
                            Attachments
                        </BaseSectionsNavBackButton>
                    }
                    sections={[
                        {
                            title: QUESTIONS_AUTOMATION_SECTION_TITLES[0].title,
                            index: 0,
                            active: activeSectionId === 0,
                            onClick: () => {
                                editContext.updateTemplateIfDirty();
                                setActiveSectionId(0);
                            },
                        },
                        {
                            title: QUESTIONS_AUTOMATION_SECTION_TITLES[1].title,
                            index: 1,
                            active: activeSectionId === 1,
                            onClick: () => {
                                editContext.updateTemplateIfDirty();
                                setActiveSectionId(1);
                            },
                        },
                    ]}
                />
            }
            header={
                <SectionHeaderViewTitleOnly
                    description={QUESTIONS_AUTOMATION_SECTION_TITLES[activeSectionId]?.description}
                    title={QUESTIONS_AUTOMATION_SECTION_TITLES[activeSectionId]?.title}
                />
            }
            navigation={
                <NavScrollContainer>
                    <QuestionsAutomationNav />
                </NavScrollContainer>
            }
        />
    );
};
