import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';
import { Box } from '@og-pro/ui';

import { fieldNames, form, OTHER } from './constants';
import { RejectionNoteForm } from './RejectionNoteForm';
import { getRejectionNoteModalData } from './selectors';
import { qaTagPageName } from '../../../constants';
import {
    hideRejectionNoteModal,
    submitApproval,
} from '../../../../../../actions/requisitionsApprovals';
import { Button } from '../../../../../../components';
import { approvalStatusTypes } from '../../../../../../../../shared_config/requisitions';

const { REJECTION_NOTE_EXTRA, REJECTION_NOTE, REJECTION_POLICY } = fieldNames;

export const RejectionNoteModal = ({ requireRejectionNote }) => {
    const dispatch = useDispatch();

    const { showModal, stepPositionApprover } = useSelector(getRejectionNoteModalData);
    const hideModal = () => dispatch(hideRejectionNoteModal());

    const onSubmit = (rawData) => {
        // If `OTHER` is selected, take the value from `REJECTION_NOTE_EXTRA` instead
        const rejectionNote =
            rawData[REJECTION_NOTE] === OTHER
                ? rawData[REJECTION_NOTE_EXTRA]
                : rawData[REJECTION_NOTE];
        const rejectionPolicy = JSON.parse(rawData[REJECTION_POLICY]);

        // Call the submit handler with the formatted data
        dispatch(
            submitApproval(
                stepPositionApprover.id,
                {
                    rejectionNote,
                    rejectionPolicy,
                    status: approvalStatusTypes.REJECTED,
                },
                { onSuccess: hideModal }
            )
        );
    };

    return (
        <Modal onHide={hideModal} show={showModal}>
            <Modal.Header closeButton>
                <Modal.Title className="text-left">Justification for Rejection</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RejectionNoteForm
                    onSubmit={onSubmit}
                    requireRejectionNote={requireRejectionNote}
                />
                <Box textAlign="right">
                    <Button bsSize="lg" onClick={hideModal} qaTag={`${qaTagPageName}-cancel`}>
                        Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        bsSize="lg"
                        bsStyle="danger"
                        onClick={() => dispatch(submit(form))}
                        qaTag={`${qaTagPageName}-reject`}
                    >
                        <i className="fa fa-ban" /> Reject
                    </Button>
                </Box>
            </Modal.Body>
        </Modal>
    );
};

RejectionNoteModal.propTypes = {
    requireRejectionNote: PropTypes.bool,
};
