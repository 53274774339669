import { Box } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { ReviewSequenceTitleMenuButtons } from './ReviewSequenceTitleMenuButtons';
import { TemplateStatusLabel } from '../../../../components';
import { statusTypes } from '../../../../../../shared_config/reviewSequences';

const { ARCHIVED, PUBLISHED } = statusTypes;

export const ReviewSequenceTitleMenu = ({
    deleting,
    disabled,
    reviewSequence,
    reviewSequence: { isException, status },
    reviewSequencesPath,
    setShowFormValidation,
    submitFailed,
    title,
    toggleArchive,
    toggleLive,
    updateReviewSequence,
    updating,
}) => {
    const deleteError = useSelector((state) => state.reviewSequences.get('deleteItemError'));
    const updateError = useSelector((state) => state.reviewSequences.get('updateItemError'));
    const copyError = useSelector((state) => state.reviewSequences.get('copyItemError'));
    const setDefaultError = useSelector((state) => state.reviewSequences.get('setDefaultError'));

    const styles = require('./ReviewSequenceTitleMenu.scss');

    return (
        <div className={styles.titleContainer}>
            <div className="row">
                <div className="col-sm-7 col-md-8">
                    <h3 className={styles.title}>{title || 'Untitled'}</h3>
                    <TemplateStatusLabel
                        className={styles.statusLabel}
                        isArchived={status === ARCHIVED}
                        isPublished={status === PUBLISHED}
                    />
                    {isException && (
                        <Box marginTop={0.5}>
                            <i>Exception Sequence</i>
                        </Box>
                    )}
                </div>
                <div className="col-sm-5 col-md-4 text-right">
                    <ReviewSequenceTitleMenuButtons
                        deleting={deleting}
                        disabled={disabled}
                        reviewSequence={reviewSequence}
                        reviewSequencesPath={reviewSequencesPath}
                        setShowFormValidation={setShowFormValidation}
                        toggleArchive={toggleArchive}
                        toggleLive={toggleLive}
                        updateReviewSequence={updateReviewSequence}
                        updating={updating}
                    />
                </div>
            </div>
            {updateError && <div className="error-block text-right">{updateError}</div>}
            {submitFailed && (
                <div className="error-block text-right">
                    Please fix form errors before submitting
                </div>
            )}
            {deleteError && <div className="error-block text-right">{deleteError}</div>}
            {copyError && <div className="error-block text-right">{copyError}</div>}
            {setDefaultError && <div className="error-block text-right">{setDefaultError}</div>}
        </div>
    );
};

ReviewSequenceTitleMenu.propTypes = {
    deleting: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    reviewSequence: PropTypes.shape({
        isException: PropTypes.bool.isRequired,
        status: PropTypes.number.isRequired,
    }).isRequired,
    reviewSequencesPath: PropTypes.string.isRequired,
    setShowFormValidation: PropTypes.func.isRequired,
    submitFailed: PropTypes.bool.isRequired,
    title: PropTypes.string,
    toggleArchive: PropTypes.func.isRequired,
    toggleLive: PropTypes.func.isRequired,
    updateReviewSequence: PropTypes.func.isRequired,
    updating: PropTypes.bool.isRequired,
};
