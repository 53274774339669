import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { defaultError } from '../../constants';

export class LoadingError extends PureComponent {
    static propTypes = {
        error: PropTypes.string,
        info: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        noOffset: PropTypes.bool,
        onClick: PropTypes.func,
    };

    get styles() {
        return require('./LoadingError.scss');
    }

    render() {
        const { error, info, noOffset, onClick } = this.props;

        const icon = error ? 'exclamation-triangle' : 'refresh';

        return (
            <div className="row">
                <div
                    className={classnames(
                        this.styles.loadingError,
                        'text-danger',
                        noOffset ? 'col-xs-12' : 'col-sm-offset-2 col-sm-8',
                        onClick && this.styles.clickHandler
                    )}
                    onClick={onClick}
                >
                    <div className={this.styles.loadingErrorIcon}>
                        <i className={`fa fa-${icon} fa-2x`} />
                    </div>
                    <div className={this.styles.errorMessage}>{error || defaultError}</div>
                    {info && <div className={this.styles.info}>{info}</div>}
                </div>
            </div>
        );
    }
}
