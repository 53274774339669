import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { closeOutProjectForm } from '../constants';
import { CLOSE_OUT_REASON, getCloseOutOptions } from './constants';
import { MultiChoiceWithOtherForm } from '../MultiChoiceWithOtherForm';
import { getBuilderDisplayName } from '../../../../../../shared_config/projects';

export class CloseOutProjectForm extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        project: PropTypes.shape({
            isIntake: PropTypes.bool.isRequired,
            wasPosted: PropTypes.bool.isRequired,
        }).isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    render() {
        const { disabled, onSubmit, project } = this.props;

        const displayName = getBuilderDisplayName(project).toLowerCase();

        const info =
            `Closing the ${displayName} will remove it from the list of active ${displayName}s.\n\n` +
            `Please specify why the ${displayName} is being closed out.`;

        return (
            <MultiChoiceWithOtherForm
                characterLimit={80}
                disabled={disabled}
                fieldName={CLOSE_OUT_REASON}
                form={closeOutProjectForm}
                info={info}
                note={
                    project.wasPosted ? 'The close out reason will be publicly visible.' : undefined
                }
                onSubmit={onSubmit}
                options={getCloseOutOptions(project)}
                otherLabel="Other Reason for Closing"
            />
        );
    }
}
