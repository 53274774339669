import { pick } from 'lodash';
import { browserHistory } from '@og-pro-migration-tools/react-router';

import {
    hideConfirmationModal,
    updatingConfirmationModal,
    showConfirmationModalError,
} from './confirmation';
import { hasEvaluation } from './evaluations';
import { UPDATE_SUCCESS as UPDATE_PROJECT_SUCCESS, showInstructionsModal } from './govProjects';
import { showSnackbar, showNotification } from './notification';
import { emitProjectSocket } from './utils';
import { trackEvent } from '../helpers';
import { projectStatusesDict } from '../../../shared_config/projects';

const { AWARD_PENDING, EVALUATION } = projectStatusesDict;

export const LOAD = 'gov/proposalEvaluations/LOAD';
export const LOAD_SUCCESS = 'gov/proposalEvaluations/LOAD_SUCCESS';
export const LOAD_FAIL = 'gov/proposalEvaluations/LOAD_FAIL';

export function loadProposalToEvaluate(projectId, proposalId, evaluatorId) {
    return (dispatch, getState, client) => {
        const params = { evaluatorId };
        dispatch({ type: LOAD });
        return client
            .get(`/project/${projectId}/proposal/${proposalId}/evaluation`, { params })
            .then((result) => {
                dispatch({ type: LOAD_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_FAIL, error });
            });
    };
}

export function shouldLoadProposalToEvaluate(state, proposalId) {
    if (!hasEvaluation(state)) return false;
    if (state.proposalEvaluations.get('loading')) return false;
    if (!state.proposalEvaluations.get('loaded')) return true;
    return state.proposalEvaluations.getIn(['proposal', 'id']) !== proposalId;
}

export const RESET_PROPOSAL_TO_EVALUATE = 'gov/proposalEvaluations/RESET_PROPOSAL_TO_EVALUATE';

export function resetProposalToEvaluation() {
    return { type: RESET_PROPOSAL_TO_EVALUATE };
}

export const LOAD_AGGREGATE = 'gov/proposalEvaluations/LOAD_AGGREGATE';
export const LOAD_AGGREGATE_SUCCESS = 'gov/proposalEvaluations/LOAD_AGGREGATE_SUCCESS';
export const LOAD_AGGREGATE_FAIL = 'gov/proposalEvaluations/LOAD_AGGREGATE_FAIL';
export const BACKGROUND_RELOAD_AGGREGATE = 'gov/proposalEvaluations/BACKGROUND_RELOAD_AGGREGATE';

export function shouldReloadAggregateEvaluations(state) {
    if (state.proposalEvaluations.get('loadingAggregate')) return false;
    const nextUpdateDate = state.proposalEvaluations.get('aggregateEvaluationsUpdateDate');
    return !nextUpdateDate || new Date() > nextUpdateDate;
}

export function loadAggregateProposalEvaluations(projectId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_AGGREGATE });
        return client
            .get(`/project/${projectId}/proposal-evaluation-aggregate`)
            .then((result) => dispatch({ type: LOAD_AGGREGATE_SUCCESS, result }))
            .catch((error) => dispatch({ type: LOAD_AGGREGATE_FAIL, error }));
    };
}

// `broadcastAll` option should only be used when all connected users should have their scorecards
// overridden. Happens when there is an event that effects all scorecards such as when the admin
// scorecard is submitted/unsubmitted. In most other cases we just need to update the totals as
// updates to individual scorecards are handled without needing to override all scorecards.
export function backgroundReloadAggregateProposalEvaluations(projectId, broadcastAll = false) {
    return (dispatch, getState, client) => {
        return client
            .get(`/project/${projectId}/proposal-evaluation-aggregate`)
            .then((proposals) => {
                if (broadcastAll) {
                    const action = { type: LOAD_AGGREGATE_SUCCESS, result: proposals };
                    return dispatch(emitProjectSocket(projectId, action, null));
                }
                const result = proposals.map((prop) =>
                    pick(prop, ['id', 'scoringCriteria', 'totalScore', 'totalScoreByPoints'])
                );
                const action = { type: BACKGROUND_RELOAD_AGGREGATE, result };
                dispatch(emitProjectSocket(projectId, action, null));
            });
    };
}

export const SHOW_SCORECARD_MODAL = 'gov/proposalEvaluations/SHOW_SCORECARD_MODAL';
export const HIDE_SCORECARD_MODAL = 'gov/proposalEvaluations/HIDE_SCORECARD_MODAL';

export function showEvaluationScorecard(proposalId, userId) {
    return { type: SHOW_SCORECARD_MODAL, data: { proposalId, userId } };
}

export function hideEvaluationScorecard() {
    return { type: HIDE_SCORECARD_MODAL };
}

export const SHOW_NOTES_MODAL = 'gov/proposalEvaluations/SHOW_NOTES_MODAL';
export const HIDE_NOTES_MODAL = 'gov/proposalEvaluations/HIDE_NOTES_MODAL';

export function showEvaluationNotes(proposalId, userId) {
    return { type: SHOW_NOTES_MODAL, data: { proposalId, userId } };
}

export function hideEvaluationNotes() {
    return { type: HIDE_NOTES_MODAL };
}

export const SHOW_PROPOSAL_SELECT_MODAL = 'gov/proposalEvaluations/SHOW_PROPOSAL_SELECT_MODAL';
export const HIDE_PROPOSAL_SELECT_MODAL = 'gov/proposalEvaluations/HIDE_PROPOSAL_SELECT_MODAL';

export function showProposalSelect() {
    return { type: SHOW_PROPOSAL_SELECT_MODAL };
}

export function hideProposalSelect() {
    return { type: HIDE_PROPOSAL_SELECT_MODAL };
}

export const UPDATE = 'gov/proposalEvaluations/UPDATE';
export const UPDATE_SUCCESS = 'gov/proposalEvaluations/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'gov/proposalEvaluations/UPDATE_FAIL';

export function updateProposalEvaluation(proposal, data, evaluatorId, isConsensus, notification) {
    return (dispatch, getState, client) => {
        const message = notification || 'Evaluation Updated';
        const {
            id: proposalId,
            project_id: projectId,
            proposalEvaluation: { id: proposalEvaluationId },
        } = proposal;
        const params = {
            evaluatorId: isConsensus ? 'consensus' : evaluatorId,
        };
        dispatch({ type: UPDATE, proposalId, proposalEvaluationId, isConsensus });
        const route = `/project/${projectId}/proposal/${proposalId}/evaluation`;
        return client
            .put(route, { data, params })
            .then((result) => {
                dispatch(showSnackbar(message));

                const action = {
                    type: UPDATE_SUCCESS,
                    result,
                    proposalId,
                    proposalEvaluationId,
                    isConsensus,
                };
                const scorecardName = result.user ? `${result.user.displayName}'s` : 'Consensus';
                const broadcastMessage = `${scorecardName} scorecard updated`;
                dispatch(emitProjectSocket(projectId, action, broadcastMessage));

                dispatch(backgroundReloadAggregateProposalEvaluations(projectId));
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_FAIL,
                    error,
                    proposalId,
                    proposalEvaluationId,
                    isConsensus,
                });
            });
    };
}

export function updateProposalCriteriaScores(proposal, data, evaluatorId, isConsensus, opts = {}) {
    return (dispatch, getState, client) => {
        const {
            id: proposalId,
            project_id: projectId,
            proposalEvaluation: { id: proposalEvaluationId },
        } = proposal;
        const params = { evaluatorId: isConsensus ? 'consensus' : evaluatorId };
        const route = `/project/${projectId}/proposal/${proposalId}/evaluation/proposal-criteria-scores`;
        return client.put(route, { data, params }).then((result) => {
            const action = {
                type: UPDATE_SUCCESS,
                result,
                proposalId,
                proposalEvaluationId,
                isConsensus,
            };

            // Sometimes we update then immediately submit, so do not need to dispatch update action
            if (opts.skipDispatch) {
                return dispatch(action);
            }

            const scorecardName = result.user ? `${result.user.displayName}'s` : 'Consensus';
            const broadcastMessage = `${scorecardName} scorecard updated`;
            dispatch(showSnackbar('Scorecard Saved'));
            dispatch(emitProjectSocket(projectId, action, broadcastMessage));
            dispatch(backgroundReloadAggregateProposalEvaluations(projectId));
        });
    };
}

export const SELECT_PROPOSAL = 'gov/proposalEvaluations/SELECT_PROPOSAL';
export const SELECT_PROPOSAL_SUCCESS = 'gov/proposalEvaluations/SELECT_PROPOSAL_SUCCESS';
export const SELECT_PROPOSAL_FAIL = 'gov/proposalEvaluations/SELECT_PROPOSAL_FAIL';

export function selectProposals(data) {
    return (dispatch, getState, client) => {
        const project = getState().govProjects.get('selectedProject');
        const projectId = project.get('id');
        const govId = project.get('government_id');
        const nextRoute = `/governments/${govId}/projects/${projectId}/evaluation/selected-proposal`;

        dispatch({ type: SELECT_PROPOSAL });
        return client
            .put(`/project/${projectId}/evaluation/select-proposal`, { data })
            .then((result) => {
                const selectAction = { type: SELECT_PROPOSAL_SUCCESS, result };
                dispatch(emitProjectSocket(projectId, selectAction));
                dispatch(hideProposalSelect());

                const isAwardPending = result.status === AWARD_PENDING;
                trackEvent(`Project ${isAwardPending ? 'Recommended' : 'Awarded'}`);
                trackEvent('Project Status Update', {
                    projectId,
                    oldStatus: EVALUATION,
                    newStatus: result.status,
                });

                // Show the evaluations instruction modal
                browserHistory.push(nextRoute);
                const instructionModal = isAwardPending ? 'awardPending' : 'evaluationComplete';
                return dispatch(showInstructionsModal(instructionModal));
            })
            .catch((error) => dispatch({ type: SELECT_PROPOSAL_FAIL, error }));
    };
}

// Unawarding only happens within the confirmation modal
export function unawardProject(projectId) {
    return (dispatch, getState, client) => {
        dispatch(updatingConfirmationModal());
        return client
            .put(`/project/${projectId}/evaluation/unaward`)
            .then((result) => {
                dispatch(hideConfirmationModal());
                dispatch(showNotification('Evaluation unawarded!'));

                // Use same action as select proposal since end result is same
                const selectAction = { type: SELECT_PROPOSAL_SUCCESS, result };
                return dispatch(emitProjectSocket(projectId, selectAction));
            })
            .catch((error) => {
                dispatch(showConfirmationModalError(error.message));
            });
    };
}

// Award finalizing only happens within the confirmation modal
export function finalizeAward(project, data) {
    return (dispatch, getState, client) => {
        const { government_id: govId, id } = project;
        const nextRoute = `/governments/${govId}/projects/${id}/evaluation/selected-proposal`;
        dispatch(updatingConfirmationModal());

        return client
            .put(`/project/${id}/evaluation/finalize-award`, { data })
            .then((result) => {
                // Use same action as select proposal since end result is same
                const selectAction = { type: SELECT_PROPOSAL_SUCCESS, result };
                dispatch(emitProjectSocket(id, selectAction));
                dispatch(hideConfirmationModal());

                // Show the evaluations instruction modal
                browserHistory.push(nextRoute);
                return dispatch(showInstructionsModal('evaluationComplete'));
            })
            .catch((error) => {
                dispatch(showConfirmationModalError(error.message));
            });
    };
}

export const UPDATE_DISPLAY_SETTINGS = 'gov/proposalEvaluations/UPDATE_DISPLAY_SETTINGS';

export function updateDisplaySettings(displayOptions) {
    return { type: UPDATE_DISPLAY_SETTINGS, displayOptions };
}

export const LOAD_LINE_ITEM_AWARDS = 'gov/proposalEvaluations/LOAD_LINE_ITEM_AWARDS';
export const LOAD_LINE_ITEM_AWARDS_SUCCESS =
    'gov/proposalEvaluations/LOAD_LINE_ITEM_AWARDS_SUCCESS';
export const LOAD_LINE_ITEM_AWARDS_FAIL = 'gov/proposalEvaluations/LOAD_LINE_ITEM_AWARDS_FAIL';

export function loadLineItemAwards(projectId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_LINE_ITEM_AWARDS });
        return client
            .get(`/project/${projectId}/line-item-awards`)
            .then((result) => {
                dispatch({ type: LOAD_LINE_ITEM_AWARDS_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_LINE_ITEM_AWARDS_FAIL, error });
            });
    };
}

export function deleteLineItemAwards(projectId) {
    return (dispatch, getState, client) => {
        return client
            .del(`/project/${projectId}/line-item-awards`)
            .then(() => {
                dispatch(loadLineItemAwards(projectId));
            })
            .catch(() => {
                dispatch(showSnackbar('Reset Failed', { isError: true }));
            });
    };
}

export function awardLowestLineItemAwards(projectId) {
    return (dispatch, getState, client) => {
        return client
            .post(`/project/${projectId}/line-item-awards/award-lowest-cost`)
            .then(() => {
                dispatch(loadLineItemAwards(projectId));
            })
            .catch(() => {
                dispatch(showSnackbar('Awarding Failed', { isError: true }));
            });
    };
}

// for create and update line item awards, their functionality is embedded in the cells of aggrid
// if we try to update the lineItems on the state, it will re-render the whole table every time,
// instead, we'll return a promise immediately, which is thenable/catchable in the component,
// and can handle result/error states directly

export function createLineItemAward(projectId, vendorPriceItemId, awardType) {
    return (dispatch, getState, client) => {
        return client.post(`/project/${projectId}/line-item-awards`, {
            data: { vendorPriceItemId, awardType },
        });
    };
}

export function updateLineItemAward(projectId, vendorPriceItemId, awardTypeId, awardType) {
    return (dispatch, getState, client) => {
        let result;
        if (awardType === 'no award') {
            result = client.del(`/project/${projectId}/line-item-awards/${awardTypeId}`);
        } else {
            result = client.put(`/project/${projectId}/line-item-awards/${awardTypeId}`, {
                data: { awardType },
            });
        }

        return result;
    };
}

export const LOAD_BID_TABULATIONS = 'gov/proposalEvaluations/LOAD_BID_TABULATIONS';
export const LOAD_BID_TABULATIONS_SUCCESS = 'gov/proposalEvaluations/LOAD_BID_TABULATIONS_SUCCESS';
export const LOAD_BID_TABULATIONS_FAIL = 'gov/proposalEvaluations/LOAD_BID_TABULATIONS_FAIL';
export const LOAD_BID_TABULATIONS_TOTALS = 'gov/proposalEvaluations/LOAD_BID_TABULATIONS_TOTALS';
export const LOAD_BID_TABULATIONS_TOTALS_SUCCESS =
    'gov/proposalEvaluations/LOAD_BID_TABULATIONS_TOTALS_SUCCESS';
export const LOAD_BID_TABULATIONS_TOTALS_FAIL =
    'gov/proposalEvaluations/LOAD_BID_TABULATIONS_TOTALS_FAIL';
export const SET_SELECTED_BID_TABULATION_PRICE_ITEMS =
    'gov/proposalEvaluations/SET_SELECTED_BID_TABULATION_PRICE_ITEMS';
export const SET_SELECTED_BID_TABULATION_PRICE_ITEMS_SUCCESS =
    'gov/proposalEvaluations/SET_SELECTED_BID_TABULATION_PRICE_ITEMS_SUCCESS';
export const SET_SELECTED_BID_TABULATION_PRICE_ITEMS_FAIL =
    'gov/proposalEvaluations/SET_SELECTED_BID_TABULATION_PRICE_ITEMS_FAIL';
export const RESET_BID_TABULATIONS = 'gov/proposalEvaluations/RESET_BID_TABULATIONS';

export function resetBidTabulations() {
    return { type: RESET_BID_TABULATIONS };
}

export function loadBidTabulations(projectId, options = {}) {
    return (dispatch, getState, client) => {
        if (options.loadTotals) {
            dispatch({ type: LOAD_BID_TABULATIONS_TOTALS });
        } else {
            dispatch({ type: LOAD_BID_TABULATIONS });
        }

        const totals = options.loadTotals ? true : undefined;

        return client
            .get(`/project/${projectId}/bid-tabulation`, { params: { totals } })
            .then((result) => {
                if (options.loadTotals) {
                    dispatch({ type: LOAD_BID_TABULATIONS_TOTALS_SUCCESS, result });
                } else {
                    dispatch({ type: LOAD_BID_TABULATIONS_SUCCESS, result });
                }
            })
            .catch((error) => {
                if (options.loadTotals) {
                    dispatch({ type: LOAD_BID_TABULATIONS_TOTALS_FAIL, error });
                } else {
                    dispatch({ type: LOAD_BID_TABULATIONS_FAIL, error });
                }
            });
    };
}

export function loadBidTabulationsWithTotals(projectId) {
    return loadBidTabulations(projectId, { loadTotals: true });
}

export function setSelectedBidTabulationPriceItems(projectId, priceItemIds) {
    return (dispatch, getState, client) => {
        dispatch({ type: SET_SELECTED_BID_TABULATION_PRICE_ITEMS });
        return client
            .post(`/project/${projectId}/bid-tabulation`, { data: priceItemIds })
            .then((result) => {
                dispatch(showSnackbar('Selections Saved'));
                dispatch({ result, type: SET_SELECTED_BID_TABULATION_PRICE_ITEMS_SUCCESS });
            })
            .catch((error) => {
                dispatch(showSnackbar('Selection Save Failed', { isError: true }));
                dispatch({ error, type: SET_SELECTED_BID_TABULATION_PRICE_ITEMS_FAIL });
            });
    };
}

export const LOAD_PROPOSAL_COMPARE = 'gov/proposalEvaluations/LOAD_PROPOSAL_COMPARE';
export const LOAD_PROPOSAL_COMPARE_SUCCESS =
    'gov/proposalEvaluations/LOAD_PROPOSAL_COMPARE_SUCCESS';
export const LOAD_PROPOSAL_COMPARE_FAIL = 'gov/proposalEvaluations/LOAD_PROPOSAL_COMPARE_FAIL';

export function loadProposalCompare(projectId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_PROPOSAL_COMPARE });
        return client
            .get(`/project/${projectId}/evaluation/compare`)
            .then((result) => dispatch({ type: LOAD_PROPOSAL_COMPARE_SUCCESS, result }))
            .catch((error) => dispatch({ type: LOAD_PROPOSAL_COMPARE_FAIL, error }));
    };
}

export const UPDATE_ADMIN_SCORECARD = 'gov/proposalEvaluations/UPDATE_ADMIN_SCORECARD';

const updateAdminScorecardStatus = (dispatch, projectId, result, message) => {
    // Admin scorecard updates can also effect scoring criteria values so we need to update the
    // entire project when admins scorecard status changes
    const action = { type: UPDATE_PROJECT_SUCCESS, result };
    dispatch(emitProjectSocket(projectId, action, message));
    dispatch(showSnackbar(message));

    // Will override all connected client scorecards. Needed when all scorecards get updated
    // via admin scorecard submission/unsubmission. Typical scoring does not need this as only
    // one scorecard is updated at a time.
    dispatch(backgroundReloadAggregateProposalEvaluations(projectId, true));
};

export function updateAdminScorecard(projectId, data) {
    return (dispatch, getState, client) => {
        return client
            .put(`/project/${projectId}/evaluation/admin-scorecard`, { data })
            .then((result) => {
                const { adminScorecard } = result.evaluation;
                const { isComplete } = adminScorecard;
                const msg = `Admin Scorecard ${isComplete ? 'Submitted' : 'Updated'}`;

                // If admin scorecard has been submitted we want to update scorecards for all
                // connected clients (all scorecards will have scores added)
                if (isComplete) {
                    updateAdminScorecardStatus(dispatch, projectId, result, msg);
                } else {
                    const action = { type: UPDATE_ADMIN_SCORECARD, result: adminScorecard };
                    dispatch(emitProjectSocket(projectId, action, msg));
                    dispatch(showSnackbar(msg));
                }
            });
    };
}

export function unsubmitAdminScorecard(projectId) {
    return (dispatch, getState, client) => {
        return client
            .put(`/project/${projectId}/evaluation/admin-scorecard/unsubmit`)
            .then((result) => {
                const msg = 'Admin Scorecard Unsubmitted';

                // When admin scorecard has been unsubmitted we want to update scorecards for all
                // connected clients (all scorecards will have scores removed)
                updateAdminScorecardStatus(dispatch, projectId, result, msg);
            });
    };
}
