import { ClosedDateCell, StatusCell, SelectCell, TextCell, TitleCell } from '../cells';

const titleDepartmentContact = [
    {
        field: 'title',
        headerName: 'Title',
        cellRendererFramework: TitleCell,
        sortable: false,
    },
    {
        field: 'department',
        headerName: 'Department',
        cellRendererFramework: TextCell,
        sortable: false,
    },
    {
        field: 'contact',
        headerName: 'Contact',
        cellRendererFramework: TextCell,
        sortable: false,
    },
];

/**
 * Returns the column specifications for the RecordTable component.
 * @return {object[]} Array of column definitions
 */
export const recordTableColumnDef = [
    {
        checkboxSelection: true,
        headerCheckboxSelection: true,
        pinned: 'left',
        suppressSizeToFit: true,
        width: 55,
    },
    {
        field: 'status',
        headerName: 'Status',
        cellRendererFramework: StatusCell,
        sortable: false,
    },
    ...titleDepartmentContact,
    {
        field: 'lastUpdated',
        headerName: 'Last Updated',
        cellRendererFramework: TextCell,
        sortable: false,
    },
];

/**
 * Returns the column specifications for the RECENTLY_CLOSED version of the table.
 * @return {object[]} Array of column definitions.
 */
export const recentlyClosedTableColumnDef = [
    {
        checkboxSelection: true,
        headerCheckboxSelection: true,
        pinned: 'left',
        suppressSizeToFit: true,
        width: 55,
        sortable: false,
    },
    ...titleDepartmentContact,
    {
        field: 'lastUpdated',
        headerName: 'Last Updated',
        cellRendererFramework: ClosedDateCell,
        sortable: false,
    },
    {
        field: 'retentionPolicy',
        headerName: 'Retention Policy',
        cellRendererFramework: SelectCell,
        cellStyle: { overflow: 'visible' },
        sortable: false,
    },
];

/**
 * Returns the column specifications for the EXPIRED version of the table.
 * @return {object[]} Array of column definitions.
 */
export const expiredTableColumnDef = [
    {
        checkboxSelection: true,
        headerCheckboxSelection: true,
        pinned: 'left',
        suppressSizeToFit: true,
        width: 55,
    },
    ...titleDepartmentContact,
    {
        field: 'expirationDate',
        headerName: 'Expiration Date',
        cellRendererFramework: ClosedDateCell,
        sortable: false,
    },
];
