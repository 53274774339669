import PropTypes from 'prop-types';
import React from 'react';

import { StatusLabel } from '../..';

export const TemplateStatusLabel = ({
    className,
    isArchived,
    isPublished,
    isSpecial,
    isArchivedLabelStyle,
}) => {
    const getStatusData = () => {
        if (isArchived) return [isArchivedLabelStyle || 'danger', 'Archived'];
        if (isSpecial) return ['primary', 'Special'];
        if (isPublished) return ['success', 'Live'];
        return ['default', 'Draft'];
    };

    const [bsStyle, label] = getStatusData();

    return (
        <StatusLabel bsStyle={bsStyle} className={className}>
            {label}
        </StatusLabel>
    );
};

TemplateStatusLabel.propTypes = {
    className: PropTypes.string,
    isArchived: PropTypes.bool,
    isArchivedLabelStyle: PropTypes.string,
    isPublished: PropTypes.bool.isRequired,
    isSpecial: PropTypes.bool,
};
