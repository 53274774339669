import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@og-pro/ui';

import { PostingOptionToggleGroup } from './PostingOptionToggleGroup';
import { fieldNames } from '../ProjectInformation/constants';
import { ProjectCreateV2FunctionsContext } from '../../../context';
import { isTemplateAdminUser } from '../../../../../../selectors';
import { SDv2ErrorBanner } from '../../../../../../../components/SDv2';

const {
    ALLOW_SUBSTITUTION_REQUESTS,
    HAS_SEALED_BID,
    IS_AUTO_BID_UNSEALED,
    IS_PRIVATE,
    IS_PUBLIC_BID_PRICING_RESULT,
    IS_PUBLIC_BID_RESULT,
    QA_ENABLED,
    REQUIRES_INVITATION,
    SHOW_PLANHOLDERS,
} = fieldNames;

export const PostingOptions = () => {
    const {
        disabled,
        form,
        project: {
            template: { forceSealedBidStatus, isReverseAuction, substitutionRequestFormUrl },
        },
        showFormErrors: showValidation,
    } = useContext(ProjectCreateV2FunctionsContext);

    const templateAdminUser = useSelector(isTemplateAdminUser);
    const disableSealedBidSettings = forceSealedBidStatus && !templateAdminUser;

    const sharedToggleProps = { disabled, form, showValidation };
    const { container, section } = require('../../shared.scss');

    return (
        <Box className={container}>
            <Box paddingBottom={2}>
                <SDv2ErrorBanner
                    description="Changes on this page will override how this project was set up in the template."
                    hasLargeIcon
                    variant="warning"
                />
            </Box>
            <Box className={section}>
                <h3>Private Bid Controls</h3>
                <PostingOptionToggleGroup
                    {...sharedToggleProps}
                    additionalOptions={[
                        {
                            fieldName: REQUIRES_INVITATION,
                            info: 'By default, a private bid is not posted publicly but can be accessed by vendors with a link to the bid. When this option is toggled on, only invited vendors will be able to view and submit responses.',
                            label: 'Private bid invite',
                            subHeading: 'Do you want to require invitation to access bid?',
                        },
                    ]}
                    fieldName={IS_PRIVATE}
                    info="A private bid is viewable only with a link. It will not be publicly displayed on your portal and will not notify vendors when the project posts."
                    label="Private bid"
                    subHeading="Do you want to make this project a private bid?"
                />
            </Box>
            <Box className={section}>
                <h3>Sealed Bid Process</h3>
                <PostingOptionToggleGroup
                    {...sharedToggleProps}
                    additionalOptions={[
                        {
                            disabled: isReverseAuction || disableSealedBidSettings,
                            fieldName: IS_AUTO_BID_UNSEALED,
                            info: `Bids will be unsealed automatically at the proposal submission deadline.${' Only template administrators can change this setting.'}`,
                            label: 'Automatically unseal bids',
                            subHeading: 'Should bids automatically unseal?',
                        },
                        {
                            disabled: disableSealedBidSettings,
                            fieldName: IS_PUBLIC_BID_PRICING_RESULT,
                            info: `Publicly displays the pricing tables or totals from each proposal received.${' Only template administrators can change this setting.'}`,
                            label: 'Publish vendor pricing results',
                            subHeading: 'Should vendor pricing results be published?',
                        },
                        {
                            disabled: disableSealedBidSettings,
                            fieldName: IS_PUBLIC_BID_RESULT,
                            info: `Publicly displays specified responses from each proposal received.${' Only template administrators can change this setting.'}`,
                            label: 'Publish vendor responses',
                            subHeading: 'Should vendor responses be published?',
                        },
                    ]}
                    disabled={isReverseAuction || disableSealedBidSettings || disabled}
                    fieldName={HAS_SEALED_BID}
                    info={`A sealed bid process prevents vendor responses from being viewed until the bid is unsealed. Once unsealed, all responses will be visible.${' Only template administrators can change this setting.'}`}
                    label="Sealed bid"
                    subHeading="Do you want to use a Sealed Bid Process?"
                />
            </Box>
            <Box className={section}>
                <h3>Sourcing Event Options</h3>
                <PostingOptionToggleGroup
                    {...sharedToggleProps}
                    additionalOptions={[
                        {
                            fieldName: ALLOW_SUBSTITUTION_REQUESTS,
                            disabled: !substitutionRequestFormUrl,
                            info: substitutionRequestFormUrl
                                ? 'Allow vendors to submit requests for substitution (ex. brand alternates) until the Question & Answer period ends.'
                                : 'To enable this setting, please upload a Request for Substitution form in the “Design your template” section of Template Admin.',
                            label: 'Requests for substitution',
                            subHeading: 'Do you want to allow Requests for Substitution?',
                        },
                    ]}
                    fieldName={QA_ENABLED}
                    info="Enables the Question and Answer section for the project."
                    label="Enable Q&A"
                    subHeading="Do you want to enable Q&A?"
                />
            </Box>
            <Box className={section}>
                <h3>Vendor Display Options</h3>
                <PostingOptionToggleGroup
                    {...sharedToggleProps}
                    fieldName={SHOW_PLANHOLDERS}
                    info="Publicly displays all the vendors that are following updates on this project."
                    label="Vendor followers"
                    subHeading="Do you want to publicly list vendor followers?"
                />
            </Box>
        </Box>
    );
};
