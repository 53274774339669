import React, { useEffect, useMemo } from 'react';
import { ListGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find } from 'lodash';

import { NoProjects } from '../../../components/GovApp';
import { LoadingError, LoadingSpinner, ProjectsListItem, IntakesList } from '../../../components';
import { projectStatusesDict } from '../../../../../shared_config/projects';
import { loadLineItemContractInfo } from '../../../actions/projects';
import { getProjectLineItemContractInfoJS } from './selectors';

const { AWARD_PENDING, CLOSED } = projectStatusesDict;

const mapDispatchToProps = { loadLineItemContractInfo };

const mapStateToProps = (state) => ({
    projectLineItemContractInfo: getProjectLineItemContractInfoJS(state),
    loadingProjectLineItemContractInfo: state.projects.get('loadingProjectLineItemContractInfo'),
});

const ProjectListGroup = ({
    hasSourcing,
    isDocBuilder,
    isIntake,
    loadError,
    isFiltered,
    isLoading,
    governmentId,
    priceItemDescriptionFilter,
    projects,
    timezone,
    projectLineItemContractInfo,
    loadingProjectLineItemContractInfo,
    loadLineItemContractInfo: dispatchLoadLineItemContractInfo,
}) => {
    // Project IDs that might have line item award information
    const candidateProjectIds = useMemo(
        () =>
            new Set(
                projects.reduce((candidates, project) => {
                    const isCandidateForContractInfo =
                        [CLOSED, AWARD_PENDING].includes(project.status) && // Project status is closed or pending award
                        Array.isArray(project.priceTables) && // Project has at least one price table
                        project.priceTables.length > 0;

                    return isCandidateForContractInfo ? [...candidates, project.id] : candidates;
                }, [])
            ),
        [projects]
    );

    useEffect(() => {
        if (candidateProjectIds.size === 0) {
            return;
        }

        // Request line item contract information from the candidates so that we can determine
        // which projects definitely have the desired information
        dispatchLoadLineItemContractInfo({ ids: Array.from(candidateProjectIds) });
    }, [candidateProjectIds]);

    if (isLoading) {
        return <LoadingSpinner centered />;
    }

    if (loadError) {
        return <LoadingError error={loadError} />;
    }

    if (projects.length === 0) {
        return <NoProjects filtered={isFiltered} isDocBuilder={isDocBuilder} isIntake={isIntake} />;
    }

    if (isIntake) {
        return <IntakesList governmentId={Number.parseInt(governmentId, 10)} projects={projects} />;
    }

    const projectPath = `/governments/${governmentId}/projects`;

    return (
        <ListGroup>
            {projects.map((project) => (
                <ProjectsListItem
                    displayAwardsColumn={candidateProjectIds.has(project.id)}
                    hasSourcing={hasSourcing}
                    isDocBuilder={isDocBuilder}
                    isLoadingContractInfo={loadingProjectLineItemContractInfo}
                    key={project.id}
                    lineItemContractInfo={find(projectLineItemContractInfo, { id: project.id })}
                    priceItemDescriptionFilter={priceItemDescriptionFilter}
                    project={project}
                    timezone={timezone}
                    to={`${projectPath}/${project.id}/${project.isLibrary ? 'builder' : 'manage'}`}
                />
            ))}
        </ListGroup>
    );
};

ProjectListGroup.propTypes = {
    governmentId: PropTypes.string.isRequired,
    hasSourcing: PropTypes.bool.isRequired,
    isDocBuilder: PropTypes.bool,
    isFiltered: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    isIntake: PropTypes.bool,
    loadError: PropTypes.string,
    loadLineItemContractInfo: PropTypes.func.isRequired,
    loadingProjectLineItemContractInfo: PropTypes.bool,
    priceItemDescriptionFilter: PropTypes.string,
    projects: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            isLibrary: PropTypes.bool.isRequired,
            priceTables: PropTypes.array,
        })
    ).isRequired,
    projectLineItemContractInfo: PropTypes.array,
    timezone: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectListGroup);
