import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { AttachmentUploader, FileUploadAttachment } from '../../../../components';
import { attachmentTypesDict } from '../../../../../../shared_config/attachments';

const { OTHER } = attachmentTypesDict;

const MAX_FILE_SIZE = 3;

export class VendorUploader extends PureComponent {
    static propTypes = {
        attachments: PropTypes.array.isRequired,
        deleteHandler: PropTypes.func.isRequired,
        isGovRequired: PropTypes.bool,
        s3GetUrl: PropTypes.string.isRequired,
        uploadHandler: PropTypes.func.isRequired,
        uploadTitle: PropTypes.string.isRequired,
        uploadType: PropTypes.string.isRequired,
    };

    get styles() {
        return require('./VendorUploader.scss');
    }

    get isGovRequiredAndIncomplete() {
        const { attachments, isGovRequired } = this.props;
        return isGovRequired && attachments.length === 0;
    }

    renderUploader() {
        const { s3GetUrl, uploadHandler, uploadTitle, uploadType } = this.props;

        return (
            <AttachmentUploader
                attachmentUploadData={{
                    vendorUploadType: uploadType,
                    type: OTHER,
                }}
                dropzoneOptions={{
                    className: this.styles.dropzone,
                    defaultRejectMessage: `File could not be uploaded. Please ensure the file is less than ${MAX_FILE_SIZE} MBs.`,
                    maxSize: MAX_FILE_SIZE * 1048576,
                    text: `Drop ${uploadTitle} here or click to select files to upload`,
                }}
                label={uploadTitle}
                onSuccess={uploadHandler}
                s3GetUrl={s3GetUrl}
                showDates
            />
        );
    }

    renderAttachments() {
        const { attachments, deleteHandler } = this.props;

        if (attachments.length === 0) {
            return (
                <div className={this.isGovRequiredAndIncomplete ? 'text-danger' : 'text-muted'}>
                    <em>
                        {this.isGovRequiredAndIncomplete
                            ? 'This field is required'
                            : 'No documents provided'}
                    </em>
                </div>
            );
        }

        return attachments.map((attachment) => (
            <FileUploadAttachment
                attachment={attachment}
                deleteHandler={deleteHandler}
                key={attachment.id}
                requireConfirmation
                showDate
                useListItemStyle
            />
        ));
    }

    render() {
        return (
            <div className={this.styles.container}>
                {this.renderUploader()}
                {this.renderAttachments()}
            </div>
        );
    }
}
