import React, { PureComponent } from 'react';

import { projectFormProps } from '../constants';
import { ProjectOverviewFormSection } from '../../../../components';
import { pseudoSectionTypeNames } from '../../../../../../shared_config/sections';

const { GLOBAL_DATA } = pseudoSectionTypeNames;

export class GlobalData extends PureComponent {
    static propTypes = projectFormProps;

    formName = GLOBAL_DATA;

    render() {
        return (
            <div className="row">
                <div className="col-xs-12">
                    <ProjectOverviewFormSection
                        {...this.props}
                        hasCategories
                        hasEmergency
                        showProcurementContact
                    />
                </div>
            </div>
        );
    }
}
