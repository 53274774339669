import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { questionStatusTypes } from '../../../../../../../shared_config/questions';
import { projectStatusesDict } from '../../../../../../../shared_config/projects';

const { DRAFT, RELEASED } = questionStatusTypes;

const { REVERSE_AUCTION } = projectStatusesDict;

export class QuestionAnswerReport extends PureComponent {
    static propTypes = {
        project: PropTypes.object.isRequired,
        questions: PropTypes.array.isRequired,
    };

    get questions() {
        const { project, questions } = this.props;

        // Auction questions don't get shown while auction is in progress
        if (project.status === REVERSE_AUCTION) {
            return questions.filter((q) => !q.isAuctionQuestion);
        }

        return questions;
    }

    render() {
        const {
            project: {
                government: {
                    organization: { timezone },
                },
            },
        } = this.props;

        const approvedQuestionsSections = this.questions.reduce(
            (acc, question) => {
                if ((question.isApproved && !question.isAnswered) || question.status === DRAFT) {
                    acc.unansweredQuestions.data.push(question);
                } else if (question.status === RELEASED) {
                    acc.answeredQuestions.data.push(question);
                }
                return acc;
            },
            {
                unansweredQuestions: { title: 'Approved, Unanswered Questions', data: [] },
                answeredQuestions: { title: 'Approved, Answers Provided', data: [] },
            }
        );

        const anyApprovedQuestions = approvedQuestionsSections.unansweredQuestions.data.concat(
            approvedQuestionsSections.answeredQuestions.data
        ).length;

        if (!anyApprovedQuestions) {
            return (
                <p>
                    <em>No Questions Received.</em>
                </p>
            );
        }

        return (
            <>
                {Object.keys(approvedQuestionsSections).map((section, sectionIndex) => {
                    return (
                        <React.Fragment key={sectionIndex}>
                            <h3 style={{ fontSize: '16pt', fontWeight: 'bold' }}>
                                {approvedQuestionsSections[section].title}
                            </h3>
                            <br />
                            {approvedQuestionsSections[section].data.map(
                                (question, questionIndex) => {
                                    const {
                                        id,
                                        number,
                                        questionComments,
                                        // eslint-disable-next-line camelcase
                                        created_at,
                                        releasedAt,
                                        subject,
                                        status,
                                    } = question;

                                    const [questionComment, answerComment] = questionComments;

                                    return (
                                        <React.Fragment key={id}>
                                            <p
                                                style={{
                                                    fontSize: '14pt',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {number}. {subject}
                                            </p>
                                            <p style={{ fontSize: '10pt', color: '#808080' }}>
                                                <i>
                                                    {moment(created_at).tz(timezone).format('lll')}
                                                </i>
                                            </p>
                                            <p>
                                                <span
                                                    style={{
                                                        fontSize: '12pt',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Question:
                                                </span>{' '}
                                                <span style={{ fontSize: '12pt' }}>
                                                    {questionComment.description}
                                                </span>
                                            </p>
                                            <p
                                                style={{
                                                    fontSize: '10pt',
                                                    color: '#808080',
                                                }}
                                            >
                                                <i>
                                                    {moment(questionComment.created_at)
                                                        .tz(timezone)
                                                        .format('lll')}
                                                </i>
                                            </p>
                                            {answerComment && status === RELEASED && (
                                                <>
                                                    <p>
                                                        <span
                                                            style={{
                                                                fontSize: '12pt',
                                                                fontWeight: 'bold',
                                                            }}
                                                        >
                                                            Answered by{' '}
                                                            {answerComment.user.displayName}:
                                                        </span>{' '}
                                                        <span style={{ fontSize: '12pt' }}>
                                                            {answerComment.description}
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            fontSize: '10pt',
                                                            color: '#808080',
                                                        }}
                                                    >
                                                        <i>
                                                            {moment(releasedAt)
                                                                .tz(timezone)
                                                                .format('lll')}
                                                        </i>
                                                    </p>
                                                </>
                                            )}
                                            {sectionIndex ===
                                                Object.keys(approvedQuestionsSections).length - 1 &&
                                            questionIndex ===
                                                Object.keys(approvedQuestionsSections[section].data)
                                                    .length -
                                                    1 ? null : (
                                                <p>
                                                    <br />
                                                </p>
                                            )}
                                        </React.Fragment>
                                    );
                                }
                            )}
                        </React.Fragment>
                    );
                })}
            </>
        );
    }
}
