import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFormSyncErrors } from 'redux-form';
import { Box, Typography } from '@og-pro/ui';

import { projectTypesDict } from '../../../../../../../../shared_config/projects';
import { buildTabRoute } from '../../../../../../helpers';
import {
    CDSButton,
    BaseSectionsNavBackButton,
    BaseSectionsNavBackNextButtons,
    SectionHeaderViewTitleOnly,
} from '../../../../../../components/SDv2';
import {
    setFormCompleteConfirmation,
    showFormValidation,
} from '../../../../../../actions/project/create/projectCreate';
import {
    FinalReviewChecklistSection,
    FinalReviewChecklistExpandableSection,
    FinalReviewChecklistBanner,
} from '../../components/FinalReviewChecklist';
import { getSDv2WritingSections } from '../../../selectors';
import { projectSectionsToNavSections } from '../../../../../../components/SDv2/helpers';
import { ProjectCreateV2NavContext, ProjectCreateV2FunctionsContext } from '../../context';
import { menuActionHandler } from '../../../../../../actions/govProjects';
import { SUBMIT_INTAKE_FOR_REVIEW } from '../../../../../../constants/menuActions';

export const ProjectCreateV2FinalReviewChecklist = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const projectSections = useSelector(getSDv2WritingSections);
    const { activeSectionId, setActiveSectionId } = useContext(ProjectCreateV2NavContext);
    const {
        buildRouteFunction,
        form,
        location,
        project,
        project: { isIntake, lastUpdatedAt, useManualNumbering },
        reviewPath,
    } = useContext(ProjectCreateV2FunctionsContext);
    const errorsSelectors = getFormSyncErrors(form);
    const formErrors = useSelector((state) => errorsSelectors(state));
    const hasIncompleteSections = !!formErrors?.allSections;

    useEffect(() => {
        dispatch(showFormValidation());
    }, []);

    useEffect(() => {
        dispatch(
            setFormCompleteConfirmation(
                hasIncompleteSections ? null : new Date(lastUpdatedAt).getTime()
            )
        );
    }, [hasIncompleteSections, lastUpdatedAt]);

    const sections = projectSectionsToNavSections({
        projectSections,
        active: activeSectionId,
        setActive: setActiveSectionId,
        useManualNumbering,
        showFormValidation: true,
        matchErrorToSection: (section) => {
            return formErrors?.sections && formErrors.sections[section.id];
        },
        hideEmptyDividers: true,
    });

    const styles = require('./index.scss');
    const buildRoute = buildTabRoute(location);
    const onReviewClick = () => {
        if (isIntake) {
            dispatch(menuActionHandler(SUBMIT_INTAKE_FOR_REVIEW, project));
        } else {
            navigate(reviewPath);
        }
    };

    const buttonText = isIntake ? 'Submit Project Request' : 'Review Approval Setup';
    const isContractDocument = project.type === projectTypesDict.CONTRACT;
    const isContractDocumentOrIntake = isContractDocument || isIntake;

    return (
        <Box className={styles.container}>
            <Box className={styles.header}>
                <SectionHeaderViewTitleOnly
                    description="Check for any errors before moving your document into the review stage."
                    title="Final Project Review"
                />
            </Box>
            <Box className={styles.content}>
                <FinalReviewChecklistBanner
                    buttonText={buttonText}
                    hasIncompleteSections={hasIncompleteSections}
                    onReviewClick={onReviewClick}
                />
                <Box mb={3}>
                    <Typography variant="h3">Project Properties Checklist</Typography>
                    <Box className={styles.sectionsContainer}>
                        <FinalReviewChecklistSection
                            incomplete={formErrors?.sections[1]}
                            onClick={() => navigate(buildRoute('project-properties'))}
                            section={{ title: 'Project Information' }}
                        />
                        <FinalReviewChecklistSection
                            incompleteFields={
                                formErrors?.upfrontQuestions &&
                                formErrors?.upfrontQuestions.filter((e) => !!e).length
                            }
                            last={isContractDocument}
                            onClick={() =>
                                navigate(`${buildRoute('project-properties')}?activeSection=1`)
                            }
                            section={{ title: 'Document Set Up' }}
                        />
                        {!isContractDocument && (
                            <FinalReviewChecklistSection
                                incompleteFields={formErrors?.rawSummary ? 1 : 0}
                                last={isContractDocumentOrIntake}
                                onClick={() =>
                                    navigate(`${buildRoute('project-properties')}?activeSection=2`)
                                }
                                section={{ title: 'Summary & Background' }}
                            />
                        )}
                        {!isContractDocumentOrIntake && (
                            <>
                                <FinalReviewChecklistSection
                                    incompleteFields={formErrors?.projectProperties.timeline}
                                    onClick={() =>
                                        navigate(
                                            `${buildRoute('project-properties')}?activeSection=3`
                                        )
                                    }
                                    section={{ title: 'Timeline' }}
                                />
                                <FinalReviewChecklistSection
                                    incompleteFields={formErrors?.projectProperties.postingOptions}
                                    last
                                    onClick={() =>
                                        navigate(
                                            `${buildRoute('project-properties')}?activeSection=4`
                                        )
                                    }
                                    section={{ title: 'Posting Options' }}
                                />
                            </>
                        )}
                    </Box>
                </Box>
                <Box mb={3}>
                    <Typography variant="h3">Document Outline Checklist</Typography>
                    <Box className={styles.sectionsContainer}>
                        {sections.map((section, i) => {
                            if (section.children && section.children.length) {
                                return (
                                    <FinalReviewChecklistExpandableSection
                                        incompleteFields={
                                            section.children.filter((c) => c.showValidationError)
                                                .length
                                        }
                                        key={i}
                                        last={i === sections.length - 1}
                                        section={section}
                                    >
                                        {section.children.map((child, ii) => {
                                            return (
                                                <FinalReviewChecklistSection
                                                    isChild
                                                    key={ii}
                                                    last={
                                                        i === sections.length - 1 &&
                                                        ii === section.children.length - 1
                                                    }
                                                    onClick={() =>
                                                        navigate(
                                                            `${buildRoute(
                                                                'document-editor'
                                                            )}?activeSection=${child.index}`
                                                        )
                                                    }
                                                    section={child}
                                                />
                                            );
                                        })}
                                    </FinalReviewChecklistExpandableSection>
                                );
                            }

                            return (
                                <FinalReviewChecklistSection
                                    key={i}
                                    last={i === sections.length - 1}
                                    onClick={() =>
                                        navigate(
                                            `${buildRoute('document-editor')}?activeSection=${
                                                section.index
                                            }`
                                        )
                                    }
                                    section={section}
                                />
                            );
                        })}
                    </Box>
                </Box>
            </Box>
            <Box className={styles.footer}>
                <BaseSectionsNavBackNextButtons
                    firstSectionComponent={
                        <BaseSectionsNavBackButton
                            onClick={() => navigate(buildRouteFunction('attachments'))}
                        >
                            Attachments
                        </BaseSectionsNavBackButton>
                    }
                    lastSectionComponent={
                        formErrors?.allSections === false ? (
                            <CDSButton
                                onClick={onReviewClick}
                                qaTag="reviewChecklist-review"
                                variant="primary"
                            >
                                {buttonText}
                            </CDSButton>
                        ) : null
                    }
                    sections={[]}
                />
            </Box>
        </Box>
    );
};
