import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getFormValues, reduxForm, Field, FieldArray } from 'redux-form';

import {
    attachmentUploadOptionTooltip,
    form,
    instructionDataFieldNames,
    previewTooltip,
    unusedOptionTooltip,
} from './constants';
import { CriteriaNeedsReviewMultipleChoiceItems } from './CriteriaNeedsReviewMultipleChoiceItems';
import { validate } from './validate';
import { fieldNames } from '../../constants';
import { TermsReview } from '../../TermsReview';
import {
    Button,
    HelpIcon,
    InputText,
    Label,
    RichTextInput,
    OutlineButton,
    SearchSelect,
    SearchSelectIconOption,
    SearchSelectIconValue,
    Toggle,
    Well,
} from '../../../..';
import {
    criteriaInstructionsDict,
    criteriaInstructionSelectOptions,
    instructionsWithoutDescription,
} from '../../../../../../../shared_config/criteria';
import { limitTextLength } from '../../../../../Forms/normalizers';
import { MANUAL_NUMBER_CHAR_LIMIT } from '../../../../../../../shared_config/manualNumber';

const {
    DESCRIPTION,
    INSTRUCTIONS,
    INSTRUCTION_DATA,
    INSTRUCTION_TYPE,
    IS_UNUSED,
    MANUAL_NUMBER,
    TITLE,
} = fieldNames;

const { ATTACHMENT, CONFIRM, TOGGLE, TOGGLE_EDIT, EDIT, RADIO, SELECT } = criteriaInstructionsDict;

const {
    INCLUDE_ATTACHMENT: INSTRUCTION_DATA_INCLUDE_ATTACHMENT,
    INSTRUCTIONS: INSTRUCTION_DATA_INSTRUCTIONS,
    NAME: INSTRUCTION_DATA_NAME,
    OPTIONS: INSTRUCTION_DATA_OPTIONS,
} = instructionDataFieldNames;

const mapStateToProps = (state) => {
    const formValues = getFormValues(form)(state) || {};
    const { instructionType } = formValues;

    return {
        formValues,
        hasDescriptionField:
            !!instructionType && !instructionsWithoutDescription.includes(instructionType),
        instructionType,
    };
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedCriteriaNeedsReviewForm extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        formValues: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hasDescriptionField: PropTypes.bool.isRequired,
        instructionType: PropTypes.string,
        isTextArea: PropTypes.bool,
        removeNeedsReview: PropTypes.func.isRequired,
        showValidation: PropTypes.bool,
        submitFailed: PropTypes.bool.isRequired,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        useManualNumbering: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    noOp = () => {};

    mockMeta = { pristine: true };

    mockInput = {
        onBlur: this.noOp,
        onChange: this.noOp,
        onFocus: this.noOp,
    };

    normalizeManualNumber = limitTextLength(MANUAL_NUMBER_CHAR_LIMIT);

    renderMultipleChoiceFields(optionFieldLabelName) {
        const { disabled, tagOptions, templateVariableOptions } = this.props;

        return (
            <div>
                <Label label="Multiple Choice Options" />
                <Panel>
                    <Panel.Body>
                        <FieldArray
                            component={CriteriaNeedsReviewMultipleChoiceItems}
                            disabled={disabled}
                            name={`${INSTRUCTION_DATA}.${INSTRUCTION_DATA_OPTIONS}`}
                            optionFieldLabelName={optionFieldLabelName}
                            tagOptions={tagOptions}
                            templateVariableOptions={templateVariableOptions}
                        />
                    </Panel.Body>
                </Panel>
            </div>
        );
    }

    renderInstructionTypeFormFields() {
        const { instructionType } = this.props;

        switch (instructionType) {
            case RADIO:
                return this.renderMultipleChoiceFields(INSTRUCTION_DATA_NAME);
            case SELECT:
                return this.renderMultipleChoiceFields(INSTRUCTION_DATA_INSTRUCTIONS);
            case ATTACHMENT:
            case CONFIRM:
            case EDIT:
            case TOGGLE:
            case TOGGLE_EDIT:
            default:
                return null;
        }
    }

    render() {
        const {
            disabled,
            formValues,
            formValues: { instructionData, needsReview },
            handleSubmit,
            hasDescriptionField,
            instructionType,
            isTextArea,
            removeNeedsReview,
            showValidation,
            submitFailed,
            tagOptions,
            templateVariableOptions,
            useManualNumbering,
        } = this.props;

        const firstColSize = instructionType === RADIO || instructionType === SELECT ? 8 : 6;

        return (
            <div className="row">
                <div className={`col-xs-${firstColSize}`}>
                    <Field
                        component={SearchSelect}
                        components={{
                            Option: SearchSelectIconOption,
                            SingleValue: SearchSelectIconValue,
                        }}
                        label="Form Type"
                        menuPortalTarget={document?.body}
                        name={INSTRUCTION_TYPE}
                        options={criteriaInstructionSelectOptions}
                        placeholder="Select type of form to include"
                        showValidation={showValidation}
                    />
                    <Field
                        component={InputText}
                        hasFeedback={false}
                        label="Instructions For Completing"
                        minRows={2}
                        name={INSTRUCTIONS}
                        placeholder="Enter instructions for completing the form"
                        showValidation={showValidation}
                        type="textarea"
                    />
                    {this.renderInstructionTypeFormFields()}
                </div>
                <div className={`col-xs-${12 - firstColSize}`}>
                    {!isTextArea && useManualNumbering && (
                        <Field
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            label="Item Number"
                            name={MANUAL_NUMBER}
                            normalize={this.normalizeManualNumber}
                            placeholder="Enter Item Number"
                            showValidation={showValidation}
                            type="text"
                        />
                    )}
                    {!isTextArea && (
                        <Field
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            label="Item Title"
                            name={TITLE}
                            placeholder="Enter Title"
                            showValidation={showValidation}
                            type="text"
                        />
                    )}
                    {hasDescriptionField && (
                        <Field
                            component={RichTextInput}
                            disabled={disabled}
                            label="Item Content"
                            minRows={3}
                            name={DESCRIPTION}
                            placeholder="Enter Content"
                            showValidation={showValidation}
                            tagOptions={tagOptions}
                            templateVariableOptions={templateVariableOptions}
                        />
                    )}
                    <div className="text-right">
                        <div className={this.styles.toggleText}>
                            Hide Item
                            <HelpIcon placement="bottom" tooltip={unusedOptionTooltip} />
                        </div>
                        <Field component={Toggle} disabled={disabled} name={IS_UNUSED} />
                    </div>
                    <div className="text-right">
                        <div className={this.styles.toggleText}>
                            Include Attachment Uploader
                            <HelpIcon placement="bottom" tooltip={attachmentUploadOptionTooltip} />
                        </div>
                        <Field
                            component={Toggle}
                            disabled={disabled}
                            name={`${INSTRUCTION_DATA}.${INSTRUCTION_DATA_INCLUDE_ATTACHMENT}`}
                        />
                    </div>
                </div>
                <div className={`col-xs-12 ${this.styles.controlButtons}`}>
                    {needsReview && (
                        <OutlineButton
                            bsStyle="danger"
                            className={this.styles.removeNeedsReviewButton}
                            disabled={disabled}
                            onClick={removeNeedsReview}
                        >
                            <i className="fa fa-ban" /> Remove Review Data
                        </OutlineButton>
                    )}
                    <Button bsStyle="success" disabled={disabled} onClick={handleSubmit}>
                        <i className="fa fa-check" /> Save & Close
                    </Button>
                    {submitFailed && (
                        <div className="error-block">
                            Please complete the required fields before saving the item
                        </div>
                    )}
                </div>
                {instructionType && (
                    <div className={`col-xs-12 ${this.styles.preview}`}>
                        <Well>
                            <h4 className={`text-info ${this.styles.previewTitle}`}>
                                Preview
                                <HelpIcon tooltip={previewTooltip} />
                            </h4>
                            {/* A lot of these are required fields that are given dummy values since
                                they do not matter for preview */}
                            <TermsReview
                                {...formValues}
                                arrayName="arrayName"
                                change={this.noOp}
                                completeHandler={this.noOp}
                                disabled
                                formName={form}
                                input={{
                                    ...this.mockInput,
                                    value: formValues[DESCRIPTION],
                                }}
                                instructionData={instructionData && JSON.stringify(instructionData)}
                                meta={this.mockMeta}
                                projectId={9999}
                            />
                        </Well>
                    </div>
                )}
            </div>
        );
    }
}

export const CriteriaNeedsReviewForm = compose(
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedCriteriaNeedsReviewForm);
