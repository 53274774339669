import { BID } from './constants';
import { currencyFormatter } from '../../../../../helpers';
import { getPreciseRoundFloat } from '../../../../../../../shared_config/reverseAuctions/util';

export function validate(values, { auctionMaxFractionDigits, currentLowestBid, minBidDecrement }) {
    const errors = {};
    const bidAmount = getPreciseRoundFloat(values[BID], auctionMaxFractionDigits);
    const minBidAmount = getPreciseRoundFloat(
        currentLowestBid - minBidDecrement,
        auctionMaxFractionDigits
    );
    const formatterOpts = {
        maximumFractionDigits: auctionMaxFractionDigits,
        useSameMinAndMax: true,
    };

    if (bidAmount === 0) {
        errors[BID] = 'Bid must be greater than 0';
    } else if (bidAmount > minBidAmount) {
        errors[BID] = `Minimum bid decrement is ${currencyFormatter(
            { value: minBidDecrement },
            formatterOpts
        )}. Your bid must be ${currencyFormatter({ value: minBidAmount }, formatterOpts)} or lower`;
    }

    return errors;
}
