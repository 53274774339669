import { pseudoSectionTypeNames } from '../../../../../shared_config/sections';

const { DOCUMENT_SETUP, GLOBAL_DATA, OVERVIEW, SUMMARY_BACKGROUND_TIMELINE } =
    pseudoSectionTypeNames;

export function isSingleEmptyCriteriaItem(criteria) {
    if (criteria.length !== 1) return false;
    const item = criteria[0];
    return !item.title && !item.description;
}

export function isPseudoSectionType(sectionType) {
    return (
        sectionType === GLOBAL_DATA ||
        sectionType === OVERVIEW ||
        sectionType === DOCUMENT_SETUP ||
        sectionType === SUMMARY_BACKGROUND_TIMELINE
    );
}
