import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { formatPercent } from '../helpers/utils';
import { currencyFormatter } from '../../helpers';
import { normalizeNumberWithCommas } from '../../../../shared_config/helpers';
import {
    customColumnsData,
    defaultColumnData,
    COMMENT,
    DESCRIPTION,
    DISCOUNT,
    LINE_ITEM,
    NO_BID,
    QUANTITY,
    TOTAL_COST,
    UNIT_PRICE,
    UNIT_TO_MEASURE,
} from '../../../../shared_config/priceTables';
import { DOCX_TABLE_LANDSCAPE_WIDTH, DOCX_TABLE_PORTRAIT_WIDTH } from '../../constants';

export class PriceTableDocx extends PureComponent {
    static propTypes = {
        auctionMaxFractionDigits: PropTypes.number,
        includeResponse: PropTypes.bool,
        isReverseAuction: PropTypes.bool,
        priceTable: PropTypes.shape({
            columnOrder: PropTypes.array.isRequired,
            description: PropTypes.string,
            hasPercentage: PropTypes.bool,
            hasSalesTaxRow: PropTypes.bool,
            hasTotalRow: PropTypes.bool,
            omitLineItem: PropTypes.bool,
            priceItems: PropTypes.array.isRequired,
            salesTaxCost: PropTypes.number,
            title: PropTypes.string,
            totalCost: PropTypes.number,
        }).isRequired,
        priceTableClassName: PropTypes.string,
        salesTax: PropTypes.number,
        size: PropTypes.string,
    };

    get currencyFormatterOpts() {
        const { auctionMaxFractionDigits, isReverseAuction } = this.props;
        if (isReverseAuction) {
            return { maximumFractionDigits: auctionMaxFractionDigits, useSameMinAndMax: true };
        }
        return { use4FractionDigits: 4 };
    }

    get columnsData() {
        const { includeResponse, priceTable } = this.props;

        const columnDefinitions = {};

        [
            {
                field: LINE_ITEM,
                omit: priceTable.omitLineItem,
                textAlign: 'center',
            },
            {
                field: DESCRIPTION,
            },
            {
                field: QUANTITY,
                formatter: normalizeNumberWithCommas,
                textAlign: 'center',
            },
            {
                field: UNIT_TO_MEASURE,
                textAlign: 'center',
            },
            {
                field: UNIT_PRICE,
                formatter: priceTable.hasPercentage
                    ? formatPercent
                    : (value) => currencyFormatter({ value }, this.currencyFormatterOpts),
                textAlign: 'center',
            },
            {
                field: DISCOUNT,
                formatter: formatPercent,
                isEmptyField: !includeResponse,
                textAlign: 'center',
            },
            {
                field: TOTAL_COST,
                formatter: (value) => currencyFormatter({ value }, this.currencyFormatterOpts),
                isEmptyField: !includeResponse,
                textAlign: 'center',
            },
            {
                field: NO_BID,
                formatter: (val) => val && 'X',
                isEmptyField: !includeResponse,
                textAlign: 'center',
            },
            {
                field: COMMENT,
                isEmptyField: !includeResponse,
            },
        ].forEach((colDefData) => {
            const { includedField, headerField } = defaultColumnData[colDefData.field];

            columnDefinitions[colDefData.field] = {
                ...colDefData,
                headerName: priceTable[headerField],
                hide: includedField !== true && !priceTable[includedField],
            };
        });

        customColumnsData.forEach((columnData) => {
            columnDefinitions[columnData.priceItemField] = {
                field: columnData.priceItemField,
                headerName: priceTable[columnData.headerField],
                hide: !priceTable[columnData.includedField],
                width: 100,
            };
        });

        return priceTable.columnOrder
            .map((columnIdentifier) => columnDefinitions[columnIdentifier])
            .filter((colDef) => colDef && !colDef.hide && !colDef.omit);
    }

    get tableWidth() {
        const { size } = this.props;

        if (size === 'xl') {
            return DOCX_TABLE_LANDSCAPE_WIDTH;
        }
        return DOCX_TABLE_PORTRAIT_WIDTH;
    }

    get columnNum() {
        return this.columnsData.length;
    }

    get totalColumnIndex() {
        return this.columnsData.findIndex(({ field }) => field === TOTAL_COST);
    }

    get hasTotalCostCol() {
        const { includeResponse } = this.props;

        return this.totalColumnIndex !== -1 && includeResponse;
    }

    getCellWidths = () => {
        const tableWidth = this.tableWidth;

        if (this.columnNum <= 5) {
            return [tableWidth - 100 * (this.columnNum - 1), 100];
        }
        if (this.columnNum <= 7) {
            return [tableWidth - 80 * (this.columnNum - 1), 80];
        }
        if (this.columnNum <= 9) {
            return [tableWidth - 60 * (this.columnNum - 1), 60];
        }
        return [tableWidth / this.columnNum, tableWidth / this.columnNum];
    };

    renderTableHeader() {
        const thStyle = { backgroundColor: '#003c81' };
        const pStyle = { textAlign: 'center', color: '#FFFFFF' };

        const [descriptionWidth, otherWidth] = this.getCellWidths();

        const columnHeaders = this.columnsData.map((columnData) => {
            const { field, headerName } = columnData;

            const width = field === DESCRIPTION ? descriptionWidth : otherWidth;

            return (
                <th key={field} style={thStyle} width={width}>
                    <p className="no-trailing-space" style={pStyle}>
                        <strong>{headerName}</strong>
                    </p>
                </th>
            );
        });

        return <tr className="repeat-header-row">{columnHeaders}</tr>;
    }

    renderTableRows() {
        const {
            priceTable: { priceItems },
        } = this.props;

        if (priceItems.length === 0) {
            return null;
        }

        const priceItemColumns = (priceItem) =>
            this.columnsData.map((columnData) => {
                const { field, formatter, isEmptyField, textAlign } = columnData;

                if (isEmptyField) {
                    return <td aria-label="Empty cell" key={field} />;
                }

                let value = priceItem[field];

                if (priceItem.taxable && field === DESCRIPTION) {
                    value = `${priceItem[field]} [*]`;
                } else if (
                    priceItem.noBid &&
                    (field === UNIT_PRICE || field === DISCOUNT || field === TOTAL_COST)
                ) {
                    value = 'No Bid';
                } else if (field === UNIT_PRICE && priceItem.discountOnly) {
                    value = 'N/A';
                } else if ((value || value === 0) && formatter) {
                    value = formatter(value);
                }

                return (
                    <td key={field}>
                        <p style={{ textAlign }}>{value}</p>
                    </td>
                );
            });

        const PriceItems = priceItems.map((item, index) => {
            if (item.isHeaderRow) {
                return (
                    <tr key={`priceItem.${index}`}>
                        <td
                            colSpan={this.columnNum}
                            style={{ backgroundColor: '#eee', fontWeight: 'bold' }}
                        >
                            <p>{item.description}</p>
                        </td>
                    </tr>
                );
            }
            return <tr key={`priceItem.${index}`}>{priceItemColumns(item)}</tr>;
        });

        return [PriceItems, this.renderSalesTaxRow(), this.renderTotalRow()];
    }

    renderSalesTaxRow() {
        const {
            priceTable: { hasSalesTaxRow, salesTaxCost },
            salesTax,
        } = this.props;

        if (!salesTax || !hasSalesTaxRow) {
            return null;
        }

        return (
            <tr key="salesTaxTotal">
                <td colSpan={this.hasTotalCostCol ? this.totalColumnIndex : this.columnNum}>
                    <p style={{ fontSize: '12px' }}>[*] Denotes item is taxable</p>
                    <p style={{ paddingLeft: 10 }}>Sales Tax (@ {salesTax}%)</p>
                </td>
                {this.hasTotalCostCol && (
                    <>
                        <td>
                            <p>&nbsp;</p>
                            <p style={{ textAlign: 'center' }}>
                                {currencyFormatter(
                                    { value: salesTaxCost },
                                    this.currencyFormatterOpts
                                )}
                            </p>
                        </td>
                        {this.totalColumnIndex + 1 < this.columnNum && (
                            <td
                                aria-label="Empty cell"
                                colSpan={this.columnNum - this.totalColumnIndex}
                            />
                        )}
                    </>
                )}
            </tr>
        );
    }

    renderTotalRow() {
        const {
            priceTable: { hasTotalRow, totalCost },
        } = this.props;

        if (!hasTotalRow) {
            return null;
        }

        return (
            <tr key="periodTotal">
                <td colSpan={this.hasTotalCostCol ? this.totalColumnIndex : this.columnNum}>
                    <p style={{ paddingLeft: 10 }}>
                        <strong>TOTAL</strong>
                    </p>
                </td>
                {this.hasTotalCostCol && (
                    <>
                        <td>
                            <p style={{ textAlign: 'center' }}>
                                <strong>
                                    {currencyFormatter(
                                        { value: totalCost },
                                        this.currencyFormatterOpts
                                    )}
                                </strong>
                            </p>
                        </td>
                        {this.totalColumnIndex + 1 < this.columnNum && (
                            <td
                                aria-label="Empty cell"
                                colSpan={this.columnNum - this.totalColumnIndex}
                            />
                        )}
                    </>
                )}
            </tr>
        );
    }

    render() {
        const {
            priceTable: { description, title },
            priceTableClassName,
        } = this.props;

        return (
            <div>
                {title && (
                    <p style={{ textAlign: 'center' }}>
                        <strong>{title.toUpperCase()}</strong>
                    </p>
                )}
                {description && <p style={{ textAlign: 'center' }}>{description}</p>}
                <table
                    className={priceTableClassName}
                    style={{
                        fontSize: this.columnNum > 7 ? '8pt' : '10pt',
                        marginBottom: 20,
                        width: this.tableWidth,
                    }}
                >
                    <thead>{this.renderTableHeader()}</thead>
                    <tbody>{this.renderTableRows()}</tbody>
                </table>
            </div>
        );
    }
}
