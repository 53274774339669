import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import {
    getPostingDate,
    getPostScheduledAt,
    getQuestionnaires,
    getReleaseProjectDate,
    isScheduledDateReleaseDate,
} from './selectors';
import { fieldNames } from '../constants';
import {
    AdminUserListButton,
    Button,
    DateTimePicker,
    ToggleButtonBox,
} from '../../../../components';
import { timezoneAbbreviations } from '../../../../constants';
import { hidePostConfirmationModal, initiateSubmit } from '../../../../actions/projectPost';
import { userRolePermissions } from '../../../../../../shared_config/userRoles';
import { DOWNLOAD, FILE_UPLOAD } from '../../../../../../shared_config/questionnaires';
import { postDateScheduledDateWarning } from '../helpers';
import { getBidPosterAuthorized } from '../../selectors';

const { POST_SCHEDULED_AT } = fieldNames;

const { IS_BID_POSTER } = userRolePermissions;

const TODAY = moment()
    .set({
        minute: 0,
        second: 0,
        millisecond: 0,
    })
    .toDate();

const mapStateToProps = (state) => {
    return {
        hasBidPostAuthorization: getBidPosterAuthorized(state),
        isScheduledDateReleaseDate: isScheduledDateReleaseDate(state),
        postingDate: getPostingDate(state),
        postScheduledAt: getPostScheduledAt(state),
        questionnaires: getQuestionnaires(state),
        releaseProjectDate: getReleaseProjectDate(state),
        show: state.projectPost.get('showPostConfirmationModal'),
    };
};

const mapDispatchToProps = {
    hideModal: hidePostConfirmationModal,
    initiateSubmit,
};

// @connect
class ConnectedPostConfirmationModal extends Component {
    static propTypes = {
        change: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        initiateSubmit: PropTypes.func.isRequired,
        hasBidPostAuthorization: PropTypes.bool.isRequired,
        isScheduledDateReleaseDate: PropTypes.bool.isRequired,
        postingDate: PropTypes.instanceOf(Date).isRequired,
        postScheduledAt: PropTypes.instanceOf(Date),
        project: PropTypes.shape({
            categories: PropTypes.array.isRequired,
            isPrivate: PropTypes.bool,
            postScheduledAt: PropTypes.instanceOf(Date),
            projectSections: PropTypes.array.isRequired,
            wasPosted: PropTypes.bool.isRequired,
        }).isRequired,
        questionnaires: PropTypes.array.isRequired,
        releaseProjectDate: PropTypes.instanceOf(Date),
        router: PropTypes.object.isRequired,
        show: PropTypes.bool.isRequired,
        timezone: PropTypes.string.isRequired,
        updateError: PropTypes.string,
        updating: PropTypes.bool,
        baseProjectPath: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = this.initialState;
    }

    UNSAFE_componentWillUpdate(nextProps) {
        // Set the postScheduledAt date when showing the modal
        if (!this.props.show && nextProps.show && !this.props.postScheduledAt) {
            const releaseDate = this.scheduleLaterDate();
            return this.props.change(POST_SCHEDULED_AT, releaseDate);
        }
    }

    componentWillUnmount() {
        if (this.props.show) {
            return this.hideModal();
        }
    }

    initialState = {
        isScheduledLater: true,
    };

    get styles() {
        return require('./PostConfirmationModal.scss');
    }

    get buttonProps() {
        if (this.state.isScheduledLater) {
            return {
                btnText: 'Schedule Post',
                btnIcon: 'fa-calendar',
                btnLoading: 'Scheduling...',
            };
        }
        return {
            btnText: 'Publish Post',
            btnIcon: 'fa-send',
            btnLoading: 'Publishing...',
        };
    }

    // Gets the release date or the current date if the release date is in past
    scheduleLaterDate = () => {
        const { project, releaseProjectDate } = this.props;

        // Use the saved postScheduledAt date if it exists
        if (project.postScheduledAt) {
            return project.postScheduledAt;
        }

        const date = moment(releaseProjectDate).isAfter() ? releaseProjectDate : new Date();

        return moment(date)
            .set({
                hour: 15,
                minute: 0,
                second: 0,
                millisecond: 0,
            })
            .toDate();
    };

    scheduleLaterHandler = (isScheduledLater) => {
        const { change } = this.props;

        if (isScheduledLater) {
            const releaseDate = this.scheduleLaterDate();
            change(POST_SCHEDULED_AT, releaseDate);
        } else {
            change(POST_SCHEDULED_AT, null);
        }
        return this.setState({ isScheduledLater });
    };

    // Reset modal to initial state and postScheduledAt to original date
    hideModal = () => {
        const { change, hideModal, project } = this.props;

        hideModal();
        this.setState(this.initialState);
        return change(POST_SCHEDULED_AT, project.postScheduledAt);
    };

    renderDate() {
        const { timezone, updating } = this.props;

        const { isScheduledLater } = this.state;

        if (!isScheduledLater) {
            return <div className={this.styles.postDate}>Publish Post Immediately</div>;
        }
        return (
            <div className="row">
                <div className={`col-sm-offset-2 col-sm-8 ${this.styles.calendar}`}>
                    <Field
                        component={DateTimePicker}
                        disabled={updating}
                        hasFeedback={false}
                        label="Posting Date"
                        min={TODAY}
                        name={POST_SCHEDULED_AT}
                        time
                    />
                    <div className={this.styles.timezone}>{timezoneAbbreviations[timezone]}</div>
                </div>
            </div>
        );
    }

    renderAdminRequired() {
        return (
            <div className={`${this.styles.errorMsg}`}>
                <div className="text-danger">
                    <i className="fa fa-exclamation-triangle" /> Only bid posters can post projects
                </div>
                <div>
                    <em>
                        If you are ready to post this project, please&nbsp;
                        <AdminUserListButton
                            permissionType={IS_BID_POSTER}
                            text="contact a bid poster"
                        />
                    </em>
                </div>
            </div>
        );
    }

    render() {
        const {
            hasBidPostAuthorization,
            postingDate,
            project,
            questionnaires,
            releaseProjectDate,
            show,
            updateError,
            updating,
            baseProjectPath,
        } = this.props;

        if (!show) {
            return null;
        }

        const { btnText, btnIcon, btnLoading } = this.buttonProps;
        const buttonText = updating ? btnLoading : btnText;
        const buttonIcon = updating ? 'fa-spin fa-spinner' : btnIcon;

        const uploadQuestionnaire = questionnaires.find(
            (questionnaire) =>
                !questionnaire.isHiddenByLogic &&
                (questionnaire.type === FILE_UPLOAD || questionnaire.type === DOWNLOAD)
        );
        const builderUrl = `${baseProjectPath}/builder/create/project-properties`;

        return (
            <Modal onHide={this.hideModal} show>
                <Modal.Body className="text-center">
                    <h4 className={this.styles.title}>When Should Project Post?</h4>
                    <ToggleButtonBox
                        className={this.styles.toggleBox}
                        isLeftActive={this.state.isScheduledLater}
                        leftButtonIcon="fa-calendar"
                        leftButtonText="Schedule For Later"
                        rightButtonIcon="fa-send"
                        rightButtonText="Post Now"
                        toggleHandler={this.scheduleLaterHandler}
                    >
                        {this.renderDate()}
                    </ToggleButtonBox>
                    {!this.props.isScheduledDateReleaseDate && hasBidPostAuthorization && (
                        <p className={`text-warning ${this.styles.warning}`}>
                            <i className="fa fa-exclamation-triangle" /> Warning:
                            <br />
                            {postDateScheduledDateWarning(postingDate, releaseProjectDate)}
                        </p>
                    )}
                    {!project.wasPosted && project.categories.length === 0 && (
                        <p className={`text-warning ${this.styles.warning}`}>
                            <i className="fa fa-exclamation-triangle" />
                            &nbsp;Warning:
                            <br />
                            There are no categories selected. If you would like to add categories,
                            please click +add next to Categories&nbsp;
                            <span
                                className="pseudoLink"
                                onClick={() => this.props.router.push(builderUrl)}
                            >
                                here
                            </span>
                            .
                        </p>
                    )}
                    {!project.wasPosted && !uploadQuestionnaire && (
                        <p className={`text-warning ${this.styles.warning}`}>
                            <i className="fa fa-exclamation-triangle" />
                            &nbsp;Warning:
                            <br />
                            There is no file upload question. Vendors will be unable to include
                            attachments.
                        </p>
                    )}
                    {project.isPrivate && (
                        <p className={`text-warning ${this.styles.warning}`}>
                            <i className="fa fa-exclamation-triangle" />
                            &nbsp;Warning:
                            <br />
                            A private bid is viewable only with a link. It will not be publicly
                            displayed in your portal and will not notify vendors when the project
                            posts.
                            <br />
                            <br />
                            Are you sure you want to publish a private bid?
                        </p>
                    )}
                    <Button
                        bsSize="lg"
                        bsStyle="success"
                        className={this.styles.submitButton}
                        disabled={!hasBidPostAuthorization || updating}
                        onClick={this.props.initiateSubmit}
                    >
                        <i className={`fa ${buttonIcon}`} /> {buttonText}
                    </Button>
                    <Button onClick={this.hideModal}>Cancel</Button>
                    {!hasBidPostAuthorization && this.renderAdminRequired()}
                    {updateError && (
                        <div className={`text-danger ${this.styles.errorMsg}`}>{updateError}</div>
                    )}
                </Modal.Body>
            </Modal>
        );
    }
}

export const PostConfirmationModal = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedPostConfirmationModal);
