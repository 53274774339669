import { listToDict } from '../../../../../../shared_config/helpers';

export const form = 'outlineNumberForm';

export const orderedListOptions = [
    { value: 'decimal', label: 'Decimal' },
    { value: 'lower-alpha', label: 'Lower Alpha' },
    { value: 'upper-alpha', label: 'Upper Alpha' },
    { value: 'lower-roman', label: 'Lower Roman' },
    { value: 'upper-roman', label: 'Upper Roman' },
];

const fields = ['firstLevel', 'secondLevel', 'thirdLevel', 'fourthLevel', 'fifthLevel'];

export const fieldNames = listToDict(fields);
