import { isValid } from 'redux-form';
import { createSelector } from 'reselect';

import { form as postCreateForm } from '../../ProjectPostCreate/constants';
import { form as evalCreateForm } from '../../EvaluationCreate/constants';
import { getProjectJS } from '../../selectors';
import { isInitialClientLoaded } from '../../../selectors';
import { evaluationStatuses } from '../../../../../../shared_config/evaluations';
import { projectStatusesDict } from '../../../../../../shared_config/projects';

const { POST_PENDING } = projectStatusesDict;

const { DRAFT: EVALUATION_DRAFT } = evaluationStatuses;

export const isPostFormValid = (state) =>
    isInitialClientLoaded(state) && isValid(postCreateForm)(state);
export const isEvaluationFormValid = (state) =>
    isInitialClientLoaded(state) && isValid(evalCreateForm)(state);

export const isPostSaveValid = createSelector(
    [isPostFormValid, getProjectJS],
    (isFormValid, project) => {
        // Saving is always valid in the pending state
        if (project && project.status === POST_PENDING) {
            return true;
        }

        return isFormValid;
    }
);

export const isEvaluationSaveValid = createSelector(
    [isEvaluationFormValid, getProjectJS],
    (isFormValid, project) => {
        // Saving is always valid in draft state
        if (project && project.evaluation && project.evaluation.status === EVALUATION_DRAFT) {
            return true;
        }

        return isFormValid;
    }
);
