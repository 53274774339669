import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';

import { AddendumsConfirmModal } from './AddendumsConfirmModal';
import {
    getAddendumProposals,
    getDraftAddendumsJS,
    getReleasedAddendumsJS,
    isProjectInAmendableStatus,
} from './selectors';
import { AddendumForm, AddendumsList, Button, ButtonGroup, Well, ZeroState } from '../..';
import { form as addendumFormName } from '../../Forms/AddendumForm/constants';
import {
    createAddendum,
    deleteAddendum,
    releaseAddendum,
    updateAddendum,
} from '../../../actions/addendums';
import { showConfirmationSimpleModal } from '../../../actions/confirmation';
import { ReportsModalButton } from '../../../containers/GovApp';
import { isSourcingEditor } from '../../../containers/GovApp/selectors';
import { addendumTypesDict, getAddendumText } from '../../../../../shared_config/addendums';
import { projectStatusesDict } from '../../../../../shared_config/projects';

const { ADDENDUM, NOTICE } = addendumTypesDict;

const { OPEN } = projectStatusesDict;

const mapStateToProps = (state, props) => {
    return {
        canEdit: isSourcingEditor(state),
        createError: state.addendums.get('createError'),
        creating: state.addendums.get('creating'),
        draftAddendums: getDraftAddendumsJS(state, props),
        isAmendableProjectStatus: isProjectInAmendableStatus(state),
        loadingProposals: state.addendums.get('loadingProposals'),
        proposals: getAddendumProposals(state, props),
        releasedAddendums: getReleasedAddendumsJS(state, props),
    };
};

const mapDispatchToProps = {
    createAddendum,
    deleteAddendum,
    releaseAddendum,
    showConfirmationSimpleModal,
    updateAddendum,
};

// @connect
class ConnectedGovernmentAddendums extends Component {
    static propTypes = {
        addendums: PropTypes.array.isRequired,
        canEdit: PropTypes.bool.isRequired,
        createAddendum: PropTypes.func.isRequired,
        createError: PropTypes.string,
        creating: PropTypes.bool.isRequired,
        deleteAddendum: PropTypes.func.isRequired,
        draftAddendums: PropTypes.array.isRequired,
        hasAutoAddenda: PropTypes.bool.isRequired,
        isAmendableProjectStatus: PropTypes.bool.isRequired,
        isNotice: PropTypes.bool,
        loadingProposals: PropTypes.bool.isRequired,
        location: PropTypes.object.isRequired,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
            status: PropTypes.string.isRequired,
        }),
        proposals: PropTypes.array.isRequired,
        releaseAddendum: PropTypes.func.isRequired,
        releasedAddendums: PropTypes.array.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        updateAddendum: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showAddendumConfirmedModal: false,
            showAddendumForm: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    get addendumText() {
        return getAddendumText({ isNotice: this.props.isNotice });
    }

    get createButtonProps() {
        const {
            canEdit,
            isNotice,
            project: { status },
        } = this.props;

        if (!isNotice && status !== OPEN) {
            return {
                disabled: true,
                tooltip: 'Addenda are closed. Please use Notices instead.',
            };
        }

        return {
            disabled: !canEdit,
            tooltip: canEdit
                ? undefined
                : `Only sourcing editors and admins can draft ${this.addendumText.lower}`,
        };
    }

    hideAddendumForm = () => this.setState({ showAddendumForm: false });

    showAddendumForm = () => this.setState({ showAddendumForm: true });

    createAddendum = (data) => {
        const { isNotice, project } = this.props;

        const addendumData = {
            ...data,
            type: isNotice ? NOTICE : ADDENDUM,
        };

        this.props.createAddendum(project.id, addendumData, { onSuccess: this.hideAddendumForm });
    };

    deleteAddendum = (addendum) => {
        const { project } = this.props;

        this.props.showConfirmationSimpleModal(
            () => this.props.deleteAddendum(project.id, addendum.id),
            {
                btnText: `Delete ${this.addendumText.upper} Draft`,
                text: `Are you sure you want to delete this draft ${this.addendumText.lower}?`,
                title: `Delete ${this.addendumText.upper} Draft`,
            }
        );
    };

    releaseAddendum = (addendum) => {
        const { project } = this.props;

        this.props.showConfirmationSimpleModal(
            () => this.props.releaseAddendum(project.id, addendum.id),
            {
                bsStyle: 'primary',
                btnText: `Release ${this.addendumText.upper}`,
                cancelText: 'Cancel',
                icon: 'envelope',
                iconSize: '',
                text: `Releasing the ${this.addendumText.lower} will send an email notification to all followers of the project. Are you sure you want to release the ${this.addendumText.lower}?`,
                title: `Release ${this.addendumText.upper}`,
            }
        );
    };

    updateAddendum = (addendum, data, successHandler) => {
        const { project } = this.props;

        this.props.updateAddendum(project.id, addendum.id, data, successHandler);
    };

    renderConfirmedAddendums() {
        const { isNotice, releasedAddendums, proposals } = this.props;

        const { showAddendumConfirmedModal } = this.state;

        if (isNotice || releasedAddendums.length === 0) {
            return null;
        }

        return (
            <>
                <ButtonGroup bsSize="sm">
                    <Button
                        onClick={() => this.setState({ showAddendumConfirmedModal: true })}
                        qaTag="connectedGovernmentAddendums-addendaAcknowledgements"
                    >
                        <i className="fa fa-check-square-o " /> Addenda Acknowledgements
                    </Button>
                    <ReportsModalButton type="addendumReport" />
                </ButtonGroup>
                {showAddendumConfirmedModal && (
                    <AddendumsConfirmModal
                        addendums={releasedAddendums}
                        hideModal={() => this.setState({ showAddendumConfirmedModal: false })}
                        proposals={proposals}
                    />
                )}
            </>
        );
    }

    render() {
        const {
            addendums,
            canEdit,
            createError,
            creating,
            draftAddendums,
            hasAutoAddenda,
            isAmendableProjectStatus,
            isNotice,
            loadingProposals,
            location,
            project,
            proposals,
            releasedAddendums,
        } = this.props;

        const { showAddendumForm } = this.state;

        if (addendums.length === 0 && !showAddendumForm) {
            return (
                <ZeroState
                    buttonClickHandler={this.showAddendumForm}
                    buttonProps={this.createButtonProps}
                    buttonText={
                        isAmendableProjectStatus ? (
                            <span>
                                <i className="fa fa-pencil" /> Draft {this.addendumText.upper}
                            </span>
                        ) : undefined
                    }
                    title={`No ${this.addendumText.upperPlural} Have Been Issued`}
                />
            );
        }

        return (
            <div>
                <div className={`row ${this.styles.actionButtons}`}>
                    <div className="col-sm-6">{this.renderConfirmedAddendums()}</div>
                    {showAddendumForm ? (
                        <div className="col-xs-12">
                            <Panel bsStyle="primary" className={this.styles.addendumForm}>
                                <Panel.Heading>
                                    <h4 className="text-center">
                                        Draft New {this.addendumText.upper}
                                    </h4>
                                </Panel.Heading>
                                <Panel.Body>
                                    <AddendumForm
                                        closeForm={this.hideAddendumForm}
                                        disabled={creating}
                                        form={`${this.addendumText.upper}_${addendumFormName}`}
                                        hasAutoAddenda={hasAutoAddenda}
                                        isCreateForm
                                        isNotice={isNotice}
                                        onSubmit={this.createAddendum}
                                        projectId={project.id}
                                        serverError={createError}
                                    />
                                </Panel.Body>
                            </Panel>
                        </div>
                    ) : (
                        isAmendableProjectStatus && (
                            <div className="col-sm-6 text-right">
                                <Button
                                    bsStyle="primary"
                                    onClick={this.showAddendumForm}
                                    qaTag="connectedGovernmentAddendums-new"
                                    {...this.createButtonProps}
                                >
                                    <i className="fa fa-plus" /> New {this.addendumText.upper}
                                </Button>
                            </div>
                        )
                    )}
                </div>
                {draftAddendums.length > 0 && isAmendableProjectStatus && (
                    <Well>
                        <h4 className={this.styles.draftAddendumsTitle}>
                            {this.addendumText.upperPlural} Awaiting Review
                        </h4>
                        <AddendumsList
                            addendums={draftAddendums}
                            canEdit={isAmendableProjectStatus && canEdit}
                            deleteHandler={this.deleteAddendum}
                            hasAutoAddenda={hasAutoAddenda}
                            isGovernmentView
                            isNotice={isNotice}
                            location={location}
                            project={project}
                            releaseHandler={this.releaseAddendum}
                            updateHandler={this.updateAddendum}
                        />
                    </Well>
                )}
                <AddendumsList
                    addendums={releasedAddendums}
                    canEdit={isAmendableProjectStatus && canEdit}
                    hasAutoAddenda={hasAutoAddenda}
                    isGovernmentView
                    isNotice={isNotice}
                    loadingProposals={loadingProposals}
                    location={location}
                    project={project}
                    proposals={proposals}
                    updateHandler={this.updateAddendum}
                />
            </div>
        );
    }
}

export const GovernmentAddendums = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedGovernmentAddendums);
