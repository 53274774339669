exports.languageDisplayNameMap = {
    SPANISH: 'Spanish',
    CANTONESE: 'Chinese (Cantonese)',
    MANDARIN: 'Chinese (Mandarin)',
    TAGALOG: 'Tagalog (Filipino)',
    VIETNAMESE: 'Vietnamese',
    FRENCH: 'French',
    ARABIC: 'Arabic',
    KOREAN: 'Korean',
    OTHER: 'Other',
};

exports.languages = Object.keys(exports.languageDisplayNameMap);

exports.languagesOptions = exports.languages.map((language) => ({
    label: exports.languageDisplayNameMap[language],
    value: language,
}));

exports.convertLanguagesToLanguageDisplayNames = (languages) =>
    languages.map((language) => exports.languageDisplayNameMap[language]).join(', ');
