import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { GovNavItems } from './GovNavItems';
import {
    getUserProjectHomepage,
    getUserIntakeHomepage,
    getUserContractHomepage,
} from '../../../helpers';

export const ProjectNavItems = ({
    governmentId,
    pathname,
    user,
    user: {
        government: { hasContracting, hasIntake, hasRequisition, hasRequisitionOnly },
        isExternal,
    },
}) => {
    const navItems = [];

    if (!hasRequisitionOnly) {
        navItems.push({
            className: classnames('projects-nav'),
            exactRoute: getUserProjectHomepage(user),
            icon: null,
            isActive: !!pathname.match(/\/governments\/\d+\/projects$/),
            path: 'projects',
            title: 'Projects',
        });
    }

    if (hasIntake) {
        navItems.push({
            className: classnames('intakes-nav'),
            exactRoute: getUserIntakeHomepage(user),
            icon: null,
            isActive: !!pathname.match(/\/intakes$/),
            path: 'intakes',
            title: 'Intake',
        });
    }

    if (hasRequisition) {
        navItems.push({
            className: classnames('requisitions-nav'),
            icon: null,
            isActive: !!pathname.match(/\/governments\/\d+\/requisitions$/),
            path: 'requisitions',
            title: 'Requisitions',
        });
    }

    if (hasContracting && !isExternal) {
        navItems.push({
            className: 'contracts-nav',
            exactRoute: getUserContractHomepage(user),
            icon: null,
            isActive: !!pathname.match(/\/governments\/\d+\/(contracts|documents)$/),
            path: 'contracts',
            title: 'Contracts',
        });
    }

    return <GovNavItems governmentId={governmentId} navItems={navItems} />;
};

ProjectNavItems.propTypes = {
    governmentId: PropTypes.number.isRequired,
    pathname: PropTypes.string.isRequired,
    user: PropTypes.shape({
        government: PropTypes.shape({
            hasContracting: PropTypes.bool.isRequired,
            hasIntake: PropTypes.bool.isRequired,
            hasRequisition: PropTypes.bool.isRequired,
            hasRequisitionOnly: PropTypes.bool.isRequired,
        }).isRequired,
        isExternal: PropTypes.bool.isRequired,
    }).isRequired,
};
