import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { isPassFailCriteria, scoreColor, scoreText as getScoreText } from '../../../../../helpers';
import { NumberWidget, ProgressBar, SearchSelect } from '../../../../../components';
import { isCostFormulaScoringCriterium } from '../../../../../../../shared_config/evaluations';

export class ScoreWithScaleInput extends PureComponent {
    static propTypes = {
        input: PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // String value only when input is empty
        }).isRequired,
        scoringCriteria: PropTypes.shape({
            maxScore: PropTypes.number.isRequired,
            minScore: PropTypes.number.isRequired,
        }).isRequired,
    };

    passFailScoringOptions = [
        { label: 'Pass', value: 1 },
        { label: 'Fail', value: 0 },
    ];

    get styles() {
        return require('./index.scss');
    }

    get isPassFail() {
        return isPassFailCriteria(this.props.scoringCriteria);
    }

    get isCostFormulaScoringCriterium() {
        return isCostFormulaScoringCriterium(this.props.scoringCriteria);
    }

    get value() {
        return this.props.input.value;
    }

    get scoringData() {
        const {
            scoringCriteria,
            scoringCriteria: { maxScore, minScore },
        } = this.props;

        const scoreText = getScoreText({
            criteriaScore: this.value,
            defaultText: this.value,
            scoringCriteria,
        });

        if (this.isPassFail) {
            return {
                maxScoreText: 'Pass',
                minScoreText: 'Fail',
                scoreText,
                scoreRange: 'Pass / Fail',
            };
        }

        return {
            maxScoreText: maxScore,
            minScoreText: minScore,
            scoreText,
            scoreRange: `${minScore}-${maxScore} Points`,
        };
    }

    renderScoreInput() {
        const {
            scoringCriteria: { minScore, maxScore },
            ...inputProps
        } = this.props;

        const { scoreRange } = this.scoringData;

        const inputData = {
            label: `Score (${scoreRange})`,
            placeholder: 'Enter a score',
        };

        if (this.isPassFail) {
            return (
                <SearchSelect
                    {...inputProps}
                    {...inputData}
                    isSearchable={false}
                    options={this.passFailScoringOptions}
                />
            );
        }

        if (this.isCostFormulaScoringCriterium) {
            return (
                <NumberWidget
                    {...inputProps}
                    format="-$#,###.##"
                    hasDecimal
                    label="Cost"
                    min={0}
                    placeholder="$60,000"
                />
            );
        }

        return (
            <NumberWidget {...inputProps} {...inputData} hasDecimal max={maxScore} min={minScore} />
        );
    }

    renderScoreIndicator() {
        const { scoringCriteria } = this.props;

        const { maxScore, minScore } = scoringCriteria;

        const { maxScoreText, minScoreText, scoreText } = this.scoringData;

        const bsStyle = scoreColor({ criteriaScore: this.value, scoringCriteria });

        return (
            <>
                <div className={this.styles.minScale}>{minScoreText}</div>
                <div className={this.styles.maxScale}>{maxScoreText}</div>
                <ProgressBar
                    bsStyle={bsStyle}
                    label={scoreText}
                    max={maxScore}
                    min={minScore}
                    now={this.value || minScore}
                />
            </>
        );
    }

    render() {
        return (
            <div>
                {this.renderScoreInput()}
                {!this.isCostFormulaScoringCriterium && this.renderScoreIndicator()}
            </div>
        );
    }
}
