import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';

import { getDepartmentsJS, getActiveUsersJS, getUserJS } from '../selectors';
import { hasUserRolesSubscription } from '../GovApp/selectors';
import { buildMap } from '../../../../shared_config/helpers';

const getUserRoles = (state) => state.adminGovernment.get('userRoles');

export const getUserRolesJS = createSelector([getUserRoles], (rawUserRoles) => rawUserRoles.toJS());

export const getDepartmentsMap = createSelector([getDepartmentsJS], (departments) => {
    return buildMap(departments, 'id');
});

export const getNavItems = createSelector(
    [getUserJS, hasUserRolesSubscription],
    (user, hasUserRoles) => {
        if (user.isVendor) {
            return [{ title: 'Users', path: 'users' }];
        }

        let baseNavItems = [
            { title: 'Users', path: 'users' },
            ...(hasUserRoles ? [{ title: 'Roles', path: 'roles' }] : []),
            { title: 'Departments', path: 'departments' },
            { title: 'Auto Numbering', path: 'auto-numbers' },
            { title: 'Tags', path: 'tags' },
        ];

        if (user.government.hasRequisitionOnly) {
            return baseNavItems;
        }

        baseNavItems = baseNavItems.concat([
            { title: 'Custom Categories', path: 'custom-categories' },
            { title: 'Documents', path: 'documents' },
            { title: 'Approvals', path: 'approvals' },
            { title: 'Workload', path: 'workload' },
            { title: 'Suggested Content', path: 'content' },
            { title: 'Site Settings', path: 'settings' },
        ]);

        return baseNavItems;
    }
);

export const getShowBulkUpdateModal = (state) => state.admin.get('showBulkUpdateModal');

export const getBulkUpdateOldUser = (state) => state.admin.get('bulkUpdateOldUser');

export const getBulkUpdateAndDisable = (state) => state.admin.get('bulkUpdateAndDisable');

export const getBulkUpdateFormValues = (state) => getFormValues('bulkUpdateContacts')(state);

export const getBulkUpdateSelectOptions = createSelector(
    [getActiveUsersJS, getBulkUpdateOldUser],
    (users, oldUser) =>
        users
            .filter((user) => user.id !== oldUser)
            .map((user) => {
                return {
                    value: user.id,
                    label: user.displayName,
                    user,
                };
            })
);
