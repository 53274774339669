import { combineReducers } from 'redux';

import { formReducer } from './form';
import accountActivate from './accountActivate';
import accountPersonal from './accountPersonal';
import accountSecurity from './accountSecurity';
import accountSecurityInvited from './accountSecurityInvited';
import addendums from './addendums';
import admin from './admin';
import adminGovernment from './adminGovernment';
import adminVendor from './adminVendor';
import analytics from './analytics';
import app from './app';
import approvals from './approvals';
import auth from './auth';
import calendar from './calendar';
import category from './category';
import checklists from './checklists';
import checklistsAdmin from './checklistsAdmin';
import confirmation from './confirmation';
import contacts from './contacts';
import contracts from './contracts';
import emergencyPortal from './emergencyPortal';
import error from './error';
import evaluations from './evaluations';
import exportProject from './exportProject';
import globalEditorDashboard from './globalEditorDashboard';
import govComments from './govComments';
import govPermissions from './govPermissions';
import govProjects from './govProjects';
import govProposals from './govProposals';
import govSignUp from './govSignUp';
import inboxNotifications from './inboxNotifications';
import notification from './notification';
import projectCreate from './projectCreate';
import projectLibrary from './projectLibrary';
import projectPost from './projectPost';
import projects from './projects';
import proposalEvaluations from './proposalEvaluations';
import publicProject from './publicProject';
import publicVendor from './publicVendor';
import questionnaire from './questionnaire';
import questions from './questions';
import ratings from './ratings';
import requisitionAccountDetails from './requisitionAccountDetails';
import requisitionGroups from './requisitionGroups';
import requisitions from './requisitions';
import requisitionsAdmin from './requisitionsAdmin';
import requisitionsApprovals from './requisitionsApprovals';
import requisitionsCreate from './requisitionsCreate';
import requisitionsDashboard from './requisitionsDashboard';
import retention from './retention';
import reverseAuctions from './reverseAuctions';
import reviewSequences from './reviewSequences';
import revisions from './revisions';
import rsvps from './rsvps';
import searchProjects from './searchProjects';
import tags from './tags';
import templateQuestions from './templateQuestions';
import templatesAdmin from './templatesAdmin';
import templateSections from './templateSections';
import vendorProfile from './vendorProfile';
import vendProposals from './vendProposals';
import vendorList from './vendorList';

const rootReducer = combineReducers({
    accountActivate,
    accountPersonal,
    accountSecurity,
    accountSecurityInvited,
    addendums,
    admin,
    adminVendor,
    adminGovernment,
    analytics,
    app,
    approvals,
    auth,
    calendar,
    category,
    checklistsAdmin,
    confirmation,
    contacts,
    contracts,
    emergencyPortal,
    error,
    evaluations,
    exportProject,
    form: formReducer,
    globalEditorDashboard,
    checklists,
    govComments,
    govPermissions,
    govProjects,
    govProposals,
    govSignUp,
    inboxNotifications,
    notification,
    projectCreate,
    projectLibrary,
    projectPost,
    projects,
    proposalEvaluations,
    publicProject,
    publicVendor,
    questionnaire,
    questions,
    ratings,
    requisitionAccountDetails,
    requisitionGroups,
    requisitions,
    requisitionsAdmin,
    requisitionsApprovals,
    requisitionsCreate,
    requisitionsDashboard,
    retention,
    reverseAuctions,
    reviewSequences,
    revisions,
    rsvps,
    searchProjects,
    tags,
    templateQuestions,
    templatesAdmin,
    templateSections,
    vendorProfile,
    vendProposals,
    vendorList,
});

export default rootReducer;
