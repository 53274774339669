import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Box } from '@og-pro/ui';

import { termUnits } from '../../../../../../shared_config/contracts';
import {
    Button,
    Toggle,
    InputText,
    SearchSelect,
    UserProfilePicture,
} from '../../../../components';
import {
    aiEnablementData as aiEnablementDataSelector,
    questionnaireConditionalEnabled as questionnaireConditionalEnabledSelector,
    getHideClosedProjectsSettings,
} from '../../../GovApp/App/selectors';
import { validate } from '../validate';
import { fieldNames, form } from '../constants';
import { AiConfirmationModal } from '../AiConfirmationModal';
import { getTimezone, getUsersJS } from '../../../selectors';
import { DownloadAiAuditsButton } from '../DownloadAiAuditsButton';

const {
    AI_ENABLED,
    HIDE_PUBLIC_CLOSED_PROJECTS_LENGTH,
    HIDE_PUBLIC_CLOSED_PROJECTS_UNIT,
    QUESTIONNAIRE_CONDITIONAL_ENABLED,
} = fieldNames;

const ConnectedSettingsForm = ({
    aiEnabledByUser,
    aiEnablementData,
    change,
    handleSubmit,
    initialValues,
    loading,
    onSubmit,
    pristine,
    timezone,
}) => {
    const [showAiConfirmationModal, setShowAiConfirmationModal] = useState(false);
    const [showHideClosedProjectsFields, setShowHideClosedProjectsFields] = useState(false);

    useEffect(() => {
        if (initialValues.hidePublicClosedProjectsUnit) {
            setShowHideClosedProjectsFields(true);
        }
    }, []);

    const aiEnabledToggleHandler = (e, toggledAiEnabled) => {
        e.preventDefault();

        if (toggledAiEnabled) {
            return setShowAiConfirmationModal(true);
        }

        return change(AI_ENABLED, false);
    };
    const hideModal = () => setShowAiConfirmationModal(false);

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Field
                    component={Toggle}
                    disabled={loading}
                    label="Enable Conditional Logic for Vendor Questionnaire"
                    labelInline
                    name={QUESTIONNAIRE_CONDITIONAL_ENABLED}
                    qaTag="siteSettings-enableConditionalVendorQuestions"
                    tooltip="When activated, allows editors to create conditional questions in the vendor questionnaire section, both in the template editor and the project editor"
                />
                <Field
                    component={Toggle}
                    disabled={loading}
                    label="Enable AI-Assisted Features"
                    labelInline
                    name={AI_ENABLED}
                    onChange={aiEnabledToggleHandler}
                    qaTag="siteSettings-enableAiAssistedFeatures"
                    tooltip="When activated, allows access to AI-assisted features such as Scope of Work generation"
                />
                {aiEnablementData && (
                    <>
                        <div className="text-muted" style={{ fontStyle: 'italic', marginTop: -20 }}>
                            Enabled on:{' '}
                            {moment.tz(aiEnablementData.enabledAt, timezone).format('LLL')}
                            &nbsp;by&nbsp;
                            {aiEnabledByUser.displayName}
                            &nbsp;
                            <UserProfilePicture horizontal user={aiEnabledByUser} />
                        </div>
                        <DownloadAiAuditsButton />
                    </>
                )}
                <Toggle
                    disabled={loading}
                    input={{
                        value: showHideClosedProjectsFields,
                        onChange: (e) => {
                            const v = e.target.checked;

                            setShowHideClosedProjectsFields(v);

                            if (!v) {
                                change('hidePublicClosedProjectsLength', null);
                                change('hidePublicClosedProjectsUnit', null);
                            }
                        },
                        onBlur: () => {},
                    }}
                    label="Remove Closed Projects from Public Portal after a period of time"
                    labelInline
                    meta={{}}
                    name="dummy.input"
                    qaTag="siteSettings-dummyInput"
                />
                {showHideClosedProjectsFields && (
                    <>
                        <Box>
                            <Box component="label" fontWeight="normal">
                                How much time should a Closed Project remain on your Public Vendor
                                Portal before it will no longer be viewable by the public?
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box mr={1}>
                                <Field
                                    component={InputText}
                                    disabled={loading}
                                    label=""
                                    name={HIDE_PUBLIC_CLOSED_PROJECTS_LENGTH}
                                    qaTag={`siteSettings-${HIDE_PUBLIC_CLOSED_PROJECTS_LENGTH}`}
                                    type="number"
                                />
                            </Box>
                            <Box>
                                <Field
                                    component={SearchSelect}
                                    disabled={loading}
                                    label=""
                                    name={HIDE_PUBLIC_CLOSED_PROJECTS_UNIT}
                                    options={termUnits.map((unit) => ({
                                        label: unit,
                                        value: unit,
                                    }))}
                                    qaTag={`siteSettings-${HIDE_PUBLIC_CLOSED_PROJECTS_UNIT}`}
                                />
                            </Box>
                        </Box>
                    </>
                )}
                <div className="text-right">
                    <Button
                        aria-label="Save settings"
                        bsStyle="primary"
                        disabled={pristine || loading}
                        qaTag="siteSettings-saveSettings"
                        type="submit"
                    >
                        <i className="fa fa-check" /> Save
                    </Button>
                </div>
            </form>
            <AiConfirmationModal
                hideModal={hideModal}
                onAccept={() => {
                    change(AI_ENABLED, true);
                    hideModal();
                }}
                show={showAiConfirmationModal}
            />
        </div>
    );
};

ConnectedSettingsForm.propTypes = {
    aiEnabledByUser: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
    }),
    aiEnablementData: PropTypes.shape({
        enabledAt: PropTypes.string.isRequired,
        userId: PropTypes.number.isRequired,
    }),
    change: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
        aiEnabled: PropTypes.bool.isRequired,
        questionnaireConditionalEnabled: PropTypes.bool.isRequired,
        hidePublicClosedProjectsLength: PropTypes.number,
        hidePublicClosedProjectsUnit: PropTypes.string,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    timezone: PropTypes.string.isRequired,
};

// not using selector because we need to initialValues to the reduxForm hoc
const mapStateToProps = (state) => {
    const aiEnablementData = aiEnablementDataSelector(state);
    const govUsers = getUsersJS(state);
    const aiEnabledByUser = aiEnablementData
        ? govUsers.find((user) => user.id === aiEnablementData.userId)
        : null;
    const questionnaireConditionalEnabled = questionnaireConditionalEnabledSelector(state);
    const timezone = getTimezone(state);
    const hideProjectsSettings = getHideClosedProjectsSettings(state);

    return {
        aiEnabledByUser,
        aiEnablementData,
        initialValues: {
            aiEnabled: !!aiEnablementData,
            questionnaireConditionalEnabled,
            ...hideProjectsSettings,
        },
        timezone,
    };
};

export const SettingsForm = compose(
    connect(mapStateToProps),
    reduxForm({
        form,
        enableReinitialize: true,
        validate,
    })
)(ConnectedSettingsForm);
