import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { getSDv2WritingSections } from '../../selectors';
import {
    BaseSectionsNavBackNextButtons,
    BaseSectionsNavBackButton,
    BaseSectionsNavNextButton,
} from '../../../../../components/SDv2';
import { projectSectionsToNavSections } from '../../../../../components/SDv2/helpers';
import { ProjectCreateV2NavContext, ProjectCreateV2FunctionsContext } from '../context';

export const ProjectCreateV2DocumentEditorBackNextButtons = () => {
    const projectSections = useSelector(getSDv2WritingSections);
    const { activeSectionId, setActiveSectionId } = useContext(ProjectCreateV2NavContext);
    const {
        buildRouteFunction,
        initiateSneakyUpdate,
        project: { useManualNumbering },
    } = useContext(ProjectCreateV2FunctionsContext);

    const sections = projectSectionsToNavSections({
        projectSections,
        active: activeSectionId,
        setActive: (i) => {
            initiateSneakyUpdate();
            setActiveSectionId(i);
        },
        useManualNumbering,
        hideEmptyDividers: true,
    });

    return (
        <BaseSectionsNavBackNextButtons
            firstSectionComponent={
                <BaseSectionsNavBackButton
                    onClick={() => initiateSneakyUpdate(buildRouteFunction('project-properties'))}
                >
                    Project Properties
                </BaseSectionsNavBackButton>
            }
            lastSectionComponent={
                <BaseSectionsNavNextButton
                    onClick={() => initiateSneakyUpdate(buildRouteFunction('attachments'))}
                >
                    Attachments
                </BaseSectionsNavNextButton>
            }
            sections={sections}
        />
    );
};
