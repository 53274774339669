import { get } from 'lodash';
import { createSelector } from 'reselect';

import initialNaicsCategories from './constants/initNaics.json';
import initialNigpCategories from './constants/initNigp.json';
import initialUnspscCategories from './constants/initUnspsc.json';
import { getUserJS } from '../selectors';
import { categorySets } from '../../../../shared_config/categories';

const getRawSelectedCategory = (state) => state.category.get('selectedCategories').last();
const getSearchResults = (state) => state.category.get('searchCategoriesResults');
const showAllCategories = (state) => state.category.get('showAllCategories');
const getUserSelectedCategorySet = (state, props) =>
    state.category.get('selectedCategorySet') || props.defaultCategorySet;

export const getSelectedCategory = createSelector(
    [getRawSelectedCategory],
    (rawSelectedCategory) => {
        if (rawSelectedCategory) {
            return rawSelectedCategory.toJS();
        }
    }
);

export const getSearchResultsJS = createSelector([getSearchResults], (searchResults) => {
    return searchResults.toJS();
});

export const getSelectedCategorySet = createSelector(
    [getUserSelectedCategorySet, getUserJS],
    (selectedCategorySet, user) => {
        return selectedCategorySet || get(user, 'government.categorySetId') || categorySets.NIGP;
    }
);

const getInitialCategorySet = createSelector([getSelectedCategorySet], (selectedCategorySet) => {
    if (selectedCategorySet === categorySets.NAICS) {
        return initialNaicsCategories;
    }
    if (selectedCategorySet === categorySets.UNSPSC) {
        return initialUnspscCategories;
    }
    return initialNigpCategories;
});

export const getInitialCategories = createSelector(
    [getInitialCategorySet, showAllCategories],
    (initialCategories, showAll) => {
        if (showAll) {
            return initialCategories;
        }
        return initialCategories.filter((category) => category.isPopular);
    }
);

export const getCategories = createSelector(
    [(state) => state.category.get('categories')],
    (categories) => categories?.toJS()
);
