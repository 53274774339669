import { getEmptyPriceItem, updateVendorAssociatedPriceItems } from '../../../helpers';
import { priceItemFieldNames } from '../../../../../constants';
import { discountTypes } from '../../../../../../../../../shared_config/priceTables';
import { fieldNames } from '../../../constants';

const { DISCOUNT, DISCOUNT_TYPE } = priceItemFieldNames;
const { ASSOCIATED_LINE_ITEMS, VENDORS } = fieldNames;

export const removeDiscount = (index, setFieldValues, setShowDiscount) => {
    setShowDiscount(false);
    setFieldValues(index, [
        { key: DISCOUNT, value: null },
        { key: DISCOUNT_TYPE, value: discountTypes.DOLLAR_AMOUNT },
    ]);
};

export const removeItem = (change, dispatch, fields, index, vendors) => {
    const { clientVendorAssignmentUuid, description, vendorAssignmentUuid } = fields.get(index);
    const totalFields = fields.length;
    updateVendorAssociatedPriceItems(
        dispatch,
        change,
        index,
        vendorAssignmentUuid,
        vendors,
        description,
        null,
        clientVendorAssignmentUuid
    );

    fields.remove(index);

    // Update vendor associated line items label to match the new index
    vendors.forEach((vendor, vendorIndex) => {
        vendor.associatedLineItems.forEach((lineItem, lineItemIndex) => {
            const foundIndex = fields
                .getAll()
                .findIndex(
                    (field) =>
                        field.vendorAssignmentUuid === lineItem.value &&
                        field.vendorAssignmentUuid !== vendorAssignmentUuid
                );
            if (foundIndex > index) {
                dispatch(
                    change(
                        `${VENDORS}[${vendorIndex}].${ASSOCIATED_LINE_ITEMS}[${lineItemIndex}].label`,
                        `Line Item ${foundIndex}`
                    )
                );
            }
        });
    });

    if (totalFields === 1) {
        // If there is only one item left, reset it
        const newPriceItem = getEmptyPriceItem();
        fields.push(newPriceItem);
    }
};
