import { createSelector } from 'reselect';

import { getCollaboratorsJS, getReviewProjectJS } from '../selectors';
import { moduleTypesDict } from '../../../../../shared_config/modules';

const { INTAKE, BUILDER, SOURCING, EVALUATION } = moduleTypesDict;

export const getModulePermissions = createSelector(
    [getReviewProjectJS, getCollaboratorsJS],
    (project, collaborators) => {
        if (project && project.isIntake) {
            return [
                {
                    collaborators: collaborators.filter(
                        (user) => user.projectUserPermissions[INTAKE]
                    ),
                    permissionTitle: 'Project Request',
                    moduleType: INTAKE,
                },
            ];
        }

        return [
            {
                collaborators: collaborators.filter((user) => user.projectUserPermissions[BUILDER]),
                permissionTitle: 'Builder',
                moduleType: BUILDER,
            },
            {
                collaborators: collaborators.filter(
                    (user) => user.projectUserPermissions[SOURCING]
                ),
                permissionTitle: 'Sourcing',
                moduleType: SOURCING,
            },
            {
                collaborators: collaborators.filter(
                    (user) => user.projectUserPermissions[EVALUATION]
                ),
                permissionTitle: 'Evaluations',
                moduleType: EVALUATION,
            },
        ];
    }
);
