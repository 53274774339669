import { moduleTypesDict } from '../../../../../shared_config/modules';

const { BUILDER, INTAKE, SOURCING, EVALUATION } = moduleTypesDict;

export const projectToolbarTypes = {
    BUILDER,
    CONTRACT: 'contract',
    EVALUATION,
    INTAKE,
    SOURCING,
};
