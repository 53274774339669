import React from 'react';

import { ArrayCellRenderer } from '../../AgGridReact/renderers';

export const CategoriesCellRenderer = (params) => (
    <ArrayCellRenderer
        data={params.data}
        tooltipTitle={params.value.map((category) => (
            <div key={category.code}>
                {category.code} - {category.title}
            </div>
        ))}
        value={params.value.map((category) => `${category.code} - ${category.title}`)}
    />
);
