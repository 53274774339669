import PropTypes from 'prop-types';
import React from 'react';

export const ReviewSequenceReviewGroups = ({ reviewGroups }) => {
    return reviewGroups.map(({ icon, id, name }) => (
        <div key={id}>
            {icon && (
                <>
                    <i className={`fa fa-fw fa-${icon}`} />
                    &nbsp;
                </>
            )}
            {name || 'Untitled'}
        </div>
    ));
};

ReviewSequenceReviewGroups.propTypes = {
    reviewGroups: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            id: PropTypes.number.isRequired,
            name: PropTypes.string,
        })
    ).isRequired,
};
