import React, { useContext } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { getFormSyncErrors } from 'redux-form';

import { sectionTypeNames } from '../../../../../../../shared_config/sections';
import { getSDv2WritingSections } from '../../selectors';
import { BaseProjectSectionsNav } from '../../../../../components/SDv2';
import { projectSectionsToNavSections } from '../../../../../components/SDv2/helpers';
import { form } from '../../constants';
import { ProjectCreateV2NavContext, ProjectCreateV2FunctionsContext } from '../context';

export const ProjectCreateV2DocumentEditorNav = () => {
    const errorsSelectors = getFormSyncErrors(form);
    const formErrors = useSelector((state) => errorsSelectors(state));

    const projectSections = useSelector(getSDv2WritingSections);
    const { activeSectionId, setActiveSectionId } = useContext(ProjectCreateV2NavContext);
    const {
        initiateSneakyUpdate,
        project: { useManualNumbering },
        showFormErrors,
    } = useContext(ProjectCreateV2FunctionsContext);

    const sections = projectSectionsToNavSections({
        projectSections,
        active: activeSectionId,
        setActive: (i) => {
            initiateSneakyUpdate();
            setActiveSectionId(i);
        },
        useManualNumbering,
        formErrors,
        showFormValidation: showFormErrors,
        matchErrorToSection: (section) =>
            sectionTypeNames.INTRODUCTION !== section.section_type
                ? get(formErrors, ['sections', section.id])
                : null,
        hideEmptyDividers: true,
    });

    return <BaseProjectSectionsNav sections={sections} />;
};
