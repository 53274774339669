import { listToDict } from '../../../shared_config/helpers';

export const contactAddressFields = [
    'contactAddress1',
    'contactAddress2',
    'contactCity',
    'contactCountryCode',
    'contactState',
    'contactZipCode',
];

export const contactPhoneFields = ['contactPhone', 'contactPhoneCountry', 'contactPhoneExt'];

export const contactNameFields = [
    'contactEmail',
    'contactFirstName',
    'contactLastName',
    'contactTitle',
];

export const contactFields = [...contactAddressFields, ...contactPhoneFields, ...contactNameFields];

export const contactFieldNames = listToDict(contactFields);
