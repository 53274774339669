import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const SectionHeaderViewTitleOnly = ({ title, description }) => {
    const styles = require('../shared.scss');

    return (
        <div className={classnames('section-header-title-only-container', styles.sectionHeader)}>
            <div className={styles.row}>
                <div className={styles.titleContainer}>
                    <h2 className={styles.title}>{title}</h2>
                    {description && <div className={styles.subtitle}>{description}</div>}
                </div>
            </div>
        </div>
    );
};

SectionHeaderViewTitleOnly.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
};
