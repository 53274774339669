import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@og-pro/ui';

import { ProjectDetailTitle } from '../GovApp';
import { SignatureDocx } from './SignatureDocx';
import { fieldTypes } from '../../../../shared_config/signatures';
import { signaturesBlocksToColumns } from '../../helpers/signatures';

const SignatureBlock = ({ block }) => {
    if (block.styling === 'header') {
        return (
            <Box
                dangerouslySetInnerHTML={{
                    __html: block.fields[0].value,
                }}
            />
        );
    }

    return (
        <Box>
            {block.fields?.map((field) => {
                return (
                    <Box display="flex" key={field.label} mt={2}>
                        <Box flex={1} mr={1}>
                            <b>{field.label}:</b>
                        </Box>
                        <Box borderBottom="1px solid #333" flex={4}>
                            {!!field.value &&
                                (field.type === fieldTypes.DATE
                                    ? new Date(field.value).toLocaleDateString()
                                    : field.value)}
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

SignatureBlock.propTypes = {
    block: PropTypes.shape({
        fields: PropTypes.array.isRequired,
        styling: PropTypes.oneOf(['header', 'default']).isRequired,
    }).isRequired,
};

export const Signature = (props) => {
    if (props.isDocx) {
        return <SignatureDocx {...props} />;
    }

    const {
        isDocx,
        projectSection,
        showComments,
        signatures,
        title,
        useManualNumbering,
        useSectionDividers,
    } = props;

    const sectionSignatures = signatures.filter(
        (signature) => signature.project_section_id === projectSection.id
    );

    return (
        <div className="col-xs-12 col-md-offset-1 col-md-10">
            <ProjectDetailTitle
                isDocx={isDocx}
                projectSection={projectSection}
                projectSubsectionId={null}
                showComments={showComments}
                title={title}
                useManualNumbering={useManualNumbering}
                useSectionDividers={useSectionDividers}
            />
            <Box px={2}>
                {sectionSignatures.map((rawSignature) => {
                    const signature = signaturesBlocksToColumns(rawSignature);

                    const left = signature.signaturesBlocks[0];
                    const right = signature.signaturesBlocks[1];

                    const maxLength = Math.max(left?.length || 0, right?.length || 0);

                    return (
                        <>
                            <Box dangerouslySetInnerHTML={{ __html: signature.introduction }} />
                            <Box>
                                {[...Array(maxLength).keys()].map((index) => {
                                    const leftBlock = left ? left[index] : null;
                                    const rightBlock = right ? right[index] : null;

                                    return (
                                        <Box display="flex" key={index} pb={6}>
                                            <Box
                                                borderTop={
                                                    !!leftBlock && index > 0
                                                        ? '1px dashed #333'
                                                        : 'none'
                                                }
                                                flex={1}
                                                mr={2}
                                            >
                                                {!!leftBlock && (
                                                    <SignatureBlock block={leftBlock} />
                                                )}
                                            </Box>
                                            <Box
                                                borderTop={
                                                    !!rightBlock && index > 0
                                                        ? '1px dashed #333'
                                                        : 'none'
                                                }
                                                flex={1}
                                                ml={2}
                                            >
                                                {!!rightBlock && (
                                                    <SignatureBlock block={rightBlock} />
                                                )}
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Box>
                            <Box dangerouslySetInnerHTML={{ __html: signature.footer }} />
                        </>
                    );
                })}
            </Box>
        </div>
    );
};

Signature.propTypes = {
    isDocx: PropTypes.bool,
    projectSection: PropTypes.shape({
        id: PropTypes.number.isRequired,
        manualNumber: PropTypes.string,
        sectionNumber: PropTypes.number,
        title: PropTypes.string.isRequired,
    }).isRequired,
    showComments: PropTypes.bool,
    signatures: PropTypes.array.isRequired,
    title: PropTypes.string,
    useManualNumbering: PropTypes.bool,
    useSectionDividers: PropTypes.bool,
};
