import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { buildMap } from '../../../../../../../../shared_config/helpers';

export class ProposalSelectOptions extends PureComponent {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        project: PropTypes.object.isRequired,
        proposals: PropTypes.array.isRequired,
        reportName: PropTypes.string.isRequired,
    };

    renderProposals(proposals, label) {
        const { onClick } = this.props;

        if (proposals.length === 0) {
            return null;
        }

        return (
            <>
                {label && <label>{label}</label>}
                <ListGroup>
                    {proposals.map((proposal) => (
                        <ListGroupItem key={proposal.id} onClick={() => onClick({ proposal })}>
                            <i className="fa fa-angle-right pull-right" />
                            {proposal.companyName || 'Unnamed Company'}
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </>
        );
    }

    render() {
        const { project, proposals, reportName } = this.props;

        const selected = get(project, 'evaluation.selectedProposals') || [];
        const selectedIds = buildMap(selected, 'id');
        const other = proposals.filter((proposal) => !selectedIds[proposal.id]);

        return (
            <>
                <h5 style={{ marginBottom: 15 }}>Choose a proposal to issue a {reportName} for</h5>
                {selected.length === 0 && other.length === 0 && (
                    <p>
                        <em>No proposals to select</em>
                    </p>
                )}
                {this.renderProposals(selected, other.length === 0 ? null : 'Selected Proposals')}
                {this.renderProposals(other, selected.length === 0 ? null : 'Other Proposals')}
            </>
        );
    }
}
