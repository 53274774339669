import React from 'react';
import { Outlet, redirect } from 'react-router-dom';

import {
    ProjectActionToolbarV2,
    ProjectCreateV2,
    ProjectCreateV2Attachments,
    ProjectCreateV2DocumentEditor,
    ProjectCreateV2FinalReviewChecklist,
    ProjectCreateV2ProjectProperties,
} from '../../../containers/GovApp';
import { legacyFetchData, requireDocBuilderSubscription } from '../../loaders';

// common routes for both intake and builder
const SubRoutes = (getState, dispatch) => [
    {
        element: <ProjectActionToolbarV2 />,
        children: [
            {
                element: <ProjectCreateV2 />,
                loader: legacyFetchData(getState, dispatch, ProjectCreateV2),
                children: [
                    {
                        index: true,
                        element: <Outlet />,
                        loader: () => redirect('project-properties'),
                    },
                    {
                        element: <ProjectCreateV2ProjectProperties />,
                        path: 'project-properties',
                    },
                    {
                        element: <ProjectCreateV2DocumentEditor />,
                        path: 'document-editor',
                    },
                    {
                        element: <ProjectCreateV2FinalReviewChecklist />,
                        path: 'final-review-checklist',
                    },
                    {
                        element: <ProjectCreateV2Attachments />,
                        path: 'attachments',
                    },
                ],
            },
        ],
    },
];

export default (getState, dispatch) => [
    {
        element: <Outlet />,
        loader: requireDocBuilderSubscription(getState),
        path: 'builder/create',
        children: SubRoutes(getState, dispatch),
    },
    {
        element: <Outlet />,
        loader: requireDocBuilderSubscription(getState),
        path: 'intake/create',
        children: SubRoutes(getState, dispatch),
    },
];
