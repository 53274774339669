import { createSelector } from 'reselect';

import { reportFormats, reportTypes } from './constants';
import {
    getProjectJS,
    isEvaluationEditor,
    isEvaluationViewer,
    isSourcingViewer,
} from '../../selectors';
import { projectStatusesDict } from '../../../../../../shared_config/projects';

const { AWARD_PENDING, CLOSED, EVALUATION, PENDING } = projectStatusesDict;

export const getReportsToDisplay = createSelector(
    [getProjectJS, isEvaluationEditor, isEvaluationViewer, isSourcingViewer],
    (project, evaluationEditor, evaluationViewer, sourcingViewer) => {
        return [
            {
                disabled: !project.wasPosted,
                disabledText: 'Report will be available once project has been posted',
                fullName: 'Question & Answer Report',
                hasPermission: true,
                icon: 'fa-send',
                name: 'Question & Answer',
                type: reportTypes.QUESTION_ANSWER_REPORT,
            },
            {
                disabled: !project.wasPosted,
                disabledText: 'Report will be available once project has been posted',
                fullName: 'Addenda Report',
                hasPermission: true,
                icon: 'fa-paperclip',
                name: 'Addenda<br>Report',
                type: reportTypes.ADDENDUM_REPORT,
            },
            {
                // NOTE: Proposal report could be removed entirely from this list. It's just here
                // for consistency as one of our reports, but is covered by the ALL_PROPOSALS_REPORT
                // report type below.
                disabled: !project.wasPosted,
                disabledText: 'Report will be available once project has been posted',
                fullName: 'Response Document Report',
                hasPermission: sourcingViewer || evaluationViewer,
                hasPermissionText:
                    'Only sourcing or evaluation viewers can generate Response Documents',
                icon: 'fa-file-text-o',
                name: 'Response Document',
                omitFromList: true, // Intentionally omits report from reports modal list
                type: reportTypes.PROPOSAL_DOCUMENT_REPORT,
            },
            {
                disabled: !project.wasPosted || !project.showBids,
                disabledText: `Report will be available once ${project.wasPosted ? 'bids have been unsealed' : 'project has been posted'}`,
                fullName: 'Response Document Report',
                hasPermission: sourcingViewer || evaluationViewer,
                hasPermissionText:
                    'Only sourcing or evaluation viewers can generate Response Document Reports',
                icon: 'fa-inbox',
                name: 'All Vendor Responses',
                type: reportTypes.ALL_PROPOSALS_REPORT,
                hasAllProposalsReportFeatureFlag: true,
            },
            {
                disabled: !project.evaluation,
                disabledText: 'Notice will be available once evaluation has been started',
                fullName: 'Notice of Interview Invitation',
                hasPermission: evaluationEditor,
                hasPermissionText: 'Only evaluation editors can create Interview Invitations',
                icon: 'fa-user',
                name: 'Interview Invitation',
                type: reportTypes.INTERVIEW_INVITATION_REPORT,
            },
            {
                disabled: !project.evaluation,
                disabledText: 'Notice will be available once evaluation has been started',
                fullName: 'Non-Award Letter',
                hasPermission: evaluationEditor,
                hasPermissionText: 'Only evaluation editors can create Non-Award Letters',
                icon: 'fa-ban',
                name: 'Non-Award Letter',
                type: reportTypes.NON_AWARD_REPORT,
            },
            {
                disabled: !project.evaluation,
                disabledText: 'Notice will be available once evaluation has been started',
                fullName: 'Notice of Intent to Award',
                hasPermission: evaluationEditor,
                hasPermissionText: 'Only evaluation editors can create Notices of Intent to Award',
                icon: 'fa-trophy',
                name: 'Notice of Intent to Award',
                type: reportTypes.AWARD_REPORT,
            },
            {
                disabled: !project.evaluation,
                disabledText: 'Report will be available once evaluation has been started',
                fullName: 'Evaluation Tabulation',
                hasPermission: evaluationEditor,
                icon: 'fa-bar-chart',
                name: 'Evaluation Tabulation',
                type: reportTypes.EVALUATION_TABULATION_REPORT,
            },
            {
                disabled: !project.wasPosted,
                disabledText: 'Report will be available once project has been posted',
                fullName: 'Executive Summary',
                hasPermission: evaluationEditor,
                icon: 'fa-files-o',
                name: 'Executive Summary',
                type: reportTypes.PROJECT_SUMMARY_REPORT,
            },
            {
                disabled: ![PENDING, EVALUATION, AWARD_PENDING, CLOSED].includes(project.status),
                disabledText: 'Report will be available once Reverse Auction has ended',
                fullName: 'Reverse Auction Summary',
                hasPermission: evaluationEditor,
                icon: 'fa-legal',
                name: 'Reverse Auction Summary',
                omitFromList: !project.template.isReverseAuction,
                type: reportTypes.REVERSE_AUCTION_SUMMARY_REPORT,
            },
        ];
    }
);

export const getFormatsToDisplay = createSelector([], () => {
    return [
        {
            hasPermission: true,
            icon: 'fa-file-word-o',
            name: 'Word<br>Document',
            type: reportFormats.DOCX,
        },
        {
            hasPermission: true,
            icon: 'fa-file-pdf-o',
            name: 'PDF<br>Document',
            type: reportFormats.PDF,
        },
        {
            hasPermission: true,
            icon: 'fa-search',
            name: 'Preview<br>Report',
            type: reportFormats.PREVIEW,
        },
        {
            hasPermission: true,
            hidden: !__DEV__,
            icon: 'fa-code',
            name: 'HTML<br>(Dev Only)',
            type: reportFormats.HTML,
        },
    ].filter((format) => !format.hidden);
});
