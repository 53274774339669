import { buildQaTag } from '@og-pro/ui';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { qaTagPageName } from '../constants';
import { currencyFormatter } from '../../../../helpers';
import { getGovernmentSalesTax, getIsRequisitionUsingFMS } from '../../../../selectors/govApp';
import { getPriceItemsSummary } from '../../../../../../shared_config/priceTables/requisitionUtils';
import { getLastActionDisplayData } from '../../../../../../shared_config/requisitions/lastActions';
import { getRequisitionStatusData } from '../../../../../../shared_config/requisitions';

export const StatusBar = ({
    requisition,
    requisition: {
        status,
        priceTable: { priceItems },
    },
}) => {
    const styles = require('./StatusBar.scss');

    const { shortName, shortNameBsStyle } = getRequisitionStatusData(status);
    const {
        bsStyle: lastActionBsStyle,
        icon: lastActionIcon,
        text: lastActionText,
    } = getLastActionDisplayData(requisition);

    const taxRate = useSelector(getGovernmentSalesTax);
    const hasFMS = useSelector(getIsRequisitionUsingFMS);

    const { total } = useMemo(
        () => getPriceItemsSummary(priceItems, taxRate),
        [priceItems, taxRate]
    );

    const items = [
        {
            label: 'Status',
            Component: () => (
                <div
                    className={classNames(
                        `text-${shortNameBsStyle}`,
                        styles.statusBarItemBoldValue
                    )}
                >
                    {shortName}
                </div>
            ),
        },
        {
            label: 'Budget Check',
            Component: () => (
                <div
                    className={classNames(
                        requisition.isOverBudget ? 'text-danger' : 'text-success',
                        styles.statusBarItemBoldValue
                    )}
                >
                    {requisition.isOverBudget ? 'Fail' : 'Pass'}
                </div>
            ),
            hidden: !hasFMS,
        },
        {
            label: 'Amount',
            Component: () => <div>{currencyFormatter({ value: total })}</div>,
        },
        { label: 'Items', Component: () => <div>{priceItems.length}</div> },
        {
            label: 'Last Action',
            Component: () => (
                <div
                    className={classNames(
                        `text-${lastActionBsStyle}`,
                        styles.statusBarItemBoldValue
                    )}
                >
                    <i className={`fa ${lastActionIcon}`} /> {lastActionText}
                </div>
            ),
        },
    ];

    return (
        <div className={styles.statusBarContainer}>
            {items
                .filter(({ hidden }) => !hidden)
                .map(({ Component, label }, index) => (
                    <div className={styles.statusBarItem} key={index}>
                        <div className={styles.statusBarItemLabel}>{label}:</div>
                        <div
                            className={styles.statusBarItemValue}
                            data-qa={buildQaTag(`${qaTagPageName}-${label}`, 'statusItemValue')}
                        >
                            <Component />
                        </div>
                    </div>
                ))}
        </div>
    );
};

StatusBar.propTypes = {
    requisition: PropTypes.shape({
        isOverBudget: PropTypes.bool.isRequired,
        priceTable: PropTypes.shape({
            priceItems: PropTypes.array.isRequired,
        }).isRequired,
        status: PropTypes.number.isRequired,
    }).isRequired,
};
