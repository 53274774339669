import { instructionDataFieldNames } from './constants';
import { fieldNames } from '../../constants';
import {
    arrayError,
    manualNumber,
    MAX_TEXT_AREA_LENGTH,
    REQUIRED_TEXT,
} from '../../../../../Forms/validation';
import {
    criteriaInstructionsDict,
    instructionsWithoutDescription,
} from '../../../../../../../shared_config/criteria';

const { DESCRIPTION, INSTRUCTION_DATA, INSTRUCTION_TYPE, INSTRUCTIONS, MANUAL_NUMBER, TITLE } =
    fieldNames;

const { RADIO, SELECT } = criteriaInstructionsDict;

const {
    INSTRUCTIONS: INSTRUCTION_DATA_INSTRUCTIONS,
    NAME,
    OPTIONS,
    VALUE,
} = instructionDataFieldNames;

export function validate(criteria, props) {
    const { isSharedSectionForm, isTextArea, useManualNumbering } = props;
    const errors = {
        [INSTRUCTION_DATA]: {},
    };

    if (isTextArea) {
        // Text area items do not have titles
    } else if (!criteria[TITLE]) {
        errors[TITLE] = REQUIRED_TEXT;
    } else if (criteria[TITLE].length >= 200) {
        errors[TITLE] = 'This field is too long';
    }

    if (useManualNumbering && !isTextArea) {
        errors[MANUAL_NUMBER] = manualNumber(criteria[MANUAL_NUMBER], {
            isSharedSection: isSharedSectionForm,
        });
    }

    if (instructionsWithoutDescription.includes(criteria[INSTRUCTION_TYPE])) {
        // Can bypass description validation
    } else if (!criteria[DESCRIPTION]) {
        errors[DESCRIPTION] = REQUIRED_TEXT;
    } else if (criteria[DESCRIPTION].length >= MAX_TEXT_AREA_LENGTH) {
        errors[DESCRIPTION] = 'This field is too long';
    }

    if (!criteria[INSTRUCTION_TYPE]) {
        errors[INSTRUCTION_TYPE] = 'Must select instruction type';
    }

    // The instructions shouldn't allow just spaces. There needs to be text here to prevent
    // a bug on the UI that prevents editing of the element when there's no valid characters.
    if (!criteria[INSTRUCTIONS] || criteria[INSTRUCTIONS].trim().length === 0) {
        errors[INSTRUCTIONS] = 'Must include instructions';
    }

    if (criteria[INSTRUCTION_TYPE] === RADIO || criteria[INSTRUCTION_TYPE] === SELECT) {
        const instructionData = criteria[INSTRUCTION_DATA];

        if (!instructionData || !instructionData[OPTIONS] || instructionData[OPTIONS].length < 2) {
            errors[INSTRUCTION_DATA][OPTIONS] = arrayError('Please include at least two options');
        } else {
            errors[INSTRUCTION_DATA][OPTIONS] = instructionData[OPTIONS].map((option) => {
                const optionErrors = {};

                if (!option[NAME] && !option[INSTRUCTION_DATA_INSTRUCTIONS]) {
                    const error = 'Please include a name for the option';
                    optionErrors[NAME] = error;
                    optionErrors[INSTRUCTION_DATA_INSTRUCTIONS] = error;
                }

                if (!option[VALUE]) {
                    optionErrors[VALUE] =
                        'Please include a value to insert when option is selected';
                }

                return optionErrors;
            });
        }
    }

    return errors;
}
