import { get, pick } from 'lodash';
import { createSelector } from 'reselect';

import { contractPartyFieldNames, contractPartyFields, fieldNames, fields } from './constants';
import { getContractJS, getTimezone } from '../../../containers/selectors';
import { convertToDate } from '../../../utils';
import { contractStatesDict } from '../../../../../shared_config/contracts';

const { PUBLISHED } = contractStatesDict;

const { ATTACHMENTS, CONTRACT_MILESTONES, CONTRACT_PARTY, END_DATE, START_DATE, TAGS } = fieldNames;

const { VENDOR_ORGANIZATION_ID } = contractPartyFieldNames;

export const getContractWithDeserializedDates = createSelector(
    [getContractJS, getTimezone],
    (contract, timezone) => {
        if (contract) {
            const { contractParty } = contract;
            const contractPartyData = pick(contractParty, contractPartyFields);

            if (contractParty && contractParty.vendor) {
                // Form uses the vendor organization ID instead of vendor ID due to the vendor search API
                contractPartyData[VENDOR_ORGANIZATION_ID] = contractParty.vendor.organization_id;
            }

            const contractData = {
                ...pick(contract, fields),
                [CONTRACT_PARTY]: contractPartyData,
            };

            // We are updating a contract that is already PUBLISHED
            if (contract.state === PUBLISHED) {
                /**
                 * Date conversion is required to convert from the UTC date stored in the database
                 * to the date displayed in the datepicker input that the user uses to specify the
                 * date in their government's timezone.
                 *
                 * On form submission, the input time will be serialized from the input time to the
                 * UTC time and then this deserializer will reconvert back to the input time that
                 * the date picker needs for displaying the time to the user.
                 *
                 * NOTE: This only needs to be done when loading dates into inputs. Otherwise just
                 * use `moment.tz()` to display the dates in the correct timezone.
                 */

                contractData[ATTACHMENTS] = contract[ATTACHMENTS].map((attachment) => {
                    // For now, since we limit the attachment tag select to a single tag, we have to
                    // manipulate the data to pass a single tag in for the prop instead of an array
                    const [tag] = attachment[TAGS] || [];
                    return {
                        ...attachment,
                        [TAGS]: tag ? tag.id : undefined,
                    };
                });

                contractData[CONTRACT_MILESTONES] = contract[CONTRACT_MILESTONES].map(
                    (milestone) => {
                        return {
                            ...milestone,
                            date: convertToDate(milestone.date, timezone),
                        };
                    }
                );

                if (contractData[END_DATE]) {
                    contractData[END_DATE] = convertToDate(contractData[END_DATE], timezone);
                }
                if (contractData[START_DATE]) {
                    contractData[START_DATE] = convertToDate(contractData[START_DATE], timezone);
                }
            }

            return contractData;
        }
    }
);

export const hasManuallyEnteredContractParty = createSelector(
    [getContractJS],
    (contract) =>
        !get(contract, 'contractParty.vendor_id') && !!get(contract, 'contractParty.companyName')
);
