import React from 'react';
import { useSelector } from 'react-redux';

import {
    requisitionTabsNames,
    requisitionQuickFiltersNames,
} from '../../../../../../../../shared_config/requisitions';
import { getOrganization } from '../../../../../../components/connected/OrganizationInfoForm/selectors';
import { ProjectCreateButton } from '../../../../../../components/GovApp';
import { RequisitionsEmptyState } from '../../../../../../components';
import { useQueryParam } from '../../../../../../hooks';
import { getIsGroupAdminOrLeader } from '../../selectors';

const { ALL_REQUESTS, MY_APPROVALS, MY_REQUESTS } = requisitionTabsNames;
const { IN_PROCESS, RECENTLY_CLOSED, THIS_FISCAL_YEAR, ALL, ACTION_NEEDED, DRAFTS } =
    requisitionQuickFiltersNames;

const emptyStateDataMapping = (entityName) => ({
    // All Requests
    [`${ALL_REQUESTS}_${IN_PROCESS}`]: {
        icon: 'fa fa-shopping-cart',
        title: 'No Active Requests',
        subtitle: 'As of now, there are no in process requests.',
    },
    [`${ALL_REQUESTS}_${RECENTLY_CLOSED}`]: {
        icon: 'fa fa-calendar',
        title: 'No Recently Closed Requests',
        subtitle: 'As of now, there have been no requests closed in the last 30 days.',
    },
    [`${ALL_REQUESTS}_${THIS_FISCAL_YEAR}`]: {
        icon: 'fa fa-calendar',
        title: 'No Requests',
        subtitle: 'As of now, there are no requests available for this fiscal year.',
    },
    [`${ALL_REQUESTS}_${ALL}`]: {
        icon: 'fa fa-shopping-cart',
        title: 'No Requests',
        subtitle: `As of now, there are no requests for ${entityName}.`,
    },

    [`${ALL_REQUESTS}_${ALL}_rejected`]: {
        icon: 'fa fa-shopping-cart',
        title: 'No Requests',
        subtitle: `As of now, there are no requests rejected for ${entityName}.`,
    },

    [`${ALL_REQUESTS}_${ALL}_onHold`]: {
        icon: 'fa fa-shopping-cart',
        title: 'No Requests',
        subtitle: `As of now, there are no requests on hold for ${entityName}.`,
    },

    [`${ALL_REQUESTS}_${ALL}_overBudget`]: {
        icon: 'fa fa-shopping-cart',
        title: 'No Requests',
        subtitle: `As of now, there are no requests over budget for ${entityName}.`,
    },

    // My Approvals
    [`${MY_APPROVALS}_${ACTION_NEEDED}`]: {
        icon: 'fa fa-check',
        title: 'You’re All Set',
        subtitle: 'At this time, there are no requests that need your attention.',
    },
    [`${MY_APPROVALS}_${IN_PROCESS}`]: {
        icon: 'fa fa-shopping-cart',
        title: 'No Active Requests',
        subtitle:
            'As of now, there are no requests in process where you are an approver on any given step.',
    },
    [`${MY_APPROVALS}_${RECENTLY_CLOSED}`]: {
        icon: 'fa fa-calendar',
        title: 'No Recently Closed Requests',
        subtitle:
            'As of now, there have been no requests where you are an approver closed in the last 30 days.',
    },
    [`${MY_APPROVALS}_${ALL}`]: {
        icon: 'fa fa-tasks',
        title: 'No Requests',
        subtitle: 'As of now, there are no requests where you were an approver.',
    },

    // My Requests
    [`${MY_REQUESTS}_${DRAFTS}`]: {
        icon: 'fa fa-shopping-cart',
        title: 'No Drafts',
        subtitle: 'As of now, you do not have any requests in draft.',
    },
    [`${MY_REQUESTS}_${IN_PROCESS}`]: {
        icon: 'fa fa-shopping-cart',
        title: 'No Active Requests',
        subtitle: 'As of now, you do not have any requests in process.',
    },
    [`${MY_REQUESTS}_${RECENTLY_CLOSED}`]: {
        icon: 'fa fa-calendar',
        title: 'No Recently Closed Requests',
        subtitle: 'As of now, you do not have any requests that were closed in the last 30 days.',
    },
    [`${MY_REQUESTS}_${ALL}`]: {
        icon: 'fa fa-tasks',
        title: 'No Requests',
        subtitle: 'As of now, you have not created any requests.',
    },
});

const getEmptyStateData = (activeTab, quickFilter, entityName, type) => {
    const emptyStateDataMap = emptyStateDataMapping(entityName);
    let key = `${activeTab}_${quickFilter}`;

    if (type) {
        key += `_${type}`;
    }

    if (emptyStateDataMap.hasOwnProperty(key)) {
        return emptyStateDataMap[key];
    }

    return {
        icon: 'fa fa-question-circle',
        title: 'No Data Available',
        subtitle: 'Sorry, no data is available for this combination.',
    };
};

export const RequisitionDashboardEmptyState = () => {
    const styles = require('./index.scss');

    const [activeTab] = useQueryParam('tab');
    const [quickFilter] = useQueryParam('quickFilter');
    const [type] = useQueryParam('type');

    const { name: entityName } = useSelector((state) => getOrganization(state));

    const { icon, title, subtitle } = getEmptyStateData(activeTab, quickFilter, entityName, type);

    const isAdminOrLeader = useSelector((state) => getIsGroupAdminOrLeader(state));

    return (
        <RequisitionsEmptyState icon={icon} subtitle={subtitle} title={title}>
            {isAdminOrLeader && (
                <div>
                    <ProjectCreateButton
                        bsStyle="link"
                        className={styles.newRequestBtn}
                        hideIcon
                        isRequisition
                        qaTag="requisitionDashboardEmptyState-create"
                        text="Start New Request"
                    />
                </div>
            )}
        </RequisitionsEmptyState>
    );
};
