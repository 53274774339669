import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { ProjectExportModalBody } from './ProjectExportModalBody';
import { getProjectJS } from '../selectors';
import * as exportActions from '../../../actions/exportProject';
import {
    initiateExport,
    initiateExportDocument,
} from '../../../actions/project/create/projectCreate';
import { Button } from '../../../components';

const mapStateToProps = (state) => {
    return {
        error: state.exportProject.get('error'),
        filename: state.exportProject.get('filename'),
        googleUrl: state.exportProject.get('googleUrl'),
        loading: state.exportProject.get('loading'),
        loadingDocument: state.exportProject.get('loadingDocument'),
        loaded: state.exportProject.get('loaded'),
        loadedDocument: state.exportProject.get('loadedDocument'),
        pdfUrl: state.exportProject.get('pdfUrl'),
        project: getProjectJS(state),
        saveOnExport: state.exportProject.get('saveOnExport'),
        sectionId: state.exportProject.get('sectionId'),
        showModal: state.exportProject.get('showModal'),
        wordUrl: state.exportProject.get('wordUrl'),
        zipFilename: state.exportProject.get('zipFilename'),
        zipUrl: state.exportProject.get('zipUrl'),
    };
};

const mapDispatchToProps = {
    ...exportActions,
    initiateExport,
    initiateExportDocument,
};

// @connect
class ConnectedProjectExportModal extends Component {
    static propTypes = {
        exportProject: PropTypes.func.isRequired,
        exportProjectDocument: PropTypes.func.isRequired,
        googleUrl: PropTypes.string,
        filename: PropTypes.string,
        hideExportModal: PropTypes.func.isRequired,
        initiateExport: PropTypes.func.isRequired,
        initiateExportDocument: PropTypes.func.isRequired,
        loaded: PropTypes.bool,
        loadedDocument: PropTypes.bool,
        loading: PropTypes.bool,
        loadingDocument: PropTypes.bool,
        pdfUrl: PropTypes.string,
        project: PropTypes.shape({
            template: PropTypes.shape({
                omitDocx: PropTypes.bool.isRequired,
            }).isRequired,
        }),
        saveOnExport: PropTypes.bool,
        sectionId: PropTypes.number,
        setExportData: PropTypes.func.isRequired,
        showModal: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        wordUrl: PropTypes.string,
        zipFilename: PropTypes.string,
        zipUrl: PropTypes.string,
    };

    exportProject = () => {
        const { exportProject, saveOnExport } = this.props;

        if (saveOnExport) {
            this.props.initiateExport();
        } else {
            exportProject();
        }
    };

    exportProjectDocument = () => {
        const { exportProjectDocument, saveOnExport } = this.props;

        if (saveOnExport) {
            this.props.initiateExportDocument();
        } else {
            exportProjectDocument();
        }
    };

    render() {
        const { hideExportModal, project, sectionId, showModal } = this.props;

        if (!showModal || !project) {
            return null;
        }

        const isPreview = showModal === 'preview';
        const sectionText = sectionId ? ' Section' : '';

        return (
            <Modal onHide={hideExportModal} show>
                <Modal.Header closeButton>
                    <Modal.Title id="export-project-modal-title">
                        {isPreview
                            ? `Preview Document${sectionText}`
                            : `Export Project${sectionText}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {get(project, 'template.omitDocx') && (
                        <p className="text-center text-danger">
                            <i className="fa fa-exclamation-triangle" /> Based on the settings for
                            this template, a Project Document PDF will NOT be automatically
                            generated when this project is posted.
                        </p>
                    )}
                    <ProjectExportModalBody
                        {...this.props}
                        exportProject={this.exportProject}
                        exportProjectDocument={this.exportProjectDocument}
                        isPreview={isPreview}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={hideExportModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export const ProjectExportModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedProjectExportModal);
