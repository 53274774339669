import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

import {
    form as savedFilterSelectForm,
    SELECTED_SAVED_FILTER,
} from './SavedFilterSelectForm/constants';
import { getUserJS } from '../../../containers/App/selectors';
import { filterTypesDict } from '../../../../../shared_config/filters';

const { DOC_BUILDER, PROJECT, INTAKE } = filterTypesDict;

const getSelectedSavedFilterValue = (state) =>
    formValueSelector(savedFilterSelectForm)(state, SELECTED_SAVED_FILTER);

export const getUserProjectFilters = createSelector(
    [getUserJS, (state, props) => props.isDocBuilder, (state, props) => props.isIntake],
    (user, isDocBuilder, isIntake) => {
        if (!user) {
            return [];
        }

        let filterTypeToMatch = PROJECT;
        if (isIntake) {
            filterTypeToMatch = INTAKE;
        } else if (isDocBuilder) {
            filterTypeToMatch = DOC_BUILDER;
        }

        return user.filters.filter((filter) => filter.type === filterTypeToMatch);
    }
);

export const getUserProjectFilterOptions = createSelector(
    [getUserProjectFilters],
    (userProjectFilters) => {
        return userProjectFilters.map((filter) => {
            return {
                label: filter.name,
                value: filter.id,
            };
        });
    }
);

export const getManagedFilterJS = createSelector(
    [(state) => state.auth.get('savedFilterBeingManaged')],
    (managedFilter) => {
        if (managedFilter) {
            return managedFilter.toJS();
        }
    }
);

export const getSelectedSavedFilter = createSelector(
    [getUserProjectFilters, getSelectedSavedFilterValue],
    (userFilters, selectedSavedFilterValue) => {
        if (selectedSavedFilterValue) {
            return userFilters.find((filter) => filter.id === selectedSavedFilterValue);
        }
    }
);
