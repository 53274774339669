import PropTypes from 'prop-types';
import React from 'react';
import { DropdownMenu, ListItem, ListItemButton, ListItemText } from '@og-pro/ui';
import { useSelector } from 'react-redux';

export const TakeActionsMenu = ({
    addVendorsToList,
    addVendorsToListBySearch,
    blockVendors,
    emailVendors,
    emailVendorsBySearch,
    inviteVendorsToProject,
    inviteVendorsToProjectBySearch,
    searchParams,
    selectedUserIds,
}) => {
    const loadedVendorCount = useSelector((state) => state.vendorList.get('vendorCount'));

    const vendorCount = selectedUserIds.length > 0 ? selectedUserIds.length : loadedVendorCount;
    const useBulkActions = selectedUserIds.length === 0;

    const actionsList = [
        {
            id: 1,
            title: `Add ${vendorCount} vendor subscriber${vendorCount > 1 ? 's' : ''} to a list`,
            ...(useBulkActions
                ? { onClick: () => addVendorsToListBySearch(searchParams) }
                : {
                      onClick: () => addVendorsToList(selectedUserIds),
                  }),
        },
        {
            id: 2,
            title: `Invite ${vendorCount} vendor subscriber${vendorCount > 1 ? 's' : ''} to a project`,
            ...(useBulkActions
                ? { onClick: () => inviteVendorsToProjectBySearch(searchParams) }
                : {
                      onClick: () => inviteVendorsToProject(selectedUserIds),
                  }),
        },
        {
            id: 3,
            title: `Email ${vendorCount} vendor subscriber${vendorCount > 1 ? 's' : ''}`,
            ...(useBulkActions
                ? { onClick: () => emailVendorsBySearch(searchParams) }
                : {
                      onClick: () => emailVendors(selectedUserIds),
                  }),
        },
        ...(useBulkActions
            ? []
            : [
                  {
                      id: 4,
                      title: `Block ${vendorCount} vendor subscriber${vendorCount > 1 ? 's' : ''}`,
                      onClick: () => blockVendors(selectedUserIds, true),
                  },
                  {
                      id: 5,
                      title: `Unblock ${vendorCount} vendor subscriber${vendorCount > 1 ? 's' : ''}`,
                      onClick: () => blockVendors(selectedUserIds, false),
                  },
              ]),
    ];

    return (
        <DropdownMenu
            color="primary"
            disabled={vendorCount === 0}
            label="Actions on Search"
            variant="text"
        >
            {actionsList.map(({ id, onClick, title }) => (
                <ListItem disablePadding key={id}>
                    <ListItemButton onClick={onClick}>
                        <ListItemText id={`certification-list-label-${id}`} primary={title} />
                    </ListItemButton>
                </ListItem>
            ))}
        </DropdownMenu>
    );
};

TakeActionsMenu.propTypes = {
    addVendorsToList: PropTypes.func.isRequired,
    addVendorsToListBySearch: PropTypes.func.isRequired,
    blockVendors: PropTypes.func.isRequired,
    emailVendors: PropTypes.func.isRequired,
    emailVendorsBySearch: PropTypes.func.isRequired,
    inviteVendorsToProject: PropTypes.func.isRequired,
    inviteVendorsToProjectBySearch: PropTypes.func.isRequired,
    searchParams: PropTypes.object.isRequired,
    selectedUserIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};
