import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change as changeFormAction, Field, FieldArray, reduxForm } from 'redux-form';
import { v4 as UUIDv4 } from 'uuid';

import {
    dateFilterOptions,
    fieldNamesDict,
    form,
    isPausedOptions,
    scopeFilterButtons,
    typeOptions,
} from './constants';
import { ManageSavedFilterModal } from './ManageSavedFilterModal';
import { SavedFilterSelectForm } from './SavedFilterSelectForm';
import {
    form as savedFilterSelectForm,
    SELECTED_SAVED_FILTER,
} from './SavedFilterSelectForm/constants';
import {
    getSelectedSavedFilter,
    getUserProjectFilterOptions,
    getUserProjectFilters,
} from './selectors';
import { validate } from './validate';
import { Button, ButtonGroup, DateTimePicker, InputText, Label, SearchSelect } from '../..';
import {
    createFilter,
    makeFilterDefault,
    showManageSavedFilterModal,
    updateFilter,
} from '../../../actions/auth';
import { showConfirmationSimpleModal } from '../../../actions/confirmation';
import { getDepartmentsSelectOptions } from '../../../containers/App/selectors';
import {
    getRetentionCodesOptions,
    getTemplateDocBuilderSelectOptions,
    getTemplatePurchaseSelectOptions,
} from '../../../containers/GovApp/selectors';
import { filterTypesDict } from '../../../../../shared_config/filters';
import {
    projectDateFilterTypesDict,
    projectFilterTypesDict,
    projectScopeFilterTypesDict,
    projectStatusFilterTypesDict,
} from '../../../../../shared_config/projects';
import { getProjectStatusOptions } from '../../../selectors/govApp';

const { DOC_BUILDER, PROJECT, INTAKE } = filterTypesDict;

const { AFTER, BEFORE, BETWEEN } = projectDateFilterTypesDict;

const {
    DEPARTMENT_ID,
    FINANCIAL_ID,
    IS_PAUSED,
    RETENTION_CODE_ID,
    STATUS,
    TEMPLATE_ID,
    TITLE,
    // Dates
    CONTRACTOR_SELECTED_DATE,
    CREATED_AT,
    POSTED_AT,
    POST_SCHEDULED_AT,
    PRE_PROPOSAL_DATE,
    PROPOSAL_DEADLINE,
    QA_DEADLINE,
    QA_RESPONSE_DEADLINE,
    RELEASE_PROJECT_DATE,
    REMOVED_FROM_PUBLIC_VIEW,
} = projectFilterTypesDict;

const { FILTERS } = fieldNamesDict;

const { MY_PROJECTS } = projectScopeFilterTypesDict;

const { ALL } = projectStatusFilterTypesDict;

const dateFieldsSet = new Set([
    CONTRACTOR_SELECTED_DATE,
    CREATED_AT,
    POSTED_AT,
    POST_SCHEDULED_AT,
    PRE_PROPOSAL_DATE,
    PROPOSAL_DEADLINE,
    QA_DEADLINE,
    QA_RESPONSE_DEADLINE,
    RELEASE_PROJECT_DATE,
]);

const formConfig = {
    form,
    validate,
};

const mapStateToProps = (state, props) => {
    return {
        departmentOptions: getDepartmentsSelectOptions(state),
        projectStatusOptions: getProjectStatusOptions(state, props).filter(
            (option) => option.value !== ALL
        ),
        retentionCodesOptions: getRetentionCodesOptions(state),
        selectedSavedFilter: getSelectedSavedFilter(state, props),
        showingManageSavedFilterModal: state.auth.get('showManageSavedFilterModal'),
        templateOptions: props.isDocBuilder
            ? getTemplateDocBuilderSelectOptions(state)
            : getTemplatePurchaseSelectOptions(state),
        userFilters: getUserProjectFilters(state, props),
        userFilterOptions: getUserProjectFilterOptions(state, props),
    };
};

const mapDispatchToProps = {
    changeExternalForm: changeFormAction,
    createFilter,
    makeFilterDefault,
    showConfirmationModal: showConfirmationSimpleModal,
    showManageSavedFilterModal,
    updateFilter,
};

// @connect
// @reduxForm
class ConnectedProjectListFiltersForm extends Component {
    static propTypes = {
        change: PropTypes.func.isRequired,
        changeExternalForm: PropTypes.func.isRequired,
        createFilter: PropTypes.func.isRequired,
        departmentOptions: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
            })
        ),
        retentionCodesOptions: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
            })
        ),
        dirty: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        initialSavedFilterId: PropTypes.number,
        isDocBuilder: PropTypes.bool,
        isIntake: PropTypes.bool,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            query: PropTypes.shape({
                scope: PropTypes.string,
                status: PropTypes.string,
            }).isRequired,
        }).isRequired,
        makeFilterDefault: PropTypes.func.isRequired,
        projectStatusOptions: PropTypes.array.isRequired,
        selectedSavedFilter: PropTypes.object,
        showingManageSavedFilterModal: PropTypes.bool,
        showManageSavedFilterModal: PropTypes.func.isRequired,
        storeLastFilter: PropTypes.func.isRequired,
        submitHandler: PropTypes.func.isRequired,
        templateOptions: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
            })
        ),
        updateFilter: PropTypes.func.isRequired,
        userFilterOptions: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
            })
        ).isRequired,
        userFilters: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
            })
        ).isRequired,
    };

    static defaultProps = {
        dirty: false,
        showingManageSavedFilterModal: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            showFilters: true,
        };
    }

    get resourceName() {
        const { isDocBuilder, isIntake } = this.props;

        if (isIntake) {
            return 'Requests';
        }
        if (isDocBuilder) {
            return 'Contract Documents';
        }
        return 'Projects';
    }

    get styles() {
        return require('./index.scss');
    }

    changeSelectedFilterFormValue = (value) => {
        const { changeExternalForm } = this.props;

        changeExternalForm(savedFilterSelectForm, SELECTED_SAVED_FILTER, value);
    };

    filterSelectStyles = {
        control: {
            height: 30,
            minHeight: 30,
        },
    };

    handleCreateSavedFilterSet = () => {
        const {
            isDocBuilder,
            isIntake,
            location: { query },
        } = this.props;

        const parsedQuery = qs.parse(query);

        const data = {
            filters: parsedQuery.filters,
            scope: parsedQuery.scope || MY_PROJECTS,
            status: parsedQuery.status || ALL,
        };

        const name = window.prompt('Please provide a name for the filter'); // eslint-disable-line no-alert

        if (!name) {
            return null;
        }

        let type = PROJECT;
        if (isIntake) {
            type = INTAKE;
        } else if (isDocBuilder) {
            type = DOC_BUILDER;
        }

        this.props.createFilter({ data, name, type }).then((newFilterOrError) => {
            if (!(newFilterOrError instanceof Error)) {
                this.changeSelectedFilterFormValue(newFilterOrError.id);
            }
        });
    };

    handleManageSavedFilterSet = () => {
        const { selectedSavedFilter } = this.props;

        this.props.showManageSavedFilterModal(selectedSavedFilter);
    };

    handlePinOrUnpinSavedFilterSet = () => {
        const { selectedSavedFilter } = this.props;

        if (selectedSavedFilter.isTypeDefault) {
            this.props.updateFilter(selectedSavedFilter.id, {
                ...selectedSavedFilter,
                isTypeDefault: false,
            });
        } else {
            this.props.makeFilterDefault(selectedSavedFilter.id);
        }
    };

    handleSavedFilterSetChange = (formData) => {
        const { userFilters } = this.props;

        const selectedSavedFilter = formData[SELECTED_SAVED_FILTER];

        // Clear filter search if no selected option
        let data = { filters: [] };
        if (selectedSavedFilter) {
            const userFilter = userFilters.find((filter) => filter.id === selectedSavedFilter);
            data = userFilter && { ...userFilter.data, filterId: userFilter.id };
        }

        this.setState({ showFilters: false });

        this.submitHandler(data);
    };

    handleSelectedSavedFilterSetDelete = () => {
        this.handleSavedFilterSetChange({ [SELECTED_SAVED_FILTER]: null });
    };

    submitHandler = (data) => {
        const { dirty, submitHandler } = this.props;

        submitHandler(data, dirty);
    };

    renderDateFilterFields = (fieldName, filterData) => {
        switch (filterData.dateFilterType) {
            case AFTER:
            case BEFORE:
                return <Field component={DateTimePicker} horizontal name={`${fieldName}.value`} />;
            case BETWEEN:
                return (
                    <div className="row">
                        <div className={`col-xs-6 ${this.styles.betweenStart}`}>
                            <Field
                                component={DateTimePicker}
                                horizontal
                                name={`${fieldName}.value[0]`}
                            />
                        </div>
                        <div className={`col-xs-6 ${this.styles.betweenEnd}`}>
                            <Field
                                component={DateTimePicker}
                                horizontal
                                name={`${fieldName}.value[1]`}
                            />
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    renderFilterValueField = (fieldName, filterData) => {
        const {
            change,
            departmentOptions,
            projectStatusOptions,
            retentionCodesOptions,
            templateOptions,
        } = this.props;

        const now = moment();

        switch (filterData.type) {
            case DEPARTMENT_ID:
                return (
                    <Field
                        blurInputOnSelect
                        component={SearchSelect}
                        name={`${fieldName}.value`}
                        options={departmentOptions}
                        styles={this.filterSelectStyles}
                    />
                );
            case FINANCIAL_ID:
                return (
                    <Field
                        component={InputText}
                        hasFeedback={false}
                        name={`${fieldName}.value`}
                        placeholder="Enter Text"
                        type="text"
                    />
                );
            case RETENTION_CODE_ID:
                return (
                    <Field
                        blurInputOnSelect
                        component={SearchSelect}
                        name={`${fieldName}.value`}
                        options={retentionCodesOptions}
                        styles={this.filterSelectStyles}
                    />
                );
            case REMOVED_FROM_PUBLIC_VIEW:
            case IS_PAUSED:
                return (
                    <Field
                        blurInputOnSelect
                        component={SearchSelect}
                        isSearchable={false}
                        name={`${fieldName}.value`}
                        options={isPausedOptions}
                        styles={this.filterSelectStyles}
                    />
                );
            case STATUS:
                return (
                    <Field
                        blurInputOnSelect
                        component={SearchSelect}
                        name={`${fieldName}.value`}
                        options={projectStatusOptions}
                        styles={this.filterSelectStyles}
                    />
                );
            case TITLE:
                return (
                    <Field
                        component={InputText}
                        hasFeedback={false}
                        name={`${fieldName}.value`}
                        placeholder="Enter Text"
                        type="text"
                    />
                );
            case TEMPLATE_ID:
                return (
                    <Field
                        blurInputOnSelect
                        component={SearchSelect}
                        name={`${fieldName}.value`}
                        options={templateOptions}
                        styles={this.filterSelectStyles}
                    />
                );

            // Dates
            case CONTRACTOR_SELECTED_DATE:
            case CREATED_AT:
            case POSTED_AT:
            case POST_SCHEDULED_AT:
            case PRE_PROPOSAL_DATE:
            case PROPOSAL_DEADLINE:
            case QA_DEADLINE:
            case QA_RESPONSE_DEADLINE:
            case RELEASE_PROJECT_DATE:
                return (
                    <div>
                        <Field
                            blurInputOnSelect
                            component={SearchSelect}
                            formClassName={this.styles.filterTypeSelect}
                            name={`${fieldName}.dateFilterType`}
                            onChange={(event, value) => {
                                if (value === BETWEEN) {
                                    change(`${fieldName}.value`, [
                                        now.toDate(),
                                        now.clone().add(1, 'year').toDate(),
                                    ]);
                                } else {
                                    change(`${fieldName}.value`, now.toDate());
                                }
                            }}
                            options={dateFilterOptions}
                            placeholder="Date Filter Type"
                            styles={this.filterSelectStyles}
                        />
                        {this.renderDateFilterFields(fieldName, filterData)}
                    </div>
                );
            default:
                return null;
        }
    };

    renderFilter = (fieldName, filterData, fields, index) => {
        const { change, handleSubmit, isDocBuilder, isIntake } = this.props;

        return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="pull-right">
                        <Button
                            aria-label="Remove Filter"
                            bsStyle="link"
                            className={this.styles.removeFilterButton}
                            onClick={() => {
                                fields.remove(index);
                                // Submit form with new data after Redux state has updated
                                setTimeout(handleSubmit(this.submitHandler), 0, fields.getAll());
                            }}
                            qaTag="connectedProjectListFiltersForm-removeFilter"
                        >
                            <i className="fa fa-times fa-lg text-danger" />
                        </Button>
                    </div>
                    <Field
                        blurInputOnSelect
                        component={SearchSelect}
                        formClassName={this.styles.filterTypeSelect}
                        label="Filter Type"
                        name={`${fieldName}.type`}
                        onChange={(newFieldName) => {
                            // Keep the dates values when the filter changes between date types
                            if (!dateFieldsSet.has(newFieldName)) {
                                change(`${fieldName}.dateFilterType`, null);
                                change(`${fieldName}.value`, null);
                            }
                        }}
                        options={typeOptions({ isDocBuilder, isIntake })}
                        placeholder="Select Filter Type"
                        styles={this.filterSelectStyles}
                    />
                </div>
                <div className="col-xs-12">
                    {this.renderFilterValueField(fieldName, filterData)}
                </div>
            </div>
        );
    };

    renderFilters = ({ fields }) => {
        const { initialSavedFilterId, selectedSavedFilter, userFilterOptions } = this.props;

        const initialValues = initialSavedFilterId
            ? { [SELECTED_SAVED_FILTER]: initialSavedFilterId }
            : undefined;

        return (
            <div className={this.styles.filtersFormContainers}>
                {userFilterOptions.length > 0 && (
                    <div className="row">
                        <div className="col-xs-12">
                            <Label
                                className={this.styles.filterSelectLabel}
                                label="Saved Filters"
                            />
                            {selectedSavedFilter && (
                                <div className={this.styles.savedFilterControl}>
                                    <Button
                                        bsSize="sm"
                                        bsStyle="link"
                                        onClick={this.handlePinOrUnpinSavedFilterSet}
                                        qaTag="connectedProjectListFiltersForm-filterToggle"
                                        tooltip={`Click to ${
                                            selectedSavedFilter.isTypeDefault ? 'Unpin' : 'Pin'
                                        } this Filter`}
                                        tooltipPlacement="top"
                                    >
                                        <i
                                            className={`fa fa-lg ${
                                                selectedSavedFilter.isTypeDefault
                                                    ? 'fa-star'
                                                    : 'fa-star-o'
                                            }`}
                                        />
                                    </Button>
                                    <Button
                                        bsSize="sm"
                                        bsStyle="link"
                                        onClick={this.handleManageSavedFilterSet}
                                        qaTag="dashboard-editSavedFilter"
                                    >
                                        <i className="fa fa-pencil" /> Edit
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div className="col-xs-12">
                            <SavedFilterSelectForm
                                formClassName={this.styles.savedFilterSearchSelect}
                                initialValues={initialValues}
                                onChange={this.handleSavedFilterSetChange}
                                options={userFilterOptions}
                            />
                        </div>
                    </div>
                )}
                <div>
                    <Button
                        bsStyle="link"
                        className={`${this.styles.smallButtonPadding} add-filter`}
                        onClick={() => {
                            this.setState({ showFilters: true });
                            fields.push({
                                localCreatingUUID: UUIDv4(),
                                type: undefined,
                                value: undefined,
                            });
                        }}
                        qaTag="dashboard-addFilter"
                    >
                        <i className="fa fa-filter" />
                        &nbsp;Add Filter
                    </Button>
                    <Button
                        bsStyle="link"
                        className={`pull-right ${this.styles.smallButtonPadding}`}
                        onClick={this.handleCreateSavedFilterSet}
                        qaTag="dashboard-saveFilter"
                    >
                        Save Filter
                    </Button>
                </div>
                {fields.length > 0 && this.state.showFilters && (
                    <div className={this.styles.filtersList}>
                        {fields.map((fieldName, index) => {
                            const filterData = fields.get(index);

                            return (
                                <div key={filterData.localCreatingUUID}>
                                    {this.renderFilter(fieldName, filterData, fields, index)}
                                </div>
                            );
                        })}
                        <div className="text-right">
                            <Button
                                bsSize="sm"
                                bsStyle="info"
                                qaTag="dashboard-filterSearch"
                                type="submit"
                            >
                                <i className="fa fa-search" /> Search
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    renderScopeFilterButtons() {
        const {
            location: { pathname, query },
        } = this.props;

        const filterButtons = scopeFilterButtons(this.resourceName).map((button) => {
            const isActive = query.scope ? button.scope === query.scope : !button.scope;

            const queryString = qs.stringify({
                ...query,
                scope: button.scope,
            });

            return (
                <Button
                    active={isActive}
                    className={classnames(`filter-${button.key}`, this.styles.filterButton)}
                    key={button.key}
                    onClick={() => {
                        this.props.storeLastFilter({ scope: button.scope });
                        this.changeSelectedFilterFormValue(null);
                    }}
                    qaTag={`projectListFilters-filter${button.text}`}
                    to={{
                        pathname,
                        search: `?${queryString}`,
                    }}
                >
                    <i className={`fa fa-fw fa-${button.icon}`} /> {button.text}
                </Button>
            );
        });

        return (
            <div className={this.styles.filterButtonGroup}>
                <strong>Find {this.resourceName}</strong>
                <ButtonGroup block vertical>
                    {filterButtons}
                </ButtonGroup>
            </div>
        );
    }

    render() {
        const { handleSubmit, showingManageSavedFilterModal } = this.props;

        return (
            <>
                <form
                    className={this.styles.formContainer}
                    onSubmit={handleSubmit(this.submitHandler)}
                >
                    {this.renderScopeFilterButtons()}
                    <FieldArray
                        component={this.renderFilters}
                        name={FILTERS}
                        rerenderOnEveryChange
                    />
                </form>
                {showingManageSavedFilterModal && (
                    <ManageSavedFilterModal onDelete={this.handleSelectedSavedFilterSetDelete} />
                )}
            </>
        );
    }
}

export const ProjectListFiltersForm = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedProjectListFiltersForm);
