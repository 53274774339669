import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { LoadingError, LoadingSpinner } from '../../../components';
import { formatS3SignedURLForIframePreview } from '../../../helpers';

export class ProjectExportModalBody extends PureComponent {
    static propTypes = {
        error: PropTypes.string,
        exportProject: PropTypes.func.isRequired,
        exportProjectDocument: PropTypes.func.isRequired,
        filename: PropTypes.string,
        googleUrl: PropTypes.string,
        isPreview: PropTypes.bool,
        loaded: PropTypes.bool,
        loadedDocument: PropTypes.bool,
        loading: PropTypes.bool,
        loadingDocument: PropTypes.bool,
        pdfUrl: PropTypes.string,
        project: PropTypes.shape({
            government: PropTypes.shape({
                usesGoogleDrive: PropTypes.bool.isRequired,
            }).isRequired,
        }).isRequired,
        setExportData: PropTypes.func.isRequired,
        wordUrl: PropTypes.string,
        zipUrl: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            isGoogleDrive: false,
            isPdf: false,
        };
    }

    componentDidMount() {
        const { exportProjectDocument, isPreview, loading, loadingDocument } = this.props;

        if (isPreview && !loading && !loadingDocument) {
            exportProjectDocument();
        }
    }

    componentDidUpdate() {
        const {
            filename,
            googleUrl,
            project: {
                government: { usesGoogleDrive },
            },
        } = this.props;

        const { isGoogleDrive } = this.state;

        if (usesGoogleDrive && googleUrl && isGoogleDrive) {
            gapi.savetodrive.render('save-button', {
                src: googleUrl,
                filename,
                sitename: 'OpenGov Procurement',
            });
        }
    }

    get styles() {
        return require('./ProjectExportModalBody.scss');
    }

    exportDocument = ({ isGoogleDrive, isPdf } = {}) => {
        const { exportProjectDocument, setExportData } = this.props;
        setExportData({ isGoogleDrive, isPdf });
        exportProjectDocument();
    };

    selectGoogleDrive = () => {
        this.setState({ isGoogleDrive: true });
        this.exportDocument({ isGoogleDrive: true });
    };

    selectPdf = () => {
        this.setState({ isPdf: true });
        this.exportDocument({ isPdf: true });
    };

    renderIntro() {
        const {
            exportProject,
            project: {
                government: { usesGoogleDrive },
            },
        } = this.props;

        return (
            <>
                <div className={`row ${this.styles.introRow}`}>
                    <div className="col-xs-6">
                        <span className="pseudoLink" onClick={this.exportDocument}>
                            <i className={`fa fa-file-word-o fa-4x ${this.styles.introFile}`} />
                            <div className={this.styles.downloadText}>Word Document Only</div>
                        </span>
                    </div>
                    <div className="col-xs-6">
                        <span className="pseudoLink" onClick={this.selectPdf}>
                            <i className={`fa fa-file-pdf-o fa-4x ${this.styles.introFile}`} />
                            <div className={this.styles.downloadText}>PDF Document Only</div>
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-6">
                        <span className="pseudoLink" onClick={exportProject}>
                            <i className={`fa fa-folder-open fa-4x ${this.styles.introFile}`} />
                            <div className={this.styles.downloadText}>
                                Full Package With Attachments
                            </div>
                            <div className={this.styles.disclaimer}>
                                *May take a few moments to download
                            </div>
                        </span>
                    </div>
                    {usesGoogleDrive && (
                        <div className="col-xs-6">
                            <span className="pseudoLink" onClick={this.selectGoogleDrive}>
                                <i
                                    className={`fa fa-cloud-upload fa-4x ${this.styles.introFile}`}
                                />
                                <div className={this.styles.downloadText}>Save to Google Drive</div>
                            </span>
                        </div>
                    )}
                </div>
            </>
        );
    }

    renderPreview() {
        const { googleUrl } = this.props;

        return (
            <div>
                <iframe
                    className={this.styles.preview}
                    frameBorder="0"
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${formatS3SignedURLForIframePreview(
                        googleUrl
                    )}`}
                    title="Document Preview Embed"
                />
            </div>
        );
    }

    renderGoogleDriveDocument() {
        return (
            <div className={this.styles.googleSave}>
                <div id="save-button" />
                <div className={this.styles.googleText}>Click to Save Document in Google Drive</div>
                <div className={this.styles.disclaimer}>
                    *Google file preview may display inaccurately. Select open in Google Docs to
                    display properly
                </div>
            </div>
        );
    }

    renderWordDocument() {
        const { filename, wordUrl } = this.props;

        return (
            <div>
                <a download={filename} href={wordUrl}>
                    <i className="fa fa-file-word-o fa-5x" />
                    <div className={this.styles.postDownload}>Click To Download Word Document</div>
                </a>
            </div>
        );
    }

    renderPackage() {
        const { zipUrl } = this.props;

        return (
            <div>
                <a href={zipUrl}>
                    <i className="fa fa-folder-open fa-5x" />
                    <div className={this.styles.postDownload}>Click To Download Package</div>
                </a>
            </div>
        );
    }

    renderPdf() {
        const { filename, pdfUrl } = this.props;

        return (
            <div>
                <a download={filename} href={pdfUrl}>
                    <i className="fa fa-file-pdf-o fa-5x" />
                    <div className={this.styles.postDownload}>Click To Download PDF Document</div>
                </a>
            </div>
        );
    }

    renderContent() {
        const { googleUrl, loaded, loadedDocument, pdfUrl } = this.props;

        if (loadedDocument) {
            if (pdfUrl) {
                return this.renderPdf();
            }
            if (googleUrl) {
                return this.renderGoogleDriveDocument();
            }
            return this.renderWordDocument();
        }

        if (loaded) {
            return this.renderPackage();
        }

        return this.renderIntro();
    }

    render() {
        const { error, googleUrl, isPreview, loading, loadingDocument } = this.props;

        const { isPdf } = this.state;

        if (error) {
            return <LoadingError error={error} />;
        }

        if (loading || loadingDocument || (isPreview && !googleUrl)) {
            return (
                <LoadingSpinner
                    noPadding
                    text={loading || isPdf ? 'This may take a few moments...' : undefined}
                />
            );
        }

        if (isPreview) {
            return this.renderPreview();
        }

        return <div className={this.styles.exportBody}>{this.renderContent()}</div>;
    }
}
