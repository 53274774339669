import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { sectionTypeNames } from '../../../../../../../shared_config/sections';
import { subsectionTypeNames } from '../../../../../../../shared_config/subsections';
import { getSDv2WritingSections } from '../../selectors';
import { SectionHeaderView } from '../../../../../components/SDv2';
import { CommentIcon } from '../../..';
import { shouldShowComments } from '../../../../../actions/govComments';
import { ProjectCreateV2NavContext, ProjectCreateV2FunctionsContext } from '../context';
import { SectionHeaderEditSection } from './ManageSections';

const { BODY } = subsectionTypeNames;

export const ProjectCreateV2DocumentEditorSectionHeader = () => {
    const { activeSectionId, setActiveSectionId } = useContext(ProjectCreateV2NavContext);
    const { project, showHelpModal } = useContext(ProjectCreateV2FunctionsContext);
    const [editing, setEditing] = useState(false);
    const sections = useSelector(getSDv2WritingSections);
    const showComments = useSelector(shouldShowComments);
    const { useManualNumbering } = project;

    const afterCancel = () => {
        setEditing(false);
    };

    const afterSave = (editedSectionNewIndex = null) => {
        setEditing(false);

        if (editedSectionNewIndex !== null && editedSectionNewIndex !== activeSectionId) {
            setActiveSectionId(editedSectionNewIndex);
        }
    };

    if (activeSectionId === null || !sections) {
        return null;
    }

    const selectedSection = sections[activeSectionId];

    if (!selectedSection) {
        return null;
    }

    if (!editing) {
        return (
            <SectionHeaderView
                commentsComponent={
                    <CommentIcon
                        iconLeft
                        projectSection={selectedSection}
                        show={showComments}
                        subsectionType={BODY}
                        useOpenGovStyle
                    />
                }
                onEditSectionClick={() => setEditing(true)}
                onInstructionsClick={
                    selectedSection?.section_type !== sectionTypeNames.DIVIDER
                        ? showHelpModal
                        : null
                }
                section={selectedSection}
                showAutomationButton={false}
                useManualNumbering={useManualNumbering}
            />
        );
    }

    return (
        <SectionHeaderEditSection
            afterCancel={afterCancel}
            afterSave={afterSave}
            index={activeSectionId}
        />
    );
};

ProjectCreateV2DocumentEditorSectionHeader.propTypes = {};
