import React from 'react';
import { Field } from 'redux-form';

import { TIMELINES } from '../../../../../../containers/GovApp/constants';
import { InputText } from '../../../../..';
import { customTimelineFieldsDict } from '../../../../../../../../shared_config/timelines';
import { limitTextLength } from '../../../../../../Forms/normalizers';
import { PROP_TYPES } from './constants';

const { TEXT_DATE } = customTimelineFieldsDict;

export const TextDateInput = (props) => {
    const { disabled, showValidation, timeline } = props;

    if (!timeline.hasTextDate) {
        return null;
    }

    return (
        <Field
            component={InputText}
            disabled={disabled}
            hasFeedback={false}
            name={`${TIMELINES}.${timeline.index}.${TEXT_DATE}`}
            normalize={limitTextLength(250)}
            qaTag={`timelineForm-${timeline.title}TextDate`}
            showValidation={showValidation}
            type="text"
            useOpenGovStyle
        />
    );
};
TextDateInput.propTypes = PROP_TYPES;
