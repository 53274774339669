import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CDSButton } from '../../../../../components';
import { getQuestionLogicMap } from '../../TemplateEdit/selectors';
import { showQuestionLogicModal } from '../../../../../actions/templatesAdmin';

export const SDv2QuestionLogicIcon = ({ linkable, linkableItem, projectSection }) => {
    const dispatch = useDispatch();
    const questionLogicMap = useSelector(getQuestionLogicMap);

    const hasQuestionLogic =
        questionLogicMap[linkable] &&
        questionLogicMap[linkable][linkableItem.id || linkableItem.sharedId];

    const onClick = (e) => {
        e.stopPropagation();

        if (hasQuestionLogic) {
            return dispatch(showQuestionLogicModal(hasQuestionLogic, hasQuestionLogic.editIndex));
        }

        return dispatch(
            showQuestionLogicModal(
                {
                    linkable,
                    linkableItem,
                    linkable_id: linkableItem.id || linkableItem.sharedId,
                    projectSection,
                },
                null,
                true
            )
        );
    };

    return (
        <CDSButton
            noPadding
            onClick={onClick}
            qaTag="automations-toggle"
            size="small"
            variant="text"
        >
            {!hasQuestionLogic && (
                <>
                    <i className="fa fa-magic fa-fw" /> Add Automation
                </>
            )}
            {hasQuestionLogic && (
                <>
                    <i className="fa fa-pencil fa-fw" /> Edit Automation
                </>
            )}
        </CDSButton>
    );
};

SDv2QuestionLogicIcon.propTypes = {
    linkable: PropTypes.string.isRequired,
    linkableItem: PropTypes.shape({
        id: PropTypes.number,
        sharedId: PropTypes.string.isRequired,
    }).isRequired,
    projectSection: PropTypes.object.isRequired,
};
