import { last } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';
import { Box } from '@og-pro/ui';

import { AnalyticsCharts, AnalyticsFiltersForm } from './components';
import { fieldNames } from './components/AnalyticsFiltersForm/constants';
import { timeRangeOptions } from './constants';
import {
    getCycleTimeSeriesData,
    getDepartmentAnalytics,
    getHistoricContractStackedAnalytics,
    getHistoricPurchaseStackedAnalytics,
    getTemplateCounts,
    getTimelineAnalytics,
    getTimelinePercentageAnalytics,
    getWorkloadWeightsJS,
    hasProjectsJS,
} from './selectors';
import {
    getTemplateDocBuilderSelectOptions,
    getNonSpecialTemplatePurchaseSelectOptions,
} from '../selectors';
import {
    getActiveUsersSelectOptions,
    getDepartmentsSelectOptions,
    getUserJS,
    isContractAdminUser,
    isGlobalEditorUser,
    isInitialClientLoaded,
    isSystemAdminUser,
} from '../../selectors';
import { loadGovAnalytics } from '../../../actions/analytics';
import { LoadingSpinner, LoadingError, PageTitle, Main } from '../../../components';
import { NoProjectsFound } from '../../../components/GovApp';
import { projectTypesPathsDict, projectTypesDict } from '../../../../../shared_config/projects';

const {
    DEPARTMENT_IDS,
    END_DATE,
    IS_CUSTOM_DATE,
    PROCUREMENT_CONTACT_IDS,
    START_DATE,
    TEMPLATE_IDS,
    TIME_RANGE,
} = fieldNames;

const TODAY = new Date();
TODAY.setHours(23, 59, 0, 0);

const MONTH_AGO = new Date(TODAY.getTime());
MONTH_AGO.setMonth(TODAY.getMonth() - 1);
MONTH_AGO.setHours(0, 0, 0, 0);

const DEFAULT_TIME_RANGE = last(timeRangeOptions).value;
const { PROJECTS, CONTRACTS } = projectTypesPathsDict;
const { PURCHASE, CONTRACT } = projectTypesDict;

const mapStateToProps = (state, props) => {
    const isProjectsPath = props.params.projectTypePath === PROJECTS;
    return {
        cycleTimeSeriesData: getCycleTimeSeriesData(isProjectsPath)(state),
        departmentData: getDepartmentAnalytics(state),
        departmentOptions: getDepartmentsSelectOptions(state),
        hasProjects: hasProjectsJS(state),
        historicData: isProjectsPath
            ? getHistoricPurchaseStackedAnalytics(state)
            : getHistoricContractStackedAnalytics(state),
        isAdminUser: isProjectsPath
            ? isSystemAdminUser(state) || isGlobalEditorUser(state)
            : isSystemAdminUser(state) || isContractAdminUser(state),
        isClientLoaded: isInitialClientLoaded(state),
        loadError: state.analytics.get('loadAggregateError'),
        loading: state.analytics.get('loadingAggregate'),
        templateData: getTemplateCounts(state),
        templateOptions: isProjectsPath
            ? getNonSpecialTemplatePurchaseSelectOptions(state)
            : getTemplateDocBuilderSelectOptions(state),
        timelineData: getTimelineAnalytics(state),
        timelinePercentageData: getTimelinePercentageAnalytics(state),
        workloadWeightsData: getWorkloadWeightsJS(
            props.params.projectTypePath,
            props.router
        )(state),
        user: getUserJS(state),
        userOptions: getActiveUsersSelectOptions(state),
    };
};

const mapDispatchToProps = {
    loadGovAnalytics,
};

// @connect
class ConnectedAggregateAnalytics extends Component {
    static propTypes = {
        cycleTimeSeriesData: PropTypes.array.isRequired,
        departmentData: PropTypes.array.isRequired,
        departmentOptions: PropTypes.array.isRequired,
        hasProjects: PropTypes.bool,
        historicData: PropTypes.object.isRequired,
        isAdminUser: PropTypes.bool,
        isClientLoaded: PropTypes.bool,
        loadError: PropTypes.string,
        loadGovAnalytics: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        templateData: PropTypes.object.isRequired,
        templateOptions: PropTypes.array.isRequired,
        timelineData: PropTypes.array.isRequired,
        timelinePercentageData: PropTypes.array.isRequired,
        workloadWeightsData: PropTypes.object.isRequired,
        params: PropTypes.shape({
            projectTypePath: PropTypes.oneOf([PROJECTS, CONTRACTS]),
        }).isRequired,
        user: PropTypes.shape({
            department: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }),
        }).isRequired,
        userOptions: PropTypes.array.isRequired,
    };

    static defaultProps = {
        hasProjects: false,
        isClientLoaded: false,
        loadError: undefined,
        loading: false,
    };

    getInitialFilters = () => {
        const { params, templateOptions } = this.props;

        return {
            [TEMPLATE_IDS]: templateOptions.map((template) => template.value),
            [TIME_RANGE]: DEFAULT_TIME_RANGE,
            [PROCUREMENT_CONTACT_IDS]: [],
            view: params.projectTypePath,
        };
    };

    componentDidMount() {
        return this.props.loadGovAnalytics(this.getInitialFilters());
    }

    componentDidUpdate(prevProps) {
        const { templateOptions, params } = this.props;
        const { templateOptions: prevTemplateOptions } = prevProps;

        if (
            templateOptions.length !== prevTemplateOptions.length ||
            params.projectTypePath !== prevProps.params.projectTypePath
        ) {
            this.props.loadGovAnalytics(this.getInitialFilters());
        }
    }

    handleSubmit = (data) => {
        const { params } = this.props;

        const timeData = data[IS_CUSTOM_DATE]
            ? {
                  [START_DATE]: data[START_DATE].toISOString(),
                  [END_DATE]: data[END_DATE].toISOString(),
              }
            : { [TIME_RANGE]: data[TIME_RANGE] };

        this.props.loadGovAnalytics({
            [TEMPLATE_IDS]: data[TEMPLATE_IDS].map((template) => template.value),
            [PROCUREMENT_CONTACT_IDS]: data[PROCUREMENT_CONTACT_IDS]
                ? data[PROCUREMENT_CONTACT_IDS].map((user) => user.value)
                : [],
            [DEPARTMENT_IDS]: data[DEPARTMENT_IDS]
                ? data[DEPARTMENT_IDS].map((dept) => dept.value)
                : [],
            view: params.projectTypePath,
            ...timeData,
        });
    };

    renderAnalytics() {
        const {
            cycleTimeSeriesData,
            departmentData,
            hasProjects,
            historicData,
            loadError,
            loading,
            templateData,
            timelineData,
            timelinePercentageData,
            workloadWeightsData,
            params,
        } = this.props;

        if (loading) return <LoadingSpinner />;
        if (loadError) return <LoadingError error={loadError} />;
        if (!hasProjects) return <NoProjectsFound />;

        return (
            <AnalyticsCharts
                cycleTimeSeriesData={cycleTimeSeriesData}
                departmentData={departmentData}
                historicData={historicData}
                projectType={params.projectTypePath === PROJECTS ? PURCHASE : CONTRACT}
                templateData={templateData}
                timelineData={timelineData}
                timelinePercentageData={timelinePercentageData}
                workloadWeightsData={workloadWeightsData}
            />
        );
    }

    render() {
        const {
            departmentOptions,
            isAdminUser,
            isClientLoaded,
            loading,
            templateOptions,
            params,
            userOptions,
            user,
        } = this.props;

        /**
         * There appears to be a bug in redux form when loading objects from the server. The dates
         * get stored as strings, which causes problems in the form. The suggested approach is to
         * use normalizers, but they do not appear to be run on the initial setting of values, only
         * on changes. For now do not render the component on the server.
         */
        if (!isClientLoaded) {
            return null;
        }

        return (
            <Main>
                <PageTitle title="Analytics" />
                <h1 className="visually-hidden">Analytics</h1>
                {!isAdminUser && (
                    <Box component="h4" mb={2}>
                        Viewing Analytics by Department: {user.department.name}
                    </Box>
                )}
                <AnalyticsFiltersForm
                    departmentOptions={departmentOptions}
                    disabled={loading}
                    initialValues={{
                        [END_DATE]: TODAY,
                        [START_DATE]: MONTH_AGO,
                        [TEMPLATE_IDS]: templateOptions,
                        [TIME_RANGE]: DEFAULT_TIME_RANGE,
                    }}
                    isAdminUser={isAdminUser}
                    onSubmit={this.handleSubmit}
                    projectType={params.projectTypePath === PROJECTS ? PURCHASE : CONTRACT}
                    templateOptions={templateOptions}
                    timeRangeOptions={timeRangeOptions}
                    todayDate={TODAY}
                    userOptions={userOptions}
                />
                {this.renderAnalytics()}
            </Main>
        );
    }
}

export const AggregateAnalytics = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedAggregateAnalytics);
