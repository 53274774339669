import { countryCode, state, zipCode } from '.';
import { contactFieldNames, mappedUserFields } from '../../../../shared_config/contacts';
import { CANADA_COUNTRY_CODE, UNITED_STATES_COUNTRY_CODE } from '../../../../shared_config/global';

const { ADDRESS_1, ADDRESS_2, CITY, COUNTRY_CODE, STATE, ZIP_CODE } = contactFieldNames;

export function address(values, opts = { fieldNames: mappedUserFields, optional: false }) {
    const { fieldNames, optional } = opts;

    const errors = {};

    const address1Field = fieldNames[ADDRESS_1];
    const address2Field = fieldNames[ADDRESS_2];
    const cityField = fieldNames[CITY];
    const countryCodeField = fieldNames[COUNTRY_CODE];
    const stateField = fieldNames[STATE];
    const zipCodeField = fieldNames[ZIP_CODE];

    const isInternational = values[countryCodeField] !== UNITED_STATES_COUNTRY_CODE;
    const isCanada = values[countryCodeField] === CANADA_COUNTRY_CODE;

    if (!optional && !values[address1Field]) {
        errors[address1Field] = 'Address is required.';
    } else if (values[address1Field] && values[address1Field].length > 256) {
        errors[address1Field] = 'Address is too long.';
    }

    if (values[address2Field] && values[address2Field].length > 256) {
        errors[address2Field] = 'Address is too long.';
    }

    if (!optional && !values[cityField]) {
        errors[cityField] = 'City is required.';
    } else if (values[cityField] && values[cityField].length > 64) {
        errors[cityField] = 'City is too long.';
    }

    return {
        ...errors,
        ...countryCode(values, countryCodeField, { optional }),
        ...state(values, stateField, { isCanada, isInternational, optional }),
        ...zipCode(values, zipCodeField, { isInternational, optional }),
    };
}
