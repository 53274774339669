import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import { form } from './constants';
import {
    getInitialFormValues,
    getModalFormDataJS,
    getConditionalLogicParentQuestionOptions,
    getParentQuestionValueSelectOptions,
    getShowConditionalLogicModal,
    hasMultiSelectOperator,
} from './selectors';
import { validate } from './validate';
import {
    questionLogicConfirmExitMessage as confirmExitMessage,
    operatorSelectOptions,
    booleanOperatorSelectOptions,
} from '../constants';
import { TemplateQuestionAddButton } from '../TemplateQuestionAddButton';
import { hideConditionalLogicModal } from '../../../actions/app';
import { Button, SearchSelect } from '../../../components';
import {
    questionLogicFieldNames,
    questionLogicOperatorNames,
} from '../../../../../shared_config/questionLogics';
import { YES_NO } from '../../../../../shared_config/questionnaires';

const { OPERATOR, VALUE, LOGICABLE_ID } = questionLogicFieldNames;

const { EQUAL, INCLUDE, NOT_EQUAL, NOT_INCLUDE } = questionLogicOperatorNames;

const mapStateToProps = (state, props) => {
    const initialValues = getInitialFormValues(state);
    const { onAddQuestion, onAddTemplateQuestion, parentQuestion } = getModalFormDataJS(state);

    return {
        hasConditionalMultiSelectValue: hasMultiSelectOperator(state),
        initialValues,
        onAddQuestion,
        onAddTemplateQuestion,
        parentQuestion,
        parentQuestionSelectOption: getConditionalLogicParentQuestionOptions(state, props),
        parentQuestionValueSelectOptions: getParentQuestionValueSelectOptions(state, props),
    };
};

const mapDispatchToProps = {
    hideModal: hideConditionalLogicModal,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedConditionalLogicModal extends Component {
    static propTypes = {
        change: PropTypes.func.isRequired,
        dirty: PropTypes.bool.isRequired,
        hasConditionalMultiSelectValue: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        isDirty: PropTypes.bool,
        onAddQuestion: PropTypes.func.isRequired,
        updateTemplate: PropTypes.func,
        onAddTemplateQuestion: PropTypes.func,
        upfrontQuestions: PropTypes.array,
        parentQuestion: PropTypes.shape({
            type: PropTypes.string,
        }),
        parentQuestionSelectOption: PropTypes.array.isRequired,
        parentQuestionValueSelectOptions: PropTypes.array.isRequired,
    };

    static defaultProps = {
        upfrontQuestions: [],
    };

    get styles() {
        return require('./index.scss');
    }

    hideModal = (options = {}) => {
        const { dirty, hideModal } = this.props;
        const { force } = options;

        // Confirm exit if form is dirty and not forcing hide
        // eslint-disable-next-line no-alert
        if (force || !dirty || window.confirm(confirmExitMessage)) {
            hideModal();
        }
    };

    operatorChangeHandler = (e, newValue, prevValue) => {
        const { change } = this.props;

        const VALUE_TYPE = {
            [EQUAL]: 'single',
            [NOT_EQUAL]: 'single',
            [INCLUDE]: 'multi',
            [NOT_INCLUDE]: 'multi',
        };

        if (VALUE_TYPE[newValue] !== VALUE_TYPE[prevValue]) {
            change(VALUE, null);
        }
    };

    submitHandler = (data) => {
        const { onAddQuestion } = this.props;

        onAddQuestion(data);

        this.hideModal({ force: true });
    };

    templateQuestionSubmitHandler = (data, templateQuestionId) => {
        const { onAddTemplateQuestion } = this.props;

        onAddTemplateQuestion(data, templateQuestionId);

        this.hideModal({ force: true });
    };

    render() {
        const {
            handleSubmit,
            hasConditionalMultiSelectValue,
            onAddTemplateQuestion,
            isDirty,
            updateTemplate,
            upfrontQuestions,
            parentQuestion,
            parentQuestionSelectOption,
            parentQuestionValueSelectOptions,
        } = this.props;

        const operatorOptions =
            parentQuestion.type === YES_NO ? booleanOperatorSelectOptions : operatorSelectOptions;

        return (
            <Modal bsSize="lg" onHide={this.hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Add Conditional Logic</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-xs-12">
                            <label>When the following condition is met</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-4">
                            <Field
                                component={SearchSelect}
                                disabled
                                name={LOGICABLE_ID}
                                options={parentQuestionSelectOption}
                                placeholder="Configuration Question"
                            />
                        </div>
                        <div className="col-xs-4">
                            <Field
                                aria-label="Operator"
                                component={SearchSelect}
                                name={OPERATOR}
                                onChange={this.operatorChangeHandler}
                                options={operatorOptions}
                                placeholder="Operator"
                            />
                        </div>
                        <div className="col-xs-4">
                            <Field
                                aria-label="Value"
                                component={SearchSelect}
                                isMulti={hasConditionalMultiSelectValue}
                                isMultiSimpleValue={hasConditionalMultiSelectValue}
                                name={VALUE}
                                options={parentQuestionValueSelectOptions}
                                placeholder="Value"
                                useNullWhenUndefined
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 text-right">
                            {!!onAddTemplateQuestion && (
                                <div className={this.styles.templateQuestionAddButtonContainer}>
                                    <TemplateQuestionAddButton
                                        addQuestion={(templateQuestionId) =>
                                            handleSubmit((formData) => {
                                                this.templateQuestionSubmitHandler(
                                                    formData,
                                                    templateQuestionId
                                                );
                                            })()
                                        }
                                        isDirty={isDirty}
                                        updateTemplate={updateTemplate}
                                        upfrontQuestionFormValues={upfrontQuestions}
                                    />
                                </div>
                            )}
                            &nbsp;&nbsp;
                            <Button bsStyle="primary" onClick={handleSubmit(this.submitHandler)}>
                                Ask Another Question <i className="fa fa-angle-right" />
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

const SecuredConditionalLogicModal = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedConditionalLogicModal);

// this component exists to prevent the case where the child component (the ConditionalLogicModal)
// gets the updated props before the parent. This component is always conditionally rendered
// so when the parent has the prop on true it tries to render the component
// which has the updated props first and a bunch of stuff in null and it breaks
// UPDATE: 08/06/2024: this problem has somehow gotten worse, with the race condition
// ignoring even this fix. Added some fallback values to the selectors.js
export const ConditionalLogicModal = (props) => {
    const opened = useSelector(getShowConditionalLogicModal);

    if (!opened) {
        return null;
    }

    return <SecuredConditionalLogicModal {...props} />;
};
