import { fieldNamesDict } from './constants';
import { contractInsuranceFormFieldsDict } from '../../../../../../shared_config/contracts';

const { APPROVED_DATE, EXPIRATION_DATE, REQUESTED_DATE, TAG_ID } = contractInsuranceFormFieldsDict;

const { CONTRACT_INSURANCES } = fieldNamesDict;

export function validate(values = {}) {
    const errors = {};

    if (values[CONTRACT_INSURANCES]) {
        errors[CONTRACT_INSURANCES] = values[CONTRACT_INSURANCES].map((contractInsurance) => {
            const insuranceErrors = {};

            if (!contractInsurance[TAG_ID]) {
                insuranceErrors[TAG_ID] = 'The insurance type is required.';
            }

            [APPROVED_DATE, EXPIRATION_DATE, REQUESTED_DATE].forEach((dateField) => {
                if (CONTRACT_INSURANCES[dateField]) {
                    const maybeDate = new Date(CONTRACT_INSURANCES[dateField]);
                    if (Number.isNaN(maybeDate.valueOf())) {
                        insuranceErrors[dateField] = 'If given, value must be a valid date.';
                    }
                }
            });

            return insuranceErrors;
        });
    }

    return errors;
}
