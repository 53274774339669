import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { Checkbox, HelpIcon } from '../../..';
import { IS_PUBLIC } from '../../../../../../shared_config/questionnaires';

export class PublicCheckbox extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
    };

    render() {
        const tooltip = (
            <div>
                Select this option when you want to make the vendor&#39;s response to this question
                public after proposals have been received and opened.
            </div>
        );

        return (
            <Field
                component={Checkbox}
                disabled={this.props.disabled}
                name={IS_PUBLIC}
                text={
                    <span>
                        Vendor response can be made public
                        <HelpIcon tooltip={tooltip} />
                    </span>
                }
            />
        );
    }
}
