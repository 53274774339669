import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { FiltersDropdown, CheckboxGroup } from '../../../../../../components';
import { FilterGroup } from '../../../../../../components/FilterGroup';
import { useQueryParam } from '../../../../../../hooks';
import { getCheckboxFilters } from '../../selectors';
import { RequisitionDashboardDateFilter } from './DateFilter';

export const RequisitionListDetailedFilters = () => {
    const styles = require('./index.scss');

    const [filters, , setQueryParams] = useQueryParam('filters', []);

    const onApply = (selectedOptions) => {
        setQueryParams({
            filters: selectedOptions,
            page: 1,
        });
    };

    const checkboxFilters = useSelector((state) => getCheckboxFilters(state));

    return (
        <FiltersDropdown
            buttonClassName={styles.filtersButton}
            defaultAppliedOptions={filters}
            onApply={onApply}
        >
            {({ dropdown }) => (
                <>
                    {checkboxFilters.map(({ options, filter, title }) => {
                        const selectedOptionsFromGroup = dropdown.selectedOptions.filter(
                            (option) => option.filter === filter
                        );

                        const subtitle = selectedOptionsFromGroup
                            .map((option) => option.label)
                            .join(', ');

                        return (
                            <div key={title}>
                                <FilterGroup
                                    groupFiltersSelected={selectedOptionsFromGroup.length}
                                    selectedOptions={dropdown.selectedOptions || []}
                                    subtitle={subtitle}
                                    title={title}
                                >
                                    <Field
                                        component={CheckboxGroup}
                                        name={filter}
                                        onOptionSelect={dropdown.toggleOption}
                                        options={options || []}
                                    />
                                </FilterGroup>
                                <div className={styles.separator} />
                            </div>
                        );
                    })}
                    <div>
                        <RequisitionDashboardDateFilter dropdown={dropdown} />
                    </div>
                </>
            )}
        </FiltersDropdown>
    );
};
