import { upperCase } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { HelpIcon, HtmlContent, RsvpButton, StatusLabel } from '../../../../components';
import { statusClass } from '../../../../components/helpers/statusHelper';
import { timezoneAbbreviations } from '../../../../constants';
import { linkifyLocation } from '../../../../../../shared_config/helpers';

export class MonthEventWrapper extends PureComponent {
    static propTypes = {
        event: PropTypes.shape({
            addEventUniqueKey: PropTypes.string,
            agendaUrl: PropTypes.string,
            allDay: PropTypes.bool.isRequired,
            isAttendanceRequired: PropTypes.bool,
            isPublic: PropTypes.bool,
            isRsvpEnded: PropTypes.bool,
            location: PropTypes.string,
            path: PropTypes.string.isRequired,
            projectId: PropTypes.number.isRequired,
            projectStatus: PropTypes.string.isRequired,
            projectTitle: PropTypes.string.isRequired,
            rsvp: PropTypes.shape({
                id: PropTypes.number,
            }),
            start: PropTypes.instanceOf(Date).isRequired,
            timelineId: PropTypes.number,
            timelineTitle: PropTypes.string.isRequired,
            timezone: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        }).isRequired,
        isEmbed: PropTypes.bool,
        onLinkClick: PropTypes.func.isRequired,
        rsvpLoginHandler: PropTypes.func.isRequired,
        rsvpingVendor: PropTypes.bool,
        user: PropTypes.shape({
            isVendor: PropTypes.bool,
        }),
        vendorRsvpHandler: PropTypes.func,
    };

    get styles() {
        return require('./GovPortalCalendar.scss');
    }

    getLocationText = () => {
        const { location } = this.props.event;

        if (!location) {
            return null;
        }

        return (
            <div className={this.styles.location}>
                <span className={this.styles.bold}>Location: </span>
                <HtmlContent content={linkifyLocation(location)} />
            </div>
        );
    };

    getTimeText = () => {
        const { allDay, start, timezone } = this.props.event;

        return (
            <div>
                <span className={this.styles.bold}>
                    {moment(start).format(allDay ? 'ddd, ll' : 'lll')}
                </span>
                <span className={this.styles.timezone}>{timezoneAbbreviations[timezone]}</span>
            </div>
        );
    };

    getAgenda = () => {
        const { agendaUrl } = this.props.event;

        if (!agendaUrl) {
            return null;
        }

        return (
            <div className={this.styles.agenda}>
                <a href={agendaUrl} rel="noopener noreferrer" target="_blank">
                    <i className="fa fa-paperclip" />
                    &nbsp;Agenda
                </a>
            </div>
        );
    };

    getRsvp = () => {
        const {
            event: { isPublic, isRsvpEnded, projectId, rsvp, timelineId },
            rsvpLoginHandler,
            rsvpingVendor,
            user,
            vendorRsvpHandler,
        } = this.props;

        if (!isPublic) {
            return null;
        }

        return (
            <div className={this.styles.rsvp}>
                <div className={this.styles.bold}>RSVP</div>
                <RsvpButton
                    isRsvpEnded={isRsvpEnded}
                    projectId={projectId}
                    rsvp={rsvp}
                    rsvpLoginHandler={rsvpLoginHandler}
                    rsvpingVendor={rsvpingVendor}
                    timelineId={timelineId}
                    user={user}
                    vendorRsvpHandler={vendorRsvpHandler}
                />
            </div>
        );
    };

    getAddToCalendar = () => {
        const { addEventUniqueKey } = this.props.event;

        if (!addEventUniqueKey) {
            return null;
        }

        return (
            <div className={this.styles.copyToCalendar}>
                Add to Calendar
                <HelpIcon
                    icon="fa-info-circle"
                    placement="right"
                    tooltip="Select the service below to add this event to your calendar. Note: Date and time changes will not automatically update the event on your calendar."
                />
                <br />
                <a
                    href={`https://www.addevent.com/event/${addEventUniqueKey}+apple`}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        alt="Apple"
                        src="https://www.addevent.com/gfx/icon-emd-share-apple-t1.png"
                        title="Apple"
                    />
                </a>
                <a
                    href={`https://www.addevent.com/event/${addEventUniqueKey}+google`}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        alt="Google"
                        src="https://www.addevent.com/gfx/icon-emd-share-google-t1.png"
                        title="Google"
                    />
                </a>
                <a
                    href={`https://www.addevent.com/event/${addEventUniqueKey}+office365`}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        alt="Office 365"
                        src="https://www.addevent.com/gfx/icon-emd-share-office365-t1.png"
                        title="Office 365"
                    />
                </a>
                <a
                    href={`https://www.addevent.com/event/${addEventUniqueKey}+outlook`}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        alt="Outlook"
                        src="https://www.addevent.com/gfx/icon-emd-share-outlook-t1.png"
                        title="Outlook"
                    />
                </a>
                <a
                    href={`https://www.addevent.com/event/${addEventUniqueKey}+outlookcom`}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        alt="Outlook.com"
                        src="https://www.addevent.com/gfx/icon-emd-share-outlookcom-t1.png"
                        title="Outlook.com"
                    />
                </a>
                <a
                    href={`https://www.addevent.com/event/${addEventUniqueKey}+yahoo`}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        alt="Yahoo"
                        src="https://www.addevent.com/gfx/icon-emd-share-yahoo-t1.png"
                        title="Yahoo"
                    />
                </a>
            </div>
        );
    };

    renderOverlay() {
        const { isEmbed, onLinkClick } = this.props;
        const { isAttendanceRequired, isPublic, path, projectStatus, projectTitle, timelineTitle } =
            this.props.event;

        const popoverTitle = () => {
            const linkProps = {};
            if (isEmbed) {
                linkProps.onClick = () => window.open(`${process.env.SITE_URL}${path}`);
            } else {
                linkProps.onClick = () => onLinkClick(path);
            }

            return (
                <>
                    <span className="pseudoLink" {...linkProps}>
                        {projectTitle}
                    </span>
                    <br />
                    <StatusLabel
                        bsStyle={statusClass(projectStatus)}
                        className={this.styles.projectStatusLabel}
                    >
                        {upperCase(projectStatus)}
                    </StatusLabel>
                </>
            );
        };

        return (
            <Popover id="calendar-event-detail" title={popoverTitle()}>
                <h5 className={this.styles.timelineTitle}>{timelineTitle}</h5>
                {isPublic && (
                    <StatusLabel bsStyle="info" className={this.styles.publicLabel}>
                        <i className="fa fa-users" /> Public Meeting
                    </StatusLabel>
                )}
                {isAttendanceRequired && (
                    <StatusLabel bsStyle="danger">
                        <i className="fa fa-exclamation-circle" /> Attendance Required
                    </StatusLabel>
                )}
                <div className={this.styles.eventInfoBlock}>
                    {this.getAgenda()}
                    {this.getTimeText()}
                    {this.getLocationText()}
                    {this.getRsvp()}
                    {this.getAddToCalendar()}
                </div>
            </Popover>
        );
    }

    render() {
        const { allDay, start, projectTitle, timelineTitle } = this.props.event;

        return (
            <OverlayTrigger
                overlay={this.renderOverlay()}
                placement="bottom"
                rootClose
                trigger="click"
            >
                <div className={this.styles.monthEvent}>
                    {!allDay ? moment(start).format('LT') : ''}
                    <br />
                    <strong>{timelineTitle}:</strong>
                    <br />
                    {projectTitle}
                </div>
            </OverlayTrigger>
        );
    }
}
