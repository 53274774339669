import PropTypes from 'prop-types';
import React from 'react';

import { fieldNames } from '../constants';
import { QuestionnairesForm } from '../../../../../components';
import { checklistQuestionTypesDisplay } from '../../../../../../../shared_config/questionnaires';

const { QUESTIONNAIRES } = fieldNames;

export const TemplateQuestionnairesForm = ({
    array,
    change,
    disabled,
    questionnaires,
    showFormValidation,
}) => {
    return (
        <QuestionnairesForm
            array={array}
            change={change}
            disabled={disabled}
            formKey={QUESTIONNAIRES}
            questionTypes={checklistQuestionTypesDisplay}
            questionnaires={questionnaires}
            rerenderOnEveryChange
            showFormErrors={showFormValidation}
        />
    );
};

TemplateQuestionnairesForm.propTypes = {
    array: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    questionnaires: PropTypes.array.isRequired,
    showFormValidation: PropTypes.bool,
};
