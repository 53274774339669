import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Box, Grid } from '@og-pro/ui';

import { defaultSectionConfigsMap } from '../../../../../../../shared_config/sections';
import { MANUAL_NUMBER_CHAR_LIMIT } from '../../../../../../../shared_config/manualNumber';
import {
    fieldNames,
    PARENT_DIVIDER,
} from '../../../../../containers/GovApp/TemplateAdmin/forms/TemplateProjectSectionsForm/constants';
import { limitTextLength } from '../../../../../Forms/normalizers';
import { InputText, SearchSelect } from '../../../..';

const { TITLE, MANUAL_NUMBER } = fieldNames;

export const TitleNumberInputs = ({
    disabled,
    member,
    showValidation,
    type,
    useManualNumbering,
    parentDividerOptions = [],
}) => {
    return (
        <>
            {useManualNumbering && (
                <Grid alignItems="flex-end" container>
                    <Grid item md={6} xs={12}>
                        <Box mr={2}>
                            <Field
                                component={InputText}
                                disabled={disabled}
                                hasFeedback={false}
                                help="Manual numbering to use"
                                label={`${type} number`}
                                name={`${member}.${MANUAL_NUMBER}`}
                                normalize={limitTextLength(MANUAL_NUMBER_CHAR_LIMIT)}
                                qaTag={`projectSectionForm-${MANUAL_NUMBER}`}
                                showValidation={showValidation}
                                type="text"
                                useOpenGovStyle
                            />
                        </Box>
                    </Grid>

                    {parentDividerOptions?.length > 0 && (
                        <Grid item md={6} xs={12}>
                            <Field
                                component={SearchSelect}
                                disabled={disabled}
                                isSearchable
                                label="Part Of"
                                name={`${member}.${PARENT_DIVIDER}`}
                                options={parentDividerOptions}
                                qaTag={`projectSectionForm-${PARENT_DIVIDER}`}
                                styles={{ menu: { zIndex: 1001 } }}
                                useOpenGovStyle
                            />
                        </Grid>
                    )}
                </Grid>
            )}
            <Field
                autoFocus
                component={InputText}
                disabled={disabled}
                hasFeedback={false}
                label={`${type} Title`}
                name={`${member}.${TITLE}`}
                normalize={limitTextLength(128)}
                placeholder={defaultSectionConfigsMap[TITLE]}
                qaTag="projectSectionForm-sectionTitle"
                showValidation={showValidation}
                type="text"
                useOpenGovStyle
            />
        </>
    );
};

TitleNumberInputs.propTypes = {
    disabled: PropTypes.bool,
    member: PropTypes.string.isRequired,
    parentDividerOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            label: PropTypes.string,
        })
    ),
    showValidation: PropTypes.bool,
    type: PropTypes.string.isRequired,
    useManualNumbering: PropTypes.bool,
};
