import { contactFieldNames, mappedUserFields } from '../../../../shared_config/contacts';
import { UNITED_STATES_PHONE_COUNTRY_CODE } from '../../../../shared_config/global';

const { PHONE, PHONE_COUNTRY, PHONE_EXT } = contactFieldNames;

/**
 * Phone number validation
 * @param {object} values Form values
 * @param {object} [opts={}] Options
 * @param {boolean} [opts.fieldNames=mappedUserFields] Dictionary of the form key value names
 * @param {boolean} [opts.optional=false] Option to skip validation when no phone number is provided
 * @return {object} Form errors object
 */
export function phone(values, opts = { fieldNames: mappedUserFields, optional: false }) {
    const errors = {};

    const phoneField = opts.fieldNames[PHONE];
    const phoneExtField = opts.fieldNames[PHONE_EXT];
    const phoneCountryField = opts.fieldNames[PHONE_COUNTRY];

    const parsedCountry = values[phoneCountryField] && values[phoneCountryField].replace(/^0+/, '');
    const isInternational = parsedCountry && parsedCountry !== UNITED_STATES_PHONE_COUNTRY_CODE;

    const phoneValue = values[phoneField];
    if (phoneValue) {
        if (isInternational) {
            if (phoneValue.length > 16) {
                errors[phoneField] = 'Phone number cannot be more than 16 digits';
            } else if (!phoneValue.match(/^[0-9]+$/)) {
                errors[phoneField] = 'Phone number may only contain digits';
            }
        } else if (phoneValue.length < 13) {
            errors[phoneField] = 'Phone number should be 10 digits long';
        }
    } else if (!opts.optional) {
        errors[phoneField] = 'Phone number is required.';
    }

    const phoneExtValue = values[phoneExtField];
    if (phoneExtValue && !phoneExtValue.match(/^[0-9*#]*$/)) {
        errors[phoneExtField] = 'Phone number extensions may only contain 0-9, *, and #';
    }

    const phoneCountryValue = values[phoneCountryField];
    if (phoneCountryValue) {
        if (phoneCountryValue.length > 3) {
            errors[phoneCountryField] = 'Country code cannot be more than 3 digits';
        } else if (!phoneCountryValue.match(/^[0-9]+$/)) {
            errors[phoneCountryField] = 'Country code may only contain digits';
        }
    } else if (!opts.optional) {
        errors[phoneCountryField] = 'Phone country code is required.';
    }

    return errors;
}
