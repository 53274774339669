import React from 'react';
import { Outlet } from 'react-router-dom';

import {
    RequisitionsAdminNav,
    RequisitionsEntityPositions,
    RequisitionsFlags,
    RequisitionsGroupPositions,
    RequisitionsSettings,
} from '../../../containers/Requisitions';
import {
    legacyFetchData,
    requireSystemAdmin,
    requireRequisitionsSubscription,
} from '../../loaders';

export default (getState, dispatch) => {
    return [
        {
            element: <Outlet />,
            loader: async (props) => {
                const permissionRedirect = await requireSystemAdmin(getState)(props);

                if (permissionRedirect) {
                    return permissionRedirect;
                }

                return requireRequisitionsSubscription(getState)(props);
            },
            path: 'requisitions-admin',
            children: [
                {
                    element: <RequisitionsAdminNav />,
                    children: [
                        {
                            element: <RequisitionsSettings />,
                            loader: legacyFetchData(getState, dispatch, RequisitionsSettings),
                            index: true,
                        },
                        {
                            element: <RequisitionsSettings />,
                            loader: legacyFetchData(getState, dispatch, RequisitionsSettings),
                            path: 'settings',
                        },
                        {
                            element: <RequisitionsEntityPositions />,
                            loader: legacyFetchData(
                                getState,
                                dispatch,
                                RequisitionsEntityPositions
                            ),
                            path: 'entity-positions',
                        },
                        {
                            element: <RequisitionsGroupPositions />,
                            loader: legacyFetchData(getState, dispatch, RequisitionsGroupPositions),
                            path: 'group-positions',
                        },
                        {
                            element: <RequisitionsFlags />,
                            loader: legacyFetchData(getState, dispatch, RequisitionsFlags),
                            path: 'flags',
                        },
                    ],
                },
            ],
        },
    ];
};
