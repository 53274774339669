import { startCase } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { TemplateListItemProjects } from '../components';
import { getTemplateSectionTypeDisplay } from '../helpers';
import { checkOutTemplateSection } from '../../../../actions/templateSections';
import { TemplateStatusLabel, UserProfilePicture } from '../../../../components';
import { defaultSectionConfigsMap } from '../../../../../../shared_config/sections';

export const TemplateSectionsListItem = ({
    templatesAdminPath,
    templateSection: {
        checkedOutUser,
        checkingOut,
        checkOutError,
        created_at: createdAt,
        id,
        isPublished,
        lastUpdatedAt,
        projects,
        projectSection: { manualNumber, section_type: sectionType, shortName, title },
        type,
        user,
    },
}) => {
    const dispatch = useDispatch();
    const styles = require('./index.scss');
    const manualNumbering = manualNumber ? `${manualNumber} ` : '';

    return (
        <div className="row">
            <div className="col-md-3">
                <span className={styles.templateTitle}>
                    <i className={`fa fa-fw fa-${defaultSectionConfigsMap[sectionType].icon}`} />
                    {manualNumbering}
                    {title || 'Untitled'}
                </span>
                <TemplateStatusLabel className={styles.statusLabel} isPublished={isPublished} />
                <div className={styles.typeText}>
                    {startCase(getTemplateSectionTypeDisplay(type))} |{' '}
                    {defaultSectionConfigsMap[sectionType].title}
                </div>
            </div>
            <div className="col-md-2">{shortName}</div>
            <div className="col-md-2 hidden-xs hidden-sm">
                {checkedOutUser ? (
                    <>
                        <UserProfilePicture horizontal user={checkedOutUser} />
                        &nbsp;&nbsp;
                        {checkedOutUser.displayName}
                    </>
                ) : (
                    <>
                        <span>Available</span>
                        <br />
                        <a
                            href="#"
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!checkingOut) {
                                    dispatch(checkOutTemplateSection(id, true));
                                }
                            }}
                        >
                            Claim & Edit Now
                        </a>
                        {checkOutError && <div className="error-block">{checkOutError}</div>}
                    </>
                )}
            </div>
            <div className="col-md-3 hidden-xs hidden-sm">
                <div className="row">
                    <div className="col-md-4" title={moment(lastUpdatedAt).format('lll')}>
                        {moment(lastUpdatedAt).fromNow()}
                    </div>
                    <div className="col-md-8">
                        <TemplateListItemProjects
                            projects={projects}
                            templatesAdminPath={templatesAdminPath}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-2 hidden-xs hidden-sm">
                {user && (
                    <>
                        <UserProfilePicture className={styles.ownerProfilePicture} user={user} />
                        <div className={styles.ownerNameInfo}>
                            {user.displayName}
                            <br />
                            <small>{moment(createdAt).format('ll')}</small>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

TemplateSectionsListItem.propTypes = {
    templatesAdminPath: PropTypes.string.isRequired,
    templateSection: PropTypes.shape({
        checkedOutUser: PropTypes.object,
        checkingOut: PropTypes.bool,
        checkOutError: PropTypes.string,
        created_at: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        isPublished: PropTypes.bool.isRequired,
        lastUpdatedAt: PropTypes.string.isRequired,
        projects: PropTypes.arrayOf(
            PropTypes.shape({
                template: PropTypes.shape({
                    title: PropTypes.string,
                }).isRequired,
            })
        ).isRequired,
        projectSection: PropTypes.shape({
            manualNumber: PropTypes.string,
            section_type: PropTypes.string.isRequired,
            title: PropTypes.string,
            shortName: PropTypes.string,
        }).isRequired,
        type: PropTypes.string,
        user: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
        }),
    }).isRequired,
};
