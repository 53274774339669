import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AccordionSummary, buildQaTag } from '@og-pro/ui';
import { ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';
import { Hidden } from '@mui/material';

import { currencyFormatter } from '../../../../../../../../helpers';
import {
    getIsRequisitionUsingFMS,
    getRequisitionJS,
} from '../../../../../../../../selectors/govApp';
import { discountTypes } from '../../../../../../../../../../shared_config/priceTables';
import {
    budgetCheckStates,
    getPriceItemBudgetCheckStatus,
} from '../../../../../../helpers/budgetCheck';
import { DECIMAL_PLACES } from '../constants';
import { isRequisitionSubmitted } from '../../../../../../../../../../shared_config/requisitions';

export const LineItemSummary = ({
    extendedPrice,
    index,
    lineItemNumber,
    priceItem,
    showDiscountInHeader,
    tax,
}) => {
    const styles = require('./index.scss');
    const hasFMS = useSelector(getIsRequisitionUsingFMS);
    const requisition = useSelector(getRequisitionJS);
    const { description, discount, discountType, quantity, taxable, unitPrice, unitToMeasure } =
        priceItem;

    const budgetCheckStatus = useMemo(() => {
        return getPriceItemBudgetCheckStatus(
            {
                accountSplitPriceItems: priceItem?.accountSplitPriceItems,
                quantity,
                unitPrice,
            },
            isRequisitionSubmitted(requisition?.status)
        );
    }, [priceItem, quantity, unitPrice, requisition?.status]);

    const renderBudgetCheck = () => {
        if (budgetCheckStatus === budgetCheckStates.FAIL) {
            return <div className={classNames(styles.budget, styles.budgetFail)}>Fail</div>;
        }

        if (budgetCheckStatus === budgetCheckStates.NON_EXPENSE) {
            return <div className={classNames(styles.budget)}>N/A</div>;
        }

        if (budgetCheckStatus === budgetCheckStates.PASS) {
            return <div className={classNames(styles.budget, styles.budgetPass)}>Pass</div>;
        }

        return <div className={classNames(styles.budget)} />;
    };

    return (
        <AccordionSummary
            expandIcon={
                <Hidden smDown>
                    <ArrowForwardIosIcon sx={{ fontSize: 'medium' }} />
                </Hidden>
            }
        >
            <div
                className={styles.summary}
                data-qa={buildQaTag(`requisitionsCreate-expandSummary${index}`, 'icon')}
            >
                <div className={styles.line}>{lineItemNumber}</div>
                {hasFMS && renderBudgetCheck()}
                <div className={styles.description}>{description}</div>
                <div className={styles.quantity}>{quantity}</div>
                <div className={styles.unitOfMeasure}>{unitToMeasure}</div>
                <div className={styles.unitPrice}>
                    {currencyFormatter(
                        { value: unitPrice },
                        { maximumFractionDigits: DECIMAL_PLACES }
                    )}
                </div>
                {showDiscountInHeader && (
                    <div className={styles.discount}>
                        {discountType === discountTypes.DOLLAR_AMOUNT
                            ? currencyFormatter(
                                  { value: discount || 0 },
                                  { maximumFractionDigits: DECIMAL_PLACES }
                              )
                            : `${discount || 0}%`}
                    </div>
                )}
                {!!tax && (
                    <div className={styles.tax}>{taxable && <i className="fa fa-check" />}</div>
                )}
                <div className={styles.total}>
                    {currencyFormatter(
                        { value: extendedPrice },
                        {
                            maximumFractionDigits: DECIMAL_PLACES,
                        }
                    )}
                </div>
            </div>
        </AccordionSummary>
    );
};

LineItemSummary.propTypes = {
    extendedPrice: PropTypes.number,
    index: PropTypes.number.isRequired,
    lineItemNumber: PropTypes.number,
    priceItem: PropTypes.shape({
        accountSplitPriceItems: PropTypes.array,
        description: PropTypes.string,
        discount: PropTypes.number,
        discountType: PropTypes.string,
        quantity: PropTypes.number,
        taxable: PropTypes.bool,
        unitPrice: PropTypes.number,
        unitToMeasure: PropTypes.string,
    }).isRequired,
    showDiscountInHeader: PropTypes.bool,
    tax: PropTypes.number,
};
