import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Checkbox } from 'react-bootstrap';

import { CONFIRMATION_TEXT } from '../constants';
import { QuestionPrompt } from '../../SharedDisplay';

export class ConfirmationDisplay extends PureComponent {
    static propTypes = {
        isDocx: PropTypes.bool,
    };

    static defaultProps = {
        isDocx: false,
    };

    render() {
        const { isDocx } = this.props;

        if (isDocx) {
            return (
                <QuestionPrompt {...this.props}>
                    <div>
                        <input type="checkbox" /> {CONFIRMATION_TEXT}
                    </div>
                </QuestionPrompt>
            );
        }

        return (
            <QuestionPrompt {...this.props}>
                <Checkbox className="confirmation-checkbox" disabled readOnly>
                    {CONFIRMATION_TEXT}
                </Checkbox>
            </QuestionPrompt>
        );
    }
}
