import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, FieldArray } from 'redux-form';

import { QUESTIONNAIRES } from './constants';
import { formConfig } from '../constants';
import { ProposalSectionButtons } from '../ProposalSectionButtons';
import { Alert, QuestionnaireDisplayList } from '../../..';
import { QUESTIONNAIRE } from '../../../ProposalCreateNav/constants';
import { shouldHideQuestionLogicItem } from '../../../../../../shared_config/questionLogics/utils';
import { QUESTIONNAIRE_RESPONSE } from '../../../../../../shared_config/questionnaires';

// @reduxForm
class ConnectedProposalQuestionnaire extends Component {
    static propTypes = {
        change: PropTypes.func.isRequired,
        dirty: PropTypes.bool,
        disabled: PropTypes.bool,
        proposalFormData: PropTypes.shape({
            id: PropTypes.number,
        }).isRequired,
        onSave: PropTypes.func.isRequired,
        sections: PropTypes.array.isRequired,
        showValidation: PropTypes.bool,
        skipQuestionnaireValidation: PropTypes.bool,
        updateError: PropTypes.string,
    };

    static defaultProps = {
        disabled: false,
        updateError: undefined,
    };

    handleConditionalQuestionChange = (parentQuestion, fields) => {
        const { change } = this.props;

        fields.forEach((member, index, fieldsRef) => {
            const questionnaire = fieldsRef.get(index);
            // When the value of a parent condition question changes we need to update the
            // child questions
            if (parentQuestion.conditionalSubQuestionIds.includes(questionnaire.id)) {
                // get the question logic that links the parent to the child
                const { questionLogic } = questionnaire;

                // 1. Compute `isHiddenByLogic` and set as form value so form updates in real time
                const isHiddenByLogic = shouldHideQuestionLogicItem(questionLogic, parentQuestion);

                // 2. If child question was previously shown and will continue to show, do not reset answer
                if (!questionnaire.isHiddenByLogic && !isHiddenByLogic) {
                    return;
                }

                change(`${member}.isHiddenByLogic`, isHiddenByLogic);
                // 3. Wipe any previous input values
                change(`${member}.${QUESTIONNAIRE_RESPONSE}`, null);
            }
        });
    };

    renderQuestionnaire = (props) => {
        const { fields } = props;

        const questionnaires = fields.map((member, index, fieldsRef) => {
            const questionnaire = fieldsRef.get(index);

            let onChange;

            if ((questionnaire.conditionalSubQuestionIds || []).length > 0) {
                // extend the onchange event to clear child questions values when they are not
                // displayed
                onChange = () => {
                    setTimeout(() => {
                        const parentQuestion = fieldsRef.get(index);
                        this.handleConditionalQuestionChange(parentQuestion, fieldsRef);
                    });
                };
            }

            return {
                ...questionnaire,
                fieldName: member,
                onChange,
            };
        });

        return (
            <QuestionnaireDisplayList
                disabled={props.disabled}
                isResponseForm
                proposalId={props.proposalId}
                questionnaires={questionnaires}
                showExportButton
                showValidation={props.showValidation}
            />
        );
    };

    render() {
        const {
            dirty,
            disabled,
            proposalFormData: { id },
            onSave,
            sections,
            showValidation,
            skipQuestionnaireValidation,
            updateError,
        } = this.props;

        return (
            <div>
                {skipQuestionnaireValidation && (
                    <Alert bsStyle="warning">
                        <i className="fa fa-info-circle" /> Questionnaire items are all optional for
                        manually uploaded responses
                    </Alert>
                )}
                <FieldArray
                    component={this.renderQuestionnaire}
                    disabled={disabled}
                    name={QUESTIONNAIRES}
                    proposalId={id}
                    showValidation={showValidation}
                />
                <ProposalSectionButtons
                    disabled={disabled}
                    isFormDirty={dirty}
                    onSave={onSave}
                    section={QUESTIONNAIRE}
                    sections={sections}
                    updateError={updateError}
                />
            </div>
        );
    }
}

export const ProposalQuestionnaire = reduxForm(formConfig)(ConnectedProposalQuestionnaire);
