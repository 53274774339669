import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

import {
    getEntityPositions,
    getFlags,
    getGroupPositions,
} from '../../../../../../../selectors/govApp';
import {
    questionLogicLogicableModelNames,
    requisitionFieldLogicableIds,
} from '../../../../../../../../../shared_config/questionLogics';

const { FLAG, REQUISITION_FIELD } = questionLogicLogicableModelNames;
const { TOTAL_SPEND } = requisitionFieldLogicableIds;

export const getPositionOptions = createSelector(
    [getEntityPositions, getGroupPositions],
    (entityPositions, groupPositions) => {
        const optionFormatter = (positions) => {
            return sortBy(
                positions.map((position) => {
                    return {
                        label: position.name,
                        value: position.id,
                        position,
                    };
                }),
                ['label']
            );
        };

        return [
            {
                label: 'Entity Positions',
                options: optionFormatter(entityPositions),
            },
            {
                label: 'Group Positions',
                options: optionFormatter(groupPositions),
            },
        ];
    }
);

export const getQuestionLogicLogicableOptions = createSelector([getFlags], (flags) => {
    return [
        {
            label: 'Flags',
            options: sortBy(
                flags.map((flag) => ({
                    label: flag.name,
                    value: `${FLAG}#${flag.id}`,
                })),
                'label'
            ),
        },
        {
            label: 'Requisition Inputs',
            options: [
                {
                    label: 'Total Spend',
                    value: `${REQUISITION_FIELD}#${TOTAL_SPEND}`,
                },
            ],
        },
    ];
});
