import { get, pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { qaTagPageName } from './constants';
import { serializeReviewSequenceData } from './helper';
import { ReviewSequenceTitleMenu } from './ReviewSequenceTitleMenu';
import { ReviewSequenceForm } from './ReviewSequenceForm';
import { fieldNames, fields, form } from './ReviewSequenceForm/constants';
import { validate } from './ReviewSequenceForm/validate';
import { getDeserializedReviewSequence, getReviewSequenceFormValues } from './selectors';
import { getReviewSequencesPath } from '../selectors';
import connectData from '../../../ConnectData';
import { loadFlags, loadPositions } from '../../../../actions/requisitionsAdmin';
import {
    loadReviewSequence,
    resetReviewSequenceEdit,
    updateReviewSequence,
    validateReviewGroups,
} from '../../../../actions/reviewSequences';
import {
    Button,
    LoadingError,
    LoadingSpinner,
    Main,
    PageTitle,
    RouteLeaveWarning,
} from '../../../../components';
import { statusTypes } from '../../../../../../shared_config/reviewSequences';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';

const { ARCHIVED, DRAFT, PUBLISHED } = statusTypes;

const { NAME } = fieldNames;

const fetchData = (getState, dispatch, location, params) => {
    const reviewSequenceId = Number.parseInt(params.reviewSequenceId, 10);
    return Promise.all([
        dispatch(loadReviewSequence(reviewSequenceId)),
        dispatch(loadPositions()),
        dispatch(loadFlags()),
    ]);
};

const formConfig = {
    enableReinitialize: true,
    form,
    validate,
};

const mapStateToProps = (state) => {
    const reviewSequence = getDeserializedReviewSequence(state);
    return {
        initialValues: pick(reviewSequence, fields),
        reviewSequence,
    };
};

// @connectData
// @connect
// @reduxForm
const ConnectedReviewSequenceEdit = (props) => {
    const { array, change, dirty, handleSubmit, reviewSequence, submitFailed } = props;

    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [showFormValidation, setShowFormValidation] = useState(false);

    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.reviewSequences.get('loadingItem'));
    const loadError = useSelector((state) => state.reviewSequences.get('loadItemError'));

    const copying = useSelector((state) => state.reviewSequences.get('copyingItem'));
    const deleting = useSelector((state) => state.reviewSequences.get('deletingItem'));
    const settingDefault = useSelector((state) => state.reviewSequences.get('settingDefault'));
    const updating = useSelector((state) => state.reviewSequences.get('updatingItem'));

    const reviewSequencesPath = useSelector(() => getReviewSequencesPath({ params, location }));
    const formValues = useSelector(getReviewSequenceFormValues);

    useEffect(() => {
        return () => dispatch(resetReviewSequenceEdit());
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (loadError || !reviewSequence) {
        return <LoadingError error={loadError} />;
    }

    const { id, status } = reviewSequence;
    const isArchived = status === ARCHIVED;
    const isPublished = status === PUBLISHED;

    const updateReviewSequenceFn = (data, options = {}) => {
        const updateData = serializeReviewSequenceData(data);

        if (!options.skipReviewGroupValidation) {
            return dispatch(validateReviewGroups(id, updateData)).then((invalidReviewGroups) => {
                const proceedWithUpdate = () => {
                    return updateReviewSequenceFn(data, {
                        ...options,
                        skipReviewGroupValidation: true,
                    });
                };

                const reviewGroupCount = invalidReviewGroups.length;
                if (reviewGroupCount === 0) {
                    return proceedWithUpdate();
                }

                return dispatch(
                    showConfirmationSimpleModal(proceedWithUpdate, {
                        bsStyle: 'warning',
                        btnText: 'Proceed',
                        icon: 'arrow-right',
                        text: `This update will affect ${reviewGroupCount} Requisition Group(s).\n\nIf you proceed with the update, the following groups will be reset to "Draft" and will need to be updated before they can be used again:\n${invalidReviewGroups
                            .map(({ name }) => `- ${name || 'Untitled'}`)
                            .join('\n')}`,
                    })
                );
            });
        }

        return dispatch(updateReviewSequence(id, updateData, options));
    };

    const toggleLive = () => {
        if (isPublished) {
            const submitData = {
                ...formValues,
                status: DRAFT,
            };
            return updateReviewSequenceFn(submitData, {
                successMessage: 'Sequence disabled',
            });
        }

        setShowFormValidation(true);

        return handleSubmit((data) => {
            const submitData = {
                ...data,
                status: PUBLISHED,
            };
            setShowFormValidation(false);
            return updateReviewSequenceFn(submitData, {
                successMessage: 'Sequence set live!',
            });
        })();
    };

    const toggleArchive = () => {
        const submitData = {
            ...formValues,
            status: isArchived ? DRAFT : ARCHIVED,
        };
        return updateReviewSequenceFn(submitData, {
            onSuccess: isArchived ? undefined : () => navigate(reviewSequencesPath),
            successMessage: `Sequence ${isArchived ? 'enabled' : 'archived'}`,
        });
    };

    const saveReviewSequence = (additionalData) => {
        // If the template has been published we want to validate the form before submitting
        if (isPublished) {
            setShowFormValidation(true);
            return handleSubmit((data) => {
                setShowFormValidation(false);
                return updateReviewSequenceFn({ ...data, ...additionalData });
            })();
        }

        return updateReviewSequenceFn(formValues, { skipReviewGroupValidation: true });
    };

    const disabled = copying || deleting || settingDefault || updating;

    return (
        <Main>
            <PageTitle title="Edit Sequence" />
            <div>
                <Button
                    bsSize="sm"
                    bsStyle="link"
                    qaTag={`${qaTagPageName}-back`}
                    to={reviewSequencesPath}
                >
                    <i className="fa fa-angle-left" /> Review Sequences
                </Button>
            </div>
            <RouteLeaveWarning blockingValue={dirty} />
            <ReviewSequenceTitleMenu
                deleting={deleting}
                disabled={disabled}
                reviewSequence={reviewSequence}
                reviewSequencesPath={reviewSequencesPath}
                setShowFormValidation={setShowFormValidation}
                submitFailed={submitFailed}
                title={get(formValues, NAME)}
                toggleArchive={toggleArchive}
                toggleLive={toggleLive}
                updateReviewSequence={saveReviewSequence}
                updating={updating}
            />
            <ReviewSequenceForm
                array={array}
                change={change}
                disabled={isArchived || disabled}
                isPublished={isPublished}
                reviewSequenceFormValues={formValues}
                setShowFormValidation={setShowFormValidation}
                showFormValidation={showFormValidation}
                toggleLive={toggleLive}
                updateReviewSequence={saveReviewSequence}
            />
        </Main>
    );
};

ConnectedReviewSequenceEdit.propTypes = {
    array: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reviewSequence: PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.number.isRequired,
    }),
    submitFailed: PropTypes.bool.isRequired,
};

export const ReviewSequenceEdit = compose(
    connectData(fetchData),
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedReviewSequenceEdit);
