import React, { useContext } from 'react';
import { Field } from 'redux-form';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@og-pro/ui';

import { getProjectSectionTagOptions } from '../../../../../selectors';
import { RichTextInput } from '../../../../../../../components';
import { ContentBlock } from '../../../../../../../components/SDv2/ContentBlock';
import { fieldNames } from '../ProjectInformation/constants';
import { INTRODUCTION_VARIABLES, getTemplateVariableOptions } from '../../../../../constants';
import { subsectionTypeNames } from '../../../../../../../../../shared_config/subsections';
import { sectionTypeNames } from '../../../../../../../../../shared_config/sections';
import { CommentIcon } from '../../../../../index';
import { ProjectCreateHelpModal } from '../../../../ProjectCreateHelpModal';
import { ProjectCreateV2FunctionsContext } from '../../../context';

const { RAW_BACKGROUND, RAW_SUMMARY } = fieldNames;

const { INTRODUCTION } = sectionTypeNames;

const { BACKGROUND, SUMMARY } = subsectionTypeNames;

export const SummaryAndBackground = () => {
    const {
        disabled,
        project,
        showFormErrors: showValidation,
    } = useContext(ProjectCreateV2FunctionsContext);

    const projectSection = project.projectSections.find(
        (section) => section.section_type === INTRODUCTION
    );
    const commentProps = {
        projectSection,
        openGovStyleIconOnly: true,
        show: true,
        useOpenGovStyle: true,
    };
    const shouldShowHelpModal = useSelector((state) => state.projectCreate.get('showHelpModal'));
    const tagOptions = useSelector(getProjectSectionTagOptions);
    const templateVariableOptions = getTemplateVariableOptions({ project }).filter(
        (opt) => opt.label !== INTRODUCTION_VARIABLES
    );

    const styles = require('../../shared.scss');

    return (
        <div>
            <ContentBlock withActions>
                <ContentBlock.Main>
                    <Box
                        alignItems="baseline"
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="space-between"
                    >
                        <Typography className={styles.noMarginBottom} variant="h3">
                            Summary
                        </Typography>
                    </Box>
                    <p className={styles.helpText}>
                        The summary should be 1-3 paragraphs. Its purpose is to give the vendor a
                        brief idea of what the project aims to accomplish and to spark interest in
                        potential quality vendors.
                    </p>
                    <Field
                        className={styles.noMarginBottom}
                        component={RichTextInput}
                        disabled={disabled}
                        forcePaddedContent
                        name={RAW_SUMMARY}
                        showValidation={showValidation}
                        tagOptions={tagOptions}
                        templateVariableOptions={templateVariableOptions}
                        useOpenGovStyle
                        useSharedTextareaToolbar
                    />
                </ContentBlock.Main>
                {!!projectSection && (
                    <ContentBlock.ActionSidebar>
                        <ContentBlock.ButtonGroup>
                            <ContentBlock.Button>
                                <CommentIcon
                                    {...commentProps}
                                    qaTag={`projectInformationForm-${SUMMARY}Comments`}
                                    subsectionType={SUMMARY}
                                />
                            </ContentBlock.Button>
                        </ContentBlock.ButtonGroup>
                    </ContentBlock.ActionSidebar>
                )}
            </ContentBlock>
            <ContentBlock withActions>
                <ContentBlock.Main>
                    <Box
                        alignItems="baseline"
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="space-between"
                    >
                        <Typography className={styles.noMarginBottom} variant="h3">
                            Background{' '}
                            <Typography component="span" fontWeight={400}>
                                (optional)
                            </Typography>
                        </Typography>
                    </Box>
                    <p className={styles.helpText}>
                        Background information should be relevant to your project and help the
                        vendor better understand why this project is important, what the challenges
                        are, or what would hit the ball out of the park.
                    </p>
                    <Field
                        className={styles.noMarginBottom}
                        component={RichTextInput}
                        disabled={disabled}
                        forcePaddedContent
                        name={RAW_BACKGROUND}
                        showValidation={showValidation}
                        tagOptions={tagOptions}
                        templateVariableOptions={templateVariableOptions}
                        useOpenGovStyle
                        useSharedTextareaToolbar
                    />
                </ContentBlock.Main>
                {!!projectSection && (
                    <ContentBlock.ActionSidebar>
                        <ContentBlock.ButtonGroup>
                            <ContentBlock.Button>
                                <CommentIcon
                                    {...commentProps}
                                    qaTag={`projectInformationForm-${BACKGROUND}Comments`}
                                    subsectionType={BACKGROUND}
                                />
                            </ContentBlock.Button>
                        </ContentBlock.ButtonGroup>
                    </ContentBlock.ActionSidebar>
                )}
            </ContentBlock>
            {shouldShowHelpModal && !!projectSection && (
                <ProjectCreateHelpModal overrideSelectedProjectSectionId={projectSection.id} />
            )}
        </div>
    );
};
