import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import {
    areProposalsEditable,
    getProjectJS,
    getProposalJS,
    getProposalForEvaluationJS,
} from '../../selectors';
import {
    showConfirmationSimpleModal,
    showConfirmationModal,
} from '../../../../actions/confirmation';
import {
    createProposal,
    disqualifyProposal,
    excludeProposal,
    unsubmitProposal,
    updateQuestionnaireResponse,
} from '../../../../actions/govProposals';
import { BackButton, Button, HoverButton, Main, ProposalDetails } from '../../../../components';
import { DISQUALIFY_PROPOSAL } from '../../../../constants/menuActions';
import { AddVendorsToListModal } from '../../VendorManagement/AddVendorsToList/modal';
import {
    showAddVendorsToListModal,
    loadVendorsLists,
    addOrRemoveVendors,
} from '../../../../actions/vendorList';
import { getShowAddVendorListModal, getVendorUsersLists } from '../../VendorManagement/selectors';
import { isVendorAdminUser } from '../../../App/selectors';
import { moduleTypesDict } from '../../../../../../shared_config/modules';

const mapStateToProps = (state, props) => {
    const proposalsPath = props.location.pathname.replace(/\/\d+\/?$/i, '');
    const governmentId = Number.parseInt(props.params.governmentId, 10);

    return {
        isVendorAdmin: isVendorAdminUser(state),
        governmentId,
        disqualifyError: state.govProposals.get('disqualifyError'),
        disqualifying: state.govProposals.get('disqualifying'),
        project: getProjectJS(state),
        proposalsPath,
        updateError: state.govProposals.get('updateError'),
        updating: state.govProposals.get('updating'),
        addVendorsToListModalOpen: getShowAddVendorListModal(state),
        vendorUsersLists: getVendorUsersLists(state, props.proposal.user_id),
    };
};

const mapDispatchToProps = {
    createProposal,
    disqualifyProposal,
    excludeProposal,
    showConfirmationModal,
    showConfirmationSimpleModal,
    unsubmitProposal,
    showAddVendorsToListModal,
    loadVendorsLists,
    addOrRemoveVendors,
    updateQuestionnaireResponse,
};

// @connect
class ConnectedProposalReview extends Component {
    static propTypes = {
        isVendorAdmin: PropTypes.bool,
        createProposal: PropTypes.func.isRequired,
        governmentId: PropTypes.number.isRequired,
        disqualifyError: PropTypes.string,
        disqualifying: PropTypes.bool.isRequired,
        disqualifyProposal: PropTypes.func.isRequired,
        excludeProposal: PropTypes.func.isRequired,
        isEditable: PropTypes.bool.isRequired,
        isProposalEvaluation: PropTypes.bool,
        location: PropTypes.shape({
            query: PropTypes.shape({
                addAnother: PropTypes.string,
            }).isRequired,
        }).isRequired,
        module: PropTypes.string,
        project: PropTypes.shape({
            hasSealedBid: PropTypes.bool.isRequired,
            showBidsWithPricing: PropTypes.bool.isRequired,
        }),
        proposal: PropTypes.shape({
            id: PropTypes.number.isRequired,
            isDisqualified: PropTypes.bool.isRequired,
            isExcluded: PropTypes.bool.isRequired,
            isGovernmentSubmitted: PropTypes.bool.isRequired,
            isPricingUnsealed: PropTypes.bool.isRequired,
            user_id: PropTypes.number,
        }),
        proposalsPath: PropTypes.string.isRequired,
        showConfirmationModal: PropTypes.func.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        unsubmitProposal: PropTypes.func.isRequired,
        updateError: PropTypes.string,
        updating: PropTypes.bool.isRequired,
        updateQuestionnaireResponse: PropTypes.func.isRequired,
        showAddVendorsToListModal: PropTypes.func.isRequired,
        addVendorsToListModalOpen: PropTypes.bool.isRequired,
        loadVendorsLists: PropTypes.func.isRequired,
        vendorUsersLists: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                id: PropTypes.number.isRequired,
            })
        ),
        addOrRemoveVendors: PropTypes.func.isRequired,
    };

    get disqualificationSectionProps() {
        const {
            proposal: { isDisqualified },
        } = this.props;

        if (isDisqualified) {
            return [
                'Undo disqualification if the response has been updated to meet minimum requirements and should be included in the evaluation and selection process.',
                'UNDO DISQUALIFICATION',
                'info',
                'fa-check-circle',
                'Undo Disqualification',
                this.undoDisqualification,
            ];
        }
        return [
            'Disqualify the response when it does not meet minimum requirements and should be removed from the evaluation and selection process. Vendors will see when their response has been disqualified.',
            'DISQUALIFY RESPONSE',
            'danger',
            'fa-ban',
            'Disqualify Response',
            this.disqualifyProposal,
        ];
    }

    get excludeSectionProps() {
        const {
            proposal: { isExcluded },
        } = this.props;

        if (isExcluded) {
            return [
                'Undo exclusion if the response should be a part of the evaluation process going forward.',
                'UNDO EXCLUSION',
                'info',
                'fa-undo',
                'Undo Exclusion',
                () => this.excludeProposalHandler(false),
            ];
        }
        return [
            'Exclude the response when it will not be part of future evaluation scoring or considered for award. Excluded responses will still appear on scorecards, but will have their scores locked. Vendors will not see when their response has been excluded.',
            'EXCLUDE RESPONSE FROM FURTHER EVALUATION',
            'danger',
            'fa-minus-circle',
            'Exclude Response',
            () => this.excludeProposalHandler(true),
        ];
    }

    get styles() {
        return require('./index.scss');
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    disqualifyProposal = () => {
        const { proposal } = this.props;

        this.props.showConfirmationModal(DISQUALIFY_PROPOSAL, { proposal });
    };

    excludeProposalHandler = (isExcluded) => {
        const { proposal } = this.props;

        const data = { isExcluded };
        this.props.showConfirmationSimpleModal(() => this.props.excludeProposal(proposal, data), {
            bsStyle: isExcluded ? 'danger' : 'primary',
            btnText: isExcluded ? 'Exclude Response' : 'Undo Exclusion',
            icon: isExcluded ? 'minus-circle' : 'undo',
            text: `Are you sure you want to ${
                isExcluded ? 'exclude' : 'undo the exclusion of'
            } this response?`,
        });
    };

    handleAddProposalClick = () => {
        const { project, proposalsPath } = this.props;

        this.props.createProposal(project, proposalsPath);
    };

    undoDisqualification = () => {
        const { proposal } = this.props;

        const data = { isDisqualified: false };
        this.props.showConfirmationSimpleModal(
            () => this.props.disqualifyProposal(proposal, data),
            {
                bsStyle: 'primary',
                btnText: 'Undo Disqualification',
                icon: 'undo',
                text: 'Are you sure you want to undo the disqualification of this response?',
            }
        );
    };

    unsubmitProposal = () => {
        const { proposal, proposalsPath } = this.props;

        return this.props.unsubmitProposal(proposal, `${proposalsPath}/${proposal.id}/edit`);
    };

    renderAddProposalButton() {
        const {
            location: {
                query: { addAnother },
            },
        } = this.props;

        if (!addAnother) {
            return null;
        }

        return (
            <div className={this.styles.addProposalBtn}>
                <Button bsSize="sm" bsStyle="success" onClick={this.handleAddProposalClick}>
                    <i className="fa fa-plus" /> Add Another Response
                </Button>
            </div>
        );
    }

    renderDisqualifySection() {
        const { disqualifyError, disqualifying } = this.props;

        const [text, title, bsStyle, icon, btnText, onClick] = this.disqualificationSectionProps;
        const btnIcon = disqualifying ? 'fa-spin fa-spinner' : icon;

        return (
            <div className={`${this.styles.section} no-print`}>
                <div className={this.styles.sectionHeader}>{title}</div>
                <div className={this.styles.sectionItem}>
                    <div className="row">
                        <div className={`col-md-5 ${this.styles.label}`}>{text}</div>
                        <div className="col-md-7">
                            <HoverButton
                                disabled={disqualifying}
                                hoverStyle={bsStyle}
                                onClick={onClick}
                            >
                                <i className={`fa ${btnIcon}`} /> {btnText}
                            </HoverButton>
                            {disqualifyError && (
                                <div className="error-block">{disqualifyError}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        const { governmentId, proposal, isVendorAdmin } = this.props;

        const proposalUserId = get(proposal, 'user_id');

        // Government submitted proposals will not have a user ID
        if (proposalUserId && isVendorAdmin) {
            this.props.loadVendorsLists(governmentId, proposalUserId);
        }
    }

    renderVendorLists() {
        const {
            isVendorAdmin,
            governmentId,
            vendorUsersLists,
            proposal: { user_id: proposalUserId },
        } = this.props;

        const button = (
            <HoverButton
                hoverStyle="primary"
                onClick={() => this.props.showAddVendorsToListModal(governmentId)}
            >
                <i className="fa fa-plus" /> Add Vendor to List
            </HoverButton>
        );

        let lists;

        if (!isVendorAdmin) {
            lists = <li>Only accessible to vendor admins</li>;
        } else if (!vendorUsersLists.length) {
            lists = <li>No Lists</li>;
        } else {
            lists = vendorUsersLists.map((item) => {
                return (
                    <li key={`vendor-list-${item.id}`}>
                        {item.name}
                        {isVendorAdmin && (
                            <a
                                onClick={() => {
                                    this.props.addOrRemoveVendors({
                                        actionType: 'remove',
                                        governmentId,
                                        userIds: [proposalUserId],
                                        vendorListId: item.id,
                                    });
                                }}
                            >
                                {' '}
                                (Remove)
                            </a>
                        )}
                    </li>
                );
            });
        }

        return (
            <ul className={this.styles.vendorListUl}>
                {lists}
                {isVendorAdmin && <li>{button}</li>}
            </ul>
        );
    }

    renderVendorListSection() {
        const {
            governmentId,
            proposal: { user_id: proposalUserId },
        } = this.props;

        return (
            <div className={`${this.styles.section} no-print`}>
                <div className={this.styles.sectionHeader}>VENDOR LISTS</div>
                <div className={this.styles.sectionItem}>
                    <div className="row">
                        <div className={`col-md-5 ${this.styles.label}`}>
                            This user is on the following lists.
                        </div>
                        <div className="col-md-7">
                            {this.renderVendorLists()}
                            {this.props.addVendorsToListModalOpen && (
                                <AddVendorsToListModal
                                    governmentId={governmentId}
                                    initialValues={{
                                        userIds: [proposalUserId],
                                    }}
                                    noUserSelection
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderExcludeSection() {
        const [text, title, bsStyle, btnIcon, btnText, onClick] = this.excludeSectionProps;

        return (
            <div className={`${this.styles.section} no-print`}>
                <div className={this.styles.sectionHeader}>{title}</div>
                <div className={this.styles.sectionItem}>
                    <div className="row">
                        <div className={`col-md-5 ${this.styles.label}`}>{text}</div>
                        <div className="col-md-7">
                            <HoverButton hoverStyle={bsStyle} onClick={onClick}>
                                <i className={`fa ${btnIcon}`} /> {btnText}
                            </HoverButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderReviseSection() {
        const {
            proposal: { isGovernmentSubmitted },
            updateError,
            updating,
        } = this.props;

        if (!isGovernmentSubmitted) {
            return null;
        }

        const icon = updating ? 'fa-spin fa-spinner' : 'fa-undo';

        return (
            <div className={`${this.styles.section} no-print`}>
                <div className={this.styles.sectionHeader}>REVISE RESPONSE</div>
                <div className={this.styles.sectionItem}>
                    <div className="row">
                        <div className={`col-md-5 ${this.styles.label}`}>
                            To revise the response, use the Unsubmit button. After editing, submit
                            the response again, so it can be reviewed.
                        </div>
                        <div className="col-md-7">
                            <HoverButton
                                disabled={updating}
                                hoverStyle="danger"
                                onClick={this.unsubmitProposal}
                            >
                                <i className={`fa ${icon}`} /> Unsubmit Response
                            </HoverButton>
                            {updateError && <div className="error-block">{updateError}</div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { isEditable, isProposalEvaluation, module, project, proposal, proposalsPath } =
            this.props;

        /**
         * The proposal will always be loaded on normal navigation, but on back
         * button nav the components get rendered before data is re-rendered
         * Possibly related:
         * https://github.com/ReactTraining/react-router/issues/5072
         */
        if (!proposal) {
            return null;
        }

        const viewPath = `${proposalsPath}/${proposal.id}/documents`;
        const supplementalsPath = `${proposalsPath}/${proposal.id}/supplementals`;
        const vendorUrl = `${proposalsPath}/${proposal.id}/vendor`;

        return (
            <Main>
                <div className="row">
                    <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
                        {!isProposalEvaluation && (
                            <>
                                {this.renderAddProposalButton()}
                                <BackButton text="Back to Responses" to={proposalsPath} />
                            </>
                        )}
                        <ProposalDetails
                            canGovUserEdit={isEditable}
                            displayExcludedWell
                            displayPricingUnsealedInfo={project.hasSealedBid}
                            documentsViewerUrl={viewPath}
                            module={module}
                            project={project}
                            proposal={proposal}
                            showReportButton
                            showVendorActivityButton
                            supplementalsViewerUrl={supplementalsPath}
                            updateQuestionnaireResponse={this.props.updateQuestionnaireResponse}
                            vendorUrl={vendorUrl}
                        />
                        {isEditable && this.renderDisqualifySection()}
                        {/* Govt submitted proposals have no vendor, so can't be added to lists */}
                        {!proposal.isGovernmentSubmitted && this.renderVendorListSection()}
                        {isEditable && this.renderExcludeSection()}
                        {isEditable && this.renderReviseSection()}
                    </div>
                </div>
            </Main>
        );
    }
}

export const ProposalReview = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProposalReview);

// Government sourcing container
ProposalReview.Sourcing = connect((state) => {
    return {
        isEditable: areProposalsEditable(state),
        module: moduleTypesDict.SOURCING,
        proposal: getProposalJS(state),
    };
})(ProposalReview);

// Government evaluation container
ProposalReview.Evaluation = connect((state) => {
    return {
        isEditable: areProposalsEditable(state),
        module: moduleTypesDict.EVALUATION,
        proposal: getProposalJS(state),
    };
})(ProposalReview);

// Government proposal evaluation container
ProposalReview.ProposalEvaluation = connect((state) => {
    return {
        isEditable: false,
        isProposalEvaluation: true,
        proposal: getProposalForEvaluationJS(state),
    };
})(ProposalReview);
