import { resourceManager } from '../helpers';
import { requisitionSearchModesNames } from '../../../shared_config/requisitions';

const { BASIC } = requisitionSearchModesNames;

export const basicRequisitionsSearch = (searchData) => {
    return resourceManager({
        method: 'post',
        url: '/requisitions/requisitions/search',
        requestOptions: {
            data: {
                ...searchData,
                mode: BASIC,
            },
        },
    });
};
