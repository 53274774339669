const { listToDict } = require('../helpers');

exports.attachmentTypes = [
    'addendum',
    'contractDocument',
    'docxTemplate',
    'functional',
    'notice',
    'other',
    'pricing',
    'projectDocument',
    'proposalDocument',
    'proposalSupplementalDocument',
    'questionnaire',
    'standardDocument',
    'substitutionAttachment',
    'internal',
    'requisitionAttachment',
];
exports.attachmentTypesDict = listToDict(exports.attachmentTypes);

exports.internalUrlAttachmentTypes = new Set([exports.attachmentTypesDict.ADDENDUM]);
