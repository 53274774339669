import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';
import PropTypes from 'prop-types';
import { Box, Typography } from '@og-pro/ui';

import { attachmentTypesDict } from '../../../../../shared_config/attachments';
import { getReviewProjectJS as getGovernmentProjectJS, isAttachmentsEditable } from '../selectors';
import { getWritingPath } from '../App/selectors';

const List = ({ attachments, emptyMessage, addMessage, url }) => {
    const styles = require('./index.scss');

    if (!attachments.length) {
        return (
            <Box className={styles.emptyMessage} mt={1}>
                {emptyMessage}&nbsp;
                {!!url && (
                    <>
                        <Link to={url}>{addMessage}&nbsp;</Link> to see it here.
                    </>
                )}
            </Box>
        );
    }

    return (
        <Box className={styles.attachments}>
            {attachments.map((attachment) => (
                <Box className={styles.attachment} key={attachment.id}>
                    <a href={attachment.url} rel="noreferrer" target="_blank">
                        <i className="fa fa-file-pdf-o" /> {attachment.name}
                    </a>
                </Box>
            ))}
        </Box>
    );
};

List.propTypes = {
    addMessage: PropTypes.string.isRequired,
    attachments: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    emptyMessage: PropTypes.string.isRequired,
    url: PropTypes.string,
};

export const AttachmentsList = withRouter(({ location, params }) => {
    const project = useSelector(getGovernmentProjectJS);
    const url = useSelector((state) =>
        getWritingPath(state, { isIntake: project.isIntake, location, params })
    );
    const editable = useSelector(isAttachmentsEditable);
    const styles = require('./index.scss');

    const attachments = project.attachments || [];
    const projectDocs = attachments.filter(
        (a) => a.type === attachmentTypesDict.PROJECT_DOCUMENT && !a.isHiddenByLogic
    );
    const other = attachments.filter(
        (a) =>
            (a.type === attachmentTypesDict.OTHER || a.type === attachmentTypesDict.FUNCTIONAL) &&
            !a.isHiddenByLogic
    );
    const internal = attachments.filter(
        (a) => a.type === attachmentTypesDict.INTERNAL && !a.isHiddenByLogic
    );

    return (
        <div className="row">
            <div className="col-xs-12 col-md-8 col-md-offset-2">
                <Box className={styles.container}>
                    <Typography align="center" variant="h2">
                        Attachments
                    </Typography>
                    <Box className={styles.subtitle} textAlign="center">
                        Manage all documents that should be included with this project.
                    </Box>

                    {project.template.omitDocx && !project.isIntake && (
                        <Box mt={4}>
                            <Typography variant="h3">Main Document</Typography>
                            <List
                                addMessage="Add a document"
                                attachments={projectDocs}
                                emptyMessage="No Documents Uploaded."
                                url={editable ? url : null}
                            />
                        </Box>
                    )}

                    {!project.isIntake && (
                        <Box mt={4}>
                            <Typography variant="h3">Supporting Attachments</Typography>
                            <List
                                addMessage="Add an attachment"
                                attachments={other}
                                emptyMessage="No Attachments Uploaded."
                                url={editable ? url : null}
                            />
                        </Box>
                    )}

                    {(editable || internal.length > 0) && (
                        <Box mb={4} mt={4}>
                            <Box display="flex" justifyContent="space-between">
                                <Box>
                                    <Typography variant="h3">Internal Attachments</Typography>
                                </Box>
                                <Box textAlign="right">
                                    <Box className={styles.internalLabel} component="span">
                                        <i className="fa fa-lock" /> Internal Only
                                    </Box>
                                </Box>
                            </Box>
                            <Box className={styles.emptyMessage}>
                                These documents are for internal use only. The vendor will not see
                                them.
                            </Box>
                            <List
                                addMessage="Add an internal attachment"
                                attachments={internal}
                                emptyMessage="No Internal Attachments Uploaded."
                                url={editable ? url : null}
                            />
                        </Box>
                    )}
                </Box>
            </div>
        </div>
    );
});
