import { statusTypes } from '../../../../../../../../shared_config/requisitions';

const getStepStatusData = (isDraftRejected) => {
    if (isDraftRejected) {
        return ['royal', 'fa-undo', 'Request Returned'];
    }
    return ['success', 'fa-check', 'Request Submitted'];
};

export const stepStatusHelper = (requisition) => {
    const isDraftRejected = requisition.status === statusTypes.DRAFT_REJECTED;
    const [className, icon, stepName] = getStepStatusData(isDraftRejected);
    return {
        className,
        icon,
        stepName,
    };
};
