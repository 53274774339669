import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { initialize, reduxForm } from 'redux-form';
import { groupBy } from 'lodash';

import { Box } from '@mui/material';

import { useQueryParam } from '../../../../../../hooks';
import { RequisitionListDetailedFilters } from './DetailedFilters';
import { RequisitionListQuickFilters } from './QuickFilters';
import { RequisitionsListSearch } from './Search';
import { RequisitionListSorting } from './Sorting';
import { ChartSelection } from './ChartSelection';
import { formConfig } from '../../form';
import { buildSortingOptionValue } from './constants';
import { fieldNames } from '../../constants';
import { dateFilterTypesDict, dateTypes } from '../../../../../../components/DateFilter/constants';
import { RequisitionsListExport } from '../RequisitionsListExport';
import { useChartInteraction } from '../../Visualizations/hooks/useChartInteraction';

const { SORTING, ONE_DATE_TYPE, ON, DATE_TYPE, START, END, BEFORE, AFTER, CREATORS, LAST_ACTIONS } =
    fieldNames;
const { ONE_DATE, DATE_RANGE } = dateFilterTypesDict;

const parseFilters = (paramFilters) => {
    const filters = groupBy(paramFilters, 'filter');

    // Set the value directly to date filters
    Object.keys(filters).forEach((key) => {
        if (dateTypes.includes(key)) {
            const value = filters[key][0].value;
            filters[key] = new Date(value);
        }
    });

    return filters;
};

const ConnectedRequisitionListFilters = () => {
    const [params] = useQueryParam();

    const dispatch = useDispatch();

    const getOneDateType = (filters) => {
        let defaultOneDateType = ON;

        if (filters[BEFORE]) {
            defaultOneDateType = BEFORE;
        }

        if (filters[AFTER]) {
            defaultOneDateType = AFTER;
        }

        return defaultOneDateType;
    };

    useEffect(() => {
        const filters = parseFilters(params.filters);

        const initialValues = {
            [ONE_DATE_TYPE]: getOneDateType(filters),
            [DATE_TYPE]: filters[START] || filters[END] ? DATE_RANGE : ONE_DATE,
            [CREATORS]: [],
            [LAST_ACTIONS]: [],
        };

        const sorting = buildSortingOptionValue(params.sort, params.sortDirection);

        dispatch(
            initialize(formConfig.form, {
                ...initialValues,
                ...params,
                [SORTING]: sorting,
                ...filters,
            })
        );
    }, [JSON.stringify(params)]);

    const { hasChartInteraction } = useChartInteraction();

    return (
        <Box
            alignItems="center"
            display="flex"
            pb={2}
            sx={{
                flexDirection: { sm: 'column', md: 'row' },
                gap: { sm: 1, md: 4 },
            }}
        >
            <Box
                sx={{
                    width: { sm: '100%', md: '25%' },
                }}
            >
                {hasChartInteraction ? <ChartSelection /> : <RequisitionListQuickFilters />}
            </Box>

            <Box
                alignItems="center"
                display="flex"
                sx={{
                    width: { sm: '100%', md: '75%' },
                    justifyContent: { sm: 'flex-start', md: 'flex-end' },
                    gap: { sm: 1, lg: 2 },
                }}
            >
                <RequisitionListSorting />
                <RequisitionListDetailedFilters />
                <RequisitionsListSearch />
                <RequisitionsListExport />
            </Box>
        </Box>
    );
};

export const RequisitionListFilters = reduxForm(formConfig)(ConnectedRequisitionListFilters);
