import React from 'react';
import { Box } from '@og-pro/ui';

import { SignatureForm } from '../../../../components';
import { fieldNames, projectFormProps } from '../constants';

const { SIGNATURES } = fieldNames;

export const Signature = (props) => {
    const {
        projectSection: { id, projectSubsections },
    } = props;
    const [projectSubsection] = projectSubsections;
    const formKey = `${SIGNATURES}.${id}_${projectSubsection.id}`;

    return (
        <Box>
            <SignatureForm {...props} formKey={formKey} showValidation={props.showFormErrors} />
        </Box>
    );
};

Signature.propTypes = projectFormProps;
