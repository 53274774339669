import { createSelector } from 'reselect';

const requisitionGroupNavItems = [
    {
        path: '',
        title: 'Requisition Groups',
    },
    {
        path: '/sequences',
        title: 'Review Sequences',
    },
];

const getSelectedRequisitionGroupNavItem = createSelector(
    [(state, props) => props.location.pathname],
    (pathname) => {
        return (
            requisitionGroupNavItems.find((navItem) => {
                return !!navItem.path && pathname.match(navItem.path);
            }) || requisitionGroupNavItems[0]
        );
    }
);

export const getNavItems = createSelector(
    [getSelectedRequisitionGroupNavItem],
    (selectedNavItem) => {
        return requisitionGroupNavItems.map((navItem) => {
            return {
                ...navItem,
                isActive: navItem.title === selectedNavItem.title,
            };
        });
    }
);
