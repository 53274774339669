import { isFunction, upperCase } from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { CategoryCodes, DataTable, StatusLabel, Tooltip, ZeroState } from '../..';
import { statusClass } from '../../helpers/statusHelper';
import { isEmbedded } from '../../../helpers';
import { projectStatusesDict } from '../../../../../shared_config/projects';

const { CLOSED } = projectStatusesDict;

// @withRouter
class ProjectListWithRouter extends PureComponent {
    static propTypes = {
        dataTableProps: PropTypes.shape({
            defaultPageSize: PropTypes.number,
            onPageChange: PropTypes.func,
            onPageSizeChange: PropTypes.func,
            page: PropTypes.number,
            pages: PropTypes.number,
            pageSize: PropTypes.number,
            pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
        }),
        government: PropTypes.shape({
            organization: PropTypes.shape({
                name: PropTypes.string.isRequired,
                timezone: PropTypes.string.isRequired,
                state: PropTypes.string.isRequired,
            }).isRequired,
        }),
        includeLogo: PropTypes.bool,
        location: PropTypes.object.isRequired,
        projectPath: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
        projects: PropTypes.arrayOf(
            PropTypes.shape({
                addendums: PropTypes.array,
                categories: PropTypes.array,
                closeOutReason: PropTypes.string,
                government: PropTypes.shape({
                    organization: PropTypes.shape({
                        name: PropTypes.string.isRequired,
                        timezone: PropTypes.string.isRequired,
                    }),
                }).isRequired,
                id: PropTypes.number.isRequired,
                proposalDeadline: PropTypes.string,
                releaseProjectDate: PropTypes.string,
                status: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
            }).isRequired
        ),
        router: PropTypes.object,
        showAddendaCount: PropTypes.bool,
        showCategories: PropTypes.bool,
        showOrg: PropTypes.bool,
        showProjectId: PropTypes.bool,
    };

    static defaultProps = {
        projects: [],
    };

    get styles() {
        return require('./index.scss');
    }

    handleProjectRowClick = (project) => {
        if (project.comingSoon) {
            return;
        }
        const { location, projectPath, router } = this.props;

        let path;
        if (typeof projectPath === 'string') {
            path = projectPath;
        } else {
            path = projectPath(project);
        }

        if (isEmbedded(location)) {
            window.open(`${process.env.SITE_URL}${projectPath}/${project.id}`);
        } else {
            router.push(`${path}/${project.id}`);
        }
    };

    renderDateCell = ({ original, value }) => {
        if (!value) {
            return '-';
        }
        const { government } = this.props;

        const governmentOrganization = original.government.organization || government.organization;
        const tz = governmentOrganization.timezone;
        return moment.tz(value, tz).format('l');
    };

    render() {
        const {
            dataTableProps,
            government,
            includeLogo,
            projects,
            showAddendaCount,
            showCategories,
            showOrg,
            showProjectId,
        } = this.props;

        if (projects.length === 0) {
            return (
                <ZeroState info="Please try a different search query" title="No Projects Found" />
            );
        }

        /**
         * `minResizeWidth` needed to handle resizing bug:
         * https://github.com/tannerlinsley/react-table/issues/1281#issuecomment-482075142
         */
        const columns = [
            {
                Header: 'Project Title',
                accessor: 'title',
                minResizeWidth: 50,
                minWidth: 240,
                className: this.styles.rowWrapText,
                Cell: (props) => (
                    <>
                        {includeLogo && (
                            <img
                                alt={`${
                                    (props.original.government || government).organization.name
                                } logo`}
                                className={`img-rounded ${this.styles.logo}`}
                                src={(props.original.government || government).organization.logo}
                            />
                        )}
                        <div className={includeLogo ? this.styles.logoText : undefined}>
                            <a
                                className={this.styles.hiddenLink}
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.handleProjectRowClick(props.original);
                                }}
                            >
                                {props.value}
                            </a>
                            <br />
                            <StatusLabel
                                bsStyle={statusClass(props.original.status)}
                                className={this.styles.statusLabel}
                            >
                                {upperCase(props.original.status)}
                            </StatusLabel>
                            {props.original.isPaused && (
                                <Tooltip isPopover tooltip="On Hold">
                                    <i className={`fa fa-clock-o ${this.styles.pauseIcon}`} />
                                </Tooltip>
                            )}
                            {props.original.status === CLOSED &&
                                !!props.original.closeOutReason && (
                                    <Tooltip isPopover tooltip={props.original.closeOutReason}>
                                        <i
                                            className={`fa fa-exclamation-triangle ${this.styles.closeOutIcon}`}
                                        />
                                    </Tooltip>
                                )}
                        </div>
                    </>
                ),
            },
        ];

        if (showOrg) {
            columns.push({
                Header: 'Organization',
                minResizeWidth: 10,
                accessorKey: 'government.organization.name',
                id: 'government.organization.name',
                className: 'text-center',
                accessor: (row) => {
                    const governmentOrganization =
                        row.government.organization || government.organization;
                    return governmentOrganization.name;
                },
            });

            columns.push({
                Header: 'State',
                maxWidth: 60,
                accessorKey: 'government.organization.state',
                id: 'government.organization.state',
                className: 'text-center',
                accessor: (row) => {
                    return row.government.organization.state || government.organization.state;
                },
            });
        }

        if (showProjectId) {
            columns.push({
                Header: 'Project ID',
                accessor: 'financialId',
                minResizeWidth: 10,
                className: 'text-center',
            });
        }

        if (showCategories) {
            columns.push({
                Header: 'Category',
                accessor: 'categories',
                minResizeWidth: 10,
                className: this.styles.rowWrapText,
                Cell: (props) => <CategoryCodes codes={props.value} limit={2} />,
            });
        }

        if (showAddendaCount) {
            columns.push({
                Header: 'Addenda',
                id: 'addendaCount',
                accessor: (row) => (row.addendums ? (row.addendums || []).length : '-'),
                maxWidth: 100,
                minResizeWidth: 10,
                className: 'text-center',
                sortable: false,
            });
        }

        columns.push(
            {
                Header: 'Release Date',
                accessor: 'releaseProjectDate',
                maxWidth: 130,
                minResizeWidth: 10,
                className: 'text-center',
                Cell: this.renderDateCell,
            },
            {
                Header: 'Due Date',
                accessor: 'proposalDeadline',
                maxWidth: 130,
                minResizeWidth: 10,
                className: 'text-center',
                Cell: this.renderDateCell,
            }
        );

        return (
            <DataTable
                className="-striped -highlight"
                columns={columns}
                data={projects}
                getTrProps={(state, rowInfo) => {
                    // Bug in `react-table` passes undefined as rowInfo for extra rows with no data
                    // https://github.com/tannerlinsley/react-table/issues/1023
                    if (!rowInfo) {
                        return {};
                    }
                    return {
                        onClick: (e, handleOriginal) => {
                            this.handleProjectRowClick(rowInfo.original);

                            // 2/16/18: handleOriginal is not always a function:
                            // https://github.com/react-tools/react-table/issues/406
                            if (isFunction(handleOriginal)) return handleOriginal();
                        },
                        className: this.styles.projectRow,
                    };
                }}
                {...dataTableProps}
            />
        );
    }
}

export const ProjectList = withRouter(ProjectListWithRouter);
