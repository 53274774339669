import { isNil } from 'lodash';

import { fieldNames, OTHER } from './constants';

const { REJECTION_NOTE, REJECTION_NOTE_EXTRA, REJECTION_POLICY } = fieldNames;

export const validate = (values, props) => {
    const { requireRejectionNote } = props;
    const errors = {};

    if (isNil(values[REJECTION_POLICY])) {
        errors[REJECTION_POLICY] = 'Please select an option';
    }

    if (requireRejectionNote && !values[REJECTION_NOTE]) {
        errors[REJECTION_NOTE] = 'Please select an option';
    }

    if (values[REJECTION_NOTE] === OTHER) {
        if (requireRejectionNote && !values[REJECTION_NOTE_EXTRA]) {
            errors[REJECTION_NOTE_EXTRA] = 'Please provide a reason for rejecting the request';
        }
        if (values[REJECTION_NOTE_EXTRA]?.length > 1000) {
            errors[REJECTION_NOTE_EXTRA] = 'Note is too long (1000 character limit)';
        }
    }

    return errors;
};
