import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Box, Typography } from '@og-pro/ui';

import { FinalReviewChecklistSectionStatus } from './Status';

export const FinalReviewChecklistSection = ({
    last,
    incomplete,
    incompleteFields,
    isChild,
    section,
    onClick,
}) => {
    const styles = require('./index.scss');

    return (
        <Box
            className={classnames(styles.section, { [styles.borderBottom]: !last })}
            onClick={onClick}
        >
            <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                pl={isChild ? 5 : 0}
                pr={isChild ? 3 : 0}
            >
                <Box>
                    {section.manualNumber ? (
                        <Box>
                            <Typography variant="h4">{section.manualNumber}</Typography>
                            <div>{section.title}</div>
                        </Box>
                    ) : (
                        <Typography variant="h4">
                            {section.numbering && <>{section.numbering} </>}
                            <Box component="span" fontWeight={isChild ? 400 : 500}>
                                {section.title}
                            </Box>
                        </Typography>
                    )}
                </Box>
                <FinalReviewChecklistSectionStatus
                    incomplete={incomplete || section.showValidationError}
                    incompleteFields={incompleteFields}
                />
            </Box>
        </Box>
    );
};

FinalReviewChecklistSection.propTypes = {
    last: PropTypes.bool,
    incomplete: PropTypes.bool,
    incompleteFields: PropTypes.number,
    isChild: PropTypes.bool,
    onClick: PropTypes.func,
    section: PropTypes.shape({
        title: PropTypes.string,
        numbering: PropTypes.string,
        manualNumber: PropTypes.string,
        showValidationError: PropTypes.bool,
    }).isRequired,
};
