import { timelineNormalizer } from '../../../shared_config/timelines/utils';

export function criteriaMapNormalizer(project) {
    const { criteria } = project;
    if (!criteria || criteria.length === 0) {
        return { criteriaMap: {} };
    }

    const criteriaMap = criteria.reduce((obj, item) => {
        const criteriaKey = `${item.project_section_id}.${item.project_subsection_id}`;
        if (!obj[criteriaKey]) {
            obj[criteriaKey] = [];
        }

        // Do not render criteria that still need to be reviewed
        if (item.needsReview && !item.isReviewed) {
            return obj;
        }

        obj[criteriaKey].push(item);

        return obj;
    }, {});
    return { criteriaMap };
}

export function sectionDescriptionsMapNormalizer(project) {
    const { sectionDescriptions } = project;
    if (!sectionDescriptions || sectionDescriptions.length === 0) {
        return { sectionDescriptions: {} };
    }

    const sectionDescriptionsMap = sectionDescriptions.reduce((obj, item) => {
        if (!obj[item.project_section_id]) {
            obj[item.project_section_id] = {};
        }
        obj[item.project_section_id][item.project_subsection_id] = item;

        return obj;
    }, {});
    return { sectionDescriptions: sectionDescriptionsMap };
}

export function normalizeProject(project) {
    return {
        ...project,
        ...criteriaMapNormalizer(project),
        ...sectionDescriptionsMapNormalizer(project),
        ...timelineNormalizer(project),
    };
}
