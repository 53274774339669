import PropTypes from 'prop-types';
import React from 'react';

import { MultiPositionApproval } from './MultiPositionApproval';
import { SinglePositionApproval } from './SinglePositionApproval';

export const StepApproval = ({ minPositionReviews, stepApproval }) => {
    const { stepPositionApprovals } = stepApproval;

    if (stepPositionApprovals.length === 1) {
        return <SinglePositionApproval stepPositionApproval={stepPositionApprovals[0]} />;
    }

    return (
        <MultiPositionApproval
            minPositionReviews={minPositionReviews}
            stepPositionApprovals={stepPositionApprovals}
        />
    );
};

StepApproval.propTypes = {
    minPositionReviews: PropTypes.number.isRequired,
    stepApproval: PropTypes.shape({
        stepPositionApprovals: PropTypes.array.isRequired,
    }).isRequired,
};
