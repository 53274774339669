import React from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import { Box } from '@og-pro/ui';
import { useDispatch } from 'react-redux';
import { arrayMove, FieldArray } from 'redux-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { getDndStyle } from '../../../constants/styles';
import { DragIcon } from '../../DragIcon';
import { CDSDropdownButton } from '../../SDv2';
import { ContentBlock } from '../../SDv2/ContentBlock';
import { Button } from '../../Button';
import { MenuItem } from '../../MenuItem/MenuItem';
import {
    blockFieldNames,
    defaultBlock,
    defaultSignatureHeaderBlock,
    fieldNames,
} from './constants';
import { SignatureField } from './Field';
import { SignatureHeader } from './Header';

const { SIGNATURES_BLOCKS } = fieldNames;
const { FIELDS, PAGE_BREAK_AFTER, STYLING } = blockFieldNames;

const FieldsMap = ({ fields, form, disabled, showValidation, move }) => {
    const copyBlock = (index) => {
        const values = pick(fields.get(index), [FIELDS, PAGE_BREAK_AFTER, STYLING]);

        fields.push(values);
    };

    return (
        <>
            {fields.map((field, index) => (
                <Draggable draggableId={field} index={index} key={field}>
                    {(draggableProvided) => {
                        const styling = fields.get(index).styling;

                        return (
                            <Box
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                            >
                                <ContentBlock withActions>
                                    <ContentBlock.Main disabled={disabled}>
                                        {styling === 'default' ? (
                                            <FieldArray
                                                component={SignatureField}
                                                disabled={disabled}
                                                form={form}
                                                name={`${field}.${FIELDS}`}
                                                showValidation={showValidation}
                                            />
                                        ) : (
                                            <SignatureHeader
                                                disabled={disabled}
                                                name={`${field}.${FIELDS}`}
                                                showValidation={showValidation}
                                            />
                                        )}
                                    </ContentBlock.Main>
                                    <ContentBlock.ActionSidebar>
                                        <ContentBlock.ButtonGroup>
                                            {index > 0 && (
                                                <ContentBlock.Button>
                                                    <Button
                                                        bsStyle="link"
                                                        onClick={() => move(index, index - 1)}
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            className="fa fa-arrow-up"
                                                        />
                                                    </Button>
                                                </ContentBlock.Button>
                                            )}
                                            <ContentBlock.Button>
                                                <DragIcon
                                                    dragHandleProps={
                                                        draggableProvided.dragHandleProps
                                                    }
                                                />
                                            </ContentBlock.Button>
                                            {index < fields.length - 1 && (
                                                <ContentBlock.Button>
                                                    <Button
                                                        bsStyle="link"
                                                        onClick={() => move(index, index + 1)}
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            className="fa fa-arrow-down"
                                                        />
                                                    </Button>
                                                </ContentBlock.Button>
                                            )}
                                        </ContentBlock.ButtonGroup>
                                        <ContentBlock.ButtonGroup>
                                            <ContentBlock.Button>
                                                <Button
                                                    bsStyle="link"
                                                    onClick={() => copyBlock(index)}
                                                >
                                                    <i aria-hidden="true" className="fa fa-copy" />
                                                </Button>
                                            </ContentBlock.Button>
                                        </ContentBlock.ButtonGroup>
                                        <ContentBlock.ButtonGroup>
                                            <ContentBlock.Button>
                                                <Button
                                                    bsStyle="link"
                                                    onClick={() => fields.remove(index)}
                                                >
                                                    <i aria-hidden="true" className="fa fa-trash" />
                                                </Button>
                                            </ContentBlock.Button>
                                        </ContentBlock.ButtonGroup>
                                    </ContentBlock.ActionSidebar>
                                </ContentBlock>
                            </Box>
                        );
                    }}
                </Draggable>
            ))}
        </>
    );
};
FieldsMap.propTypes = {
    disabled: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    move: PropTypes.func.isRequired,
    showValidation: PropTypes.bool,
};

export const SignatureBlock = ({ disabled, fields, form, showValidation }) => {
    const dispatch = useDispatch();

    const move = (originLocation, newLocation) => {
        if (newLocation !== undefined && newLocation !== originLocation) {
            dispatch(arrayMove(form, fields.name, originLocation, newLocation));
        }
    };

    const handleDragEnd = (result) => {
        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        move(originLocation, newLocation);
    };

    return (
        <Box>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable
                    droppableId="signatureBlockList"
                    isDropDisabled={disabled}
                    type={SIGNATURES_BLOCKS}
                >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getDndStyle(snapshot)}
                            {...provided.droppableProps}
                        >
                            <FieldsMap
                                fields={fields}
                                move={move}
                                {...{ disabled, showValidation, form }}
                            />

                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Box pb={2}>
                <CDSDropdownButton
                    disabled={disabled}
                    size="small"
                    title={
                        <>
                            <i className="fa fa-plus" /> Add Block
                        </>
                    }
                    variant="secondary"
                >
                    <MenuItem
                        onClick={() => fields.push(defaultBlock)}
                        qaTag="signatureForm-addBlock"
                    >
                        Signature Block
                    </MenuItem>
                    <MenuItem
                        onClick={() => fields.unshift(defaultSignatureHeaderBlock)}
                        qaTag="timelineForm-addEvent"
                    >
                        Signature Block Header
                    </MenuItem>
                </CDSDropdownButton>
            </Box>
        </Box>
    );
};

SignatureBlock.propTypes = {
    disabled: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    // meta: PropTypes.object.isRequired,
    showValidation: PropTypes.bool,
};
