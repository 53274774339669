import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import { ActiveRequests } from './ActiveRequests';
import { NumberOfRequests } from './NumberOfRequests';
import { RequestsNeedingAttention } from './RequestsNeedingAttention';
import { getIsLoading, getLoadError } from '../selectors';
import { LoadingError, LoadingSpinner } from '../../../../../components';
import { useQueryParam } from '../../../../../hooks';
import { chartInteractionParamsDict } from './helpers/chartInteractions';
import {
    activeRequestsVisualization,
    numberOfRequestsVisualization,
    requestsNeedingAttentionVisualization,
} from './constants';

export const RequisitionsDashboardVisualizations = ({ requisitionsSummary }) => {
    const styles = require('./index.scss');
    const [params] = useQueryParam();
    const isLoading = useSelector(getIsLoading);
    const loadError = useSelector(getLoadError);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (loadError || !requisitionsSummary) {
        return <LoadingError error={loadError} />;
    }

    const charts = [
        {
            name: numberOfRequestsVisualization,
            data: requisitionsSummary.numberOfRequestsData,
            component: NumberOfRequests,
            param: chartInteractionParamsDict.OLDER_THAN,
        },
        {
            name: requestsNeedingAttentionVisualization,
            data: requisitionsSummary.requestsNeedingAttentionData,
            component: RequestsNeedingAttention,
            param: chartInteractionParamsDict.TYPE,
        },
        {
            name: activeRequestsVisualization,
            data: requisitionsSummary.activeRequestsData,
            component: ActiveRequests,
            param: chartInteractionParamsDict.STATUS,
        },
    ];

    const isChartDisabled = (param) => {
        // chart is disabled if any of the other charts have a query param set
        return Object.values(chartInteractionParamsDict).some((value) => {
            return value !== param && !!params[value];
        });
    };

    return (
        <div className="row">
            {charts.map(({ data, component: Component, param, name }) => (
                <div
                    className={classNames('col-xs-12 col-sm-4', {
                        [styles.disabled]: isChartDisabled(param),
                    })}
                    key={name}
                >
                    <Component data={data} />
                </div>
            ))}
        </div>
    );
};

RequisitionsDashboardVisualizations.propTypes = {
    requisitionsSummary: PropTypes.shape({
        numberOfRequestsData: PropTypes.array.isRequired,
        requestsNeedingAttentionData: PropTypes.array.isRequired,
        activeRequestsData: PropTypes.array.isRequired,
    }),
};
