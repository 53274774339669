import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AppSwitcher } from '@opengov/components-app-switcher';
import { compose } from 'redux';

import { LOGO_SRC, NAV_TYPE, OPENGOV_URL } from './constants';
import { GovNavbar } from './GovNavbar';
import { PublicNavbar } from './PublicNavbar';
import { VendorNavbar } from './VendorNavbar';
import { isEmbedded } from '../../helpers';
import { userStatusTypes } from '../../../../shared_config/users';
import { USE_OG_PLATFORM } from '../../constants';

import { GlobalNavbar } from './GlobalNavbar';
import { FLAGS, withFlags } from '../../lib/launchdarkly';

const { INVITED, RESET } = userStatusTypes;

export class ConnectedMasterNavbar extends PureComponent {
    static propTypes = {
        fluid: PropTypes.bool,
        getFlag: PropTypes.func.isRequired,
        hideItems: PropTypes.bool,
        isClientLoaded: PropTypes.bool.isRequired,
        isSystemAdmin: PropTypes.bool.isRequired,
        location: PropTypes.shape({
            query: PropTypes.shape({
                homepage: PropTypes.string,
            }).isRequired,
        }).isRequired,
        logout: PropTypes.func,
        user: PropTypes.object,
    };

    get styles() {
        return require('./MasterNavbar.scss');
    }

    navType() {
        const { user } = this.props;

        if (!user || [INVITED, RESET].includes(user.status)) {
            return NAV_TYPE.PUBLIC;
        }
        if (user.isVendor) {
            return NAV_TYPE.VENDOR;
        }

        return NAV_TYPE.GOV;
    }

    renderBrand() {
        const { location } = this.props;

        const baseBrand = (
            <Navbar.Brand>
                <img alt="OpenGov Procurement logo" src={LOGO_SRC} />
            </Navbar.Brand>
        );

        if (isEmbedded(location)) {
            const {
                location: {
                    query: { homepage },
                },
            } = this.props;

            const url = homepage || OPENGOV_URL;
            return (
                <a // eslint-disable-line react/jsx-no-target-blank
                    href={url}
                    rel={url === OPENGOV_URL ? undefined : 'noreferrer noopener'}
                    target="_blank"
                >
                    {baseBrand}
                </a>
            );
        }

        return <Link to="/">{baseBrand}</Link>;
    }

    renderNavItems() {
        const { isSystemAdmin, location, logout, user } = this.props;
        const navType = this.navType();

        if (navType === NAV_TYPE.PUBLIC) {
            return <PublicNavbar location={location} />;
        }
        if (navType === NAV_TYPE.VENDOR) {
            return (
                <VendorNavbar
                    isSystemAdmin={isSystemAdmin}
                    location={location}
                    logout={logout}
                    user={user}
                />
            );
        }
        return <GovNavbar location={location} logout={logout} user={user} />;
    }

    renderOgAppSwitcher() {
        const { isClientLoaded, user } = this.props;
        const navType = this.navType();
        const isGovUser = navType === NAV_TYPE.GOV;

        // only show for gov users
        if (user && isGovUser && isClientLoaded) {
            return (
                <div className={this.styles.appSwitcher}>
                    {/* The App Switcher styles are loaded thru CDN in the src/server/Html.js file, when upgrading this library remember to upgrade the CSS to the CDN too. */}
                    <AppSwitcher
                        activeProduct="procurement"
                        auth0Config={{
                            auth0clientID: process.env.AUTH0_CLIENT_ID,
                            auth0domain: process.env.AUTH0_URL,
                            auth0callbackUrl: `${process.env.SITE_URL}/login`,
                        }}
                        baseUrl={process.env.OG_CONTROL_PANEL_URL}
                        federatedId={user.government.code}
                    />
                </div>
            );
        }
    }

    render() {
        const { fluid, hideItems, getFlag } = this.props;
        const navType = this.navType();

        // Use Global Nav for Gov Users
        if (navType === NAV_TYPE.GOV && getFlag(FLAGS.ENABLE_GLOBAL_NAV_GOV_USERS)) {
            return <GlobalNavbar />;
        }

        return (
            <Navbar className={this.styles.opengovNavbar} collapseOnSelect fluid={fluid} staticTop>
                <Navbar.Header>
                    {USE_OG_PLATFORM && this.renderOgAppSwitcher()}
                    {this.renderBrand()}
                    {!hideItems && <Navbar.Toggle />}
                </Navbar.Header>
                {!hideItems && <Navbar.Collapse>{this.renderNavItems()}</Navbar.Collapse>}
            </Navbar>
        );
    }
}

export const MasterNavbar = compose(withFlags())(ConnectedMasterNavbar);
