import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@og-pro/ui';

import {
    AdditionalInformation,
    Attachments,
    GeneralInformation,
    PurchaseDetails,
    VendorSelection,
} from '../FormComponents';
import { qaTagPageName } from '../../constants';
import { getRequisitionsCreateSections } from '../../selectors';
import { Button } from '../../../../../components';
import {
    ADDITIONAL_INFORMATION,
    ATTACHMENTS,
    GENERAL_INFORMATION,
    PURCHASE_DETAILS,
    sectionsTypes,
    VENDOR_SELECTION,
} from '../../../../../constants/requisitionsCreate';
import {
    ReviewAdditionalInformation,
    ReviewAttachments,
    ReviewGeneralInformation,
    ReviewPurchaseDetails,
    ReviewVendorSelection,
} from '../ReviewComponents';
import {
    getShowMissingVendorAddressBanner,
    getShowMissingVendorBanner,
} from '../../../RequisitionsApproval/selectors';
import { SDv2ErrorContainer } from '../../../../../components/SDv2';
import { scrollToSection } from '../../utils';

const sectionComponents = {
    [GENERAL_INFORMATION]: [ReviewGeneralInformation, GeneralInformation],
    [PURCHASE_DETAILS]: [ReviewPurchaseDetails, PurchaseDetails],
    [VENDOR_SELECTION]: [ReviewVendorSelection, VendorSelection],
    [ADDITIONAL_INFORMATION]: [ReviewAdditionalInformation, AdditionalInformation],
    [ATTACHMENTS]: [ReviewAttachments, Attachments],
};

export const ApprovalReviewAndEdit = ({
    cancelHandler,
    change,
    disabled,
    editHandler,
    editingSectionType,
    vendorAssignmentModalSubmitHandler,
}) => {
    const sections = useSelector(getRequisitionsCreateSections);
    const showMissingVendorBanner = useSelector(getShowMissingVendorBanner);
    const showMissingVendorAddressBanner = useSelector(getShowMissingVendorAddressBanner);
    const reviewAndSubmitSharedStyles = require('../ReviewAndSubmit/index.scss');
    const sharedStyles = require('../shared.scss');
    const styles = require('./index.scss');

    const isNotEditingAnySections = !editingSectionType;

    const onJumpToVendorSelection = () => {
        scrollToSection(VENDOR_SELECTION);
    };

    return (
        <div className={reviewAndSubmitSharedStyles.container}>
            {(showMissingVendorBanner || showMissingVendorAddressBanner) && (
                <SDv2ErrorContainer>
                    <Box display="flex" flexDirection="row">
                        <i className={`${styles.warningIcon} fa fa-warning fa-2x`} />
                        <Box display="flex" flexDirection="column" marginLeft={2}>
                            <Typography variant="h4">Unable to Approve This Step</Typography>
                            {showMissingVendorAddressBanner
                                ? 'This step cannot be approved until all vendors have valid addresses on file in your accounting system.'
                                : 'This step cannot be approved until at least one vendor has been added to the request.'}
                            <Button
                                bsStyle="link"
                                className={styles.jumpToVendorSelectionButton}
                                onClick={onJumpToVendorSelection}
                                qaTag="requisitionFormError-jumpToVendorSelection"
                                zeroPadding
                            >
                                Jump to Vendor Selection
                            </Button>
                        </Box>
                    </Box>
                </SDv2ErrorContainer>
            )}
            {sectionsTypes.map((sectionKey) => {
                const section = sections[sectionKey];

                const [DisplayComponent, FormComponent] = sectionComponents[sectionKey] ?? [];

                if (!DisplayComponent || section.disabled) {
                    return null;
                }

                const showVendorSelection = sections[VENDOR_SELECTION].isEditable;
                const showForm = sectionKey === editingSectionType;
                const buttonIcon = showForm ? 'fa-times-circle' : 'fa-pencil-square-o';
                const buttonText = showForm ? 'Cancel' : 'Edit';
                const buttonClickHandler = showForm ? cancelHandler : editHandler;

                const renderDisplayComponent = () => {
                    const Component = (
                        <DisplayComponent
                            change={change}
                            editHandler={() => editHandler(sectionKey)}
                            isEditable={section.isEditable && isNotEditingAnySections}
                            showVendorSelection={showVendorSelection}
                            vendorAssignmentModalSubmitHandler={vendorAssignmentModalSubmitHandler}
                        />
                    );

                    return sectionKey === PURCHASE_DETAILS || sectionKey === VENDOR_SELECTION ? (
                        Component
                    ) : (
                        <div className={sharedStyles.approvalContainer}>{Component}</div>
                    );
                };

                const ComponentToUse = showForm ? (
                    <FormComponent
                        change={change}
                        disabled={disabled}
                        isApprovalView
                        showVendorSelection={showVendorSelection}
                    />
                ) : (
                    renderDisplayComponent()
                );

                return (
                    <React.Fragment key={sectionKey}>
                        <div className={reviewAndSubmitSharedStyles.formContainer} id={sectionKey}>
                            <div className={reviewAndSubmitSharedStyles.titleContainer}>
                                <h3>{section.name}</h3>
                                {section.isEditable && (isNotEditingAnySections || showForm) && (
                                    <Button
                                        bsStyle="link"
                                        className={reviewAndSubmitSharedStyles.linkButton}
                                        disabled={disabled}
                                        onClick={() => buttonClickHandler(sectionKey)}
                                        qaTag={`${qaTagPageName}-${buttonText.toLowerCase()}-${sectionKey}`}
                                        zeroPadding
                                    >
                                        <i className={`fa ${buttonIcon}`} /> {buttonText}
                                    </Button>
                                )}
                            </div>
                            {ComponentToUse}
                        </div>
                        {showMissingVendorBanner && sectionKey === VENDOR_SELECTION && (
                            <span className={styles.noVendorSelectedErrorMessage}>
                                <i className="fa fa-warning" />
                                &nbsp; At least one vendor must be added to approve this step.
                            </span>
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

ApprovalReviewAndEdit.propTypes = {
    cancelHandler: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    editHandler: PropTypes.func.isRequired,
    editingSectionType: PropTypes.string,
    vendorAssignmentModalSubmitHandler: PropTypes.func,
};
