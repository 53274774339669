import React from 'react';
import { tokens } from '@opengov/capital-style';
import { Box } from '@og-pro/ui';

const { colors } = tokens;

export const SharedTextareaToolbar = () => (
    <Box
        alignItems="center"
        bgcolor={colors.colorWhite}
        borderBottom={`1px solid ${colors.colorGray200}`}
        display="flex"
        id="SharedToolbarContainer"
        minHeight={56}
        position="sticky"
        px={1}
        top={0}
        zIndex={900}
    />
);
