import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { builderSectionFieldNames, editorSelectOptions, fieldNames } from '../../../constants';
import {
    Button,
    InputText,
    RichTextInput,
    OutlineButton,
    SearchSelect,
    SearchSelectIconOption,
    SearchSelectIconValue,
} from '../../../../../../../../components';
import { limitTextLength } from '../../../../../../../../Forms/normalizers';
import { MANUAL_NUMBER_CHAR_LIMIT } from '../../../../../../../../../../shared_config/manualNumber';

const { BUILDER_SECTION, IS_WRITING_FORM, MANUAL_NUMBER, SHORT_NAME, TITLE } = fieldNames;

const { INSTRUCTIONS } = builderSectionFieldNames;

export class ProjectSectionForm extends PureComponent {
    static propTypes = {
        canHaveInstructions: PropTypes.bool.isRequired,
        changeParentDivider: PropTypes.func,
        defaultSection: PropTypes.shape({
            isGeneral: PropTypes.bool.isRequired,
            isWritingFormEditable: PropTypes.bool,
            shortName: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        }).isRequired,
        disabled: PropTypes.bool,
        dividerDropdownOptions: PropTypes.arrayOf(
            PropTypes.shape({
                icon: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
            })
        ),
        hasInstructions: PropTypes.bool,
        index: PropTypes.number.isRequired,
        isDivider: PropTypes.bool.isRequired,
        shouldNotDelete: PropTypes.bool.isRequired,
        member: PropTypes.string.isRequired,
        onComplete: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        questionLogicIcon: PropTypes.node,
        showFormValidation: PropTypes.bool,
        sortUsingManualNumbering: PropTypes.func,
        useManualNumbering: PropTypes.bool.isRequired,
        useSectionDividers: PropTypes.bool.isRequired,
    };

    // Moving a section and sorting occurs on form closure hence the use of `componentWillUnmount`
    componentWillUnmount() {
        const { changeParentDivider, index, sortUsingManualNumbering, useManualNumbering } =
            this.props;
        const { newParentDividerIndex } = this.state;

        if (useManualNumbering) {
            if (newParentDividerIndex !== -1) {
                changeParentDivider(index, newParentDividerIndex);
            }

            sortUsingManualNumbering();
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            ...(props.useManualNumbering && {
                currentParentDividerIndex: this.parentDividerIndex,
                newParentDividerIndex: -1,
            }),
            showInstructionsForm: props.canHaveInstructions && props.hasInstructions,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    get isWritingFormDisabled() {
        const {
            defaultSection: { isGeneral, isWritingFormEditable },
            isDivider,
        } = this.props;

        return (!isGeneral && !isWritingFormEditable) || isDivider;
    }

    get isWritingFormHelpText() {
        const { isDivider } = this.props;

        if (this.isWritingFormDisabled) {
            if (isDivider) {
                return 'Dividers contain no editable content';
            }
            return 'Project Editors must be able to edit special use sections';
        }
        return 'Who can edit the section';
    }

    get parentDividerIndex() {
        const { dividerDropdownOptions, index, isDivider, useManualNumbering, useSectionDividers } =
            this.props;

        if ((!useManualNumbering && !useSectionDividers) || isDivider) {
            return -1;
        }

        let parentDividerIndex = 0;

        for (let i = 0; i < dividerDropdownOptions.length; i++) {
            const currentDividerIndex = dividerDropdownOptions[i].value;
            if (currentDividerIndex > index) {
                break;
            }

            parentDividerIndex = currentDividerIndex;
        }

        return parentDividerIndex;
    }

    normalizeManualNumber = limitTextLength(MANUAL_NUMBER_CHAR_LIMIT);

    normalizeShortName = limitTextLength(32);

    normalizeTitle = limitTextLength(128);

    deleteHandler = () => {
        const { index, onDelete } = this.props;

        onDelete(index);
    };

    toggleInstructionsForm = () => {
        this.setState((prevState) => ({
            showInstructionsForm: !prevState.showInstructionsForm,
        }));
    };

    render() {
        const {
            canHaveInstructions,
            defaultSection: { shortName, title },
            disabled,
            dividerDropdownOptions,
            isDivider,
            member,
            onComplete,
            questionLogicIcon,
            shouldNotDelete,
            showFormValidation,
            useManualNumbering,
            useSectionDividers,
        } = this.props;

        const { currentParentDividerIndex, newParentDividerIndex, showInstructionsForm } =
            this.state;

        return (
            <div className="row">
                <div className="col-lg-9">
                    <div className="row">
                        <div className="col-md-5">
                            <Field
                                component={InputText}
                                disabled={disabled}
                                hasFeedback={false}
                                help="Use our standard title or define your own"
                                label="Section Title"
                                name={`${member}.${TITLE}`}
                                normalize={this.normalizeTitle}
                                placeholder={title}
                                qaTag="projectSectionForm-sectionTitle"
                                showValidation={showFormValidation}
                                type="text"
                            />
                        </div>
                        <div className="col-md-4">
                            <Field
                                component={InputText}
                                disabled={disabled}
                                hasFeedback={false}
                                help="Abbreviated title"
                                label="Short Name"
                                name={`${member}.${SHORT_NAME}`}
                                normalize={this.normalizeShortName}
                                placeholder={shortName}
                                qaTag="projectSectionForm-sectionShortName"
                                showValidation={showFormValidation}
                                type="text"
                            />
                        </div>
                        <div className="col-md-3">
                            <Field
                                component={SearchSelect}
                                components={{
                                    Option: SearchSelectIconOption,
                                    SingleValue: SearchSelectIconValue,
                                }}
                                disabled={disabled || this.isWritingFormDisabled}
                                help={this.isWritingFormHelpText}
                                isSearchable={false}
                                label="Section Editors"
                                name={`${member}.${IS_WRITING_FORM}`}
                                options={editorSelectOptions}
                                qaTag="projectSectionForm-sectionEditors"
                                showValidation={showFormValidation}
                            />
                        </div>
                    </div>
                    {useManualNumbering && (
                        <div className="row">
                            <div className="col-md-5">
                                <Field
                                    component={InputText}
                                    disabled={disabled}
                                    hasFeedback={false}
                                    help="Manual numbering to use"
                                    label={`${isDivider ? 'Divider' : 'Section'} Number`}
                                    name={`${member}.${MANUAL_NUMBER}`}
                                    normalize={this.normalizeManualNumber}
                                    qaTag={`projectSectionForm-${MANUAL_NUMBER}`}
                                    showValidation={showFormValidation}
                                    type="text"
                                />
                            </div>
                            {useSectionDividers && !isDivider && (
                                <div className="col-md-5">
                                    <Field
                                        component={SearchSelect}
                                        components={{
                                            Option: SearchSelectIconOption,
                                            SingleValue: SearchSelectIconValue,
                                        }}
                                        disabled={disabled}
                                        help="Divider this section belongs to"
                                        input={{
                                            onBlur: () => {},
                                            onChange: (value) => {
                                                this.setState({ newParentDividerIndex: value });
                                            },
                                            value:
                                                newParentDividerIndex !== -1
                                                    ? newParentDividerIndex
                                                    : currentParentDividerIndex,
                                        }}
                                        isSearchable
                                        label="Divider"
                                        name={`${member}.dividerSelect`}
                                        options={dividerDropdownOptions}
                                        qaTag="projectSectionForm-dividerSelect"
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className={`col-lg-3 ${this.styles.doneButton}`}>
                    <Button
                        aria-label="Done"
                        bsStyle="primary"
                        onClick={onComplete}
                        qaTag="projectSectionForm-done"
                    >
                        Done
                    </Button>
                    &nbsp;&nbsp;
                    <OutlineButton
                        aria-label="Delete"
                        bsSize="sm"
                        bsStyle="danger"
                        disabled={disabled || shouldNotDelete}
                        onClick={this.deleteHandler}
                        qaTag="projectSectionForm-delete"
                    >
                        <i className="fa fa-trash" /> Delete
                    </OutlineButton>
                    {canHaveInstructions && (
                        <div>
                            <Button
                                aria-label="Instructions"
                                bsStyle="link"
                                className={this.styles.showInstructionsButton}
                                onClick={this.toggleInstructionsForm}
                                qaTag="projectSectionForm-closeOrAddInstructions"
                            >
                                {showInstructionsForm ? 'Close' : 'Add'} Instructions
                            </Button>
                        </div>
                    )}
                    {!!questionLogicIcon && (
                        <div className={this.styles.questionLogicIcon}>{questionLogicIcon}</div>
                    )}
                </div>
                {showInstructionsForm && (
                    <div className="col-xs-12 col-lg-10">
                        <Field
                            component={RichTextInput}
                            disabled={disabled}
                            help="Include custom instructions for completing this section"
                            label="Section Instructions"
                            minRows={3}
                            name={`${member}.${BUILDER_SECTION}.${INSTRUCTIONS}`}
                            qaTag="projectSectionForm-instructionsText"
                            showValidation={showFormValidation}
                            toolbarPlacement="bottom"
                        />
                    </div>
                )}
            </div>
        );
    }
}
