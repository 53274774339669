import PropTypes from 'prop-types';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { useFlags } from '../../lib/launchdarkly';

export const RequireFeatureFlag = ({ children, featureFlag, redirectTo }) => {
    const isFlagEnabled = useFlags(featureFlag);

    if (!isFlagEnabled) {
        return <Navigate replace to={redirectTo} />;
    }

    return children;
};

RequireFeatureFlag.propTypes = {
    children: PropTypes.node.isRequired,
    featureFlag: PropTypes.string.isRequired,
    redirectTo: PropTypes.string.isRequired,
};
