import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { Button, InputText } from '../../../../../../../../components';
import { limitTextLength } from '../../../../../../../../Forms/normalizers';
import {
    customColumnsData,
    standardColumnsData,
} from '../../../../../../../../../../shared_config/priceTables';

export class ColumnOptions extends PureComponent {
    static propTypes = {
        arrayName: PropTypes.string.isRequired,
        change: PropTypes.func.isRequired,
        columnChangeHandler: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        priceTable: PropTypes.object.isRequired,
    };

    normalizeHeaderName = limitTextLength(64);

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { arrayName, change, columnChangeHandler, disabled, priceTable } = this.props;

        return (
            <div className="row">
                <div className="col-xs-12 col-sm-6">
                    {standardColumnsData.map(
                        ({ defaultHeaderName, headerField, includedField }) => {
                            if (includedField !== true && !priceTable[includedField]) {
                                return null;
                            }
                            return (
                                <Field
                                    component={InputText}
                                    disabled={disabled}
                                    hasFeedback={false}
                                    key={headerField}
                                    label={`${defaultHeaderName} Column Name`}
                                    name={`${arrayName}.${headerField}`}
                                    normalize={this.normalizeHeaderName}
                                    onBlur={columnChangeHandler}
                                    placeholder={defaultHeaderName}
                                    type="text"
                                />
                            );
                        }
                    )}
                </div>
                <div className="col-xs-12 col-sm-6">
                    {customColumnsData.map(({ defaultHeaderName, headerField, includedField }) => {
                        if (!priceTable[includedField]) {
                            return null;
                        }
                        return (
                            <div className="row" key={headerField}>
                                <div
                                    className={classnames(
                                        'col-xs-9',
                                        this.styles.customColumnInput
                                    )}
                                >
                                    <Field
                                        component={InputText}
                                        disabled={disabled}
                                        hasFeedback={false}
                                        label={`${defaultHeaderName} Name`}
                                        name={`${arrayName}.${headerField}`}
                                        normalize={this.normalizeHeaderName}
                                        onBlur={columnChangeHandler}
                                        placeholder={defaultHeaderName}
                                        type="text"
                                    />
                                </div>
                                <div className="col-xs-3">
                                    <Button
                                        bsStyle="link"
                                        className={this.styles.deleteCustomColumnBtn}
                                        onClick={() => {
                                            change(`${arrayName}.${includedField}`, false);
                                            change(`${arrayName}.${headerField}`, null);
                                            columnChangeHandler();
                                        }}
                                    >
                                        <i className="fa fa-lg fa-times text-danger" />
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                    {customColumnsData.some(
                        (customCol) => !priceTable[customCol.includedField]
                    ) && (
                        <div className={this.styles.addColumnBtn}>
                            <Button
                                bsSize="sm"
                                disabled={disabled}
                                onClick={() => {
                                    const customColumn = customColumnsData.find(
                                        (customCol) => !priceTable[customCol.includedField]
                                    );
                                    change(`${arrayName}.${customColumn.includedField}`, true);
                                    change(`${arrayName}.${customColumn.headerField}`, 'Custom');
                                    columnChangeHandler();
                                }}
                            >
                                <i className="fa fa-plus" /> Add Custom Column
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
