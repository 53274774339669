import { listToDict } from '../../../../../shared_config/helpers';

export const form = 'proposalDisqualification';

export const fields = ['disqualifiedReason', 'disqualifiedReasonExtra', 'notifyVendor'];

export const fieldNames = listToDict(fields);

export const OTHER = 'other';

export const disqualifiedOptions = [
    {
        label: 'Minimum response requirements not met',
        value: 'Minimum response requirements not met',
    },
    {
        label: 'Certifications not met',
        value: 'Certifications not met',
    },
    {
        label: 'Incomplete response',
        value: 'Incomplete response',
    },
    {
        label: 'Did not comply with addenda',
        value: 'Did not comply with addenda',
    },
    {
        label: 'Other',
        value: OTHER,
    },
];
