import copy from 'copy-to-clipboard';
import { browserHistory } from '@og-pro-migration-tools/react-router';

import {
    hideConfirmationModal,
    showConfirmationModalError,
    updatingConfirmationModal,
} from './confirmation';
import {
    govLoadProject,
    showInstructionsModal,
    UPDATE_SUCCESS as GOV_PROJECTS_UPDATE_SUCCESS,
} from './govProjects';
import { update as updateBuilderProject } from './project/create/projectCreate';
import { showNotification, showSnackbar } from './notification';
import { emitProjectSocket } from './utils';
import { projectStatusesDict } from '../../../shared_config/projects';
import { trackEvent } from '../helpers';
import { loadVendorLists } from './vendorList';

const { OPEN } = projectStatusesDict;

export const INITIALIZE = 'gov/projectPost/INITIALIZE';
export const INITIATE_UPDATE = 'gov/projectPost/INITIATE_UPDATE';
export const INITIATE_SUBMIT = 'gov/projectPost/INITIATE_SUBMIT';
export const SHOW_FORM_VALIDATION = 'gov/projectPost/SHOW_FORM_VALIDATION';

export function initializeProjectPostForm() {
    return { type: INITIALIZE };
}

export function initiateUpdate() {
    return { type: INITIATE_UPDATE };
}

export function initiateSubmit() {
    return { type: INITIATE_SUBMIT };
}

export function showFormValidation() {
    return { type: SHOW_FORM_VALIDATION };
}

export function createPost(project) {
    return (dispatch, getState, client) => {
        const projectId = project.id;
        // Create post only happens within the confirmation modal
        dispatch(updatingConfirmationModal());
        return client
            .put(`/project/${projectId}/post`)
            .then((result) => {
                trackEvent('Project Post Created');
                trackEvent('Project Status Update', {
                    projectId,
                    oldStatus: project.status,
                    newStatus: result.status,
                });

                const updateAction = { type: GOV_PROJECTS_UPDATE_SUCCESS, result };
                dispatch(emitProjectSocket(projectId, updateAction));
                dispatch(hideConfirmationModal());

                const { government_id: govId } = result;
                const postingPath = `/governments/${govId}/projects/${projectId}/sourcing/create`;

                // Route to post create flow and display instructions modal
                browserHistory.push(postingPath);
                dispatch(showInstructionsModal('createPost'));
            })
            .catch((error) => dispatch(showConfirmationModalError(error.message)));
    };
}

export function submitPost(projectId, formData) {
    return (dispatch, getState) => {
        const govId = getState().govProjects.getIn(['selectedProject', 'government_id']);
        const nextRoute = `/governments/${govId}/projects/${projectId}/sourcing`;
        const newStatus = formData.postScheduledAt ? formData.status : OPEN;

        const onComplete = (result) => {
            let instructionType;
            if (result.wasPosted) {
                instructionType = 'published';
                trackEvent('Project Post Completed');
                trackEvent('Project Status Update', {
                    projectId,
                    oldStatus: formData.status,
                    newStatus,
                });
            } else {
                instructionType = 'postScheduled';
                trackEvent('Project Post Scheduled');
            }

            // Show the projects page
            browserHistory.push(nextRoute);
            dispatch(showInstructionsModal(instructionType));
        };

        dispatch(updatingConfirmationModal());
        return dispatch(
            updateBuilderProject(formData, projectId, newStatus, {
                endpoint: `/project/${projectId}/publish`,
                onComplete,
                modal: true,
            })
        );
    };
}

export function update(formData, id, status, opts = {}) {
    // The sourcing module is a subset of builder module fields, and so can use the builder module's
    // update function which includes the builder module's serializer
    return updateBuilderProject(formData, id, status, opts);
}

export function retractPost(project) {
    return (dispatch, getState, client) => {
        const projectId = project.id;
        // Retract post only happens within the confirmation modal
        dispatch(updatingConfirmationModal());
        return client
            .put(`/project/${projectId}/post/retract`)
            .then((result) => {
                trackEvent('Project Post Retracted');
                trackEvent('Project Status Update', {
                    projectId,
                    oldStatus: project.status,
                    newStatus: result.status,
                });
                dispatch(hideConfirmationModal());
                dispatch(showNotification('Post Retracted!'));

                const updateAction = { type: GOV_PROJECTS_UPDATE_SUCCESS, result };
                dispatch(emitProjectSocket(projectId, updateAction));
            })
            .catch((error) => {
                return dispatch(showConfirmationModalError(error.message));
            });
    };
}

export function deletePost(project) {
    return (dispatch, getState, client) => {
        const { government_id: govId, id: projectId } = project;

        dispatch(updatingConfirmationModal());

        return client
            .del(`/project/${projectId}/post`)
            .then((result) => {
                dispatch(hideConfirmationModal());

                trackEvent('Project Sourcing Module Deleted');

                if (result.isPostOnly) {
                    // The project and evaluation were both deleted
                    trackEvent('Project Deleted');
                    dispatch(showNotification('Post successfully deleted'));
                    browserHistory.push(`/governments/${govId}/projects`);
                } else {
                    // The project was updated, not deleted
                    trackEvent('Project Status Update', {
                        projectId,
                        oldStatus: project.status,
                        newStatus: result.status,
                    });
                    dispatch(govLoadProject(projectId));
                    dispatch(showSnackbar('Reopened Project Builder'));
                    browserHistory.push(`/governments/${govId}/projects/${projectId}/manage`);
                }
            })
            .catch((error) => {
                dispatch(showConfirmationModalError(`Post could not be deleted: ${error.message}`));
            });
    };
}

export const SHOW_SHARE_LINK_MODAL = 'gov/projectPost/SHOW_SHARE_LINK_MODAL';
export const HIDE_SHARE_LINK_MODAL = 'gov/projectPost/HIDE_SHARE_LINK_MODAL';

export function showShareLinkModal(projectId, governmentCode, link) {
    return (dispatch) => {
        // Sometimes we already have the link and don't need to rebuild it
        const shareLink =
            link || `${process.env.SITE_URL}/portal/${governmentCode}/projects/${projectId}`;

        // Copy link to clipboard and show modal
        copy(shareLink);
        dispatch({ type: SHOW_SHARE_LINK_MODAL, value: shareLink });
        return dispatch(showSnackbar('Share Link Copied to Clipboard'));
    };
}

export function hideShareLinkModal() {
    return { type: HIDE_SHARE_LINK_MODAL };
}

export const SHOW_POST_MODAL = 'gov/projectPost/SHOW_POST_MODAL';
export const HIDE_POST_MODAL = 'gov/projectPost/HIDE_POST_MODAL';
export const SHOW_VENDOR_INVITE_MODAL = 'gov/projectPost/SHOW_VENDOR_INVITE_MODAL';
export const HIDE_VENDOR_INVITE_MODAL = 'gov/projectPost/HIDE_VENDOR_INVITE_MODAL';

export function showPostConfirmationModal() {
    return { type: SHOW_POST_MODAL };
}

export function hidePostConfirmationModal() {
    return { type: HIDE_POST_MODAL };
}

export function showVendorProjectInviteModal(governmentId) {
    return (dispatch) => {
        dispatch(loadVendorLists(governmentId));
        dispatch({ type: SHOW_VENDOR_INVITE_MODAL });
    };
}

export function hideVendorProjectInviteModal() {
    return { type: HIDE_VENDOR_INVITE_MODAL };
}

export const LOAD_VENDOR_DATA = 'gov/projectPost/LOAD_VENDOR_DATA';
export const LOAD_VENDOR_DATA_SUCCESS = 'gov/projectPost/LOAD_VENDOR_DATA_SUCCESS';
export const LOAD_VENDOR_DATA_FAIL = 'gov/projectPost/LOAD_VENDOR_DATA_FAIL';

export function loadProjectVendorData(projectId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_VENDOR_DATA });
        return client
            .get(`/project/${projectId}/vendors`)
            .then((result) => dispatch({ type: LOAD_VENDOR_DATA_SUCCESS, result }))
            .catch((error) => dispatch({ type: LOAD_VENDOR_DATA_FAIL, error }));
    };
}

export const RESET_VENDOR_DATA = 'gov/projectPost/RESET_VENDOR_DATA';

export function resetVendorAnalytics() {
    return { type: RESET_VENDOR_DATA };
}

// State is manage by the calling component internally instead of redux
export function loadEmailAudit(projectId, emailUuid) {
    return (dispatch, getState, client) => {
        return client.get(`/project/${projectId}/vendors/email-audits/${emailUuid}`);
    };
}

export const LOAD_VENDOR_EMAIL_AUDITS_SUCCESS = 'gov/projectPost/LOAD_VENDOR_EMAIL_AUDITS_SUCCESS';

export function loadEmailAudits(projectId) {
    return (dispatch, getState, client) => {
        return client
            .get(`/project/${projectId}/vendors/email-audits`)
            .then((result) => dispatch({ type: LOAD_VENDOR_EMAIL_AUDITS_SUCCESS, result }));
    };
}

export const LOAD_VENDOR_CLASSIFICATION_SUCCESS =
    'gov/projectPost/LOAD_VENDOR_CLASSIFICATION_SUCCESS';

export function loadClassificationReport(projectId) {
    return (dispatch, getState, client) => {
        return client
            .get(`/project/${projectId}/vendors/classifications`)
            .then((result) => dispatch({ type: LOAD_VENDOR_CLASSIFICATION_SUCCESS, result }));
    };
}

export const INVITE_VENDORS = 'gov/projectPost/INVITE_VENDORS';
export const INVITE_VENDORS_SUCCESS = 'gov/projectPost/INVITE_VENDORS_SUCCESS';
export const INVITE_VENDORS_FAIL = 'gov/projectPost/INVITE_VENDORS_FAIL';

export function inviteVendorsToProject(projectId, data) {
    return (dispatch, getState, client) => {
        dispatch({ type: INVITE_VENDORS });

        return client
            .post(`/project/${projectId}/vendors/invites`, { data })
            .then((result) => {
                dispatch({ type: INVITE_VENDORS_SUCCESS });
                const { pendingUsers, users } = result;
                dispatch(
                    showSnackbar(`Sent ${pendingUsers.length + users.length} Vendor Invite Emails!`)
                );
            })
            .catch((error) => {
                dispatch({ type: INVITE_VENDORS_FAIL, error });
                return error;
            });
    };
}
