import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { connect } from 'react-redux';
import { buildQaTag, componentNames } from '@og-pro/ui';

import { PERMISSION_REQUIRED_ACTION_TOOLTIP } from '../../constants';
import { aiEnablementData } from '../../../App/selectors';
import { Tooltip } from '../../../../../components';
import { onKeyDown } from '../../../../../helpers/accessibilityHelper';
import { withFlags } from '../../../../../lib/launchdarkly';

const mapStateToProps = (state) => {
    return {
        isGenerativeAiEnabled: !!aiEnablementData(state),
    };
};

export class ConnectedActionItem extends PureComponent {
    static propTypes = {
        action: PropTypes.string,
        actionHandler: PropTypes.func.isRequired,
        featureFlag: PropTypes.string,
        getFlag: PropTypes.func.isRequired,
        hasPermission: PropTypes.bool,
        isGenerativeAiEnabled: PropTypes.bool,
        icon: PropTypes.string.isRequired,
        link: PropTypes.string,
        name: PropTypes.string.isRequired,
        requiresAiEnablement: PropTypes.bool,
        reverseFeatureFlag: PropTypes.string,
        router: PropTypes.object.isRequired,
    };

    clickHandler = () => {
        const { action, actionHandler, hasPermission, link, router } = this.props;

        if (!hasPermission) return null;
        if (link) return router.push(link);

        actionHandler(action);
    };

    renderItem() {
        const { hasPermission, icon, name } = this.props;

        const styles = require('../shared.scss');
        const disabledStyle = hasPermission ? '' : styles.disabledActionItem;
        const qaTag = buildQaTag(
            `${name.replace(/\s/g, '_').toLowerCase()}-action`,
            componentNames.MENU_ITEM
        );

        return (
            <li
                className={`${styles.actionItem} ${disabledStyle}`}
                data-qa={qaTag}
                onClick={this.clickHandler}
                onKeyDown={(event) => onKeyDown(event, this.clickHandler)}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
            >
                <i className={`fa fa-fw ${icon}`} />
                <span className={styles.actionItemName}>{name}</span>
            </li>
        );
    }

    render() {
        const {
            featureFlag,
            getFlag,
            hasPermission,
            isGenerativeAiEnabled,
            requiresAiEnablement,
            reverseFeatureFlag,
        } = this.props;

        if (featureFlag && !getFlag(featureFlag)) {
            return null;
        }

        if (reverseFeatureFlag && getFlag(reverseFeatureFlag)) {
            return null;
        }

        if (requiresAiEnablement && !isGenerativeAiEnabled) {
            return null;
        }

        if (!hasPermission) {
            const tooltip = PERMISSION_REQUIRED_ACTION_TOOLTIP;
            return (
                <Tooltip placement="left" tooltip={tooltip}>
                    {this.renderItem()}
                </Tooltip>
            );
        }
        return this.renderItem();
    }
}

export const ActionItem = compose(
    withFlags(),
    withRouter,
    connect(mapStateToProps)
)(ConnectedActionItem);
