import { get } from 'lodash';
import qs from 'qs';

import {
    BID_BOND,
    CONFIRMATION,
    CUSTOM_VARIABLE,
    DATA,
    DATA_VERIFY,
    DOWNLOAD,
    EXTERNAL_FORM,
    FILE_UPLOAD,
    LONG_ANSWER,
    MULTIPLE_CHOICE,
    NOTARIZE,
    QUESTIONNAIRE_RESPONSE,
    SHORT_ANSWER,
    YES_NO,
    RESPONSE_DATA,
    VALUE,
} from '../../../../shared_config/questionnaires';
import { getHtmlCharacterCount, stripHtml } from '../../utils';

// `questionnaireData` contains the questionnaire fields plus some additional options
export function getRawQuestionnaireResponse(questionnaireData) {
    const { questionnaireResponse, type } = questionnaireData;

    const responsePath =
        questionnaireData.responsePath || `${QUESTIONNAIRE_RESPONSE}.${RESPONSE_DATA}`;

    const response = get(questionnaireData, `${responsePath}.${VALUE}`);

    switch (type) {
        case BID_BOND: {
            const data = get(questionnaireResponse, [DATA], {});
            const { bidBondId, bidBondVendorId } = data;
            return `Bond ID: ${bidBondId || 'No Response Submitted'}\nVendor ID: ${
                bidBondVendorId || 'No Response Submitted'
            }`;
        }
        case CONFIRMATION: {
            if (!response) return 'Not confirmed';
            return 'Confirmed';
        }
        case DOWNLOAD:
        case EXTERNAL_FORM:
        case FILE_UPLOAD:
        case NOTARIZE: {
            // NOTE: needs to be updated to work with dynamic `responsePath`
            const attachments = get(questionnaireResponse, 'attachments') || [];
            if (attachments.length === 0) return null;

            return attachments.map((attachment) => attachment.url).join('\n');
        }
        case LONG_ANSWER: {
            if (!response) return null;

            return stripHtml(response);
        }
        case CUSTOM_VARIABLE:
        case DATA_VERIFY:
        case MULTIPLE_CHOICE:
        case SHORT_ANSWER:
            return response;
        case YES_NO: {
            if (response === true) return 'Yes';
            if (response === false) return 'No';
            return null;
        }
        default:
            return null;
    }
}

export const formatLicenseURL = (url, wildcard) => {
    let link = (url || '').trim();

    if (!link) {
        return;
    }

    // Without a protocol the link is treated as a local link by the href
    if (link.substring(0, 7) !== 'mailto:' && link.substring(0, 4) !== 'http') {
        link = `http://${link}`;
    }

    const encodedWildcard = wildcard && qs.stringify({ q: wildcard }).slice(2);

    return link.replace(/{}/g, encodedWildcard || '');
};

export const getTextBasedResponseHelp = (maxLength, inputValue) => {
    if (!maxLength) {
        return undefined;
    }

    const inputLength = getHtmlCharacterCount(inputValue);
    if (inputLength > maxLength) {
        return `Response too long (${maxLength} characters maximum)`;
    }

    return `Maximum ${maxLength} characters (${maxLength - inputLength} remaining)`;
};
