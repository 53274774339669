import { listToDict } from '../../../../../../../../shared_config/helpers';
import { highlightPoint, unhighlightPoint } from '../helpers';

const chartInteractionParams = ['olderThan', 'status', 'type'];

export const chartInteractionParamsDict = listToDict(chartInteractionParams);

export const handleChartInteractionInitialize = (param, points, chartName, pointName) => {
    if (param) {
        // select the point chart if the query param is set
        const point = points.find((p) => {
            return p.name === pointName;
        });

        if (point) {
            point.select(true);

            points.forEach((p) => unhighlightPoint(p, chartName));
            highlightPoint(point, chartName);
        }
    } else {
        // deselect all points if the query param is not set
        points.forEach((p) => {
            p.select(false);
            points.forEach((point) => highlightPoint(point, chartName));
        });
    }
};
