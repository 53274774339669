import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field } from 'redux-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import { Button, InputText } from '../../../../../components';
import {
    AccountNumberInput,
    useAccountSearch,
    useCoaConfigLookup,
} from '../../../../../lib/ogFinancials';
import { getIsGovernmentUsingFMS } from '../../../../../selectors/govApp';
import { fieldsDict, formConfig } from './form';
import { getRequisitionsPath } from '../../../RequisitionsDashboard/RequisitionsDashboard/selectors';

const { ACCOUNT_ID } = fieldsDict;

export const AccountSelect = ({ accountId }) => {
    const params = useParams();
    const styles = require('./AccountSelect.scss');
    const hasFMS = useSelector(getIsGovernmentUsingFMS);
    const requisitionsPath = getRequisitionsPath({ params });
    const dispatch = useDispatch();
    const { data: coaConfigLookupData } = useCoaConfigLookup();

    const accountIdAsNumber = Number.parseInt(accountId, 10);
    const {
        data: accountData,
        error: accountDataError,
        isFetching,
    } = useAccountSearch(accountIdAsNumber, {
        enabled: hasFMS && !Number.isNaN(accountIdAsNumber),
    });

    if (accountDataError) {
        return (
            <div className={classNames(styles.error, 'text-danger')}>
                <i className="fa fa-fw fa-chain-broken" /> Error loading account number data.{' '}
                <Button bsStyle="link" to={`${requisitionsPath}/accounts/details`} zeroPadding>
                    Reset filters
                </Button>{' '}
                and try again
            </div>
        );
    }

    if (isFetching) {
        return (
            // Render a mock account number input to show the loading state
            <div className={styles.accountSelect}>
                <Field
                    component={InputText}
                    disabled
                    label="Account Number"
                    name="mockAccount"
                    placeholder="Loading..."
                />
            </div>
        );
    }

    const handleAccountChange = (newAccount) => {
        if (newAccount && newAccount.account_pseudo_key) {
            dispatch(change(formConfig.form, ACCOUNT_ID, newAccount.account_pseudo_key));
        }
    };

    return (
        <div className={styles.accountSelect}>
            <Field
                accountCompressedFormatted={accountData?.accountCompressedFormatted}
                accountDescription={accountData?.description}
                coaConfigLookupData={coaConfigLookupData}
                component={hasFMS ? AccountNumberInput : InputText}
                label="Account Number"
                name={ACCOUNT_ID}
                onAccountChange={handleAccountChange}
            />
        </div>
    );
};

AccountSelect.propTypes = {
    accountId: PropTypes.number,
};
