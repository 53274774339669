import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Box } from '@og-pro/ui';

import { CDSButton } from '../../../../../../components';

export const FinalReviewChecklistBanner = ({
    buttonText = 'Review Approval Setup',
    hasIncompleteSections,
    onReviewClick,
}) => {
    const styles = require('./index.scss');

    if (hasIncompleteSections) {
        return (
            <Box className={classnames(styles.banner, styles.errorBanner)}>
                <Box mr={2}>
                    <i className="fa fa-exclamation-triangle" />
                </Box>
                <Box>
                    <Box className={styles.title}>You have one or more incomplete sections.</Box>
                    <Box>You must complete all required fields before starting project review.</Box>
                </Box>
                <Box flex={1} textAlign="right">
                    <CDSButton disabled size="large" variant="secondary">
                        Review Project
                    </CDSButton>
                </Box>
            </Box>
        );
    }

    return (
        <Box className={classnames(styles.banner, styles.successBanner)}>
            <Box mr={2}>
                <i className="fa fa-check-circle" />
            </Box>
            <Box>
                <Box className={styles.title}>All sections are complete!</Box>
                <Box>
                    Click the &quot;{buttonText}&quot; button to review and finalize those who will
                    approve this draft. After finalizing approvers, you will submit your draft for
                    review.
                </Box>
            </Box>
            <Box flex={1} textAlign="right">
                <CDSButton
                    onClick={() => onReviewClick()}
                    qaTag="projectCreate-reviewProject"
                    size="large"
                    variant="primary"
                >
                    {buttonText}
                </CDSButton>
            </Box>
        </Box>
    );
};

FinalReviewChecklistBanner.propTypes = {
    buttonText: PropTypes.string,
    hasIncompleteSections: PropTypes.bool,
    onReviewClick: PropTypes.func.isRequired,
};
