import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Field } from 'redux-form';

import { qaTagPageName } from '../../../../constants';
import { InputText, SearchSelect } from '../../../../../../../../components';
import { maskNumberWithCommas } from '../../../../../../../../Forms/maskers';
import { dollarString } from '../../../../../../../../Forms/normalizers';
import { MaskedInputText } from '../../../../../../../../hocs';
import { inputValueTypes } from '../../../../../../../../../../shared_config/questionLogics';

export const QuestionLogicValueField = ({
    disabled,
    help,
    inputValueOptions,
    inputValueType,
    label,
    name,
    placeholder,
    showFormValidation,
}) => {
    const getAdditionalFieldValues = (valueType) => {
        switch (valueType) {
            case inputValueTypes.DOLLARS:
                return [
                    MaskedInputText(InputText),
                    { inputGroupPrefix: '$', mask: maskNumberWithCommas, normalizer: dollarString },
                ];
            case inputValueTypes.SELECT:
                return [SearchSelect, { options: inputValueOptions }];
            default:
                return [InputText, { disabled: true }];
        }
    };

    const [Component, additionalProps] = useMemo(
        () => getAdditionalFieldValues(inputValueType),
        [inputValueType]
    );

    return (
        <Field
            component={Component}
            disabled={disabled}
            hasFeedback={false}
            help={help}
            label={label}
            name={name}
            placeholder={placeholder}
            qaTag={`${qaTagPageName}-questionLogicValue`}
            showValidation={showFormValidation}
            {...additionalProps}
        />
    );
};

QuestionLogicValueField.propTypes = {
    disabled: PropTypes.bool,
    help: PropTypes.string,
    inputValueOptions: PropTypes.array,
    inputValueType: PropTypes.string,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    showFormValidation: PropTypes.bool,
};
