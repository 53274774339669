import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { HelpIcon } from '..';
import {
    formatTypesDisplay as allFormatTypes,
    questionTypesDisplay as allQuestionTypes,
} from '../../../../shared_config/questionnaires';

export class QuestionnaireTypeSelect extends PureComponent {
    static propTypes = {
        formatTypes: PropTypes.array,
        omitFormatTypes: PropTypes.bool,
        onFormatSelect: PropTypes.func.isRequired,
        onQuestionSelect: PropTypes.func.isRequired,
        questionTypes: PropTypes.array,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            formatTypes = allFormatTypes,
            omitFormatTypes = false,
            onFormatSelect,
            onQuestionSelect,
            questionTypes = allQuestionTypes,
        } = this.props;

        return (
            <div className={`row ${this.styles.container}`}>
                <div className="col-md-offset-3 col-md-6">
                    <div className={this.styles.titleText}>Select a Question Type:</div>
                    <ListGroup>
                        {questionTypes.map((item) => (
                            <ListGroupItem
                                className={this.styles.listItem}
                                key={item.type}
                                onClick={() => onQuestionSelect(item)}
                            >
                                <div className="pull-right">
                                    <HelpIcon
                                        className={this.styles.helpIcon}
                                        tooltip={item.instructions}
                                    />
                                    <i className="fa fa-lg fa-angle-right" />
                                </div>
                                <div className="pull-left">
                                    <i
                                        className={`fa fa-fw fa-lg fa-${item.icon} ${this.styles.icon}`}
                                    />
                                </div>
                                <div className={this.styles.optionTitle}>
                                    {item.title}
                                    {!!item.integration && (
                                        <div className={this.styles.integrationContainer}>
                                            Powered by integration with {item.integration}
                                            <img
                                                alt={`${item.integration} logo`}
                                                className={`img-rounded ${this.styles.logo}`}
                                                src={item.integrationLogo}
                                            />
                                        </div>
                                    )}
                                </div>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                    {!omitFormatTypes && (
                        <>
                            {formatTypes.length > 0 && (
                                <div className={this.styles.titleText}>
                                    Select a Formatting Option:
                                </div>
                            )}
                            <ListGroup>
                                {formatTypes.map((item) => (
                                    <ListGroupItem
                                        className={this.styles.listItem}
                                        key={item.type}
                                        onClick={() => onFormatSelect(item)}
                                    >
                                        <i
                                            className={`fa fa-fw fa-lg fa-${item.icon} ${this.styles.icon}`}
                                        />
                                        {item.title}
                                        <span className="pull-right">
                                            <HelpIcon
                                                className={`${this.styles.helpIcon}`}
                                                tooltip={item.instructions}
                                            />
                                            <i className="fa fa-lg fa-angle-right" />
                                        </span>
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </>
                    )}
                </div>
            </div>
        );
    }
}
