import { projectTypesDict } from '../../../shared_config/projects';

/**
 * Determines if a project is of type purchase
 * @param {string} type
 * @returns {boolean}
 */
export const isPurchase = (type) => {
    return type === projectTypesDict.PURCHASE;
};
