import { listToDict } from '../../../../../../../shared_config/helpers';
import { showAccountInformationOptionsValues } from '../../../../../../../shared_config/requisitions';

export const form = 'requisitionsSettingsForm';

export const fields = [
    'isDescriptionRequired',
    'useBudgetGroup',
    'usePreEncumbrance',
    'showAccountInformation',
];

export const fieldNames = listToDict(fields);

export const isDescriptionRequiredOptions = [
    { label: 'Optional', value: false },
    { label: 'Required', value: true },
];

export const usePreEncumbranceOptions = [
    { label: 'Include in budget check', value: true },
    { label: 'Exclude from budget check', value: false },
];

export const useBudgetGroupOptions = [
    { label: 'Account', value: false },
    { label: 'Group', value: true },
];

const { REQUIRED, OPTIONAL, HIDDEN } = showAccountInformationOptionsValues;

export const showAccountInformationOptions = [
    { label: 'Required', value: REQUIRED },
    { label: 'Optional', value: OPTIONAL },
    { label: 'Hidden', value: HIDDEN },
];
