import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { Component } from 'react';
import { v4 } from 'uuid';
import { connect } from 'react-redux';
import { Field, submit as reduxFormSubmit, hasSubmitSucceeded } from 'redux-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Checkbox as BootstrapCheckbox, ListGroup, Well } from 'react-bootstrap';

import { form as timelineCreateFormName } from './TimelineCreateForm/constants';
import { TimelineCreateForm } from './TimelineCreateForm';
import { TimelineDateToggles } from './TimelineDateToggles';
import { TimelineHasErrorsField } from './TimelineHasErrorsField';
import {
    AttachmentUploader,
    Button,
    Checkbox,
    DateTimePicker,
    DragIcon,
    HelpToolTip,
    InputText,
    Label,
} from '../../..';
import {
    TIMELINE_CONFIG,
    TIMELINES,
    TIMELINE_HAS_ERRORS,
} from '../../../../containers/GovApp/constants';
import { getDndStyle } from '../../../../constants/styles';
import { getTimelines } from '../../../../containers/GovApp/selectors';
import { limitTextLength } from '../../../../Forms/normalizers';
import { customTimelineFieldsDict } from '../../../../../../shared_config/timelines';
import { TIMELINE_PROPTYPE } from './constants';

const {
    AGENDA_URL,
    DATE,
    IS_ATTENDANCE_REQUIRED,
    IS_PUBLIC,
    LOCATION,
    ORDER_BY_ID,
    SHOW_LOCATION,
    TEXT_DATE,
    TITLE,
} = customTimelineFieldsDict;

const TODAY = new Date();
TODAY.setHours(0, 0, 0, 0);

const mapStateToProps = (state, props) => {
    return {
        timelineCreateFormSuccedded: hasSubmitSucceeded(timelineCreateFormName)(state),
        timelineData: getTimelines(state, props),
    };
};

// @connect
class ConnectedTimeline extends Component {
    static propTypes = {
        form: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        disableToggles: PropTypes.bool,
        isTemplate: PropTypes.bool,
        showValidation: PropTypes.bool,
        timezone: PropTypes.string,
        change: PropTypes.func.isRequired,
        array: PropTypes.object.isRequired,
        className: PropTypes.string,
        project: PropTypes.shape({
            id: PropTypes.number,
        }),
        shouldConditionallyDisable: PropTypes.func,
        timelineData: PropTypes.arrayOf(TIMELINE_PROPTYPE).isRequired,
        reduxFormSubmit: PropTypes.func.isRequired,
        timelineCreateFormSuccedded: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            editMode: !!this.props.isTemplate,
            showDynamicTimelineForm: false,
            togglePending: false,
        };
    }

    static defaultProps = {
        className: '',
        disableToggles: false,
    };

    get styles() {
        return require('./Timeline.scss');
    }

    componentDidUpdate(prevProps) {
        // if the form was submitted we make sure to remove the dynamic timeline form
        if (!prevProps.timelineCreateFormSuccedded && this.props.timelineCreateFormSuccedded) {
            this.setState({ showDynamicTimelineForm: false }, () => {
                // if there was a toggle pending (see function below)
                // we make sure to make it happen
                if (this.state.togglePending) {
                    this.setState({ togglePending: false });
                    this.toggleEditMode();
                }
            });
        }
    }

    normalizeTimelineTitleLength = limitTextLength(128);

    normalizeTimelineTextDateLength = limitTextLength(250);

    normalizeTimelineLocationLength = limitTextLength(2000);

    normalizeTimelineExtensionMinuteLength = limitTextLength(2);

    cancelAddDate = () => this.setState({ showDynamicTimelineForm: false });

    disabled = (timeline) => {
        const { disabled, shouldConditionallyDisable } = this.props;

        if (!shouldConditionallyDisable || !timeline || disabled) {
            return disabled;
        }

        return shouldConditionallyDisable(timeline);
    };

    handleDragEnd = (result) => {
        const { change, timelineData } = this.props;

        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        if (newLocation !== undefined && newLocation !== originLocation) {
            const timelineDataCopy = timelineData.slice();
            const timelineToMove = timelineDataCopy.splice(originLocation, 1);
            timelineDataCopy.splice(newLocation, 0, ...timelineToMove);
            timelineDataCopy.forEach((timeline, idx) => {
                const fieldName = timeline.orderByIdField
                    ? `${TIMELINE_CONFIG}.${timeline.orderByIdField}`
                    : `${TIMELINES}.${timeline.index}.${ORDER_BY_ID}`;
                change(fieldName, idx + 1);
            });
        }
    };

    removeDynamicTimeline = (index) => {
        this.props.array.remove(TIMELINES, index);
    };

    showDynamicTimelineForm = () => this.setState({ showDynamicTimelineForm: true });

    submitHandler = (data) => {
        const { array, timelineData } = this.props;

        array.push(TIMELINES, {
            ...data,
            hasTextDate: !!data.textDate,
            isCustomDate: true,
            orderById: timelineData[timelineData.length - 1].orderById + 1,
            uuid: v4(),
        });
    };

    toggleEditMode = () => {
        // if we enter into this condition it's because they were adding a new timeline
        // and they pressed the wrong button, so we need to submit the form before toggling
        if (this.state.showDynamicTimelineForm) {
            // this new state is used to trigger the componentDidUpdate
            // so that we can close the form and toggle edit mode afterwards
            return this.setState({ togglePending: true }, () => {
                return this.props.reduxFormSubmit(timelineCreateFormName);
            });
        }

        this.setState((prevState) => {
            return {
                editMode: !prevState.editMode,
                showDynamicTimelineForm: false,
            };
        });
    };

    checkboxChangeHandler = (fieldValuePairs = {}) => {
        const { change } = this.props;
        return Object.entries(fieldValuePairs).forEach((pair) => change(pair[0], pair[1]));
    };

    renderEditingControls = (index, isCustomDate, projectField, provided) => {
        const { disabled } = this.props;
        const { editMode } = this.state;

        if (isCustomDate) {
            return (
                <span
                    className={classnames(
                        'col-xs-1',
                        this.styles.editingControls,
                        editMode && this.styles.show
                    )}
                >
                    <Button
                        aria-label="Remove Button"
                        bsStyle="link"
                        className={this.styles.deleteIcon}
                        disabled={disabled}
                        onClick={() => this.removeDynamicTimeline(index)}
                        qaTag="timeline-remove"
                        zeroPadding
                    >
                        <i className="fa fa-2x fa-times text-danger" />
                    </Button>
                    <DragIcon
                        className={this.styles.dragIcon}
                        disabled={disabled}
                        dragHandleProps={provided.dragHandleProps}
                    />
                </span>
            );
        }

        return (
            <span
                className={classnames(
                    'col-xs-1',
                    this.styles.editingControls,
                    editMode && this.styles.show
                )}
            >
                <HelpToolTip
                    placement="left"
                    text={`${startCase(
                        projectField
                    )} cannot be re-ordered or deleted. Please use the toggles below to remove.`}
                />
            </span>
        );
    };

    renderTextDate = (timeline) => {
        const disabled = this.disabled(timeline);
        const { showValidation } = this.props;
        const { hasTextDate, index, title } = timeline;
        const editMode = this.state.editMode;

        if (!hasTextDate) {
            return null;
        }

        const textDateFieldName = `${TIMELINES}.${index}.${TEXT_DATE}`;
        return (
            <>
                {!editMode && (
                    <div className="col-xs-6">
                        <Label label={title} />
                    </div>
                )}
                <div className="col-xs-6">
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        name={textDateFieldName}
                        normalize={this.normalizeTimelineTextDateLength}
                        placeholder="Enter text to use instead of date"
                        qaTag={`timelineForm-${title}TextDate`}
                        showValidation={showValidation}
                        type="text"
                    />
                </div>
            </>
        );
    };

    /*  The following assumptions are what the below rendering functions logic is based on:
        - `preProposal` always has a location and is always public
        - `proposalDeadline` can have a location, but it is not a meeting so it does not get
            the public, required, or agenda fields
        - Custom timeline dates have all options available to them
    */

    renderLocation = (timeline) => {
        const disabled = this.disabled(timeline);
        const { showValidation } = this.props;
        const {
            hasProposalDeadlineLocation,
            hasProposalDeadlineLocationField,
            index,
            isCustomDate,
            locationField,
            showLocation,
            title,
        } = timeline;

        if (!locationField && !isCustomDate) {
            return null;
        }

        const locationFieldName = locationField
            ? `${TIMELINE_CONFIG}.${locationField}`
            : `${TIMELINES}.${index}.${LOCATION}`;

        const locationInputField = (
            <Field
                component={InputText}
                disabled={disabled}
                hasFeedback={false}
                help="Can be a conference room, address or teleconference number"
                label="Location"
                name={locationFieldName}
                normalize={this.normalizeTimelineLocationLength}
                placeholder="West Conference Room - 123 Front St"
                qaTag={`timelineForm-${title}Location`}
                showValidation={showValidation}
                type="textarea"
            />
        );

        if (hasProposalDeadlineLocationField || isCustomDate) {
            const specifyLocationFieldName = hasProposalDeadlineLocationField
                ? `${TIMELINE_CONFIG}.${hasProposalDeadlineLocationField}`
                : `${TIMELINES}.${index}.${SHOW_LOCATION}`;

            const publicFieldName = isCustomDate && `${TIMELINES}.${index}.${IS_PUBLIC}`;
            const requiredFieldName =
                isCustomDate && `${TIMELINES}.${index}.${IS_ATTENDANCE_REQUIRED}`;

            const displayLocationChangeHandler = (e, displayLocation) => {
                if (!displayLocation) {
                    return this.checkboxChangeHandler({
                        [locationFieldName]: null,
                        ...(publicFieldName && { [publicFieldName]: false }),
                        ...(requiredFieldName && { [requiredFieldName]: false }),
                    });
                }
                return this.checkboxChangeHandler({
                    ...(publicFieldName && { [publicFieldName]: true }),
                });
            };

            return (
                <div className="col-xs-offset-6 col-xs-6" key={locationFieldName}>
                    <Field
                        className={this.styles.checkbox}
                        component={Checkbox}
                        disabled={disabled}
                        name={specifyLocationFieldName}
                        onChange={displayLocationChangeHandler}
                        qaTag={`timelineForm-${title}SpecifyLocation`}
                        text="Specify Location?"
                    />
                    {(hasProposalDeadlineLocation || showLocation) && locationInputField}
                </div>
            );
        }

        return (
            <div className="col-xs-offset-6 col-xs-6" key={locationFieldName}>
                {locationInputField}
            </div>
        );
    };

    renderPublic = (timeline) => {
        const disabled = this.disabled(timeline);
        const { showValidation } = this.props;
        const {
            index,
            isCustomDate,
            isPublic,
            isRequiredField, // Also being used to identify `preProposal` timeline type
            showLocation,
            title,
        } = timeline;

        const requiredFieldName = isRequiredField
            ? `${TIMELINE_CONFIG}.${isRequiredField}`
            : `${TIMELINES}.${index}.${IS_ATTENDANCE_REQUIRED}`;

        const publicChangeHandler = (e, publicMeeting) => {
            if (!publicMeeting) {
                return this.checkboxChangeHandler({
                    [requiredFieldName]: false,
                });
            }
        };

        if ((!isCustomDate && !isRequiredField) || (isCustomDate && !showLocation)) {
            return null;
        }

        return (
            <div className="col-xs-offset-6 col-xs-6" key={requiredFieldName}>
                {isRequiredField ? (
                    <BootstrapCheckbox
                        checked
                        className={this.styles.checkbox}
                        disabled
                        inline
                        style={{ marginBottom: 15 }}
                    >
                        Public Meeting? (Mandatory for Pre-Proposal)
                    </BootstrapCheckbox>
                ) : (
                    <Field
                        className={this.styles.checkbox}
                        component={Checkbox}
                        disabled={disabled}
                        inline
                        name={`${TIMELINES}.${index}.${IS_PUBLIC}`}
                        onChange={publicChangeHandler}
                        qaTag={`timelineForm-${title}PublicMeeting`}
                        showValidation={showValidation}
                        text="Public Meeting?"
                    />
                )}
                {(isRequiredField || isPublic) && (
                    <Field
                        className={this.styles.checkbox}
                        component={Checkbox}
                        disabled={disabled}
                        inline
                        name={requiredFieldName}
                        qaTag={`timelineForm-${title}RequireAttendance`}
                        showValidation={showValidation}
                        text="Require Attendance?"
                    />
                )}
            </div>
        );
    };

    renderAgenda = (timeline) => {
        const disabled = this.disabled(timeline);
        const { change, isTemplate, project, showValidation } = this.props;

        const {
            agendaUrl,
            index,
            isPublic,
            preProposalAgendaUrl,
            preProposalAgendaUrlField,
            title,
        } = timeline;

        const fieldName = preProposalAgendaUrlField
            ? `${TIMELINE_CONFIG}.${preProposalAgendaUrlField}`
            : `${TIMELINES}.${index}.${AGENDA_URL}`;

        const agendaUrlString = preProposalAgendaUrl || agendaUrl || '';
        const isAgendaUpload = agendaUrlString.includes('government-project.s3.amazonaws');

        const agendaFileUploadHandler = (agendaFile) => {
            return change(
                fieldName,
                `https://${agendaFile.bucket}.s3.amazonaws.com/${agendaFile.path}`
            );
        };

        const agendaFileDeleteHandler = () => {
            return change(fieldName, null);
        };

        if (isTemplate || (!preProposalAgendaUrlField && !isPublic)) {
            return null;
        }

        return (
            <div className="col-xs-offset-6 col-xs-6" key={fieldName}>
                <AttachmentUploader
                    allowSingleAttachmentOnly
                    attachments={
                        isAgendaUpload
                            ? [{ filename: 'Agenda', path: agendaUrlString, url: agendaUrlString }]
                            : []
                    }
                    deleteHandler={agendaFileDeleteHandler}
                    disabled={disabled}
                    dropzoneOptions={{
                        text: 'Drop file or click to select file to upload for meeting agenda',
                    }}
                    label="Agenda"
                    onSuccess={agendaFileUploadHandler}
                    s3GetUrl={`/project/${project.id}/agenda/s3`}
                    skipForm
                />
                {!isAgendaUpload && (
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        label="Agenda URL"
                        name={fieldName}
                        placeholder="Upload agenda above or add URL here"
                        qaTag={`timelineForm-${title}AgendaUrl`}
                        showValidation={showValidation}
                        type="text"
                    />
                )}
            </div>
        );
    };

    renderAuctionOptions = (timeline) => {
        const disabled = this.disabled(timeline);
        const { showValidation } = this.props;
        const {
            auctionExtensionGracePeriodField,
            auctionExtensionTimeField,
            hasAuctionEndDateField,
            hasAuctionExtension,
            hasAuctionExtensionField,
        } = timeline;

        if (!hasAuctionEndDateField) {
            return null;
        }

        const extensionChangeHandler = (e, showExtensionGracePeriodAndTime) => {
            if (!showExtensionGracePeriodAndTime) {
                return this.checkboxChangeHandler({
                    [`${TIMELINE_CONFIG}.${auctionExtensionGracePeriodField}`]: null,
                    [`${TIMELINE_CONFIG}.${auctionExtensionTimeField}`]: null,
                });
            }
        };

        return (
            <div className="col-xs-offset-6 col-xs-6">
                <Field
                    className={this.styles.checkbox}
                    component={Checkbox}
                    disabled={disabled}
                    name={`${TIMELINE_CONFIG}.${hasAuctionExtensionField}`}
                    onChange={extensionChangeHandler}
                    qaTag="timelineForm-hasAuctionExtension"
                    text="Allow Grace Period and Extension Time?"
                />
                {hasAuctionExtension && (
                    <>
                        <Field
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            help={`Time period prior to the end of the scheduled auction end date during which any new
                    bid will extend the auction by the below Extension Time`}
                            label="Extension Grace Period (in minutes)"
                            name={`${TIMELINE_CONFIG}.${auctionExtensionGracePeriodField}`}
                            normalize={this.normalizeTimelineExtensionMinuteLength}
                            qaTag="timelineForm-auctionExtensionGracePeriod"
                            showValidation={showValidation}
                            type="number"
                        />
                        <Field
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            help="Time period to extend the auction when a bid is placed during the grace period"
                            label="Extension Time (in minutes)"
                            name={`${TIMELINE_CONFIG}.${auctionExtensionTimeField}`}
                            normalize={this.normalizeTimelineExtensionMinuteLength}
                            qaTag="timelineForm-auctionExtensionTime"
                            showValidation={showValidation}
                            type="number"
                        />
                    </>
                )}
            </div>
        );
    };

    renderTimelineInputs = () => {
        const { isTemplate, showValidation, timelineData, timezone } = this.props;
        const editMode = this.state.editMode;

        return timelineData.map((timeline, idx) => {
            const disabled = this.disabled(timeline);
            const {
                displayTime,
                hasTextDate,
                index,
                isCustomDate,
                isIncluded,
                projectField,
                required,
                title,
                titleField,
            } = timeline;

            const dateFieldName = projectField || `${TIMELINES}.${index}.${DATE}`;
            const titleFieldName = titleField
                ? `${TIMELINE_CONFIG}.${titleField}`
                : `${TIMELINES}.${index}.${TITLE}`;

            return (
                <Draggable
                    draggableId={titleFieldName}
                    index={idx}
                    isDragDisabled={!isCustomDate}
                    key={titleFieldName}
                >
                    {(provided) => (
                        <span
                            className={classnames('list-group-item', {
                                [this.styles.hide]: !isIncluded && !required && !isCustomDate,
                            })}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                        >
                            <div className="row">
                                {this.renderEditingControls(
                                    index,
                                    isCustomDate,
                                    projectField,
                                    provided
                                )}
                                {editMode && (
                                    <div className="col-xs-5">
                                        <Field
                                            component={InputText}
                                            disabled={disabled}
                                            hasFeedback={false}
                                            name={titleFieldName}
                                            normalize={this.normalizeTimelineTitleLength}
                                            placeholder={title}
                                            qaTag={`timelineForm-${title}TitleField`}
                                            type="text"
                                        />
                                    </div>
                                )}
                                <div className={editMode ? 'col-xs-6' : 'col-xs-12'}>
                                    {!hasTextDate && (
                                        <Field
                                            component={DateTimePicker}
                                            disabled={isTemplate || disabled}
                                            horizontal
                                            label={!editMode ? title : ''}
                                            name={dateFieldName}
                                            showValidation={showValidation}
                                            time={displayTime || false}
                                            timezone={displayTime ? timezone : undefined}
                                        />
                                    )}
                                </div>
                                {this.renderTextDate(timeline)}
                                {this.renderLocation(timeline)}
                                {this.renderPublic(timeline)}
                                {this.renderAgenda(timeline)}
                                {this.renderAuctionOptions(timeline)}
                            </div>
                        </span>
                    )}
                </Draggable>
            );
        });
    };

    render() {
        const {
            array,
            change,
            disabled,
            disableToggles,
            form,
            isTemplate,
            project,
            showValidation,
        } = this.props;

        const { editMode, showDynamicTimelineForm } = this.state;

        const { addBtn, editBtn, editBtnIsEditing, toggleWell } = this.styles;

        return (
            <div className={this.props.className}>
                <DragDropContext onDragEnd={this.handleDragEnd}>
                    <Droppable
                        droppableId="timelineList"
                        isDropDisabled={!editMode}
                        type={TIMELINES}
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getDndStyle(snapshot)}
                                {...provided.droppableProps}
                            >
                                <ListGroup>
                                    {this.renderTimelineInputs()}
                                    {editMode && showDynamicTimelineForm && (
                                        <TimelineCreateForm
                                            cancelAddDate={this.cancelAddDate}
                                            disabled={disabled}
                                            isTemplate={isTemplate}
                                            onSubmit={this.submitHandler}
                                            projectId={project && project.id}
                                        />
                                    )}
                                </ListGroup>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {editMode && !disableToggles && (
                    <Well className={toggleWell}>
                        <TimelineDateToggles
                            array={array}
                            change={change}
                            disabled={disabled}
                            form={form}
                        />
                    </Well>
                )}
                <div className="text-center">
                    {editMode && !showDynamicTimelineForm && (
                        <Button
                            className={addBtn}
                            disabled={disabled}
                            onClick={this.showDynamicTimelineForm}
                            qaTag="timelineForm-addCustomDate"
                        >
                            <i className="fa fa-plus" /> Add Custom Date
                        </Button>
                    )}
                    <Button
                        className={classnames(editMode ? editBtnIsEditing : editBtn)}
                        disabled={disabled}
                        onClick={this.toggleEditMode}
                        qaTag="timelineForm-editTimeline"
                    >
                        <i className="fa fa-pencil" />{' '}
                        {editMode ? 'Done Editing' : 'Edit Timeline Configuration'}
                    </Button>
                </div>

                <Field
                    component={TimelineHasErrorsField}
                    name={TIMELINE_HAS_ERRORS}
                    showError={showValidation}
                />
            </div>
        );
    }
}

export const Timeline = connect(mapStateToProps, { reduxFormSubmit })(ConnectedTimeline);
