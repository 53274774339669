import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@og-pro/ui';

import { Button } from '../../../../components';
import { getUserJS } from '../../../selectors';
import { getAiAudits } from '../../../../actions/adminGovernment';
import { writeAiAuditsLogFile } from './helper';

export const DownloadAiAuditsButton = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const user = useSelector(getUserJS);

    const clickHandler = async () => {
        setLoading(true);
        setError(null);

        const onSuccess = (result) => {
            if (result.length === 0) {
                return setError('No Scope of Work searches found in the last 90 days.');
            }
            return writeAiAuditsLogFile(result, user.organization.timezone);
        };

        const onFailure = (e) => {
            return setError(e.message);
        };

        await dispatch(getAiAudits(user.government_id, onSuccess, onFailure));

        setLoading(false);
    };

    return (
        <Box mt={1}>
            <Button
                bsStyle="link"
                disabled={loading}
                onClick={clickHandler}
                qaTag="siteSettings-downloadAiAudits"
                zeroPadding
            >
                Download Last 90 Days of AI Usage from Scope of Work Generation
            </Button>
            &nbsp;
            {loading && <i className="fa fa-spinner fa-spin" />}
            {error && <div className="text-danger">{error}</div>}
        </Box>
    );
};
