import React, { useContext } from 'react';
import { formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';
import { Box } from '@og-pro/ui';

import { TemplateEditV2FunctionsContext } from '../../../context';
import { ATTACHMENTS_TITLES } from '../../../constants';
import {
    BaseSectionsNavBackNextButtons,
    BaseSectionsNavBackButton,
    BaseSectionsNavNextButton,
    SectionHeaderViewTitleOnly,
} from '../../../../../../../components/SDv2';
import { TemplateAttachmentsForm } from '../../../../forms/ProjectContentForm/components';
import { questionLogicLinkableModelNames } from '../../../../../../../../../shared_config/questionLogics';
import { sectionTypeNames } from '../../../../../../../../../shared_config/sections';
import { form } from '../../../../forms/constants';
import { fieldNames as formFieldNames } from '../../../../forms/TemplateForm/constants';

const { ATTACHMENTS } = sectionTypeNames;
const { ATTACHMENT: ATTACHMENT_LINKABLE } = questionLogicLinkableModelNames;
const selector = formValueSelector(form);

export const TemplateEditV2Attachments = () => {
    const {
        disabled,
        buildRouteFunction,
        isIntake,
        updateTemplateIfDirty,
        router,
        showFormValidation,
        projectId,
        tagOptions,
        templateVariableOptions,
    } = useContext(TemplateEditV2FunctionsContext);
    const useManualNumbering = useSelector((state) =>
        selector(state, formFieldNames.USE_MANUAL_NUMBERING)
    );
    const styles = require('./index.scss');

    return (
        <Box className={styles.container}>
            <Box className={styles.header}>
                <SectionHeaderViewTitleOnly
                    description={ATTACHMENTS_TITLES[0].description}
                    title={ATTACHMENTS_TITLES[0].title}
                />
            </Box>
            <Box className={styles.content}>
                <TemplateAttachmentsForm
                    disabled={disabled}
                    form={form}
                    hideQuestionLogic={false}
                    hideSupportingAttachments={isIntake}
                    isSharedSectionForm={false}
                    projectId={projectId}
                    projectSection={{
                        id: 0.5,
                        section_type: ATTACHMENTS,
                        title: 'Attachments',
                    }}
                    questionLogicLinkable={ATTACHMENT_LINKABLE}
                    showFormValidation={showFormValidation}
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                    useManualNumbering={useManualNumbering}
                    usingFakeSection
                />
            </Box>
            <Box className={styles.footer}>
                <BaseSectionsNavBackNextButtons
                    firstSectionComponent={
                        <BaseSectionsNavBackButton
                            onClick={() => {
                                updateTemplateIfDirty();
                                router.push(buildRouteFunction('document-builder'));
                            }}
                        >
                            Document Editor
                        </BaseSectionsNavBackButton>
                    }
                    lastSectionComponent={
                        <BaseSectionsNavNextButton
                            onClick={() => {
                                updateTemplateIfDirty();
                                router.push(buildRouteFunction('questions-and-automations'));
                            }}
                        >
                            Questions & Automations
                        </BaseSectionsNavNextButton>
                    }
                    sections={[]}
                />
            </Box>
        </Box>
    );
};
