import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';

import { validate } from './validate';
import { fieldNames } from '../constants';
import { AttachmentUploader, Button, InputText, RichTextInput } from '../../../../components';
import { attachmentTypesDict } from '../../../../../../shared_config/attachments';

const { STANDARD_DOCUMENT } = attachmentTypesDict;

const { ATTACHMENT, TEXT, TITLE } = fieldNames;

const formConfig = {
    validate,
};

// @reduxForm
class ConnectedStandardDocumentForm extends PureComponent {
    static propTypes = {
        change: PropTypes.func.isRequired,
        closeForm: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        hasAttachmentOption: PropTypes.bool,
        isCreate: PropTypes.bool,
        s3Url: PropTypes.string,
        serverError: PropTypes.string,
        templateVariableOptions: PropTypes.array,
    };

    constructor(props) {
        super(props);

        this.state = {
            attachmentData: null,
        };
    }

    uploadHandler = (uploadData) => {
        this.props.change(ATTACHMENT, uploadData);
        this.setState({ attachmentData: uploadData });
    };

    render() {
        const {
            closeForm,
            disabled,
            handleSubmit,
            hasAttachmentOption,
            isCreate,
            s3Url,
            serverError,
            templateVariableOptions,
        } = this.props;

        const { attachmentData } = this.state;

        return (
            <form onSubmit={handleSubmit}>
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    label="Document Title"
                    name={TITLE}
                    placeholder="Enter Title"
                    type="text"
                />
                <Field
                    component={RichTextInput}
                    disabled={disabled}
                    label="Document Content (optional)"
                    name={TEXT}
                    placeholder="Enter content"
                    showHeaderToolbar
                    templateVariableOptions={templateVariableOptions}
                />
                {hasAttachmentOption && (
                    <AttachmentUploader
                        allowSingleAttachmentOnly
                        attachmentUploadData={{
                            type: STANDARD_DOCUMENT,
                        }}
                        attachments={attachmentData && [attachmentData]}
                        label="Document"
                        onSuccess={this.uploadHandler}
                        s3GetUrl={s3Url}
                        skipForm
                    />
                )}
                <div className="text-right">
                    <Button bsStyle="link" disabled={disabled} onClick={closeForm}>
                        Cancel
                    </Button>
                    <Button
                        bsStyle={isCreate ? 'success' : 'primary'}
                        disabled={disabled}
                        type="submit"
                    >
                        <i className={`fa fa-${isCreate ? 'plus' : 'check'}`} />
                        &nbsp;
                        {isCreate ? 'Create' : 'Save'} Document
                    </Button>
                    {serverError && <div className="error-block">{serverError}</div>}
                </div>
            </form>
        );
    }
}

export const StandardDocumentForm = reduxForm(formConfig)(ConnectedStandardDocumentForm);
