import { get } from 'lodash';
import moment from 'moment';

import {
    getErrorLocation,
    parseXLSXCompatibleFileToJSON,
    prepareAndValidateRowEntry,
} from '../../../../helpers';
import { purchaseOrderStatuses } from '../../../../../../shared_config/purchaseOrders';

const { ISSUED } = purchaseOrderStatuses;

const DUPE = 'dupe';

// Maps column headers to their corresponding purchase order properties

export const columns = [
    {
        header: 'Vendor ID',
        itemProperty: 'vendorAssignedNo',
        requireValue: false,
        convertToString: true,
    },
    {
        header: 'Contract ID',
        itemProperty: 'contractId',
        requireValue: false,
        convertToString: true,
    },
    {
        header: 'Order Amount',
        enforceType: 'number',
        itemProperty: 'amount',
        requireValue: true,
    },
    {
        header: 'Paid Amount',
        enforceType: 'number',
        itemProperty: 'paidAmount',
        requireValue: false,
    },
    {
        header: 'Fiscal Year',
        itemProperty: 'fiscalYear',
        requireValue: false,
        convertToString: true,
        csvFormatter: (purchaseOrder) => purchaseOrder.tag.name,
    },
    {
        header: 'Department',
        itemProperty: 'department',
        requireValue: false,
        csvFormatter: (purchaseOrder) => purchaseOrder.department.name,
    },
    {
        header: 'Issue Date',
        enforceType: 'date',
        itemProperty: 'date',
        requireValue: false,
        csvFormatter: (purchaseOrder) => moment(purchaseOrder.date).format('ll'),
    },
    {
        header: 'PO Number',
        itemProperty: 'number',
        maxLength: 64,
        requireValue: false,
        convertToString: true,
    },
    {
        header: 'Description',
        itemProperty: 'description',
        requireValue: false,
    },
    {
        header: 'Comments',
        itemProperty: 'notes',
        requireValue: false,
    },
];

// Case-insensitive lookup for all valid table headers
const columnHeaderToPurchaseOrderProperties = columns.reduce((obj, column) => {
    obj[column.header.toLowerCase()] = column;
    return obj;
}, {});

const rowError = (rowFromSpreadsheet, errorMessage) => {
    throw new Error(`${getErrorLocation(rowFromSpreadsheet)}${errorMessage}`);
};

const findInList = (list, itemToFind, key) => {
    if (!itemToFind) {
        return undefined;
    }
    return list.find((item) => {
        if (!item[key]) {
            return false;
        }
        return item[key].trim().toLowerCase() === itemToFind.trim().toLowerCase();
    });
};

const countInList = (list, itemToFind, key) => {
    if (!itemToFind) {
        return 0;
    }
    return list.filter((item) => {
        if (!item[key]) {
            return false;
        }
        return item[key].trim().toLowerCase() === itemToFind.trim().toLowerCase();
    }).length;
};

const getDepartmentInfo = (poDepartment, departments, user) => {
    const department = findInList(departments, poDepartment, 'name') || user.department;
    return {
        department,
        department_id: department.id,
    };
};

const getFiscalYearTagInfo = (poFiscalYear, fiscalYears) => {
    const defaultFiscalYear = fiscalYears.find((fy) => fy.default) || fiscalYears[0];
    const tag = findInList(fiscalYears, poFiscalYear, 'name') || defaultFiscalYear;
    return {
        tag,
        tag_id: tag.id,
    };
};

const matchToContract = (contracts, purchaseOrder) => {
    const { contractId, vendorAssignedNo } = purchaseOrder;

    const contractIdCount = countInList(contracts, contractId, 'contractId');
    const contractFoundByContractId = findInList(contracts, contractId, 'contractId');
    const vendorIdCount = countInList(contracts, vendorAssignedNo, 'vendorAssignedNo');
    const contractFoundByVendorId = findInList(contracts, vendorAssignedNo, 'vendorAssignedNo');

    if (contractIdCount === 1) {
        return contractFoundByContractId;
    }
    if (vendorIdCount === 1) {
        return contractFoundByVendorId;
    }

    // If we are here it's because there are multiple contract ID/vendor ID matches (dupe) or we
    // did not find any matches (skip)
    const foundContract = contractFoundByContractId || contractFoundByVendorId;
    if (foundContract) {
        return DUPE;
    }

    return null;
};

export const groupedPurchaseOrders = (contracts, purchaseOrdersToImport) => {
    const purchaseOrderGroups = {
        create: [],
        duplicate: [],
        existing: [],
        unmatched: [],
        invalidPaidAmount: [],
    };

    const purchaseOrdersMap = contracts.reduce((purchaseOrdersObj, contract) => {
        const purchaseOrders = get(contract, 'budget.purchaseOrders') || [];
        purchaseOrders.forEach((purchaseOrder) => {
            purchaseOrdersObj[purchaseOrder.id] = purchaseOrder;
        });
        return purchaseOrdersObj;
    }, {});

    const purchaseOrders = Object.values(purchaseOrdersMap);

    purchaseOrdersToImport.forEach((purchaseOrder) => {
        const existingContract = matchToContract(contracts, purchaseOrder);

        if (purchaseOrder.paidAmount > purchaseOrder.amount) {
            purchaseOrder.budget_id = existingContract.budget_id;
            purchaseOrder.contract_party_id = existingContract.contractParty.id;
            purchaseOrder.contractParty = existingContract.contractParty;
            return purchaseOrderGroups.invalidPaidAmount.push(purchaseOrder);
        }

        const existingPurchaseOrder =
            findInList(purchaseOrders, purchaseOrder.number, 'number') ||
            findInList(purchaseOrders, purchaseOrder.invoiceIdentifier, 'invoiceIdentifier');

        if (existingPurchaseOrder) {
            return purchaseOrderGroups.existing.push({
                ...existingPurchaseOrder,
                ...purchaseOrder,
            });
        }

        if (existingContract === DUPE) {
            return purchaseOrderGroups.duplicate.push({
                contractParty: {
                    companyName: 'MULTIPLE MATCHES FOUND!',
                },
                ...purchaseOrder,
            });
        }

        if (existingContract && existingContract.contractParty) {
            purchaseOrder.budget_id = existingContract.budget_id;
            purchaseOrder.contract_party_id = existingContract.contractParty.id;
            purchaseOrder.contractParty = existingContract.contractParty;
            return purchaseOrderGroups.create.push(purchaseOrder);
        }

        return purchaseOrderGroups.unmatched.push({
            contractParty: {
                companyName: 'NO MATCH FOUND!',
            },
            ...purchaseOrder,
        });
    });

    return purchaseOrderGroups;
};

const buildPurchaseOrder = (purchaseOrderFromSpreadsheet, fiscalYears, departments, user) => {
    const purchaseOrderData = prepareAndValidateRowEntry(
        purchaseOrderFromSpreadsheet,
        columnHeaderToPurchaseOrderProperties
    );

    const { date, department, fiscalYear, status, ...purchaseOrderFormattedData } =
        purchaseOrderData;

    const purchaseOrder = {
        ...purchaseOrderFormattedData,
        ...getDepartmentInfo(department, departments, user),
        ...getFiscalYearTagInfo(fiscalYear, fiscalYears),
        date: date || new Date(),
        status: ISSUED,
        user_id: user.id,
    };

    if (!purchaseOrder.vendorAssignedNo && !purchaseOrder.contractId) {
        rowError(purchaseOrderFromSpreadsheet, 'Must specify a "Vendor ID" or "Contract ID"');
    }

    if (!purchaseOrder.amount && purchaseOrder.amount !== 0) {
        rowError(purchaseOrderFromSpreadsheet, 'Must specify an "Order Amount"');
    }

    return purchaseOrder;
};

/*
 * Extract purchase order rows from a purchase order spreadsheet file.
 * Returns the two values necessary for initializing a purchase order:
 *   - priceItems (an array of objects)
 *   - priceTableConfig  (a config object)
 * Assumes the CSV file (or the first sheet of the spreadsheet file) will have a header row with
 * one or more purchase order headers.
 */
export const parsePurchaseOrdersFile = (file, fiscalYears, departments, user) => {
    return parseXLSXCompatibleFileToJSON(file).then((arrayOfRows) => {
        return arrayOfRows.map((purchaseOrder) => {
            return buildPurchaseOrder(purchaseOrder, fiscalYears, departments, user);
        });
    });
};
