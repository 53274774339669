import { get, isEmpty, isFunction, sortBy, startCase } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';

import { FLAGS, withFlags } from '../../../lib/launchdarkly';

import { HelpIcon } from '../../InputText';

import {
    COMPANY_NAME,
    CONTACT_DISPLAY_NAME,
    CONTRACT_ID,
    CONTRACT_BUDGET_AMOUNT,
    CONTRACT_BUDGET_ISSUED,
    CONTRACT_RENEWALS,
    DEPARTMENT_NAME,
    DURATION_TYPE,
    END_DATE,
    FUNDING_SOURCE_TAGS,
    HAS_CLAIM,
    HAS_PROTEST,
    IS_COOPERATIVE,
    IS_EMERGENCY,
    IS_PIGGYBACK,
    IS_PUBLIC,
    NOTE,
    PROCUREMENT_CLASSIFICATION_TAG,
    PROCUREMENT_DISPLAY_NAME,
    REBID,
    REQUISITION_IDENTIFIER,
    START_DATE,
    STATUS,
    SUMMARY,
    TAGS,
    TITLE,
    VENDOR_ASSIGNED_NO,
    form,
    SEARCH,
} from './constants';
import { getLoadingContractsForCSVExportJS } from './selectors';
import { getContractModulePath, getParsedQueryParams } from '../../../containers/GovApp/selectors';
import { getUserJS } from '../../../containers/selectors';
import { contractStatusClass } from '../../helpers/statusHelper';
import { currencyFormatter, isEmbedded } from '../../../helpers';
import {
    DEFAULT_PAGE_SIZE_LIMIT,
    contractStatuses,
    contractStatusesTextMap,
    durationTextMap,
} from '../../../../../shared_config/contracts';
import { sortedCertificationsList } from '../../../../../shared_config/certifications';
import { DataTable } from '../../DataTable/DataTable';
import { StatusLabel } from '../../StatusLabel';
import { UserProfilePicture } from '../../UserProfilePicture/UserProfilePicture';
import { TableSearchForm } from './TableSearchForm';
import { conditionalPropCheck, stripHtml } from '../../../utils';
import { dataTypesDict } from '../../../constants';
import { Tooltip } from '../../Tooltip';
import { Button } from '../../Button';
import { storeLastFilter } from '../../../actions/auth';
import {
    getUserDefaultFilters,
    getUserDefaultSort,
} from '../../../containers/GovApp/GovernmentContractsListNav/selectors';
import { loadContractsForCSVExport } from '../../../actions/contracts';
import { parseContractsFilters } from '../../../containers/GovApp/GovernmentContractsListNav/helpers';

const { BOOLEAN, FUNCTION, NODE, STRING } = dataTypesDict;

const mapStateToProps = (state, props) => {
    return {
        contractPath: getContractModulePath(state, props),
        csvExportIsLoading: getLoadingContractsForCSVExportJS(state),
        queryParams: getParsedQueryParams(state, props),
        user: getUserJS(state),
        userDefaultFilters: getUserDefaultFilters(state),
        userDefaultSort: getUserDefaultSort(state),
        searchFormValue: formValueSelector(form)(state, SEARCH),
    };
};

const mapDispatchToProps = {
    dispatchStoreLastFilter: storeLastFilter,
    dispatchLoadContractsForCSVExport: loadContractsForCSVExport,
};

// @withRouter
// @connect
class ConnectedTableView extends Component {
    static propTypes = {
        buttons: PropTypes.arrayOf(conditionalPropCheck(NODE, ({ props }) => !props.isPublicView)),
        contracts: PropTypes.arrayOf(
            PropTypes.shape({
                budget: PropTypes.shape({
                    amount: PropTypes.number,
                    issued: PropTypes.number,
                    paid: PropTypes.number,
                }).isRequired,
                [DEPARTMENT_NAME]: PropTypes.string.isRequired,
                [END_DATE]: PropTypes.string,
                [FUNDING_SOURCE_TAGS]: PropTypes.array.isRequired,
                government: PropTypes.shape({
                    id: PropTypes.number.isRequired,
                }),
                [HAS_CLAIM]: PropTypes.bool,
                [HAS_PROTEST]: PropTypes.bool,
                id: PropTypes.number.isRequired,
                [IS_COOPERATIVE]: PropTypes.bool,
                [IS_EMERGENCY]: PropTypes.bool,
                [IS_PIGGYBACK]: PropTypes.bool,
                [IS_PUBLIC]: PropTypes.bool.isRequired,
                [PROCUREMENT_DISPLAY_NAME]: PropTypes.string,
                [REBID]: PropTypes.bool,
                [START_DATE]: PropTypes.string.isRequired,
                [STATUS]: PropTypes.oneOf(contractStatuses).isRequired,
                [SUMMARY]: PropTypes.string,
                [TAGS]: PropTypes.array.isRequired,
                [TITLE]: PropTypes.string.isRequired,
            })
        ).isRequired,
        contractPath: PropTypes.string.isRequired,
        getFlag: PropTypes.func.isRequired,
        csvExportIsLoading: PropTypes.bool,
        defaultSorted: PropTypes.arrayOf(
            PropTypes.shape({
                id: conditionalPropCheck(STRING, ({ props }) => !props.isPublicView),
                desc: conditionalPropCheck(BOOLEAN, ({ props }) => !props.isPublicView),
            })
        ),
        dispatchLoadContractsForCSVExport: PropTypes.func.isRequired,
        executeSearch: conditionalPropCheck(
            FUNCTION,
            ({ props }) => !props.isVendorView && !props.isPublicView
        ),
        isPublicView: PropTypes.bool,
        isVendorView: PropTypes.bool,
        location: PropTypes.object.isRequired,
        noDataText: PropTypes.string,
        pageSize: PropTypes.number,
        params: PropTypes.shape({
            governmentCode: PropTypes.string, // Available when `!props.isVendorView && props.isPublicView`
            governmentId: PropTypes.string, // Available when `!props.isVendorView && !props.isPublicView`
            projectId: PropTypes.string,
        }),
        resetDefaultSorted: PropTypes.func,
        router: PropTypes.object.isRequired,
        searchFormValue: PropTypes.string,
        showPagination: PropTypes.bool,
        queryParams: PropTypes.shape({
            detailView: PropTypes.string,
            filters: PropTypes.arrayOf(PropTypes.object),
            ids: PropTypes.string,
            page: PropTypes.number,
            limit: PropTypes.number,
            quickSearchQuery: PropTypes.string,
            sort: PropTypes.arrayOf(
                PropTypes.shape({
                    desc: PropTypes.bool,
                    id: PropTypes.string,
                })
            ),
        }),
        user: PropTypes.shape({
            government: PropTypes.shape({
                hideContractSpend: PropTypes.bool.isRequired,
            }),
            filters: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.shape({
                        defaultFilters: PropTypes.array,
                        defaultSorted: PropTypes.array,
                    }),
                })
            ),
            lastFilter: PropTypes.shape({
                contract: PropTypes.shape({
                    filters: PropTypes.array,
                    sort: PropTypes.array,
                }),
            }),
        }),
        userDefaultFilters: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string,
                value: PropTypes.string,
            })
        ),
        userDefaultSort: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                desc: PropTypes.bool,
                length: PropTypes.number,
            })
        ),
    };

    get styles() {
        return require('../index.scss');
    }

    get isGovView() {
        const { isVendorView, isPublicView } = this.props;
        return !isVendorView && !isPublicView;
    }

    get showSpend() {
        const { isPublicView, user } = this.props;
        return !isPublicView && !get(user, 'government.hideContractSpend');
    }

    get columns() {
        const { contractPath, isVendorView, router } = this.props;
        const { getFlag } = this.props;
        const isPiggybackFlag = getFlag(FLAGS.ENABLE_PIGGYBACK_LANGUAGE);

        const amountSpentColumn = {
            Header: 'Amount Issued',
            accessor: CONTRACT_BUDGET_ISSUED,
            minResizeWidth: 10,
            minWidth: 125,
            Cell: (props) => {
                const spend = props.original.budget.issued + props.original.budget.paid;

                if (this.isGovView && !spend) {
                    const clickHandler = (e) => {
                        e.stopPropagation();
                        const contractId = props.original.id;
                        return router.push(`${contractPath}/${contractId}/spend-management`);
                    };

                    return (
                        <div onClick={(e) => clickHandler(e)}>
                            <a>Add Spend</a>
                        </div>
                    );
                }

                return <div>{currencyFormatter({ ...props, value: spend })}</div>;
            },
        };

        return [
            {
                Header: isVendorView ? 'Agency' : 'Vendor',
                accessor: isVendorView ? 'government.organization.name' : COMPANY_NAME,
                minResizeWidth: 50,
                minWidth: 240,
                Cell: (props) => {
                    const entity = isVendorView
                        ? props.original.government
                        : props.original.contractParty.vendor;

                    return (
                        <div style={{ whiteSpace: 'normal' }}>
                            <img
                                alt={`${props.value} logo`}
                                className={`img-rounded ${this.styles.logo}`}
                                src={
                                    entity
                                        ? entity.organization.logo
                                        : 'https://assets.procurement.opengov.com/assets/no-logo.png'
                                }
                            />
                            <div>{props.value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Title',
                accessor: TITLE,
                minResizeWidth: 10,
                minWidth: 240,
                Cell: (props) => {
                    const status = props.original.status;
                    return (
                        <div>
                            {props.value}
                            <br />
                            <StatusLabel
                                bsStyle={contractStatusClass(status)}
                                className={this.styles.statusLabel}
                            >
                                {startCase(status)}
                            </StatusLabel>
                        </div>
                    );
                },
            },
            {
                Header: 'Contract ID',
                accessor: CONTRACT_ID,
                minResizeWidth: 10,
            },
            {
                Header: (
                    <div>
                        {isPiggybackFlag ? 'Cooperative' : 'Piggyback'}
                        <HelpIcon
                            style={{ color: 'grey' }}
                            tooltip={
                                <div>
                                    Contracts with an “X” in this column contain cooperative
                                    language, which can sometimes be called “piggyback.”
                                </div>
                            }
                        />
                    </div>
                ),

                accessor: IS_PIGGYBACK,
                minResizeWidth: 15,
                minWidth: 130,
                Cell: this.booleanCellRenderer,
            },
            ...(this.showSpend ? [amountSpentColumn] : []),
            {
                Header: 'Budget Amount',
                accessor: CONTRACT_BUDGET_AMOUNT,
                minResizeWidth: 10,
                minWidth: 125,
                Cell: (props) => <div>{props.value === null ? '' : currencyFormatter(props)}</div>,
                sortMethod: (a, b) => {
                    // Default sorting wasn't forcing empty values to the top or bottom.
                    // Got this sort from the docs: https://github.com/tannerlinsley/react-table/tree/v6#multi-sort
                    a = a === null ? -Infinity : a;
                    b = b === null ? -Infinity : b;

                    if (a > b) {
                        return 1;
                    }

                    if (a < b) {
                        return -1;
                    }

                    return 0;
                },
            },
            {
                Header: 'Department',
                accessor: DEPARTMENT_NAME,
                minResizeWidth: 10,
            },
            ...(this.isGovView
                ? [
                      {
                          Header: 'Project Contact',
                          accessor: CONTACT_DISPLAY_NAME,
                          minResizeWidth: 10,
                          minWidth: 150,
                          Cell: (props) => {
                              const { projectContact } = props.original;
                              const displayName = props.value;

                              if (!projectContact && isEmpty(displayName)) {
                                  return null;
                              }

                              const user = {
                                  avatarUrl: projectContact ? projectContact.avatarUrl : null,
                                  displayName,
                                  email: props.original.contactEmail,
                                  initials: props.original.contactInitials,
                              };

                              const contactInfoTooltip = (
                                  <>
                                      <strong>Phone:</strong> {props.original.contactPhone}
                                      <br />
                                      <strong>Email:</strong> {user.email}
                                  </>
                              );

                              return (
                                  <Tooltip
                                      isPopover
                                      placement="bottom"
                                      tooltip={contactInfoTooltip}
                                  >
                                      <div>
                                          {this.isGovView && projectContact && (
                                              <UserProfilePicture
                                                  className={this.styles.userProfilePicture}
                                                  horizontal
                                                  showTooltip={false}
                                                  user={user}
                                              />
                                          )}
                                          {displayName}{' '}
                                          <i
                                              className={`fa fa-info-circle ${this.styles.infoIcon}`}
                                          />
                                      </div>
                                  </Tooltip>
                              );
                          },
                      },
                  ]
                : []),
            {
                Header: 'Procurement Contact',
                accessor: PROCUREMENT_DISPLAY_NAME,
                minResizeWidth: 10,
                minWidth: 160,
                Cell: (props) => {
                    const { procurementContact } = props.original;
                    const displayName = props.value;

                    if (!procurementContact && isEmpty(displayName)) {
                        return null;
                    }

                    const user = {
                        avatarUrl: procurementContact ? procurementContact.avatarUrl : null,
                        displayName,
                        email: props.original.procurementEmail,
                        initials: props.original.procurementInitials,
                    };

                    const contactInfoTooltip = (
                        <>
                            <strong>Phone:</strong> {props.original.procurementPhoneComplete}
                            <br />
                            <strong>Email:</strong> {props.original.procurementEmail}
                        </>
                    );

                    return (
                        <Tooltip isPopover placement="bottom" tooltip={contactInfoTooltip}>
                            <div>
                                {this.isGovView && procurementContact && (
                                    <UserProfilePicture
                                        className={this.styles.userProfilePicture}
                                        horizontal
                                        showTooltip={false}
                                        user={user}
                                    />
                                )}
                                {displayName}{' '}
                                <i className={`fa fa-info-circle ${this.styles.infoIcon}`} />
                            </div>
                        </Tooltip>
                    );
                },
            },
            {
                Header: 'Term Type',
                accessor: (row) => durationTextMap[row[DURATION_TYPE]],
                id: DURATION_TYPE,
                minResizeWidth: 10,
            },
            {
                Header: 'Renewal Option',
                accessor: (row) => row.contractRenewals.some((renewal) => !renewal.selected),
                id: CONTRACT_RENEWALS,
                minResizeWidth: 10,
                minWidth: 125,
                Cell: this.booleanCellRenderer,
            },
            {
                Header: 'End Date',
                accessor: END_DATE,
                minResizeWidth: 10,
                Cell: (props) => {
                    const { timezone } = props.original.government.organization;

                    return <div>{props.value && moment(props.value).tz(timezone).format('l')}</div>;
                },
            },
        ];
    }

    booleanCellRenderer = (props) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <i
                    className={classNames(
                        'fa',
                        `fa-${props.value ? 'check' : 'times'}`,
                        this.styles.booleanIcon,
                        props.value ? this.styles.isTrue : this.styles.isFalse
                    )}
                />
            </div>
        );
    };

    formatDataForCSVExport = async (data) => {
        const {
            dispatchLoadContractsForCSVExport,
            isVendorView,
            isPublicView,
            params,
            queryParams,
        } = this.props;
        const governmentCode = params.governmentCode;

        const renderCertCells = (rowData) => {
            const vendorCerts = get(
                rowData,
                ['contractParty', 'vendor', 'vendorCertifications'],
                []
            );
            return sortedCertificationsList.map((cert) => {
                if (vendorCerts.some((vCert) => vCert.certification_id === cert.id)) {
                    return 1;
                }
                return 0;
            });
        };

        const headers = [
            isVendorView ? 'Agency' : 'Vendor',
            ...(this.isGovView ? ['Vendor Email'] : []),
            ...(!isVendorView ? ['City'] : []),
            ...(!isVendorView ? ['State'] : []),
            ...(!isVendorView ? ['Zip Code'] : []),
            'Title',
            'Status',
            'Contract ID',
            ...(this.showSpend ? ['Amount Spent'] : []),
            'Budget Amount',
            'Department',
            ...(this.isGovView ? ['Project Contact'] : []),
            'Procurement Contact',
            'Term Type',
            'Renewal Option',
            'End Date',
            'Type',
            'Funding Source',
            'Start Date',
            ...(this.isGovView ? ['Rebid'] : []),
            'Piggyback',
            'Cooperative',
            'Bid Protest',
            'Contract Claim',
            'Is Public',
            'Summary',
            ...(this.isGovView ? ['Is Emergency'] : []),
            ...(this.isGovView ? ['Requisition ID'] : []),
            ...(this.isGovView ? ['Vendor Assigned Number'] : []),
            ...(this.isGovView ? ['Procurement Classification'] : []),
            ...(this.isGovView ? ['Notes'] : []),
            ...(this.isGovView ? sortedCertificationsList.map((cert) => cert.title) : []),
        ];

        let rows = [];
        const formatTags = (tags) => (tags || []).map((tag) => tag.name).join(', ');

        // Vendor view of contract list gets contracts from a separate endpoint - /me/awards/contracts
        // This endpoint has not been paginated and does not need to be handled differently compared to /contracts/list
        // Public view of contract list has not been paginated
        if (this.isGovView) {
            const { filters, ids, quickSearchQuery, sort } = queryParams;

            const { contracts: exportedContracts } = await dispatchLoadContractsForCSVExport({
                exportAsCSV: true,
                filters: parseContractsFilters(filters),
                ids,
                publicView: isPublicView,
                quickSearchQuery,
                sort,
                governmentCode,
            });

            rows = exportedContracts.map((contract) => {
                const contractStartDate = get(contract, START_DATE);
                const contractEndDate = get(contract, END_DATE);
                const governmentTimezone = contract.government.organization.timezone;
                const tags = formatTags(get(contract, TAGS));
                const fundingSourceTags = formatTags(get(contract, FUNDING_SOURCE_TAGS));
                const { note, summary } = contract;
                const strippedHtmlNote = stripHtml(note);
                const strippedHtmlSummary = stripHtml(summary);
                const contractTitleWithoutNewlines = get(contract, TITLE).replace(/\r?\n/g, ' ');

                return [
                    get(contract, COMPANY_NAME),
                    contract.contractParty.companyEmail ?? '',
                    contract.contractParty.vendor?.organization.city ?? '',
                    contract.contractParty.vendor?.organization.state ?? '',
                    contract.contractParty.vendor?.organization.zipCode ?? '',
                    contractTitleWithoutNewlines,
                    contractStatusesTextMap[contract.status],
                    get(contract, CONTRACT_ID) ?? '',
                    ...(this.showSpend ? [contract.budget.issued + contract.budget.paid] : []),
                    contract.budget.amount ?? '',
                    get(contract, DEPARTMENT_NAME) ?? '',
                    ...(this.isGovView ? [get(contract, CONTACT_DISPLAY_NAME, '')] : []),
                    get(contract, PROCUREMENT_DISPLAY_NAME, ''),
                    durationTextMap[get(contract, DURATION_TYPE)],
                    get(contract, CONTRACT_RENEWALS).length > 0 ? 1 : 0,
                    contractEndDate
                        ? moment(contractEndDate).tz(governmentTimezone).format('l')
                        : '',
                    tags,
                    fundingSourceTags,
                    contractStartDate
                        ? moment(contractStartDate).tz(governmentTimezone).format('l')
                        : '',
                    ...(this.isGovView ? [get(contract, REBID) ? 1 : 0] : []),
                    get(contract, IS_PIGGYBACK) ? 1 : 0,
                    get(contract, IS_COOPERATIVE) ? 1 : 0,
                    get(contract, HAS_PROTEST) ? 1 : 0,
                    get(contract, HAS_CLAIM) ? 1 : 0,
                    contract[IS_PUBLIC] ? 1 : 0,
                    strippedHtmlSummary ?? '',
                    ...(this.isGovView ? [get(contract, IS_EMERGENCY) ? 1 : 0] : []),
                    ...(this.isGovView ? [get(contract, REQUISITION_IDENTIFIER)] : []),
                    ...(this.isGovView ? [get(contract, VENDOR_ASSIGNED_NO) ?? ''] : []),
                    ...(this.isGovView
                        ? [get(contract[PROCUREMENT_CLASSIFICATION_TAG], 'name') ?? '']
                        : []),
                    ...(this.isGovView ? [strippedHtmlNote ?? ''] : []),
                    ...(this.isGovView ? renderCertCells(contract) : []),
                ];
            });
        } else {
            rows = data.map((dataRow) => {
                const originalData = dataRow._original; // eslint-disable-line no-underscore-dangle
                const budgetAmount = dataRow[CONTRACT_BUDGET_AMOUNT];
                const endDate = dataRow[END_DATE];
                const startDate = originalData[START_DATE];
                const { timezone } = originalData.government.organization;
                const vendorOrgData = get(
                    originalData,
                    ['contractParty', 'vendor', 'organization'],
                    {}
                );
                const tags = formatTags(get(originalData, TAGS));
                const fundingSourceTags = formatTags(get(originalData, FUNDING_SOURCE_TAGS));
                const strippedHtmlNote = stripHtml(originalData[NOTE]);
                const strippedHtmlSummary = stripHtml(originalData[SUMMARY]);

                return [
                    dataRow[isVendorView ? 'government.organization.name' : COMPANY_NAME] || '',
                    ...(this.isGovView ? [originalData?.contractParty?.companyEmail] : []),
                    ...(!isVendorView ? [vendorOrgData.city] : []),
                    ...(!isVendorView ? [vendorOrgData.state] : []),
                    ...(!isVendorView ? [vendorOrgData.zipCode] : []),
                    dataRow[TITLE] ?? '',
                    contractStatusesTextMap[originalData.status],
                    dataRow[CONTRACT_ID] ?? '',
                    ...(this.showSpend
                        ? [originalData.budget.issued + originalData.budget.paid]
                        : []),
                    budgetAmount === null ? '' : budgetAmount,
                    dataRow[DEPARTMENT_NAME] ?? '',
                    ...(this.isGovView ? [dataRow[CONTACT_DISPLAY_NAME] || ''] : []),
                    dataRow[PROCUREMENT_DISPLAY_NAME] || '',
                    dataRow[DURATION_TYPE] ?? '',
                    dataRow[CONTRACT_RENEWALS] ? 1 : 0,
                    endDate ? moment(endDate).tz(timezone).format('l') : '',
                    tags,
                    fundingSourceTags,
                    startDate ? moment(startDate).tz(timezone).format('l') : '',
                    ...(this.isGovView ? [originalData[REBID] ? 1 : 0] : []),
                    originalData[IS_PIGGYBACK] ? 1 : 0,
                    originalData[IS_COOPERATIVE] ? 1 : 0,
                    originalData[HAS_PROTEST] ? 1 : 0,
                    originalData[HAS_CLAIM] ? 1 : 0,
                    originalData[IS_PUBLIC] ? 1 : 0,
                    strippedHtmlSummary ?? '',
                    ...(this.isGovView ? [originalData[IS_EMERGENCY] ? 1 : 0] : []),
                    ...(this.isGovView ? [originalData[REQUISITION_IDENTIFIER] ?? ''] : []),
                    ...(this.isGovView ? [originalData[VENDOR_ASSIGNED_NO] || ''] : []),
                    ...(this.isGovView
                        ? [get(originalData[PROCUREMENT_CLASSIFICATION_TAG], 'name', '')]
                        : []),
                    ...(this.isGovView ? [strippedHtmlNote ?? ''] : []),
                    ...(this.isGovView ? renderCertCells(originalData) : []),
                ];
            });
        }

        return [headers].concat(rows);
    };

    handleRowClick = (contract) => {
        const {
            location,
            params: paramsProp,
            queryParams: { detailView },
            router,
        } = this.props;

        const governmentCode =
            get(paramsProp, 'governmentCode') || get(contract, 'government.code');
        const governmentId = get(paramsProp, 'governmentId') || get(contract, 'government.id');
        const projectId = get(paramsProp, 'projectId');
        const projectSection = projectId ? `projects/${projectId}/` : '';

        let contractUrl = `/portal/${governmentCode}/${projectSection}contracts/${contract.id}`;

        if (isEmbedded(location)) {
            return window.open(`${process.env.SITE_URL}${contractUrl}`);
        }

        if (this.isGovView) {
            contractUrl = `/governments/${governmentId}/${projectSection}contracts/${contract.id}`;
        }

        if (detailView) {
            contractUrl += `#${detailView}`;
        }

        router.push(contractUrl);
    };

    getContracts = () => {
        const {
            contracts,
            queryParams: { sort },
            searchFormValue,
        } = this.props;
        let contractsData = contracts;

        // Filter contracts based on search in vendor view and public view
        // This is to filter the data displayed in the table, when the API response returns all contracts, not paginated results
        if (searchFormValue && !this.isGovView) {
            const query = searchFormValue.toLowerCase();

            contractsData = contracts.filter((contract) => {
                const companyName = contract.contractParty && contract.contractParty.companyName;
                const contractId = contract.contractId;
                const contractTitle = contract.title;
                const procurementContactName = contract.procurementDisplayName;
                const projectContactName = contract.contactDisplayName;

                const id = contractId ? contractId.toLowerCase() : '';
                const procurementContact = procurementContactName
                    ? procurementContactName.toLowerCase()
                    : '';
                const projectContact = projectContactName ? projectContactName.toLowerCase() : '';
                const title = contractTitle ? contractTitle.toLowerCase() : '';
                const vendor = companyName ? companyName.toLowerCase() : '';

                return (
                    id.includes(query) ||
                    procurementContact.includes(query) ||
                    projectContact.includes(query) ||
                    title.includes(query) ||
                    vendor.includes(query)
                );
            });
        }

        if (sort) {
            return contractsData;
        }

        return sortBy(contractsData, 'status');
    };

    sortHandler = (newSort) => {
        const { executeSearch } = this.props;

        return executeSearch({ sort: newSort }, { resetPage: true });
    };

    renderResetFiltersButton = () => {
        if (!this.isGovView) {
            return null;
        }

        const onResetContractFilters = () => {
            const {
                executeSearch,
                resetDefaultSorted,
                pageSize,
                queryParams,
                userDefaultFilters,
                userDefaultSort,
            } = this.props;

            let actualPageSize;

            // Prefer the user's selected page size
            if (pageSize) {
                actualPageSize = pageSize;
            } else if (queryParams.limit) {
                // Fallback to page size from URL
                actualPageSize = queryParams.limit;
            } else {
                // Default page size otherwise
                actualPageSize = DEFAULT_PAGE_SIZE_LIMIT;
            }

            const data = {
                filters: userDefaultFilters,
                limit: actualPageSize,
                page: 1,
                quickSearchQuery: '',
                sort: userDefaultSort,
            };

            resetDefaultSorted();
            return executeSearch(data, { resetPage: true });
        };

        return (
            <Button
                bsSize="sm"
                className={this.styles.resetContractFiltersButton}
                onClick={onResetContractFilters}
                qaTag="contract-resetFilters"
            >
                <i className="fa fa-rotate-right" /> Reset All Filters
            </Button>
        );
    };

    getComponentsInlineWithButtons = () => {
        const { executeSearch, queryParams, searchFormValue } = this.props;

        const handleSubmitContractSearch = (e) => {
            e.preventDefault(); // Prevent page from refreshing when clicking search button

            // Searching in public and vendor view are handled with this.getContracts
            // In the government view, the search is handled via the API and requires a separate function call
            if (this.isGovView) {
                return executeSearch(
                    {
                        quickSearchQuery: searchFormValue,
                    },
                    { resetPage: true }
                );
            }
        };

        return (
            <div className={this.styles.inlineComponentsContainer}>
                <div className={`col-md-6 col-xs-12 ${this.styles.searchForm}`}>
                    <div className={this.styles.searchInput}>
                        <TableSearchForm
                            enableReinitialize // Needed to display the correct initial value for the search field
                            initialValues={
                                queryParams.quickSearchQuery
                                    ? { search: queryParams.quickSearchQuery }
                                    : {}
                            }
                            isGovView={this.isGovView}
                            onSubmitContractSearch={handleSubmitContractSearch}
                        />
                    </div>
                </div>
                {this.renderResetFiltersButton()}
            </div>
        );
    };

    render() {
        const {
            buttons,
            csvExportIsLoading,
            defaultSorted,
            noDataText,
            pageSize,
            queryParams,
            showPagination,
        } = this.props;

        const sorted = queryParams.sort ? [...queryParams.sort] : [];
        const sortProps = this.isGovView ? { onSortedChange: this.sortHandler, sorted } : {};

        return (
            <DataTable
                buttons={buttons}
                className={classNames('-striped', '-highlight', this.styles.contractsTable)}
                columns={this.columns}
                componentsInlineWithButtons={this.getComponentsInlineWithButtons()}
                csvExportIsLoading={csvExportIsLoading}
                csvExportOptions={{
                    fileName: 'Contracts List',
                    getFormattedCSVData: this.formatDataForCSVExport,
                    headers: true,
                }}
                data={this.getContracts()}
                defaultSorted={defaultSorted}
                getTrProps={(state, rowInfo) => {
                    // Bug in `react-table` passes undefined as rowInfo for extra rows with no data
                    // https://github.com/tannerlinsley/react-table/issues/1023
                    if (!rowInfo) {
                        return {};
                    }
                    return {
                        onClick: (e, handleOriginal) => {
                            this.handleRowClick(rowInfo.original);

                            // 2/16/18: handleOriginal is not always a function:
                            // https://github.com/react-tools/react-table/issues/406
                            if (isFunction(handleOriginal)) return handleOriginal();
                        },
                    };
                }}
                noDataText={noDataText || 'No rows found'}
                pageSize={this.isGovView ? pageSize : undefined}
                showCSVExport
                showPagination={showPagination}
                {...sortProps}
            />
        );
    }
}

export const TableView = compose(
    withRouter,
    withFlags(),
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedTableView);
