import { truncate } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ContractComplaintsList } from '../../components';
import { StarsDisplay, UserProfilePicture } from '../../../../../components';
import { contractReviewTypeNames } from '../../../../../../../shared_config/contractReviews';

export class ContractReviewsListItem extends Component {
    static propTypes = {
        contractReview: PropTypes.shape({
            contractComplaints: PropTypes.array.isRequired,
            id: PropTypes.number.isRequired,
            comment: PropTypes.string,
            overallRating: PropTypes.number,
            type: PropTypes.number.isRequired,
            updated_at: PropTypes.string.isRequired,
            user: PropTypes.object.isRequired,
        }).isRequired,
        readOnly: PropTypes.bool,
        showContractComplaintModal: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    showContractComplaintModal = (contractComplaint) => {
        const { contractReview, showContractComplaintModal } = this.props;

        showContractComplaintModal({
            contractComplaint,
            contractReview,
            readOnly: true,
        });
    };

    render() {
        const {
            contractReview,
            contractReview: {
                comment,
                contractComplaints,
                overallRating,
                type,
                updated_at: updatedAt,
                user,
            },
            readOnly,
        } = this.props;

        const complaintNum = contractComplaints.length;
        const unresolvedNum = contractComplaints.filter(
            (complaint) => !complaint.isResolved
        ).length;

        return (
            <div className="row">
                <div className="col-md-8">
                    <div className="pull-right text-right">
                        <div title={moment(updatedAt).format('lll')}>
                            Last updated {moment(updatedAt).fromNow()}
                        </div>
                        <small>Contract {contractReviewTypeNames[type]} at time of review</small>
                    </div>
                    <div className={this.styles.user}>
                        <UserProfilePicture horizontal user={user} />
                        &nbsp;
                        <strong>{user.displayName}</strong>
                    </div>
                    <div className={this.styles.reviewBlock}>
                        <StarsDisplay value={overallRating} />
                    </div>
                    <p>{truncate(comment, { length: 20000, separator: ' ' })}</p>
                </div>
                <div className="col-md-4">
                    <h5>
                        Complaints: {complaintNum}
                        {complaintNum > 0 && ` (${unresolvedNum} Unresolved)`}
                    </h5>
                    {contractComplaints.length === 0 && (
                        <div className={this.styles.noCompaints}>
                            <small>
                                <em>No complaints were filed with this review</em>
                            </small>
                        </div>
                    )}
                    <ContractComplaintsList
                        className={this.styles.contractComplaintsList}
                        contractReview={contractReview}
                        readOnly={readOnly}
                        showContractComplaintModal={this.props.showContractComplaintModal}
                    />
                </div>
            </div>
        );
    }
}
