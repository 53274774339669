import PropTypes from 'prop-types';
import React from 'react';
import { AccordionDetails, Box, Checkbox, FormControlLabel, FormGroup } from '@og-pro/ui';

import { accordionDetailsStyles } from './sharedStyles';
import { ethnicities } from '../../../../../shared_config/certifications';

export const EthnicityAccordion = ({ formData, setFormData }) => {
    return (
        <AccordionDetails sx={accordionDetailsStyles}>
            <Box pb={1} px={1}>
                {ethnicities.map((ethnicity) => (
                    <FormGroup key={ethnicity}>
                        <FormControlLabel
                            checked={formData.ethnicities.indexOf(ethnicity) > -1}
                            control={<Checkbox name={ethnicity} size="small" />}
                            label={ethnicity}
                            onChange={() => {
                                const currentIndex = formData.ethnicities.indexOf(ethnicity);
                                const updatedEthnicities = [...formData.ethnicities];
                                if (currentIndex === -1) {
                                    updatedEthnicities.push(ethnicity);
                                } else {
                                    updatedEthnicities.splice(currentIndex, 1);
                                }

                                setFormData({
                                    ...formData,
                                    ethnicities: updatedEthnicities,
                                });
                            }}
                            sx={{ marginBottom: '0' }}
                        />
                    </FormGroup>
                ))}
            </Box>
        </AccordionDetails>
    );
};

EthnicityAccordion.propTypes = {
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired,
};
