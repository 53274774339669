import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { form, UPFRONT_QUESTIONS } from './constants';
import { validate, warn } from './validate';
import {
    getIntakeWritingPath,
    getReviewProjectJS,
    getWritingPath,
    isBuilderEditor,
    isIntakeEditor,
} from '../selectors';
import { Button, Main, SectionTitle, UpfrontQuestionsResponseForm } from '../../../components';
import { projectStatusesDict } from '../../../../../shared_config/projects';
import { INPUT_DATA } from '../../../../../shared_config/questionnaires';

const { DRAFT, REQUEST_DRAFT, REQUEST_REVIEW, REVIEW } = projectStatusesDict;

const formConfig = {
    form,
    validate,
    warn,
};

const ConnectedUpfrontQuestionsList = reduxForm(formConfig)((props) => {
    const { change, isDraft, isEditor, project, writingPath } = props;

    if (!project) {
        return null;
    }

    return (
        <Main className="row">
            <div className="col-lg-offset-2 col-lg-8">
                <SectionTitle
                    info="Options that have been used to setup the document"
                    title="Document Setup"
                />
                <div className="text-right">
                    {isDraft && (
                        <Button
                            bsStyle="link"
                            disabled={!isEditor}
                            qaTag="connectedUpfrontQuestionsList-edit"
                            to={writingPath}
                            tooltip={
                                isEditor
                                    ? undefined
                                    : 'Only document editors can update this information'
                            }
                        >
                            <i className="fa fa-pencil" /> Edit
                        </Button>
                    )}
                </div>
                <UpfrontQuestionsResponseForm
                    change={change}
                    disabled
                    formKey={UPFRONT_QUESTIONS}
                    responsePath={INPUT_DATA}
                />
            </div>
        </Main>
    );
});

ConnectedUpfrontQuestionsList.propTypes = {
    change: PropTypes.func.isRequired,
    isDraft: PropTypes.bool.isRequired,
    isEditor: PropTypes.bool.isRequired,
    project: PropTypes.shape({
        id: PropTypes.number.isRequired,
        upfrontQuestions: PropTypes.array.isRequired,
    }).isRequired,
    writingPath: PropTypes.string.isRequired,
};

export const UpfrontQuestionsList = (props) => {
    const baseLocation = useLocation();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const location = { ...baseLocation, query: Object.fromEntries(searchParams) };

    const project = useSelector(getReviewProjectJS);
    const isEditor = useSelector((state) =>
        project && project.isIntake ? isIntakeEditor(state) : isBuilderEditor(state)
    );
    const writingPath = useSelector((state) =>
        project && project.isIntake
            ? getIntakeWritingPath(state, { params, location })
            : getWritingPath(state, { params, location })
    );

    const extraProps = {
        initialValues: {
            [UPFRONT_QUESTIONS]: project.upfrontQuestions,
        },
        isDraft: [REQUEST_DRAFT, REQUEST_REVIEW, DRAFT, REVIEW].includes(project && project.status),
        isEditor,
        project,
        writingPath,
    };

    return <ConnectedUpfrontQuestionsList {...props} {...extraProps} />;
};
