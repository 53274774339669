import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { getVendorSearchDownloading } from '../../VendorManagement/selectors';
import { SubscribedVendorList } from '../../VendorManagement/SubscribedVendorList';
import connectData from '../../../ConnectData';
import {
    downloadVendorSearch,
    loadProcuratedRatingsClient,
    vendorClassificationsReport,
    vendorSearch,
} from '../../../../actions/vendorList';
import { showVendorProfileModal } from '../../../../actions/vendorProfile';
import { vendorEmergencyDesignationsColumnMapping } from '../../../../../../shared_config/vendors';

function fetchData(getState, dispatch, location, params) {
    const promises = [];

    const governmentId = Number.parseInt(params.governmentId, 10);

    promises.push(dispatch(vendorClassificationsReport(governmentId)));

    return Promise.all(promises);
}

const mapStateToProps = (state, props) => {
    const governmentId = Number.parseInt(props.params.governmentId, 10);
    return {
        governmentId,
        vendorSearchDownloading: getVendorSearchDownloading(state),
    };
};

const mapDispatchToProps = {
    vendorSearch,
    downloadVendorSearch,
    loadProcuratedRatingsClient,
    showVendorProfileModal,
};

// @connectData
// @connect
class ConnectedEmergencyVendorsList extends Component {
    static propTypes = {
        downloadVendorSearch: PropTypes.func.isRequired,
        governmentId: PropTypes.number.isRequired,
        loadProcuratedRatingsClient: PropTypes.func.isRequired,
        showVendorProfileModal: PropTypes.func.isRequired,
        vendorSearch: PropTypes.func.isRequired,
        vendorSearchDownloading: PropTypes.bool,
    };

    render() {
        const { governmentId, vendorSearchDownloading } = this.props;

        return (
            <SubscribedVendorList
                downloadVendorSearch={this.props.downloadVendorSearch}
                governmentId={governmentId}
                hideCategorySearch
                hideStatusColumn
                includeExpandedData
                loadProcuratedRatingsClient={this.props.loadProcuratedRatingsClient}
                searchParams={{
                    emergencyDesignations: Object.values(vendorEmergencyDesignationsColumnMapping),
                    isEmergencySearchDesignation: true,
                }}
                showVendorProfileModal={this.props.showVendorProfileModal}
                vendorSearch={this.props.vendorSearch}
                vendorSearchDownloading={vendorSearchDownloading}
            />
        );
    }
}

export const EmergencyVendorsList = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedEmergencyVendorsList);
