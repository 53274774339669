import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import momentLocalizer from 'react-widgets-moment';

import { Label } from '../InputText';

momentLocalizer();

/*
 * Unfortunately `PureComponent` can't be used
 * here, since we don't want it to re-render every
 * time min changes. Basically,there's a bug in the
 * library where if you change min, then its ui stops
 * working... really annoying.
 */
export class DateTime extends Component {
    static propTypes = {
        allowEmpty: PropTypes.bool,
        date: PropTypes.bool,
        disabled: PropTypes.bool,
        dropUp: PropTypes.bool,
        format: PropTypes.string,
        input: PropTypes.shape({
            name: PropTypes.string,
            onBlur: PropTypes.func.isRequired,
            onChange: PropTypes.func.isRequired,
            onFocus: PropTypes.func.isRequired,
            value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
        }).isRequired,
        label: PropTypes.string,
        max: PropTypes.instanceOf(Date),
        min: PropTypes.instanceOf(Date),
        onChange: PropTypes.func,
        onInputDateChange: PropTypes.func,
        parse: PropTypes.func,
        placeholder: PropTypes.string,
        time: PropTypes.bool,
    };

    static defaultProps = {
        allowEmpty: false,
        date: true,
        disabled: false,
        dropUp: false,
        format: undefined,
        max: undefined,
        min: undefined,
        parse: undefined,
        time: false,
    };

    constructor(props) {
        super(props);
        this.state = { key: 1 };
    }

    /*
     * onChange acts as an action dispatcher. We do the update when min changes (this is why you see
     * dates shift forward - so you don't have things in the future and past). We also have to do
     * this here when min changes to deal with a bug when min changes.  If min changes and onChange
     * is not called, the widget's UI breaks
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const value = this.props.input.value || nextProps.input.value;

        if (this.props.min !== nextProps.min) {
            const min = nextProps.min;

            if (min > value || (min && !value)) {
                this.props.input.onChange(min);
                this.props.onChange?.(min);
            }
        } else if (this.props.max !== nextProps.max) {
            const max = nextProps.max;

            if (max < value || (max && !value)) {
                this.props.input.onChange(max);
                this.props.onChange?.(max);
            }
        }
    }

    /*
     * Also - MAJOR performance issues if we do not use
     * shouldComponentUpdate here. Otherwise, every time
     * you change the text on a separate field in the parent
     * component, these widgets reload. These widgets are
     * fairly bulky, so that can and DOES hurt performance
     * drastically. Additionally, we must stop the update
     * if min changes - otherwise a bug in this widget
     * surfaces.  Instead, we trigger an onChange event
     * for the value to move to the new min (see above
     * componentWillReceiveProps)
     */
    shouldComponentUpdate(nextProps) {
        if (nextProps.input !== this.props.input) {
            return true;
        }
        if (nextProps.min !== this.props.min) {
            return false;
        }
        if (nextProps.max !== this.props.max) {
            return false;
        }
        if (nextProps.disabled !== this.props.disabled) {
            return true;
        }
        return false;
    }

    onChange = (date) => {
        const { allowEmpty } = this.props;

        // Do not allow no date to be selected
        if (!date) {
            if (allowEmpty) {
                this.props.input.onChange(null);
                this.props.onChange?.(null);
            }

            /*
             * Workaround for displaying the current date in the date input when a null or invalid
             * date is provided as the new date.
             *
             * `react-widgets` sets its internal state to empty when no date or an invalid date is
             * provided, which is not the behavior we want when `allowEmpty` is not used.
             *
             * With `allowEmpty` off, we want to always keep a date in the input. We do so with our
             * form value by not calling `input.onChange`, but `react-widgets` still updates its
             * internal state causing the display to be empty. As a workaround for preventing the
             * empty display, we re-render the input in such cases, since the re-rendered display
             * will use the value in the form.
             */
            this.setState((prevState) => ({ key: prevState.key + 1 }));
            return;
        }

        let changedDate = date;
        if (date < this.props.min) {
            changedDate = this.props.min;
        } else if (date > this.props.max) {
            changedDate = this.props.max;
        }

        /**
         * Re-render the DateTimePicker if the date has changed
         * This is necessary because the DateTimePicker does not update its internal state when a
         * date that is not within the min and max range is typed in the input field.
         */
        if (date !== changedDate) {
            this.setState((prevState) => ({ key: prevState.key + 1 }));
        }
        this.props.input.onChange(changedDate);
        this.props.onChange?.(changedDate);
    };

    /**
     * The field value must be fetched due to a race condition with onChange which fires directly
     * before onBlur. This happens when the text input is updated. onChange does not fire until the
     * text input is blurred, which causes the race condition.
     */
    onBlur = () => {
        const {
            input: { value, onBlur },
        } = this.props;
        onBlur(value);
    };

    render() {
        const {
            disabled,
            date,
            dropUp,
            format,
            input: { name, onFocus, value },
            label,
            max,
            min,
            onInputDateChange,
            parse,
            placeholder,
            time,
        } = this.props;

        return (
            <>
                {!label && <Label className="visually-hidden" label={name} />}
                <DateTimePicker
                    aria-labelledby={label || name}
                    date={date}
                    disabled={disabled}
                    dropUp={dropUp}
                    format={format}
                    inputProps={{
                        onInput: onInputDateChange,
                    }}
                    key={this.state.key}
                    max={max}
                    min={min}
                    name={name}
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    onCurrentDateChange={this.onChange}
                    onFocus={onFocus}
                    parse={parse}
                    placeholder={placeholder}
                    time={time}
                    value={value ? new Date(value) : null}
                />
            </>
        );
    }
}
