import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { v4 } from 'uuid';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Box, Typography } from '@og-pro/ui';

import { TIMELINE_CONFIG, TIMELINES } from '../../../../../containers/GovApp/constants';
import { getOpenGovDndStyle } from '../../../../../constants/styles';
import { customTimelineFieldsDict } from '../../../../../../../shared_config/timelines';
import { CDSButton, CDSDropdownButton, MenuItem } from '../../../..';
import { TimelineToggles } from './TimelineToggles';
import { TIMELINE_PROPTYPE } from '../constants';

const {
    ORDER_BY_ID,
    AGENDA_URL,
    DATE,
    DISPLAY_TIME,
    HAS_TEXT_DATE,
    IS_ATTENDANCE_REQUIRED,
    IS_PUBLIC,
    LOCATION,
    SHOW_LOCATION,
    TEXT_DATE,
    TITLE,
} = customTimelineFieldsDict;

export const TimelineConfiguration = (props) => {
    const {
        array,
        change,
        disabled,
        isReverseAuction,
        showValidation,
        project,
        timelineData,
        toggleViewMode,
    } = props;

    const styles = require('./shared.scss');

    const addCustomEvent = (opts = {}) => {
        array.push(TIMELINES, {
            [HAS_TEXT_DATE]: false,
            [ORDER_BY_ID]: timelineData[timelineData.length - 1].orderById + 1,
            [IS_ATTENDANCE_REQUIRED]: false,
            [IS_PUBLIC]: false,
            [LOCATION]: null,
            [SHOW_LOCATION]: false,
            [AGENDA_URL]: null,
            [DISPLAY_TIME]: false,
            [DATE]: null,
            [TEXT_DATE]: null,
            [TITLE]: '',
            isIncluded: true,
            isCustomDate: true,
            uuid: v4(),
            ...opts,
        });
    };
    const handleDragEnd = (result) => {
        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        if (newLocation !== undefined && newLocation !== originLocation) {
            const timelineDataCopy = timelineData.slice();
            const timelineToMove = timelineDataCopy.splice(originLocation, 1);
            timelineDataCopy.splice(newLocation, 0, ...timelineToMove);
            timelineDataCopy.forEach((timeline, idx) => {
                const fieldName = timeline.orderByIdField
                    ? `${TIMELINE_CONFIG}.${timeline.orderByIdField}`
                    : `${TIMELINES}.${timeline.index}.${ORDER_BY_ID}`;

                change(fieldName, idx + 1);
            });
        }
    };
    const onCustomEventRemove = (index) => {
        array.remove(TIMELINES, index);
    };

    return (
        <Box className={styles.container}>
            <Box mb={4}>
                <Typography variant="h3">Define the Project Dates</Typography>
            </Box>
            <Box className={classnames(styles.legend, styles.borderBottom)} fontWeight={500} pb={1}>
                Event
            </Box>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="timelineList" type={TIMELINES}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getOpenGovDndStyle(snapshot)}
                            {...provided.droppableProps}
                        >
                            <div>
                                {timelineData.map((timeline, i) => (
                                    <TimelineToggles
                                        change={change}
                                        disabled={disabled}
                                        editMode
                                        index={i}
                                        isReverseAuction={isReverseAuction}
                                        key={i}
                                        onCustomEventRemove={onCustomEventRemove}
                                        showValidation={showValidation}
                                        timeline={timeline}
                                    />
                                ))}
                            </div>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Box display="flex" justifyContent="space-between" mt={2}>
                <Box>
                    {!project ? (
                        <CDSButton
                            onClick={toggleViewMode}
                            qaTag="timelineForm-seeDates"
                            variant="secondary"
                        >
                            <i className="fa fa-calendar" /> See Dates
                        </CDSButton>
                    ) : (
                        <CDSButton
                            onClick={toggleViewMode}
                            qaTag="timelineForm-seeDates"
                            variant="primary"
                        >
                            Done
                        </CDSButton>
                    )}
                </Box>
                <Box className={styles.dropdown}>
                    <CDSDropdownButton
                        disabled={disabled}
                        title={
                            <>
                                <i className="fa fa-plus" /> Add Custom Event
                            </>
                        }
                        variant="secondary"
                    >
                        <MenuItem onClick={() => addCustomEvent()} qaTag="timelineForm-addEvent">
                            <i className="fa fa-fw fa-calendar" /> Date
                        </MenuItem>
                        <MenuItem
                            onClick={() => addCustomEvent({ [DISPLAY_TIME]: true })}
                            qaTag="timelineForm-addEvent"
                        >
                            <i className="fa fa-fw fa-clock-o" /> Date and Time
                        </MenuItem>
                        <MenuItem
                            onClick={() => addCustomEvent({ [HAS_TEXT_DATE]: true })}
                            qaTag="timelineForm-addEvent"
                        >
                            <i className="fa fa-fw fa-font" /> Text instead of Date
                        </MenuItem>
                    </CDSDropdownButton>
                </Box>
            </Box>
        </Box>
    );
};

TimelineConfiguration.propTypes = {
    array: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isReverseAuction: PropTypes.bool,
    showValidation: PropTypes.bool,
    project: PropTypes.object,
    timelineData: PropTypes.arrayOf(TIMELINE_PROPTYPE).isRequired,
    toggleViewMode: PropTypes.func.isRequired,
};
