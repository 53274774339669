import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { linkifyLocation } from '../../../../../../../shared_config/helpers';

export class TimelineDate extends PureComponent {
    static propTypes = {
        agendaUrl: PropTypes.string,
        date: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        isDocx: PropTypes.bool,
        location: PropTypes.string,
    };

    getLocationText = () => {
        const { location } = this.props;

        if (!location) {
            return null;
        }

        return linkifyLocation(location);
    };

    renderDocx() {
        const { agendaUrl, date, location } = this.props;

        return (
            <td style={{ padding: 10, textAlign: 'left' }}>
                {date}
                {agendaUrl && (
                    <div>
                        <a href={agendaUrl} rel="noopener noreferrer" target="_blank">
                            Agenda
                        </a>
                    </div>
                )}
                {location && (
                    <div>
                        {/* eslint-disable-next-line react/no-danger */}
                        <span dangerouslySetInnerHTML={{ __html: this.getLocationText() }} />
                    </div>
                )}
            </td>
        );
    }

    render() {
        const { agendaUrl, date, isDocx, location } = this.props;

        if (isDocx) {
            return this.renderDocx();
        }

        return (
            <div className="col-md-6">
                {date}
                {agendaUrl && (
                    <div>
                        <a href={agendaUrl} rel="noopener noreferrer" target="_blank">
                            <i className="fa fa-paperclip" /> Agenda
                        </a>
                    </div>
                )}
                {/* eslint-disable-next-line react/no-danger */}
                {location && <div dangerouslySetInnerHTML={{ __html: this.getLocationText() }} />}
            </div>
        );
    }
}
